import { IGetOther, IGetOtherResponse, IOther } from 'interfaces/team/others.interface';
import { Dispatch } from 'redux';
import { otherService } from 'services/team/others.service';

export const otherConstants = {
    CREATE_REQUEST: 'OTHER_CREATE_REQUEST',
    CREATE_SUCCESS: 'OTHER_CREATE_SUCCESS',
    CREATE_FAILURE: 'OTHER_CREATE_FAILURE',

    GET_REQUEST: 'OTHER_GET_REQUEST',
    GET_SUCCESS: 'OTHER_GET_SUCCESS',
    GET_FAILURE: 'OTHER_GET_FAILURE',

    SEARCH_REQUEST: 'OTHER_SEARCH_REQUEST',
    SEARCH_SUCCESS: 'OTHER_SEARCH_SUCCESS',

    UPDATE_REQUEST: 'OTHER_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'OTHER_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'OTHER_UPDATE_FAILURE',

    DELETE_SUCCESS: 'OTHER_SUCCESS',
};

const createOther =
    (otherData: IOther) =>
    (dispatch: Dispatch): Promise<IOther> => {
        dispatch({
            type: otherConstants.CREATE_REQUEST,
        });

        return otherService.create(otherData).then(other => {
            dispatch({
                type: otherConstants.CREATE_SUCCESS,
                other,
            });

            return other;
        });
    };

const getBusinessOther =
    (getOtherData: IGetOther, overwrite = false) =>
    (dispatch: Dispatch): Promise<IGetOtherResponse> => {
        return otherService.getBusinessOther(getOtherData).then(other => {
            if (!overwrite) {
                dispatch({
                    type: otherConstants.GET_SUCCESS,
                    other,
                });
            } else {
                dispatch({
                    type: otherConstants.SEARCH_SUCCESS,
                    other,
                });
            }

            return other;
        });
    };

const searchBusinessOther =
    (searchData: IGetOther) =>
    (dispatch: Dispatch): Promise<IGetOtherResponse> => {
        return otherService.searchBusinessOther(searchData).then(other => {
            dispatch({
                type: otherConstants.SEARCH_SUCCESS,
                other,
            });
            return other;
        });
    };

const getOtherById =
    (otherId: string) =>
    (dispatch: Dispatch): Promise<IOther> => {
        return otherService.getOtherById(otherId).then(other => {
            // dispatch({
            //     type: otherConstants.GET_SUCCESS,
            //     other,
            // });

            return other;
        });
    };

const updateOtherById =
    (id: string, otherData: any) =>
    (dispatch: Dispatch): Promise<IOther> => {
        dispatch({
            type: otherConstants.UPDATE_REQUEST,
        });

        return otherService.updateOtherById(id, otherData).then(other => {
            dispatch({
                type: otherConstants.UPDATE_SUCCESS,
                other,
            });

            return other;
        });
    };
const deleteOtherById =
    (id: string) =>
    (dispatch: Dispatch): Promise<IOther> => {
        return otherService.deleteOtherById(id).then(other => {
            dispatch({
                type: otherConstants.DELETE_SUCCESS,
                id,
            });

            return other;
        });
    };
export const otherActions = {
    createOther,
    getBusinessOther,
    searchBusinessOther,
    getOtherById,
    deleteOtherById,
    updateOtherById,
};
