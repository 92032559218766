import { api } from 'helpers/auth-axios';
import { IGetPermissions, IPermissions } from 'interfaces/permissions.interface';

function create(user_id: string, permissions: IPermissions): Promise<IPermissions> {
    return api.post(`/permissions/${user_id}`, permissions).then(res => {
        return res.data;
    });
}
function getPermissions(user_id: string): Promise<IGetPermissions[]> {
    return api.get(`/permissions/${user_id}`).then(res => {
        return res.data;
    });
}
function updatePermissions(id: string, permissions: IPermissions): Promise<IPermissions> {
    return api.put(`/permissions/${id}`, permissions).then(res => {
        return res.data;
    });
}

export const PermissionServices = { create, getPermissions, updatePermissions };
