import React from 'react';
import moment from 'moment-timezone';

const SuccessfulAppointment = ({
    onBack,
    business,
    serviceName,
    serviceProviderName,
    appointmentDate,
    appointmentStartTime,
}: {
    onBack?(): void;
    business: any;
    serviceName: string | undefined;
    serviceProviderName: string | undefined;
    appointmentDate: any;
    appointmentStartTime: any;
}) => {
    return (
        <div className="booking-widget--body" style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="successful--appointment-container">
                <div className="successful--title">Appointment successfully booked.</div>
                <div className="successful--appointment-details">
                    {serviceName} with {serviceProviderName} on{' '}
                    {moment(appointmentDate).format('dddd, MMM DD, YYYY')} at{' '}
                    {moment(appointmentStartTime, 'HH:mm').format('hh:mm a')}
                </div>
                <div className="bookAgain--button" onClick={onBack}>
                    <button
                        className="btn-book"
                        style={{
                            width: 'auto',
                            backgroundColor: `${
                                business?.booking_widget?.button_bg_color
                                    ? business?.booking_widget?.button_bg_color
                                    : '#cf9d65'
                            }`,
                            color: `
                    ${
                        business?.booking_widget?.button_text_color
                            ? business?.booking_widget?.button_text_color
                            : '#27292e'
                    }`,
                        }}>
                        Book Another Appointment
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SuccessfulAppointment;
