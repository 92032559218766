import React, { useState } from 'react';
import styled from 'styled-components';
export interface RadioProps {
    name?: string;
    value?: string;
    selected?: boolean;
    disabled?: boolean;
    onChange?: any;
    children?: React.ReactNode;
}
const Radio = ({ name, value, selected, onChange, children }: RadioProps) => {
    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { id } = event.currentTarget;
        if (onChange) onChange(id);
    };
    return (
        <RadioContainer htmlFor={value}>
            <div>
                <RadioInput
                    type="radio"
                    name={name}
                    value={value}
                    id={value}
                    checked={selected}
                    onChange={handleRadioChange}
                />
                <RadioChoice selected={selected}></RadioChoice>
            </div>
            <RadioLabel>{children}</RadioLabel>
        </RadioContainer>
    );
};
const RadioContainer = styled.label`
    display: flex;
    user-select: none;
    cursor: pointer;
    align-items: center;
`;
const RadioInput = styled.input`
    position: absolute;
    visibility: hidden;
    z-index: -1;
`;
const RadioChoice = styled.div<RadioProps>`
    position: relative;
    background: #fff;
    height: 24px;
    width: 24px;
    border: 1px solid #ebebeb;
    flex: 0 0 24px;
    transition: all 0.2s ease-in-out;
    overflow: hidden;
    border-radius: 50%;
    border: ${({ selected }) => (selected ? 'solid 2px #252f3e' : '1px solid #ebebeb')};
    &::before {
        content: ' ';
        opacity: ${({ selected }) => (selected ? '1' : '0')};
        height: 12px;
        width: 12px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #252f3e;
        transition: all 0.2s ease-in-out;
        border-radius: 50%;
    }
`;
const RadioLabel = styled.div`
    margin-left: 16px;
    flex: 1;
`;
export default Radio;
