import { workingHoursConstants } from 'actions/workingHours';
import { IGetWorkingHours } from 'interfaces/workinghours.interface';

const INITIAL_STATE: IGetWorkingHours = {
    workingHours: [],
};
export function workingHours(state = INITIAL_STATE, action: any) {
    switch (action.type) {
        case workingHoursConstants.GET_SUCCESS:
            return {
                ...action.workingHours,
                workingHours: action.workingHours,
            };
        case workingHoursConstants.CREATE_SUCCESS:
            return {
                ...state,
                workingHours: state.workingHours?.concat(action.workingHours),
            };
        case workingHoursConstants.UPDATE_SUCCESS:
            // const prev = state.workingHours.find(
            //   (workingHours) => workingHours._id === action.workingHours._id
            // );
            return { workingHours: action.workingHours };
        // return state;
        default:
            return state;
    }
}
