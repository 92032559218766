import { useEffect, useState, useCallback } from 'react';
import leftChevron from '../../../images/icons/chevron-left.svg';
import { publicApi } from '../../../helpers/public-axios';
import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import { Checkbox, SquarePaymentForm } from '@components/common';
import NewCreditCard from './CreditCard';
import TermsModel from './TermsModel';
import { ISquareCard } from 'interfaces/square.interface';

const CompleteBooking = ({
    service,
    isVip,
    showReserveButton,
    showPrepayButton,
    onPrevious,
    onNext,
    business,
    buttonTitle,
    loading,
    reserve,
    customer,
    serviceProvider,
    errorMessage,
    squareCardInfo,
    stripeCardInfo,
    isPrepaying,
}: {
    service: any;
    isVip: boolean;
    showReserveButton: boolean;
    showPrepayButton: boolean;
    onPrevious(): void;
    onNext(): void;
    business: any;
    buttonTitle: any;
    loading: any;
    reserve: any;
    customer?: any;
    serviceProvider: any;
    errorMessage?: any;
    squareCardInfo(card: ISquareCard, bookingFeeToken: string): void;
    stripeCardInfo(_cardInfo: any): void;
    isPrepaying(_isPrepaying: boolean): void;
}) => {
    const [cardState, setCardState] = useState({
        cardNumber: '',
        cardHolder: 'FULL NAME',
        cardMonth: '',
        cardYear: '',
        cardCVV: '',
        isCardFlipped: false,
    });
    const [loadingSubmit, setLoading] = useState<boolean>(false);
    const [onCardResponse, setOnCardResponse] = useState<boolean>(false);
    const [cardNumber, setCardNumber] = useState<any>('');
    const [paymentMethodId, setPaymentMethodId] = useState<any>();
    const [termsModel, setTermsModel] = useState<boolean>(true);

    /*
     * Stripe-Specific Variables
     */
    const [userAcceptedStripeCardSavingTerms, setUserAcceptedStripeCardSavingTerms] =
        useState<boolean>(false);

    const updateCardState = useCallback(
        (keyName: string, value: any) => {
            setCardState({
                ...cardState,
                [keyName]: value,
            });
        },
        [cardState]
    );

    /*
     * Helper/Utility Functions
     */
    // return a string representing the payment processor in use
    const getPaymentProcessor = () => {
        // First, check the service provider
        // Check if stripe is enabled
        if (serviceProvider?.stripe_account_verified) {
            return 'stripe';
        }
        // Check if square is enabled
        if (serviceProvider?.square_account_verified) {
            return 'square';
        }
        // Now, we check the business
        // Check if stripe is enabled
        if (business?.stripe_account_verified) {
            return 'stripe';
        }
        // Check if square is enabled
        if (business?.square_account_verified) {
            return 'square';
        }

        return '';
    };

    return (
        <div className="booking-wrapper">
            <div className="boking-widget--card-header">
                {loadingSubmit === true || loading === true ? (
                    <div className="booking-widget--backBtn" style={{ opacity: '0' }}>
                        <img src={leftChevron} alt="" />
                    </div>
                ) : (
                    <div className="booking-widget--backBtn" onClick={onPrevious}>
                        <img src={leftChevron} alt="" />
                    </div>
                )}
                <h3>Complete Booking Request</h3>
            </div>
            <CustomDiv>
                {getPaymentProcessor() === 'stripe' && (
                    <>
                        <NewCreditCard
                            initialState={cardState}
                            isReserveAndPayEnabled={showPrepayButton}
                            loading={loading}
                            onChange={updateCardState}
                        />
                        <CheckboxWrapper>
                            <Checkbox
                                name="enable-save-card-on-file"
                                label={
                                    <>
                                        This service provider - {serviceProvider.name} - is using
                                        Stripe for payment processing. I authorize this service
                                        provider to save this card on file for future purchases.{' '}
                                        <a
                                            href="https://www.getexclusively.com/terms-stripe"
                                            target="_blank"
                                            style={{
                                                textDecoration: 'underline',
                                                color: '#0154ff',
                                            }}>
                                            More info here.
                                        </a>
                                    </>
                                }
                                onChange={(checked: boolean) => {
                                    setUserAcceptedStripeCardSavingTerms(checked);
                                }}
                            />
                        </CheckboxWrapper>
                        {cardState.cardCVV.length < 5 &&
                        cardState.cardHolder &&
                        cardState.cardMonth &&
                        cardState.cardNumber.length >= 12 &&
                        cardState.cardYear &&
                        userAcceptedStripeCardSavingTerms ? (
                            <>
                                <div
                                    className="carddetail--footer"
                                    style={{ flexDirection: 'column' }}>
                                    {(loading === true || onCardResponse === true) && cardNumber && (
                                        <div className="card_error">
                                            {' '}
                                            <span className="error-text">
                                                {
                                                    cardNumber
                                                    // : errorMessage
                                                    // ? errorMessage
                                                    //         : 'Something went wrong, check entered information and try again!'
                                                }
                                            </span>
                                        </div>
                                    )}
                                    {errorMessage && (
                                        <div className="card_error">
                                            {' '}
                                            <span className="error-text">
                                                {errorMessage ===
                                                'Appointment Time Not Available' ? (
                                                    <>
                                                        <b>{errorMessage}: </b>
                                                        {
                                                            'Due to high demand of our services, the appointment time you have selected is no longer available. Please select a  new time and try again.'
                                                        }
                                                    </>
                                                ) : (
                                                    <>{errorMessage}</>
                                                )}
                                            </span>
                                        </div>
                                    )}
                                    <div>
                                        <div
                                            className="carddetail--footer"
                                            style={{ flexDirection: 'column', border: 'none' }}>
                                            <div
                                                style={{
                                                    fontSize: '14px',
                                                    textAlign: 'center',
                                                    margin: '1rem 0rem',
                                                    color: 'black',
                                                    borderRadius: '4px',
                                                    padding: '10px 45px',
                                                }}>
                                                {buttonTitle}
                                            </div>
                                            {showPrepayButton && (
                                                <button
                                                    className="btn"
                                                    disabled={loading}
                                                    onClick={() => {
                                                        stripeCardInfo(cardState);
                                                        isPrepaying(true);
                                                        onNext();
                                                    }}>
                                                    {loading === false
                                                        ? `Reserve & Pay ${
                                                              business.currency
                                                          }${parseFloat(service).toFixed(2)}`
                                                        : 'Please wait...'}{' '}
                                                </button>
                                            )}
                                            {showReserveButton && (
                                                <button
                                                    className="btn"
                                                    disabled={loading}
                                                    style={{ marginTop: '0.5rem' }}
                                                    onClick={() => {
                                                        stripeCardInfo(cardState);
                                                        isPrepaying(false);
                                                        onNext();
                                                    }}>
                                                    {reserve === false && loading === false
                                                        ? `Reserve with Card`
                                                        : 'Please wait...'}{' '}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="agreement-txt-card">
                                    <Terms onClick={() => setTermsModel(true)}>
                                        By booking an appointment you agree to the{' '}
                                        <a
                                            href="https://getexclusively.com/privacy"
                                            target="_blank"
                                            style={{ textDecoration: 'underline' }}>
                                            Privacy Policy
                                        </a>{' '}
                                        and{' '}
                                        <a
                                            href="https://getexclusively.com/terms"
                                            target="_blank"
                                            style={{ textDecoration: 'underline' }}>
                                            Terms of Use
                                        </a>
                                        .
                                    </Terms>
                                </div>
                            </>
                        ) : (
                            <div>
                                <div
                                    className="carddetail--footer-disabled"
                                    style={{ flexDirection: 'column' }}>
                                    <div
                                        style={{
                                            fontSize: '14px',
                                            textAlign: 'center',
                                            margin: '1rem 0rem',
                                            color: 'black',
                                            padding: '10px 45px',
                                        }}>
                                        {buttonTitle}
                                    </div>
                                    {showPrepayButton && (
                                        <button className="btn">
                                            {loadingSubmit === false || loading === true
                                                ? 'Reserve & Pay'
                                                : 'Please wait...'}{' '}
                                            {business.currency}
                                            {parseFloat(service).toFixed(2)}
                                        </button>
                                    )}
                                    {showReserveButton && (
                                        <button className="btn" style={{ marginTop: '0.5rem' }}>
                                            {reserve === false
                                                ? `Reserve with Card`
                                                : 'Please wait...'}{' '}
                                        </button>
                                    )}
                                </div>
                                <div className="agreement-txt-card">
                                    <Terms>
                                        By booking an appointment you agree to the{' '}
                                        <a
                                            href="https://getexclusively.com/privacy"
                                            target="_blank"
                                            style={{ textDecoration: 'underline' }}>
                                            Privacy Policy
                                        </a>{' '}
                                        and{' '}
                                        <a
                                            href="https://getexclusively.com/terms"
                                            target="_blank"
                                            style={{ textDecoration: 'underline' }}>
                                            Terms of Use
                                        </a>
                                        .
                                    </Terms>
                                </div>
                            </div>
                        )}
                    </>
                )}
                {getPaymentProcessor() === 'square' && (
                    <>
                        <SquarePaymentForm
                            enableReserveAndPay={showPrepayButton}
                            enableReserveOnly={showReserveButton}
                            singleTokenReceiver={(reserveAndPayTokenResult: any) => {
                                const squareCard: ISquareCard = {
                                    id: reserveAndPayTokenResult.token,
                                    brand: reserveAndPayTokenResult.details.card.brand,
                                    expMonth: reserveAndPayTokenResult.details.card.expMonth,
                                    expYear: reserveAndPayTokenResult.details.card.expYear,
                                    last4: reserveAndPayTokenResult.details.card.last4,
                                    billing: {
                                        postalCode:
                                            reserveAndPayTokenResult.details.billing.postalCode,
                                    },
                                };
                                squareCardInfo(squareCard, '');
                                isPrepaying(true);
                                onNext();
                            }}
                            doubleTokenReceiver={(
                                reserveTokenResult: any,
                                bookingFeeTokenResult: any
                            ) => {
                                const squareCard: ISquareCard = {
                                    id: reserveTokenResult.token,
                                    brand: reserveTokenResult.details.card.brand,
                                    expMonth: reserveTokenResult.details.card.expMonth,
                                    expYear: reserveTokenResult.details.card.expYear,
                                    last4: reserveTokenResult.details.card.last4,
                                    billing: {
                                        postalCode: reserveTokenResult.details.billing.postalCode,
                                    },
                                };
                                squareCardInfo(squareCard, bookingFeeTokenResult.token);
                                isPrepaying(false);
                                onNext();
                            }}
                            reserveAndPayButtonLabel={`Reserve & Pay ${
                                business.currency
                            }${parseFloat(service).toFixed(2)}`}
                            reserveOnlyButtonLabel={`Reserve with Card`}
                            cardOnFileAuthorizationCheckboxLabel={
                                <>
                                    This service provider - {serviceProvider.name} - is using Square
                                    for payment processing. I authorize this service provider to
                                    save this card on file for future purchases.{' '}
                                    <a
                                        href="https://www.getexclusively.com/terms-square"
                                        target="_blank"
                                        style={{
                                            textDecoration: 'underline',
                                            color: '#0154ff',
                                        }}>
                                        More info here.
                                    </a>
                                </>
                            }
                            loading={loading}>
                            {errorMessage && (
                                <div className="card_error">
                                    {' '}
                                    <span className="error-text">
                                        {errorMessage === 'Appointment Time Not Available' ? (
                                            <>
                                                <b>{errorMessage}: </b>
                                                {
                                                    'Due to high demand of our services, the appointment time you have selected is no longer available. Please select a  new time and try again.'
                                                }
                                            </>
                                        ) : (
                                            <>{errorMessage}</>
                                        )}
                                    </span>
                                </div>
                            )}
                            <div
                                style={{
                                    fontSize: '14px',
                                    textAlign: 'center',
                                    margin: '1rem 0rem',
                                    color: 'black',
                                    borderRadius: '4px',
                                    padding: '10px 45px',
                                }}>
                                {buttonTitle}
                            </div>
                        </SquarePaymentForm>

                        <div className="agreement-txt-card">
                            <Terms onClick={() => setTermsModel(true)}>
                                By booking an appointment you agree to the{' '}
                                <a
                                    href="https://getexclusively.com/privacy"
                                    target="_blank"
                                    style={{ textDecoration: 'underline' }}>
                                    Privacy Policy
                                </a>{' '}
                                and{' '}
                                <a
                                    href="https://getexclusively.com/terms"
                                    target="_blank"
                                    style={{ textDecoration: 'underline' }}>
                                    Terms of Use
                                </a>
                                .
                            </Terms>
                        </div>
                    </>
                )}
                {termsModel === true && (
                    <TermsModel
                        cancellationPolicy={business.cancellation_policy}
                        onClose={() => setTermsModel(false)}
                    />
                )}
            </CustomDiv>
        </div>
    );
};
const CustomDiv = styled.div`
    padding: 10px 16px 2px 16px;
    height: 100vh;
    overflow-y: auto;

    //     ${media.xs`
//     height: 38rem;
//     overflow-y: auto;
// `}
    //     ${media.sm`
// height: 38rem;
// overflow-y: auto;
// `}
    // ${media.md`
// height: 38rem;
// overflow-y: auto;
// `}
    // ${media.lg`
// height: 38rem;
// overflow-y: auto;
// `};
`;
const Terms = styled.div`
    cursor: pointer;
`;
const CheckboxWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export default CompleteBooking;
