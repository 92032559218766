import { useEffect, useState } from 'react';
import { publicApi } from '../../../helpers/public-axios';

import { IService } from 'interfaces/service.interface';
import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import { IServiceProvider } from '../../../interfaces/team/serviceProvider.interface';

const SelectService = ({
    businessId,
    onSelect,
    close,
    serviceProvider,
}: {
    businessId: any;
    onSelect(service: IService): void;
    close?: any;
    serviceProvider?: IServiceProvider;
}) => {
    const [services, setServices] = useState<IService[]>([]);
    const getServices = () => {
        publicApi.get(`/businesses/public/${businessId._id}/services`).then((res: any) => {
            /*
             * Add indexes to services that still need them and
             * order services by their index.
             */
            let businessServices = [...res.data];

            // if service provider is given, filter out services that are not
            // assigned to the provider
            if (serviceProvider) {
                // the IDs of all services (not staff-services) assigned to
                // the given service provider
                const assignedServiceIds = new Set(
                    serviceProvider.services.map(service => service.service_id)
                );
                businessServices = businessServices.filter(service =>
                    assignedServiceIds.has(service._id)
                );
                // create hashtable of staffservices (key: service_id, props: my_price, vip_price, my_duration)
                let staffServicesHashTable: any = {};
                for (let i = 0; i < serviceProvider.services.length; i++) {
                    staffServicesHashTable[serviceProvider.services[i].service_id] = {
                        my_duration: serviceProvider.services[i].my_duration,
                        my_price: serviceProvider.services[i].my_price,
                        vip_price: serviceProvider.services[i].vip_price,
                    };
                }
                // use staff service customized properties to overwrite service's
                for (let i = 0; i < businessServices.length; i++) {
                    let serviceId = businessServices[i]._id;
                    let staffService = staffServicesHashTable[serviceId];
                    let myDuration = staffService.my_duration;
                    let myPrice = staffService.my_price;
                    let vipPrice = staffService.vip_price;
                    businessServices[i].my_duration = myDuration;
                    businessServices[i].my_price = myPrice;
                    businessServices[i].vip_price = vipPrice;
                }
            }

            // pre-sort services so those w/ default index (-1) are in front
            businessServices.sort((service1, service2) => service1.index - service2.index);
            // go over array again, any value less than 0 (default index is -1)
            // should be set to next available index and moved to the end of the list
            while (businessServices[0]?.index < 0) {
                const nextAvailableIndex = businessServices[businessServices.length - 1].index + 1;
                // remove element from beginning of list
                const [elem] = businessServices.splice(0, 1);
                // update element's index
                elem.index = nextAvailableIndex;
                // add element to end of list
                businessServices.push(elem);
            }

            setServices(businessServices);
        });
    };
    useEffect(() => {
        getServices();
    }, []);

    return (
        <div className="booking-wrapper">
            {businessId?.active == true ? (
                <>
                    <div className="boking-widget--header">
                        <h3>Select a Service</h3>
                    </div>
                    <div className="booking-widget--body">
                        <CustomDiv>
                            {services.map(service =>
                                service.available_for_boooking == true ? (
                                    <div className="single-service">
                                        <div>
                                            <div className="service-title">{service.name}</div>
                                            <div className="service-description">
                                                {service.description || 'No Description'}
                                            </div>
                                            <div className="service-duration">{`${
                                                service.my_duration >= 60
                                                    ? Math.floor(service.my_duration / 60) + 'h'
                                                    : ''
                                            } ${service.my_duration % 60}min`}</div>
                                        </div>
                                        <div className="service--bookWrapper">
                                            {businessId.hide_business_prices === true ? (
                                                ''
                                            ) : (
                                                <div className="service-price">
                                                    {businessId.currency}
                                                    {service.my_price}
                                                </div>
                                            )}
                                            <button
                                                className="btn select-serviceBtn"
                                                onClick={() => onSelect(service)}>
                                                Select
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )
                            )}
                        </CustomDiv>
                    </div>
                </>
            ) : (
                <BlockUser className="booking-widget--body">
                    <div
                        style={{
                            marginBottom: '1rem',
                        }}>
                        User Blocked, Please contact admin for furthur information!
                    </div>
                    {/* <div className="bookAgain--button" onClick={onBack}> */}
                    <button
                        onClick={close}
                        className="btn-book"
                        style={{
                            backgroundColor: `${
                                businessId?.booking_widget?.button_bg_color
                                    ? businessId?.booking_widget?.button_bg_color
                                    : '#cf9d65'
                            }`,
                            color: `
                    ${
                        businessId?.booking_widget?.button_text_color
                            ? businessId?.booking_widget?.button_text_color
                            : '#27292e'
                    }`,
                        }}>
                        Close
                    </button>
                </BlockUser>
            )}
        </div>
    );
};

const CustomDiv = styled.div`
    padding: 0 16px 2px 16px;

    //     ${media.xs`
//     height: 38rem;
//     overflow-y: auto;
// `}
    //     ${media.sm`
// height: 38rem;
// overflow-y: auto;
// `}
    // ${media.md`
// height: 100%;
// overflow-y: auto;
// `}
    // ${media.lg`
// height: 100%;
// overflow-y: auto;
// `};
`;

const BlockUser = styled.div`
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // position: fixed;
    top: 0;
    bottom: 0;
`;
export default SelectService;
