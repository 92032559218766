import React, { useEffect, useState } from 'react';
import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { Switch, Select, useSnackbar } from '@components/common';
import { IWorkingHours } from 'interfaces/workinghours.interface';
import { useForm } from 'react-hook-form';
import Button from '@components/Button';
import useRouter from 'hooks/router';
import { calculateHoursDifference } from '../utils';
import { api } from 'helpers/auth-axios';
import moment from 'moment';
import LoaderOverlay from '../LoaderOverlay';
interface IProps {
    id: string; // ID of the user we're looking up working hours for
    active: boolean;
    business_id: string;
    onChange: (day: any) => void;
    readOnly?: boolean;
}

const WorkingHours = ({ id, onChange, business_id, readOnly }: IProps) => {
    const [openSnackbar] = useSnackbar();
    const { handleSubmit, formState } = useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const router = useRouter();
    const { isSubmitting } = formState;
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    let businesHours: { label: string; value: any }[] = [];
    for (let i: any = 0; i < 24; i++) {
        for (let j = 0; j < 4; j++) {
            businesHours.push({
                label: moment({
                    hour: i,
                    minute: j * 15,
                }).format(_userData.user.business_id.time_select === '12' ? 'hh:mm a' : 'HH:mm'),
                value: moment({ hour: i, minute: j * 15 }).format('HH:mm'),
            });
        }
    }
    const [dayHours, setDayHours] = useState<IWorkingHours>();
    const [mo_Diff, setMonday] = useState();
    const [tu_Diff, setTeusday] = useState();
    const [we_Diff, setWednesday] = useState();
    const [th_Diff, setThursday] = useState();
    const [fr_Diff, setFriday] = useState();
    const [sa_Diff, setSaturday] = useState();
    const [su_Diff, setSunday] = useState();

    const onSubmit = () => {
        let id: any = dayHours?._id;
        let data: any = {
            business_id: business_id,
            mo_from: dayHours?.mo_from,
            mo_to: dayHours?.mo_to,
            tu_from: dayHours?.tu_from,
            tu_to: dayHours?.tu_to,
            we_from: dayHours?.we_from,
            we_to: dayHours?.we_to,
            th_from: dayHours?.th_from,
            th_to: dayHours?.th_to,
            fr_from: dayHours?.fr_from,
            fr_to: dayHours?.fr_to,
            sa_from: dayHours?.sa_from,
            sa_to: dayHours?.sa_to,
            su_from: dayHours?.su_from,
            su_to: dayHours?.su_to,
            mo_on: dayHours?.mo_on,
            tu_on: dayHours?.tu_on,
            we_on: dayHours?.we_on,
            th_on: dayHours?.th_on,
            fr_on: dayHours?.fr_on,
            sa_on: dayHours?.sa_on,
            su_on: dayHours?.su_on,
        };

        api.put(`/workinghours/${id}`, data)
            .then((res: any) => {
                setDayHours(res.data);
                openSnackbar('Updated successfully!');
            })
            .catch(() => {
                openSnackbar('Failed to update!');
            });
    };
    useEffect(() => {
        return onChange(dayHours);
    }, [dayHours, onChange]);

    const workingHoursData = () => {
        setLoading(true);
        api.get(`/workinghours/${id}`)
            .then((dayHours: any) => {
                if (dayHours.data) {
                    setLoading(false);
                    setDayHours(dayHours.data);
                }
            })
            .catch((e: any) => {
                setLoading(false);
            });
    };

    const getWorkingHours = async () => {
        const monday: any = await calculateHoursDifference(dayHours?.mo_from, dayHours?.mo_to);
        setMonday(monday);
        const teusday: any = await calculateHoursDifference(dayHours?.tu_from, dayHours?.tu_to);
        setTeusday(teusday);
        const wednesday: any = await calculateHoursDifference(dayHours?.we_from, dayHours?.we_to);
        setWednesday(wednesday);
        const thursday: any = await calculateHoursDifference(dayHours?.th_from, dayHours?.th_to);
        setThursday(thursday);
        const friday: any = await calculateHoursDifference(dayHours?.fr_from, dayHours?.fr_to);
        setFriday(friday);
        const saturday: any = await calculateHoursDifference(dayHours?.sa_from, dayHours?.sa_to);
        setSaturday(saturday);
        const sunday: any = await calculateHoursDifference(dayHours?.su_from, dayHours?.su_to);
        setSunday(sunday);
    };
    useEffect(() => {
        workingHoursData();
    }, [id]);

    useEffect(() => {
        getWorkingHours();
    }, [dayHours]);
    return (
        <>
            {loading && <LoaderOverlay />}
            {dayHours && (
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div
                        className={`hours-item ${
                            !dayHours?.mo_on || readOnly ? 'hours-item--disabled' : ''
                        }`}>
                        <List>
                            <WeekContent>
                                <Switch
                                    value={dayHours && dayHours?.mo_on}
                                    onChange={(val: boolean) => {
                                        setDayHours((prevState: any) => ({
                                            ...prevState,
                                            mo_on: val,
                                        }));
                                    }}
                                    disabled={readOnly}
                                />
                                <WeekName>Monday</WeekName>
                            </WeekContent>

                            <HourContent className="hours-item--details">
                                <Hour>
                                    <Select
                                        options={businesHours}
                                        value={
                                            dayHours?.mo_from
                                                ? moment(
                                                      moment().format() + ' ' + dayHours?.mo_from,
                                                      'YYYY-MM-DD HH:mm:ss'
                                                  ).format(
                                                      _userData.user.business_id.time_select ===
                                                          '12'
                                                          ? 'hh:mm a'
                                                          : 'HH:mm'
                                                  )
                                                : '09:00'
                                        }
                                        onChange={(val: string) => {
                                            setDayHours((prevState: any) => ({
                                                ...prevState,
                                                mo_from: val,
                                            }));
                                        }}
                                        readOnly={readOnly}
                                    />
                                </Hour>
                                <Hour>
                                    <Select
                                        options={businesHours}
                                        value={
                                            dayHours?.mo_to
                                                ? moment(
                                                      moment().format() + ' ' + dayHours?.mo_to,
                                                      'YYYY-MM-DD HH:mm:ss'
                                                  ).format(
                                                      _userData.user.business_id.time_select ===
                                                          '12'
                                                          ? 'hh:mm a'
                                                          : 'HH:mm'
                                                  )
                                                : '06:00'
                                        }
                                        onChange={(val: string) => {
                                            setDayHours((prevState: any) => ({
                                                ...prevState,
                                                mo_to: val,
                                            }));
                                        }}
                                        readOnly={readOnly}
                                    />
                                </Hour>
                                <Time>
                                    {/* {parseFloat(dayHours?.mo_from!) + parseFloat(dayHours?.mo_to!)} */}
                                    <Icon className="fal fa-clock"></Icon>
                                    {mo_Diff}h
                                </Time>
                            </HourContent>
                        </List>
                    </div>
                    <div
                        className={`hours-item ${
                            !dayHours?.tu_on || readOnly ? 'hours-item--disabled' : ''
                        }`}>
                        <List>
                            <WeekContent>
                                <Switch
                                    value={dayHours?.tu_on}
                                    onChange={(val: boolean) => {
                                        setDayHours((prevState: any) => ({
                                            ...prevState,
                                            tu_on: val,
                                        }));
                                    }}
                                    disabled={readOnly}
                                />
                                <WeekName>Tuesday</WeekName>
                            </WeekContent>

                            <HourContent className="hours-item--details">
                                <Hour>
                                    <Select
                                        options={businesHours}
                                        value={
                                            dayHours?.tu_from
                                                ? moment(
                                                      moment().format() + ' ' + dayHours?.tu_from,
                                                      'YYYY-MM-DD HH:mm:ss'
                                                  ).format(
                                                      _userData.user.business_id.time_select ===
                                                          '12'
                                                          ? 'hh:mm a'
                                                          : 'HH:mm'
                                                  )
                                                : '09:00'
                                        }
                                        onChange={(val: string) => {
                                            setDayHours((prevState: any) => ({
                                                ...prevState,
                                                tu_from: val,
                                            }));
                                        }}
                                        readOnly={readOnly}
                                    />
                                </Hour>
                                <Hour>
                                    <Select
                                        options={businesHours}
                                        value={
                                            dayHours?.tu_to
                                                ? moment(
                                                      moment().format() + ' ' + dayHours?.tu_to,
                                                      'YYYY-MM-DD HH:mm:ss'
                                                  ).format(
                                                      _userData.user.business_id.time_select ===
                                                          '12'
                                                          ? 'hh:mm a'
                                                          : 'HH:mm'
                                                  )
                                                : '06:00'
                                        }
                                        onChange={(val: string) => {
                                            setDayHours((prevState: any) => ({
                                                ...prevState,
                                                tu_to: val,
                                            }));
                                        }}
                                        readOnly={readOnly}
                                    />
                                </Hour>
                                <Time>
                                    <Icon className="fal fa-clock"></Icon>
                                    {tu_Diff}h
                                </Time>
                            </HourContent>
                        </List>
                    </div>
                    <div
                        className={`hours-item ${
                            !dayHours?.we_on || readOnly ? 'hours-item--disabled' : ''
                        }`}>
                        <List>
                            <WeekContent>
                                <Switch
                                    value={dayHours?.we_on}
                                    onChange={(val: boolean) => {
                                        setDayHours((prevState: any) => ({
                                            ...prevState,
                                            we_on: val,
                                        }));
                                    }}
                                    disabled={readOnly}
                                />
                                <WeekName>Wednesday</WeekName>
                            </WeekContent>

                            <HourContent className="hours-item--details">
                                <Hour>
                                    <Select
                                        options={businesHours}
                                        value={
                                            dayHours?.we_from
                                                ? moment(
                                                      moment().format() + ' ' + dayHours?.we_from,
                                                      'YYYY-MM-DD HH:mm:ss'
                                                  ).format(
                                                      _userData.user.business_id.time_select ===
                                                          '12'
                                                          ? 'hh:mm a'
                                                          : 'HH:mm'
                                                  )
                                                : '09:00'
                                        }
                                        onChange={(val: string) => {
                                            setDayHours((prevState: any) => ({
                                                ...prevState,
                                                we_from: val,
                                            }));
                                        }}
                                        readOnly={readOnly}
                                    />
                                </Hour>
                                <Hour>
                                    <Select
                                        options={businesHours}
                                        value={
                                            dayHours?.we_to
                                                ? moment(
                                                      moment().format() + ' ' + dayHours?.we_to,
                                                      'YYYY-MM-DD HH:mm:ss'
                                                  ).format(
                                                      _userData.user.business_id.time_select ===
                                                          '12'
                                                          ? 'hh:mm a'
                                                          : 'HH:mm'
                                                  )
                                                : '06:00'
                                        }
                                        onChange={(val: string) => {
                                            setDayHours((prevState: any) => ({
                                                ...prevState,
                                                we_to: val,
                                            }));
                                        }}
                                        readOnly={readOnly}
                                    />
                                </Hour>
                                <Time>
                                    <Icon className="fal fa-clock"></Icon>
                                    {we_Diff}h
                                </Time>
                            </HourContent>
                        </List>
                    </div>
                    <div
                        className={`hours-item ${
                            !dayHours?.th_on || readOnly ? 'hours-item--disabled' : ''
                        }`}>
                        <List>
                            <WeekContent>
                                <Switch
                                    value={dayHours?.th_on}
                                    onChange={(val: boolean) => {
                                        setDayHours((prevState: any) => ({
                                            ...prevState,
                                            th_on: val,
                                        }));
                                    }}
                                    disabled={readOnly}
                                />
                                <WeekName>Thursday</WeekName>
                            </WeekContent>

                            <HourContent className="hours-item--details">
                                <Hour>
                                    <Select
                                        options={businesHours}
                                        value={
                                            dayHours?.th_from
                                                ? moment(
                                                      moment().format() + ' ' + dayHours?.th_from,
                                                      'YYYY-MM-DD HH:mm:ss'
                                                  ).format(
                                                      _userData.user.business_id.time_select ===
                                                          '12'
                                                          ? 'hh:mm a'
                                                          : 'HH:mm'
                                                  )
                                                : '09:00'
                                        }
                                        onChange={(val: string) => {
                                            setDayHours((prevState: any) => ({
                                                ...prevState,
                                                th_from: val,
                                            }));
                                        }}
                                        readOnly={readOnly}
                                    />
                                </Hour>
                                <Hour>
                                    <Select
                                        options={businesHours}
                                        value={
                                            dayHours?.th_to
                                                ? moment(
                                                      moment().format() + ' ' + dayHours?.th_to,
                                                      'YYYY-MM-DD HH:mm:ss'
                                                  ).format(
                                                      _userData.user.business_id.time_select ===
                                                          '12'
                                                          ? 'hh:mm a'
                                                          : 'HH:mm'
                                                  )
                                                : '06:00'
                                        }
                                        onChange={(val: string) => {
                                            setDayHours((prevState: any) => ({
                                                ...prevState,
                                                th_to: val,
                                            }));
                                        }}
                                        readOnly={readOnly}
                                    />
                                </Hour>
                                <Time>
                                    <Icon className="fal fa-clock"></Icon>
                                    {th_Diff}h
                                </Time>
                            </HourContent>
                        </List>
                    </div>
                    <div
                        className={`hours-item ${
                            !dayHours?.fr_on || readOnly ? 'hours-item--disabled' : ''
                        }`}>
                        <List>
                            <WeekContent>
                                <Switch
                                    value={dayHours?.fr_on}
                                    onChange={(val: boolean) => {
                                        setDayHours((prevState: any) => ({
                                            ...prevState,
                                            fr_on: val,
                                        }));
                                    }}
                                    disabled={readOnly}
                                />
                                <WeekName>Friday</WeekName>
                            </WeekContent>

                            <HourContent className="hours-item--details">
                                <Hour>
                                    <Select
                                        options={businesHours}
                                        value={
                                            dayHours?.fr_from
                                                ? moment(
                                                      moment().format() + ' ' + dayHours?.fr_from,
                                                      'YYYY-MM-DD HH:mm:ss'
                                                  ).format(
                                                      _userData.user.business_id.time_select ===
                                                          '12'
                                                          ? 'hh:mm a'
                                                          : 'HH:mm'
                                                  )
                                                : '09:00'
                                        }
                                        onChange={(val: string) => {
                                            setDayHours((prevState: any) => ({
                                                ...prevState,
                                                fr_from: val,
                                            }));
                                        }}
                                        readOnly={readOnly}
                                    />
                                </Hour>
                                <Hour>
                                    <Select
                                        options={businesHours}
                                        value={
                                            dayHours?.fr_to
                                                ? moment(
                                                      moment().format() + ' ' + dayHours?.fr_to,
                                                      'YYYY-MM-DD HH:mm:ss'
                                                  ).format(
                                                      _userData.user.business_id.time_select ===
                                                          '12'
                                                          ? 'hh:mm a'
                                                          : 'HH:mm'
                                                  )
                                                : '06:00'
                                        }
                                        onChange={(val: string) => {
                                            setDayHours((prevState: any) => ({
                                                ...prevState,
                                                fr_to: val,
                                            }));
                                        }}
                                        readOnly={readOnly}
                                    />
                                </Hour>
                                <Time>
                                    <Icon className="fal fa-clock"></Icon>
                                    {fr_Diff}h
                                </Time>
                            </HourContent>
                        </List>
                    </div>
                    <div
                        className={`hours-item ${
                            !dayHours?.sa_on || readOnly ? 'hours-item--disabled' : ''
                        }`}>
                        <List>
                            <WeekContent>
                                <Switch
                                    value={dayHours?.sa_on}
                                    onChange={(val: boolean) => {
                                        setDayHours((prevState: any) => ({
                                            ...prevState,
                                            sa_on: val,
                                        }));
                                    }}
                                    disabled={readOnly}
                                />
                                <WeekName>Saturday</WeekName>
                            </WeekContent>

                            <HourContent className="hours-item--details">
                                <Hour>
                                    <Select
                                        options={businesHours}
                                        value={
                                            dayHours?.sa_from
                                                ? moment(
                                                      moment().format() + ' ' + dayHours?.sa_from,
                                                      'YYYY-MM-DD HH:mm:ss'
                                                  ).format(
                                                      _userData.user.business_id.time_select ===
                                                          '12'
                                                          ? 'hh:mm a'
                                                          : 'HH:mm'
                                                  )
                                                : '09:00'
                                        }
                                        onChange={(val: string) => {
                                            setDayHours((prevState: any) => ({
                                                ...prevState,
                                                sa_from: val,
                                            }));
                                        }}
                                        readOnly={readOnly}
                                    />
                                </Hour>
                                <Hour>
                                    <Select
                                        options={businesHours}
                                        value={
                                            dayHours?.sa_to
                                                ? moment(
                                                      moment().format() + ' ' + dayHours?.sa_to,
                                                      'YYYY-MM-DD HH:mm:ss'
                                                  ).format(
                                                      _userData.user.business_id.time_select ===
                                                          '12'
                                                          ? 'hh:mm a'
                                                          : 'HH:mm'
                                                  )
                                                : '06:00'
                                        }
                                        onChange={(val: string) => {
                                            setDayHours((prevState: any) => ({
                                                ...prevState,
                                                sa_to: val,
                                            }));
                                        }}
                                        readOnly={readOnly}
                                    />
                                </Hour>
                                <Time>
                                    <Icon className="fal fa-clock"></Icon>
                                    {sa_Diff}h
                                </Time>
                            </HourContent>
                        </List>
                    </div>
                    <div
                        className={`hours-item ${
                            !dayHours?.su_on || readOnly ? 'hours-item--disabled' : ''
                        }`}>
                        <List>
                            <WeekContent>
                                <Switch
                                    value={dayHours?.su_on}
                                    onChange={(val: boolean) => {
                                        setDayHours((prevState: any) => ({
                                            ...prevState,
                                            su_on: val,
                                        }));
                                    }}
                                    disabled={readOnly}
                                />
                                <WeekName>Sunday</WeekName>
                            </WeekContent>

                            <HourContent className="hours-item--details">
                                <Hour>
                                    <Select
                                        options={businesHours}
                                        value={
                                            dayHours?.su_from
                                                ? moment(
                                                      moment().format() + ' ' + dayHours?.su_from,
                                                      'YYYY-MM-DD HH:mm:ss'
                                                  ).format(
                                                      _userData.user.business_id.time_select ===
                                                          '12'
                                                          ? 'hh:mm a'
                                                          : 'HH:mm'
                                                  )
                                                : '09:00'
                                        }
                                        onChange={(val: string) => {
                                            setDayHours((prevState: any) => ({
                                                ...prevState,
                                                su_from: val,
                                            }));
                                        }}
                                        readOnly={readOnly}
                                    />
                                </Hour>
                                <Hour>
                                    <Select
                                        options={businesHours}
                                        value={
                                            dayHours?.su_to
                                                ? moment(
                                                      moment().format() + ' ' + dayHours?.su_to,
                                                      'YYYY-MM-DD HH:mm:ss'
                                                  ).format(
                                                      _userData.user.business_id.time_select ===
                                                          '12'
                                                          ? 'hh:mm a'
                                                          : 'HH:mm'
                                                  )
                                                : '06:00'
                                        }
                                        onChange={(val: string) => {
                                            setDayHours((prevState: any) => ({
                                                ...prevState,
                                                su_to: val,
                                            }));
                                        }}
                                        readOnly={readOnly}
                                    />
                                </Hour>
                                <Time>
                                    <Icon className="fal fa-clock"></Icon>
                                    {su_Diff}h
                                </Time>
                            </HourContent>
                        </List>
                    </div>
                    {!readOnly && (
                        <CardFooter>
                            <Button
                                disabled={isSubmitting}
                                type="submit"
                                bgtype="secondary"
                                label="Save"></Button>
                        </CardFooter>
                    )}
                </Form>
            )}
            {/* ))} */}
        </>
    );
};
const List = styled.div`
    display: flex;
    align-items: center;
    ${media.xs`
  display: block;
  `}
    ${media.sm`
  display: block;
  `}
    ${media.md`
    display: block;
  `}
    ${media.lg`
    display: flex;
  `}
`;
const WeekContent = styled.div`
    display: flex;
    align-items: center;
    // margin-right: 2rem;
    ${media.xs`
    margin-bottom: 1rem;
  `}
    ${media.sm`
    margin-bottom: 1rem;
  `}
    ${media.md`
    margin-bottom: 1rem;
  `}
    ${media.lg`
    margin-bottom: 0rem;
    `}
`;
const WeekName = styled.div`
    width: 6.42rem;
    font-weight: 500 !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    padding-left: 1rem !important;
`;
const HourContent = styled.div`
    display: flex;
    align-items: center;
`;
const Hour = styled.div`
    width: 9.28rem;
    margin-right: 0.75rem !important;
    ${media.xs`
    width: 7.28rem;
`}
    ${media.sm`
width: 7.28rem;
`}
${media.md`
width: 9.28rem;
`}
${media.lg`
width: 9.28rem;
`}
${media.xl`
width: 9.28rem;
`}
`;
const Time = styled.div`
    color: rgba(223, 226, 230) !important;
    font-weight: 300 !important;
    font-size: 0.9rem !important;
`;
const Icon = styled.i`
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
    margin-right: 0.5rem !important;
`;
const CardFooter = styled.div`
    margin-top: 1.5rem !important;
`;
const Form = styled.form`
    @media screen and (min-width: 1px) and (max-width: 900px) {
        padding: 1rem;
    }
`;
export default WorkingHours;
