import { Layout } from '@components/common';
import { TeamOptions } from '@components/team';

const Teams = () => {
    return (
        <>
            <TeamOptions />
        </>
    );
};

Teams.Layout = Layout;
export default Teams;
