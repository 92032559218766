import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import { Input, Select } from '@components/common';
import { useState } from 'react';
interface CardFormProps {
    cardMonth: string;
    cardYear: string;
    onUpdateState(state: string, value: any): void;
    cardNumberRef: any;
    cardHolderRef: any;
    cardDateRef: any;
}
const currentYear = new Date().getFullYear();
const monthsArr = Array.from({ length: 12 }, (x, i) => {
    const month = i + 1;
    return month <= 9 ? '0' + month : month;
});
const yearsArr = Array.from({ length: 9 }, (_x, i) => currentYear + i);
const CardForm = ({
    cardMonth,
    cardYear,
    onUpdateState,
    cardNumberRef,
    cardHolderRef,
    cardDateRef,
}: CardFormProps) => {
    const [cardNumber, setCardNumber] = useState('');
    const handleFormChange = ({ name, value }: { name: string; value: any }) => {
        onUpdateState(name, value);
    };
    const onCardNumberChange = (name: string, value: string) => {
        let cardNumber = value;
        value = value.replace(/\D/g, '');
        if (/^3[47]\d{0,13}$/.test(value)) {
            cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ');
        } else if (/^3(?:0[0-5]|[68]\d)\d{0,11}$/.test(value)) {
            // diner's club, 14 digits
            cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ');
        } else if (/^\d{0,16}$/.test(value)) {
            // regular cc number, 16 digits
            cardNumber = value
                .replace(/(\d{4})/, '$1 ')
                .replace(/(\d{4}) (\d{4})/, '$1 $2 ')
                .replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ');
        }
        setCardNumber(cardNumber.trimRight());
        onUpdateState(name, cardNumber);
    };
    return (
        <Card>
            <InputGroup>
                <Input
                    label="Card Number"
                    value={cardNumber}
                    maxLength={19}
                    allowNumber={true}
                    name="cardNumber"
                    onChange={({ name, value }: { name: string; value: string }) => {
                        onCardNumberChange(name, value);
                    }}
                />
            </InputGroup>
            <InputGroup>
                <Input label="Name" name="cardHolder" onChange={handleFormChange} />
            </InputGroup>
            <Wrapper>
                <Row>
                    <Col lg={4}>
                        <InputGroup>
                            <Select
                                label="MM"
                                maxLength={2}
                                allowNumber={true}
                                onChange={(value: string) => {
                                    onUpdateState('cardMonth', value);
                                }}
                                options={monthsArr.map(val => {
                                    return {
                                        label: val.toString(),
                                    };
                                })}
                            />
                        </InputGroup>
                    </Col>
                    <Col lg={4}>
                        <InputGroup>
                            <Select
                                label="YYYY"
                                maxLength={4}
                                allowNumber={true}
                                onChange={(value: string) => {
                                    onUpdateState('cardYear', value);
                                }}
                                options={yearsArr.map(val => {
                                    return {
                                        label: val.toString(),
                                    };
                                })}
                            />
                        </InputGroup>
                    </Col>
                    <Col lg={4}>
                        <InputGroup>
                            <Input
                                label="CVV"
                                name="cardCVV"
                                maxLength={4}
                                allowNumber={true}
                                onFocus={() => onUpdateState('isCardFlipped', true)}
                                onBlur={() => onUpdateState('isCardFlipped', false)}
                                onChange={handleFormChange}
                            />
                        </InputGroup>
                    </Col>
                </Row>
            </Wrapper>
        </Card>
    );
};
const Wrapper = styled(Container)`
    padding: 0rem;
`;
const Card = styled.div`
    margin-top: 2.5rem !important;
`;
const InputGroup = styled.div`
    margin-bottom: 1.71rem;
    @media screen and (max-width: 480px) {
        margin-bottom: 0.71rem;
    }
`;
export default CardForm;
