import React, { useCallback, useEffect, useState } from 'react';
import Compressor from 'compressorjs';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import * as Yup from 'yup';

import { teamActions } from 'actions/team/teamMember';
import Button from '@components/Button';
import { Input, useSnackbar } from '@components/common';
import ProfileImageCropperModal from '@components/common/ProfileImageCropperModal';
import { api } from 'helpers/auth-axios';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from 'hooks/redux';
import useRouter from 'hooks/router';
import { ITeam } from 'interfaces/team/teamMember.interface';

const validationSchema = Yup.object().shape({
    name: Yup.string().required('This field is required'),
    email: Yup.string().required('This field is required'),
    // updatePassword: Yup.string().required('This field is required'),
});

const EditTeamMembers = () => {
    const [openSnackbar] = useSnackbar();
    const router = useRouter();
    const [showCropperModal, setShowCropperModal] = useState<boolean>(false);
    const [selectedImage, setSelectedImage] = useState<any>(); // this is the image before cropping
    const [profileImage, setProfileImage] = useState<any>(null); // this is after cropping
    const [uploadImage, setUploadImage] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const { handleSubmit, setValue, getValues, formState, trigger } = useForm<ITeam>({
        resolver: yupResolver(validationSchema),
    });
    const { errors, isSubmitting } = formState;
    const [team, setTeam] = useState<ITeam>();
    async function onChange({ name, value }: { name: string; value: string }) {
        if (name === 'phone') {
            let phone = value;
            if (phone.length <= 3) {
                phone = phone.replace(/[- .]/g, '');
                // phone = phone.slice(0, 3) + '-';
            } else if (phone.length <= 7) {
                phone = phone.replace(/[- .]/g, '');
                phone = phone.slice(0, 3) + '-' + phone.slice(3, 6);
            } else if (phone.length >= 7) {
                phone = phone.replace(/[- .]/g, '');
                phone = phone.slice(0, 3) + '-' + phone.slice(3, 6) + '-' + phone.slice(6);
            }
            setValue(name as never, phone as never);
            await trigger(name as never);
        } else if (name === 'email') {
            setValue(name as never, value?.toLowerCase() as never);
            await trigger(name as never);
        } else {
            setValue(name as never, value as never);
            await trigger(name as never);
        }
    }

    const onSubmit = (data: ITeam) => {
        const imgUpdateWithData = {
            ...data,
            newPhoto: profileImage,
        };
        api.put(`/team/${router.query.id as string}`, {
            ...data,
            newPhoto: uploadImage == true ? profileImage : null,
        })
            .then((res: any) => {
                if (res) {
                    openSnackbar('Team Member updated sucessfully!');

                    router.navigate(`/team/team-list/${res.data._id}`);
                }
            })
            .catch((e: any) => {
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };
    const getTeamById = () => {
        api.get(`/team/${router.query.id as string}`)
            .then((team: any) => {
                setTeam(team.data);
                setValue('name', team.data.name);
                setValue('email', team.data.email);
                setValue('phone', team.data.phone);
                setValue('updatePassword', '');
                setValue('position', team.data.position);
                setValue('description', team.data.description);
                setProfileImage(team.data.photo);
            })
            .catch((e: any) => {
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    useEffect(() => {
        getTeamById();
        setTimeout(() => {
            setValue('name', '');
            setValue('email', '');
            // setValue('updatePassword', '');
        });
    }, [setValue]);

    const getNormalizedFile = (file: any) => {
        return new Promise((resolve, reject) => {
            new Compressor(file, {
                maxWidth: 750,
                maxHeight: 750,
                success(normalizedFile) {
                    resolve(normalizedFile);
                },
                error(error) {
                    reject(error);
                },
            });
        });
    };

    const readFile = useCallback((file: any) => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result);
                getNormalizedFile(file)
                    .then((normalizedFile: any) => reader.readAsDataURL(normalizedFile))
                    .catch((error: any) => reject(error));
            } catch (error) {
                reject(error);
            }
        });
    }, []);

    const handleCapture = async (e: any) => {
        try {
            e.preventDefault();
            if (e.target?.files?.length > 0) {
                const file = e.target.files[0];
                const imageDataUrl = await readFile(file);
                setSelectedImage(imageDataUrl);
                e.target.value = null;
                setShowCropperModal(true);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <MainContainer>
                <Row>
                    <Col lg={12}>
                        {team && (
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <TitleContainer>
                                    <TitleBody>
                                        <CustomLink to={`/team/team-list/${router.query.id}`}>
                                            <Icon className="fal fa-times"></Icon>
                                        </CustomLink>
                                        <Title>Edit Team Member</Title>
                                    </TitleBody>
                                    <AddButton>
                                        <Button
                                            bgtype={'secondary'}
                                            type={'submit'}
                                            disabled={isSubmitting}
                                            label="Save"></Button>
                                    </AddButton>
                                </TitleContainer>
                                <Card>
                                    <FormContent>
                                        <Left>
                                            <InfoTitle>General Info</InfoTitle>
                                            <Circle>
                                                <CircleLabel
                                                    htmlFor="faceImage"
                                                    aria-label="upload picture">
                                                    <Data
                                                        src={
                                                            uploadImage == true
                                                                ? profileImage
                                                                : `https://profile-images-barberone-s3.s3.amazonaws.com/${profileImage}`
                                                        }></Data>
                                                </CircleLabel>
                                                <FileInput
                                                    accept="image/jpeg, image/png"
                                                    id="faceImage"
                                                    type="file"
                                                    onChange={handleCapture}
                                                />
                                            </Circle>
                                            <InputField>
                                                <Input
                                                    label="Name"
                                                    name="name"
                                                    value={team.name}
                                                    onChange={onChange}
                                                    error={errors.name as any}
                                                />
                                            </InputField>
                                            <InputField>
                                                <Input
                                                    label="Email"
                                                    name="email"
                                                    // readOnly={true}
                                                    value={getValues('email')?.toLowerCase()}
                                                    onChange={onChange}
                                                    error={errors.email as any}
                                                />
                                            </InputField>
                                            <InputField>
                                                <Input
                                                    label="Phone"
                                                    name="phone"
                                                    value={team.phone}
                                                    onChange={onChange}
                                                    allowPhoneNumberOnly={true}
                                                    maxLength={12}
                                                />
                                            </InputField>
                                            <InputField>
                                                <Input
                                                    label="Password"
                                                    type="password"
                                                    name="updatePassword"
                                                    value={getValues('updatePassword')}
                                                    onChange={onChange}
                                                    // error={errors.updatePassword as any}
                                                />
                                            </InputField>
                                            <InputField>
                                                <Input
                                                    label="Title"
                                                    name="position"
                                                    value={team.position}
                                                    onChange={onChange}
                                                />
                                            </InputField>
                                            <InputField>
                                                <Input
                                                    label="Short Description"
                                                    name="description"
                                                    value={team.description}
                                                    onChange={onChange}
                                                />
                                            </InputField>
                                        </Left>
                                    </FormContent>
                                </Card>
                            </Form>
                        )}
                    </Col>
                </Row>
                <div style={{ position: 'absolute' }}>
                    <ProfileImageCropperModal
                        showModal={showCropperModal}
                        imgURL={selectedImage}
                        onModalClose={() => setShowCropperModal(false)}
                        onSaveHandler={(croppedImage: any) => {
                            setProfileImage(croppedImage);
                            setUploadImage(true);
                        }}
                    />
                </div>
            </MainContainer>
        </>
    );
};

const MainContainer = styled(Container)`
    width: 100%;
    padding: 1.71rem;
`;

const Form = styled.form``;
const TitleContainer = styled.div`
display: flex;
justify-content: space-between;
margin-bottom: 1.75rem
align-items: center;
`;
const TitleBody = styled.div`
    display: flex;
    align-items: center;
`;
const CustomLink = styled(Link)`
    margin-right: 1.5rem /* 24px */;
`;

const Icon = styled.i`
    font-size: 2.25rem /* 36px */;
    line-height: 2.5rem /* 40px */;
    ${media.xs`
    font-size: 1.5rem
    `}
`;

const Title = styled.div`
    font-size: 1.78rem;
    font-weight: 600;
    ${media.xs`
  font-size: 1.3rem
`}
`;
const AddButton = styled.div``;
const Card = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: border-box;
    border-radius: 0.42rem;
    box-shadow: 0rem 0rem 2.14rem 0rem rgb(82 63 105 / 5%);
    border: 0;
    margin-top: 1.5rem;
`;
const FormContent = styled.div`
    height: 100%;
    display: flex;
    ${media.xs`
display: block !important;
`}
    ${media.sm`
  display: block;
`}
  ${media.md`
  display: flex;
`}
${media.lg`
  display: flex;
`}
`;
const Left = styled.div`
    padding: 1.71rem;
    border-right: 1px solid #e5e7eb;
    flex-basis: 28.57rem;
`;
const InfoTitle = styled.div`
    margin-bottom: 1rem /* 16px */;
    font-size: 1.125rem /* 18px */;
    line-height: 1.75rem /* 28px */;
    font-weight: 500;
`;
const Circle = styled.div`
    justify-content: center !important;
    display: flex !important;
    margin-bottom: 3.5rem /* 24px */;
`;
const CircleLabel = styled.label`
    display: inline-block;
    flex-shrink: 0;
    position: relative;
    border-radius: 0.42rem;
    width: 3.14rem;
    height: 3.14rem;
`;
const Data = styled.img`
    background: linear-gradient(-135deg, #eee, #dddfe1);
    color: #7e8299;
    width: 5.42rem;
    height: 5.42rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 0.42rem;
    font-size: 1.21rem;
    text-transform: capitalize !important;
    border-radius: 50%;
    cursor: pointer;
`;
const InputField = styled.div`
    margin-bottom: 1.71rem;
`;
const FileInput = styled.input`
    display: none;
`;
export default EditTeamMembers;
