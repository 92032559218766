import React, {
    forwardRef,
    useCallback,
    useRef,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
} from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
    IServiceProvider,
    IGetServiceProviderResponse,
} from 'interfaces/team/serviceProvider.interface';
import useRouter from 'hooks/router';
import _ from 'lodash';
import SearchField from '@components/common/Search/SearchField';
import { api } from 'helpers/auth-axios';
import { LoaderOverlay, useSnackbar } from '@components/common';
import Button from '@components/Button';
import { palette } from '../../../../styled/common';

interface IProps {
    hasId: any;
}
const ServiceProviderList = forwardRef(({}, ref) => {
    useImperativeHandle(ref, () => ({
        getServiceProvider() {
            getAllServiceProvider();
        },
    }));

    const navigate = useNavigate();
    const router = useRouter();
    const [pageNumber, setPageNumber] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [query, setQuery] = useState<any>(null);
    const [value, setValue] = useState<any>('');
    const [initial, setInitial] = useState(false); // used to check whether data is initially on store
    const observer = useRef<IntersectionObserver>();
    const [serviceProvider, setServiceProvider] = useState<IGetServiceProviderResponse>();
    const [serviceProviderList, setServiceProviderList] = useState<IServiceProvider[]>([]);
    const pageSize = 20;
    const [total, setTotal] = useState(0);
    const [openSnackbar] = useSnackbar();
    const loaderRef = useCallback(
        (node: any) => {
            if (loading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(entries => {
                // if (entries[0].isIntersecting && hasMore) {
                //     setPageNumber(prevPageNumber => prevPageNumber + 1);
                // }
            });
            if (node) observer.current.observe(node);
        },
        [loading, hasMore]
    );
    const getAllServiceProvider = () => {
        setLoading(true);
        api.get(`/serviceProvider?page=${pageNumber}&pageSize=${pageSize}`)
            .then((res: any) => {
                setServiceProvider(res.data);
                setServiceProviderList(
                    checkServiceProviderIndexesAndSort(res.data.serviceProvider)
                );
                setTotal(res?.data?.total);
                if (
                    res.data.serviceProvider.length &&
                    router.query[Object.keys(router.query)[0]] === '' &&
                    window.innerWidth > 767
                ) {
                    navigate(`/team/service-provider-list/${res.data.serviceProvider[0]._id}`);
                }
                setHasMore(res.data.serviceProvider.length > 0);
                setLoading(false);
            })
            .catch((e: any) => {
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };
    const loadServiceProvider = () => {
        setLoading(true);
        api.get(`/serviceProvider?page=${pageNumber}&pageSize=${pageSize}`)
            .then((res: any) => {
                setServiceProviderList((prev: any) =>
                    checkServiceProviderIndexesAndSort([...prev, ...res.data.serviceProvider])
                );
                setServiceProvider(res.data);
                setTotal(res.data.total);
                setHasMore(res.data.serviceProvider.length > 0);
                setLoading(false);
            })
            .catch((e: any) => {
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };
    useEffect(() => {
        if (pageNumber > 1) {
            loadServiceProvider();
        }
    }, [pageNumber]);
    const searchServiceProvider = (query: string) => {
        let value = query;
        if (Number.isInteger(parseInt(value)) === true) {
            if (value.length <= 3) {
                value = value.replace(/[- .]/g, '');
                // value = value + '-';
            } else if (value.length <= 7) {
                value = value.replace(/[- .]/g, '');
                value = value.slice(0, 3) + '-' + value.slice(3, 6);
            } else if (value.length >= 7) {
                value = value.replace(/[- .]/g, '');
                value = value.slice(0, 3) + '-' + value.slice(3, 6) + '-' + value.slice(6);
            }
            setLoading(true);
            api.get(`/serviceProvider?page=${pageNumber}&pageSize=${pageSize}&query=${value}`)
                .then((res: any) => {
                    setLoading(false);
                    setServiceProviderList(
                        checkServiceProviderIndexesAndSort(res.data.serviceProvider)
                    );

                    setServiceProvider(res.data);
                    setHasMore(res.data.serviceProvider.length > 0);
                })
                .catch((e: any) => {
                    if (e?.response) {
                        openSnackbar(e?.response?.data?.message);
                    }
                });
        } else {
            setLoading(true);
            api.get(`/serviceProvider?page=${pageNumber}&pageSize=${pageSize}&query=${value}`)
                .then((res: any) => {
                    setLoading(false);
                    setServiceProviderList(
                        checkServiceProviderIndexesAndSort(res.data.serviceProvider)
                    );

                    setServiceProvider(res.data);
                    setHasMore(res.data.serviceProvider.length > 0);
                })
                .catch((e: any) => {
                    if (e?.response) {
                        openSnackbar(e?.response?.data?.message);
                    }
                });
        }
    };

    useEffect(() => {
        if (serviceProvider !== undefined && serviceProvider.total !== 0) {
            setPageNumber(serviceProvider && serviceProvider.page);
            setValue((serviceProvider !== undefined && serviceProvider.query) ?? null);
            setQuery((serviceProvider !== undefined && serviceProvider.query) ?? null);
            setHasMore(true);
            setInitial(true);
            return;
        }
        getAllServiceProvider();
    }, []);
    const searchServiceProviderDebounce = useMemo(() => {
        return _.debounce((searchVal: string) => {
            setHasMore(true);
            setQuery(searchVal);
            setPageNumber(1);
            searchServiceProvider(searchVal);
        }, 300);
    }, []);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        searchServiceProviderDebounce(event.target.value);
    };
    const loadMore = () => {
        setPageNumber((prevPage: any) => prevPage + 1);
    };

    /*
     * Drag-and-drop reordering functions / routines
     */

    const checkServiceProviderIndexesAndSort = (list: IServiceProvider[]) => {
        // order services by their currently set index
        list.sort((provider1, provider2) => provider1.index - provider2.index);
        // go over array again, any value less than 0 should be set to next available
        // index and moved to the end of the list
        while (list[0]?.index < 0) {
            const nextAvailableIndex = list[list.length - 1].index + 1;
            // remove element from beginning of list
            const [elem] = list.splice(0, 1);
            // update element's index
            elem.index = nextAvailableIndex;
            // add element to end of list
            list.push(elem);
        }

        return list;
    };

    // a function for deep-copying the services list (so any modifications to
    // the copy don't mess with the original, or the react-beautiful-dnd library)
    const deepCopyServiceProviderList = (list: IServiceProvider[]) => {
        let listCopy = [];
        for (let i = 0; i < list.length; i++) {
            const elem: IServiceProvider = { ...list[i] };
            listCopy.push(elem);
        }
        return listCopy;
    };

    // update list on drop
    const handleDrop = (droppedItem: any) => {
        // ignore drop outside droppable container
        if (!droppedItem.destination) return;
        // create a temporary list copy
        let updatedList = deepCopyServiceProviderList(serviceProviderList);
        // remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // update all indexes
        for (let i = 0; i < updatedList.length; i++) {
            updatedList[i].index = i;
        }
        // update state
        setServiceProviderList(updatedList);
        // send updates to backend for each service provider
        updatedList.forEach((provider: any) => {
            api.put(`/serviceProvider/service_provider/${provider._id}`, {
                index: provider.index,
            })
                .then((res: any) => {})
                .catch((e: any) => {
                    if (e?.response) {
                        openSnackbar(e?.response?.data?.message);
                    }
                });
        });
    };

    return (
        <>
            <Wrapper>
                {loading && <LoaderOverlay />}
                <CustomRow>
                    <CustomCol md={12} lg={12}>
                        <HeadingWrapper>
                            <CloseButton to="/team">
                                <i className="fal fa-long-arrow-left"></i>
                            </CloseButton>
                            <Header>Service Providers</Header>
                        </HeadingWrapper>
                        <OnSamllViewPort hasId={router.query['*']}>
                            <SearchField
                                value={value}
                                handleChange={handleChange}
                                name={`Service Provider`}
                            />

                            <DragDropContext onDragEnd={handleDrop}>
                                <Droppable droppableId="list-container">
                                    {provided => (
                                        <ProvidersList
                                            className="list-container"
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}>
                                            {serviceProviderList?.length ? (
                                                serviceProviderList.map(
                                                    (
                                                        serviceProvider: IServiceProvider,
                                                        index: any
                                                    ) => {
                                                        const queryId =
                                                            router.query[
                                                                Object.keys(router.query)[0]
                                                            ];
                                                        return (
                                                            <Draggable
                                                                key={serviceProvider._id}
                                                                draggableId={serviceProvider._id}
                                                                index={index}>
                                                                {provided => (
                                                                    <Container
                                                                        className="item-container"
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}>
                                                                        <Row>
                                                                            <Col
                                                                                style={{
                                                                                    padding: '0rem',
                                                                                }}
                                                                                lg={12}
                                                                                onClick={() =>
                                                                                    navigate(
                                                                                        `/team/service-provider-list/${serviceProvider._id}`
                                                                                    )
                                                                                }>
                                                                                <div
                                                                                    className={`${
                                                                                        (queryId as string) ===
                                                                                        serviceProvider._id
                                                                                            ? 'isActivelist'
                                                                                            : ''
                                                                                    }`}>
                                                                                    <ServiceProviderListItem>
                                                                                        <InfoContainer>
                                                                                            <ProfilePic
                                                                                                src={`https://profile-images-barberone-s3.s3.amazonaws.com/${serviceProvider?.photo}`}></ProfilePic>
                                                                                            <Name>
                                                                                                {
                                                                                                    serviceProvider.name
                                                                                                }
                                                                                            </Name>
                                                                                        </InfoContainer>
                                                                                        <i
                                                                                            className="fal fa-bars fa-lg"
                                                                                            {...provided.dragHandleProps}></i>
                                                                                    </ServiceProviderListItem>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </Container>
                                                                )}
                                                            </Draggable>
                                                        );
                                                    }
                                                )
                                            ) : (
                                                <NotFound>No serviceProvider found</NotFound>
                                            )}
                                            <div ref={loaderRef}></div>
                                        </ProvidersList>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            {query
                                ? ''
                                : (total >
                                      (pageNumber === 1 ? pageNumber + 0 : pageNumber + 1) *
                                          pageSize ||
                                      loading) && (
                                      <LoadMore>
                                          <Button
                                              bgtype={'secondary'}
                                              ifClicked={loadMore}
                                              disabled={loading}
                                              //   width="12rem !important"
                                              label={`Load more serviceProvider (${
                                                  total -
                                                  (pageNumber === 1
                                                      ? pageNumber + 0
                                                      : pageNumber + 1) *
                                                      pageSize
                                              })`}></Button>
                                      </LoadMore>
                                  )}
                            <Col>
                                <AddIcon to="/team/service-provider/create">
                                    <i className="fal fa-plus"></i>
                                </AddIcon>
                            </Col>
                        </OnSamllViewPort>
                    </CustomCol>
                </CustomRow>
            </Wrapper>
        </>
    );
});
const Wrapper = styled(Container)`
    flex: 0 0 25rem;
    margin-left: 0rem;

    ${media.xs`
    padding-top: 1rem;
    padding: auto;
    flex: 0 0 25rem;
    `}
    ${media.sm`
    margin-top: 1rem;
    flex: 0 0 25rem;
     background-color: white;
    `}
	${media.md`
    margin-top: 0rem;
    padding: 0;
    background-color: white;
     flex: 0 0 20rem;
    `}
	@media screen and (min-width: 1076px) and (max-width: 1140px) {
        flex: 0 0 20rem;
    }
    @media screen and (min-width: 1032px) and (max-width: 1075px) {
        flex: 0 0 18rem;
    }
    @media screen and (min-width: 992px) and (max-width: 1031px) {
        flex: 0 0 16rem;
    }

    ${media.lg`
    margin-top: 0rem;
    padding: 0;
    background-color: white;
    `}
    ${media.xl`
    margin-top: 0rem;
    padding: 0;
    background-color: white;
`}
`;
const CustomRow = styled(Row)`
    position: relative;
    margin: 0;
    ${media.xs`
    background-color: white;
`}
`;
const CustomCol = styled(Col)`
    padding: 0;
`;
const Header = styled.h1`
    font-size: 1.78rem;
    font-weight: 600;
`;
const HeadingWrapper = styled.div`
    padding: 1.42rem 0rem 0.85rem 1.71rem;
    display: flex;
    align-items: center;
    justify-content: start;
`;
const AddIcon = styled(Link)`
    position: fixed;
    bottom: 1.71rem;
    // right: 2.14rem;
    width: 3.42rem;
    height: 3.42rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1rem 1.71rem 0 rgb(0 0 0 / 28%);
    background: #0154ff;
    border-radius: 100%;
    color: #fff;
    font-size: 1.5rem;
`;

const CloseButton = styled(Link)`
    margin-right: 1.25rem /* 20px */;
    font-size: 1.875rem /* 30px */;
    line-height: 2.25rem /* 36px */;
`;
const ProvidersList = styled.div`
    margin-top: 1rem;
    height: 40rem;
    overflow: auto;
`;

const ServiceProviderListItem = styled.div`
    padding: 0.85rem 1.71rem;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.1rem solid ${palette.grey.lightest};
    cursor: pointer;
    &:hover {
        background-color: ${palette.grey.lightest};
    }
`;

const ProfilePic = styled.img`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.2rem;
    height: 3.2rem;
    overflow: auto;
    background: red;
    border-radius: 2rem;
    background: linear-gradient(-135deg, ${palette.grey.lightest}, ${palette.grey.lightest});
    color: ${palette.silver};
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Name = styled.div`
    padding: 0 0.85rem;
    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Details = styled.div`
    padding: 0 0.85rem;
    font-size: 0.7rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const OnSamllViewPort = styled.span<IProps>`
    display: block;
    ${media.xs`
	display: ${({ hasId }: any) => (hasId ? 'none' : 'block')};
	`}
    ${media.sm`
	display: ${({ hasId }: any) => (hasId ? 'none' : 'block')};
	`}
	${media.md`
    display: block;
	`}
	${media.lg`
    display: block;
	`}
`;
const LoadMore = styled.div`
    margin-top: 0.5rem;
    display: flex;
    justify-content: center;
`;
const NotFound = styled.div`
    display: flex;
    justify-content: center;
`;
export default ServiceProviderList;
