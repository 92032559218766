import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { serviceActions } from 'actions/service';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import s from './Checkout.module.scss';
import { IService } from 'interfaces/service.interface';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { api } from 'helpers/auth-axios';
interface IProps {
    selectedServices: IService[];
    onClose(): void;
    onSelect(services: IService[]): void;
}
const SelectServices = ({ selectedServices, onSelect }: IProps) => {
    const dispatch = useAppDispatch();
    const [selected, setSelected] = useState<IService[]>(selectedServices);
    // const { services } = useAppSelector(state => state.service);
    const [services, setServices] = useState<any[]>([]);
    const toggleSelect = (service: IService) => {
        if (selected?.find(item => item._id === service._id)) {
            let newSelected = selected.filter(item => item._id !== service._id);
            setSelected(newSelected);
            onSelect(newSelected);
        } else {
            setSelected([...selected, service]);
            onSelect([...selected, service]);
        }
    };
    useEffect(() => {
        if (!services.length)
            // dispatch(serviceActions.getServices());
            api.get('/services').then((res: any) => {
                if (res.data.length) {
                    setServices(res.data);
                }
            });
    }, []);
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    return (
        <>
            <Container>
                <Row>
                    {services.map((service, i) => (
                        <ServicesBody xs={12} sm={4} md={6} lg={4} key={i}>
                            <div
                                className={`${s.services_service} ${
                                    selected?.find(item => item._id === service._id)
                                        ? s.services_selectedService
                                        : ''
                                } service-color-${service.color}--bd`}>
                                <Services
                                    onClick={() => toggleSelect(service)}
                                    className={`service-color-${service.color}--bg`}>
                                    <Label>
                                        &nbsp;
                                        {_userData.user.business_id.currency}
                                        {service.price}
                                    </Label>
                                    <Name>{service.name}</Name>
                                    <ServiceDuration>{`${
                                        service.duration >= 60
                                            ? Math.floor(service.duration / 60) + 'h'
                                            : ''
                                    } ${service.duration % 60}min`}</ServiceDuration>
                                </Services>
                            </div>
                        </ServicesBody>
                    ))}
                </Row>
            </Container>
        </>
    );
};
const ServicesBody = styled(Col)`
    padding: 0rem 0rem 0.5rem 0.5rem;
`;
const Services = styled.div`
    border-radius: 0.5rem;
    padding: 26px 20px;
    position: relative;
    cursor: pointer;
`;
const Label = styled.div`
    background-color: #00be70;
    padding: 0.14rem 0.42rem 0.14rem 0.14rem;
    display: inline-block;
    border-radius: 3.57rem;
    font-size: 0.85rem;
    font-weight: 500;
    color: #fff;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0.42rem;
    right: 0.42rem;
`;
const Name = styled.div`
    font-weight: 500;
    font-size: 1.2rem;
    width: 195px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;
const ServiceDuration = styled.div`
    font-size: 0.9rem /* 14.4px */;
`;
export default SelectServices;
