import { api } from 'helpers/auth-axios';
import { IGetWorkingHours, IWorkingHours } from 'interfaces/workinghours.interface';

function create(user_id: string, workinghours: IWorkingHours): Promise<IWorkingHours> {
    return api.post(`/workinghours/${user_id}`, workinghours).then(res => {
        return res.data;
    });
}
function getWorkingHourse(user_id: string): Promise<any> {
    return api.get(`/workinghours/${user_id}`).then(res => {
        return res.data;
    });
}

function updateWorkingHours(id: string, workingHours: IWorkingHours): Promise<IWorkingHours> {
    return api.put(`/workinghours/${id}`, workingHours).then(res => {
        return res.data;
    });
}
export const WorkingHoursServices = {
    create,
    getWorkingHourse,
    updateWorkingHours,
};
