import { useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';
import styled from 'styled-components';

import { getCroppedImg } from 'utils/getCroppedImage';

const ProfileImageCropperModal = ({
    showModal,
    imgURL,
    onModalClose,
    onSaveHandler,
}: {
    showModal: boolean;
    imgURL: string;
    onModalClose(): void;
    onSaveHandler(croppedImage: any): void;
}) => {
    const [crop, setCrop] = useState<any>({ x: 2, y: 2 });
    const [zoom, setZoom] = useState<any>(1);
    const [croppedArea, setCroppedArea] = useState('');

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedArea(croppedAreaPixels);
    }, []);

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(imgURL, croppedArea, 0);
            return croppedImage;
        } catch (error) {
            console.error(error);
        }
    }, [croppedArea, imgURL]);

    return (
        <Wrapper
            style={{
                opacity: showModal ? 1 : 0,
                pointerEvents: showModal ? 'all' : 'none',
            }}>
            <div
                onClick={onModalClose}
                role="button"
                className="iu-modal-backdrop"
                style={{
                    display: showModal ? 'flex' : 'none',
                }}
            />
            <div className="iu-modal-content">
                <div style={{ width: '100%' }}>
                    <Cropper
                        image={imgURL}
                        crop={crop}
                        zoom={zoom}
                        aspect={1}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                    />
                </div>
                <Footer>
                    <Button onClick={onModalClose}>Dismiss</Button>
                    <Button
                        onClick={async () => {
                            onModalClose();
                            onSaveHandler(await showCroppedImage());
                        }}>
                        Save
                    </Button>
                </Footer>
            </div>
        </Wrapper>
    );
};

const Button = styled.button`
    color: #fff;
    font-weight: 600;
    display: block;
    padding-left: 6px;
    padding-right: 6px;
    border-radius: 0.375rem;
    background-color: #000;
    border: none;
    outline: none;
    height: 45px;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
    transition-duration: 500ms;
    &:hover {
        opacity: 0.75;
    }
    &:focus {
        border: none;
        outline: none;
    }
    &:disabled {
        opacity: 1;
        cursor: not-allowed;
    }
`;

const Footer = styled.footer`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    border-top: 1px;
    border-color: #000;
    width: 100%;
    background-color: #fff;
    left: 0;
    bottom: 0;
    padding: 4px 12px;
`;

const Wrapper = styled.div`
    position: relative;
    width: 100vw;
    height: 100%;
    .iu-modal-backdrop {
        background: rgba(0, 0, 0, 0.7);
        z-index: 13000;
        backdrop-filter: blur(0px);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .iu-modal-content {
        position: fixed;
        z-index: 15000;
        left: 50%;
        top: 50%;
        padding: 0;
        height: 80vh;
        width: 100%;
        max-width: 732px;
        overflow: hidden !important;
        background: #fff;
        transform: translate(-50%, -50%);
        & button {
            width: 135px;
        }
        & button:focus {
            background: transparent;
            color: transparent;
        }
        &::-webkit-scrollbar {
            width: 0.2rem;
            height: 0rem;
            border-radius: 10px;
        }
    }
    .iu-preview:hover {
        color: #2eff7b;
    }
`;

export default ProfileImageCropperModal;
