import {
    IAppointment,
    IGetAppointments,
    IUpdateAppointmentStatus,
} from 'interfaces/appointment.interface';
import { Dispatch } from 'redux';
import { appointmentService } from 'services';

export const appointmentConstants = {
    CREATE_REQUEST: 'APPOINTMENT_CREATE_REQUEST',
    CREATE_SUCCESS: 'APPOINTMENT_CREATE_SUCCESS',
    CREATE_FAILURE: 'APPOINTMENT_CREATE_FAILURE',

    GET_REQUEST: 'APPOINTMENT_GET_REQUEST',
    GET_SUCCESS: 'APPOINTMENT_GET_SUCCESS',
    GET_FAILURE: 'APPOINTMENT_GET_FAILURE',
};

const createAppointment =
    (appointmentData: any) =>
    (dispatch: Dispatch): Promise<IAppointment> => {
        dispatch({
            type: appointmentConstants.CREATE_REQUEST,
        });

        return appointmentService.create(appointmentData).then(appointment => {
            dispatch({
                type: appointmentConstants.CREATE_SUCCESS,
                appointment,
            });

            return appointment;
        });
    };

const getAppointments =
    (getAppointmentsData: IGetAppointments) =>
    (dispatch: Dispatch): Promise<IAppointment[]> => {
        dispatch({
            type: appointmentConstants.CREATE_REQUEST,
        });

        return appointmentService.getAppointments(getAppointmentsData).then(appointments => {
            dispatch({
                type: appointmentConstants.GET_SUCCESS,
                appointments,
            });

            return appointments;
        });
    };

const updateAppointmentStatus =
    (appointmentId: string, appointmentStatusUpdate: IUpdateAppointmentStatus) =>
    (dispatch: Dispatch): Promise<IAppointment> => {
        return appointmentService
            .updateAppointmentStatus(appointmentId, appointmentStatusUpdate)
            .then(appointment => {
                return appointment;
            });
    };

const rescheduleAppointment =
    (appointmentId: string, appointmentData: any) =>
    (dispatch: Dispatch): Promise<IAppointment> => {
        return appointmentService
            .rescheduleAppointment(appointmentId, appointmentData)
            .then(appointment => {
                return appointment;
            });
    };
const cancelAppointmentById =
    (appointmentId: string) =>
    (dispatch: Dispatch): Promise<IAppointment> => {
        return appointmentService.cancelAppointmentById(appointmentId).then(appointment => {
            return appointment;
        });
    };
const getAppoinmentById =
    (appointmentId: string) =>
    (dispatch: Dispatch): Promise<IAppointment> => {
        return appointmentService.getAppointmentById(appointmentId).then(appointment => {
            return appointment;
        });
    };

export const appointmentActions = {
    createAppointment,
    getAppointments,
    getAppoinmentById,
    updateAppointmentStatus,
    rescheduleAppointment,
    cancelAppointmentById,
};
