import React, { useState } from 'react';
import styled from 'styled-components';
import { palette } from 'styled/common';

const Accordion = ({ tabsNameList }) => {
    const [clicked, setClicked] = useState(false);

    const toggle = index => {
        if (clicked === index) {
            return setClicked(null);
        }

        setClicked(index);
    };

    return (
        <AccordionSection>
            <Container>
                {tabsNameList.map((item, index) => {
                    return (
                        <div key={index}>
                            <Wrap onClick={() => toggle(index)}>
                                <h1>{item.name}</h1>
                                <span>{clicked === index ? '-' : '+'}</span>
                            </Wrap>
                            {clicked === index ? <Dropdown>{item.content}</Dropdown> : null}
                        </div>
                    );
                })}
            </Container>
        </AccordionSection>
    );
};

const AccordionSection = styled.div`
    padding: 1rem;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
    background: #fff;
`;

const Container = styled.div`
    width: 100%;
    // border: 0.1rem solid ${palette.grey.lightest};
`;

const Wrap = styled.div`
    background-color: ${palette.light};
    color: ${palette.dark};
    border: 0.1rem solid ${palette.grey.lightest};
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: center;
    cursor: pointer;
    h1 {
        padding: 1rem;
        font-weight: normal;
        font-size: 1.1rem;
    }
    span {
        margin-right: 1.5rem;
    }
`;

const Dropdown = styled.div`
    background-color: ${palette.light};
    color: ${palette.dark};
    width: 100%;
    border: 0.1rem solid ${palette.grey.lightest};
`;

export default Accordion;
