import emptyCartImg from 'images/placeholders/cart.svg';
import s from './Checkout.module.scss';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';
const EmptyBasket = () => {
    return (
        <>
            <Container>
                <Row>
                    <Basket lg={12}>
                        <Image src={emptyCartImg} alt="Cart" />
                        <BasketItem>No Items</BasketItem>
                        <BasketContent>
                            {' '}
                            Cart is currently empty. Start selecting services.
                        </BasketContent>
                    </Basket>
                </Row>
            </Container>
        </>
    );
};
const Basket = styled(Col)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
`;
const Image = styled.img`
    max-width: 6.42rem;
`;
const BasketItem = styled.div`
    font-weight: 500;
    font-size: 1.125rem /* 18px */;
    line-height: 1.75rem /* 28px */;
    padding-top: 0.5rem /* 8px */;
`;
const BasketContent = styled.p`
    text-align: center;
    font-size: 0.9rem /* 14.4px */;
    color: rgba(181, 181, 195);
  }
`;
export default EmptyBasket;
