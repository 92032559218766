import { Input, Select, useSnackbar, LoaderOverlay, WarningModal } from '@components/common';
import { useEffect, useState } from 'react';

import { ICustomer } from 'interfaces/customer.interface';
import { AppointmentStatusEnum, IAppointment } from 'interfaces/appointment.interface';

import styled from 'styled-components';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import Button from '@components/Button';
import { IServiceProvider } from 'interfaces/team/serviceProvider.interface';
import { api } from 'helpers/auth-axios';
import moment from 'moment-timezone';
import { Link, useNavigate } from 'react-router-dom';

const DurationHours: { label: string; value: number }[] = [];
for (let i = 0; i < 24; i++) {
    DurationHours.push({
        label: `${i}h`,
        value: i,
    });
}

const DurationMinutes: { label: string; value: number }[] = [];
for (let i = 0; i < 4; i++) {
    DurationMinutes.push({
        label: `${i * 15}min`,
        value: i * 15,
    });
}

const EditAppointmentDrawerForCustomer = ({
    id,
    onChange,
    onClose,
    getAppointments,
}: {
    id: string;
    onChange(appointment: IAppointment): void;
    onClose(): void;
    getAppointments: any;
}) => {
    const [openSnackbar] = useSnackbar();
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const loggedInAsCustomer = _userData?.user_login === 'customer';
    const navigate = useNavigate();
    const [appointment, setAppointment] = useState<any>();
    const [loading, setLoading] = useState(false);

    const [selectedCustomer, setSelectedCustomer] = useState<ICustomer>();
    const [selectedServiceProvider, setSelectedServiceProvider] = useState<IServiceProvider>();
    const [selectedService, setSelectedService] = useState<any>();
    const [appointmentNote, setAppointmentNote] = useState<string>();
    const [customerNote, setCustomerNote] = useState<string>();
    const [start, setStart] = useState<any>();
    const [end, setEnd] = useState<any>();
    const [status, setStatus] = useState<AppointmentStatusEnum>();
    const [startDate, setStartDate] = useState<any>();
    const [enddate, setEndDate] = useState<any>();
    const [serviceDurationHours, setServiceDurationHours] = useState<any>(0);
    const [durationHours, setDurationHours] = useState<any>();
    const [serviceDurationMinutes, setServiceDurationMinutes] = useState<any>(0);
    const [durationMinutes, setDurationMinutes] = useState<any>();
    const [availableSlot, setAvailableSlot] = useState<any[]>([]);
    const [dropDownFilter, setDropdownFilter] = useState<any>();
    const [is_vip, setIs_vip] = useState<any>();
    const [businessId, setbusinessId] = useState<any>();
    const [verifyToken, setVerifyToken] = useState<any>();
    const [showMissingVerifyTokenError, setShowMissingVerifyTokenError] = useState<boolean>(false);
    const [appointmentId, setAppointmentId] = useState<any>();
    const [business, setBusiness] = useState<any>();
    const [timezone, setTimezone] = useState<any>();
    const [validToken, setValidToken] = useState<boolean>(false);
    const [inValidToken, setInValidToken] = useState<boolean>(false);
    const [overlayLoading, setOverLayloading] = useState(false);

    const [showCancellationWarningModal, setShowCancellationWarningModal] =
        useState<boolean>(false);

    const appointmentTime: { label: string; value: any }[] = [];
    const currentTime = moment().format();
    let time = moment().format('HH:mm');
    let currentDate = moment().format('YYYY-MM-DD');
    let todayDate = moment(startDate ? startDate : start).format('YYYY-MM-DD');

    availableSlot.length &&
        availableSlot.forEach((item: any) => {
            if (todayDate === currentDate) {
                item >= time &&
                    appointmentTime.push({
                        label: moment(moment().format() + ' ' + item, 'YYYY-MM-DD HH:mm:ss').format(
                            business?.time_select === '12' ? 'hh:mm a' : 'HH:mm'
                        ),
                        value: moment(moment().format() + ' ' + item, 'YYYY-MM-DD HH:mm:ss').format(
                            'HH:mm'
                        ),
                    });
            } else {
                appointmentTime.push({
                    label: moment(moment().format() + ' ' + item, 'YYYY-MM-DD HH:mm:ss').format(
                        business?.time_select === '12' ? 'hh:mm a' : 'HH:mm'
                    ),
                    value: moment(moment().format() + ' ' + item, 'YYYY-MM-DD HH:mm:ss').format(
                        'HH:mm'
                    ),
                });
            }
        });

    const setUpdateAppointment = (appointment: IAppointment) => {
        const appointmentDuration: any = appointment!.duration;
        setAppointment(appointment);
        setAppointmentNote(appointment?.appointment_note);
        setCustomerNote(appointment?.customer_note);
        setSelectedCustomer(appointment?.customer);
        setSelectedService(appointment?.service);
        setSelectedServiceProvider(appointment?.serviceProvider);
        setStart(moment(appointment?.booked_from_iso).format('YYYY-MM-DDTHH:mm:00'));
        setEnd(appointment?.booked_till_iso);
        setStartDate(moment(appointment?.booked_from_iso).format('YYYY-MM-DD'));
        setEndDate(appointment?.booked_till_iso);
        setServiceDurationHours(
            appointmentDuration >= 60 ? Math.floor(appointmentDuration / 60) : ''
        );
        setDurationHours(appointmentDuration >= 60 ? Math.floor(appointmentDuration / 60) : '');
        setDurationMinutes(appointmentDuration % 60);
        setServiceDurationMinutes(appointmentDuration % 60);
        setDropdownFilter(moment(appointment.booked_from_iso).format('HH:mm'));
        setIs_vip(appointment?.is_vip);
        setbusinessId(appointment?.business_id);
        setAppointmentId(appointment?._id);
        setStatus(appointment?.status);
    };
    const getAppointment = (data: any) => {
        setOverLayloading(true);
        api.get(
            `appointments/get_customer_appointment/${window.location.pathname.slice(
                26,
                50
            )}?business_id=${window.location.pathname.slice(1, 25)}&timeZone=${data}`
        )
            .then((res: any) => {
                setOverLayloading(false);
                if (res.error) return;
                const appointment = res.data as IAppointment;
                setUpdateAppointment(appointment);
            })
            .catch((e: any) => {
                setOverLayloading(false);
            });
    };
    useEffect(() => {
        api.get(`/businesses/public/${window.location.pathname.slice(1, 25)}`).then((res: any) => {
            setBusiness(res.data);
            getAppointment(res?.data?.timezone);
            setTimezone(res?.data?.timezone);
            moment.tz.setDefault(res?.data?.timezone);
        });
    }, []);

    const defaultDuration = serviceDurationHours * 60 + serviceDurationMinutes;
    const customDuration = durationHours * 60 + durationMinutes;
    const onSubmit = () => {
        if (!appointmentId) {
            openSnackbar('Invalid appointment ID');
            return;
        }

        const startTime =
            moment(startDate ? startDate : start).format('YYYY-MM-DD') +
            'T' +
            moment(start).format('HH:mm') +
            ':00';
        const endTime =
            moment(enddate ? enddate : end).format('YYYY-MM-DD') +
            'T' +
            moment(start).add(defaultDuration, 'minutes').format('HH:mm') +
            ':00';

        const userView = {
            current_view: 'timeGridDay',
        };
        const updatedAppointment = {
            booked_from_iso: appointmentTime?.length ? moment(startTime).format() : null,
            booked_till_iso: appointmentTime?.length ? moment(endTime).format() : null,
            booked_from: startTime ? moment(startTime).format() : moment(start).format(),
            booked_till: endTime ? moment(endTime).format() : moment(end).format(),
            business_id: businessId !== undefined && businessId,
            timeZone: timezone,
            user: userView,
            customerToken: verifyToken,
        };

        setLoading(true);
        if (loggedInAsCustomer) {
            api.put(`appointments/customer_reschedule/${appointmentId}`, updatedAppointment)
                .then((res: any) => {
                    setLoading(false);
                    if (res.error) {
                        return;
                    }
                    navigate(`/customers/${_userData?.user?._id}`);
                    // update the appointment we "start" with (before any change is made by user)
                    setAppointment({
                        ...appointment,
                        booked_from_iso: updatedAppointment.booked_from_iso,
                        booked_from: updatedAppointment.booked_from,
                        booked_till_iso: updatedAppointment.booked_till_iso,
                        booked_till: updatedAppointment.booked_till,
                    });
                    setStart(
                        moment(updatedAppointment.booked_from_iso).format('YYYY-MM-DDTHH:mm:00')
                    );
                    setEnd(updatedAppointment.booked_till_iso);
                    setStartDate(moment(updatedAppointment.booked_from_iso).format('YYYY-MM-DD'));
                    setEndDate(updatedAppointment.booked_till_iso);
                    setValidToken(true);
                    setInValidToken(false);
                    openSnackbar('Appointment updated successfully!');
                    if (is_vip === true) {
                        getVipAvailableSlot();
                    } else if (is_vip === false) {
                        getAvailableSlot();
                    }
                })
                .catch(e => {
                    setLoading(false);
                    if (e?.response) {
                        const response = e?.response?.data;
                        if (response) {
                            if (response?.message == 'Invalid confirmation code') {
                                setInValidToken(true);
                                setValidToken(false);
                            }

                            openSnackbar(response?.message);
                        }
                    }
                });
        } else {
            api.put(
                `appointments/customer-reschedule-with-token/${appointmentId}`,
                updatedAppointment
            )
                .then((res: any) => {
                    setLoading(false);
                    if (res.error) {
                        return;
                    }
                    // update the appointment we "start" with (before any change is made by user)
                    setAppointment({
                        ...appointment,
                        booked_from_iso: updatedAppointment.booked_from_iso,
                        booked_from: updatedAppointment.booked_from,
                        booked_till_iso: updatedAppointment.booked_till_iso,
                        booked_till: updatedAppointment.booked_till,
                    });
                    setStart(
                        moment(updatedAppointment.booked_from_iso).format('YYYY-MM-DDTHH:mm:00')
                    );
                    setEnd(updatedAppointment.booked_till_iso);
                    setStartDate(moment(updatedAppointment.booked_from_iso).format('YYYY-MM-DD'));
                    setEndDate(updatedAppointment.booked_till_iso);
                    setValidToken(true);
                    setInValidToken(false);
                    openSnackbar('Appointment updated successfully!');
                    if (is_vip === true) {
                        getVipAvailableSlot();
                    } else if (is_vip === false) {
                        getAvailableSlot();
                    }
                })
                .catch(e => {
                    setLoading(false);
                    if (e?.response) {
                        const response = e?.response?.data;
                        if (response) {
                            if (response?.message == 'Invalid confirmation code') {
                                setInValidToken(true);
                                setValidToken(false);
                            }

                            openSnackbar(response?.message);
                        }
                    }
                });
        }
    };

    const cancelAppointment = () => {
        setLoading(true);
        if (loggedInAsCustomer) {
            api.put(`appointments/${appointmentId}/customer-cancel`)
                .then((res: any) => {
                    setAppointment({
                        ...appointment,
                        status: AppointmentStatusEnum.CANCELLED,
                    });
                    setLoading(false);
                    navigate(`/customers/${_userData?.user?._id}`);
                    openSnackbar('Appointment Cancelled');
                })
                .catch(e => {
                    setLoading(false);
                    const message = e?.response?.data?.message;
                    if (message) {
                        openSnackbar(message);
                    }
                });
        } else {
            api.put(`appointments/${appointmentId}/customer-cancel-with-token`, {
                customerToken: verifyToken,
            })
                .then((res: any) => {
                    setAppointment({
                        ...appointment,
                        status: AppointmentStatusEnum.CANCELLED,
                    });
                    setStatus(AppointmentStatusEnum.CANCELLED);
                    setLoading(false);
                    setValidToken(true);
                    setInValidToken(false);
                    openSnackbar('Appointment Cancelled');
                })
                .catch(e => {
                    setLoading(false);
                    const message = e?.response?.data?.message;
                    if (message) {
                        if (message === 'Invalid confirmation code') {
                            setInValidToken(true);
                            setValidToken(false);
                        }
                        openSnackbar(message);
                    }
                });
        }
    };

    const hiddenDateCalendar = (e: any) => {
        setStartDate(e.target.value);
        setEndDate(e.target.value);
    };

    const getAvailableSlot = (start_date = '') => {
        if (
            selectedServiceProvider !== undefined &&
            appointment !== undefined &&
            is_vip === false
        ) {
            let date: any = moment(startDate ? startDate : start).format();
            let duration = defaultDuration;
            api.post(
                `appointments/customer_available_slots/${
                    selectedServiceProvider._id
                }?business_id=${window.location.pathname.slice(1, 25)}`,
                {
                    date: date,
                    duration: duration,
                    serviceId: selectedService._id,
                    appointmentIdBeingRescheduled: appointment._id,
                }
            ).then((res: any) => {
                setAvailableSlot(res.data);
            });
        }
    };
    const getVipAvailableSlot = () => {
        if (selectedServiceProvider !== undefined && appointment !== undefined && is_vip === true) {
            let date: any = moment(startDate ? startDate : start).format();
            let duration = defaultDuration;
            api.post(
                `appointments/customer_vip_available_slots/${
                    selectedServiceProvider._id
                }?business_id=${window.location.pathname.slice(1, 25)}&timeZone=${timezone}`,
                {
                    date: date,
                    duration: duration,
                    serviceId: selectedService._id,
                    appointmentIdBeingRescheduled: appointment._id,
                }
            ).then((res: any) => {
                setAvailableSlot(res.data);
            });
        }
    };
    useEffect(() => {
        getAvailableSlot();
        getVipAvailableSlot();
    }, [startDate, defaultDuration, customDuration, start, is_vip]);

    useEffect(() => {
        dropDownValue();
    }, [startDate, defaultDuration, customDuration, start, selectedService, dropDownFilter]);
    const dropDownValue = () => {
        let time = '';
        appointmentTime.forEach((val: any) => {
            const value = moment(start).format(
                business?.time_select === '12' ? 'hh:mm a' : 'HH:mm'
            );

            if (value.indexOf('am') != -1 || value.indexOf('pm') != -1) {
                time = val.label;
            } else {
                time = val.value;
            }
            if (time === value) {
                let vals = [];
                if (val.value >= value) {
                    vals.push(val.value);
                }

                setDropdownFilter(val.value);
                return;
            } else {
                return appointmentTime[0].label;
            }
        });
    };

    const readOnly =
        currentTime >= end
            ? true
            : status &&
              status === AppointmentStatusEnum.CONFIRMED &&
              appointment?.status === AppointmentStatusEnum.CONFIRMED
            ? false
            : true;

    // True if the time or date is changed, else False
    const changeWasMade =
        startDate !== moment(appointment?.booked_from_iso).format('YYYY-MM-DD') ||
        start !== moment(appointment?.booked_from_iso).format('YYYY-MM-DDTHH:mm:00');

    // only show the reschedule button if a new date or time is set and if customer isn't logged in, wait until they input the verification code
    const showRescheduleButton = changeWasMade;

    return (
        <>
            {overlayLoading && <LoaderOverlay />}
            <MainContainer>
                <Row>
                    <Col>
                        <DrawerModel>
                            <AppointmentContent>
                                <HeaderContainer>
                                    {loggedInAsCustomer && (
                                        <Back to={`/customers/${_userData?.user?._id}`}>
                                            <i className="fal fa-long-arrow-left"></i>
                                        </Back>
                                    )}
                                    <Header>
                                        {loggedInAsCustomer ? 'Go Back' : 'Appointment Details'}
                                    </Header>
                                </HeaderContainer>
                                <DrawerBody>
                                    <FormContent>
                                        <FormDetails>
                                            Select Date
                                            <DatesBody>
                                                <FormDate>
                                                    <CalenderDate>
                                                        {startDate
                                                            ? moment(startDate).format(
                                                                  'ddd, MMM DD'
                                                              )
                                                            : moment(start).format('ddd, MMM DD')}
                                                    </CalenderDate>
                                                    <input
                                                        type="date"
                                                        readOnly={readOnly}
                                                        min={new Date().toISOString().split('T')[0]}
                                                        value={moment(startDate).format(
                                                            'YYYY-MM-DD'
                                                        )}
                                                        onChange={(e: any) => hiddenDateCalendar(e)}
                                                    />
                                                </FormDate>
                                            </DatesBody>
                                            <InputGroup>
                                                <InputContent>
                                                    <Col lg={12}>
                                                        Select Time
                                                        <Select
                                                            value={moment(
                                                                moment().format() +
                                                                    ' ' +
                                                                    dropDownFilter,
                                                                'YYYY-MM-DD HH:mm:ss'
                                                            ).format(
                                                                business?.time_select === '12'
                                                                    ? 'hh:mm a'
                                                                    : 'HH:mm'
                                                            )}
                                                            readOnly={readOnly}
                                                            options={appointmentTime}
                                                            onChange={(val: string) => {
                                                                const newStart =
                                                                    moment(start).format(
                                                                        'YYYY-MM-DD'
                                                                    ) +
                                                                    'T' +
                                                                    val +
                                                                    ':00';

                                                                setStart(newStart);
                                                            }}
                                                        />
                                                    </Col>
                                                </InputContent>
                                            </InputGroup>
                                            {loggedInAsCustomer ? (
                                                ' '
                                            ) : (
                                                <InputGroup>
                                                    <Input
                                                        label={`Confirmation Code`}
                                                        value={verifyToken}
                                                        onChange={({
                                                            value,
                                                        }: {
                                                            value: string;
                                                        }) => {
                                                            if (showMissingVerifyTokenError) {
                                                                setShowMissingVerifyTokenError(
                                                                    false
                                                                );
                                                            }
                                                            setVerifyToken(value);
                                                        }}
                                                        error={
                                                            showMissingVerifyTokenError
                                                                ? {
                                                                      message:
                                                                          'Please enter the confirmation code from your appointment email!',
                                                                  }
                                                                : undefined
                                                        }
                                                    />
                                                </InputGroup>
                                            )}
                                            {validToken &&
                                                validToken === true &&
                                                !loggedInAsCustomer && (
                                                    <Verified>Confirmation Code Verified</Verified>
                                                )}
                                            {inValidToken &&
                                                inValidToken === true &&
                                                !loggedInAsCustomer && (
                                                    <Invalid>Invalid Confirmation Code</Invalid>
                                                )}
                                            {currentTime <= end && (
                                                <FormFooter>
                                                    {showRescheduleButton && (
                                                        <FormButtons>
                                                            <RescheduleButton
                                                                disabled={loading}
                                                                onClick={() => {
                                                                    if (
                                                                        loggedInAsCustomer ||
                                                                        (verifyToken &&
                                                                            verifyToken !== '')
                                                                    ) {
                                                                        onSubmit();
                                                                    } else {
                                                                        setShowMissingVerifyTokenError(
                                                                            true
                                                                        );
                                                                    }
                                                                }}>
                                                                Reschedule
                                                            </RescheduleButton>
                                                        </FormButtons>
                                                    )}
                                                    {!showRescheduleButton && (
                                                        <SubtleText>
                                                            You may reschedule or cancel this
                                                            appointment. To reschedule the
                                                            appointment, please select a new time or
                                                            date above.
                                                        </SubtleText>
                                                    )}

                                                    {status !== AppointmentStatusEnum.CANCELLED && (
                                                        <FormButtons
                                                            style={{
                                                                marginTop: '0.7rem',
                                                            }}>
                                                            <Button
                                                                bgtype={'primary'}
                                                                label="Cancel Appointment"
                                                                width="100% !important"
                                                                ifClicked={() => {
                                                                    if (
                                                                        loggedInAsCustomer ||
                                                                        (verifyToken &&
                                                                            verifyToken !== '')
                                                                    ) {
                                                                        setShowCancellationWarningModal(
                                                                            true
                                                                        );
                                                                    } else {
                                                                        setShowMissingVerifyTokenError(
                                                                            true
                                                                        );
                                                                    }
                                                                }}></Button>
                                                        </FormButtons>
                                                    )}
                                                </FormFooter>
                                            )}
                                        </FormDetails>
                                    </FormContent>
                                </DrawerBody>
                            </AppointmentContent>
                        </DrawerModel>
                    </Col>
                </Row>
            </MainContainer>
            {showCancellationWarningModal && (
                <WarningModal
                    message={
                        'Are you sure you want to cancel your appointment? This cannot be undone.'
                    }
                    continueButtonAlternateText="Yes, Cancel Appointment"
                    cancelButtonAlternateText="Go Back"
                    onContinue={() => {
                        setShowCancellationWarningModal(false);
                        cancelAppointment();
                    }}
                    onClose={() => {
                        setShowCancellationWarningModal(false);
                    }}></WarningModal>
            )}
        </>
    );
};
const MainContainer = styled(Container)`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
`;
const AppointmentContent = styled(Col)`
    height: 100%;
    display: flex;
    flex-direction: column;
`;
const HeaderContainer = styled.div`
    display: flex;
    padding: 1rem 1.75rem;
    min-height: 70px;
    align-items: center;
`;
const Header = styled.div`
    font-size: 1.25rem;
    font-weight: 800;
`;
const DrawerModel = styled.div`
    background-color: #fff;
    z-index: 100;
    box-shadow: rgb(62 53 71 / 18%) 0px 0px 48px 0px;
    min-width: 360px;
    border-radius: 0.75rem;
    // transform: translateX(100%);
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    ${media.xs`
    width: 100%;
    height: 98%;
	`}
    ${media.sm`
    width: 100%;
    height: 98%;
	`}
    ${media.md`
    width: 100%;
    height: 98%;

	`}
	${media.lg`
    width: 400px;
	`}
`;
const DrawerBody = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    padding-top: 0.5rem /* 8px */;
    padding: 0 1.75rem;
    overflow: auto;
    margin-bottom: 1rem;
`;
const FormContent = styled.div`
    flex: 1 1 0%;
`;
const FormDetails = styled.div`
    padding-top: 2rem;
    height: calc(100% - 84px);
    // overflow: scroll;
`;
const DatesBody = styled.div`
    justify-content: space-between !important;
    display: -webkit-box !important;
    margin-bottom: 0.5rem /* 24px */;
`;
const FormDate = styled.div`
    font-weight: 500;
    font-size: 1.35rem;
    display: block;
    justify-content: center;
    align-items: center;
    input {
        border: none;
        box-sizing: border-box;
        outline: 0;
        position: relative;
        top: -1.8rem;
        width: 7rem;
        opacity: 0;
        cursor: pointer;
    }
    input[type='date']::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }
`;
const InputGroup = styled.div`
    margin-bottom: 1rem;
`;
const InputContent = styled(Row)`
    margin-bottom: 1rem;
`;
const FormFooter = styled.div`
    padding: 0.5rem 0rem;
    background: #fff;
`;
const FormButtons = styled.div`
    display: flex;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1.5rem /* 24px */;
    justify-content: space-between;
`;
const ToggleDurationButton = styled.div`
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const CustomSpan = styled.span`
    font-size: 0.8rem;
`;
const RescheduleButton = styled.button`
    width: 100%;
    padding: 0.75rem 1rem;
    text-transform: capitalize;
    background: #27292e;
    border-radius: 0.57rem;
    font-size: 14px;
    font-weight: 400;
    background-color: rgba(255, 255, 255);
    color: rgba(255, 255, 255);
    border-width: 1px;
    border-color: rgba(73, 196, 150);
    background-color: rgba(73, 196, 150);
    border: none;
    cursor: pointer;
`;
const SubtleText = styled.div`
    color: #67717e;
    text-align: center;
    font-size: 12.5px;
`;
const CalenderDate = styled.div`
    cursor: pointer;
`;

const Verified = styled.div`
    color: #00be70;
`;
const Invalid = styled.div`
    color: #f1416c;
`;
const Back = styled(Link)`
    margin-right: 1.25rem /* 20px */;
    font-size: 1.875rem /* 30px */;
    line-height: 2.25rem /* 36px */;
`;
export default EditAppointmentDrawerForCustomer;
