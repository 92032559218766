import useRouter from 'hooks/router';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { serviceActions } from 'actions/service';
import { IOther } from 'interfaces/team/others.interface';
import { otherActions } from 'actions/team/other';
import { Tab, TabPanel, Tabs, useSnackbar } from '@components/common';
import styled from 'styled-components';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import TabContext from '@components/common/TabContext';
import Card from '@components/common/Cards/Card';
import Permissions from '@components/common/Permissions/Permissions';
import DeleteModel from '@components/common/DeleteModel/DeleteModel';
import { palette } from 'styled/common';
import Accordion from '@components/Accordion';
import { api } from 'helpers/auth-axios';
import { RoleEnum } from 'constants/enums';

const OthersCard = ({ callOther }: any) => {
    const [other, setOther] = useState<IOther>();
    const [tabValue, setTabValue] = useState(0);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [businessId, setBusinessId] = useState<any>();
    const router = useRouter();
    const dispatch = useAppDispatch();
    const [openSnackbar] = useSnackbar();

    const _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const otherId: string = router.query?.id ?? _userData.user._id;

    const data = () => {
        api.get(`/other/${otherId}`).then((res: any) => {
            if (res.data !== undefined) {
                setOther(res.data);
                setBusinessId(res.data.business_id);
            }
        });
    };
    useEffect(() => {
        data();
    }, [dispatch, otherId]);

    const deleteModel = (id: any) => {
        setDeleteModal(true);
        setDeleteId(id);
    };

    const deleteOther = (id: any) => {
        setLoading(true);
        api.delete(`/other/${id}`)
            .then((res: any) => {
                router.navigate('/team/other-list');
                openSnackbar('Other deleted successfully!');
                setDeleteModal(false);
                callOther();
            })
            .catch((e: any) => {
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const tabsNameList = [
        {
            name: 'Profile',
            content: (
                <Card
                    link={`/team/others/${otherId}/edit`}
                    model={() => deleteModel(otherId)}
                    nameData={other?.name}
                    name={other?.name}
                    email={other?.email}
                    phone={other?.phone}
                    profileImage={other?.photo}
                    accountObject={other}
                    reloadAccountObject={() => data()}
                    accountRole={RoleEnum.OTHERS}
                />
            ),
        },
        {
            name: 'Permissions',
            content: (
                <Permissions id={otherId} businessId={businessId} userRole={RoleEnum.OTHERS} />
            ),
        },
    ];

    return (
        <>
            <MainContainer>
                <Row>
                    {other && (
                        <>
                            <WebView lg={12}>{tabsNameList[0].content}</WebView>
                            <WebView lg={12}>
                                <TabsCard>
                                    <TabContext value={tabValue}>
                                        <Tabs
                                            onChange={(event, newValue) => {
                                                setTabValue(newValue);
                                            }}>
                                            <Tab label={tabsNameList[1].name} />
                                        </Tabs>
                                        <TabPanel value={0} preload={true}>
                                            <TabContent>{tabsNameList[1].content}</TabContent>
                                        </TabPanel>
                                    </TabContext>
                                </TabsCard>
                            </WebView>
                            <MobileView lg={12}>
                                <Accordion tabsNameList={tabsNameList} />
                            </MobileView>

                            {deleteModal && other && (
                                <DeleteModel
                                    id={other._id}
                                    onDelete={() => {
                                        setDeleteModal(false);
                                    }}
                                    name="Others"
                                    content="If you delete this Other you will lose all information connected to
									their profile including permissions. This action cannot be undone."
                                    loading={loading}
                                    onCLick={() => deleteOther(deleteId)}
                                    onClose={() => setDeleteModal(false)}
                                />
                            )}
                        </>
                    )}
                </Row>
            </MainContainer>
        </>
    );
};

const MainContainer = styled(Container)`
    height: 100vh;
    overflow-y: scroll;
    padding: 1.71rem;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    ${media.xs`
	padding: 1.1rem;
    overflow-x: hidden;
    overflow-y: auto;
	`}
    ${media.sm`
    padding: 0rem;
    padding-top: 1rem;
    overflow-x: hidden;
    overflow-y: auto;
	`}
	${media.md`
	  padding: 1.71rem;
	`}
	${media.lg`
	  padding: 1.71rem;
	`}
`;

const WebView = styled(Col)`
    display: block;
    @media screen and (min-width: 1px) and (max-width: 900px) {
        display: none;
    }
`;
const MobileView = styled(Col)`
    display: none;
    @media screen and (min-width: 1px) and (max-width: 900px) {
        display: block;
    }
`;
const TabsCard = styled.div`
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: ${palette.light};
    background-clip: border-box;
    border-radius: 0.42rem;
    box-shadow: 0rem 0rem 2.2rem 0rem ${palette.grey.shadow};
    border: 0;
`;

const TabContent = styled.div`
    padding: 1.3rem;
    flex: 1;
`;
export default OthersCard;
