import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { IService } from 'interfaces/service.interface';
import moneyImg from 'images/money.svg';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { useSnackbar, ServicesList } from '@components/common';
import { serviceProviderActions } from 'actions/team/serviceProvider';
import { IServiceProvider } from 'interfaces/team/serviceProvider.interface';
import s from './SelectServices.module.scss';
const SelectService = ({
    onClose,
    onSelect,
    selectedService,
    id,
}: {
    onClose(): void;
    onSelect(service: IService): void;
    id: string;
    selectedService: any;
}) => {
    const [serviceProvider, setServiceProvider] = useState<IServiceProvider>();
    const [services, setServices] = useState<IService[]>();
    const dispatch = useAppDispatch();
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const select = (service: IService) => {
        onSelect(service);
        onClose();
    };
    useEffect(() => {
        if (id !== undefined) {
            dispatch(serviceProviderActions.getServiceProviderById(id)).then(
                (serviceProvider: IServiceProvider) => {
                    setServiceProvider(serviceProvider);

                    /*
                     * So that we can pass these services to the ServicesList
                     * component, make sure the service's indexes are unique and in
                     * order.
                     */
                    let businessServices = [...serviceProvider.services];
                    // Handle any services which do not yet have an index assigned
                    // order services by their currently set index
                    businessServices.sort((service1, service2) => service1.index - service2.index);
                    // go over array again, any value less than 0 should be set to next available
                    // index and moved to the end of the list
                    while (businessServices[0]?.index < 0) {
                        const nextAvailableIndex =
                            businessServices[businessServices.length - 1].index + 1;
                        // remove element from beginning of list
                        const [elem] = businessServices.splice(0, 1);
                        // update element's index
                        elem.index = nextAvailableIndex;
                        // add element to end of list
                        businessServices.push(elem);
                    }

                    setServices(businessServices);
                }
            );
        }
    }, [dispatch, id]);

    return (
        <>
            <Container>
                <Row>
                    <ServicesContent>
                        <HeaderContainer>
                            <CloseButton onClick={onClose}>
                                <Icon className="fal fa-long-arrow-left"></Icon>
                            </CloseButton>
                            <Header>Select Service</Header>
                        </HeaderContainer>
                        {id === undefined ? (
                            <div>Please Select Service Provider First</div>
                        ) : (
                            <ServicesBody>
                                <ServicesList
                                    currency={_userData.user.business_id.currency}
                                    services={services as IService[]}
                                    selectHandler={selectedService => select(selectedService)}
                                />
                            </ServicesBody>
                        )}
                    </ServicesContent>
                </Row>
            </Container>
        </>
    );
};
const ServicesContent = styled(Col)`
    height: 100%;
    display: flex;
    flex-direction: column;
`;
const HeaderContainer = styled.div`
    display: flex;
    padding: 1rem 1.75rem;
    min-height: 70px;
    align-items: center;
`;
const CloseButton = styled.div`
    margin-right: 1.5rem;
    cursor: pointer;
`;

const Icon = styled.i`
    font-size: 2.25rem;
    line-height: 2.5rem;
`;
const Header = styled.div`
    font-size: 1.75rem;
    font-weight: 600;
`;

const ServicesBody = styled.div`
    padding: 0 1.75rem;
    overflow: hidden;
`;

export default SelectService;
