import { useState, useEffect } from 'react';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';

import styled from 'styled-components';
import { palette } from '../../../styled/common';
import { Link } from 'react-router-dom';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { IService } from 'interfaces/service.interface';

export interface IServicesListProps {
    currency: string;
    /***
     * NOTE: services require unique indexes before being passed to this
     * component. So, add any checks for that before passing services in here.
     *
     * Services will also require sorting by index before being passed to this
     * component, if the reordering feature isn't being used.
     */
    services: IService[];
    selectHandler?(selectedService: IService): void; // what to do if a service is clicked on
    reorderingHandler?(reorderedServicesList: IService[]): void;
    editHandler?(editService: IService): void;
    deleteHandler?(deletedService: IService): void;
    showDescription?: boolean;
    showVipPrice?: boolean;
    readOnly?: boolean; // if True, user should have no ability to modify the list in any way
}
const ServicesList = ({
    currency,
    services,
    selectHandler,
    reorderingHandler,
    editHandler,
    deleteHandler,
    showDescription,
    showVipPrice,
    readOnly,
}: IServicesListProps) => {
    const [servicesList, setServicesList] = useState<IService[]>(services);

    useEffect(() => {
        setServicesList(services);
    }, [services]);

    // a function for deep-copying the services list (so any modifications to
    // the copy don't mess with the original, or the react-beautiful-dnd library)
    const deepCopyServicesList = (list: IService[]) => {
        let listCopy = [];
        for (let i = 0; i < list.length; i++) {
            const elem: IService = { ...list[i] };
            listCopy.push(elem);
        }
        return listCopy;
    };

    // update list on drop
    const handleDrop = (droppedItem: any) => {
        // ignore drop outside droppable container
        if (!droppedItem.destination) return;
        // create a temporary list copy
        let updatedList = deepCopyServicesList(servicesList);
        // remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // update all indexes
        for (let i = 0; i < updatedList.length; i++) {
            updatedList[i].index = i;
        }
        // update state
        setServicesList(updatedList);
        if (reorderingHandler !== undefined) {
            reorderingHandler(updatedList);
        }
    };

    return reorderingHandler && !readOnly ? (
        <DragDropContext onDragEnd={handleDrop}>
            <Droppable droppableId="list-container">
                {provided => (
                    <ServicesListContainer
                        className="list-container"
                        {...provided.droppableProps}
                        ref={provided.innerRef}>
                        {servicesList?.length ? (
                            servicesList.map((service: IService, index) => {
                                return (
                                    <Draggable
                                        key={service._id}
                                        draggableId={service._id}
                                        index={index}>
                                        {provided => (
                                            <ServicesListItem
                                                className="item-container"
                                                backgroundChangeOnHover={true}
                                                onClick={() =>
                                                    selectHandler ? selectHandler(service) : null
                                                }
                                                ref={provided.innerRef}
                                                {...provided.dragHandleProps}
                                                {...provided.draggableProps}>
                                                <Row>
                                                    <ServiceHeader
                                                        xl={3}
                                                        lg={3}
                                                        md={3}
                                                        sm={3}
                                                        xs={5}>
                                                        <BackgroundColorIcon
                                                            className={`service-color-${service.color}--bd-light`}></BackgroundColorIcon>
                                                        <Name>{service.name}</Name>
                                                    </ServiceHeader>
                                                    <Description
                                                        xl={3}
                                                        lg={4}
                                                        md={4}
                                                        sm={4}
                                                        hiddenXsDown={true}>
                                                        {showDescription &&
                                                            (service.description ||
                                                                'No Description')}
                                                    </Description>
                                                    <DurationPriceContainer
                                                        xl={3}
                                                        lg={2}
                                                        md={2}
                                                        sm={2}
                                                        xs={4}>
                                                        <Row
                                                            smJustifyContent="end"
                                                            justifyContent="center">
                                                            <Duration>{`${
                                                                service.my_duration >= 60
                                                                    ? Math.floor(
                                                                          service.my_duration / 60
                                                                      ) + 'h'
                                                                    : ''
                                                            } ${
                                                                service.my_duration % 60
                                                            }min`}</Duration>
                                                            <Price>
                                                                {currency}
                                                                {service.my_price}
                                                            </Price>
                                                            {showVipPrice && (
                                                                <Price>
                                                                    {currency}
                                                                    {service.vip_price}
                                                                </Price>
                                                            )}
                                                        </Row>
                                                    </DurationPriceContainer>
                                                    <Col xl={3} lg={3} md={3} sm={3} xs={3}>
                                                        <Row
                                                            smJustifyContent="end"
                                                            justifyContent="center">
                                                            {editHandler && (
                                                                <EditButton
                                                                    onClick={() =>
                                                                        editHandler(service)
                                                                    }>
                                                                    <i className="fal fa-pen"></i>
                                                                </EditButton>
                                                            )}
                                                            {deleteHandler && (
                                                                <DeleteButton
                                                                    onClick={() =>
                                                                        deleteHandler(service)
                                                                    }>
                                                                    <i className="fal fa-trash-alt"></i>
                                                                </DeleteButton>
                                                            )}
                                                        </Row>
                                                    </Col>
                                                    <Description hiddenSmUp={true}>
                                                        {showDescription &&
                                                            (service.description ||
                                                                'No Description')}
                                                    </Description>
                                                </Row>
                                            </ServicesListItem>
                                        )}
                                    </Draggable>
                                );
                            })
                        ) : (
                            <div>No Services</div>
                        )}
                        {provided.placeholder}
                    </ServicesListContainer>
                )}
            </Droppable>
        </DragDropContext>
    ) : (
        <ServicesListContainer>
            {servicesList?.length ? (
                servicesList.map((service: IService, index) => {
                    return (
                        <ServicesListItem
                            onClick={() => (selectHandler ? selectHandler(service) : null)}
                            backgroundChangeOnHover={!!selectHandler}>
                            <Row>
                                <ServiceHeader xl={3} lg={3} md={3} sm={3} xs={5}>
                                    <BackgroundColorIcon
                                        className={`service-color-${service.color}--bd-light`}></BackgroundColorIcon>
                                    <Name>{service.name}</Name>
                                </ServiceHeader>
                                <Description xl={3} lg={4} md={4} sm={4} hiddenXsDown={true}>
                                    {showDescription && (service.description || 'No Description')}
                                </Description>
                                <DurationPriceContainer xl={3} lg={2} md={2} sm={2} xs={4}>
                                    <Row smJustifyContent="end" justifyContent="center">
                                        <Duration>{`${
                                            service.my_duration >= 60
                                                ? Math.floor(service.my_duration / 60) + 'h'
                                                : ''
                                        } ${service.my_duration % 60}min`}</Duration>
                                        <Price>
                                            {currency}
                                            {service.my_price}
                                        </Price>
                                        {showVipPrice && (
                                            <Price>
                                                {currency}
                                                {service.vip_price}
                                            </Price>
                                        )}
                                    </Row>
                                </DurationPriceContainer>
                                <Col xl={3} lg={3} md={3} sm={3} xs={3}>
                                    <Row smJustifyContent="end" justifyContent="center">
                                        {editHandler && !readOnly && (
                                            <EditButton onClick={() => editHandler(service)}>
                                                <i className="fal fa-pen"></i>
                                            </EditButton>
                                        )}
                                        {deleteHandler && !readOnly && (
                                            <DeleteButton onClick={() => deleteHandler(service)}>
                                                <i className="fal fa-trash-alt"></i>
                                            </DeleteButton>
                                        )}
                                    </Row>
                                </Col>
                                <Description hiddenSmUp={true}>
                                    {showDescription && (service.description || 'No Description')}
                                </Description>
                            </Row>
                        </ServicesListItem>
                    );
                })
            ) : (
                <div>No Services</div>
            )}
        </ServicesListContainer>
    );
};

const ServicesListContainer = styled.div`
    width: 100%;
    border-top: 0.1rem solid ${palette.grey.lightest};
`;

interface ListItemProps {
    backgroundChangeOnHover?: boolean;
}
const ServicesListItem = styled.div<ListItemProps>`
    padding: 0.85rem 1.71rem;
    border-bottom: 0.1rem solid ${palette.grey.lightest};
    ${props =>
        props.backgroundChangeOnHover &&
        `
        cursor: pointer;
        &:hover {
            background-color: ${palette.grey.lightest};
        }
    `}
    @media screen and (min-width: 320px) and (max-width: 471px) {
        scroll-direction: horizontal;
        overflow: auto;
    }
`;

const ServiceHeader = styled(Col)`
    ${media.sm`
        min-width: 120px;
    `}
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
`;

const BackgroundColorIcon = styled.div`
    width: 0.5rem;
    min-width: 0.5rem;
    max-width: 0.5rem;
    height: 100%;
    min-height: 3rem;
`;

const Name = styled.div`
    padding: 0 0.85rem;
    font-size: 1rem;
    overflow: hidden;
`;

const Description = styled(Col)`
    padding: 0 0.85rem;
    align-self: center;
    font-size: 1rem;
    overflow: auto;
    @media screen and (min-width: 320px) and (max-width: 471px) {
        overflow: none;
    }
`;

const DurationPriceContainer = styled(Col)`
    align-self: center;
`;

const Price = styled.div`
    padding: 0 0.85rem;
    font-size: 1rem;
    white-space: nowrap;
`;

const Duration = styled.div`
    padding: 0 0.85rem;
    font-size: 1rem;
    white-space: nowrap;
`;

const EditButton = styled.button`
    padding: 0;
    min-width: 2.85rem;
    min-height: 2.85rem;
    border: 0.07rem solid ${palette.grey.lightest};
    background-color: ${palette.light};
    color: ${palette.silver};
    margin-right: 1rem;
    font-weight: 600;
    border-radius: 0.51rem;
    font-size: 1.2rem;
    cursor: pointer;
    &:hover {
        background-color: ${palette.grey.light};
    }
`;

const DeleteButton = styled.button`
    padding: 0;
    min-width: 2.85rem;
    min-height: 2.85rem;
    border-width: 0rem;
    background-color: ${palette.primary};
    color: ${palette.light};
    font-weight: 600;
    border-radius: 0.51rem;
    font-size: 1.07rem;
    margin-right: 0.71rem;
    cursor: pointer;
    &:hover {
        background-color: ${palette.primaryHover};
    }
`;

export default ServicesList;
