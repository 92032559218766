import { ILogin, IUser } from 'interfaces/user.interface';
import { api } from '../helpers/auth-axios';

export const userService = {
    login,
    register,
    getUser,
};

function login(data: ILogin) {
    return api
        .post('/auth/login', data)
        .then(res => {
            localStorage.setItem('user', JSON.stringify(res.data));
            return res.data;
        })
        .catch(e => {
            return e;
        });
}

function register(register: IUser) {
    return api.post('/users/register', register).then(res => {
        localStorage.setItem('user', JSON.stringify(res.data));
        return res.data;
    });
}

function getUser(accessToken: string) {
    return api.post('/auth/success', { accessToken: accessToken }).then(res => {
        localStorage.setItem('user', JSON.stringify(res.data));

        return res.data;
    });
}
