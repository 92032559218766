import { useEffect, useState } from 'react';
import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';

import { Checkbox, useSnackbar } from '@components/common';
import Button from '@components/Button';
import { useAppDispatch } from 'hooks/redux';

// Explicitly extend the window property to include Square, to satisfy typescript
declare global {
    interface Window {
        Square: any;
    }
}

/*
 * There are 2 different ways to use this component:
 * 1. to get a single token for a card - for New and EditAppointmentDrawer
 * 2. to provide two buttons (Reserve Only & Prepay) - for booking pages
 *
 * So, you can either:
 * 1. set enableSaveCard to true
 * 2. set enableReserveAndPay and/or enableReserveOnly to true
 *
 * If one tries to set enableSaveCard to true and either enableReserveOnly
 * or enableReserveAndPay to true, then this component should display an error
 *
 * If enableReserveAndPay or enableSaveCard are set to true, then
 * singleTokenReceiver must be set.
 * If enableReserveOnly is set to true, then doubleTokenReceiver must be set.
 * If these conditions aren't met, an error will be displayed.
 */

const SquarePaymentForm = ({
    enableReserveAndPay,
    enableReserveOnly,
    enableSaveCard,
    singleTokenReceiver,
    doubleTokenReceiver,
    reserveAndPayButtonLabel,
    reserveOnlyButtonLabel,
    cardOnFileAuthorizationCheckboxLabel,
    loading,
    children,
}: {
    enableReserveAndPay?: boolean;
    enableReserveOnly?: boolean;
    enableSaveCard?: boolean;
    singleTokenReceiver?(tokenResult: any): void;
    doubleTokenReceiver?(tokenResult1: any, tokenResult2: any): void;
    reserveAndPayButtonLabel?: string;
    reserveOnlyButtonLabel?: string;
    cardOnFileAuthorizationCheckboxLabel?: React.ReactNode;
    loading?: boolean;
    children?: React.ReactNode;
}) => {
    const [openSnackbar] = useSnackbar();
    const [fetchingToken, setFetchingToken] = useState(false);
    const [loadedSquareScript, setLoadedSquareScript] = useState(false);
    const [cardInitialized, setCardInitialized] = useState(false);
    const [userAcceptedCardOnFileTerms, setUserAcceptedCardOnFileTerms] = useState<boolean>(false);

    useEffect(() => {
        const SQUARE_PROD_HOST = 'https://web.squarecdn.com/v1/square.js';
        const SQUARE_STG_HOST = 'https://sandbox.web.squarecdn.com/v1/square.js';
        let SQUARE_HOST =
            process.env.REACT_APP_ENVIRONMENT === 'production' ? SQUARE_PROD_HOST : SQUARE_STG_HOST;

        let squareScript = document.createElement('script');
        squareScript.src = SQUARE_HOST;
        squareScript.type = 'text/javascript';
        squareScript.async = false;
        squareScript.onload = () => {
            setLoadedSquareScript(true);
        };
        document.getElementsByTagName('head')[0].appendChild(squareScript);
    });

    useEffect(() => {
        if (!loadedSquareScript) {
            return;
        }

        if (!window?.Square) {
            console.log('[SquarePaymentForm] Square.js failed to load properly');
            return;
        }

        const squareAppId = process.env.REACT_APP_SQUARE_APPLICATION_ID!;
        const squareLocationId = process.env.REACT_APP_SQUARE_LOCATION_ID!;

        const payments = window.Square.payments(squareAppId, squareLocationId);
        initializeCard(payments)
            .then(card => {
                setCardInitialized(false);
                if (enableReserveAndPay) {
                    const reserveAndPayButton = document.getElementById(
                        'reserve-and-pay-button'
                    ) as HTMLButtonElement;
                    if (reserveAndPayButton) {
                        reserveAndPayButton.addEventListener('click', async (event: any) => {
                            await fetchSingleToken(event, card);
                        });
                    }
                }

                if (enableReserveOnly) {
                    const reserveOnlyButton = document.getElementById(
                        'reserve-only-button'
                    ) as HTMLButtonElement;
                    if (reserveOnlyButton) {
                        reserveOnlyButton.addEventListener('click', async (event: any) => {
                            await fetchTwoTokens(event, card);
                        });
                    }
                }

                if (enableSaveCard) {
                    const saveCardButton = document.getElementById(
                        'save-card-button'
                    ) as HTMLButtonElement;
                    if (saveCardButton) {
                        saveCardButton.addEventListener('click', async (event: any) => {
                            await fetchSingleToken(event, card);
                        });
                    }
                }
                setCardInitialized(true);
            })
            .catch((e: any) => {
                console.error(e?.message);
                openSnackbar(e?.message);
            });
    }, [loadedSquareScript]);

    const initializeCard = async (payments: any) => {
        const card = await payments.card();
        await card.attach('#card-container');
        return card;
    };

    const fetchSingleToken = async (event: any, paymentMethod: any) => {
        event.preventDefault();

        if (!singleTokenReceiver) {
            throw new Error(
                'singleTokenReceiver must be defined in order to use fetchSingleToken()'
            );
        }

        try {
            setFetchingToken(true);
            const result = await tokenize(paymentMethod);
            singleTokenReceiver(result);
            setFetchingToken(false);
        } catch (e: unknown) {
            setFetchingToken(false);
            if (typeof e === 'string') {
                console.error(e);
                openSnackbar(e);
            } else if (e instanceof Error) {
                console.error(e?.message);
                openSnackbar(e?.message);
            }
            return;
        }
    };

    const fetchTwoTokens = async (event: any, paymentMethod: any) => {
        event.preventDefault();

        if (!doubleTokenReceiver) {
            throw new Error('doubleTokenReceiver must be defined in order to use fetchTwoTokens()');
        }

        try {
            setFetchingToken(true);
            const result1 = await tokenize(paymentMethod);
            const result2 = await tokenize(paymentMethod);
            doubleTokenReceiver(result1, result2);
            setFetchingToken(false);
        } catch (e: unknown) {
            setFetchingToken(false);
            if (typeof e === 'string') {
                console.error(e);
                openSnackbar(e);
            } else if (e instanceof Error) {
                console.error(e?.message);
                openSnackbar(e?.message);
            }
            return;
        }
    };

    /*
     * Tokenize the payment method
     */
    const tokenize = async (paymentMethod: any) => {
        const tokenResult = await paymentMethod.tokenize();
        if (tokenResult.status === 'OK') {
            return tokenResult;
        } else {
            let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
            if (tokenResult.errors) {
                errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
            }
            throw new Error(errorMessage);
        }
    };

    if (enableSaveCard && (enableReserveAndPay || enableReserveOnly)) {
        return (
            <>
                {
                    'Error: Cannot set "enableSaveCard" if "enableReserveAndPay" or "enableReserveOnly" are set'
                }
            </>
        );
    } else if ((enableSaveCard || enableReserveAndPay) && !singleTokenReceiver) {
        return (
            <>
                {
                    'Error: singleTokenReceiver not set. It must be set to use "Reserve & Pay" or "Save Card"'
                }
            </>
        );
    } else if (enableReserveOnly && !doubleTokenReceiver) {
        return <>{'Error: doubleTokenReceiver not set. It must be set to use "Reserve Only"'}</>;
    } else {
        return loadedSquareScript ? (
            <>
                <form id="payment-form">
                    {enableSaveCard ? (
                        <div className="appointmentcard">
                            <div className="appointmentcard-body">
                                <div className="card-title">Card Details</div>
                                <div id="card-container" style={{ marginTop: '1rem' }}></div>
                            </div>
                        </div>
                    ) : (
                        <div className="grid grid-cols-6 gap-10">
                            <div className="col-span-3 flex flex-col">
                                <div className="flex-1">
                                    <div className="card-body">
                                        <p
                                            style={{
                                                paddingLeft: '4px',
                                                paddingBottom: '10px',
                                                fontSize: '14px',
                                                color: '#5e5e5e',
                                            }}>
                                            A credit card is required to book your appointment.
                                            {enableReserveAndPay &&
                                                ' Save time by reserving and paying ahead of time.'}
                                        </p>
                                        <div id="card-container"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <CheckboxWrapper>
                        <Checkbox
                            name="enable-save-card-on-file"
                            label={cardOnFileAuthorizationCheckboxLabel}
                            onChange={(checked: boolean) => {
                                setUserAcceptedCardOnFileTerms(checked);
                            }}
                        />
                    </CheckboxWrapper>
                    <div
                        className="carddetail--footer"
                        style={{ flexDirection: 'column', border: 'none' }}>
                        {children}
                        {enableReserveAndPay && (
                            <button
                                id="reserve-and-pay-button"
                                className="btn"
                                type="button"
                                disabled={loading || fetchingToken || !userAcceptedCardOnFileTerms}>
                                {loading || fetchingToken
                                    ? 'Please wait...'
                                    : reserveAndPayButtonLabel}
                            </button>
                        )}
                        {enableReserveOnly && (
                            <button
                                id="reserve-only-button"
                                className="btn"
                                type="button"
                                style={{ marginTop: '0.5rem' }}
                                disabled={loading || fetchingToken || !userAcceptedCardOnFileTerms}>
                                {loading || fetchingToken
                                    ? 'Please wait...'
                                    : reserveOnlyButtonLabel}
                            </button>
                        )}
                        {enableSaveCard && (
                            <ChargeCardButton
                                id="save-card-button"
                                type="button"
                                disabled={
                                    loading ||
                                    fetchingToken ||
                                    !cardInitialized ||
                                    !userAcceptedCardOnFileTerms
                                }>
                                {loading || fetchingToken || !cardInitialized
                                    ? 'Please wait...'
                                    : 'Save Card'}
                            </ChargeCardButton>
                        )}
                    </div>
                </form>
            </>
        ) : null;
    }
};

const ChargeCardButton = styled.button`
    width: 100%;
    padding: 0.75rem 1rem;
    font-weight: 400;
    display: inline-block;
    border: none;
    border-radius: 0.57rem;
    box-shadow: none;
    cursor: pointer;
    white-space: nowrap;
    transition: all 0.25s ease 0s;
    color: #fff;
    background-color: #0154ff;
    &:disabled {
        background-color: #d6d6d7 !important;
        cursor: not-allowed;
    }
`;

const CheckboxWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export default SquarePaymentForm;
