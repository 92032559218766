import { useState } from 'react';
import { BlockPicker, ColorResult } from 'react-color';
import './ColorPicker.scss';

const ColorPicker = ({ color, onChange }: { color?: string; onChange?(color: string): void }) => {
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [currColor, setCurrColor] = useState(color || '#000000');

    const handleClick = () => {
        setShowColorPicker(prevVal => !prevVal);
    };

    const handleChange = (color: ColorResult) => {
        setCurrColor(color.hex);

        if (onChange) onChange(color.hex);
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        const validKeys = ['Enter'];

        if (validKeys.indexOf(event.key) !== -1) {
            event.preventDefault();
            if (event.key === 'Enter') {
                setShowColorPicker(false);
            }
        }
    };

    return (
        <div className="colorPicker" onKeyDown={handleKeyDown}>
            <div className="colorPicker--swatch" onClick={handleClick}>
                <div className="swatch-color" style={{ backgroundColor: currColor }}></div>
                <div className="swatch-text pl-4">
                    <span className="font-medium pr-2">#</span>
                    <span>{currColor.slice(1)}</span>
                </div>
            </div>
            {showColorPicker && (
                <div className="colorPicker--popover">
                    <BlockPicker color={currColor} onChange={handleChange} />
                </div>
            )}
        </div>
    );
};

export default ColorPicker;
