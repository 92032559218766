export const paymentOptions = [
    {
        code: 'cash',
        label: 'Cash',
        icon: 'fal fa-money-bill',
    },
    {
        code: 'cash_app',
        label: 'Cash App',
        icon: 'fal fa-mobile',
    },
    {
        code: 'paypal',
        label: 'PayPal',
        icon: 'fab fa-cc-paypal',
    },
    {
        code: 'square',
        label: 'Square',
        icon: 'far fa-square',
    },
    {
        code: 'stripe',
        label: 'Stripe',
        icon: 'fab fa-cc-stripe',
    },
    {
        code: 'venmo',
        label: 'Venmo',
        icon: 'fal fa-mobile',
    },
    {
        code: 'zelle',
        label: 'Zelle',
        icon: 'fal fa-mobile',
    },
    {
        code: 'other',
        label: 'Other',
        icon: 'fal fa-money-check-dollar',
    },
];
