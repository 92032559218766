import { useState } from 'react';
import DropdownContext from './DropdownContext';

const Dropdown = ({ children }: { children?: React.ReactNode }) => {
    const [isOpen, setOpen] = useState(true);

    const getContextValue = () => {
        return {
            isOpen: isOpen,
            toggleMenu: toggle,
        };
    };

    const toggle = () => {
        setOpen(prevValue => !prevValue);
    };

    return (
        <DropdownContext value={getContextValue()}>
            <div className="dropdown">{children}</div>
        </DropdownContext>
    );
};

export default Dropdown;
