import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import { NewAppointmentDrawer, EditAppointmentDrawer } from '@components/appointments';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import { v4 as uuid } from 'uuid';
import { ICustomer } from 'interfaces/customer.interface';
import { IService } from 'interfaces/service.interface';
import useTimeIndicator from './useTimeIndicator';
import moment from 'moment';
import './BookingCalendar.scss';
import { AppointmentStatusEnum, IAppointment } from 'interfaces/appointment.interface';
import { LoaderOverlay, useSnackbar } from '@components/common';
import useRouter from 'hooks/router';
import ModifyAppointmentModal from '../Modal/ModifyAppointmentModal';
import { api } from 'helpers/auth-axios';
import { IWorkingHours } from 'interfaces/workinghours.interface';
import DeleteModel from '@components/common/DeleteModel/DeleteModel';
import BlockModel from '../Modal/BlockModel';
import ConflictModal from '@components/appointments/Modal/ConflictModal';
import jquery from 'jquery';
import Pusher from 'pusher-js';

export interface IEvent {
    id: string;
    start: string;
    end: string;
    service: IService;
    customer: ICustomer;
    calendarRef: any;
}
interface IProps {
    initialView: string;
    setView: any;
    dateRange: any;
    setDateRange: any;
    serviceProviderId: any;
    setTeamCalendarDate: any;
    teamCalendarDate: any;
    pickDate: any;
    calendar_size: any;
}

const BookingsCalendar = forwardRef(
    (
        {
            initialView,
            setView,
            dateRange,
            setDateRange,
            serviceProviderId,
            setTeamCalendarDate,
            teamCalendarDate,
            pickDate,
            calendar_size,
        }: IProps,
        ref
    ) => {
        useImperativeHandle(ref, () => ({
            next() {
                next();
            },
            prev() {
                prev();
            },
            today() {
                today();
            },
            changeView(view?: string) {
                changeView(view);
            },
            updateCalendarDate() {
                updateCalendarDate();
            },
        }));
        if (initialView == 'teamGridWeek') {
            initialView = 'timeGridDay';
        }

        const $: JQueryStatic = jquery;
        const router = useRouter();

        const [loading, setLoading] = useState(false);
        const [checkView, setCheckView] = useState<any>();
        const [confirmation, setConfirmation] = useState<any>({
            show: false,
            id: '',
            start: moment().format(),
            end: moment().format(),
            callback: () => {},
        });
        const calendarRef = useRef<FullCalendar>(null);
        const [newAppointment, setNewAppointment] = useState<any>({
            show: false,
            serviceProviderId: serviceProviderId,
            id: '',
            start: moment().format(),
            end: moment().format(),
        });
        const [workingHours, setWorkingHours] = useState<any[]>();
        const [blockDeleteModel, setBlockDeleteModel] = useState<any>(false);
        const [appointmentId, setAppointmentId] = useState<any>();
        const [blockModel, setBlockModel] = useState<any>(false);
        const [blockDate, setBlockDate] = useState<any>();
        const [indicate] = useTimeIndicator();
        const [openSnackbar] = useSnackbar();
        const [conflictDates, setConflictDates] = useState<string[]>([]);
        const [showConflictModal, setShowConflictModal] = useState(false);
        const [appointmentData, setAppointmentData] = useState<any[]>([]);
        var _userData = JSON.parse(localStorage.getItem('user') || '{}');
        //const { business }: { business: IBusiness } = useAppSelector(state => state.business);
        useEffect(() => {
            indicate();
            if (initialView == 'dayGridMonth') {
                monthlyCalenderHeaderDate();
            }
        });

        useEffect(() => {
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef?.current?.getApi();
                calendarApi.gotoDate(moment(teamCalendarDate).format());
                // updateCalendarDate();
            }
        }, []);
        useEffect(() => {
            updateCalendarDate();
        }, [teamCalendarDate]);

        const updateCalendarDate = () => {
            //setTeamCalendarDate(teamCalendarDate);
            let dateFTC = moment(teamCalendarDate).format();
            const calendarApi = calendarRef?.current?.getApi();
            if (
                (calendarApi?.view.type === 'timeGridDay' ||
                    calendarApi?.view.type === 'dayGridMonth') &&
                dateFTC != ''
            ) {
                calendarApi.gotoDate(moment(dateFTC).format('YYYY-MM-DD'));
            } else if (calendarApi?.view.type === 'timeGridWeek' && dateFTC != '') {
                var format = /[–]+/;
                var doFormat: any = '';
                if (format.test(dateFTC)) {
                    doFormat = dateFTC.split(' ');
                    if (doFormat.length !== 0 && doFormat.length === 7) {
                        dateFTC = doFormat[0] + ' ' + doFormat[1] + ',' + ' ' + doFormat[6];
                    } else if (doFormat.length !== 0 && doFormat.length === 6) {
                        dateFTC = doFormat[0] + ' ' + doFormat[1] + ',' + ' ' + doFormat[5];
                    } else if (doFormat.length !== 0 && doFormat.length === 5) {
                        dateFTC = doFormat[0] + ' ' + doFormat[1] + ',' + ' ' + doFormat[4];
                    }
                }
                calendarApi.gotoDate(moment(dateFTC).format('YYYY-MM-DD'));
            }
            changeView(initialView);
        };

        useEffect(() => {
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef.current.getApi();
                if (calendarApi.view.type === 'timeGridDay') {
                    setDateRange(calendarApi.view.title);
                    getDailyAppointments();
                } else if (calendarApi.view.type === 'timeGridWeek') {
                    setDateRange(calendarApi.view.title);
                    getWeeklyAppointments();
                } else if (calendarApi.view.type === 'dayGridMonth') {
                    setDateRange(calendarApi.view.title);
                    getMonthlyAppointments();
                }
            }
            if (newAppointment.show) {
                setNewAppointment({
                    show: false,
                });
                setTimeout(() => {
                    setNewAppointment({
                        show: true,
                    });
                }, 150);
            }
        }, [serviceProviderId]);

        const next = () => {
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef.current.getApi();
                calendarApi.next();
                setDateRange(calendarApi.view.title);
                if (calendarApi.view.type === 'timeGridDay') {
                    const start_date: any = moment(calendarApi.view.activeStart, 'mm/dd/yyyy');
                    setTeamCalendarDate(start_date);

                    return getDailyAppointments(start_date);
                } else if (calendarApi.view.type === 'timeGridWeek') {
                    const start_date: any = moment(calendarApi.view.currentStart, 'mm/dd/yyyy');
                    setTeamCalendarDate(start_date);

                    return getWeeklyAppointments(start_date);
                } else if (calendarApi.view.type === 'dayGridMonth') {
                    const start_date: any = moment(calendarApi.view.currentStart, 'mm/dd/yyyy');
                    setTeamCalendarDate(start_date);

                    return getMonthlyAppointments(start_date);
                }
            }
        };

        const prev = () => {
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef.current.getApi();
                calendarApi.prev();
                setDateRange(calendarApi.view.title);

                if (calendarApi.view.type === 'timeGridDay') {
                    const start_date: any = moment(calendarApi.view.activeStart, 'mm/dd/yyyy');
                    setTeamCalendarDate(start_date);

                    return getDailyAppointments(start_date);
                } else if (calendarApi.view.type === 'timeGridWeek') {
                    const start_date: any = moment(calendarApi.view.currentStart, 'mm/dd/yyyy');
                    setTeamCalendarDate(start_date);

                    return getWeeklyAppointments(start_date);
                } else if (calendarApi.view.type === 'dayGridMonth') {
                    const start_date: any = moment(calendarApi.view.currentStart, 'mm/dd/yyyy');
                    setTeamCalendarDate(start_date);

                    return getMonthlyAppointments(start_date);
                }
            }
        };

        const today = () => {
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef.current.getApi();
                calendarApi.today();
                setDateRange(calendarApi.view.title);
                if (calendarApi.view.type === 'timeGridDay') {
                    const start_date: any = moment(calendarApi.view.activeStart, 'mm/dd/yyyy');
                    setTeamCalendarDate(start_date);

                    return getDailyAppointments(start_date);
                } else if (calendarApi.view.type === 'timeGridWeek') {
                    const start_date: any = moment(calendarApi.view.currentStart, 'mm/dd/yyyy');
                    setTeamCalendarDate(start_date);

                    return getWeeklyAppointments(start_date);
                } else if (calendarApi.view.type === 'dayGridMonth') {
                    const start_date: any = moment(calendarApi.view.currentStart, 'mm/dd/yyyy');
                    setTeamCalendarDate(start_date);

                    return getMonthlyAppointments(start_date);
                }
            }
        };

        const changeView = (view?: string) => {
            setCheckView(view);
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef.current.getApi();
                if (view) calendarApi.changeView(view);
                setView(calendarApi.view.type);
                setDateRange(calendarApi.view.title);
                // if (view === 'timeGridDay') {
                //     setDateFTC(calendarApi.view.title);
                // }
            }
            if (view === 'timeGridDay') {
                return getDailyAppointments();
            } else if (view === 'timeGridWeek') {
                return getWeeklyAppointments();
            } else if (view === 'dayGridMonth') {
                return getMonthlyAppointments();
            } else {
                return null;
            }
        };
        const monthlyCalenderHeaderDate = () => {
            const weekClasses = [
                '.fc-day-sun',
                '.fc-day-mon',
                '.fc-day-tue',
                '.fc-day-wed',
                '.fc-day-thu',
                '.fc-day-fri',
                '.fc-day-sat',
            ];
            let week = 0;
            for (let i = 0; i < weekClasses.length; i++) {
                let headerDate: any = $(`th${weekClasses[week]} div a`).text();
                let dateList = headerDate.split(',');
                $(`th${weekClasses[week]} div a`).text('');
                $(`th${weekClasses[week]} div a`).text(dateList[0]);
                week = week + 1;
            }
        };

        const addEvent = (event: any) => {
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef.current.getApi();
                calendarApi.addEvent(event);
            }
        };

        const removeAllEvent = () => {
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef.current.getApi();
                calendarApi.removeAllEvents();
            }
        };

        const editEvent = (event: any) => {
            removeEvent(event);
            addEvent(event);
        };
        const removeEvent = (event: any) => {
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef.current.getApi();
                calendarApi.getEventById(event.id)?.remove();
            }
        };

        const getDailyAppointments = (start_date = '') => {
            let fetched = false;
            if (!fetched) {
                removeAllEvent();
                if (serviceProviderId !== undefined) {
                    setLoading(true);
                    let date: any = moment(start_date ? start_date : teamCalendarDate).format();
                    api.post(`/appointments/daily/${serviceProviderId}`, { date: date }).then(
                        (res: any) => {
                            if (res.error) {
                                return;
                            }
                            removeAllEvent();
                            if (res.data !== undefined) {
                                setAppointmentData(res.data.dailyAppointments);
                                setWorkingHours(res.data.customizeWorkingHours);
                            }
                            setLoading(false);
                        }
                    );
                }
            }
        };

        const getWeeklyAppointments = (start_date = '') => {
            let fetched = false;
            if (!fetched) {
                removeAllEvent();
                if (serviceProviderId !== undefined) {
                    setLoading(true);
                    let date: any = moment(start_date ? start_date : teamCalendarDate).format();
                    api.post(`/appointments/weekly/${serviceProviderId}`, { date: date }).then(
                        (res: any) => {
                            if (res.error) {
                                return;
                            }
                            removeAllEvent();
                            setAppointmentData(res.data.weeklyAppointments);
                            setWorkingHours(res.data.customizeWorkingHours);
                            setLoading(false);
                        }
                    );
                }
            }
        };

        const getMonthlyAppointments = (start_date = '') => {
            let fetched = false;
            if (!fetched) {
                removeAllEvent();
                if (serviceProviderId !== undefined) {
                    setLoading(true);
                    let date: any = moment(start_date ? start_date : teamCalendarDate).format();
                    api.post(`/appointments/monthly/${serviceProviderId}`, { date: date }).then(
                        (res: any) => {
                            if (res.error) {
                                return;
                            }
                            removeAllEvent();
                            setAppointmentData(res.data.monthlyAppointments);
                            setWorkingHours(res.data.customizeWorkingHours);
                            setLoading(false);
                        }
                    );
                }
            }
        };

        useEffect(() => {
            if (appointmentData?.length) {
                appointmentData?.forEach((appointment: any) => {
                    addEvent({
                        id: appointment._id,
                        start: moment(appointment?.booked_from_iso).format(),
                        end: moment(appointment?.booked_till_iso).format(),
                        service: appointment?.service,
                        customer: appointment?.customer,
                        is_blocked: appointment?.is_blocked,
                        block_reason: appointment?.block_reason,
                        appointmentId: appointment?._id,
                        is_vip: appointment?.is_vip,
                        status: appointment?.status,
                        payment: appointment?.payment,
                    });
                });
            }
        }, [appointmentData]);
        const renderEventContent = (arg: any) => {
            const props = arg.event.extendedProps;
            const duration = moment
                .duration((arg.event.end as any) - (arg.event.start as any))
                .asMinutes();

            return (
                <div
                    className={`booking_event ${
                        props.is_blocked == true ? 'block_bg' : ''
                    } service-color-${props.service?.color}--bg`}>
                    <div
                        className={`booking_eventBorder service-color-${props.service?.color}--bd`}></div>
                    {duration <= 15 &&
                    (checkView === 'timeGridDay' || checkView === 'timeGridWeek') ? (
                        <div className={`booking_eventDetails`}>
                            <div className="booking_serviceCustomer">
                                {props?.payment && <i className="far fa-usd-circle "></i>}{' '}
                                {props.is_vip == true && <>vip - </>}
                                {props.is_blocked === true
                                    ? props.block_reason !== ''
                                        ? props.block_reason
                                        : 'Block'
                                    : ''}{' '}
                                {props.customer
                                    ? props.customer?.firstname?.substring(0, 10)
                                    : props.is_blocked === false && 'customer removed'}{' '}
                                {props.customer?.lastname?.substring(0, 10)}{' '}
                            </div>
                        </div>
                    ) : checkView === 'timeGridWeek' || checkView === 'dayGridMonth' ? (
                        <div className={`booking_eventDetails`}>
                            <div className="booking_serviceCustomer">
                                <div>
                                    {props.is_blocked === true
                                        ? props.block_reason !== ''
                                            ? props.block_reason
                                            : 'Block'
                                        : ''}{' '}
                                    {props?.payment && <i className="far fa-usd-circle "></i>}{' '}
                                    {props.is_vip == true && <>vip - </>}
                                    {props.customer
                                        ? props.customer?.firstname?.substring(0, 10)
                                        : props.is_blocked === false && 'customer removed'}{' '}
                                    {props.customer?.lastname?.substring(0, 10)}{' '}
                                    {checkView !== undefined && checkView === 'dayGridMonth' ? (
                                        <br />
                                    ) : (
                                        ''
                                    )}
                                    {props.is_blocked === true
                                        ? ''
                                        : props?.service == null
                                        ? '(' + 'service removed' + ')'
                                        : '(' + props?.service?.name?.substring(0, 10) + ')'}
                                </div>

                                {props?.status === AppointmentStatusEnum.CANCELLED ? (
                                    <div
                                        className="booking_serviceCustomer"
                                        style={{
                                            color: 'red',
                                            textTransform: 'capitalize',
                                            fontSize: '0.7rem',
                                        }}>
                                        {props?.status}
                                    </div>
                                ) : (
                                    props.is_blocked === false && (
                                        <div
                                            className="booking_serviceCustomer"
                                            style={{
                                                textTransform: 'capitalize',
                                                fontSize: '0.7rem',
                                            }}>
                                            {props?.status}
                                        </div>
                                    )
                                )}
                            </div>
                            <div
                                className={`${
                                    _userData?.user?.business_id?.time_select === '12'
                                        ? 'booking_week_time'
                                        : 'booking_time'
                                } `}>
                                {moment(arg.event.startStr).format(
                                    _userData?.user?.business_id?.time_select === '12'
                                        ? 'hh:mm a'
                                        : 'HH:mm'
                                )}{' '}
                                -{' '}
                                {moment(arg.event.endStr).format(
                                    _userData?.user?.business_id?.time_select === '12'
                                        ? 'hh:mm a'
                                        : 'HH:mm'
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className={`booking_eventDetails`}>
                            <div className="booking_serviceCustomer" style={{ display: 'flex' }}>
                                <div>
                                    {props.is_blocked === true
                                        ? props.block_reason !== ''
                                            ? props.block_reason
                                            : 'Block'
                                        : ''}{' '}
                                    {props?.payment && <i className="far fa-usd-circle "></i>}{' '}
                                    {props.is_vip == true && <>vip - </>}
                                    {props.customer
                                        ? props.customer?.firstname
                                        : props.is_blocked === false && 'customer removed'}{' '}
                                    {props.customer?.lastname}{' '}
                                    {checkView !== undefined && checkView === 'dayGridMonth' ? (
                                        <br />
                                    ) : (
                                        ''
                                    )}
                                    {props.is_blocked === true
                                        ? ''
                                        : props?.service == null
                                        ? '(' + 'service removed' + ')'
                                        : '(' + props?.service?.name + ')'}
                                    {/* {props.service ? '(' + props.service.name + ')' : ''} */}
                                </div>
                                &nbsp;
                            </div>

                            {props?.status === AppointmentStatusEnum.CANCELLED ? (
                                <div
                                    className="booking_serviceCustomer"
                                    style={{
                                        color: 'red',
                                        textTransform: 'capitalize',
                                        fontSize: '0.7rem',
                                    }}>
                                    {props?.status}
                                </div>
                            ) : (
                                props.is_blocked === false && (
                                    <div
                                        className="booking_serviceCustomer"
                                        style={{ textTransform: 'capitalize', fontSize: '0.7rem' }}>
                                        {props?.status}
                                    </div>
                                )
                            )}

                            <div className={`${'booking_time'}`}>
                                {moment(arg.event.startStr).format(
                                    _userData?.user?.business_id?.time_select === '12'
                                        ? 'hh:mm a'
                                        : 'HH:mm'
                                )}{' '}
                                -{' '}
                                {moment(arg.event.endStr).format(
                                    _userData?.user?.business_id?.time_select === '12'
                                        ? 'hh:mm a'
                                        : 'HH:mm'
                                )}
                            </div>
                        </div>
                    )}
                </div>
            );
        };
        const deleteBlock = () => {
            setLoading(true);
            api.delete(`/appointments/${appointmentId}`)
                .then(() => {
                    setLoading(false);
                    openSnackbar('Block deleted successfully!');
                    setBlockDeleteModel(false);
                    if (calendarRef.current !== null) {
                        const calendarApi = calendarRef.current.getApi();
                        if (calendarApi.view.type === 'timeGridDay') {
                            getDailyAppointments();
                        } else if (calendarApi.view.type === 'timeGridWeek') {
                            getWeeklyAppointments();
                        } else if (calendarApi.view.type === 'dayGridMonth') {
                            getMonthlyAppointments();
                        }
                    }
                })
                .catch((res: any) => {
                    openSnackbar('Failed to delete Block!');
                });
        };
        $('.fc-timegrid-slot-lane,.fc-timegrid-slot-label').hover(function () {
            var getDefaultTime = $(this).attr('data-time');
            var formatedTime = moment(
                moment().format() + ' ' + getDefaultTime,
                'YYYY-MM-DD HH:mm:ss'
            ).format(_userData.user.business_id.time_select === '12' ? 'hh:mm a' : 'HH:mm a');
            $(this).attr('data-time', formatedTime);
        });
        const removeEventFromPusher = (event: any) => {
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef.current.getApi();
                calendarApi.getEventById(event._id)?.remove();
            }
        };
        // Pusher
        useEffect(() => {
            if (serviceProviderId === undefined) return;
            const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY!, {
                cluster: 'ap2',
            });
            let businessId = _userData?.user?.business_id?._id;
            const channel = pusher.subscribe(businessId);
            channel.bind('barberOne', function (data: any) {
                if (calendarRef.current !== null) {
                    const calendarApi = calendarRef.current.getApi().getEvents();
                    const eventsData: any = calendarApi.map((data: any) => data._def.publicId);
                    if (data?.rescheduleAppointment) {
                        let checkServiceProvider =
                            serviceProviderId !== undefined &&
                            (serviceProviderId ===
                                data.rescheduleAppointment?.serviceProvider._id ||
                                serviceProviderId === data.rescheduleAppointment?.serviceProvider);
                        if (!checkServiceProvider) {
                            return;
                        }
                        editEvent({
                            id: data.rescheduleAppointment._id,
                            start: moment(data?.rescheduleAppointment?.booked_from_iso).format(),
                            end: moment(data?.rescheduleAppointment?.booked_till_iso).format(),
                            service: data?.rescheduleAppointment?.service,
                            customer: data?.rescheduleAppointment?.customer,
                            is_blocked: data?.rescheduleAppointment?.is_blocked,
                            block_reason: data?.rescheduleAppointment?.block_reason,
                            is_vip: data?.rescheduleAppointment?.is_vip,
                            status: data?.rescheduleAppointment?.status,
                            payment: data?.rescheduleAppointment?.payment,
                        });
                    }
                    if (data?.cancelledAppointment) {
                        let checkServiceProvider =
                            serviceProviderId !== undefined &&
                            (serviceProviderId === data.cancelledAppointment?.serviceProvider._id ||
                                serviceProviderId === data.cancelledAppointment?.serviceProvider);
                        if (!checkServiceProvider) {
                            return;
                        }
                        editEvent({
                            id: data.cancelledAppointment._id,
                            start: moment(data?.cancelledAppointment?.booked_from_iso).format(),
                            end: moment(data?.cancelledAppointment?.booked_till_iso).format(),
                            service: data?.cancelledAppointment?.service,
                            customer: data?.cancelledAppointment?.customer,
                            is_blocked: data?.cancelledAppointment?.is_blocked,
                            block_reason: data?.cancelledAppointment?.block_reason,
                            is_vip: data?.cancelledAppointment?.is_vip,
                            status: data?.cancelledAppointment?.status,
                            payment: data?.cancelledAppointment?.payment,
                        });
                    }
                    if (data?.deleteAppointment) {
                        data?.deleteAppointment?.map((data: any) => {
                            removeEventFromPusher(data);
                        });
                    }
                    if (data?.newAppointment?.length) {
                        let checkServiceProvider =
                            serviceProviderId !== undefined &&
                            (serviceProviderId === data.newAppointment[0]?.serviceProvider._id ||
                                serviceProviderId === data.newAppointment[0]?.serviceProvider);
                        if (!checkServiceProvider) {
                            return;
                        }
                        // if the new appointment/block is already on calendar, don't add again
                        let obj = eventsData.find((x: any) => x === data?.newAppointment[0]?._id);
                        if (obj !== undefined) {
                            return;
                        }
                        if (data?.newAppointment[0].is_blocked === true) {
                            data?.newAppointment?.map((event: any) => {
                                addEvent({
                                    id: event?._id,
                                    start: moment(event?.booked_from_iso).format(),
                                    end: moment(event?.booked_till_iso).format(),
                                    service: event?.service,
                                    customer: event?.customer,
                                    is_blocked: event?.is_blocked,
                                    block_reason: event?.block_reason,
                                    is_vip: event?.is_vip,
                                    status: event?.status,
                                });
                            });
                        } else {
                            data?.newAppointment?.map((event: any) => {
                                addEvent({
                                    id: event?._id,
                                    start: moment(event?.booked_from_iso).format(),
                                    end: moment(event?.booked_till_iso).format(),
                                    service: event?.service,
                                    customer: event?.customer,
                                    is_blocked: event?.is_blocked,
                                    block_reason: event?.block_reason,
                                    is_vip: event?.is_vip,
                                    status: event?.status,
                                    payment: event?.payment,
                                });
                            });
                        }
                    }
                }
            });
        }, [serviceProviderId]);
        return (
            <Wrapper>
                {loading && <LoaderOverlay />}
                <Flex>
                    <FullCalendar
                        plugins={[
                            dayGridPlugin,
                            timeGridPlugin,
                            interactionPlugin,
                            momentTimezonePlugin,
                        ]}
                        initialView={initialView}
                        slotDuration={`00:${calendar_size !== undefined ? calendar_size : '15'}:00`}
                        // snapDuration={}
                        allDaySlot={false}
                        nowIndicator={true}
                        slotLabelInterval="1:00"
                        height="100%"
                        headerToolbar={{
                            left: '',
                            center: '',
                            right: '',
                        }}
                        dayHeaderFormat={{
                            weekday: 'short',
                            month: 'short',
                            day: 'numeric',
                            omitCommas: false,
                        }}
                        slotLabelFormat={{
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: _userData.user.business_id.time_select === '12' ? true : false,
                        }}
                        scrollTime={moment().subtract(2, 'h').format('HH') + ':00'}
                        editable={true}
                        timeZone={
                            _userData?.user?.business_id
                                ? _userData?.user?.business_id?.timezone
                                : 'America/Chicago'
                        }
                        eventDurationEditable={true}
                        displayEventTime={true}
                        eventTimeFormat={{
                            hour: 'numeric',
                            minute: '2-digit',
                            meridiem: 'short',
                        }}
                        selectable={true}
                        selectMirror={true}
                        longPressDelay={150}
                        select={args => {
                            const id = uuid();
                            setNewAppointment({
                                show: true,
                                id: id,
                                start: moment(args.startStr).format(),
                                end: moment(args.endStr).format(),
                            });
                            setBlockDate(args.startStr);
                        }}
                        businessHours={
                            workingHours?.length !== 0
                                ? workingHours
                                : { daysOfWeek: [], startTime: '08:00', endTime: '17:00' }
                        }
                        eventContent={renderEventContent}
                        eventClassNames={function (arg) {
                            return arg.event.extendedProps.classNames;
                        }}
                        eventClick={arg => {
                            arg.event._def.extendedProps.is_blocked === true
                                ? setBlockDeleteModel(true)
                                : router.setParams({ appointment: arg.event.id });
                            setAppointmentId(arg.event.id);
                            setNewAppointment({
                                show: false,
                            });
                        }}
                        eventDrop={(info: any) => {
                            setConfirmation({
                                show: true,
                                id: info.event.id,
                                start: info.event.start,
                                end: info.event.end,
                                callback: info.revert,
                            });
                        }}
                        eventResize={(info: any) => {
                            setConfirmation({
                                show: true,
                                id: info.event.id,
                                start: info.event.start,
                                end: info.event.end,
                                callback: info.revert,
                            });
                        }}
                        ref={calendarRef}
                    />
                </Flex>
                {newAppointment.show && (
                    <NewAppointmentDrawer
                        serviceProviderId={serviceProviderId}
                        id={newAppointment.id}
                        startDate={newAppointment.start}
                        endDate={newAppointment.end}
                        conflictDate={val => {
                            setConflictDates(val);
                        }}
                        conflictModel={(val: any) => {
                            setShowConflictModal(val);
                        }}
                        setBlockModel={() => {
                            setBlockModel(true);
                            setNewAppointment((prevState: any) => ({
                                ...prevState,
                                show: false,
                            }));
                        }}
                        onClose={(isAppointmentAdded: any, event: any) => {
                            setNewAppointment((prevState: any) => ({
                                ...prevState,
                                show: false,
                            }));
                            router.setParams({ appointment: '' });
                            if (!isAppointmentAdded) {
                                removeEvent(event);
                            }
                        }}
                        onSave={(event: any, appointment: IAppointment) => {
                            router.navigate('');
                            if (calendarRef.current !== null) {
                                const calendarApi = calendarRef.current.getApi();
                                if (calendarApi.view.type === 'timeGridDay') {
                                    getDailyAppointments();
                                } else if (calendarApi.view.type === 'timeGridWeek') {
                                    getWeeklyAppointments();
                                } else if (calendarApi.view.type === 'dayGridMonth') {
                                    getMonthlyAppointments();
                                }
                            }
                            removeEvent(event);
                        }}
                        onChange={(event: IEvent) => {
                            // editEvent(event);
                        }}
                    />
                )}
                {newAppointment.show == true
                    ? ''
                    : router.query.appointment && (
                          <EditAppointmentDrawer
                              getAppointments={(event: IAppointment) => {
                                  router.navigate('');
                                  if (calendarRef.current !== null) {
                                      const calendarApi = calendarRef.current.getApi();
                                      if (calendarApi.view.type === 'timeGridDay') {
                                          getDailyAppointments();
                                      } else if (calendarApi.view.type === 'timeGridWeek') {
                                          getWeeklyAppointments();
                                      } else if (calendarApi.view.type === 'dayGridMonth') {
                                          getMonthlyAppointments();
                                      }
                                  }
                              }}
                              id={router.query.appointment as string}
                              onClose={() => {
                                  router.navigate('');
                                  if (calendarRef.current !== null) {
                                      const calendarApi = calendarRef.current.getApi();
                                      if (calendarApi.view.type === 'timeGridDay') {
                                          getDailyAppointments();
                                      } else if (calendarApi.view.type === 'timeGridWeek') {
                                          getWeeklyAppointments();
                                      } else if (calendarApi.view.type === 'dayGridMonth') {
                                          getMonthlyAppointments();
                                      }
                                  }
                              }}
                              onChange={(event: IAppointment) => {
                                  editEvent(event);
                              }}
                              setCalendarLoading={(value: boolean) => setLoading(value)}
                          />
                      )}
                {confirmation.show && (
                    <ModifyAppointmentModal
                        id={confirmation.id}
                        startDate={confirmation.start}
                        endDate={confirmation.end}
                        onDragDate={''}
                        serviceProvider={false}
                        serviceProvierData={''}
                        onSave={() => {
                            setConfirmation((prevState: any) => ({
                                ...prevState,
                                show: false,
                            }));
                            router.navigate('');
                            if (calendarRef.current !== null) {
                                const calendarApi = calendarRef.current.getApi();
                                if (calendarApi.view.type === 'timeGridDay') {
                                    getDailyAppointments();
                                } else if (calendarApi.view.type === 'timeGridWeek') {
                                    getWeeklyAppointments();
                                } else if (calendarApi.view.type === 'dayGridMonth') {
                                    getMonthlyAppointments();
                                }
                            }
                        }}
                        onClose={() => {
                            confirmation.callback();
                            setConfirmation((prevState: any) => ({
                                ...prevState,
                                show: false,
                            }));
                        }}
                    />
                )}
                {blockModel && (
                    <BlockModel
                        serviceProviderId={serviceProviderId}
                        blockDate={blockDate}
                        startTime={newAppointment.start}
                        endTime={newAppointment.end}
                        time_select={_userData?.user?.business_id?.time_select}
                        onClose={() => {
                            router.navigate('');
                            if (calendarRef.current !== null) {
                                const calendarApi = calendarRef.current.getApi();
                                if (calendarApi.view.type === 'timeGridDay') {
                                    getDailyAppointments();
                                } else if (calendarApi.view.type === 'timeGridWeek') {
                                    getWeeklyAppointments();
                                } else if (calendarApi.view.type === 'dayGridMonth') {
                                    getMonthlyAppointments();
                                }
                            }
                            setBlockModel(false);
                        }}
                    />
                )}
                {blockDeleteModel && (
                    <DeleteModel
                        id={''}
                        onDelete={() => {
                            setBlockDeleteModel(false);
                        }}
                        onClose={() => {
                            setBlockDeleteModel(false);
                        }}
                        name="Block"
                        content=" Are you sure you want to delete this Block?"
                        loading={loading}
                        onCLick={() => {
                            deleteBlock();
                        }}
                    />
                )}
                {showConflictModal == true && (
                    <ConflictModal
                        dates={conflictDates}
                        recurringConflict={showConflictModal}
                        onContinue={() => {
                            setShowConflictModal(false);
                        }}
                        onClose={() => setShowConflictModal(false)}
                    />
                )}
            </Wrapper>
        );
    }
);
const Wrapper = styled.div`
    flex-direction: column !important;
    flex: 1 1 0% !important;
    display: flex !important;
`;
const Flex = styled.div`
    flex: 1 1 0% !important;
`;
export default BookingsCalendar;
