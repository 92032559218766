import { Dispatch } from 'redux';

const saveCalenderCurrentView = (name: string) => (dispatch: Dispatch) => {
    dispatch({
        type: 'current_view',
        current_view: name,
    });
};

export const calenderActions = {
    saveCalenderCurrentView,
};
