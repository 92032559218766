import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { UserNav } from '@components/common';
import Select from '../../common/Select/CalenderSelect';
import { Dropdown, DropdownMenu, DropdownToggle } from '@components/common';
import s from '../../common/UserNav/UserNav.module.scss';
import { api } from 'helpers/auth-axios';
import { palette } from 'styled/common';
import { media } from 'styled-bootstrap-grid';
import AsideMobile from '../../common/AsideMobile/AsideMobile';
import { useAppDispatch } from 'hooks/redux';
import { calenderActions } from '../../../actions/calender';
import moment from 'moment';
import { IServiceProvider } from 'interfaces/team/serviceProvider.interface';
interface IProps {
    goNext: () => void;
    goPrev: () => void;
    goToday: () => void;
    currentView: string;
    changeView: (view: string) => void;
    dateRange: any;
    setServiceProviderId: any;
    showTeamCalendar: boolean;
    setPickDate: any;
    setCalendar_Size: any;
    setTeamCalendarDate: any;
    teamCalendarDate: any;
    setDateRange: any;
}

const calendarViews = [
    {
        name: 'timeGridDay',
        icon: 'fal fa-calendar-day',
    },
    {
        name: 'timeGridWeek',
        icon: 'fal fa-calendar-week',
    },
    {
        name: 'dayGridMonth',
        icon: 'fal fa-calendar-alt',
    },
    {
        name: 'teamGridWeek',
        icon: 'fal fa-calendar-day',
    },
];

const CalendarHeader = ({
    goNext,
    goPrev,
    goToday,
    currentView,
    changeView,
    dateRange,
    showTeamCalendar,
    setServiceProviderId,
    setCalendar_Size,
    setPickDate,
    setTeamCalendarDate,
    teamCalendarDate,
    setDateRange,
}: IProps) => {
    const dispatch = useAppDispatch();
    const [currentCalendarView, setCurrentCalendarView] = useState(currentView);
    const [serviceProvider, setServiceProvider] = useState<any>([]);
    const [selectedServiceProvider, setSelectedServiceProvider] = useState(null);

    const [openCalenderPopup, setOpenCalenderPopup] = useState(false);
    const [openNotificationPopup, setOpenNotificationPopup] = useState(false);
    const [openAuthPopup, setOpenAuthPopup] = useState(false);
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const [open, setOpen] = useState(false);
    const [selectId, setSelectId] = useState<any>();

    useEffect(() => {
        getAllServiceProvider();
    }, []);

    const providerValue = (e: any) => {
        setServiceProviderId(e.id);
        setSelectedServiceProvider(e.label);
        setCalendar_Size(e.calendar_size);
    };

    const calenderView = (name: string) => {
        if (name === 'teamGridWeek') {
            setCurrentCalendarView('teamGridWeek');
        } else {
            setCurrentCalendarView(name);
        }
        changeView(name);
        dispatch(calenderActions.saveCalenderCurrentView(name));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getAllServiceProvider = () => {
        api.get(`/serviceProvider?page=${1}&pageSize=${20}`).then((res: any) => {
            setServiceProvider(checkServiceProviderIndexesAndSort(res.data.serviceProvider));
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            if (res.data.serviceProvider.length) {
                setServiceProviderId(res.data.serviceProvider[0]._id);
                setCalendar_Size(res.data.serviceProvider[0].calendar_size);
            }
        });
    };
    const calendar: { label: string; id: any; icon: any; calendar_size: any }[] = [];

    serviceProvider.map((data: any) => {
        calendar.push({
            label: `${data.name}`,
            id: `${data._id}`,
            icon: `${data.photo}`,
            calendar_size: `${data.calendar_size}`,
        });
    });
    const openAsidNew = () => {
        if (open) {
            setOpen(false);
        } else if (open === false) {
            setOpen(true);
        }
    };

    const checkServiceProviderIndexesAndSort = (list: IServiceProvider[]) => {
        // order services by their currently set index
        list.sort((provider1, provider2) => provider1.index - provider2.index);
        // go over array again, any value less than 0 should be set to next available
        // index and moved to the end of the list
        while (list[0]?.index < 0) {
            const nextAvailableIndex = list[list.length - 1].index + 1;
            // remove element from beginning of list
            const [elem] = list.splice(0, 1);
            // update element's index
            elem.index = nextAvailableIndex;
            // add element to end of list
            list.push(elem);
        }

        return list;
    };

    const openCalenderDrop = () => {
        if (openCalenderPopup) {
            setOpenCalenderPopup(false);
        } else {
            setOpenAuthPopup(false);
            setOpenNotificationPopup(false);
            setOpenCalenderPopup(true);
        }
    };
    const openNotification = () => {
        if (openNotificationPopup) {
            setOpenNotificationPopup(false);
        } else {
            setOpenAuthPopup(false);
            setOpenNotificationPopup(true);
            setOpenCalenderPopup(false);
        }
    };
    const openAuth = () => {
        if (openAuthPopup) {
            setOpenAuthPopup(false);
        } else {
            setOpenAuthPopup(true);
            setOpenNotificationPopup(false);
            setOpenCalenderPopup(false);
        }
    };
    const CalendarDate = (e: any) => {
        if (e.target.value) {
            setTeamCalendarDate(e.target.value);
            setDateRange(e.target.value);
        }
    };
    const dateList = () => {
        var format = /[–]+/;
        var doFormat: any = '';

        if (format.test(dateRange)) {
            doFormat = dateRange.split(' ');
            if (doFormat.length !== 0 && doFormat.length === 7) {
                dateRange = doFormat[0] + ' ' + doFormat[1] + ',' + ' ' + doFormat[6];
            } else if (doFormat.length !== 0 && doFormat.length === 6) {
                dateRange = doFormat[0] + ' ' + doFormat[1] + ',' + ' ' + doFormat[5];
            } else if (doFormat.length !== 0 && doFormat.length === 5) {
                dateRange = doFormat[0] + ' ' + doFormat[1] + ',' + ' ' + doFormat[4];
            }
        }
    };
    if (currentCalendarView === 'teamGridWeek') {
        dateList();
        // dateRange = moment(dateRange).format('ddd, MMMM DD, YYYY');
    }
    return (
        <>
            <CalendarHead>
                <Flex>
                    <Bars onClick={openAsidNew}>
                        <BarIcon className="far fa-bars"></BarIcon>
                    </Bars>
                </Flex>
                <TodayFlex>
                    <TodayButton onClick={goToday}>Today</TodayButton>
                    <Section>
                        <LeftArrow onClick={goPrev}>
                            <i className="fas fa-chevron-left"></i>
                        </LeftArrow>
                        <RightArrow onClick={goNext}>
                            <i className="fas fa-chevron-right"></i>
                        </RightArrow>
                    </Section>
                    <CalenderDate>
                        <Date>
                            {' '}
                            {dateRange}
                            {/* {moment(dateRange).format('MMMM, DD YYYY')} */}
                            {/* {currentCalendarView === 'teamGridWeek'
                                ? moment(dateRange).format('ddd, MMMM, DD YYYY')
                                : dateRange} */}
                        </Date>
                        <input
                            type="date"
                            value={moment(teamCalendarDate).format('YYYY-MM-DD')}
                            onChange={(e: any) => CalendarDate(e)}
                        />
                    </CalenderDate>
                </TodayFlex>
                {!showTeamCalendar &&
                (_userData?.user_login == 'user' ||
                    _userData?.user_login == 'teamMember' ||
                    _userData?.user_login == 'others') ? (
                    <SelectFlex>
                        {calendar.length > 0 ? (
                            <Select
                                // disabled={true}
                                id={selectId ? selectId : calendar[0].id}
                                value={
                                    selectedServiceProvider != null
                                        ? selectedServiceProvider
                                        : calendar[0].label
                                }
                                options={calendar}
                                onChange={(e: any) => {
                                    providerValue(e);
                                    setSelectId(e?.id);
                                }}
                            />
                        ) : (
                            ''
                        )}
                    </SelectFlex>
                ) : (
                    ''
                )}
                <MobileCalenderOption>
                    <Dropdown>
                        <DropdownToggle onClick={openCalenderDrop}>
                            <CalendarIcon className={`${s.actionBtn}`}>
                                <i className="far fa-calendar-check"></i>
                            </CalendarIcon>
                        </DropdownToggle>
                        {openCalenderPopup && (
                            <DropdownMenu className={s.calender_menu} scrollable={false}>
                                <Cover>
                                    <CoverHeading>{dateRange}</CoverHeading>
                                    <input
                                        type="date"
                                        value={moment(teamCalendarDate).format('YYYY-MM-DD')}
                                        onChange={(e: any) => CalendarDate(e)}
                                    />
                                </Cover>
                                <SettingListBody>
                                    <TodayList>
                                        {/* {!showTeamCalendar && ( */}
                                        <>
                                            {' '}
                                            <TodayButton onClick={goToday}>Today</TodayButton>
                                            <Section>
                                                <LeftArrow onClick={goPrev}>
                                                    <i className="fas fa-chevron-left"></i>
                                                </LeftArrow>
                                                <RightArrow onClick={goNext}>
                                                    <i className="fas fa-chevron-right"></i>
                                                </RightArrow>
                                            </Section>
                                        </>
                                        {/* )} */}
                                    </TodayList>
                                    {(calendar.length > 0 && _userData.user_login == 'user') ||
                                    _userData.user_login == 'teamMember' ||
                                    _userData?.user_login == 'others' ? (
                                        <SelectList>
                                            <Select
                                                // disabled={true}
                                                id={selectId ? selectId : calendar[0].id}
                                                value={
                                                    selectedServiceProvider != null
                                                        ? selectedServiceProvider
                                                        : calendar[0].label
                                                }
                                                options={calendar}
                                                onChange={(e: any) => {
                                                    providerValue(e);
                                                    setSelectId(e?.id);
                                                }}
                                            />
                                        </SelectList>
                                    ) : (
                                        ''
                                    )}
                                    <List>
                                        <CalendarsIcons>
                                            {calendarViews.map((view, i) => (
                                                <div key={i}>
                                                    {currentCalendarView === view.name ? (
                                                        <>
                                                            {_userData.user_login != 'user' &&
                                                            view.name != 'teamGridWeek' ? (
                                                                <CalandersPrimary
                                                                    key={i}
                                                                    onClick={() =>
                                                                        calenderView(view.name)
                                                                    }>
                                                                    <Icon
                                                                        className={`${view.icon}`}></Icon>
                                                                </CalandersPrimary>
                                                            ) : _userData.user_login == 'user' ||
                                                              _userData.user_login ==
                                                                  'teamMember' ||
                                                              _userData.user_login == 'others' ? (
                                                                <CalandersPrimary
                                                                    key={i}
                                                                    onClick={() =>
                                                                        calenderView(view.name)
                                                                    }>
                                                                    <Icon
                                                                        className={`${view.icon}`}></Icon>
                                                                </CalandersPrimary>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {' '}
                                                            {_userData.user_login != 'user' &&
                                                            view.name != 'teamGridWeek' ? (
                                                                <CalandersSecondary
                                                                    key={i}
                                                                    onClick={() =>
                                                                        calenderView(view.name)
                                                                    }>
                                                                    <Icon
                                                                        className={`${view.icon}`}></Icon>
                                                                </CalandersSecondary>
                                                            ) : _userData.user_login == 'user' ||
                                                              _userData.user_login ==
                                                                  'teamMember' ||
                                                              _userData.user_login == 'others' ? (
                                                                <CalandersSecondary
                                                                    key={i}
                                                                    onClick={() =>
                                                                        calenderView(view.name)
                                                                    }>
                                                                    <Icon
                                                                        className={`${view.icon}`}></Icon>
                                                                </CalandersSecondary>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            ))}
                                        </CalendarsIcons>
                                    </List>
                                </SettingListBody>
                            </DropdownMenu>
                        )}
                    </Dropdown>
                </MobileCalenderOption>
                <Flex>
                    <CalendarsRow>
                        {calendarViews.map((view, i) => (
                            <div key={i}>
                                {currentCalendarView === view.name ? (
                                    <>
                                        {_userData.user_login != 'user' &&
                                        view.name != 'teamGridWeek' ? (
                                            <CalandersPrimary
                                                key={i}
                                                onClick={() => calenderView(view.name)}>
                                                <Icon className={`${view.icon}`}></Icon>
                                            </CalandersPrimary>
                                        ) : _userData.user_login == 'user' ||
                                          _userData.user_login == 'teamMember' ||
                                          _userData.user_login == 'others' ? (
                                            <CalandersPrimary
                                                key={i}
                                                onClick={() => calenderView(view.name)}>
                                                <Icon className={`${view.icon}`}></Icon>
                                            </CalandersPrimary>
                                        ) : (
                                            ''
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {' '}
                                        {_userData.user_login != 'user' &&
                                        view.name != 'teamGridWeek' ? (
                                            <CalandersSecondary
                                                key={i}
                                                onClick={() => calenderView(view.name)}>
                                                <Icon className={`${view.icon}`}></Icon>
                                            </CalandersSecondary>
                                        ) : _userData.user_login == 'user' ||
                                          _userData.user_login == 'teamMember' ||
                                          _userData.user_login == 'others' ? (
                                            <CalandersSecondary
                                                key={i}
                                                onClick={() => calenderView(view.name)}>
                                                <Icon className={`${view.icon}`}></Icon>
                                            </CalandersSecondary>
                                        ) : (
                                            ''
                                        )}
                                    </>
                                )}
                            </div>
                        ))}
                    </CalendarsRow>

                    <UserNav
                        openNotification={openNotification}
                        openNotificationPopup={openNotificationPopup}
                        openAuth={openAuth}
                        openAuthPopup={openAuthPopup}
                        userData={_userData}
                    />
                </Flex>
            </CalendarHead>
            {open && <AsideMobile open={open} openAsidNew={openAsidNew} />}
        </>
    );
};
const Bars = styled.h1`
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 500;
    display: none;
    align-items: center;
    text-color: black;
    align-items: center;
    ${media.xs`
    display:flex;
  `}
    ${media.sm`
    display:flex;
  `}
    ${media.md`
	display:flex;
  `}
    ${media.lg`
    display:none;
  `}
`;

const BarIcon = styled.i`
    font-size: 1.5rem !important;
    line-height: 2rem !important;
    margin-right: 1rem !important;
`;

const CalendarHead = styled.div`
    height: 64px;
    width: 100%;
    padding: 1rem 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-style: solid !important;
    border-left-width: 0px !important;
    border-right-width: 0px !important;
    border-top-width: 0px !important;
    border-bottom-width: 0.5px !important;
    border-color: rgb(229 231 235);
`;
const Flex = styled.div`
    display: flex;
    align-items: center !important;
`;
const MobileCalenderOption = styled.div`
    display: none;
    align-items: center !important;
    @media screen and (min-width: 200px) and (max-width: 1200px) {
        display: flex;
    }
`;

const TodayButton = styled.div`
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow,
        transform, filter, backdrop-filter, -webkit-backdrop-filter !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
    border: 1px solid #ededed !important;
    border-radius: 0.25rem !important;
    cursor: pointer;
    &:hover {
        background-color: #ededed !important;
    }
`;
const Section = styled.div`
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
    display: flex;
`;
const LeftArrow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow,
        transform, filter, backdrop-filter, -webkit-backdrop-filter !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
    color: rgba(157, 160, 164) !important;
    border-radius: 9999px !important;
    width: 2.25rem !important;
    height: 2.25rem !important;
    cursor: pointer;
    &:hover {
        background-color: #ededed !important;
    }
`;
const RightArrow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow,
        transform, filter, backdrop-filter, -webkit-backdrop-filter !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
    color: rgba(157, 160, 164) !important;
    border-radius: 9999px !important;
    width: 2.25rem !important;
    height: 2.25rem !important;
    cursor: pointer;
    &:hover {
        background-color: #ededed !important;
    }
`;
const Date = styled.div`
    color: rgba(38, 52, 70) !important;
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
    cursor: pointer;
    @media screen and (min-width: 200px) and (max-width: 700px) {
        display: none;
    }
`;
const CalendarsRow = styled.div`
    display: flex !important;
    margin-right: 2rem !important;
    @media screen and (min-width: 200px) and (max-width: 1200px) {
        display: none !important;
    }
`;
const CalendarsIcons = styled.div`
    display: flex !important;
    margin-right: 2rem !important;
    @media screen and (min-width: 200px) and (max-width: 1200px) {
        justify-content: center;
        margin-right: 0rem !important;
        margin-left: 2rem;
    }
`;
const CalandersPrimary = styled.div`
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
    color: black !important;
    border-radius: 9999px !important;
    width: 3rem !important;
    height: 3rem !important;
    cursor: pointer;
    align-items: center;
    display: flex;
`;
const CalandersSecondary = styled.div`
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
    color: rgba(157, 160, 164) !important;
    border-radius: 9999px !important;
    width: 3rem !important;
    height: 3rem !important;
    cursor: pointer;
    align-items: center;
    display: flex;
`;
const Icon = styled.i`
    font-size: 1.5rem !important;
    line-height: 2rem !important;
`;
const SelectOne = styled.select`
    border-radius: 0.325rem;
    background-color: #fff;
    padding: 0.75rem 1.15rem;
    transition: border-color 0.15s ease-in-out;
    display: block;
    border: 1px solid #e5e7eb;
`;
const Option = styled.option`
    box-shadow: 0 16px 48px 0 rgb(62 53 71 / 18%);
    left: 0;
    background: #fff;
    border-radius: 8px;
    padding: 16px 10px;
    min-width: 100%;
    overflow-y: auto;
    max-height: 240px;
    border-bottom: 1px solid #f4f4f4;
    list-style: none;
`;
const CalendarIcon = styled.div`
    color: rgba(38, 52, 70) !important;
    border-radius: 9999px !important;
    cursor: pointer;
`;

const Cover = styled.div`
    background-color: #1a202c;
    background-position: center center;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    background-size: cover;
    padding: 24px;
    input {
        border: none;
        box-sizing: border-box;
        outline: 0;
        position: absolute;
        top: 1.9rem;
        width: 10rem;
        opacity: 0;
        cursor: pointer;
    }
    input[type='date']::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }
`;
const CoverHeading = styled.div`
    color: rgba(255, 255, 255) !important;
    font-weight: 500 !important;
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
`;

const SettingListBody = styled.div``;

const List = styled.div`
    display: flex;
    padding: 0.75rem 0.75rem !important;
    border: 0.1rem solid ${palette.grey.lightest};
    align-items: center;
    cursor: pointer;
    font-size: 1.15rem !important;
    color: ${palette.dark};
`;
const TodayFlex = styled.div`
    display: flex;
    align-items: center !important;
    @media screen and (min-width: 200px) and (max-width: 531px) {
        display: none;
    }
`;

const TodayList = styled.div`
    display: none;
    padding: 0.75rem 0.75rem !important;
    border: 0.1rem solid ${palette.grey.lightest};
    align-items: center;
    cursor: pointer;
    font-size: 1.15rem !important;
    color: ${palette.dark};
    @media screen and (min-width: 200px) and (max-width: 531px) {
        display: flex;
    }
`;

const SelectFlex = styled.span`
    display: flex;
    align-items: center !important;
    width: 15%;
    @media screen and (min-width: 200px) and (max-width: 1050px) {
        display: none;
    }
`;

const SelectList = styled.div`
    display: none;
    padding: 0.75rem 0.75rem !important;
    border: 0.1rem solid ${palette.grey.lightest};
    align-items: center;
    cursor: pointer;
    font-size: 1.15rem !important;
    color: ${palette.dark};
    @media screen and (min-width: 200px) and (max-width: 1050px) {
        display: block;
    }
`;

const CalenderDate = styled.div`
    display: block;
    input {
        border: none;
        box-sizing: border-box;
        outline: 0;
        position: absolute;
        top: 1.5rem;
        width: 10rem;
        opacity: 0;
        cursor: pointer;
    }
    input[type='date']::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }
    @media screen and (min-width: 200px) and (max-width: 700px) {
        display: none;
    }
`;
const Block = styled.div`
    display: flex;
    align-items: center !important;
    justify-content: center;
    position: relative;
    cursor: pointer;
    &::before {
        position: absolute;
        height: 20px;
        width: 20px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transform-origin: 50% 50%;
        background-color: #e5e9f2;
        border-radius: 50%;
        opacity: 0;
        transition: all 0.3s;
    }
    &:hover::before {
        opacity: 1;
        width: 40px;
        height: 40px;
    }
    // &:hover i {
    //     opacity: 1;
    //     width: 2rem;
    //     height: 2rem;
    //     background-color: #e5e9f2;
    // }

    i {
        z-index: 1;
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 1.3rem;
    }
`;
export default CalendarHeader;
