import { Input, useSnackbar } from '@components/common';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import Button from '@components/Button';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ICustomer } from 'interfaces/customer.interface';
import { media } from 'styled-bootstrap-grid';
import { api } from 'helpers/auth-axios';

const validationSchema = Yup.object().shape({
    firstname: Yup.string().required('This field is required'),
    lastname: Yup.string().required('This field is required'),
    email: Yup.string().required('This field is required'),
    // password: Yup.string().required('This field is required'),
    phone: Yup.string().required('This field is required'),
});
const CustomerModal = ({
    onClose,
    onSave,
    title,
    onEdit,
    editModel,
}: {
    onClose(): void;
    onSave(data: any): void;
    title?: any;
    onEdit?: any;
    editModel?: any;
}) => {
    const [openSnackbar] = useSnackbar();
    const [phone, setPhone] = useState();
    const [profileImage, setProfileImage] = useState<any>(null);
    const [loading, setLoading] = useState<any>(false);
    const formOptions = { resolver: yupResolver(validationSchema) };
    // @ts-ignore
    const { handleSubmit, setValue, getValues, formState, trigger } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    async function onChange({ name, value }: { name: string; value: string }) {
        if (name === 'phone') {
            phoneNumber(name, value);
            await trigger(name as never);
        } else if (name === 'email') {
            setValue(name as never, value?.toLowerCase() as never);
            await trigger(name as never);
        } else {
            setValue(name as never, value as never);
            await trigger(name as never);
        }
    }

    const closeModal = (event: any) => {
        if (event.target === event.currentTarget) {
            onClose();
        }
    };
    const onSubmit = (data: any) => {
        setLoading(true);
        editModel === true
            ? api
                  .put(`/customers/${onEdit?._id}`, {
                      ...data,
                  })
                  .then((res: any) => {
                      setLoading(false);

                      if (res) {
                          onSave(res?.data);
                          openSnackbar('Customer updated sucessfully');
                          onClose();
                      }
                  })
                  .catch((e: any) => {
                      setLoading(false);

                      openSnackbar(e?.response?.data?.message);
                  })
            : api
                  .post('/customers', { ...data, photo: profileImage })
                  .then((res: any) => {
                      setLoading(false);

                      onSave(res?.data);
                      openSnackbar('Customer added sucessfully');
                      onClose();
                      // navigate(`/customers/${res.data._id}`);
                  })
                  .catch((e: any) => {
                      setLoading(false);

                      openSnackbar(e?.response?.data?.message);
                  });
    };
    useEffect(() => {
        if (onEdit && editModel === true) {
            setValue('firstname', onEdit?.firstname);
            setValue('lastname', onEdit?.lastname);
            setValue('email', onEdit?.email);
            setValue('phone', onEdit?.phone);
            setPhone(onEdit?.phone);
        }
    }, [setValue, getValues]);
    const phoneNumber = (name: any, value: any) => {
        let phone = value;
        if (phone.length <= 3) {
            phone = phone.replace(/[- .]/g, '');
            // phone = phone.slice(0, 3) + '-';
        } else if (phone.length <= 7) {
            phone = phone.replace(/[- .]/g, '');
            phone = phone.slice(0, 3) + '-' + phone.slice(3, 6);
        } else if (phone.length >= 7) {
            phone = phone.replace(/[- .]/g, '');
            phone = phone.slice(0, 3) + '-' + phone.slice(3, 6) + '-' + phone.slice(6);
        }
        setPhone(phone);
        setValue(name as never, phone as never);
    };
    return (
        <Model tabIndex={-1} onClick={closeModal}>
            <ModelBody style={{ width: '680px' }}>
                <ModelHead>
                    <Cover onClick={onClose}>
                        <i className="fas fa-times"></i>
                    </Cover>
                </ModelHead>
                <ModelHeading>
                    <Heading>{title}</Heading>
                </ModelHeading>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <ModelContent>
                        <CustomDuv>
                            <InputGroup>
                                <Input
                                    label="First Name"
                                    name="firstname"
                                    value={
                                        editModel === true
                                            ? onEdit?.firstname
                                            : getValues('firstname')
                                    }
                                    onChange={onChange}
                                    error={errors.firstname as any}
                                />
                            </InputGroup>
                            <InputGroup>
                                <Input
                                    label="Last Name"
                                    name="lastname"
                                    value={
                                        editModel === true
                                            ? onEdit?.lastname
                                            : getValues('lastname')
                                    }
                                    onChange={onChange}
                                    error={errors.lastname as any}
                                />
                            </InputGroup>
                        </CustomDuv>
                        <CustomDuv>
                            <InputGroup>
                                <Input
                                    label="Email"
                                    name="email"
                                    value={getValues('email')?.toLowerCase()}
                                    onChange={onChange}
                                    error={errors.email as any}
                                />
                            </InputGroup>
                            <InputGroup>
                                <Input
                                    allowPhoneNumberOnly={true}
                                    maxLength={12}
                                    label="Phone"
                                    name="phone"
                                    value={phone ? phone : getValues('phone')}
                                    onChange={onChange}
                                    error={errors.phone as any}
                                />
                            </InputGroup>
                        </CustomDuv>
                    </ModelContent>

                    <Footer>
                        <Button
                            bgtype={'secondary'}
                            type={'submit'}
                            disabled={loading}
                            label="Save"></Button>
                        <Button ifClicked={onClose} bgtype={'discard'} label="Close"></Button>
                    </Footer>
                </Form>
            </ModelBody>
        </Model>
    );
};
const Model = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 24px;
    overflow: auto;
    overscroll-behavior-y: contain;
    z-index: 102;
`;
const ModelBody = styled.div`
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 15px 25px -6px rgb(0 0 0 / 10%);
    padding: 24px;
    max-width: 82vw;
`;
const ModelHead = styled.div`
    display: flex;
    justify-content: end;
    margin-bottom: 0.75rem;
`;
const Cover = styled.div`
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3f4254;
    background-color: #e4e6ef;
    border-radius: 8px;
    font-size: 1.2rem;
    cursor: pointer;
`;
const ModelHeading = styled.div`
    align-items: center !important;
    display: flex !important;
`;
const Heading = styled.h1`
    font-weight: 600 !important;
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
    margin-bottom: 0px !important;
`;
const Form = styled.form``;
const ModelContent = styled.div`
    padding: 2rem 0 1rem 0 !important;
    color: rgba(157, 160, 164) !important;
`;
const Text = styled.div`
    margin-bottom: 1.5rem !important;
    color: rgba(157, 160, 164) !important;
`;
const InputGroup = styled.div`
    margin-bottom: 1.71rem;
    width: 100%;
`;
const Footer = styled.div`
    display: flex;
    justify-content: space-between;
`;
const DaysName = styled.div`
    display: flex;
    flex-direction: row;
`;
const Options = styled.div<any>`
    margin-top: 0.5rem;
    height: 2rem;
    width: 2rem;
    border: 1px solid #e5e7eb;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: ${({ active }: any) => (active ? `rgb(57, 57, 185)` : 'white')};

    color: ${({ active }: any) => (active ? `white` : 'rgb(57, 57, 185)')};
`;
const CustomDuv = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    ${media.xs`
    display: block;
    `}
    ${media.sm`
      display: block;
    `}
      ${media.md`
      display: flex;
    `}
    ${media.lg`
      display: flex;
    `}
`;
export default CustomerModal;
