import { IGetWorkingHours, IWorkingHours } from 'interfaces/workinghours.interface';
import { Dispatch } from 'redux';
import { WorkingHoursServices } from 'services/workinghours.service';

export const workingHoursConstants = {
    CREATE_REQUEST: 'WORKINGHOURSE_CREATE_REQUEST',
    CREATE_SUCCESS: 'WORKINGHOURSE_CREATE_SUCCESS',
    CREATE_FAILURE: 'WORKINGHOURSE_CREATE_FAILURE',

    GET_REQUEST: 'WORKINGHOURSE_GET_REQUEST',
    GET_SUCCESS: 'WORKINGHOURSE_GET_SUCCESS',
    GET_FAILURE: 'WORKINGHOURSE_GET_FAILURE',

    UPDATE_REQUEST: 'WORKINGHOURSE_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'WORKINGHOURSE_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'WORKINGHOURSE_UPDATE_FAILURE',
};

const createWorkingHours =
    (user_id: string, workingHoursData: IWorkingHours) =>
    (dispatch: Dispatch): Promise<IWorkingHours> => {
        dispatch({
            type: workingHoursConstants.CREATE_REQUEST,
        });

        return WorkingHoursServices.create(user_id, workingHoursData).then(workingHours => {
            dispatch({
                type: workingHoursConstants.CREATE_SUCCESS,
                workingHours,
            });

            return workingHours;
        });
    };

const getWorkingHours =
    (user_id: string) =>
    (dispatch: Dispatch): Promise<IGetWorkingHours[]> => {
        dispatch({
            type: workingHoursConstants.GET_REQUEST,
        });

        return WorkingHoursServices.getWorkingHourse(user_id).then(workingHours => {
            dispatch({
                type: workingHoursConstants.GET_SUCCESS,
                workingHours,
            });

            return workingHours;
        });
    };

const updateWorkingHours =
    (id: string, workingHoursData: IWorkingHours) =>
    (dispatch: Dispatch): Promise<IWorkingHours> => {
        dispatch({
            type: workingHoursConstants.UPDATE_REQUEST,
        });

        return WorkingHoursServices.updateWorkingHours(id, workingHoursData).then(workingHours => {
            dispatch({
                type: workingHoursConstants.UPDATE_SUCCESS,
                workingHours,
            });

            return workingHours;
        });
    };
export const workingHoursActions = {
    createWorkingHours,
    getWorkingHours,
    updateWorkingHours,
};
