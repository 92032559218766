import { serviceProviderActions } from 'actions/team/serviceProvider';
import { api } from 'helpers/auth-axios';
import { useAppDispatch } from 'hooks/redux';
import useRouter from 'hooks/router';
import { IServiceProvider } from 'interfaces/team/serviceProvider.interface';
import { IService } from 'interfaces/service.interface';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { useSnackbar, Select, ServicesList } from '@components/common';
import DeleteModel from '@components/common/DeleteModel/DeleteModel';
import EditSelectedServicesModel from '@components/team/Edit/EditServiceProvider/EditSelectedServicesModel';

const ServiceProviderServices = ({
    serviceProviderId,
    readOnly,
}: {
    serviceProviderId: string;
    readOnly: boolean;
}) => {
    const [serviceProvider, setServiceProvider] = useState<IServiceProvider>();
    const [selectedUnassignedService, setSelectedUnassignedService] = useState<IService>();
    const [unassignedBusinessServices, setUnassignedBusinessServices] = useState<IService[]>([]);
    const [unassignedBusinessServiceOptions, setUnassignedBusinessServiceOptions] = useState<any[]>(
        [
            {
                label: 'Assign Service',
                value: '',
            },
        ]
    );
    const [assignedServices, setAssignedServices] = useState<IService[]>([]);
    const [serviceToEdit, setServiceToEdit] = useState<IService>();
    const [showEditServiceModal, setShowEditServiceModal] = useState<boolean>(false);
    const [showUnassignServiceModal, setShowUnassignServiceModal] = useState<boolean>(false);
    const [serviceToUnassign, setServiceToUnassign] = useState<IService>();
    const router = useRouter();
    const dispatch = useAppDispatch();
    const [openSnackbar] = useSnackbar();

    useEffect(() => {
        fetchServiceProviderData();
    }, [serviceProviderId]);

    const fetchServiceProviderData = () => {
        api.get(`/serviceProvider/${serviceProviderId}`).then((res: any) => {
            if (res.data !== 0) {
                setServiceProvider(res.data);

                /*
                 * So that we can pass these services to the ServicesList
                 * component, make sure the service's indexes are unique and in
                 * order.
                 */
                let staffServices = [...res.data.services];
                // Handle any services which do not yet have an index assigned
                // order services by their currently set index
                staffServices.sort((service1, service2) => service1.index - service2.index);
                // go over array again, any value less than 0 should be set to next available
                // index and moved to the end of the list
                while (staffServices[0]?.index < 0) {
                    const nextAvailableIndex = staffServices[staffServices.length - 1].index + 1;
                    // remove element from beginning of list
                    const [elem] = staffServices.splice(0, 1);
                    // update element's index
                    elem.index = nextAvailableIndex;
                    // add element to end of list
                    staffServices.push(elem);
                }

                setAssignedServices(staffServices);
            }
        });
    };

    useEffect(() => {
        // TODO: we should only have to do this call in this view once, after that
        // we should be using the services data gathered by the initial API call
        api.get('/services').then((res: any) => {
            const businessServices: IService[] = res.data as IService[];
            /*
             * Remove any assigned services from business services (so they won't
             * be listed in the select field)
             */
            const unassignedServices: IService[] = businessServices.filter(
                (businessService: IService) =>
                    assignedServices.some(
                        (assignedService: IService) =>
                            assignedService.service_id === businessService._id
                    ) == false
            );
            setUnassignedBusinessServices(unassignedServices);

            /*
             * Now create the option objects for the select field
             */
            let unassignedServiceOptions: any[] = [
                {
                    label: 'Assign Service',
                    value: '',
                },
            ];
            unassignedServices.forEach((unassignedService: IService) => {
                unassignedServiceOptions.push({
                    label: unassignedService.name,
                    value: unassignedService._id,
                });
            });
            setUnassignedBusinessServiceOptions(unassignedServiceOptions);
        });
    }, [assignedServices]);

    const updateServiceProvidersStaffServices = (
        provider: IServiceProvider,
        services: IService[]
    ) => {
        return api.put(`/serviceProvider/${serviceProviderId}`, {
            ...provider,
            services: services.map(service => {
                return {
                    price: service.price,
                    service_id: service.service_id,
                    my_price: service.my_price,
                    vip_price: service.vip_price ? service.vip_price : service.my_price,
                    my_duration: service.my_duration,
                    duration: service.duration,
                    name: service.name,
                    color: service.color,
                    available_for_boooking: service.available_for_boooking,
                    customer_booking_visible_slots: service.customer_booking_visible_slots,
                };
            }),
        });
    };

    const openEditServiceModal = (service: IService) => {
        setServiceToEdit(service);
        setShowEditServiceModal(true);
    };

    const openUnassignServiceModal = (service: IService) => {
        setServiceToUnassign(service);
        setShowUnassignServiceModal(true);
    };

    const addNewService = () => {
        // if service provider is undefined, do nothing
        // if there is no currently selected service, do nothing
        // if the currently selected service has already been added, do nothing
        if (
            serviceProvider === undefined ||
            selectedUnassignedService === undefined ||
            assignedServices.find(
                (assignedService: IService) =>
                    assignedService.service_id === selectedUnassignedService._id
            )
        ) {
            return;
        }
        // add service to assigned services
        let newAssignedServices: IService[] = [...assignedServices];

        // convert selectedUnassignedService from a service to a staff service (by
        // adding the "service_id" property), then add to the new services array
        newAssignedServices.push({
            ...selectedUnassignedService,
            service_id: selectedUnassignedService._id,
        });
        setAssignedServices(newAssignedServices);

        // save the changes
        updateServiceProvidersStaffServices(serviceProvider, newAssignedServices)
            .then(() => {
                // pull a fresh list of service provider staff service objects
                fetchServiceProviderData();
            })
            .catch((e: any) => {
                openSnackbar(e?.response?.data?.message);
            });
        // unset the selected unassigned service
        setSelectedUnassignedService(undefined);
    };

    const removeAssignedService = (deletedService: IService) => {
        // if service provider is undefined, do nothing
        if (serviceProvider === undefined) {
            return;
        }

        // remove the service from provider's assigned services
        let newAssignedServices: IService[] = assignedServices.filter(
            (service: IService) => service._id !== deletedService._id
        );

        // save the changes
        updateServiceProvidersStaffServices(serviceProvider, newAssignedServices)
            .then((res: any) => {
                /*
                 * go through the returned services to make sure the service was removed
                 * NOTE: this becomes an issue when the provider's "services" permission was unknowingly disabled
                 */
                const returnedServices: IService[] = res.data.services;
                // create object mapping service_id to true, to quickly find if service is still assigned to the provider
                let isServiceStillThere: any = new Object();
                returnedServices.forEach((returnedService: IService) => {
                    isServiceStillThere[returnedService.service_id as string] = true;
                });

                // now we make sure the service was removed
                newAssignedServices = assignedServices.filter(
                    (service: IService) => isServiceStillThere[service.service_id as string]
                );

                setAssignedServices(newAssignedServices);
            })
            .catch((e: any) => {
                openSnackbar(e?.response?.data?.message);
            });
    };

    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    return (
        <>
            <Container>
                <Card>
                    {!readOnly && (
                        <Row>
                            <Col col={10}>
                                <Select
                                    options={unassignedBusinessServiceOptions}
                                    name="Unassigned Services"
                                    label="Unassigned Services"
                                    value={
                                        selectedUnassignedService?.name ||
                                        unassignedBusinessServiceOptions[0].label
                                    }
                                    onChange={(service_id: string) => {
                                        // get the service object corresponding to the
                                        // selected option object
                                        const res = unassignedBusinessServices.filter(
                                            (service: IService) => service._id === service_id
                                        );
                                        if (res?.length) {
                                            const selectedService: IService = res[0];
                                            // add service to service provider's assigned services
                                            setSelectedUnassignedService(selectedService);
                                        }
                                    }}
                                />
                            </Col>
                            <Col col={2}>
                                <AddIcon onClick={addNewService}>
                                    <i className="fal fa-plus"></i>
                                </AddIcon>
                            </Col>
                        </Row>
                    )}
                    {serviceProvider && (
                        <ServicesList
                            currency={_userData.user.business_id.currency}
                            services={assignedServices as IService[]}
                            editHandler={editService => {
                                openEditServiceModal(editService);
                            }}
                            deleteHandler={deletedService => {
                                openUnassignServiceModal(deletedService);
                            }}
                            showDescription={true}
                            readOnly={readOnly}
                        />
                    )}
                </Card>
            </Container>
            {showEditServiceModal && (
                <EditSelectedServicesModel
                    editData={serviceToEdit}
                    // upDateCustomServie={upDateCustomServie}
                    onClose={() => {
                        setShowEditServiceModal(false);
                        fetchServiceProviderData();
                    }}
                />
            )}
            {showUnassignServiceModal && (
                <DeleteModel
                    id={''}
                    onDelete={() => {}}
                    onClose={() => {
                        setShowUnassignServiceModal(false);
                    }}
                    name="Service"
                    content="If you click Delete, the service will be removed from your services list."
                    loading={undefined}
                    onCLick={() => {
                        if (serviceToUnassign) {
                            removeAssignedService(serviceToUnassign);
                        }
                        setServiceToUnassign(undefined);
                        setShowUnassignServiceModal(false);
                    }}
                />
            )}
        </>
    );
};

const Card = styled(Row)`
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: border-box;
    border-radius: 0.42rem;
    border: 0;
`;
const CardContent = styled(Col)`
    padding: 1.71rem;
    flex: 1 1;
    ${media.xs`
    padding: 1rem;s
    `}
    ${media.sm`
    padding: 1rem;s
    `}
`;
const CardBody = styled.div`
    gap: 1rem !important;
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    display: grid !important;
    ${media.xs`
	grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
	`}
    ${media.sm`
	grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
	`}
	${media.md`
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
	`}
	${media.lg`
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
	`}
    ${media.xl`
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
	`}
`;

const CardData = styled.div`
    border-radius: 0.5rem;
    padding: 1.85rem 1.42rem;
    position: relative;
    cursor: pointer;
`;

const AddIcon = styled.button`
    bottom: 1.71rem;
    right: 2.14rem;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1rem 1.71rem 0 rgb(0 0 0 / 28%);
    background: #0154ff;
    border-radius: 100%;
    color: #fff;
    font-size: 1.5rem;
    border: none;
    cursor: pointer;
`;

const Label = styled.div`
    background-color: #00be70;
    padding: 0.14rem 0.42rem 0.14rem 0.14rem;
    display: inline-block;
    border-radius: 3.57rem;
    font-size: 0.85rem;
    font-weight: 500;
    color: #fff;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0.42rem;
    right: 0.42rem;
`;
const Name = styled.div`
    font-weight: 500;
    font-size: 1.2rem;
    width: 200px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;
const ServiceDescription = styled.div`
    font-size: 1rem;
    padding: 0.2rem 0;
`;
const ServiceDuration = styled.div`
    font-size: 0.9rem /* 14.4px */;
`;
export default ServiceProviderServices;
