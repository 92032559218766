import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Col, Row, media } from 'styled-bootstrap-grid';
import { Input, useSnackbar } from '@components/common';
import { Link, useNavigate } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { api } from 'helpers/auth-axios';

const ForgotPassword = ({ setForgotPassword }: { setForgotPassword: any }) => {
    useEffect(() => {
        localStorage.removeItem('user');
        localStorage.removeItem('url');
    }, []);
    const [openSnackbar] = useSnackbar();
    const [email, setEmail] = useState();
    const validationSchema = Yup.object().shape({
        email: Yup.string().required('This field is required'),
        password: Yup.string().required('This field is required'),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, setValue, formState, trigger } = useForm(formOptions);
    const { errors } = formState;

    const onForgotPassword = () => {
        api.post('/auth/forgot_password', {
            email: email,
            role: 'customer',
            businessId: window.location.pathname.slice(1, 25),
        })
            .then((res: any) => {
                openSnackbar('Email sent to your email address Successfully');
                setForgotPassword(false);
            })
            .catch((e: any) => {
                if (e.response) {
                    openSnackbar('Incorrect email');
                }
            });
    };

    async function onChange({ name, value }: { name: string; value: string }) {
        setValue(name as never, value as never);
        await trigger(name as never);
    }

    return (
        <>
            <InputGroup>
                <Input
                    label="E-mail Address"
                    name="email"
                    type="email"
                    onChange={(e: any) => setEmail(e.value)}
                    error={errors.email as any}
                />
            </InputGroup>

            <Reset onClick={() => onForgotPassword()}>Reset</Reset>
        </>
    );
};
const Wrapper = styled(Container)`
    paddind: 0;
    min-width: 100%;
    background-color: #fff;
`;
const CardLeft = styled.div`
    background-color: #4871fe;
    padding-top: 3.5rem !important;
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
`;
const CardRight = styled.div`
    padding-left: 4.75rem !important;
    padding-right: 4.75rem !important;
    background-color: #fff;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
const Inner = styled.div`
    margin-bottom: 3rem;
`;
const Heading = styled.h3`
    color: #000;
    font-size: 2.6rem;
    font-weight: 600 !important;
    ${media.xs`
    margin-top:0;
	`}
    ${media.sm`
    margin-top:0;
	`}
	${media.md`
    margin-top:0;
	`}
`;
const InputGroup = styled.div`
    margin-bottom: 1.71rem;
`;
const Button = styled.button`
    color: rgba(255, 255, 255) !important;
    text-transform: capitalize !important;
    font-weight: 800 !important;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
    background-color: #0154ff !important;
    border-radius: 0.5rem !important;
    width: 100% !important;
    display: inline-block !important;
    margin-top: 1rem !important;
    border: 0;
    cursor: pointer;
    font-size: 1.25rem;
`;
const Reset = styled.button`
    color: rgba(255, 255, 255) !important;
    text-transform: capitalize !important;
    font-weight: 800 !important;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
    background-color: #0154ff !important;
    // background-color: #7e899f !important;
    border-radius: 0.5rem !important;
    width: 100% !important;
    display: inline-block !important;
    margin-top: 1rem !important;
    border: 0;
    cursor: pointer;
    font-size: 1.25rem;
`;
const Span = styled.span``;
const SignUpText = styled.span`
    color: #0154ff !important;
    font-weight: 800;
    padding-left: 0.5rem;
    margin: 0;
`;
const SignInText = styled.span`
    display: flex;
    color: #000 !important;
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
`;
const CustomDiv = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 0rem 0rem;
    color: #0154ff;
    cursor: pointer;
`;
export default ForgotPassword;
