import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import s from './BusinessService.module.scss';
import moneyImg from 'images/money.svg';
import { Link } from 'react-router-dom';
import { serviceActions } from 'actions/service';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { IService } from 'interfaces/service.interface';
import { palette } from '../../../styled/common';
import DeleteModel from '@components/common/DeleteModel/DeleteModel';
import { api } from 'helpers/auth-axios';
import { useSnackbar, ServicesList } from '@components/common';
const BusinessServices = ({ onSelectService }: { onSelectService: any }) => {
    const dispatch = useAppDispatch();
    const [currency, setCurrency] = useState<any>();
    const [openDeleteModel, setOpenDeleteModel] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [deleteId, setDeleteId] = useState<any>();
    const [service, setService] = useState<any[]>([]);
    const [openSnackbar] = useSnackbar();
    const { services } = useAppSelector(state => state.service);
    useEffect(() => {
        api.get('/services').then((res: any) => {
            if (res.data.length) {
                /*
                 * So that we can pass these services to the ServicesList
                 * component, make sure the service's indexes are unique and in
                 * order.
                 */
                let businessServices = [...res.data];
                // Handle any services which do not yet have an index assigned
                // order services by their currently set index
                businessServices.sort((service1, service2) => service1.index - service2.index);
                // go over array again, any value less than 0 should be set to next available
                // index and moved to the end of the list
                while (businessServices[0]?.index < 0) {
                    const nextAvailableIndex =
                        businessServices[businessServices.length - 1].index + 1;
                    // remove element from beginning of list
                    const [elem] = businessServices.splice(0, 1);
                    // update element's index
                    elem.index = nextAvailableIndex;
                    // add element to end of list
                    businessServices.push(elem);
                }
                setService(businessServices);
            }
        });
    }, []);
    const selectService = (service: IService) => {
        onSelectService(service);
    };
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');

    const deleteService = (id: any) => {
        setLoading(true);
        api.delete(`/services/${id}`)
            .then((res: any) => {
                openSnackbar('Service deleted successfully!');
                const deleteServices: any = service.filter((item: any) => {
                    return item._id !== id;
                });
                setService(deleteServices);
            })
            .catch((e: any) => {
                openSnackbar('Failed to delete Service!');
            });
        setOpenDeleteModel(false);
        setLoading(false);
    };
    const deleteModel = (id: any) => {
        setOpenDeleteModel(true);
        setDeleteId(id);
    };
    return (
        <>
            <Wrapper>
                <Row>
                    <Col lg={10}>
                        <Card>
                            <ServicesList
                                currency={_userData.user.business_id.currency}
                                services={service}
                                reorderingHandler={reorderedServices => {
                                    // for each service in list, update the service
                                    let updatedServices: IService[] = [];
                                    reorderedServices.forEach((reorderedService: IService) => {
                                        api.put(
                                            `/services/${reorderedService._id}`,
                                            reorderedService
                                        )
                                            .then((res: any) => {})
                                            .catch((e: any) => {
                                                if (e.response) {
                                                    openSnackbar(e.reponse?.data?.message);
                                                }
                                            });
                                    });
                                }}
                                editHandler={editService => {
                                    onSelectService(editService);
                                }}
                                deleteHandler={deletedService => {
                                    deleteModel(deletedService._id);
                                }}
                                showDescription={true}
                            />
                            <Wrapper>
                                {openDeleteModel && (
                                    <DeleteModel
                                        id={''}
                                        onDelete={() => {}}
                                        onClose={() => {
                                            setOpenDeleteModel(false);
                                        }}
                                        name="Service"
                                        content=" If you delete this service you will lose all information referencing it,
                                     including assigned staff service and appointment service. This action cannot be undone."
                                        loading={loading}
                                        onCLick={() => deleteService(deleteId)}
                                    />
                                )}
                            </Wrapper>
                        </Card>
                    </Col>
                </Row>
                <AddButton to="/settings/services/create">
                    <i className="fal fa-plus"></i>
                </AddButton>
            </Wrapper>
        </>
    );
};
const Wrapper = styled(Container)`
    padding: 0rem;
`;
const Card = styled.div`
    background-color: ${palette.light};
    box-shadow: 0rem 0rem 2.14rem 0rem ${palette.grey.shadow};
    border-radius: 0.42rem;
    padding: 3rem 2rem 1.75rem 2rem !important;
    cursor: pointer;
`;
const ServiceCard = styled.div`
    margin-bottom: 1.5rem;
`;
const PriceTag = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 0px;
    // position: absolute;
    // top: 0.4rem;
    // right: 0.4rem;
`;
const ServiceName = styled.h1`
    font-weight: 500;
    font-size: 1.2rem;
    width: 200px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;
const ServiceDescription = styled.div`
    font-size: 1rem;
    margin-bottom: 0.5rem;
`;
const Time = styled.div`
    font-size: 0.9rem !important;
`;
const AddButton = styled(Link)`
    position: fixed;
    bottom: 1.71rem;
    width: 4.28rem;
    height: 4.28rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1rem 1.71rem 0 ${palette.grey.shadowDarkest};
    background: #0154ff;
    border-radius: 50%;
    color: ${palette.white};
    font-size: 1.5rem;
    cursor: pointer;
`;

const DeleteIcon = styled.button`
    padding: 0;
    width: 1.6rem;
    height: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 0rem;
    background-color: ${palette.primary};
    color: ${palette.light};
    font-weight: 600;
    text-transform: capitalize;
    border-radius: 0.51rem;
    font-size: 0.87rem;
    cursor: pointer;
    text-align: center;
    display: inline-block;
    color: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    top: 0.4rem;
    left: 1.3rem;
    z-index: 2;
}
`;

const PriceField = styled.div`
    background-color: ${palette.green};
    padding: 0.15rem 0.4rem 0.15rem 0.15rem;
    display: inline-block;
    border-radius: 2rem;
    font-size: 0.85rem;
    font-weight: 500;
    color: ${palette.white};
    display: flex;
    align-items: center;
    position: absolute;
    top: 0.4rem;
    right: 0.4rem;
`;
export default BusinessServices;
