import { appointmentConstants } from 'actions/appointment';
import { IGetAppointmentsResponse } from 'interfaces/appointment.interface';
import moment from 'moment';

const INITIAL_STATE: any = {};
// debugger;
const getAppointments = (prevState: any, payload: IGetAppointmentsResponse) => {
    let state = prevState;

    let now = moment(payload.start_date).clone();

    // Add visited dates to state
    while (now.diff(moment(payload.end_date)) < 0) {
        const date = now.format();
        if (!state[date]) {
            state[date] = [];
        }

        now.add(1, 'days');
    }

    if (payload.appointments.length) {
        payload.appointments.forEach(appointment => {
            const startTime = moment(appointment.booked_from).format();
            state = {
                ...state,
                [startTime]: state[startTime]
                    ? state[startTime].concat(appointment)
                    : [appointment],
            };
        });
    }

    return state;
};

export function appointment(state = INITIAL_STATE, action: any) {
    switch (action.type) {
        case appointmentConstants.GET_SUCCESS:
            // debugger;
            return getAppointments(state, action.appointments);
        case appointmentConstants.CREATE_SUCCESS:
            const startTime = moment(action.appointment.booked_from).format();
            return {
                // ...state,
                [startTime]: state[startTime]
                    ? state[startTime].concat(action.appointment)
                    : [action.appointment],
            };
        default:
            return state;
    }
}
