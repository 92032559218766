import { api } from 'helpers/auth-axios';
import { IGetTimeOff, ITimeOff } from 'interfaces/timeoff.interface';

function create(user_id: string, timeoff: ITimeOff): Promise<ITimeOff> {
    return api.post(`/timeoff/${user_id}`, timeoff).then(res => {
        return res.data;
    });
}
function getTimeoff(user_id: string): Promise<IGetTimeOff[]> {
    return api.get(`/timeoff/all/${user_id}`).then(res => {
        return res.data;
    });
}

function deleteTimeoff(id: string): Promise<ITimeOff> {
    return api.delete(`/timeoff/${id}`).then(res => {
        return res.data;
    });
}
export const TimeOffServices = { create, getTimeoff, deleteTimeoff };
