import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import { Link, Routes, Route, useNavigate } from 'react-router-dom';
import { ServiceProviderCard, TeamCard, OthersCard } from '@components/team';
const TeamOptions = () => {
    const navigate = useNavigate();
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const [teamOptionList, setTeamOptionList] = useState([
        {
            link: '/team/service-provider-list',
            title: 'Service Providers',
            subTitle: 'Team members with bookable schedules.',
        },
        {
            link: '/team/team-list',
            title: 'Team Members',
            subTitle: 'Team members with unbookable schedules.',
        },
        {
            link: '/team/other-list',
            title: 'Others',
            subTitle:
                "User who needs access to everyone's calendars. For example, a front desk person or a manager.",
        },
    ]);
    const callServiceProvider = () => {};
    const callTeam = () => {};
    const callOther = () => {};
    useEffect(() => {
        if (_userData.user_login == 'serviceProvider') {
            navigate(`/team/service-provider-list/${_userData.user._id}`);
        }
        if (_userData.user_login == 'teamMember') {
            navigate(`/team/team-list/${_userData.user._id}`);
        }
        if (_userData.user_login == 'others') {
            navigate(`/team/other-list/${_userData.user._id}`);
        }
    }, []);
    return (
        <>
            <Container>
                <Row>
                    {_userData.user_login == 'user' ? (
                        <>
                            {teamOptionList.map((list, index) => {
                                return (
                                    <Col
                                        key={index}
                                        md={6}
                                        lg={6}
                                        style={{ marginBottom: '1.5rem' }}>
                                        <CustomLink to={list.link}>
                                            <div>
                                                <Heading>{list.title}</Heading>
                                                <Text>{list.subTitle}</Text>
                                            </div>
                                            <div>
                                                <Arrow>
                                                    <i className="fal fa-chevron-right"></i>
                                                </Arrow>
                                            </div>
                                        </CustomLink>
                                    </Col>
                                );
                            })}
                        </>
                    ) : _userData.user_login == 'serviceProvider' ? (
                        <Routes>
                            <Route
                                path=":id"
                                element={
                                    <ServiceProviderCard
                                        callServiceProvider={callServiceProvider}
                                    />
                                }
                            />
                        </Routes>
                    ) : _userData.user_login == 'teamMember' ? (
                        <Routes>
                            <Route path=":id" element={<TeamCard callTeam={callTeam} />} />
                        </Routes>
                    ) : _userData.user_login == 'others' ? (
                        <Routes>
                            <Route path=":id" element={<OthersCard callOther={callOther} />} />
                        </Routes>
                    ) : (
                        ''
                    )}
                </Row>
            </Container>
        </>
    );
};
const CustomLink = styled(Link)`
    display: flex;
    justify-content: space-between;
    background: white;
    padding: 2rem 2rem;
    border-radius: 0.5rem;
    align-items: center;
`;
const Heading = styled.h1`
    color: #263446;
    font-size: 1.13rem;
    font-weight: 500;
`;
const Text = styled.p`
    color: #a8aeb5;
    font-size: 0.9rem;
`;
const Arrow = styled.span`
    color: #a8aeb5;
    font-size: 1.2rem;
`;
export default TeamOptions;
