import React, { useRef, useState, useEffect } from 'react';
import { scrollIntoView } from '../utils';
interface Option {
    label: string;
    value?: string | number;
    render?: string;
}
export type FocusDirection = 'up' | 'down' | 'pageup' | 'pagedown' | 'first' | 'last';
export interface InputProps {
    name?: string;
    type?: string;
    id?: string;
    value?: string | number;
    label?: string;
    className?: string;
    readOnly?: boolean;
    disabled?: boolean;
    options: Option[];
    slotLeft?: React.ReactNode;
    maxLength?: any;
    allowNumber?: boolean;
    noKeyBoard?: boolean; // Disables keyboard input for searching options
    onBlur?(): void;
    onInput?(value: string): void;
    onChange?(value: any): void;
    customHeight?: any;
    showLabel?: boolean;
}
const Input = ({
    name,
    type,
    id,
    value,
    options,
    label,
    className,
    readOnly,
    disabled,
    slotLeft,
    maxLength,
    allowNumber,
    noKeyBoard,
    onBlur,
    onInput,
    onChange,
    customHeight,
    showLabel,
    ...props
}: InputProps) => {
    const [searchValue, setSearchValue] = useState('');
    const [focusedOption, setFocusedOption] = useState<Option | null>(null);
    const [scrollToFocusedOption, setScrollToFocusedOption] = useState(false);
    const [isActive, setActive] = useState(false);
    const [isFocused, setFocused] = useState(false);
    const [inputValue, setInputValue] = useState<string | number>('');
    const inputRef = useRef<HTMLInputElement>(null);
    const focusedOptionRef = useRef<HTMLDivElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    if (readOnly === undefined) {
        readOnly = false;
    }
    useEffect(() => {
        setInputValue(value ?? '');

        /*
         * set the initial "focused option", so when user first opens the dropdown,
         * the correct option is selected
         */
        const selectedOption = options.find(option => option.label === value) ?? null;
        if (selectedOption) {
            setFocusedOption(selectedOption);
        }
    }, [value]);
    useEffect(() => {
        // if showLabel is set to true, then we setActive(true) to show the label
        if (showLabel) setActive(true);

        if (inputRef.current && inputRef.current.value.length > 0) setActive(true);
        // Check if input value was a value rather than a label
        const selectedOption = options.find(option => option.value === inputValue);
        if (selectedOption) setInputValue(selectedOption.label);
    }, []);
    useEffect(() => {
        if (dropdownRef.current && focusedOptionRef.current && scrollToFocusedOption) {
            scrollIntoView(dropdownRef.current, focusedOptionRef.current);
            setScrollToFocusedOption(false);
        }
    }, [scrollToFocusedOption, focusedOptionRef]);
    const focusInput = () => {
        if (!isFocused) {
            setActive(true);
            setFocused(true);
            if (inputRef.current) {
                inputRef.current.focus();
            }
            setTimeout(() => {
                if (focusedOptionRef.current && dropdownRef.current) {
                    scrollIntoView(dropdownRef.current, focusedOptionRef.current);
                }
            });
        } else {
            setFocused(false);
        }
    };
    const unfocusInput = (event: React.FocusEvent<HTMLInputElement>) => {
        setTimeout(() => {
            var target: any = event.relatedTarget;
            if (event.relatedTarget != null && target.tagName == 'INPUT') {
                event.relatedTarget = null;
            }
            if (!event.relatedTarget) {
                setFocused(false);
                if (inputRef.current && inputRef.current.value.length === 0) setActive(false);
            } else {
                focusInput();
            }
            if (onBlur) onBlur();
        }, 300);
    };
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (allowNumber) {
            const re = /^[0-9\b]+$/;
            let val: any = event.target.value.replaceAll(' ', '');
            if (val == '') {
                val = 0;
            }
            if (re.test(val) && parseInt(val) == 12 && maxLength == 2) {
                setInputValue(event.target.value);
                setSearchValue(event.target.value);
                if (onInput) onInput(event.target.value);
            } else if (re.test(val) && parseInt(val) == 2022 && maxLength == 4) {
                setInputValue(event.target.value);
                setSearchValue(event.target.value);
                if (onInput) onInput(event.target.value);
            }
        } else {
            setInputValue(event.target.value);
            setSearchValue(event.target.value);
            if (onInput) onInput(event.target.value);
        }
    };
    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (!readOnly || noKeyBoard) {
            const validKeys = [' ', 'ArrowUp', 'ArrowDown', 'Enter'];
            if (validKeys.indexOf(event.key) !== -1) {
                event.preventDefault();
                if (event.key === 'ArrowDown') {
                    focusOption('down');
                }
                if (event.key === 'ArrowUp') {
                    focusOption('up');
                }
                if (event.key === 'Enter') {
                    if (!focusedOption) return;
                    selectOption(focusedOption);
                }
            }
        }
    };
    const focusOption = (direction: FocusDirection = 'first') => {
        if (!options.length) return;
        let nextFocus = 0; // handles 'first'
        let focusedIndex = options.indexOf(focusedOption!);
        if (!focusedOption) {
            focusedIndex = -1;
        }
        switch (direction) {
            case 'up':
                nextFocus = focusedIndex > 0 ? focusedIndex - 1 : options.length - 1;
                break;
            case 'down':
                nextFocus = (focusedIndex + 1) % options.length;
                break;
            default:
                break;
        }
        setScrollToFocusedOption(true);
        setFocusedOption(options[nextFocus]);
    };
    //label: string, value: string, render?: string
    const selectOption = (option: Option) => {
        if (option.render) setInputValue(option.render);
        else setInputValue(option.label);
        setFocusedOption(option);
        // inputRef.current?.blur(); // Doesn't work for now cause of asynchronous state change, which doesn't update the value of input in time
        setFocused(false);
        // Reset search value
        setSearchValue('');
        if (onChange) onChange((option.value as string) || option.label || '');
    };

    const areOptionsEqual = (option1: any, option2: any) => {
        return option1?.label === option2?.label && option1?.value === option2?.value;
    };

    return (
        <div className={`dropdown ${className ?? ''}`} tabIndex={-1} onKeyDown={handleKeyDown}>
            <div
                className={`select--container ${isActive ? 'select--active' : ''} ${
                    isFocused ? 'select--focused select--opened' : ''
                }`}
                onClick={() => focusInput()}>
                <div className="select--wrapper">
                    <div className="select--label-wrapper">
                        <label>{label}</label>
                    </div>
                    <div className="select--input--wrapper">
                        {slotLeft && <div className="select--leftSlot">{slotLeft}</div>}
                        <input
                            ref={inputRef}
                            placeholder={!isActive ? label : ''}
                            id={name}
                            name={name}
                            type={type ? type : 'text'}
                            onChange={event => handleInputChange(event)}
                            value={inputValue}
                            className={className}
                            disabled={disabled}
                            readOnly={readOnly || noKeyBoard}
                            autoComplete="none"
                            onBlur={e => unfocusInput(e)}
                            maxLength={maxLength}
                        />
                    </div>
                    <div className="select--icon">
                        <i className="fa fal fa-chevron-down" style={{ fontWeight: 300 }}></i>
                    </div>
                </div>
            </div>
            {isFocused && (
                <div
                    className={`${readOnly === false ? 'dropdown--content' : 'dropdown-content'}`}
                    style={{ maxHeight: customHeight }}
                    ref={dropdownRef}>
                    <div className="dropdown--wrapper">
                        <ul>
                            {options
                                ?.filter(option => {
                                    if (
                                        option.label
                                            .toLowerCase()
                                            .indexOf(searchValue.toLowerCase()) === -1
                                    ) {
                                        return false;
                                    }
                                    return true;
                                })
                                .map((option, i: number) => {
                                    const isOptionFocused = areOptionsEqual(focusedOption, option);
                                    return (
                                        <li key={i}>
                                            <div
                                                className={`dropdown--item ${
                                                    inputValue === option.label
                                                        ? 'item--selected'
                                                        : ''
                                                } ${isOptionFocused ? 'item--focused' : ''}`}
                                                onClick={() => selectOption(option)}
                                                ref={
                                                    isOptionFocused ? focusedOptionRef : undefined
                                                }>
                                                <div className="item--text">{option.label}</div>
                                            </div>
                                        </li>
                                    );
                                })}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};
export default Input;
