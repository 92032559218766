import React, { useEffect, useRef, useState } from 'react';
import Button from '@components/Button';
import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { Input, Select, Spinner, useSnackbar } from '@components/common';
import { useForm } from 'react-hook-form';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { businessActions } from 'actions/business';
import { palette } from '../../../styled/common';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { IBusiness } from 'interfaces/business.interface';
import { api } from 'helpers/auth-axios';
import states from 'states-us';
mapboxgl.accessToken =
    'pk.eyJ1IjoiYXJtZWRpbiIsImEiOiJja3VsYXJqMnowMnBwMnBwaXJwc2lzd3JlIn0.228CNp9bPmiNFyAkVeXEGg';
const BusinessLocation = () => {
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const dispatch = useAppDispatch();
    const { handleSubmit, formState } = useForm();
    const { isSubmitting } = formState;
    const [openSnackbar] = useSnackbar();
    const mapContainer: any = useRef(null);
    const map: any = useRef(null);
    const geocoder: any = useRef(null);
    const { business }: { business: IBusiness } = useAppSelector(state => state.business);
    const [lng, setLng] = useState(0);
    const [lat, setLat] = useState(0);
    const [address, setAddress] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [postcode, setPostcode] = useState('');
    const getBusiness = () => {
        api.get(`/businesses/${_userData?.user?.business_id?._id}`).then((res: any) => {
            if (res.data) {
                setLat(res?.data?.location?.coordinates?.latitude);
                setLng(res?.data?.location?.coordinates.longitude);
                setAddress(res?.data?.location?.address);
                setAddress2(res?.data?.location?.address2);
                setCity(res?.data?.location?.city);
                setState(res?.data?.location?.state);
                setPostcode(res?.data?.location?.zipcode);
                if (map.current) return;
                map.current = new mapboxgl.Map({
                    container: mapContainer.current,
                    style: 'mapbox://styles/mapbox/streets-v11',
                    center: [
                        res?.data?.location ? res?.data?.location?.coordinates.longitude : 0,
                        res?.data?.location ? res?.data?.location?.coordinates?.latitude : 0,
                    ],
                    zoom: 9,
                });
                geocoder.current = new MapboxGeocoder({
                    accessToken: mapboxgl.accessToken,
                    // @ts-ignore
                    mapboxgl: mapboxgl,
                    placeholder: 'Seach on map',
                });
                map.current.addControl(geocoder.current);
            }
        });
    };
    useEffect(() => {
        getBusiness();
    }, []);
    useEffect(() => {
        if (!map.current) return;
        geocoder.current.on('result', (e: any) => {
            const result = e.result;
            if (typeof result.geometry.coordinates == 'object') {
                setLat(result.geometry.coordinates[1]);
                setLng(result.geometry.coordinates[0]);
            }
            setAddress(result?.place_name);
            if (result.context) {
                result.context.forEach((v: any, i: any) => {
                    if (v.id.indexOf('place') >= 0) {
                        setCity(v.text);
                    }
                    if (v.id.indexOf('postcode') >= 0) {
                        setPostcode(v.text);
                    }
                    if (v.id.indexOf('region') >= 0) {
                        setState(v.text);
                    }
                });
            }
        });
    });
    const onSubmit = () => {
        return dispatch(
            businessActions.updateLocation(_userData?.user?.business_id?._id, {
                zipcode: postcode,
                address: address,
                address2: address2,
                city: city,
                state: state,
                coordinates: {
                    latitude: lat,
                    longitude: lng,
                },
            })
        ).then(() => {
            openSnackbar('Saved successfully!');
        });
    };
    const States: { label: string; value: any }[] = [];
    states.length &&
        states.map(x => {
            States.push({ label: x.name, value: x.name });
        });
    return (
        <>
            <Wrapper>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Wrapper>
                                <Row>
                                    <Col lg={6}>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <InputGroup>
                                                <Input
                                                    label="Street address & number"
                                                    value={address}
                                                    onChange={({ value }: { value: any }) => {
                                                        setAddress(value);
                                                    }}
                                                />
                                            </InputGroup>
                                            <InputGroup>
                                                <Input
                                                    label="Suite number (optional)"
                                                    value={address2}
                                                    onChange={({ value }: { value: any }) => {
                                                        setAddress2(value);
                                                    }}
                                                />
                                            </InputGroup>
                                            <InputGroup>
                                                <Input
                                                    label="City"
                                                    value={city}
                                                    onChange={({ value }: { value: any }) => {
                                                        setCity(value);
                                                    }}
                                                />
                                            </InputGroup>
                                            <Wrapper>
                                                <Row>
                                                    <CustomCol lg={7}>
                                                        <InputGroup>
                                                            <Select
                                                                label="State"
                                                                value={state}
                                                                options={States}
                                                                onChange={val => setState(val)}
                                                            />
                                                        </InputGroup>
                                                    </CustomCol>
                                                    <Col lg={5}>
                                                        <InputGroup>
                                                            <Input
                                                                label="Postal Code"
                                                                value={postcode}
                                                                onChange={({
                                                                    value,
                                                                }: {
                                                                    value: any;
                                                                }) => {
                                                                    setPostcode(value);
                                                                }}
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                            </Wrapper>
                                            <Button
                                                disabled={isSubmitting}
                                                type="submit"
                                                bgtype="secondary"
                                                label="Save"></Button>
                                        </form>
                                    </Col>
                                    <Col lg={6}>
                                        <Map ref={mapContainer} />
                                        <style>{`
                                            .mapboxgl-ctrl-top-right {
                                                width: 100% !important;
                                                padding: 0.71rem !important;
                                                top: 0;
                                                right: 0;
                                            }
                                            .mapboxgl-ctrl-geocoder {
                                                max-width: 100% !important;
                                                width: 100% !important;
                                                margin: 0 !important;
                                            }
                                        `}</style>
                                    </Col>
                                </Row>
                            </Wrapper>
                        </Card>
                    </Col>
                </Row>
            </Wrapper>
        </>
    );
};
const Wrapper = styled(Container)`
    padding: 0rem;
`;

const CustomCol = styled(Col)`
    ${media.xs`
    margin-bottom: 1.8rem;
  `}
    ${media.sm`
    margin-bottom: 1.8rem;
  `}
    ${media.md`
    margin-bottom: 1.8rem;
  `}
    ${media.lg`
    margin-bottom: 0rem;
  `}
`;
const InputGroup = styled.div`
    margin-bottom: 1.71rem;
`;
const Card = styled.div`
    background-color: ${palette.white};
    box-shadow: 0rem 0rem 2.14rem 0rem ${palette.grey.lightest};
    border-radius: 0.42rem;
    padding: 3rem 2rem 1.75rem 2rem !important;
`;

const Map = styled.div`
    margin-top: 0rem;
    height: 24.57rem;
    font: 0.85rem/1.42rem Helvetica Neue, Arial, Helvetica, sans-serif !important;
    overflow: hidden !important;
    position: relative !important;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    ${media.xs`
    margin-top: 1.8rem;
  `}
    ${media.sm`
    margin-top: 1.8rem;
  `}
    ${media.md`
    margin-top: 1.8rem;
  `}
    ${media.lg`
    margin-top: 0rem;
  `}
`;
export default BusinessLocation;
