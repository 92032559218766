import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './pages/App';

import { SnackbarProvider } from '@components/common';

import { Provider } from 'react-redux';
import { store } from './helpers/store';
// import moment from 'moment';
// moment.tz.setDefault('America/Chicago');
ReactDOM.render(
    <Provider store={store}>
        <SnackbarProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </SnackbarProvider>
    </Provider>,
    document.getElementById('root')
);
