import {
    IAppointment,
    IGetAppointments,
    IUpdateAppointmentStatus,
} from 'interfaces/appointment.interface';
import { api } from '../helpers/auth-axios';

export const appointmentService = {
    create,
    getAppointments,
    getAppointmentById,
    updateAppointmentStatus,
    rescheduleAppointment,
    cancelAppointmentById,
};

function create(appointmentData: IAppointment): Promise<IAppointment> {
    return api.post('/appointments', appointmentData).then(res => {
        return res.data;
    });
}

function getAppointments(getAppointmentsData: IGetAppointments): Promise<IAppointment[]> {
    return api.get('/appointments', { params: getAppointmentsData }).then(res => {
        return res.data;
    });
}

function getAppointmentById(appointmentId: string): Promise<IAppointment> {
    return api.get(`/appointments/get/${appointmentId}`).then(res => {
        return res.data;
    });
}

function updateAppointmentStatus(
    appointmentId: string,
    appointmentStatusUpdate: IUpdateAppointmentStatus
): Promise<IAppointment> {
    return api.put(`/appointments/${appointmentId}/status`, appointmentStatusUpdate).then(res => {
        return res.data as IAppointment;
    });
}

function rescheduleAppointment(appointmentId: string, appointmentData: any): Promise<IAppointment> {
    return api.put(`/appointments/reschedule/${appointmentId}`, appointmentData).then(res => {
        return res.data;
    });
}

function cancelAppointmentById(appointmentId: string): Promise<IAppointment> {
    return api.put(`/appointments/status/${appointmentId}`).then(res => {
        return res.data;
    });
}
