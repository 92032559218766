import React from 'react';

const Context = React.createContext<any>(null);

const DropdownContext = ({ children, value }: { children: React.ReactNode; value: any }) => {
    const context = React.useMemo(() => {
        return { value };
    }, [value]);

    return <Context.Provider value={context}>{children}</Context.Provider>;
};

export function useDropdownContext() {
    return React.useContext(Context);
}

export default DropdownContext;
