import clsx from 'clsx';
import React from 'react';
import s from './Alert.module.scss';

export interface AlertProps {
    type: 'error' | 'warning' | 'info' | 'success';
    title?: string;
    style?: React.CSSProperties;
    children?: React.ReactNode;
    onClose(): void;
}

const getDefaultTitle = (type: string) => {
    switch (type) {
        case 'error':
            return 'Error';
        case 'warning':
            return 'Warning';
        case 'info':
            return 'Info';
        case 'success':
            return 'Success';
        default:
            return '';
    }
};

const getAlertTypeClass = (alertType: string) => {
    switch (alertType) {
        case 'error':
            return s.alert_error;
        case 'warning':
            return s.alert_warning;
        case 'info':
            return s.alert_info;
        case 'success':
            return s.alert_success;
        default:
            return '';
    }
};

const Alert = ({ title, type, style, children, onClose }: AlertProps) => {
    return (
        <div className={clsx(s.alert, getAlertTypeClass(type))} style={style}>
            <div className={`${s.close_button}`} onClick={onClose}>
                <i className="fas fa-times"></i>
            </div>
            <div className={`${s.alert_content}`}>
                <div className={s.alert_title}>{title ? title : getDefaultTitle(type)}</div>
                <div className={s.alert_message}>{children}</div>
            </div>
        </div>
    );
};

Alert.defaultProps = {
    type: 'error',
};

export default Alert;
