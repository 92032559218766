import { Input, useSnackbar } from '@components/common';
import { useForm } from 'react-hook-form';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { ITimeOff } from 'interfaces/timeoff.interface';
import useRouter from 'hooks/router';
import moment from 'moment';
import { palette } from 'styled/common';
import DeleteModel from '../DeleteModel/DeleteModel';
import { api } from 'helpers/auth-axios';

const TimeOff = ({
    id,
    readOnly,
}: {
    id: string; // ID of the user we're looking up working hours for
    readOnly?: boolean;
}) => {
    const [openSnackbar] = useSnackbar();
    const [deleteModel, setDeleteModel] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleteId, setDeleteId] = useState<any>();
    const router = useRouter();
    const [timeoff, setTimeOff] = useState<any>([]);
    const [fromIso, setFromIso] = useState<string>(moment().format('YYYY-MM-DD'));
    const [toIso, setToIso] = useState<string>(moment().format('YYYY-MM-DD'));

    const onSubmit = () => {
        const payload = {
            from_iso: moment(fromIso).format(),
            to_iso: moment(toIso).format(),
        };
        api.post(`/timeoff/${id}`, payload)
            .then((res: any) => {
                timeoff.push(res.data);
                openSnackbar('Time off saved successfully!');
            })
            .catch((e: any) => {
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    useEffect(() => {
        api.get(`/timeoff/all/${id}`).then((timeoff: any) => {
            setTimeOff(timeoff.data);
        });
    }, [id]);

    const openDeleteModel = (id: any) => {
        setDeleteModel(true);
        setDeleteId(id);
    };

    const deleteTeamTimeOff = (id: any) => {
        setLoading(true);
        api.delete(`/timeoff/${id}`)
            .then(() => {
                openSnackbar('Time Off deleted successfully!');
                setDeleteModel(false);
                const deleteTimeoff: any = timeoff.filter((item: any) => {
                    return item._id !== id;
                });
                setTimeOff(deleteTimeoff);
            })
            .catch((e: any) => {
                openSnackbar(e?.response?.data?.message);
            });
        setLoading(false);
        setDeleteModel(false);
    };
    return (
        <Container style={{ padding: '0px' }}>
            <Row>
                <Col lg={12}>
                    {!readOnly && (
                        <div>
                            <Container>
                                <Row style={{ marginBottom: '10px' }}>
                                    <Col lg={12}>
                                        <h3>Add Time Off</h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Flex xs={8} sm={5} md={5} lg={5}>
                                        <Input
                                            label="from"
                                            type="date"
                                            name="from_iso"
                                            min={new Date().toISOString().split('T')[0]}
                                            value={fromIso}
                                            onChange={({
                                                name,
                                                value,
                                            }: {
                                                name: string;
                                                value: string;
                                            }) => {
                                                setFromIso(value);
                                            }}
                                            //error={errors.from_iso as any}
                                        />
                                    </Flex>
                                    <Flex xs={8} sm={5} md={5} lg={5}>
                                        <Input
                                            label="to"
                                            name="to_iso"
                                            type="date"
                                            min={new Date().toISOString().split('T')[0]}
                                            value={toIso}
                                            onChange={({
                                                name,
                                                value,
                                            }: {
                                                name: string;
                                                value: string;
                                            }) => {
                                                setToIso(value);
                                            }}
                                            //error={errors.to_iso as any}
                                        />
                                    </Flex>
                                    <FlexEnd xs={4} sm={2} md={2} lg={2}>
                                        <AddIcon type="submit" onClick={onSubmit}>
                                            <i className="fal fa-plus"></i>
                                        </AddIcon>
                                    </FlexEnd>
                                </Row>
                            </Container>
                        </div>
                    )}
                    <CustomCol lg={12}>
                        {timeoff !== undefined &&
                            timeoff.map((time: ITimeOff, i: any) => (
                                <div key={i}>
                                    <Container key={i} style={{ marginBottom: '0.5rem' }}>
                                        <Row>
                                            <Flex xs={8} sm={5} md={5} lg={5}>
                                                <CustomDiv>
                                                    From&nbsp;
                                                    <b>
                                                        {time.from_iso
                                                            ? moment(time.from_iso).format(
                                                                  'MM/DD/YYYY'
                                                              )
                                                            : 'No Time Off'}{' '}
                                                    </b>
                                                </CustomDiv>
                                            </Flex>
                                            <Flex xs={8} sm={5} md={5} lg={5}>
                                                <CustomDiv>
                                                    To&nbsp;
                                                    <b>
                                                        {time.from_iso
                                                            ? moment(time.to_iso).format(
                                                                  'MM/DD/YYYY'
                                                              )
                                                            : 'No Time Off'}
                                                    </b>
                                                </CustomDiv>
                                            </Flex>
                                            {!readOnly && (
                                                <FlexEnd xs={4} sm={2} md={2} lg={2}>
                                                    <DeletIcon
                                                        onClick={() => openDeleteModel(time._id)}>
                                                        <i className="fal fa-trash-alt"></i>
                                                    </DeletIcon>
                                                </FlexEnd>
                                            )}
                                        </Row>
                                    </Container>
                                </div>
                            ))}
                    </CustomCol>
                    {deleteModel && (
                        <DeleteModel
                            id={deleteId}
                            onDelete={() => {
                                setDeleteModel(false);
                            }}
                            name="Time Off"
                            content="Are you sure you want to delete?"
                            loading={loading}
                            onCLick={() => deleteTeamTimeOff(deleteId)}
                            onClose={() => {
                                setDeleteModel(false);
                            }}
                        />
                    )}
                </Col>
            </Row>
        </Container>
    );
};

const Wrapper = styled(Container)``;
const AddIcon = styled.button`
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1rem 1.71rem 0 rgb(0 0 0 / 28%);
    background: #0154ff;
    border-radius: 100%;
    color: #fff;
    font-size: 1.5rem;
    border: none;
    cursor: pointer;
`;
const List = styled.div`
    display: flex;
    justify-content: space-around;
    margin-top: 0.4rem;
    padding-top: 1rem;
    border-top: 1px solid #e5e7eb;
`;
const CustomDiv = styled.div`
    margin-left: 1rem;
`;
const DeletIcon = styled.button`
    padding: 0;
    width: 1.85rem;
    height: 1.85rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 0rem;
    background-color: ${palette.primary};
    color: ${palette.light};
    font-weight: 600;
    text-transform: capitalize;
    border-radius: 0.51rem;
    font-size: 0.87rem;
    cursor: pointer;
`;
const CustomCol = styled(Col)`
    padding: 0;
    margin-top: 1rem;
`;
const Flex = styled(Col)`
    display: flex;
    justify-content: end;
    align-items: center;
    margin-bottom: 0rem;
    ${media.xs`
    justify-content: start;
    margin-bottom:1rem;
	`}
    ${media.sm`
    justify-content: end;
	`}
	${media.md`
    justify-content: end;
	`}
	${media.lg`
    justify-content: end;
	`}
    @media screen and (min-width: 200px) and (max-width: 1050px) {
        padding-right: 0rem;
    }
`;
const FlexEnd = styled(Col)`
    display: flex;
    justify-content: end;
    align-items: center;
`;
export default TimeOff;
