import Button from '@components/Button';
import { Input, Select, useSnackbar } from '@components/common';
import { api } from 'helpers/auth-axios';
import moment from 'moment';
import { useState } from 'react';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { palette } from 'styled/common';
export interface IBlockModel {
    onClose(): void;
    serviceProviderId: any;
    blockDate: any;
    startTime: any;
    endTime: any;
    time_select?: any;
}

const formatDate = (date: Date) => {
    return moment(date).format('YYYY-MM-DD') + 'T' + moment(date).format('HH:mm:ss');
};
const BlockModel = ({
    onClose,
    serviceProviderId,
    blockDate,
    startTime,
    endTime,
    time_select,
}: IBlockModel) => {
    const [startDate, setStartDate] = useState<any>();
    const [start, setStart] = useState<any>(formatDate(startTime));
    const [end, setEnd] = useState<any>(formatDate(endTime));
    const [reasonForBlocking, setReasonForBlocking] = useState<any>();
    const [openSnackbar] = useSnackbar();
    const closeModal = (event: any) => {
        // event.preventDefault();
        if (event.target === event.currentTarget) {
            onClose();
        }
    };
    const appointmentTime: { label: string; value: any }[] = [];
    for (let i: any = 0; i < 24; i++) {
        for (let j = 0; j < 4; j++) {
            appointmentTime.push({
                label: moment({
                    hour: i,
                    minute: j * 15,
                }).format(time_select === '12' ? 'hh:mm a' : 'HH:mm'),
                value: moment({ hour: i, minute: j * 15 }).format('HH:mm'),
            });
        }
    }

    const blockStartDate =
        moment(startDate ? startDate : blockDate).format('YYYY-MM-DD') +
        'T' +
        moment(start).format('HH:mm') +
        ':00';

    const blockEndDate =
        moment(startDate ? startDate : blockDate).format('YYYY-MM-DD') +
        'T' +
        moment(end).format('HH:mm') +
        ':00';

    const bookedFrom = moment(blockStartDate);
    const bookedTill = moment(blockEndDate);
    const duration = bookedTill.diff(bookedFrom, 'minutes');

    const saveBlock = () => {
        const block = {
            booked_from_iso: bookedFrom.format(),
            booked_till_iso: bookedTill.format(),
            is_blocked: true,
            block_reason: reasonForBlocking,
            duration: duration,
        };
        api.post(`/appointments/blocked/${serviceProviderId}`, block)
            .then((res: any) => {
                openSnackbar('Block added successfully!');
                onClose();
            })
            .catch((e: any) => {
                openSnackbar('Block not added!');
                onClose();
            });
    };
    return (
        <>
            <Container>
                <Model tabIndex={-1} onClick={closeModal}>
                    <ModelBody lg={12}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <ModelTitle>
                                <ModelHeading> Block Details</ModelHeading>
                            </ModelTitle>
                            <ModelClose>
                                <CloseButton onClick={onClose}>
                                    {' '}
                                    <i className="fas fa-times"></i>
                                </CloseButton>
                            </ModelClose>
                        </div>

                        <CustomDiv>
                            <InputGroup>
                                <CalenderDate>
                                    <Input
                                        type="date"
                                        label="Date"
                                        min={new Date().toISOString().split('T')[0]}
                                        value={
                                            startDate
                                                ? moment(startDate).format('YYYY-MM-DD')
                                                : moment(blockDate).format('YYYY-MM-DD')
                                        }
                                        onChange={(e: any) => setStartDate(e.value)}
                                    />

                                    <input
                                        name="hidenDate"
                                        type="date"
                                        min={moment().format('YYYY-MM-DD')}
                                        value={
                                            startDate
                                                ? moment(startDate).format('YYYY-MM-DD')
                                                : moment(blockDate).format('YYYY-MM-DD')
                                        }
                                        onChange={(e: any) => setStartDate(e.target.value)}
                                    />
                                </CalenderDate>
                            </InputGroup>
                            <InputGroup>
                                <Select
                                    label="Start"
                                    value={moment(start).format(
                                        time_select === '12' ? 'hh:mm a' : 'HH:mm'
                                    )}
                                    options={appointmentTime}
                                    onChange={(val: string) => {
                                        const newStart =
                                            moment(start).format('YYYY-MM-DD') + 'T' + val + ':00';

                                        setStart(newStart);
                                    }}
                                />
                            </InputGroup>
                            <InputGroup>
                                <Select
                                    label="End"
                                    value={moment(end).format(
                                        time_select === '12' ? 'hh:mm a' : 'HH:mm'
                                    )}
                                    options={appointmentTime}
                                    onChange={(val: string) => {
                                        const newStart =
                                            moment(end).format('YYYY-MM-DD') + 'T' + val + ':00';

                                        setEnd(newStart);
                                    }}
                                />
                            </InputGroup>
                            <InputGroup>
                                <Input
                                    label="Reason for blocking (optional)"
                                    name="reason"
                                    onChange={(event: any) => setReasonForBlocking(event.value)}
                                    maxLength={30}
                                    showCharacterCount={true}
                                />
                            </InputGroup>
                        </CustomDiv>
                        <ModelButtons>
                            <Button
                                ifClicked={() => saveBlock()}
                                bgtype={'secondary'}
                                label="Save Block to Schedule"
                                width="100% !important"></Button>
                        </ModelButtons>
                    </ModelBody>
                </Model>
            </Container>
        </>
    );
};

const Model = styled(Row)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: ${palette.overlay};
    padding: 1.8rem;
    overflow: auto;
    overscroll-behavior-y: contain;
    z-index: 102;
`;
const ModelBody = styled(Col)`
    display: flex;
    flex-direction: column;
    background: ${palette.white};
    border-radius: 0.6rem;
    box-shadow: 0 1rem 1.8rem -1.4rem ${palette.grey.shadow};
    padding: 1.7rem;
    max-width: 35vw;
    ${media.xs`
        width: 100%;
        max-width: 100%;
    `}
    ${media.sm`
        width: 100%;
        max-width: 60vw;
    `}
     ${media.md`
        width: 100%;
        max-width: 45vw;
    `}
    ${media.lg`
        max-width: 35vw;
    `}
`;
const ModelClose = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
`;
const CloseButton = styled.button`
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${palette.silver};
    background-color: ${palette.grey.lightest};
    border-radius: 0.5rem;
    font-size: 1.2rem;
    border: none;
    cursor: pointer;
`;
const ModelTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;
const CustomDiv = styled.div`
    // display: flex;
    // justify-content: space-between;
`;
const InputGroup = styled.div`
    margin-bottom: 1rem;
`;
const DeletIcon = styled.div`
    margin-right: 1rem;
    width: 3rem;
    height: 3rem;
    border-radius: 9999px;
    background-color: ${palette.primary};
    color: ${palette.white};
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Icon = styled.i`
    font-size: 1.25rem;
    line-height: 1.75rem;
`;
const ModelHeading = styled.h1`
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 600;
    ${media.xs`
     font-size: 1.7rem;
    `}
`;
const ModelContent = styled.div`
    flex: 1 1;
    display: flex;
    flex-direction: column;
    padding-top: 1.25rem !important;
    padding-bottom: 2.5rem !important;
    color: rgba(157, 160, 164);
`;

const ModelButtons = styled.div`
    display: flex;
    justify-content: center;
`;
const CalenderDate = styled.div`
    position: relative;
    input[name='hidenDate'] {
        border: none;
        box-sizing: border-box;
        outline: 0;
        position: absolute;
        width: 100%;
        opacity: 0;
        cursor: pointer;
        top: 0;
        bottom: 0;
    }
    input[name='hidenDate']::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }
`;

export default BlockModel;
