import Compressor from 'compressorjs';
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';

import { userActions, getSaveUser } from 'actions/user';
import Button from '@components/Button';
import { Input, Select, Spinner, WarningModal } from '@components/common';
import ProfileImageCropperModal from '@components/common/ProfileImageCropperModal';
import { api } from 'helpers/auth-axios';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from 'hooks/redux';
import useRouter from 'hooks/router';
import { palette } from 'styled/common';
import { useSnackbar } from './Snackbar';

interface ErrorMessage {
    message: string;
}

interface Props {
    onClose(): void;
    actions?: any;
}

const EditProfileModel = ({ onClose, actions }: Props) => {
    const [loading, setLoading] = useState(false);

    // Profile image upload state-variables
    const [showCropperModal, setShowCropperModal] = useState<boolean>(false);
    const [selectedImage, setSelectedImage] = useState<any>(); // this is the image before cropping
    const [profileImage, setProfileImage] = useState<any>(null); // this is after cropping
    const [uploadImage, setUploadImage] = useState<boolean>(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [nameFieldErrorMessage, setNameFieldErrorMessage] = useState<ErrorMessage | undefined>();
    const [emailFieldErrorMessage, setEmailFieldErrorMessage] = useState<
        ErrorMessage | undefined
    >();
    const [oldPasswordFieldErrorMessage, setOldPasswordFieldErrorMessage] = useState<
        ErrorMessage | undefined
    >();
    const [newPasswordFieldErrorMessage, setNewPasswordFieldErrorMessage] = useState<
        ErrorMessage | undefined
    >();
    const [confirmPasswordFieldErrorMessage, setConfirmPasswordFieldErrorMessage] = useState<
        ErrorMessage | undefined
    >();

    const [isChangingPassword, setIsChangingPassword] = useState(false);

    const [showChangeEmailPrompt, setShowChangeEmailPrompt] = useState<boolean>(false);

    const [openSnackbar] = useSnackbar();

    const closeModal = (event: any) => {
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    const getNormalizedFile = (file: any) => {
        return new Promise((resolve, reject) => {
            new Compressor(file, {
                maxWidth: 750,
                maxHeight: 750,
                success(normalizedFile) {
                    resolve(normalizedFile);
                },
                error(error) {
                    reject(error);
                },
            });
        });
    };

    const readFile = useCallback((file: any) => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result);
                getNormalizedFile(file)
                    .then((normalizedFile: any) => reader.readAsDataURL(normalizedFile))
                    .catch((error: any) => reject(error));
            } catch (error) {
                reject(error);
            }
        });
    }, []);

    const handleCapture = async (e: any) => {
        try {
            e.preventDefault();
            if (e.target?.files?.length > 0) {
                const file = e.target.files[0];
                const imageDataUrl = await readFile(file);
                setSelectedImage(imageDataUrl);
                e.target.value = null;
                setShowCropperModal(true);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onSubmit = () => {
        setLoading(true);

        // perform password field checks
        if (isChangingPassword) {
            if (newPassword.length < 8) {
                setNewPasswordFieldErrorMessage({
                    message: 'Password length should be at least 8 characters.',
                });
                setLoading(false);
                return;
            }

            if (newPassword !== confirmPassword) {
                setLoading(false);
                return;
            }

            if (oldPassword === '') {
                setLoading(false);
                return;
            }

            if (newPassword === oldPassword) {
                setNewPasswordFieldErrorMessage({
                    message: "Come on... Don't use your old password as your new password.",
                });
                setLoading(false);
                return;
            }
        }

        if (name === '') {
            setNameFieldErrorMessage({ message: 'Name is required' });
            setLoading(false);
            return;
        }

        if (email === '') {
            setEmailFieldErrorMessage({ message: 'Email is required' });
            setLoading(false);
            return;
        }

        const userData = {
            name: name,
            email: email,
            oldPassword: isChangingPassword ? oldPassword : '',
            newPassword: isChangingPassword ? newPassword : '',
        };

        api.put('/users/update_profile', {
            ...userData,
            newPhoto: uploadImage === true ? profileImage : null,
        })
            .then((res: any) => {
                var _userData = JSON.parse(localStorage.getItem('user') || '{}');
                const changedEmail = _userData.user.email !== res.data.email;
                if (res !== undefined) {
                    _userData.user.name = res.data.name;
                    _userData.user.email = res.data.email;
                    _userData.user.photo = res.data.photo;
                    localStorage.setItem('user', JSON.stringify(_userData));
                    actions.getSaveUser(res.data);
                }

                setLoading(false);
                openSnackbar('Updated successfully!');

                if (changedEmail) {
                    logout();
                    return;
                }

                onClose();
            })
            .catch((e: any) => {
                setLoading(false);
                onClose();
                openSnackbar(e?.response?.data?.message);
            });
    };

    const getUser = () => {
        var _user: any = JSON.parse(localStorage.getItem('user') || '{}');
        if (_user !== undefined && _user.accessToken) {
            api.post('/auth/success', { accessToken: _user.accessToken }).then((res: any) => {
                setName(res?.data?.user?.name);
                setEmail(res?.data?.user?.email);
                setProfileImage(res?.data?.user?.photo);
            });
        }
    };

    useEffect(() => {
        getUser();
    }, []);

    useEffect(() => {
        getUser();
    }, [onClose]);

    useEffect(() => {
        // only proceed if we're changing password
        if (!isChangingPassword) {
            return;
        }

        setNewPasswordFieldErrorMessage(undefined);
        setConfirmPasswordFieldErrorMessage(undefined);

        // if both new and confirm password are empty, there should be no error for confirm password field
        if (newPassword === '' && confirmPassword === '') {
            setConfirmPasswordFieldErrorMessage(undefined);
            return;
        }

        if (newPassword !== confirmPassword) {
            setConfirmPasswordFieldErrorMessage({ message: "Passwords don't match." });
        } else {
            setConfirmPasswordFieldErrorMessage(undefined);
        }
    }, [newPassword, confirmPassword, isChangingPassword]);

    useEffect(() => {
        // only proceed if we're changing password
        if (!isChangingPassword) {
            return;
        }

        setOldPasswordFieldErrorMessage(undefined);

        if (oldPassword === '') {
            setOldPasswordFieldErrorMessage({ message: 'Old Password is required' });
        } else {
            setOldPasswordFieldErrorMessage(undefined);
        }
    }, [oldPassword, isChangingPassword]);

    const showPasswordInputs = () => {
        setIsChangingPassword(prevValue => !prevValue);
    };

    const logout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('url');
        window.location.href = '/login';
    };

    return (
        <Model tabIndex={-1} onClick={closeModal}>
            <ModelBody style={{ width: '650px' }}>
                <ModelHead>
                    <TitleBody>
                        <Icon className="fal fa-times" onClick={closeModal}></Icon>
                        <Heading>Edit Profile</Heading>
                    </TitleBody>
                </ModelHead>
                <ModelContent>
                    <Wrapper>
                        <Row>
                            <Col md={6} lg={6}>
                                <Wrapper>
                                    <Row>
                                        <Col lg={12}>
                                            <Cover>
                                                {profileImage !== null && (
                                                    <ProfileImage
                                                        src={
                                                            uploadImage == true
                                                                ? profileImage
                                                                : `${`https://profile-images-barberone-s3.s3.amazonaws.com/${profileImage}`}`
                                                        }
                                                    />
                                                )}
                                            </Cover>
                                        </Col>
                                        <Col lg={12}>
                                            <FileButton
                                                htmlFor="faceImage"
                                                aria-label="upload picture">
                                                Upload
                                            </FileButton>
                                            <FileInput
                                                accept="image/jpeg, image/png"
                                                id="faceImage"
                                                type="file"
                                                onChange={handleCapture}
                                            />
                                        </Col>
                                    </Row>
                                </Wrapper>
                            </Col>
                            <Col md={6} lg={6}>
                                <Wrapper>
                                    <Row>
                                        <Col sm={6} md={12} lg={12}>
                                            <InputGroup>
                                                <Input
                                                    label="Name"
                                                    name="name"
                                                    value={name}
                                                    onChange={(e: any) => {
                                                        setName(e.value.trim());
                                                    }}
                                                    error={nameFieldErrorMessage}
                                                />
                                            </InputGroup>
                                        </Col>
                                        <Col sm={6} md={12} lg={12}>
                                            <InputGroup>
                                                <Input
                                                    label="Email"
                                                    name="email"
                                                    value={email}
                                                    onChange={(e: any) => {
                                                        setEmail(e.value.trim());
                                                    }}
                                                    error={emailFieldErrorMessage}
                                                />
                                            </InputGroup>
                                        </Col>
                                        <Col sm={12} md={12} lg={12}>
                                            <Divider>
                                                <Span onClick={showPasswordInputs}>
                                                    Change Password
                                                </Span>
                                            </Divider>
                                        </Col>
                                        {isChangingPassword && (
                                            <>
                                                <Col sm={6} md={12} lg={12}>
                                                    <InputGroup>
                                                        <Input
                                                            label="Old Password"
                                                            name="oldPassword"
                                                            type="password"
                                                            value={oldPassword}
                                                            onChange={(e: any) => {
                                                                setOldPassword(e.value);
                                                            }}
                                                            error={oldPasswordFieldErrorMessage}
                                                        />
                                                    </InputGroup>
                                                </Col>
                                                <Col sm={6} md={12} lg={12}>
                                                    <InputGroup>
                                                        <Input
                                                            label="New Password"
                                                            name="newPassword"
                                                            type="password"
                                                            value={newPassword}
                                                            onChange={(e: any) => {
                                                                setNewPassword(e.value);
                                                            }}
                                                            error={newPasswordFieldErrorMessage}
                                                        />
                                                    </InputGroup>
                                                </Col>
                                                <Col sm={6} md={12} lg={12}>
                                                    {/* <InputGroup> */}
                                                    <Input
                                                        label="Confirm Password"
                                                        name="confirmPassword"
                                                        type="password"
                                                        value={confirmPassword}
                                                        onChange={(e: any) => {
                                                            setConfirmPassword(e.value);
                                                        }}
                                                        error={confirmPasswordFieldErrorMessage}
                                                    />
                                                    {/* </InputGroup> */}
                                                </Col>
                                            </>
                                        )}
                                    </Row>
                                </Wrapper>
                            </Col>
                        </Row>
                    </Wrapper>
                </ModelContent>
                <Footer>
                    <Button
                        type="submit"
                        bgtype={'secondary'}
                        disabled={loading}
                        ifClicked={() => {
                            var _userData = JSON.parse(localStorage.getItem('user') || '{}');
                            if (email !== _userData.user.email) {
                                setShowChangeEmailPrompt(true);
                            } else {
                                onSubmit();
                            }
                        }}
                        label="Update"></Button>
                    <Button ifClicked={onClose} bgtype={'discard'} label="Close"></Button>
                </Footer>
            </ModelBody>
            <div style={{ position: 'absolute' }}>
                <ProfileImageCropperModal
                    showModal={showCropperModal}
                    imgURL={selectedImage}
                    onModalClose={() => setShowCropperModal(false)}
                    onSaveHandler={(croppedImage: any) => {
                        setProfileImage(croppedImage);
                        setUploadImage(true);
                    }}
                />
            </div>
            {showChangeEmailPrompt && (
                <WarningModal
                    message={`Looks like you're changing your email address. If you press "Continue", you will be logged out and will have to log back in.`}
                    onContinue={() => {
                        setShowChangeEmailPrompt(false);
                        onSubmit();
                    }}
                    onClose={() => {
                        setShowChangeEmailPrompt(false);
                    }}></WarningModal>
            )}
        </Model>
    );
};
const Wrapper = styled(Container)`
    padding: 0rem;
`;
const Model = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 24px;
    overflow: auto;
    overscroll-behavior-y: contain;
    z-index: 102;
`;
const ModelBody = styled.div`
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 15px 25px -6px rgb(0 0 0 / 10%);
    padding: 24px;
    max-width: 82vw;
`;
const ModelHead = styled.div`
    display: flex;
    justify-content: start;
    margin-bottom: 0.75rem;
`;

const ModelContent = styled.div`
    padding: 2rem 0 1rem 0 !important;
    color: rgba(157, 160, 164) !important;
`;

const InputGroup = styled.div`
    margin-bottom: 1.71rem;
`;
const Footer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const TitleBody = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
`;

const Heading = styled.div`
    font-size: 25px;
    font-weight: 600;
    color: ${palette.dark};
`;

const Icon = styled.i`
    font-size: 2.25rem;
    line-height: 2.5rem;
    margin-right: 1.5rem;
    color: ${palette.dark};
`;

const Cover = styled.div`
    margin-bottom: 1.9rem;
    display: flex;
    justify-content: center;
`;

const ProfileImage = styled.img`
    object-fit: cover;
    width: 19rem;
    height: 19rem;
    border-radius: 12rem;
    ${media.xs`
    width: 10rem;
    height: 10rem;
  `}
    ${media.sm`
    width: 15rem;
    height: 15rem;
  `}
    ${media.md`
	width: 19rem;
    height: 19rem;
  `}
    ${media.lg`
    width: 19rem;
    height: 19rem;
  `}
`;
const FileButton = styled.label`
    width: 100%;
    height: 3.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${palette.secondary};
    border-radius: 0.5rem;
    color: white;
    text-transform: capitalize;
    cursor: pointer;
    margin-top: 1.71rem;
    margin-bottom: 0rem;

    ${media.xs`
    margin-top: 0rem;
    margin-bottom: 1.71rem;
  `}
    ${media.sm`
    margin-top: 0rem;
    margin-bottom: 1.71rem;
  `}
    ${media.md`
	margin-top: 1.71rem;
    margin-bottom: 0rem;
  `}
    ${media.lg`
    margin-top: 1.71rem;
  `}
`;

const FileInput = styled.input`
    display: none;
`;

const Span = styled.span``;
const Divider = styled.div`
    border: 0.01rem solid #f3f4f6;
    width: 100%;
    height: 0.1rem;
    position: relative;
    margin-top: 0rem !important;
    margin-bottom: 1.5rem !important;
    & ${Span} {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        background-color: #fff;
        width: 10.14rem;
        font-weight: 500;
        text-align: center;
        color: rgba(12, 83, 252);
    }
`;
const mapStateToProps = (state: any) => {
    return {
        user: state.auth.user,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        actions: bindActionCreators({ getSaveUser }, dispatch),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditProfileModel);
// export default EditProfileModel;
