import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import { Spinner, useSnackbar } from '@components/common';
import { businessActions } from 'actions/business';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
// import { IBusiness } from 'interfaces/business.interface';
import ColorPicker from '@components/common/ColorPicker';
import { api } from 'helpers/auth-axios';

interface IProps {
    btnBgColor: any;
    btnTextColor: any;
    setBtnTextColor: any;
    setBtnBgColor: any;
}
const CustomiseWidget = ({ btnBgColor, btnTextColor, setBtnTextColor, setBtnBgColor }: IProps) => {
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const dispatch = useAppDispatch();
    const [openSnackbar] = useSnackbar();
    // const [btnBgColor, setBtnBgColor] = useState<any>('');
    // const [btnTextColor, setBtnTextColor] = useState<any>('');
    const [business, setBusiness] = useState<any>();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        api.get(`/businesses/${_userData?.user?.business_id?._id}`).then((res: any) => {
            setBusiness(res?.data);
            setBtnBgColor(res?.data?.booking_widget?.button_bg_color);
            setBtnTextColor(res?.data?.booking_widget?.button_text_color);
        });
    }, []);
    const updateSettings = () => {
        setLoading(true);

        dispatch(
            businessActions.updateBookingSettings(_userData?.user?.business_id?._id, {
                booking_widget: { button_text_color: btnTextColor, button_bg_color: btnBgColor },
            })
        ).then((res: any) => {
            setLoading(false);
            if (res) {
                openSnackbar('Update successfully!');
            }
        });
    };
    return (
        <>
            {business && (
                <TopPadding>
                    <BottomPadding>
                        <Heading>Button Design</Heading>

                        <Wrapper>
                            <Row>
                                <Col md={6} lg={6}>
                                    <Text>Background Color</Text>
                                    <ColorPicker
                                        color={
                                            btnBgColor
                                                ? btnBgColor
                                                : business?.booking_widget?.button_bg_color
                                                ? business?.booking_widget?.button_bg_color
                                                : '#cf9d65'
                                        }
                                        onChange={(color: any) => setBtnBgColor(color)}
                                    />
                                </Col>
                                <Col md={6} lg={6}>
                                    <Text>Text Color</Text>
                                    <ColorPicker
                                        color={
                                            btnTextColor
                                                ? btnTextColor
                                                : business?.booking_widget?.button_text_color
                                                ? business?.booking_widget?.button_text_color
                                                : '#27292e'
                                        }
                                        onChange={(color: any) => setBtnTextColor(color)}
                                    />
                                </Col>
                            </Row>
                        </Wrapper>
                    </BottomPadding>
                    <Wrapper>
                        <Row>
                            <Col>
                                <Flex>
                                    <Button
                                        className="btn"
                                        disabled={loading}
                                        onClick={updateSettings}>
                                        {loading ? <Spinner /> : 'Update'}
                                    </Button>
                                </Flex>
                            </Col>
                        </Row>
                    </Wrapper>
                </TopPadding>
            )}
        </>
    );
};
const Wrapper = styled(Container)`
    padding: 0rem;
`;
const TopPadding = styled.div`
    padding-top: 1.5rem !important;
`;

const BottomPadding = styled.div`
    padding-bottom: 1rem !important;
`;

const Heading = styled.div`
    font-weight: 600 !important;
    padding-bottom: 0.25rem !important;
`;

const Text = styled.p`
    margin: 0rem;
    font-size: 0.9rem !important;
    color: rgba(103, 113, 126) !important;
    padding-bottom: 0.3rem !important;
`;

const Flex = styled.div`
    justify-content: flex-end;
    display: flex;
`;

const Button = styled.button`
    cursor: pointer;
    padding: 13px 26px;
    font-weight: 600;
    text-transform: capitalize;
    color: #fff;
    background: #27292e;
    border-radius: 8px;
    font-size: 13px;
    border: none;
    outline: none;
`;
export default CustomiseWidget;
