import canvasSize from 'canvas-size';

const createImage = (url: any): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', error => reject(error));
        image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
        image.src = url;
    });

function getRadianAngle(degreeValue: number) {
    return (degreeValue * Math.PI) / 180;
}

/*
 * Take an image input and a cropping area input, then make a new image from the
 * given crop area. Return the cropped image.
 */
export const getCroppedImg = async (imageSrc: any, pixelCrop: any, rotation: number = 0) => {
    const image: HTMLImageElement = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const maxSize = Math.max(image.width, image.height);
    let safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    // adjust safe area to meet canvas limitations in current browser
    const canvasLimitation = await canvasSize.maxArea({
        usePromise: true,
        useWorker: true,
    });
    if (safeArea > canvasLimitation.height) {
        safeArea *= canvasLimitation.height / safeArea;
    }

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    if (!ctx) return;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(getRadianAngle(rotation));
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
        data,
        Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
        Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    );

    // As Base64 string
    return canvas.toDataURL('image/jpeg');

    // As a blob
    // return new Promise(resolve => {
    //     canvas.toBlob(file => {
    //         resolve(URL.createObjectURL(file));
    //     }, 'image/jpeg');
    // });
};
