import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useRouter from 'hooks/router';
import styled from 'styled-components';
import moment from 'moment-timezone';
interface RouteLink {
    path: string;
    title: string;
    icon: string;
    patterns?: RouteLink[];
}
interface AsideProps {
    routes?: RouteLink[];
    openAsidNew?: any;
}
const AsideList: FC<AsideProps> = ({ routes, openAsidNew }) => {
    const router = useRouter();
    var _user = JSON.parse(localStorage.getItem('user') || '{}');
    var permission = _user?.user?.permissions;
    useEffect(() => {
        if (_user?.user?.business_id) {
            moment.tz.setDefault(_user?.user?.business_id?.timezone);
        }
    }, []);
    return (
        <>
            {_user?.user?.role == 'ADMIN'
                ? ''
                : routes?.map((link: RouteLink, i: number) => {
                      var pathName = router.pathname.split('/');
                      const isActive =
                          (link.patterns &&
                              link.patterns.find(
                                  linkPattern => router.pathname === linkPattern.path
                              )) ||
                          '/' + pathName[1] === link.path;
                      // router.pathname === link.path;

                      return (
                          <div key={i}>
                              {(permission?.dashboard === true && link.title === 'Dashboard') ||
                              (_user?.user_login === 'customer'
                                  ? link.title === 'Calendar'
                                  : permission?.calendar === true && link.title === 'Calendar') ||
                              (permission?.team === true &&
                                  _user?.user_login == 'user' &&
                                  link.title === 'Team') ||
                              (_user?.user_login !== 'customer' &&
                                  _user?.user_login != 'user' &&
                                  link.title === 'Profile') ||
                              (_user?.user_login == 'customer'
                                  ? link.title === 'Customers'
                                  : permission?.customers === true && link.title === 'Customers') ||
                              (permission?.sales === true && link.title === 'Sales') ||
                              (permission?.business === true &&
                                  link.title === 'Business Settings') ||
                              (permission?.activity === true && link.title === 'Activity') ? (
                                  <Link to={link.path}>
                                      <Routes
                                          className={`${isActive ? `activeTab` : 'active'}`}
                                          onClick={openAsidNew}>
                                          <ListIcon
                                              className={`${
                                                  _user?.user_login != 'user' &&
                                                  link.icon == 'far fa-cut'
                                                      ? 'far fa-user-circle'
                                                      : link.icon
                                              } ${
                                                  isActive ? `activeIcon` : 'activeIconColor'
                                              } `}></ListIcon>
                                          {_user?.user_login == 'customer' &&
                                          link.title == 'Customers'
                                              ? 'Profile'
                                              : link.title}
                                      </Routes>
                                  </Link>
                              ) : (
                                  ''
                              )}
                          </div>
                      );
                  })}
            {_user?.user_login !== 'customer' && _user?.user?.role !== 'ADMIN' && (
                <div key={routes!.length}>
                    <a target="_blank" href={'https://www.getexclusively.com/help#hs-chat-open'}>
                        <Routes className="active">
                            <ListIcon className={'far fa-comments activeIconColor'}></ListIcon>
                            Get Help
                        </Routes>
                    </a>
                </div>
            )}
        </>
    );
};

const ListIcon = styled.i`
    margin-right: 1.5rem;
    font-size: 1.25rem !important;
`;
const Routes = styled.div`
    font-weight: 500;
    padding: 16px 24px;
    width: 100%;
    display: inline-block;
`;
export default AsideList;
