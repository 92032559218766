import { permissionsConstants } from 'actions/permissions';
import { IGetPermissions } from 'interfaces/permissions.interface';

const INITIAL_STATE: IGetPermissions = {
    permissions: [],
};
export function permissions(state = INITIAL_STATE, action: any) {
    switch (action.type) {
        case permissionsConstants.GET_SUCCESS:
            return {
                ...action.permissions,
                permissions: state.permissions?.concat(action.permissions),
            };
        case permissionsConstants.CREATE_SUCCESS:
            return {
                ...state,
                permissions: state.permissions?.concat(action.permissions),
            };
        case permissionsConstants.UPDATE_SUCCESS:
            const prev = state.permissions.find(
                permissions => permissions._id === action.permissions._id
            );
            Object.assign(prev, action.permissions);
            return state;
        default:
            return state;
    }
}
