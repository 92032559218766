import { ICustomer } from './customer.interface';
import { IRecurring } from './recurring.interface';
import { IService } from './service.interface';
import { IServiceProvider } from './team/serviceProvider.interface';
import { ISquareCard } from './square.interface';

export enum AppointmentStatusEnum {
    PENDING = 'pending',
    CONFIRMED = 'confirmed',
    CHECKED_IN = 'checked-in',
    LATE = 'late',
    CANCELLED = 'cancelled',
    NO_SHOW = 'no-show',
    COMPLETED = 'completed',
}
export const AppointmentStatuses = [
    'pending',
    'confirmed',
    'checked-in',
    'late',
    'cancelled',
    'no-show',
    'completed',
];

export interface IAppointment {
    _id?: string;
    booked_from_iso: Date;
    booked_till_iso: Date;
    booked_from: string;
    booked_till: string;
    appointment_note: string;
    customer: ICustomer;
    customer_note: string;
    service: IService;
    total: string;
    status: AppointmentStatusEnum;
    total_value: number;
    recurring?: IRecurring;
    serviceProvider: IServiceProvider;
    overbooking: boolean; // TODO: What is this?
    platform?: any;
    duration?: string;
    payment?: string;
    payment_processor?: string;
    payment_intent_id?: string;
    payment_method_id?: string;
    square_payment_id?: string; // this is used to store the ID of the card used (or to be used) when charging appointment
    is_vip?: any;
    customer_token?: any;
    business_id: any;
    created_at?: Date;
}

export interface IGetAppointments {
    start_date?: string;
    end_date?: string;
    page?: number;
    pageSize?: number;
    customer?: string;
    status?: AppointmentStatusEnum;
}

export interface IGetAppointmentsResponse {
    appointments: IAppointment[];
    start_date: Date;
    end_date: Date;
}

export interface IGetCustomerAppointmentsResponse {
    bookings: IAppointment[];
    page: number;
    pageSize: number;
    total?: number;
}

export interface IUpdateAppointmentStatus {
    status: AppointmentStatusEnum;
}

export interface ICreateReservation {
    business_id: string;
    customer_details: {
        firstname: string;
        lastname: string;
        email: string;
        phone: string;
    };

    service_provider_id: string;
    service_id: string;
    appointment_date: string;
    start_time: string;
    recurring?: IRecurring;
    custom_duration?: number; // can only be used on the privileged "POST /appointments" endpoint
    is_vip: boolean;
    appointment_note?: string; // customers shouldn't be able to access this
    customer_note: string;

    payment_processor?: string;
    square_card_info?: {
        card: ISquareCard;
        bookingFeeToken: any; // in the case of reservation only, we send a second payment token so we can immediately charge $1 booking fee
    };
    stripe_card_info?: {
        cardNumber: string;
        cardHolder: string;
        cardMonth: string;
        cardYear: string;
        cardCVV: string;
    };
    tip_amount?: number; // in cents
    tip_label?: string;
    is_prepaying?: boolean;

    platform: string; // which platform the request is coming from (web, mobile, widget, etc.)
}

export interface IPayForAppointment {
    paymentProcessor: string;

    /*
     * The following card info properties only need to
     * be set when the payment method has changed.
     */
    squareCardInfo?: {
        token: any;
        verifiedBuyer: any;
    };
    stripeCardInfo?: {
        cardNumber: string;
        cardHolder: string;
        cardMonth: string;
        cardYear: string;
        cardCVV: string;
    };

    tipLabel: string;

    /*
     * newTipAmount only needs to be set when the tip amount has changed.
     */
    newTipAmount?: number; // in cents; this must be editable so we can sanitize and set to 0, if negative
}
