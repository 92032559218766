import { api } from 'helpers/auth-axios';
import { IBreaks, IGetBreaks } from '../interfaces/breaks.interface';

function create(user_id: string, breaks: IBreaks): Promise<IBreaks> {
    return api.post(`/breaks/${user_id}`, breaks).then(res => {
        return res.data;
    });
}
function getBreaks(user_id: string): Promise<IGetBreaks[]> {
    return api.get(`/breaks/all/${user_id}`).then(res => {
        return res.data;
    });
}
function deleteBreak(id: string): Promise<IBreaks> {
    return api.delete(`/breaks/${id}`).then(res => {
        return res.data;
    });
}
export const BreaksServices = { create, getBreaks, deleteBreak };
