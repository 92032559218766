export const businessTypes = [
    {
        label: 'Barbershop',
    },
    {
        label: 'Salon',
    },
    {
        label: 'Tattoo Shop',
    },
    {
        label: 'Nail Spa',
    },
    {
        label: 'Eyebrow Boutique',
    },
    {
        label: 'Eyelash Studio',
    },
    {
        label: 'Spa',
    },
    {
        label: 'Other',
    },
];

export const defaultOpenHours = [
    {
        mo_from: '10:00',
        mo_to: '19:00',
        tu_from: '10:00',
        tu_to: '19:00',
        we_from: '10:00',
        we_to: '19:00',
        th_from: '10:00',
        th_to: '19:00',
        fr_from: '10:00',
        fr_to: '19:00',
        sa_from: '10:00',
        sa_to: '19:00',
        su_from: '10:00',
        su_to: '19:00',
        mo_on: true,
        tu_on: true,
        we_on: true,
        th_on: true,
        fr_on: true,
        sa_on: false,
        su_on: false,
    },
];

export const bookingWindow = ['15 minutes', '30 minutes', '1 hour', '2 hours', '1 day'];
