import { combineReducers } from 'redux';

import { auth } from './auth.reducer';
import { user } from './user.reducer';
import { business } from './business.reducer';
import { customer } from './customer.reducer';
import { service } from './service.reducer';
import { appointment } from './appointment.reducer';
import { serviceProvider } from './team/serviceProvider.reducer';
import { team } from './team/teamMember.reducer';
import { other } from './team/other.reducer';
import { timeoff } from './timeoff.reducer';
import { breaks } from './break.reducer';
import { workingHours } from './workingHours.reducer';
import { permissions } from './permissions.reducer';
import { calenderCurrentView } from './calender.reducer';

const rootReducer = combineReducers({
    auth,
    user,
    business,
    customer,
    service,
    appointment,
    serviceProvider,
    team,
    other,
    timeoff,
    breaks,
    workingHours,
    permissions,
    calenderCurrentView,
});

export default rootReducer;
