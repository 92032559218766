import React, { useEffect, useState } from 'react';
import Button from '@components/Button';
import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import { Layout, useSnackbar } from '@components/common';
import { Link, useNavigate } from 'react-router-dom';
import { Input, Select, Switch } from '@components/common';
import { palette } from '../../../styled/common';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { serviceActions } from 'actions/service';
import { useAppDispatch } from 'hooks/redux';
import { IService } from 'interfaces/service.interface';

import s from './CreateService.module.scss';

const hoursArr: { label: string; value: number }[] = [];
for (let i = 0; i < 24; i++) {
    hoursArr.push({
        label: `${i}h`,
        value: i,
    });
}

const minutesArr: { label: string; value: number }[] = [];
for (let i = 0; i < 4; i++) {
    minutesArr.push({
        label: `${i * 15}min`,
        value: i * 15,
    });
}

const validationSchema = Yup.object().shape({
    name: Yup.string().required('This field is required'),
    price: Yup.number().typeError('A valid number is required').required('This field is required'),
});

const CreateService = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [price, setPrice] = useState('');
    const [serviceColor, setServiceColor] = useState(Math.floor(Math.random() * 32));
    const [selfBooking, setSelfBooking] = useState(true);
    const [showColorModal, setShowColorModal] = useState(false);
    const [durationHours, setDurationHours] = useState(0);
    const [durationMinutes, setDurationMinutes] = useState(30);
    const [openSnackbar] = useSnackbar();
    const dispatch = useAppDispatch();
    const { handleSubmit, setValue, getValues, formState, trigger } = useForm<IService>({
        resolver: yupResolver(validationSchema),
    });
    const { errors } = formState;

    async function onChange({ name, value }: { name: string; value: string }) {
        setValue(name as never, value as never);
        await trigger(name as never);
    }

    const onSubmit = (data: any) => {
        const serviceData = {
            ...data,
            duration: durationHours * 60 + durationMinutes,
            price,
            my_duration: durationHours * 60 + durationMinutes,
            my_price: price,
            available_for_boooking: selfBooking,
            color: serviceColor,
            vip_price: price,
        };

        setLoading(true);
        dispatch(serviceActions.createService(serviceData))
            .then((res: IService) => {
                setLoading(false);
                navigate('/settings/services');
                openSnackbar('Service added successfully!');
            })
            .catch((e: any) => {
                setLoading(false);
                openSnackbar('Failed to add service. Please try again later.');
            });
    };

    const changeServiceColor = (color: number) => {
        setServiceColor(color);
        setShowColorModal(false);
    };

    const closeModal = (event: any) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
            setShowColorModal(false);
        }
    };
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    return (
        <MainContent>
            <Inner>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Wrapper>
                        <Row>
                            <Header lg={12}>
                                <Flex>
                                    <Link to="/settings/services">
                                        <Cross className="fal fa-times"></Cross>
                                    </Link>
                                    <Heading>Add Service</Heading>
                                </Flex>
                                <Flex>
                                    <Button
                                        type="submit"
                                        label={'Save'}
                                        disabled={loading}
                                        bgtype="secondary"></Button>
                                </Flex>
                            </Header>
                            <Col lg={12}>
                                <Card>
                                    <Wrapper>
                                        <Row>
                                            <LeftCol sm={6} md={6} lg={6}>
                                                <CardBody>
                                                    <InputGroup>
                                                        <Input
                                                            label="Service name"
                                                            name="name"
                                                            onChange={onChange}
                                                            error={errors.name as any}
                                                        />
                                                    </InputGroup>

                                                    <InputGroup>
                                                        <Input
                                                            label="Service Description"
                                                            name="description"
                                                            onChange={onChange}
                                                            maxLength={150}
                                                            showCharacterCount={true}
                                                        />
                                                    </InputGroup>

                                                    <Wrapper>
                                                        <Row>
                                                            <Col lg={6}>
                                                                <InputGroup>
                                                                    <Select
                                                                        label="Hour(s)"
                                                                        options={hoursArr}
                                                                        value={durationHours + 'h'}
                                                                        onChange={(val: string) => {
                                                                            setDurationHours(
                                                                                parseInt(val)
                                                                            );
                                                                        }}
                                                                    />
                                                                </InputGroup>
                                                            </Col>
                                                            <Col lg={6}>
                                                                <InputGroup>
                                                                    <Select
                                                                        label="Minutes"
                                                                        options={minutesArr}
                                                                        value={
                                                                            durationMinutes + 'min'
                                                                        }
                                                                        onChange={(val: string) => {
                                                                            setDurationMinutes(
                                                                                parseInt(val)
                                                                            );
                                                                        }}
                                                                    />
                                                                </InputGroup>
                                                            </Col>
                                                        </Row>
                                                    </Wrapper>

                                                    <Wrapper>
                                                        <Row>
                                                            <Col lg={12}>
                                                                <InputGroup>
                                                                    <Input
                                                                        name="price"
                                                                        label={`Price(${_userData.user.business_id.currency})`}
                                                                        onChange={onChange}
                                                                        value={price}
                                                                        onBlur={() => {
                                                                            const formatPrice = (
                                                                                Math.round(
                                                                                    parseInt(
                                                                                        getValues(
                                                                                            'price'
                                                                                        )
                                                                                    ) * 100
                                                                                ) / 100
                                                                            )
                                                                                .toFixed(2)
                                                                                .toString();

                                                                            setPrice(formatPrice);
                                                                            setValue(
                                                                                'price',
                                                                                formatPrice
                                                                            );
                                                                        }}
                                                                        error={errors.price as any}
                                                                    />
                                                                </InputGroup>
                                                            </Col>
                                                        </Row>
                                                    </Wrapper>
                                                </CardBody>
                                            </LeftCol>
                                            <Col sm={6} md={6} lg={6}>
                                                <TabsWrapper>
                                                    <Tab>
                                                        <TabName>Settings</TabName>
                                                    </Tab>
                                                </TabsWrapper>
                                                <InnerContent>
                                                    <AlignFlex>
                                                        <div>Show on Widget</div>
                                                        <Switch
                                                            value={selfBooking}
                                                            onChange={() => setSelfBooking(false)}
                                                        />
                                                    </AlignFlex>
                                                    <AlignFlex>
                                                        <div>Service Color</div>
                                                        <div
                                                            className={`${s.service_colorPicker} service-color-${serviceColor}--bd-light`}
                                                            onClick={() => setShowColorModal(true)}>
                                                            <Icon className={`fal fa-pen`}></Icon>
                                                        </div>
                                                    </AlignFlex>
                                                </InnerContent>
                                            </Col>
                                        </Row>
                                    </Wrapper>
                                </Card>
                            </Col>
                        </Row>
                    </Wrapper>
                </form>

                {showColorModal && (
                    <Model tabIndex={-1} onClick={closeModal}>
                        <ModelBody>
                            <ModelHeader>
                                <Cross
                                    className="fal fa-times"
                                    onClick={() => setShowColorModal(false)}></Cross>
                                <Heading>Service Color</Heading>
                            </ModelHeader>
                            <ModelContent>
                                <ModelWrapper>
                                    <Row style={{ justifyContent: 'center' }}>
                                        {[...Array(32)].map((x, i) => (
                                            <Color key={i}>
                                                <div
                                                    className={` ${s.colorPicker_serviceColor} service-color-${i}--bd-light`}
                                                    onClick={() => changeServiceColor(i)}>
                                                    {serviceColor === i && (
                                                        <IconCover>
                                                            <i className="fal fa-check"></i>
                                                        </IconCover>
                                                    )}
                                                </div>
                                            </Color>
                                        ))}
                                    </Row>
                                </ModelWrapper>
                            </ModelContent>
                        </ModelBody>
                    </Model>
                )}
            </Inner>
        </MainContent>
    );
};
const MainContent = styled.div`
    background-color: ${palette.grey.light};
    position: absolute;
    top: 4.571rem;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 2.7rem;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    ${media.xs`
	height: auto;
  `}
    ${media.sm`
	height: 100%;
  `}
	${media.md`
	height: 100%;
  `}
	${media.lg`
	height: 100%;
  `}
`;
const Inner = styled.div`
    margin-left: auto !important;
    margin-right: auto !important;
`;

const Wrapper = styled(Container)`
    padding: 0rem;
`;
const Header = styled(Col)`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.75rem !important;
`;
const Flex = styled.div`
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
`;

const LeftCol = styled(Col)`
    border-right: 0.1rem solid ${palette.grey.lightest};
    ${media.xs`
	border-right: none;
  `}
    ${media.sm`
	border-right: 0.1rem solid ${palette.grey.lightest};
  `}
	${media.md`
	border-right: 0.1rem solid ${palette.grey.lightest};
  `}
	${media.lg`
	border-right: 0.1rem solid ${palette.grey.lightest};
  `}
`;
const InputGroup = styled.div`
    margin-bottom: 1.71rem;
`;

const AlignFlex = styled.div`
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    border-bottom: 0.1rem solid ${palette.grey.lightest}!important;
    justify-content: space-between !important;
    align-items: center !important;
    display: flex !important;
`;

const Card = styled.div`
    background-color: ${palette.light};
    box-shadow: 0rem 0rem 2.14rem 0rem ${palette.grey.shadow};
    border-radius: 0.75rem;
`;

const CardBody = styled.div`
    padding: 3rem 0rem 1.75rem 2rem !important;
    ${media.xs`
	padding: 3rem 2rem 1.75rem 2rem !important;
  `}
    ${media.sm`
	padding: 3rem 0rem 1.75rem 2rem !important;
  `}
	${media.md`
	padding: 3rem 0rem 1.75rem 2rem !important;
  `}
	${media.lg`
	padding: 3rem 0rem 1.75rem 2rem !important;
  `}
`;
const Heading = styled.h1`
    font-weight: 600 !important;
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
    margin: 0;
`;
const Cross = styled.i`
    font-size: 2.25rem !important;
    line-height: 2.5rem !important
    cursor: pointer !important;
    margin-right: 1.5rem !important;
`;
const TabsWrapper = styled.div`
    border-bottom: 0.1rem solid ${palette.grey.lightest};
    padding-left: 1.071rem;
    padding-right: 1.071rem;
`;
const Tab = styled.ul`
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
`;

const TabName = styled.li`
	text-transform: capitalize;
	padding: 0 1.14rem;
	height: 3.42rem;
	display: flex;
	cursor: pointer;
	position: relative;
	font-size: 0.85rem;
	align-items: center;
	color:  ${palette.dark};
	font-weight: 500;
	:after {
		content: '';
		width: calc(100% - 32px);
		height: 2px;
		background-color:  ${palette.dark};
		position: absolute;
		bottom: 0;
		left: 16px;
`;

const Icon = styled.i`
    font-size: 1.125rem;
    color: ${palette.white};
`;

const InnerContent = styled.div`
    padding: 0.25rem 1rem 1rem 1rem !important;
`;

const BookingText = styled(Col)`
    display: flex;
    align-items: center;
    padding-bottom: 0rem;
    ${media.xs`
	padding-bottom: 1.5rem;
  `}
    ${media.sm`
	padding-bottom: 1.5rem;
  `}
	${media.md`
	padding-bottom: 1.5rem;
  `}
	${media.lg`
	padding-bottom: 0rem;
  `}
`;

const CustomCol = styled(Col)`
    padding-bottom: 0rem;
    ${media.xs`
	padding-bottom: 1.5rem;
  `}
    ${media.sm`
	padding-bottom: 1.5rem;
  `}
	${media.md`
	padding-bottom: 1.5rem;
  `}
	${media.lg`
	padding-left: 0;
	padding-bottom: 0rem;
  `}
`;

const Model = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: ${palette.overlay};
    padding: 1.71rem;
    overflow: auto;
    overscroll-behavior-y: contain;
    z-index: 102;
`;

const ModelBody = styled.div`
    min-width: 45.71rem;
    display: flex;
    flex-direction: column;
    background: ${palette.white};
    border-radius: 0.57rem;
    box-shadow: 0 1.07rem 1.75rem 0.42rem ${palette.grey.shadow};
    padding: 1.71rem;
    width: 50rem;
    ${media.xs`
	min-width: 25rem;
  `}
    ${media.sm`
	min-width: 35rem;
  `}
	${media.md`
	min-width: 40rem;
  `}
	${media.lg`
	min-width: 45.71rem;
  `}
`;
const ModelHeader = styled.div`
    padding-top: 2.5rem !important;
    flex: 1 1;
    display: flex;
`;
const ModelContent = styled.div`
    padding-top: 2.5rem !important;
`;
const ModelWrapper = styled(Container)`
    height: 30rem;
    overflow-y: auto;
    overflow-x: hidden;
    ${media.xs`
	height: 22rem;
  `}
    ${media.sm`
	height: 24rem;
  `}
	${media.md`
	height: 24rem;
  `}
	${media.lg`
	height: 28rem;
  `}
`;
const Color = styled.div`
    margin-bottom: 1rem;
    padding: 0.3rem;
`;
const IconCover = styled.div`
    color: ${palette.white};
    font-size: 2.85rem;
`;

CreateService.Layout = Layout;
export default CreateService;
