import React, { FC } from 'react';
import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { Aside } from '@components/common';
interface Props {}
const CalendarLayout: FC<Props> = ({ children, ...props }) => {
    return (
        <Wrapper>
            <Aside />
            <PageContent>{children}</PageContent>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
    flex-direction: row !important;
    display: flex !important;
`;
const PageContent = styled.div`
    margin-left: 18.6rem;
    flex-direction: column !important;
    flex-grow: 1 !important;
    display: flex !important;
    ${media.xs`
  margin-left: 0rem;
`}
    ${media.sm`
  margin-left: 0rem;
`}
  ${media.md`
  margin-left: 0rem;
`}
  ${media.lg`
  margin-left: 18.6rem;
`}
`;
export default CalendarLayout;
