import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import { Link, Routes, Route, useNavigate } from 'react-router-dom';
import { ServiceProviderCard, TeamCard, OthersCard } from '@components/team';
const NotificationOptions = () => {
    const navigate = useNavigate();
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const [optionsList, setOptionList] = useState([
        {
            link: '/notifications/customer',
            title: 'User Notifications setting',
            subTitle: 'Manage Email, SMS and push notifications for user.',
        },
        {
            link: '/team/team-list',
            title: 'ServiceProvider Notifications setting',
            subTitle: 'Manage Email, SMS and push notifications for user.',
        },
        {
            link: '/team/other-list',
            title: 'Customer Notifications setting',
            subTitle: 'Manage Email, SMS and push notifications for user.',
        },
    ]);
    const callServiceProvider = () => {};
    const callTeam = () => {};
    const callOther = () => {};
    useEffect(() => {
        if (_userData.user_login == 'serviceProvider') {
            navigate(`/team/service-provider-list/${_userData.user._id}`);
        }
        if (_userData.user_login == 'teamMember') {
            navigate(`/team/team-list/${_userData.user._id}`);
        }
        if (_userData.user_login == 'others') {
            navigate(`/team/other-list/${_userData.user._id}`);
        }
    }, []);
    return (
        <>
            <Container>
                <Row>
                    <>
                        {optionsList.map((list, index) => {
                            return (
                                <Col key={index} md={6} lg={6} style={{ marginBottom: '1.5rem' }}>
                                    <CustomLink to={list.link}>
                                        <div>
                                            <Heading>{list.title}</Heading>
                                            <Text>{list.subTitle}</Text>
                                        </div>
                                        <div>
                                            <Arrow>
                                                <i className="fal fa-chevron-right"></i>
                                            </Arrow>
                                        </div>
                                    </CustomLink>
                                </Col>
                            );
                        })}
                    </>
                </Row>
            </Container>
        </>
    );
};
const CustomLink = styled(Link)`
    display: flex;
    justify-content: space-between;
    background: white;
    padding: 2rem 2rem;
    border-radius: 0.5rem;
    align-items: center;
`;
const Heading = styled.h1`
    color: #263446;
    font-size: 1.13rem;
    font-weight: 500;
`;
const Text = styled.p`
    color: #a8aeb5;
    font-size: 0.9rem;
`;
const Arrow = styled.span`
    color: #a8aeb5;
    font-size: 1.2rem;
`;
export default NotificationOptions;
