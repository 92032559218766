import React, { useState, useEffect } from 'react';
import Button from '@components/Button';
import styled from 'styled-components';
import { Col, Row, media, Container } from 'styled-bootstrap-grid';
import { Input, Select, useSnackbar } from '@components/common';
import { api } from 'helpers/auth-axios';

const EditSelectedServicesModel = ({
    editData,
    upDateCustomServie,
    onClose,
}: {
    editData: any;
    upDateCustomServie?: any;
    onClose(): void;
}) => {
    const DurationHours: { label: string; value: number }[] = [];
    for (let i = 0; i < 24; i++) {
        DurationHours.push({
            label: `${i}h`,
            value: i,
        });
    }

    const DurationMinutes: { label: string; value: number }[] = [];
    for (let i = 0; i < 4; i++) {
        DurationMinutes.push({
            label: `${i * 15}min`,
            value: i * 15,
        });
    }

    const customerVisibleSlotsOptions = [
        { label: 'every 15 minutes', value: 15 },
        { label: 'at the half hour', value: 30 },
        { label: 'on the hour', value: 60 },
    ];

    const [myPrice, setMyPrice] = useState<any>();
    const [myHour, setMyHour] = useState<any>();
    const [myMint, setMyMint] = useState<any>();
    const [vip_price, setVip_Price] = useState<any>();
    const [customerVisibleSlots, setCustomerVisibleSlots] = useState<number>();
    const [openSnackbar] = useSnackbar();
    const closeModal = (event: any) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
            onClose();
        }
    };
    const Save = () => {
        const myDuration = myHour ? parseInt(myHour) * 60 + parseInt(myMint) : parseInt(myMint);
        const serviceData: any = {
            my_duration: myDuration,
            my_price: myPrice ? parseFloat(myPrice)?.toFixed(2) : 0,
            vip_price: vip_price ? parseFloat(vip_price)?.toFixed(2) : 0,
            customer_booking_visible_slots: customerVisibleSlots,
        };
        api.put(`/staffServices/${editData._id}`, serviceData)
            .then((res: any) => {
                if (res) {
                    openSnackbar('Updated successfully!');
                    onClose();
                    return res;
                }
            })
            .catch((e: any) => {
                if (e?.response) openSnackbar(e?.response?.data?.message);
            });
    };
    useEffect(() => {
        if (editData != undefined && editData != '') {
            setMyHour(editData?.my_duration >= 60 ? Math.floor(editData?.my_duration / 60) : '');
            setMyMint(editData?.my_duration % 60);
            setMyPrice(editData?.my_price);
            setVip_Price(editData?.vip_price);
            setCustomerVisibleSlots(editData?.customer_booking_visible_slots ?? 15);
        }
    }, [editData]);
    return (
        <>
            <Model tabIndex={-1} onClick={closeModal}>
                <ModelBody lg={12}>
                    <ModelTitle>
                        <CloseButton onClick={onClose}>
                            {' '}
                            <Icon className="fal fa-times"></Icon>
                        </CloseButton>
                        <ModelHeading>Edit Services</ModelHeading>
                    </ModelTitle>
                    <ModelContent>
                        <InputField>
                            <Input
                                label="My Price"
                                name="price"
                                value={myPrice}
                                onChange={(e: any) => setMyPrice(e.value)}
                            />
                        </InputField>
                        <InputField>
                            <Input
                                label="VIP Price"
                                name="vip price"
                                value={vip_price}
                                onChange={(e: any) => setVip_Price(e.value)}
                            />
                        </InputField>
                        <Label>Duration</Label>
                        <Wrapper>
                            <Row>
                                <Col md={6} lg={6}>
                                    <InputField>
                                        <Select
                                            label="Hour(s)"
                                            options={DurationHours}
                                            name="hour"
                                            value={myHour === '' ? '0h' : parseInt(myHour) + 'h'}
                                            onChange={(val: string) => {
                                                setMyHour(val);
                                            }}
                                        />
                                    </InputField>
                                </Col>
                                <Col md={6} lg={6}>
                                    <InputField>
                                        <Select
                                            label="Minutes"
                                            name="minutes"
                                            options={DurationMinutes}
                                            value={
                                                myMint === '' ? '0min' : parseInt(myMint) + 'min'
                                            }
                                            onChange={(val: string) => {
                                                setMyMint(val);
                                            }}
                                        />
                                    </InputField>
                                </Col>
                            </Row>
                        </Wrapper>
                        <Label>Allow customers to book this service...</Label>
                        <Wrapper>
                            <Row>
                                <Col md={12} lg={12}>
                                    <InputField>
                                        <Select
                                            label=""
                                            options={customerVisibleSlotsOptions}
                                            name="customerVisibleSlots"
                                            value={
                                                customerVisibleSlotsOptions.find(option => {
                                                    return option.value === customerVisibleSlots;
                                                })?.label
                                            }
                                            onChange={(val: number) => {
                                                setCustomerVisibleSlots(val);
                                            }}
                                        />
                                    </InputField>
                                </Col>
                            </Row>
                        </Wrapper>
                    </ModelContent>
                    <ModelButtons>
                        <div></div>
                        <CustomButtons>
                            <Button
                                ifClicked={() => {
                                    Save();
                                }}
                                bgtype={'secondary'}
                                type={'submit'}
                                label={`Save`}></Button>
                        </CustomButtons>
                    </ModelButtons>
                </ModelBody>
            </Model>
        </>
    );
};
const Model = styled(Row)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 1.8rem;
    overflow: auto;
    overscroll-behavior-y: contain;
    z-index: 102;
`;
const ModelBody = styled(Col)`
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 0.6rem;
    box-shadow: 0 1rem 1.8rem -1.4rem rgb(0 0 0 / 10%);
    padding: 1.7rem;
    width: 100%;
    max-width: 50vw;
    ${media.xs`
  width: 100%;
  max-width: 70vw;
`}
    ${media.sm`
    max-width: 60vw;
`}
  ${media.md`
  max-width: 50vw;
`}
    ${media.lg`
    max-width: 30vw;
    `}
`;

const Wrapper = styled(Container)`
    padding: 0;
`;

const CloseButton = styled.div`
    padding: 0;
    line-height: inherit;
    color: inherit;
    margin-right: 1.5rem !important;
    cursor: pointer;
    border: none;
`;
const ModelTitle = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 2rem;
`;
const Icon = styled.i`
    font-size: 2.25rem !important;
    line-height: 2.5rem !important;
`;

const ModelHeading = styled.h1`
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 600;
    ${media.xs`
  font-size: 1.6rem
`}
`;
const ModelContent = styled.div`
    flex: 1 1;
    display: flex;
    flex-direction: column;
`;
const ModelButtons = styled.div`
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const CustomButtons = styled.div`
    display: flex;
    justify-content: space-between;
    ${media.xs`
display: flex !important;
`}
`;

const InputField = styled.div`
    margin-bottom: 1.71rem;
`;

const Label = styled.h4`
    margin: 0rem 0rem 0.5rem 0rem;
`;

export default EditSelectedServicesModel;
