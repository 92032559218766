import styled from 'styled-components';
import moment from 'moment';
import Button from '@components/Button';
const ConflictModal = ({
    dates,
    onContinue,
    onClose,
    recurringConflict,
}: {
    dates: string[];
    onContinue(): void;
    onClose(): void;
    recurringConflict?: any;
}) => {
    const closeModal = (event: any) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    return (
        <Model tabIndex={-1} onClick={closeModal}>
            <ModelBody style={{ width: '480px' }}>
                <ModelHead>
                    <Cover onClick={onClose}>
                        <i className="fas fa-times"></i>
                    </Cover>
                </ModelHead>
                <ModelHeading>
                    <Heading>
                        {recurringConflict == true ? 'Conflict' : 'Resolve Conflict'}{' '}
                    </Heading>
                </ModelHeading>
                <ModelContent>
                    Appointments from:{' '}
                    {recurringConflict == true ? (
                        <div>
                            {dates?.map((date, index) => {
                                return date + (index !== dates?.length - 1 ? ', ' : '');
                            })}
                        </div>
                    ) : (
                        dates?.map((date, index) => {
                            return (
                                moment(date).format('DD MMM') +
                                (index !== dates?.length - 1 ? ', ' : '')
                            );
                        })
                    )}
                    &nbsp;
                    {recurringConflict == true
                        ? ''
                        : `overlaps with other blocks or appoinments. Please modify your day, time or duration to proceed.`}
                </ModelContent>
                <Footer>
                    {/* <Continue onClick={onContinue}>Continue</Continue>
          <Discard onClick={onClose}>Cancel</Discard> */}
                    {/* <Button bgtype={'secondary'} ifClicked={onContinue} label="Continue"></Button> */}
                    <Button bgtype={'secondary'} ifClicked={onClose} label="OK"></Button>
                </Footer>
            </ModelBody>
        </Model>
    );
};
const Model = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 24px;
    overflow: auto;
    overscroll-behavior-y: contain;
    z-index: 102;
`;
const ModelBody = styled.div`
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 15px 25px -6px rgb(0 0 0 / 10%);
    padding: 24px;
    max-width: 82vw;
`;
const ModelHead = styled.div`
    display: flex;
    justify-content: end;
    margin-bottom: 0.75rem;
`;
const Cover = styled.div`
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3f4254;
    background-color: #e4e6ef;
    border-radius: 8px;
    font-size: 1.2rem;
    cursor: pointer;
`;
const ModelHeading = styled.div`
    align-items: center !important;
    display: flex !important;
`;
const Heading = styled.h1`
    font-weight: 600 !important;
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
    margin-bottom: 0px !important;
`;
const ModelContent = styled.div`
    padding: 2rem 0 1rem 0 !important;
    color: rgba(157, 160, 164) !important;
`;
const Footer = styled.div`
    display: flex;
    justify-content: center;
`;

export default ConflictModal;
