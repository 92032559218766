import {
    IGetServiceProvider,
    IGetServiceProviderResponse,
    IServiceProvider,
    IBookingWindow,
} from 'interfaces/team/serviceProvider.interface';
import { Dispatch } from 'redux';
import { serviceProviderService } from 'services';

export const serviceProviderConstants = {
    CREATE_REQUEST: 'SERVICE_PROVIDER_CREATE_REQUEST',
    CREATE_SUCCESS: 'SERVICE_PROVIDER_CREATE_SUCCESS',
    CREATE_FAILURE: 'SERVICE_PROVIDER_CREATE_FAILURE',

    GET_REQUEST: 'SERVICE_PROVIDER_GET_REQUEST',
    GET_SUCCESS: 'SERVICE_PROVIDER_GET_SUCCESS',
    GET_FAILURE: 'SERVICE_PROVIDER_GET_FAILURE',

    SEARCH_REQUEST: 'SERVICE_PROVIDER_SEARCH_REQUEST',
    SEARCH_SUCCESS: 'SERVICE_PROVIDER_SEARCH_SUCCESS',

    UPDATE_REQUEST: 'SERVICE_PROVIDER_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'SERVICE_PROVIDER_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'SERVICE_PROVIDER_UPDATE_FAILURE',

    DELETE_SUCCESS: 'SERVICE_PROVIDER_SUCCESS',
};

const createServiceProvider =
    (serviceProviderData: IServiceProvider) =>
    (dispatch: Dispatch): Promise<IServiceProvider> => {
        dispatch({
            type: serviceProviderConstants.CREATE_REQUEST,
        });

        return serviceProviderService.create(serviceProviderData).then(serviceProvider => {
            dispatch({
                type: serviceProviderConstants.CREATE_SUCCESS,
                serviceProvider,
            });

            return serviceProvider;
        });
    };

const getBusinessServiceProvider =
    (getServiceProviderData: IGetServiceProvider, overwrite = false) =>
    (dispatch: Dispatch): Promise<IGetServiceProviderResponse> => {
        return serviceProviderService
            .getBusinessServiceProvider(getServiceProviderData)
            .then(serviceProvider => {
                if (!overwrite) {
                    dispatch({
                        type: serviceProviderConstants.GET_SUCCESS,
                        serviceProvider,
                    });
                } else {
                    dispatch({
                        type: serviceProviderConstants.SEARCH_SUCCESS,
                        serviceProvider,
                    });
                }

                return serviceProvider;
            });
    };

const searchBusinessServiceProvider =
    (searchData: IGetServiceProvider) =>
    (dispatch: Dispatch): Promise<IGetServiceProviderResponse> => {
        return serviceProviderService
            .searchBusinessServiceProvider(searchData)
            .then(serviceProvider => {
                dispatch({
                    type: serviceProviderConstants.SEARCH_SUCCESS,
                    serviceProvider,
                });
                return serviceProvider;
            });
    };

const getServiceProviderById =
    (serviceProviderId: string) =>
    (dispatch: Dispatch): Promise<IServiceProvider> => {
        return serviceProviderService
            .getServiceProviderById(serviceProviderId)
            .then(serviceProvider => {
                // dispatch({
                //     type: serviceProviderConstants.GET_SUCCESS,
                //     serviceProvider,
                // });

                return serviceProvider;
            });
    };
const updateServiceProviderById =
    (id: string, serviceProviderData: any) =>
    (dispatch: Dispatch): Promise<IServiceProvider> => {
        dispatch({
            type: serviceProviderConstants.UPDATE_REQUEST,
        });

        return serviceProviderService
            .updateServiceProviderById(id, serviceProviderData)
            .then(serviceProvider => {
                dispatch({
                    type: serviceProviderConstants.UPDATE_SUCCESS,
                    serviceProvider,
                });

                return serviceProvider;
            });
    };
const deleteServiceProviderById =
    (id: string) =>
    (dispatch: Dispatch): Promise<IServiceProvider> => {
        return serviceProviderService.deleteServiceProviderById(id).then(serviceProvider => {
            dispatch({
                type: serviceProviderConstants.DELETE_SUCCESS,
                id,
            });

            return serviceProvider;
        });
    };

export const serviceProviderActions = {
    createServiceProvider,
    getBusinessServiceProvider,
    searchBusinessServiceProvider,
    getServiceProviderById,
    updateServiceProviderById,
    deleteServiceProviderById,
};
