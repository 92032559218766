import { useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { publicApi, slotApi } from '../../../helpers/public-axios';
import leftChevron from '../../../images/icons/chevron-left.svg';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { SmallSwitch } from '../../common/Switch';
import InfoModel from './InfoModel';
const today: any = new Date();

// let getMonth: any = parseInt(today.getMonth() + 1);
// let getDay: any = today.getDate();
// if (getMonth < 9) {
//     getMonth = '0' + getMonth;
// }
// if (getDay < 9) {
//     getDay = '0' + getDay;
// }
// const currentDate = today.getFullYear() + '-' + getMonth + '-' + getDay;

const AppointmentCalendar = ({
    onPrevious,
    onSelect,
    serviceProvider,
    serviceId,
    serviceDuration,
    timezone,
}: {
    onPrevious?(): void;
    onSelect(startDate: any, startTime: any, is_vip: boolean): void;
    serviceProvider: any;
    serviceId: string;
    serviceDuration: any;
    timezone: any;
}) => {
    const [selectedSlot, setSelectedSlot] = useState<any>();
    const [morning, setMorning] = useState<any[]>([]);
    const [afterNoon, setAfterNoon] = useState<any[]>([]);
    const [evening, setEvening] = useState<any[]>([]);
    const [selectedDay, setSelectedDay] = useState<any>(today);
    const [selectedMonth, setSelectedMonth] = useState<any>();
    const [disabledDays, setDisabledDays] = useState<any[]>([{ before: today }]); // the days to disable in the calendar
    const [availableSlots, setAvailableSlots] = useState<any[]>([]);
    const [vipAppointment, setVipAppointment] = useState<any>(false);
    const [infoModel, setInfoModel] = useState<boolean>(false);
    const [response, setResponse] = useState('');
    useEffect(() => {
        if (timezone) {
            moment.tz.setDefault(timezone);
        }
    });
    let currentFullDate = moment().format();

    let currentTime = moment(currentFullDate).format('HH:mm');
    const currentDate = moment(currentFullDate).format('YYYY-MM-DD');

    const getAvailableSlot = (date: any) => {
        if (
            serviceProvider?._id !== undefined &&
            selectedDay !== undefined &&
            vipAppointment === false
        ) {
            const time = moment().format('HH:mm:ssZ');
            let selectedDate: any = date + 'T' + time;
            slotApi
                .post(`appointments/customer_available_slots/${serviceProvider?._id}`, {
                    date: selectedDate,
                    duration: serviceDuration,
                    serviceId: serviceId,
                })
                .then((res: any) => {
                    if (res.data !== undefined) {
                        setMorning([]);
                        setAfterNoon([]);
                        setEvening([]);
                        setSelectedSlot('');
                        setResponse('');
                        setAvailableSlots(res.data);
                        res.data.map((time: any, index: any) => {
                            if (currentDate == date) {
                                if (time >= currentTime) {
                                    slotList(time);
                                }
                            } else {
                                slotList(time);
                            }
                        });
                    }
                })
                .catch((e: any) => {
                    setMorning([]);
                    setAfterNoon([]);
                    setEvening([]);
                    setSelectedSlot('');
                    setResponse(e?.message);
                });
        }
    };
    const getVipAvailableSlot = (date: any) => {
        if (
            serviceProvider?._id !== undefined &&
            selectedDay !== undefined &&
            vipAppointment === true
        ) {
            const time = moment().format('HH:mm:ssZ');
            let selectedDate: any = date + 'T' + time;
            slotApi
                .post(`/appointments/customer_vip_available_slots/${serviceProvider?._id}`, {
                    date: selectedDate,
                    duration: serviceDuration,
                    serviceId: serviceId,
                })
                .then((res: any) => {
                    if (res.data !== undefined) {
                        setMorning([]);
                        setAfterNoon([]);
                        setEvening([]);
                        setSelectedSlot('');
                        setResponse('');
                        setAvailableSlots(res.data);
                        res.data.map((time: any, index: any) => {
                            if (currentDate == date) {
                                if (time >= currentTime) {
                                    slotList(time);
                                }
                            } else {
                                slotList(time);
                            }
                        });
                    }
                })
                .catch((e: any) => {
                    setMorning([]);
                    setAfterNoon([]);
                    setEvening([]);
                    setSelectedSlot('');
                    setResponse(e?.message);
                });
        }
    };

    const getUnavailableDates = (month: Date) => {
        if (month === undefined) {
            return;
        }
        // get the date range we want to query (the dates in the month that are not in the past)
        // if selected month is the current month, the range starts at today
        // else if the month/year is after today the range starts at the first of the month
        // else, calendar is in the past, do nothing
        let startDate: any = null;
        // get last day of the selected month
        let lastDay = new Date(month.getFullYear(), month.getMonth() + 1, 0);
        let endDayStr = (lastDay.getDate() < 10 ? '0' : '') + `${lastDay.getDate()}`;
        let monthStr = (lastDay.getMonth() + 1 < 10 ? '0' : '') + `${lastDay.getMonth() + 1}`;
        let endDate = `${lastDay.getFullYear()}-${monthStr}-${endDayStr}`;

        if (month.getMonth() === today.getMonth() && month.getFullYear() === today.getFullYear()) {
            let startDayStr = (today.getDate() < 10 ? '0' : '') + `${today.getDate()}`;
            startDate = `${month.getFullYear()}-${monthStr}-${startDayStr}`;
        } else if (month > today) {
            // month is in the future
            startDate = `${month.getFullYear()}-${monthStr}-01`;
        } else {
            // month is in the past
            return; // do nothing
        }

        slotApi
            .get(
                `/appointments/customer${vipAppointment ? '_vip' : ''}_unavailable_dates/${
                    serviceProvider?._id
                }?startDate=${startDate}&endDate=${endDate}&duration=${serviceDuration}&serviceId=${serviceId}`
            )
            .then((res: any) => {
                if (res.data === undefined) return;

                // convert each of the returned unavailableDates from strings to Dates
                let unavailableDates = res.data.map((unavailableDate: string) =>
                    moment(unavailableDate, 'YYYY-MM-DD').tz(timezone).toDate()
                );
                // add default disabled day option
                unavailableDates.push({ before: today });
                setDisabledDays(unavailableDates);
                // iterate over dates that are available and find the first one
                let d = moment(startDate, 'YYYY-MM-DD').tz(timezone);
                let end = moment(endDate, 'YYYY-MM-DD').tz(timezone);
                while (d <= end) {
                    // if date is available (isn't in list of "unavailable" dates), set it and break out of loop
                    if (!res.data.includes(d.format('YYYY-MM-DD'))) {
                        setSelectedDay(d.toDate());
                        break;
                    }
                    d.add(1, 'days');
                }
            })
            .catch((err: any) => {
                setResponse(err?.message);
            });
    };

    const slotList = (time: any) => {
        if (time < '12:00') {
            setMorning((prevState: any) => {
                return [...prevState, time];
            });
        } else if (time >= '12:00' && time <= '17:00') {
            setAfterNoon((prevState: any) => {
                return [...prevState, time];
            });
        } else if (time > '17:00') {
            setEvening((prevState: any) => {
                return [...prevState, time];
            });
        }
    };
    let selct: any = new Date(selectedDay);
    let month: any = parseInt(selct.getMonth() + 1);
    let day: any = selct.getDate();
    if (month < 10) {
        month = '0' + month;
    }
    if (day < 10) {
        day = '0' + day;
    }
    let mydate = selct.getFullYear() + '-' + month + '-' + day;
    const time = moment().format('HH:mm:ssZ');
    let selectedDate: any = selectedDay ? mydate + 'T' + time : null;
    useEffect(() => {
        getAvailableSlot(mydate);
        getVipAvailableSlot(mydate);
    }, [selectedDay, vipAppointment]);
    useEffect(() => {
        getUnavailableDates(selectedMonth || moment(mydate, 'YYYY-MM-DD').tz(timezone).toDate());
    }, [selectedMonth, vipAppointment]);

    const dayPickerCustomCss = `
        .rdp-button:not([disabled]) {
            font-weight: bold;
        }
    `;

    return (
        <>
            <style>{dayPickerCustomCss}</style>
            <div className="booking-wrapper">
                <div className="boking-widget--header">
                    <div className="booking-widget--backBtn" onClick={onPrevious}>
                        <img src={leftChevron} alt="" />
                    </div>
                    <h3>Select Date and Time</h3>
                </div>
                <div className="booking-widget--body">
                    <Flex className="mini-calendar">
                        <DayPicker
                            mode="single"
                            selected={selectedDay}
                            disabled={disabledDays}
                            onDayClick={day => setSelectedDay(day)}
                            onMonthChange={setSelectedMonth}
                        />
                        <div>
                            {serviceProvider?.vip == true && (
                                <VipAppointment>
                                    <CustomSpan>View VIP Times</CustomSpan>
                                    <SmallSwitch
                                        value={vipAppointment}
                                        onChange={(val: any) => {
                                            setVipAppointment(val);
                                        }}
                                    />{' '}
                                    <Info
                                        className="fa fas fa-info-circle"
                                        onClick={() => setInfoModel(true)}></Info>
                                </VipAppointment>
                            )}
                            {selectedDate !== null && (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                        backgroundColor: '#000',
                                        color: '#fff',
                                        fontWeight: 'bold',
                                        fontSize: '13px',
                                        marginTop: '3px',
                                    }}>
                                    {moment(selectedDate).format('ddd, MMM. DD, YYYY')}
                                </div>
                            )}
                            <div className="col-3" onClick={() => setInfoModel(false)}>
                                <div>
                                    <Text>Morning</Text>
                                </div>
                                <div>
                                    <Text>Afternoon</Text>
                                </div>
                                <div>
                                    <Text>Evening</Text>
                                </div>
                            </div>
                            <div className="col-3">
                                <div>
                                    {morning.map((x, i) => (
                                        <div
                                            className={`timeSlot ${
                                                selectedSlot === x ? 'selectedSlot' : ''
                                            }`}
                                            key={i}
                                            onClick={() => setSelectedSlot(x)}>
                                            {moment(
                                                moment().format() + ' ' + x,
                                                'YYYY-MM-DD HH:mm:ss'
                                            ).format('hh:mm a')}
                                        </div>
                                    ))}
                                </div>
                                <div>
                                    {afterNoon.map((x, i) => (
                                        <div
                                            className={`timeSlot ${
                                                selectedSlot === x ? 'selectedSlot' : ''
                                            }`}
                                            key={i}
                                            onClick={() => setSelectedSlot(x)}>
                                            {moment(
                                                moment().format() + ' ' + x,
                                                'YYYY-MM-DD HH:mm:ss'
                                            ).format('hh:mm a')}
                                        </div>
                                    ))}
                                </div>
                                <div>
                                    {evening.map((x, i) => (
                                        <div
                                            className={`timeSlot ${
                                                selectedSlot === x ? 'selectedSlot' : ''
                                            }`}
                                            key={i}
                                            onClick={() => setSelectedSlot(x)}>
                                            {moment(
                                                moment().format() + ' ' + x,
                                                'YYYY-MM-DD HH:mm:ss'
                                            ).format('hh:mm a')}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-12">
                                {selectedDay ? (
                                    morning.length || afterNoon.length || evening.length ? (
                                        ''
                                    ) : (
                                        <div className="date-empty-text">
                                            {' '}
                                            <span className="text">
                                                {response !== ''
                                                    ? response
                                                    : 'No slots available for the date you selected.'}
                                            </span>
                                        </div>
                                    )
                                ) : (
                                    <div className="date-empty-text">
                                        {' '}
                                        <span className="text">Please select a date.</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Flex>
                </div>
                {selectedSlot ? (
                    <div
                        className="booking-widget--footer"
                        onClick={() => onSelect(selectedDay, selectedSlot, vipAppointment)}
                        style={{
                            position: 'fixed',
                            flexDirection: 'column',
                            height: '7rem',
                        }}>
                        <button className="btn">Next</button>
                    </div>
                ) : (
                    <div
                        className="booking-widget--footer-disabled"
                        style={{
                            position: 'fixed',
                            flexDirection: 'column',
                            height: '7rem',
                        }}>
                        <button className="btn">Next</button>
                    </div>
                )}
            </div>
            {infoModel === true && <InfoModel onClose={() => setInfoModel(false)} />}
        </>
    );
};
const Flex = styled.div`
    display: flex;
    padding-bottom: 1rem;
`;
const CustomSpan = styled.span`
    font-size: 1rem;
`;
const Text = styled.p`
    text-align: center;
`;

const VipAppointment = styled.div`
    margin-top: 1rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
`;
const Info = styled.i`
    font-size: 1.5rem;
    cursor: pointer;
    margin-left: 0.2rem;
`;
export default AppointmentCalendar;
