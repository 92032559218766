import { Prism as Highlighter } from 'react-syntax-highlighter';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { vsDark, vscDarkPlus } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import { useSnackbar } from '../Snackbar';
import './SyntaxHighlighter.scss';
import React from 'react';

const SyntaxHighlighter = ({ children, language }: { children?: any; language: string }) => {
    const [openSnackbar] = useSnackbar();

    return (
        <div className="syntaxHighlighter--container">
            <div className="syntaxHighlighter--lang">javascript</div>
            <Highlighter
                language={language}
                style={vscDarkPlus}
                className="syntaxHighlighter"
                wrapLongLines={true}
                customStyle={{
                    paddingTop: '26px',
                }}>
                {children ?? ''}
            </Highlighter>
            <CopyToClipboard
                text={children}
                onCopy={() => {
                    openSnackbar('Copied to clipboard');
                }}>
                <button className="syntaxHighlighter--copy">Copy</button>
            </CopyToClipboard>
        </div>
    );
};

export default SyntaxHighlighter;
