import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { IServiceProvider } from 'interfaces/team/serviceProvider.interface';
import _ from 'lodash';
import SearchField from '@components/common/Search/SearchField';
import { LoaderOverlay, useSnackbar } from '@components/common';
import { api } from 'helpers/auth-axios';
import Button from '@components/Button';
const SelectServiceProvider = ({
    onClose,
    onSelect,
}: {
    onClose(): void;
    onSelect(serviceProvider: IServiceProvider): void;
}) => {
    const [pageNumber, setPageNumber] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [query, setQuery] = useState<any>(null);
    const [value, setValue] = useState('');
    const [initial, setInitial] = useState(false); // used to check whether data is initially on store
    const observer = useRef<IntersectionObserver>();
    const [serviceProvider, setServiceProvider] = useState<any>();
    const [serviceProviderData, setServiceProviderData] = useState<any>([]);
    // const serviceProviderData = useAppSelector(state => state.serviceProvider);
    const [openSnackbar] = useSnackbar();
    const pageSize = 20;
    const [total, setTotal] = useState(0);
    const loaderRef = useCallback(
        (node: any) => {
            if (loading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(entries => {
                // if (entries[0].isIntersecting && hasMore) {
                //     setPageNumber(prevPageNumber => prevPageNumber + 1);
                // }
            });
            if (node) observer.current.observe(node);
        },
        [loading, hasMore]
    );
    const getAllServiceProvider = () => {
        setLoading(true);
        api.get(`/serviceProvider?page=${pageNumber}&pageSize=${pageSize}&active=true`)
            .then((serviceProvider: any) => {
                setServiceProvider(serviceProvider.data);
                setServiceProviderData(serviceProvider.data.serviceProvider);
                setHasMore(serviceProvider.data.serviceProvider.length > 0);
                setLoading(false);
                setTotal(serviceProvider?.data?.total);
            })
            .catch((e: any) => {
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };
    const loadServiceProvider = () => {
        setLoading(true);
        api.get(`/serviceProvider?page=${pageNumber}&pageSize=${pageSize}&active=true`).then(
            (res: any) => {
                setServiceProviderData((prev: any) => [...prev, ...res.data.serviceProvider]);
                setServiceProvider(res.data);
                setTotal(res.data.total);
                setHasMore(res.data.serviceProvider.length > 0);
                setLoading(false);
            }
        );
    };
    useEffect(() => {
        if (pageNumber > 1) {
            loadServiceProvider();
        }
    }, [pageNumber]);
    const searchServiceProvider = (query: string) => {
        let value = query;
        if (Number.isInteger(parseInt(value)) === true) {
            if (value.length <= 3) {
                value = value.replace(/[- .]/g, '');
                // value = value + '-';
            } else if (value.length <= 7) {
                value = value.replace(/[- .]/g, '');
                value = value.slice(0, 3) + '-' + value.slice(3, 6);
            } else if (value.length >= 7) {
                value = value.replace(/[- .]/g, '');
                value = value.slice(0, 3) + '-' + value.slice(3, 6) + '-' + value.slice(6);
            }
            setLoading(true);
            api.get(`/serviceProvider?page=${1}&pageSize=${pageSize}&query=${value}&active=true`)
                .then((serviceProvider: any) => {
                    setLoading(false);
                    setServiceProvider(serviceProvider.data);
                    setServiceProviderData(serviceProvider.data.serviceProvider);

                    setHasMore(serviceProvider.data.serviceProvider.length > 0);
                })
                .catch((e: any) => {
                    if (e?.response) {
                        openSnackbar(e?.response?.data?.message);
                    }
                });
        } else {
            setLoading(true);
            api.get(
                `/serviceProvider?page=${pageNumber}&pageSize=${pageSize}&query=${value}&active=true`
            )
                .then((serviceProvider: any) => {
                    setLoading(false);
                    setServiceProvider(serviceProvider.data);
                    setServiceProviderData(serviceProvider.data.serviceProvider);

                    setHasMore(serviceProvider.data.serviceProvider.length > 0);
                })
                .catch((e: any) => {
                    if (e?.response) {
                        openSnackbar(e?.response?.data?.message);
                    }
                });
        }
    };
    useEffect(() => {
        if (serviceProvider !== undefined && serviceProvider?.total !== 0) {
            setPageNumber(serviceProvider?.page);
            setValue(serviceProvider?.query ?? null);
            setQuery(serviceProvider?.query ?? null);
            setHasMore(true);
            setInitial(true);
            return;
        }
        getAllServiceProvider();
    }, []);
    const searchServiceProviderDebounce = useMemo(() => {
        return _.debounce((searchVal: string) => {
            setHasMore(true);
            setQuery(searchVal);
            setPageNumber(1);
            searchServiceProvider(searchVal);
        }, 300);
    }, []);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        searchServiceProviderDebounce(event.target.value);
    };

    const select = (serviceProvider: IServiceProvider) => {
        onSelect(serviceProvider);
        onClose();
    };
    const loadMore = () => {
        setPageNumber((prevPage: any) => prevPage + 1);
    };
    return (
        <>
            {loading && <LoaderOverlay />}
            <Wrapper>
                <CustomRow>
                    <CustomCol md={12} lg={12}>
                        <HeadingWrapper>
                            <CloseButton onClick={onClose}>
                                <i className="fal fa-long-arrow-left"></i>
                            </CloseButton>
                            <Header>Select Service Provider</Header>
                        </HeadingWrapper>

                        <SearchField
                            value={value}
                            handleChange={handleChange}
                            name={`Service Provider`}
                        />

                        <ProvidersList>
                            {serviceProviderData.length ? (
                                serviceProviderData.map(
                                    (serviceProvider: IServiceProvider, i: any) => {
                                        return (
                                            <li key={i} onClick={() => select(serviceProvider)}>
                                                <SearchItems>
                                                    <SearchItemWrapper>
                                                        <Circle>
                                                            <NameCircle>
                                                                {serviceProvider.name
                                                                    ? serviceProvider.name.charAt(0)
                                                                    : ''}
                                                            </NameCircle>
                                                        </Circle>
                                                        <SearchItemName>
                                                            {serviceProvider.name}
                                                        </SearchItemName>
                                                    </SearchItemWrapper>
                                                </SearchItems>
                                            </li>
                                        );
                                    }
                                )
                            ) : (
                                <NotFound>No serviceProvider found</NotFound>
                            )}
                            <div ref={loaderRef}></div>
                        </ProvidersList>
                        {query
                            ? ''
                            : (total >
                                  (pageNumber === 1 ? pageNumber + 0 : pageNumber + 1) * pageSize ||
                                  loading) && (
                                  <LoadMore>
                                      <Button
                                          bgtype={'secondary'}
                                          ifClicked={loadMore}
                                          disabled={loading}
                                          //   width="12rem !important"
                                          label={`Load more serviceProvider (${
                                              total -
                                              (pageNumber === 1 ? pageNumber + 0 : pageNumber + 1) *
                                                  pageSize
                                          })`}></Button>
                                  </LoadMore>
                              )}
                        <Col></Col>
                    </CustomCol>
                </CustomRow>
            </Wrapper>
        </>
    );
};
const Wrapper = styled(Container)`
    margin-left: 0rem;
    padding: 0;
    background-color: white;
`;
const CustomRow = styled(Row)`
    position: relative;
    margin: 0;
`;
const CustomCol = styled(Col)`
    padding: 0;
`;
const Header = styled.h1`
    font-size: 1.78rem;
    font-weight: 600;
`;
const HeadingWrapper = styled.div`
    padding: 1.42rem 0rem 0.85rem 1.71rem;
    display: flex;
    align-items: center;
    justify-content: start;
`;
const SearchItems = styled.div`
    cursor: pointer;
    position: relative;
    margin: 0px;
    padding: 0px;
    &:hover {
        background-color: #f4f5f6;
    }
`;
const SearchItemWrapper = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e5e7eb;
    padding: 12px 0;
`;
const SearchItemName = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 12px;
    flex: 1;
    font-size: 14px;
`;
const Circle = styled.div`
    display: inline-block;
    flex-shrink: 0;
    position: relative;
    border-radius: 0.42rem;
`;

const NameCircle = styled.div`
    background: linear-gradient(-135deg, #eee, #dddfe1);
    color: #7e8299;
    width: 3.14rem;
    height: 3.14rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 0.42rem;
    font-size: 1.21rem;
    text-transform: capitalize !important;
    border-radius: 50%;
`;
const CloseButton = styled.div`
    margin-right: 1.25rem /* 20px */;
    font-size: 1.875rem /* 30px */;
    line-height: 2.25rem /* 36px */;
    cursor: pointer;
`;
const ProvidersList = styled.ul`
    //margin-top: 1rem;
    height: 30.6rem;
    overflow: auto;
    list-style: none;
    padding-left: 2rem;
    margin-bottom: 0.5rem;
`;
const LoadMore = styled.div`
    margin-top: 0.5rem;
    display: flex;
    justify-content: center;
`;
const NotFound = styled.div`
    display: flex;
    justify-content: center;
`;
export default SelectServiceProvider;
