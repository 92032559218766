import React, { useEffect, useState } from 'react';
import { Col, Container, media, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { Switch, Select, useSnackbar, LoaderOverlay } from '@components/common';
import './BusinessHours.scss';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useForm } from 'react-hook-form';

import Button from '@components/Button';
import { IBusiness } from 'interfaces/business.interface';
import { palette } from 'styled/common';
import { calculateHoursDifference } from '@components/common/utils';
import { api } from 'helpers/auth-axios';
import moment from 'moment';
interface IProps {
    active: boolean;
    onChange: (day: any) => void;
}

const BusinessHours = ({ onChange }: IProps) => {
    const [dayHours, setDayHours] = useState<any>();
    const [mo_Diff, setMonday] = useState();
    const [tu_Diff, setTeusday] = useState();
    const [we_Diff, setWednesday] = useState();
    const [th_Diff, setThursday] = useState();
    const [fr_Diff, setFriday] = useState();
    const [sa_Diff, setSaturday] = useState();
    const [su_Diff, setSunday] = useState();
    const [loading, setLoading] = useState(false);
    const [openSnackbar] = useSnackbar();
    const { handleSubmit, formState } = useForm();
    const { isSubmitting } = formState;

    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    let businessHours: { label: string; value: any }[] = [];
    for (let i: any = 0; i < 24; i++) {
        for (let j = 0; j < 4; j++) {
            businessHours.push({
                label: moment({
                    hour: i,
                    minute: j * 15,
                }).format(_userData.user.business_id.time_select === '12' ? 'hh:mm a' : 'HH:mm'),
                value: moment({ hour: i, minute: j * 15 }).format('HH:mm'),
            });
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getWorkingHours = async () => {
        const monday: any = await calculateHoursDifference(dayHours?.mo_from, dayHours?.mo_to);
        setMonday(monday);
        const teusday: any = await calculateHoursDifference(dayHours?.tu_from, dayHours?.tu_to);
        setTeusday(teusday);
        const wednesday: any = await calculateHoursDifference(dayHours?.we_from, dayHours?.we_to);
        setWednesday(wednesday);
        const thursday: any = await calculateHoursDifference(dayHours?.th_from, dayHours?.th_to);
        setThursday(thursday);
        const friday: any = await calculateHoursDifference(dayHours?.fr_from, dayHours?.fr_to);
        setFriday(friday);
        const saturday: any = await calculateHoursDifference(dayHours?.sa_from, dayHours?.sa_to);
        setSaturday(saturday);
        const sunday: any = await calculateHoursDifference(dayHours?.su_from, dayHours?.su_to);
        setSunday(sunday);
    };
    const onSubmit = () => {
        setLoading(true);
        let id: any = dayHours?._id;
        let openHours: any = {
            mo_from: dayHours?.mo_from,
            mo_to: dayHours?.mo_to,
            tu_from: dayHours?.tu_from,
            tu_to: dayHours?.tu_to,
            we_from: dayHours?.we_from,
            we_to: dayHours?.we_to,
            th_from: dayHours?.th_from,
            th_to: dayHours?.th_to,
            fr_from: dayHours?.fr_from,
            fr_to: dayHours?.fr_to,
            sa_from: dayHours?.sa_from,
            sa_to: dayHours?.sa_to,
            su_from: dayHours?.su_from,
            su_to: dayHours?.su_to,
            mo_on: dayHours?.mo_on,
            tu_on: dayHours?.tu_on,
            we_on: dayHours?.we_on,
            th_on: dayHours?.th_on,
            fr_on: dayHours?.fr_on,
            sa_on: dayHours?.sa_on,
            su_on: dayHours?.su_on,
        };
        api.put(`/workinghours/${id}`, openHours)
            .then((res: any) => {
                setDayHours(res.data);
                setLoading(false);
                openSnackbar('Updated successfully!');
            })
            .catch(() => {
                setLoading(false);
                openSnackbar('Failed to update!');
            });
    };
    const workingHoursData = () => {
        if (_userData.user.business_id !== undefined) {
            const id = _userData.user.business_id._id;
            setLoading(true);
            api.get(`/businesses/${id}`)
                .then((res: any) => {
                    if (res.data) {
                        setLoading(false);

                        setDayHours(res.data.open_hours);
                    }
                })
                .catch((e: any) => {
                    setLoading(false);
                    if (e?.response) {
                        openSnackbar(e?.response?.data?.message);
                    }
                });
        }
    };
    useEffect(() => {
        return onChange(dayHours);
    }, [dayHours, onChange]);
    useEffect(() => {
        workingHoursData();
    }, []);
    useEffect(() => {
        getWorkingHours();
    }, [getWorkingHours]);

    return (
        <>
            {' '}
            <Wrapper>
                <Row>
                    <CustomCol xs={12} sm={12} md={10} lg={8}>
                        {loading && <LoaderOverlay />}
                        <Card>
                            {dayHours && (
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div
                                        className={`hours-item ${
                                            !dayHours?.mo_on ? 'hours-item--disabled' : ''
                                        }`}>
                                        <List>
                                            <WeekContent>
                                                <Switch
                                                    value={dayHours?.mo_on}
                                                    onChange={(val: boolean) => {
                                                        setDayHours((prevState: any) => ({
                                                            ...prevState,
                                                            mo_on: val,
                                                        }));
                                                    }}
                                                />
                                                <WeekName>Monday</WeekName>
                                            </WeekContent>

                                            <HourContent className="hours-item--details">
                                                <Hour>
                                                    <Select
                                                        options={businessHours}
                                                        value={
                                                            dayHours?.mo_from
                                                                ? moment(
                                                                      moment().format() +
                                                                          ' ' +
                                                                          dayHours?.mo_from,
                                                                      'YYYY-MM-DD HH:mm:ss'
                                                                  ).format(
                                                                      _userData.user.business_id
                                                                          .time_select === '12'
                                                                          ? 'hh:mm a'
                                                                          : 'HH:mm'
                                                                  )
                                                                : '09:00'
                                                        }
                                                        onChange={(val: string) => {
                                                            setDayHours((prevState: any) => ({
                                                                ...prevState,
                                                                mo_from: val,
                                                            }));
                                                        }}
                                                    />
                                                </Hour>
                                                <Hour>
                                                    <Select
                                                        options={businessHours}
                                                        value={
                                                            dayHours?.mo_to
                                                                ? moment(
                                                                      moment().format() +
                                                                          ' ' +
                                                                          dayHours?.mo_to,
                                                                      'YYYY-MM-DD HH:mm:ss'
                                                                  ).format(
                                                                      _userData.user.business_id
                                                                          .time_select === '12'
                                                                          ? 'hh:mm a'
                                                                          : 'HH:mm'
                                                                  )
                                                                : '06:00'
                                                        }
                                                        onChange={(val: string) => {
                                                            setDayHours((prevState: any) => ({
                                                                ...prevState,
                                                                mo_to: val,
                                                            }));
                                                        }}
                                                    />
                                                </Hour>
                                                <Time>
                                                    <Icon className="fal fa-clock"></Icon>
                                                    {mo_Diff}h
                                                </Time>
                                            </HourContent>
                                        </List>
                                    </div>
                                    <div
                                        className={`hours-item ${
                                            !dayHours?.tu_on ? 'hours-item--disabled' : ''
                                        }`}>
                                        <List>
                                            <WeekContent>
                                                <Switch
                                                    value={dayHours?.tu_on}
                                                    onChange={(val: boolean) => {
                                                        setDayHours((prevState: any) => ({
                                                            ...prevState,
                                                            tu_on: val,
                                                        }));
                                                    }}
                                                />
                                                <WeekName>Tuesday</WeekName>
                                            </WeekContent>

                                            <HourContent className="hours-item--details">
                                                <Hour>
                                                    <Select
                                                        options={businessHours}
                                                        value={
                                                            dayHours?.tu_from
                                                                ? moment(
                                                                      moment().format() +
                                                                          ' ' +
                                                                          dayHours?.tu_from,
                                                                      'YYYY-MM-DD HH:mm:ss'
                                                                  ).format(
                                                                      _userData.user.business_id
                                                                          .time_select === '12'
                                                                          ? 'hh:mm a'
                                                                          : 'HH:mm'
                                                                  )
                                                                : '09:00'
                                                        }
                                                        onChange={(val: string) => {
                                                            setDayHours((prevState: any) => ({
                                                                ...prevState,
                                                                tu_from: val,
                                                            }));
                                                        }}
                                                    />
                                                </Hour>
                                                <Hour>
                                                    <Select
                                                        options={businessHours}
                                                        value={
                                                            dayHours?.tu_to
                                                                ? moment(
                                                                      moment().format() +
                                                                          ' ' +
                                                                          dayHours?.tu_to,
                                                                      'YYYY-MM-DD HH:mm:ss'
                                                                  ).format(
                                                                      _userData.user.business_id
                                                                          .time_select === '12'
                                                                          ? 'hh:mm a'
                                                                          : 'HH:mm'
                                                                  )
                                                                : '06:00'
                                                        }
                                                        onChange={(val: string) => {
                                                            setDayHours((prevState: any) => ({
                                                                ...prevState,
                                                                tu_to: val,
                                                            }));
                                                        }}
                                                    />
                                                </Hour>
                                                <Time>
                                                    <Icon className="fal fa-clock"></Icon>
                                                    {tu_Diff}h
                                                </Time>
                                            </HourContent>
                                        </List>
                                    </div>
                                    <div
                                        className={`hours-item ${
                                            !dayHours?.we_on ? 'hours-item--disabled' : ''
                                        }`}>
                                        <List>
                                            <WeekContent>
                                                <Switch
                                                    value={dayHours?.we_on}
                                                    onChange={(val: boolean) => {
                                                        setDayHours((prevState: any) => ({
                                                            ...prevState,
                                                            we_on: val,
                                                        }));
                                                    }}
                                                />
                                                <WeekName>Wednesday</WeekName>
                                            </WeekContent>

                                            <HourContent className="hours-item--details">
                                                <Hour>
                                                    <Select
                                                        options={businessHours}
                                                        value={
                                                            dayHours?.we_from
                                                                ? moment(
                                                                      moment().format() +
                                                                          ' ' +
                                                                          dayHours?.we_from,
                                                                      'YYYY-MM-DD HH:mm:ss'
                                                                  ).format(
                                                                      _userData.user.business_id
                                                                          .time_select === '12'
                                                                          ? 'hh:mm a'
                                                                          : 'HH:mm'
                                                                  )
                                                                : '09:00'
                                                        }
                                                        onChange={(val: string) => {
                                                            setDayHours((prevState: any) => ({
                                                                ...prevState,
                                                                we_from: val,
                                                            }));
                                                        }}
                                                    />
                                                </Hour>
                                                <Hour>
                                                    <Select
                                                        options={businessHours}
                                                        value={
                                                            dayHours?.we_to
                                                                ? moment(
                                                                      moment().format() +
                                                                          ' ' +
                                                                          dayHours?.we_to,
                                                                      'YYYY-MM-DD HH:mm:ss'
                                                                  ).format(
                                                                      _userData.user.business_id
                                                                          .time_select === '12'
                                                                          ? 'hh:mm a'
                                                                          : 'HH:mm'
                                                                  )
                                                                : '06:00'
                                                        }
                                                        onChange={(val: string) => {
                                                            setDayHours((prevState: any) => ({
                                                                ...prevState,
                                                                we_to: val,
                                                            }));
                                                        }}
                                                    />
                                                </Hour>
                                                <Time>
                                                    <Icon className="fal fa-clock"></Icon>
                                                    {we_Diff}h
                                                </Time>
                                            </HourContent>
                                        </List>
                                    </div>
                                    <div
                                        className={`hours-item ${
                                            !dayHours?.th_on ? 'hours-item--disabled' : ''
                                        }`}>
                                        <List>
                                            <WeekContent>
                                                <Switch
                                                    value={dayHours?.th_on}
                                                    onChange={(val: boolean) => {
                                                        setDayHours((prevState: any) => ({
                                                            ...prevState,
                                                            th_on: val,
                                                        }));
                                                    }}
                                                />
                                                <WeekName>Thursday</WeekName>
                                            </WeekContent>

                                            <HourContent className="hours-item--details">
                                                <Hour>
                                                    <Select
                                                        options={businessHours}
                                                        value={
                                                            dayHours?.th_from
                                                                ? moment(
                                                                      moment().format() +
                                                                          ' ' +
                                                                          dayHours?.th_from,
                                                                      'YYYY-MM-DD HH:mm:ss'
                                                                  ).format(
                                                                      _userData.user.business_id
                                                                          .time_select === '12'
                                                                          ? 'hh:mm a'
                                                                          : 'HH:mm'
                                                                  )
                                                                : '09:00'
                                                        }
                                                        onChange={(val: string) => {
                                                            setDayHours((prevState: any) => ({
                                                                ...prevState,
                                                                th_from: val,
                                                            }));
                                                        }}
                                                    />
                                                </Hour>
                                                <Hour>
                                                    <Select
                                                        options={businessHours}
                                                        value={
                                                            dayHours?.th_to
                                                                ? moment(
                                                                      moment().format() +
                                                                          ' ' +
                                                                          dayHours?.th_to,
                                                                      'YYYY-MM-DD HH:mm:ss'
                                                                  ).format(
                                                                      _userData.user.business_id
                                                                          .time_select === '12'
                                                                          ? 'hh:mm a'
                                                                          : 'HH:mm'
                                                                  )
                                                                : '06:00'
                                                        }
                                                        onChange={(val: string) => {
                                                            setDayHours((prevState: any) => ({
                                                                ...prevState,
                                                                th_to: val,
                                                            }));
                                                        }}
                                                    />
                                                </Hour>
                                                <Time>
                                                    <Icon className="fal fa-clock"></Icon>
                                                    {th_Diff}h
                                                </Time>
                                            </HourContent>
                                        </List>
                                    </div>
                                    <div
                                        className={`hours-item ${
                                            !dayHours?.fr_on ? 'hours-item--disabled' : ''
                                        }`}>
                                        <List>
                                            <WeekContent>
                                                <Switch
                                                    value={dayHours?.fr_on}
                                                    onChange={(val: boolean) => {
                                                        setDayHours((prevState: any) => ({
                                                            ...prevState,
                                                            fr_on: val,
                                                        }));
                                                    }}
                                                />
                                                <WeekName>Friday</WeekName>
                                            </WeekContent>

                                            <HourContent className="hours-item--details">
                                                <Hour>
                                                    <Select
                                                        options={businessHours}
                                                        value={
                                                            dayHours?.fr_from
                                                                ? moment(
                                                                      moment().format() +
                                                                          ' ' +
                                                                          dayHours?.fr_from,
                                                                      'YYYY-MM-DD HH:mm:ss'
                                                                  ).format(
                                                                      _userData.user.business_id
                                                                          .time_select === '12'
                                                                          ? 'hh:mm a'
                                                                          : 'HH:mm'
                                                                  )
                                                                : '09:00'
                                                        }
                                                        onChange={(val: string) => {
                                                            setDayHours((prevState: any) => ({
                                                                ...prevState,
                                                                fr_from: val,
                                                            }));
                                                        }}
                                                    />
                                                </Hour>
                                                <Hour>
                                                    <Select
                                                        options={businessHours}
                                                        value={
                                                            dayHours?.fr_to
                                                                ? moment(
                                                                      moment().format() +
                                                                          ' ' +
                                                                          dayHours?.fr_to,
                                                                      'YYYY-MM-DD HH:mm:ss'
                                                                  ).format(
                                                                      _userData.user.business_id
                                                                          .time_select === '12'
                                                                          ? 'hh:mm a'
                                                                          : 'HH:mm'
                                                                  )
                                                                : '06:00'
                                                        }
                                                        onChange={(val: string) => {
                                                            setDayHours((prevState: any) => ({
                                                                ...prevState,
                                                                fr_to: val,
                                                            }));
                                                        }}
                                                    />
                                                </Hour>
                                                <Time>
                                                    <Icon className="fal fa-clock"></Icon>
                                                    {fr_Diff}h
                                                </Time>
                                            </HourContent>
                                        </List>
                                    </div>
                                    <div
                                        className={`hours-item ${
                                            !dayHours?.sa_on ? 'hours-item--disabled' : ''
                                        }`}>
                                        <List>
                                            <WeekContent>
                                                <Switch
                                                    value={dayHours?.sa_on}
                                                    onChange={(val: boolean) => {
                                                        setDayHours((prevState: any) => ({
                                                            ...prevState,
                                                            sa_on: val,
                                                        }));
                                                    }}
                                                />
                                                <WeekName>Saturday</WeekName>
                                            </WeekContent>

                                            <HourContent className="hours-item--details">
                                                <Hour>
                                                    <Select
                                                        options={businessHours}
                                                        value={
                                                            dayHours?.sa_from
                                                                ? moment(
                                                                      moment().format() +
                                                                          ' ' +
                                                                          dayHours?.sa_from,
                                                                      'YYYY-MM-DD HH:mm:ss'
                                                                  ).format(
                                                                      _userData.user.business_id
                                                                          .time_select === '12'
                                                                          ? 'hh:mm a'
                                                                          : 'HH:mm'
                                                                  )
                                                                : '09:00'
                                                        }
                                                        onChange={(val: string) => {
                                                            setDayHours((prevState: any) => ({
                                                                ...prevState,
                                                                sa_from: val,
                                                            }));
                                                        }}
                                                    />
                                                </Hour>
                                                <Hour>
                                                    <Select
                                                        options={businessHours}
                                                        value={
                                                            dayHours?.sa_to
                                                                ? moment(
                                                                      moment().format() +
                                                                          ' ' +
                                                                          dayHours?.sa_to,
                                                                      'YYYY-MM-DD HH:mm:ss'
                                                                  ).format(
                                                                      _userData.user.business_id
                                                                          .time_select === '12'
                                                                          ? 'hh:mm a'
                                                                          : 'HH:mm'
                                                                  )
                                                                : '06:00'
                                                        }
                                                        onChange={(val: string) => {
                                                            setDayHours((prevState: any) => ({
                                                                ...prevState,
                                                                sa_to: val,
                                                            }));
                                                        }}
                                                    />
                                                </Hour>
                                                <Time>
                                                    <Icon className="fal fa-clock"></Icon>
                                                    {sa_Diff}h
                                                </Time>
                                            </HourContent>
                                        </List>
                                    </div>
                                    <div
                                        className={`hours-item ${
                                            !dayHours?.su_on ? 'hours-item--disabled' : ''
                                        }`}>
                                        <List>
                                            <WeekContent>
                                                <Switch
                                                    value={dayHours?.su_on}
                                                    onChange={(val: boolean) => {
                                                        setDayHours((prevState: any) => ({
                                                            ...prevState,
                                                            su_on: val,
                                                        }));
                                                    }}
                                                />
                                                <WeekName>Sunday</WeekName>
                                            </WeekContent>

                                            <HourContent className="hours-item--details">
                                                <Hour>
                                                    <Select
                                                        options={businessHours}
                                                        value={
                                                            dayHours?.su_from
                                                                ? moment(
                                                                      moment().format() +
                                                                          ' ' +
                                                                          dayHours?.su_from,
                                                                      'YYYY-MM-DD HH:mm:ss'
                                                                  ).format(
                                                                      _userData.user.business_id
                                                                          .time_select === '12'
                                                                          ? 'hh:mm a'
                                                                          : 'HH:mm'
                                                                  )
                                                                : '09:00'
                                                        }
                                                        onChange={(val: string) => {
                                                            setDayHours((prevState: any) => ({
                                                                ...prevState,
                                                                su_from: val,
                                                            }));
                                                        }}
                                                    />
                                                </Hour>
                                                <Hour>
                                                    <Select
                                                        options={businessHours}
                                                        value={
                                                            dayHours?.su_to
                                                                ? moment(
                                                                      moment().format() +
                                                                          ' ' +
                                                                          dayHours?.su_to,
                                                                      'YYYY-MM-DD HH:mm:ss'
                                                                  ).format(
                                                                      _userData.user.business_id
                                                                          .time_select === '12'
                                                                          ? 'hh:mm a'
                                                                          : 'HH:mm'
                                                                  )
                                                                : '06:00'
                                                        }
                                                        onChange={(val: string) => {
                                                            setDayHours((prevState: any) => ({
                                                                ...prevState,
                                                                su_to: val,
                                                            }));
                                                        }}
                                                    />
                                                </Hour>
                                                <Time>
                                                    <Icon className="fal fa-clock"></Icon>
                                                    {su_Diff}h
                                                </Time>
                                            </HourContent>
                                        </List>
                                    </div>
                                    <CardFooter>
                                        <Button
                                            disabled={isSubmitting}
                                            type="submit"
                                            bgtype="secondary"
                                            label="Save"></Button>
                                    </CardFooter>
                                </form>
                            )}
                        </Card>
                    </CustomCol>
                </Row>
            </Wrapper>
            {/* //   ))} */}
        </>
    );
};
const List = styled.div`
    display: flex;
    align-items: center;
    ${media.xs`
  display: block;
  `}
    ${media.sm`
  display: block;
  `}
    ${media.md`
    display: block;
  `}
    ${media.lg`
    display: flex;
  `}
`;
const WeekContent = styled.div`
    display: flex;
    align-items: center;
    // margin-right: 2rem;
    ${media.xs`
    margin-bottom: 1rem;
  `}
    ${media.sm`
    margin-bottom: 1rem;
  `}
    ${media.md`
    margin-bottom: 1rem;
  `}
    ${media.lg`
    margin-bottom: 0rem;
    `}
`;
const WeekName = styled.div`
    width: 6.42rem;
    font-weight: 500 !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    padding-left: 1rem !important;
`;
const HourContent = styled.div`
    display: flex;
    align-items: center;
    margin-right: 2rem;
    ${media.xs`
    margin-right: 0rem;
  `}
    ${media.sm`
    margin-right: 0rem;
  `}
  ${media.md`
    margin-right: 2rem;
  `}
  ${media.lg`
    margin-right: 2rem;
  `}
`;
const Hour = styled.div`
    width: 9.28rem;
    margin-right: 0.75rem !important;
`;
const Time = styled.div`
    color: rgba(223, 226, 230) !important;
    font-weight: 300 !important;
    font-size: 0.9rem !important;
`;
const Icon = styled.i`
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
    margin-right: 0.5rem !important;
`;
const Wrapper = styled(Container)`
    padding: 0rem;
`;
const CustomCol = styled(Col)`
    padding: 1rem;
    border-radius: 0.42rem;
    max-width: 100%;
    background-color: ${palette.light};
    box-shadow: 0rem 0rem 2.14rem 0rem ${palette.grey.shadow};
    @media screen and (min-width: 992px) and (max-width: 1199px) {
        max-width: 100%;
    }
`;
const Card = styled.div`
    padding: 0 !important;
    //     ${media.xs`
//   padding: 3rem 1rem 1.75rem 1rem !important;
//   `}
    //     ${media.sm`
//   padding: 3rem 1rem 1.75rem 1rem !important;
//   `}
    //     ${media.md`
//     padding: 3rem 1.5rem 1.75rem 1.5rem !important;
//   `}
    //     ${media.lg`
//     padding: 3rem 2rem 1.75rem 2rem !important;
//     `}
`;
const CardFooter = styled.div`
    margin-top: 1.5rem !important;
`;
export default BusinessHours;
