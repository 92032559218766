import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { api } from 'helpers/auth-axios';
import Button from '@components/Button';
import { Checkbox, ErrorModal, Input, SquarePaymentForm, useSnackbar } from '@components/common';
import NewCreditCard from '@components/appointments/Modal/CreditCard/NewCreditCard';
import { ICreateReservation, IPayForAppointment } from 'interfaces/appointment.interface';
import { ISquareCard } from 'interfaces/square.interface';
import SquareCardOnFileList from './SquareCardOnFileList';
import s from '../../NewAppointmentDrawer/NewAppointmentDrawer.module.scss';

interface IProps {
    newAppointmentModel?: any;
    stripeId?: string;
    selectedService?: any;
    selectedProfessional?: any;
    customerDetails?: any; // Exclusively Customer Object
    calendarDate?: any;
    appointment?: any;
    onSubmit(): any;
    onClose(): void;
}

const CompletePayment = ({
    newAppointmentModel,
    selectedService,
    selectedProfessional,
    customerDetails,
    appointment,
    onSubmit,
    onClose,
}: IProps) => {
    /*
     * General Variables
     */
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const [openSnackbar] = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [business, setBusiness] = useState<any>();
    const [tipPercentageLabel, setTipPercentageLabel] = useState<any>(); // "no tip", "20%", "25%", etc.
    const [selectedTipAmount, setSelectedTipAmount] = useState<any>(null);
    const [customTip, setCustomTip] = useState<any>(appointment.tip_amount || 0);
    const [differentCard, setDifferentCard] = useState<boolean>(false);
    const [totalCharges, setTotalCharges] = useState<number>(0);
    const [paymentProcessorFee, setPaymentProcessorFee] = useState<number>(0);
    const [newCardSelected, setNewCardSelected] = useState<boolean>(false);

    /*
     * NewCreditCard Form Variables
     */
    const [cardState, setCardState] = useState({
        cardNumber: '',
        cardHolder: 'FULL NAME',
        cardMonth: '',
        cardYear: '',
        cardCVV: '',
        isCardFlipped: false,
    });

    /*
     * Square-Specific Variables
     */
    const [squareCardInfo, setSquareCardInfo] = useState<any>(null);
    const [showSquareAddCardForm, setShowSquareAddCardForm] = useState<boolean>(false);

    /*
     * Stripe-Specific Variables
     */
    const [userAcceptedStripeCardSavingTerms, setUserAcceptedStripeCardSavingTerms] =
        useState<boolean>(false);

    // error modal state variables
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
    const [errorModalTitle, setErrorModalTitle] = useState<string>('');
    const [errorModalMessage, setErrorModalMessage] = useState<string>('');

    const navigate = useNavigate();
    const updateCardState = useCallback(
        (keyName: string, value: any) => {
            setCardState({
                ...cardState,
                [keyName]: value,
            });
        },
        [cardState]
    );
    useEffect(() => {
        if (customTip !== undefined && customTip !== null) {
            setSelectedTipAmount(customTip);
            setTipPercentageLabel('custom tip');
        }
    }, [customTip]);
    const subtotal = appointment?.total.slice(1);
    const gratuityOptions = [
        {
            label: 'No Tip',
            value: 0,
        },
        {
            label: '20%',
            value: subtotal * 0.2,
        },
        {
            label: '25%',
            value: subtotal * 0.25,
        },
        {
            label: '30%',
            value: subtotal * 0.3,
        },
    ];

    useEffect(() => {
        api.get(`/businesses/${_userData?.user?.business_id?._id}`).then((res: any) => {
            setBusiness(res.data);
        });
    }, []);

    useEffect(() => {
        const initialServiceCost = parseFloat(appointment?.total.slice(1));
        const tipAmount = parseFloat(selectedTipAmount ? selectedTipAmount : 0);
        const passFeesToCustomer = selectedProfessional.pass_fees_to_customer;
        const totalCharges = calculateTotalCharges(
            initialServiceCost,
            tipAmount,
            passFeesToCustomer
        );
        const finalServiceCost = totalCharges - tipAmount;
        const processingFee = calculatePaymentProcessorFee(
            finalServiceCost,
            tipAmount,
            passFeesToCustomer
        );
        setTotalCharges(totalCharges);
        setPaymentProcessorFee(processingFee);
    }, [appointment, selectedTipAmount, selectedProfessional, business, newCardSelected]);

    useEffect(() => {
        if (business) {
            moment.tz.setDefault(business.timezone);
        }
    }, [business]);

    const reserveAndPayForAppointment = () => {
        const paymentProcessor = getPaymentProcessorToChargeWith();

        if (paymentProcessor === 'stripe') {
            const cardIsExpired = checkIfCardHasExpired();
            if (cardIsExpired) {
                setShowErrorModal(true);
                setErrorModalMessage('The credit card expiration date has passed.');
                return;
            }
        }
        setLoading(true);
        const appointmentStartTime = moment(
            appointment.booked_from_iso,
            'YYYY-MM-DD HH:mm:ss'
        ).format('HH:mm');
        const appointmentDate = moment(appointment.booked_from_iso, 'YYYY-MM-DD HH:mm:ss').format(
            'YYYY-MM-DD'
        );
        let requestBody: ICreateReservation = {
            business_id: business._id!,
            customer_details: customerDetails,

            service_provider_id: selectedProfessional._id,
            service_id: selectedService._id,
            appointment_date: appointmentDate,
            start_time: appointmentStartTime,
            is_vip: appointment.is_vip,
            appointment_note: appointment.appointment_note,
            customer_note: appointment.customer_note,

            payment_processor: paymentProcessor,
            tip_label: tipPercentageLabel,
            is_prepaying: true,

            platform: 'web',
        };

        /*
         * Perform any necessary type conversion on tip amount
         * (custom tip field can change type to string)
         */
        if (typeof selectedTipAmount === 'string') {
            requestBody['tip_amount'] = parseFloat(selectedTipAmount);
        } else {
            requestBody['tip_amount'] = selectedTipAmount;
        }

        if (paymentProcessor === 'stripe') {
            requestBody['stripe_card_info'] = cardState;
        } else if (paymentProcessor === 'square') {
            requestBody['square_card_info'] = squareCardInfo;
        }

        api.post('/appointments', requestBody)
            .then((res: any) => {
                setLoading(false);
                openSnackbar('Payment successful!');
                onSubmit();
                if (_userData?.user_login === 'customer') {
                    navigate(`/customers/${_userData?.user?._id}`);
                }
            })
            .catch((error: any) => {
                if (error?.response) {
                    setShowErrorModal(true);
                    setErrorModalMessage(error.response.data.message);
                }

                setLoading(false);
            });
    };

    const payForAppointment = () => {
        setLoading(true);

        const paymentProcessor = getPaymentProcessorToChargeWith();

        let requestBody: IPayForAppointment = {
            paymentProcessor: paymentProcessor,
            tipLabel: selectedTipAmount === 0 ? 'no tip' : tipPercentageLabel,
        };

        if (paymentProcessor === 'stripe') {
            // if new payment method info was provided, add it to the requestBody
            if ((differentCard || !appointment.payment_intent_id) && stripeFormIsComplete()) {
                requestBody['stripeCardInfo'] = {
                    cardNumber: cardState.cardNumber,
                    cardHolder: cardState.cardHolder,
                    cardMonth: cardState.cardMonth,
                    cardYear: cardState.cardYear,
                    cardCVV: cardState.cardCVV,
                };
            }
        } else if (paymentProcessor === 'square') {
            // if new payment method info was provided, add it to the requestBody
            if (newCardSelected || (squareCardInfo && squareCardInfo.card?.id)) {
                requestBody['squareCardInfo'] = squareCardInfo;
            }
        }

        // if tip amount has changed, add it to the requestBody
        if (
            (selectedTipAmount || selectedTipAmount === 0) &&
            selectedTipAmount !== appointment.tip_amount
        ) {
            if (typeof selectedTipAmount === 'string') {
                requestBody['newTipAmount'] = parseFloat(selectedTipAmount);
            } else {
                requestBody['newTipAmount'] = selectedTipAmount;
            }
        }

        api.post(`/appointments/${appointment._id}/pay`, requestBody)
            .then((res: any) => {
                setLoading(false);
                openSnackbar('Payment successful!');
                onSubmit();
                if (_userData?.user_login === 'customer') {
                    navigate(`/customers/${_userData?.user?._id}`);
                }
            })
            .catch((error: any) => {
                if (error?.response) {
                    if (paymentProcessor === 'square') {
                        /*
                         * When payment fails, force user to select another card
                         */
                        setSquareCardInfo(null);
                        setDifferentCard(true);
                    }
                    setShowErrorModal(true);
                    if (error.response.data.message.includes('NOT_FOUND')) {
                        setErrorModalMessage('Payment failed. Please try another card.');
                    } else {
                        setErrorModalMessage(error.response.data.message);
                    }
                }

                setLoading(false);
            });
    };

    const calculateTotalCharges = (
        initialServiceCost: number,
        tipAmount: number,
        passFeesToCustomer: boolean
    ) => {
        const paymentProcessor = getPaymentProcessorToChargeWith();

        let totalCharges = initialServiceCost + tipAmount;

        if (passFeesToCustomer) {
            if (paymentProcessor === 'stripe') {
                totalCharges = calculateStripeTotalWithSurcharge(initialServiceCost, tipAmount);
            } else if (paymentProcessor === 'square') {
                totalCharges = calculateSquareTotalWithSurcharge(initialServiceCost, tipAmount);
            }
        }
        return roundToNearestCent(totalCharges);
    };

    const calculatePaymentProcessorFee = (
        serviceCost: number,
        tipAmount: number,
        passFeesToCustomer: boolean
    ) => {
        const paymentProcessor = getPaymentProcessorToChargeWith();
        let paymentProcessorFee = 0;

        if (passFeesToCustomer) {
            if (paymentProcessor === 'stripe') {
                paymentProcessorFee = calculateStripeFee(serviceCost, tipAmount);
            } else if (paymentProcessor === 'square') {
                paymentProcessorFee = calculateSquareFee(serviceCost, tipAmount);
            }
        }
        return roundToNearestCent(paymentProcessorFee);
    };

    /*
     * Stripe-Specific Functions
     */
    const calculateStripeTotalWithSurcharge = (serviceCost: number, tipAmount: number) => {
        return (serviceCost + tipAmount + 0.3) / 0.971;
    };
    const calculateStripeFee = (serviceCost: number, tipAmount: number) => {
        return (serviceCost + tipAmount) * 0.029 + 0.3;
    };
    const clearCardState = () => {
        setCardState({
            cardNumber: '',
            cardHolder: '',
            cardMonth: '',
            cardYear: '',
            cardCVV: '',
            isCardFlipped: false,
        });
    };

    /*
     * Square-Specific Functions
     */
    const calculateSquareTotalWithSurcharge = (initialServiceCost: number, tip: number) => {
        let finalServiceCost;
        // calculate the amount we need to set the service cost to in order to
        // cover the cost of fees
        finalServiceCost = (initialServiceCost + tip + 0.3) / 0.971;
        return finalServiceCost;
    };
    const calculateSquareFee = (serviceCost: number, tip: number) => {
        return 0.029 * (serviceCost + tip) + 0.3;
    };

    /*
     * Helper/Utility Functions
     */
    const roundToNearestCent = (amount: number) => {
        return Math.round(amount * 100) / 100;
    };

    const checkIfCardHasExpired = () => {
        const currentDate = new Date();
        // if the card's expiration date has passed present an error and prevent submission
        if (parseInt(cardState.cardYear) == currentDate.getFullYear()) {
            if (parseInt(cardState.cardMonth) < currentDate.getMonth() + 1) {
                return true;
            }
        }
        return false;
    };

    const stripeFormIsComplete = () => {
        return (
            cardState.cardNumber &&
            cardState.cardHolder &&
            cardState.cardMonth &&
            cardState.cardYear &&
            cardState.cardCVV &&
            userAcceptedStripeCardSavingTerms
        );
    };

    // Return true if business or provider have a payment processor enabled
    const aPaymentProcessorIsEnabled = () => {
        // First, check the service provider
        // Check if stripe is enabled
        if (selectedProfessional?.stripe_account_verified) {
            return true;
        }
        // Check if square is enabled
        if (selectedProfessional?.square_account_verified) {
            return true;
        }
        // Now, we check the business
        // Check if stripe is enabled
        if (business?.stripe_account_verified) {
            return true;
        }
        // Check if square is enabled
        if (business?.square_account_verified) {
            return true;
        }
        // Default case, return False since nothing else shows as enabled
        return false;
    };

    // This returns the payment processor to use currently,
    // meaning: to checkout with, to calculate total fees, etc.
    const getPaymentProcessorToChargeWith = () => {
        if (newCardSelected && !!squareCardInfo) {
            return 'square';
        } else if (newCardSelected && stripeFormIsComplete()) {
            return 'stripe';
        } else if (appointment.payment_intent_id) {
            return 'stripe';
        } else if (appointment.square_payment_id) {
            return 'square';
        } else {
            return getCurrentlySetupPaymentProcessor();
        }
    };

    // return a string representing the payment processor
    // used to create the appointment, return empty string
    // if no payment info is present on appointment
    const getAppointmentPaymentProcessor = () => {
        if (appointment.payment_processor === 'stripe' && appointment.payment_intent_id) {
            return 'stripe';
        } else if (appointment.payment_processor === 'square' && appointment.square_payment_id) {
            return 'square';
        }
        return '';
    };

    // return a string representing the payment processor in use
    const getCurrentlySetupPaymentProcessor = () => {
        // First, check the service provider
        // Check if stripe is enabled
        if (selectedProfessional?.stripe_account_verified) {
            return 'stripe';
        }
        // Check if square is enabled
        if (selectedProfessional?.square_account_verified) {
            return 'square';
        }
        // Now, we check the business
        // Check if stripe is enabled
        if (business?.stripe_account_verified) {
            return 'stripe';
        }
        // Check if square is enabled
        if (business?.square_account_verified) {
            return 'square';
        }

        return '';
    };

    const showStripeCardForm =
        getCurrentlySetupPaymentProcessor() === 'stripe' &&
        (differentCard ||
            (!appointment.payment_intent_id &&
                (!appointment.payment_processor ||
                    (appointment.payment_processor === 'square' &&
                        !appointment.square_payment_id))));
    const showSquareCardList =
        !showSquareAddCardForm &&
        !showStripeCardForm &&
        getCurrentlySetupPaymentProcessor() === 'square' &&
        (differentCard ||
            (!squareCardInfo &&
                (((!appointment.payment_processor || appointment.payment_processor === 'square') &&
                    !appointment.square_payment_id) ||
                    ((!appointment.payment_processor ||
                        appointment.payment_processor === 'stripe') &&
                        !appointment.payment_intent_id))));
    const showChargeButton =
        (showStripeCardForm && stripeFormIsComplete()) ||
        (!newCardSelected &&
            !showSquareCardList &&
            !showSquareAddCardForm &&
            (appointment.payment_intent_id || appointment.square_payment_id)) ||
        (newCardSelected && !showStripeCardForm && !!squareCardInfo);

    return (
        <>
            <Container>
                <Row>
                    <ClientContent lg={12}>
                        <HeaderContainer>
                            <CloseButton onClick={onClose}>
                                <Icon className="fal fa-long-arrow-left"></Icon>
                            </CloseButton>
                            <Header>Pay</Header>
                        </HeaderContainer>
                        {/* <SearchField value={value} name={`Cusomer`} /> */}
                        {aPaymentProcessorIsEnabled() ||
                        appointment.payment_intent_id ||
                        appointment.square_payment_id ? (
                            <CustomersBody>
                                <Wrapper>
                                    {showStripeCardForm && (
                                        <>
                                            <NewCreditCard
                                                initialState={cardState}
                                                loading={false}
                                                onChange={updateCardState}
                                                cardDisable={true}
                                            />
                                            <CheckboxWrapper>
                                                <Checkbox
                                                    name="enable-save-card-on-file"
                                                    label={
                                                        <>
                                                            You need your customer’s signed
                                                            authorization to save their card and
                                                            protect you from chargeback liability.
                                                            Have your customer{' '}
                                                            <a
                                                                href="https://squareup.com/dashboard/assets/billing/en-US/card_on_file_authorization_form_2021-44b25a6bd51d27ac4229c0e2c9c7e57575bb3f5fa1538e9cbb19607d6a3f0913.pdf"
                                                                target="_blank"
                                                                style={{
                                                                    textDecoration: 'underline',
                                                                    color: '#0154ff',
                                                                }}>
                                                                fill out and sign this form
                                                            </a>
                                                            , then store it securely in accordance
                                                            with PCI-DSS and data privacy
                                                            requirements. I agree to the above.
                                                        </>
                                                    }
                                                    onChange={(checked: boolean) => {
                                                        setUserAcceptedStripeCardSavingTerms(
                                                            checked
                                                        );
                                                    }}
                                                />
                                            </CheckboxWrapper>
                                        </>
                                    )}
                                    {showSquareCardList && (
                                        <SquareCardOnFileList
                                            customerId={
                                                appointment.customer?._id
                                                    ? appointment.customer._id
                                                    : appointment.customer
                                            }
                                            serviceProviderId={selectedProfessional._id}
                                            onSelect={(card: ISquareCard) => {
                                                setDifferentCard(false);
                                                setSquareCardInfo({
                                                    card: card,
                                                    bookingFeeToken: '',
                                                });
                                                setNewCardSelected(true);
                                            }}
                                            onAddCardButtonClick={() =>
                                                setShowSquareAddCardForm(true)
                                            }
                                        />
                                    )}
                                    {showSquareAddCardForm && (
                                        <>
                                            <CloseButton
                                                onClick={() => setShowSquareAddCardForm(false)}>
                                                <i className="fal fa-chevron-left"></i>
                                            </CloseButton>
                                            <SquarePaymentForm
                                                enableSaveCard={true}
                                                singleTokenReceiver={(paymentTokenResult: any) => {
                                                    const squareCard: ISquareCard = {
                                                        id: paymentTokenResult.token,
                                                        brand: paymentTokenResult.details.card
                                                            .brand,
                                                        expMonth:
                                                            paymentTokenResult.details.card
                                                                .expMonth,
                                                        expYear:
                                                            paymentTokenResult.details.card.expYear,
                                                        last4: paymentTokenResult.details.card
                                                            .last4,
                                                        billing: {
                                                            postalCode:
                                                                paymentTokenResult.details.billing
                                                                    .postalCode,
                                                        },
                                                    };
                                                    setDifferentCard(false);
                                                    setShowSquareAddCardForm(false);
                                                    setSquareCardInfo({
                                                        card: squareCard,
                                                        bookingFeeToken: '',
                                                    });
                                                    setNewCardSelected(true);
                                                }}
                                                cardOnFileAuthorizationCheckboxLabel={
                                                    <>
                                                        You need your customer’s signed
                                                        authorization to save their card and protect
                                                        you from chargeback liability. Have your
                                                        customer{' '}
                                                        <a
                                                            href="https://squareup.com/dashboard/assets/billing/en-US/card_on_file_authorization_form_2021-44b25a6bd51d27ac4229c0e2c9c7e57575bb3f5fa1538e9cbb19607d6a3f0913.pdf"
                                                            target="_blank"
                                                            style={{
                                                                textDecoration: 'underline',
                                                                color: '#0154ff',
                                                            }}>
                                                            fill out and sign this form
                                                        </a>
                                                        , then store it securely in accordance with
                                                        PCI-DSS and data privacy requirements. I
                                                        agree to the above.
                                                    </>
                                                }
                                                loading={loading}
                                            />
                                        </>
                                    )}
                                    <div>
                                        <div className={`${s.gratuity}`}>
                                            <Title>Tip</Title>
                                            {getCurrentlySetupPaymentProcessor() === 'stripe' &&
                                                !showStripeCardForm &&
                                                !showSquareCardList &&
                                                !showSquareAddCardForm && (
                                                    <Span
                                                        onClick={() => {
                                                            setDifferentCard(true);
                                                            // clear any payment processor card info
                                                            setSquareCardInfo(null);
                                                            clearCardState();
                                                            setNewCardSelected(true);
                                                        }}>
                                                        try another card?
                                                    </Span>
                                                )}
                                            {differentCard &&
                                                (showStripeCardForm ||
                                                    showSquareCardList ||
                                                    showSquareAddCardForm) && (
                                                    <Span
                                                        onClick={() => {
                                                            setDifferentCard(false);
                                                            setNewCardSelected(false);
                                                            setShowSquareAddCardForm(false);
                                                            setUserAcceptedStripeCardSavingTerms(
                                                                false
                                                            );
                                                        }}>
                                                        use default one?
                                                    </Span>
                                                )}
                                        </div>
                                        <div
                                            className="gratuity-items"
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-around',
                                            }}>
                                            {gratuityOptions.map((option: any, i: any) => {
                                                return (
                                                    <div>
                                                        {loading == true ? (
                                                            <div
                                                                key={i}
                                                                className={`${s.gratuity_tip} ${
                                                                    selectedTipAmount ===
                                                                    option.value
                                                                        ? s.gratuity_tipActive
                                                                        : ''
                                                                }`}
                                                                onClick={() => {
                                                                    setSelectedTipAmount(
                                                                        option.value
                                                                    );
                                                                    setCustomTip(null);
                                                                    setTipPercentageLabel(
                                                                        option.label
                                                                    );
                                                                }}>
                                                                <div>
                                                                    {
                                                                        _userData?.user?.business_id
                                                                            ?.currency
                                                                    }
                                                                    {option.value.toFixed(2)}
                                                                </div>
                                                                <GratuatyLabel>
                                                                    {option.label}
                                                                </GratuatyLabel>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                key={i}
                                                                className={`${s.gratuity_tip} ${
                                                                    selectedTipAmount ===
                                                                    option.value
                                                                        ? s.gratuity_tipActive
                                                                        : ''
                                                                }`}
                                                                onClick={() => {
                                                                    // gratuity(selectedTipAmount == 0 ? 0 : option.value);
                                                                    setTipPercentageLabel(
                                                                        option.label
                                                                    );
                                                                    setSelectedTipAmount(
                                                                        option.value
                                                                    );
                                                                    setCustomTip(null);
                                                                }}>
                                                                <div>
                                                                    {
                                                                        _userData?.user?.business_id
                                                                            ?.currency
                                                                    }
                                                                    {option.value.toFixed(2)}
                                                                </div>
                                                                <GratuatyLabel>
                                                                    {option.label}
                                                                </GratuatyLabel>
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <InputField>
                                            <Input
                                                label="Custom Tip"
                                                name="Custom Tip"
                                                value={customTip}
                                                type={'number'}
                                                // allowNumber={true}
                                                step="any"
                                                pattern="[0-9]*\.?[0-9]*"
                                                onChange={(e: any) => {
                                                    setCustomTip(e.value);
                                                }}
                                            />
                                        </InputField>

                                        <div style={{ padding: '1rem' }} className="subTotal-div">
                                            <CustomDiv>
                                                <Left></Left>{' '}
                                                <Right>
                                                    <GratuatyContent>
                                                        <Items>Service</Items>
                                                        <Amount>Amount</Amount>
                                                    </GratuatyContent>

                                                    <BasketItem>
                                                        <ItemsName>
                                                            {selectedService?.name}
                                                        </ItemsName>
                                                        <ItemsAmount>
                                                            {appointment?.is_vip == true
                                                                ? appointment?.total
                                                                : business?.currency +
                                                                  parseFloat(
                                                                      appointment?.total.slice(1)
                                                                  ).toFixed(2)}
                                                        </ItemsAmount>
                                                    </BasketItem>
                                                </Right>
                                            </CustomDiv>
                                            <TotalPriceContainer>
                                                <Left></Left>
                                                <Right>
                                                    <GratuatyContent>
                                                        <Gratuaty>Tip</Gratuaty>
                                                        <Gratuaty>
                                                            {business?.currency}
                                                            {selectedTipAmount
                                                                ? parseFloat(
                                                                      selectedTipAmount
                                                                  ).toFixed(2)
                                                                : '0.00'}
                                                        </Gratuaty>
                                                    </GratuatyContent>
                                                    {selectedProfessional.pass_fees_to_customer && (
                                                        <GratuatyContent>
                                                            <GratuityCapitalized>
                                                                Service Charge
                                                            </GratuityCapitalized>
                                                            <Gratuaty>
                                                                {business?.currency}
                                                                {paymentProcessorFee.toFixed(2)}
                                                            </Gratuaty>
                                                        </GratuatyContent>
                                                    )}
                                                    <SubTotalWrapper>
                                                        <SubTotalContent>
                                                            Total Charges
                                                        </SubTotalContent>
                                                        <SubTotalContent>
                                                            {business?.currency}
                                                            {totalCharges.toFixed(2)}
                                                        </SubTotalContent>
                                                    </SubTotalWrapper>
                                                </Right>
                                            </TotalPriceContainer>

                                            {getCurrentlySetupPaymentProcessor() === 'square' &&
                                                !showStripeCardForm &&
                                                !showSquareCardList &&
                                                !showSquareAddCardForm && (
                                                    <CustomDiv>
                                                        <Left></Left>
                                                        <RightBold>
                                                            {squareCardInfo && (
                                                                <GratuatyContent>
                                                                    <Gratuaty>
                                                                        <b>Charge Card Ending In</b>
                                                                    </Gratuaty>
                                                                    <Gratuaty>
                                                                        <b>
                                                                            ****
                                                                            {
                                                                                squareCardInfo.card
                                                                                    .last4
                                                                            }
                                                                        </b>
                                                                    </Gratuaty>
                                                                </GratuatyContent>
                                                            )}
                                                            <SquareChangeCardContainer>
                                                                <Span
                                                                    onClick={() => {
                                                                        setDifferentCard(true);
                                                                        // clear any payment processor card info
                                                                        setSquareCardInfo(null);
                                                                        clearCardState();
                                                                    }}>
                                                                    {differentCard === false && (
                                                                        <>
                                                                            <b>Change Card</b>{' '}
                                                                            <i className="fas fa-long-arrow-right"></i>
                                                                        </>
                                                                    )}
                                                                </Span>
                                                            </SquareChangeCardContainer>
                                                        </RightBold>
                                                    </CustomDiv>
                                                )}
                                        </div>
                                    </div>
                                    <Button
                                        bgtype={'secondary'}
                                        disabled={!showChargeButton || loading}
                                        ifClicked={() => {
                                            if (!showChargeButton) {
                                                openSnackbar('Please enter your card details!');
                                            } else {
                                                if (newAppointmentModel) {
                                                    reserveAndPayForAppointment();
                                                } else {
                                                    payForAppointment();
                                                }
                                            }
                                        }}
                                        width="100% !important"
                                        label={`${
                                            loading == true
                                                ? `Processing Please wait ${business?.currency}
                                                ${totalCharges?.toFixed(2)}`
                                                : `${
                                                      _userData.user_login === 'customer'
                                                          ? `Pay ${business?.currency}
                                                          ${totalCharges?.toFixed(2)}`
                                                          : 'Charge'
                                                  }`
                                        }`}></Button>
                                </Wrapper>
                            </CustomersBody>
                        ) : (
                            'Please connect exclusively to a valid Stripe or Square account.'
                        )}
                    </ClientContent>
                </Row>
            </Container>
            {showErrorModal && (
                <ErrorModal
                    title={'Error'}
                    message={errorModalMessage}
                    onClose={() => {
                        setShowErrorModal(false);
                        setErrorModalTitle('');
                        setErrorModalMessage('');
                    }}></ErrorModal>
            )}
        </>
    );
};
const ClientContent = styled(Col)`
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    ${media.xs`
    margin-left: 1.5rem;
  `}
    ${media.sm`
`}
    ${media.md`
`}
    ${media.lg`
    `}
`;
const HeaderContainer = styled.div`
    display: flex;
    padding: 2rem 0.3rem;
    min-height: 70px;
    align-items: center;
`;
const CloseButton = styled.div`
    margin-right: 1.5rem;
    cursor: pointer;
`;

const Icon = styled.i`
    font-size: 2.25rem;
    line-height: 2.5rem;
`;
const Header = styled.div`
    font-size: 1.75rem;
    font-weight: 600;
`;

const CustomersBody = styled.div`
    flex: 0 0 25rem;
    background-color: #fff;
    margin-left: 0rem;
    min-height: 100vh;
`;
const Wrapper = styled.div`
    padding-bottom: 100px;
`;
const CheckboxWrapper = styled.div`
    padding-top: 10px;
`;
const SearchResult = styled.ul`
    margin-top: 2rem;
    flex: 1;
    min-height: auto;
    position: relative;
    overflow-y: auto;
    list-style: none;
    padding: 0;
`;
const CustomerDetails = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 10px;
    margin-bottom: 1.3rem;
`;
const DivGroup = styled.div`
    margin-bottom: 1.3rem;
`;
const Items = styled.div`
    grid-column: span 6 / span 6 !important;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    color: rgba(157, 160, 164) !important;
`;
const Amount = styled.div`
    grid-column: span 3 / span 3 !important;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    text-align: right !important;
    color: rgba(157, 160, 164) !important;
`;
const Title = styled.div`
    font-weight: 600;
    font-size: 1.25rem /* 20px */;
    line-height: 1.75rem /* 28px */;
`;
const Span = styled.div`
    font-size: 1rem /* 20px */;
    line-height: 1.75rem /* 28px */;
    color: #0154ff;
    cursor: pointer;
`;
const GratuatyLabel = styled.div`
    color: rgba(157, 160, 164);
`;

const BasketItem = styled.ul`
    display: flex !important;
    justify-content: space-between;
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
    gap: 0.75rem !important;
    margin-top: 0.5rem;
`;

const ItemsName = styled.div`
    font-size: 0.92rem;
    display: flex;
    align-items: center;
    grid-column: span 6 / span 6;
    width: 150px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;

const ItemsAmount = styled.div`
    font-size: 13.8px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: end;
    grid-column: span 3 / span 3;
    text-align: right;
`;

const CustomDiv = styled.div`
    display: grid !important;
    margin-top: 0.5rem !important;
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
    gap: 0.75rem !important;
`;
const TotalPriceContainer = styled.div`
    display: grid !important;
    margin-top: 0.5rem !important;
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
    gap: 0.75rem !important;
`;
const Left = styled.div`
    grid-column: span 3 / span 3 !important;
`;
const Right = styled.div`
    grid-column: span 7 / span 7 !important;
    border-top: 1px solid rgba(223, 226, 230);
`;
const RightBold = styled.div`
    grid-column: span 7 / span 7 !important;
    border-top: 2px solid rgba(223, 226, 230);
`;
const SubTotalWrapper = styled.div`
    display: flex !important;
    justify-content: space-between !important;
    padding-top: 0.75rem !important;
    padding-bottom: 0.5rem !important;
`;
const SubTotalContent = styled.div`
    font-size: 0.9rem;
`;
const DiscountWrapper = styled.div`
    display: flex !important;
    justify-content: space-between !important;
    padding-bottom: 0.5rem !important;
`;
const GratuatyContent = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.5rem;
    margin-top: 1rem;
`;
const Gratuaty = styled.div`
    font-size: 0.9rem /* 14.4px */;
`;
const GratuityCapitalized = styled.div`
    font-size: 0.9rem /* 14.4px */;
    text-transform: capitalize;
`;
const SquareChangeCardContainer = styled.div`
    display: flex;
    justify-content: end;
    padding-bottom: 0.5rem;
    margin-top: 1rem;
    font-size: 0.9rem;
`;
const InputField = styled.div`
    margin-left: 1rem;
    margin-top: 1.5rem;
    width: 35%;
    ${media.xs`
    margin-top: 1.5rem;
    width: 42%;
  `}
    ${media.sm`
    margin-top: 1.5rem;
    width: 40%;
`}
    ${media.md`
    margin-top: 1rem;
  width: 35%;
`}
    ${media.lg`
    width: 35%;
    `}
`;
// const Button = styled.button`
//     padding: 13px 26px;
//     font-weight: 600;
//     text-transform: capitalize;
//     color: #fff;
//     background: #27292e;
//     border-radius: 8px;
//     font-size: 13px;
//     width: 100% !important;
//     line-height: inherit;
//     cursor: pointer;
//     border: none;
// `;
export default CompletePayment;
