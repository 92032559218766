import React, { useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import { Link } from 'react-router-dom';
import { palette } from 'styled/common';
const SettingsOptions = () => {
    const [Cards, setCards] = useState([
        {
            link: '/settings/details',
            heading: 'Business Details',
            text: 'Configure how your business appears to customers.',
        },
        {
            link: '/settings/business-hours',
            heading: 'Business Hours',
            text: "Let your customers know when you're available for services.",
        },
        {
            link: '/settings/services',
            heading: 'Services',
            text: 'Set up your services, assign pricing, and adjust advanced settings.',
        },
        { link: '/settings/location', heading: 'Location', text: 'Let us know where you work' },
        {
            link: '/settings/online-booking',
            heading: 'Online Booking',
            text: 'Set up your booking widget, configure payments and more.',
        },
        {
            link: '/settings/booking-settings',
            heading: 'Booking Settings',
            text: 'Set up booking policies, cancellation rules and more.',
        },
        // {
        //     link: '/settings/notification',
        //     heading: 'Notifications',
        //     text: 'Setup Email, SMS, Push notifications for user, serviceProviders and customers.',
        // },
        // {
        //     link: '/settings/billing',
        //     heading: 'Subscription & Billing',
        //     text: 'Configure your exclusively monthly subscription.',
        // },
    ]);
    return (
        <Wrapper>
            <Row>
                {Cards.map((value, index) => {
                    return (
                        <Flex key={index} xs={12} sm={12} md={6} lg={6}>
                            <CustomLink to={value.link}>
                                <div>
                                    <Heading>{value.heading}</Heading>
                                    <Text>{value.text}</Text>
                                </div>
                                <div>
                                    <Arrow>
                                        <i className="fal fa-chevron-right"></i>
                                    </Arrow>
                                </div>
                            </CustomLink>
                        </Flex>
                    );
                })}
            </Row>
        </Wrapper>
    );
};
const Wrapper = styled(Container)`
    padding-bottom: 1.5rem !important;
`;
const Header = styled.h1`
    font-size: 1.875rem;
    font-weight: 600;
    margin-bottom: 2rem !important;
    line-height: 2.25rem !important;
`;

const Flex = styled(Col)`
    margin-bottom: 1.5rem;
`;
const CustomLink = styled(Link)`
    display: flex;
    justify-content: space-between;
    background: white;
    padding: 2rem 2rem;
    border-radius: 0.5rem;
    align-items: center;
`;
const Heading = styled.h1`
    color: ${palette.dark};
    font-size: 1.13rem;
    font-weight: 500;
`;
const Text = styled.p`
    color: ${palette.silver};
    font-size: 0.9rem;
`;
const Arrow = styled.span`
    color: ${palette.silver};
    font-size: 1.2rem;
`;
export default SettingsOptions;
