import { useSnackbar } from '@components/common';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { appointmentActions } from 'actions/appointment';
import { useAppDispatch } from 'hooks/redux';
import { useState } from 'react';
import Button from '@components/Button';
import ConflictModal from '@components/appointments/Modal/ConflictModal';
import moment from 'moment';
const ModifyAppointmentModal = ({
    id,
    startDate,
    endDate,
    onClose,
    onSave,
    serviceProvierData,
    serviceProvider,
    showServiceProviderChangeWarning,
    onDragDate,
}: {
    id: string;
    startDate: any;
    endDate: any;
    onClose(): void;
    onSave(): void;
    serviceProvierData: any;
    serviceProvider: boolean;
    showServiceProviderChangeWarning?: boolean;
    onDragDate: any;
}) => {
    const [loading, setLoading] = useState(false);
    const [conflictDates, setConflictDates] = useState<string[]>([]);
    const [showConflictModal, setShowConflictModal] = useState(false);
    const dispatch = useAppDispatch();
    const [openSnackbar] = useSnackbar();
    const closeModal = (event: any) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
            onClose();
        }
    };
    const start: any = moment(startDate).format();
    const end: any = moment(endDate).format();
    const calculateDuration = moment.duration(moment(end).diff(start));
    const duration = calculateDuration.asMinutes();

    const save = () => {
        setLoading(true);
        const updatedAppointment = {
            booked_from_iso: moment(startDate).format(),
            booked_till_iso: moment(endDate).format(),
            duration: duration,
        };

        let from_hour: any = moment(startDate).format('HH');
        let from_mint: any = moment(startDate).format('mm');
        let till_hours: any = moment(endDate).format('HH');
        let till_mint: any = moment(endDate).format('mm');
        let today: any =
            onDragDate !== '' ? moment(onDragDate).format('YYYY-MM-DD') : moment().format();
        let day: any = today !== '' ? moment(today).format('DD') : '';
        let year: any = today !== '' ? moment(today).format('YYYY') : '';
        let month: any = today !== '' ? moment(today).format('MM') : '';
        let booked_from_iso = moment(
            year + month + day + from_hour + from_mint,
            'YYYY MM DD HH mm'
        );
        let booked_till_iso = moment(
            year + month + day + till_hours + till_mint,
            'YYYY MM DD HH mm'
        );
        const updatedAppointmentWithServiceProvider = {
            booked_from_iso: moment(booked_from_iso).format(),
            booked_till_iso: moment(booked_till_iso).format(),
            serviceProvider: serviceProvierData,
            duration: duration,
        };
        dispatch(
            appointmentActions.rescheduleAppointment(
                id,
                serviceProvider === true
                    ? updatedAppointmentWithServiceProvider
                    : updatedAppointment
            )
        )
            .then((res: any) => {
                setLoading(false);
                if (res.message) {
                    openSnackbar('Conflict');
                }
                setLoading(false);
                onSave();
                openSnackbar('Modify Appointment successfully!');
                onClose();
            })
            .catch(e => {
                setLoading(false);
                onClose();
                if (e.response) {
                    const response = e.response.data.response;

                    if (e?.response?.data?.message === undefined) {
                        openSnackbar('Something went wrong!');
                    } else {
                        openSnackbar(response?.message);
                    }

                    if (response?.dates) {
                        setConflictDates(response.dates);
                        setShowConflictModal(true);
                    } else {
                        onClose();
                    }
                }
            });
    };
    return (
        <>
            <Model tabIndex={-1} onClick={closeModal}>
                <ModelBody>
                    <ModelHead>
                        <Cover onClick={onClose}>
                            <i className="fas fa-times"></i>
                        </Cover>
                    </ModelHead>
                    <ModelHeading>
                        <Heading>Modify existing appointment?</Heading>
                    </ModelHeading>
                    {showServiceProviderChangeWarning ? (
                        <ModalMessage>
                            {
                                'CAUTION: If you proceed with changing the service provider all customer payment information will be removed and the appointment will no longer have appointment protection.'
                            }
                        </ModalMessage>
                    ) : (
                        <Space></Space>
                    )}

                    <Footer>
                        <Button
                            bgtype={'secondary'}
                            ifClicked={save}
                            disabled={loading}
                            width="10rem !important"
                            label="Save"></Button>
                        <Button
                            bgtype={'discard'}
                            ifClicked={onClose}
                            width="10rem !important"
                            label="Close"></Button>
                    </Footer>
                </ModelBody>
            </Model>
            {showConflictModal && (
                <ConflictModal
                    dates={conflictDates}
                    onContinue={() => {
                        setShowConflictModal(false);
                    }}
                    onClose={() => {
                        onClose();
                        setShowConflictModal(false);
                    }}
                />
            )}
        </>
    );
};
const Model = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 24px;
    overflow: auto;
    overscroll-behavior-y: contain;
    z-index: 102;
`;
const ModelBody = styled.div`
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 15px 25px -6px rgb(0 0 0 / 10%);
    padding: 24px;
    ${media.xs`max-width: 82vw;`}
    ${media.sm`max-width: 82vw;`}
    ${media.md`max-width: 42vw;`}
    ${media.lg`max-width: 42vw;`}
`;
const ModelHead = styled.div`
    display: flex;
    justify-content: end;
    margin-bottom: 0.75rem;
`;
const Cover = styled.div`
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3f4254;
    background-color: #e4e6ef;
    border-radius: 8px;
    font-size: 1.2rem;
`;
const ModelHeading = styled.div`
    align-items: center !important;
    display: flex !important;
`;
const Heading = styled.h1`
    font-weight: 600 !important;
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
    margin-bottom: 0px !important;
`;
const ModalMessage = styled.div`
    padding-top: 1rem !important;
    padding-bottom: 1.5rem !important;
`;
const Space = styled.div`
    padding-top: 2.5rem !important;
`;
const Footer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export default ModifyAppointmentModal;
