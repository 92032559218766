import BrandInfo from './BrandInfo';
import AsideList from './AsideList';
import Profile from './Profile';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { palette } from '../../../styled/common';
import { routes, customerRoute } from 'constants/routes';

const Aside = () => {
    var _user = JSON.parse(localStorage.getItem('user') || '{}');
    return (
        <>
            <Wrapper>
                <Row>
                    <BrandInfo />
                </Row>
                <Row>
                    <List lg={12}>
                        <AsideList
                            routes={_user?.user_login == 'customer' ? customerRoute : routes}
                        />
                    </List>
                </Row>
                <Row>
                    <Profile />
                </Row>
            </Wrapper>
        </>
    );
};
const Wrapper = styled(Container)`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 20;
    background-color: #1a202c;
    box-shadow: 0 0.1rem 0.3rem 0 ${palette.grey.shadow};
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    width: 18.5rem;
    ${media.xs`
    display:none;
  `}
    ${media.sm`
    display:none;
  `}
    ${media.md`
	display:none;
  `}
    ${media.lg`
    display: flex;
  `}
`;
const List = styled(Col)`
    width: 100%;
    flex: 1 1 100%;
    padding: 0rem;
    margin: 0rem;
`;
export default Aside;
