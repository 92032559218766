import '../styles/globals.css';
import '../components/common/index.scss';

import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { IUserState } from 'interfaces/user.interface';
import { businessActions } from 'actions/business';

import moment from 'moment-timezone';

import AppRoutes from 'router/routes';
import { IBusiness } from 'interfaces/business.interface';
import NotFound from './NotFound';
import { api } from 'helpers/auth-axios';
import Alert from '@components/common/Alert';
import useRouter from 'hooks/router';

const App = () => {
    const dispatch = useAppDispatch();
    const { user }: { user: IUserState } = useAppSelector(state => state.auth);
    const [loading, setLoading] = useState(true);
    const [stripeWarningMessage, setStripeWarningMessage] = useState<React.ReactNode>();
    const [timeWarningMessageWasLastClosed, setTimeWarningMessageWasLastClosed] =
        useState<moment.Moment>();
    const [disableStripeWarning, setDisableStripeWarning] = useState<boolean>(false);
    const router = useRouter();

    var _userData = JSON.parse(localStorage.getItem('user') || '{}');

    useEffect(() => {
        if (user) {
            if (_userData?.user?.business_id?._id) {
                api.get(`/businesses/${_userData?.user?.business_id?._id}`)
                    .then((res: any) => {
                        setLoading(false);
                    })
                    .catch((err: any) => {
                        console.log('[App] error:', err);
                    });
            } else {
                setLoading(false);
            }
        } else {
            setLoading(false);
            // Do something
        }
    }, [user, dispatch]);

    /*
     * Handle showing Stripe warning messages
     */
    useEffect(() => {
        // if the user has logged out, only clear the Stripe message
        if (router.pathname === '/login') {
            setStripeWarningMessage(undefined);
            setTimeWarningMessageWasLastClosed(undefined);
            return;
        }
        // if there is already a message about Stripe, do nothing
        if (stripeWarningMessage && stripeWarningMessage !== '') {
            return;
        }

        // if stripe message has been disabled, don't perform checks
        if (disableStripeWarning) {
            return;
        }

        // make sure only show message every 5 hours (after the user has closed the message)
        if (timeWarningMessageWasLastClosed) {
            // calculate time since the last message
            const timeSinceLastMessageClosed = moment().diff(
                timeWarningMessageWasLastClosed,
                'seconds'
            );

            // if it's been less than 5 hours (18,000 seconds) since the last message, just do nothing
            if (timeSinceLastMessageClosed < 18000) {
                return;
            }
        }

        /*
         * Service Providers should be shown their own Stripe restricted account message
         */
        if (
            _userData?.user_login === 'serviceProvider' &&
            _userData.user?.stripe_account_verified
        ) {
            const stripeAccountId = _userData.user.stripe_account_id;
            // if the stripe account is null or something, don't even try to make an api call with it
            if (!stripeAccountId) {
                setDisableStripeWarning(true);
                return;
            }

            api.get(`/billing/account/${stripeAccountId}`)
                .then((res: any) => {
                    /*
                     * If for some reason the endpoint returns w/ code 401 Unauthorized,
                     * prevent a page load loop.
                     */
                    if (res === undefined) {
                        setDisableStripeWarning(true);
                        return;
                    }

                    const account = res.data;
                    const disabledReason = account?.requirements?.disabled_reason;
                    // if disabled_reason is not null, then the account is disabled
                    if (disabledReason) {
                        setStripeWarningMessage(
                            <>
                                Your Stripe account is currenty disabled. Please{' '}
                                <a
                                    href="https://dashboard.stripe.com/login"
                                    target="_blank"
                                    style={{ textDecoration: 'underline' }}>
                                    login to your Stripe account
                                </a>{' '}
                                and provide any missing information to continue receiving payments.
                            </>
                        );
                    }
                })
                .catch((err: any) => {
                    setDisableStripeWarning(true);
                    console.error('There was a problem fetching Stripe account info:', err);
                });
        }

        /*
         * If business has their own Stripe account setup for everyone,
         * then the Business Owner should be warned if that account is restricted
         */
        if (
            _userData?.user_login === 'user' &&
            _userData.user?.business_id?.stripe_account_verified
        ) {
            const stripeAccountId = _userData.user?.business_id?.stripe_account_id;
            // if the stripe account is null or something, don't even try to make an api call with it
            if (!stripeAccountId) {
                setDisableStripeWarning(true);
                return;
            }

            api.get(`/billing/account/${stripeAccountId}`)
                .then((res: any) => {
                    /*
                     * If for some reason the endpoint returns w/ code 401 Unauthorized,
                     * prevent a page load loop.
                     */
                    if (res === undefined) {
                        setDisableStripeWarning(true);
                        return;
                    }

                    const account = res.data;
                    const disabledReason = account?.requirements?.disabled_reason;
                    // if disabled_reason is not null, then the account is disabled
                    if (disabledReason) {
                        setStripeWarningMessage(
                            <>
                                Your Stripe account is currenty disabled. Please{' '}
                                <a
                                    href="https://dashboard.stripe.com/login"
                                    target="_blank"
                                    style={{ textDecoration: 'underline' }}>
                                    login to your Stripe account
                                </a>{' '}
                                and provide any missing information to continue receiving payments.
                            </>
                        );
                    }
                })
                .catch((err: any) => {
                    setDisableStripeWarning(true);
                    console.error('There was a problem fetching Stripe account info:', err);
                });
        }
    }, [router.pathname]);

    const linkStyle = { textDecoration: 'underline' };
    const logout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('url');
        window.location.href = '/login';
    };

    return loading ? (
        <>
            Loading...
            <br />
            If this page does not load click{' '}
            <a style={linkStyle} onClick={logout} href="#">
                here
            </a>
        </>
    ) : (
        <>
            {stripeWarningMessage && stripeWarningMessage !== '' && (
                <Alert
                    type="error"
                    title="Stripe Account Restricted"
                    onClose={() => {
                        setStripeWarningMessage(undefined);
                        setTimeWarningMessageWasLastClosed(moment());
                    }}>
                    {stripeWarningMessage}
                </Alert>
            )}
            {<AppRoutes />}
        </>
    );
};

export default App;
