import { useEffect, useState } from 'react';
import styled from 'styled-components';
import leftChevron from '../../../images/icons/chevron-left.svg';
import './styles.scss';
import { Col, Container, media, Row } from 'styled-bootstrap-grid';
import Input from '../../common/Input/Input';
import { BookingOptionsEnum } from '../../../constants/enums';

const SelectTip = ({
    businessId,
    subTotal,
    service,
    serviceProvider,
    isVip,
    onSelect,
    gratuity,
    onPrevious,
    buttonTitle,
    tipLabel,
    loading,
    onSuccess,
}: {
    businessId: any;
    onSelect(tip: any): void;
    gratuity(value: number, totalCharges: number): void;
    onPrevious(): void;
    subTotal: any;
    buttonTitle: any;
    service: any;
    serviceProvider: any;
    isVip: boolean;
    tipLabel(val: any): void;
    loading: any;
    onSuccess: any;
}) => {
    const [selectedGratuity, setSelectedGratuity] = useState<number>(0);
    const [customTip, setCustomTip] = useState<any>(null);
    useEffect(() => {
        gratuity(selectedGratuity, totalCharges);
    }, [selectedGratuity, customTip]);

    useEffect(() => {
        if (customTip !== undefined && customTip !== null) {
            gratuity(parseFloat(customTip), totalCharges);
            setSelectedGratuity(parseFloat(customTip));
            tipLabel('custom tip');
        }
    }, [customTip]);
    const gratuityOptions = [
        {
            label: 'No Tip',
            value: 0,
        },
        {
            label: '20%',
            value: subTotal * 0.2,
        },
        {
            label: '25%',
            value: subTotal * 0.25,
        },
        {
            label: '30%',
            value: subTotal * 0.3,
        },
    ];

    const roundToNearestCent = (amount: number) => {
        return Math.round(amount * 100) / 100;
    };

    // start w/ just service cost + tip and then factor in fees (if passing fees to customer)
    let totalCharges = parseFloat(subTotal) + (selectedGratuity ? selectedGratuity : 0);
    let stripeFee = totalCharges; // start w/ service cost + tip, and use in later calculation
    if (serviceProvider.pass_fees_to_customer) {
        totalCharges = (totalCharges + 0.3) / 0.971;
        stripeFee = totalCharges - stripeFee;
    }
    // round the total to the nearest cent
    totalCharges = roundToNearestCent(totalCharges);
    // round stripe fees to nearest cent
    stripeFee = roundToNearestCent(stripeFee);

    return (
        <div className="booking-wrapper">
            <div>
                <div className="boking-widget--header">
                    {loading == true ? (
                        <div className="booking-widget--backBtn" style={{ opacity: '0' }}>
                            <img src={leftChevron} alt="" />
                        </div>
                    ) : (
                        <div className="booking-widget--backBtn" onClick={onPrevious}>
                            <img src={leftChevron} alt="" />
                        </div>
                    )}
                    <div className="gratuity">
                        <Title>
                            <h3>Charges and Tip</h3>
                        </Title>
                    </div>
                </div>
                <div className="booking-widget--body">
                    <div className="grid-items">
                        {gratuityOptions.map(option => {
                            return (
                                <div>
                                    {loading == true ? (
                                        <div
                                            key={option.label}
                                            className={`gratuity--tip ${
                                                selectedGratuity === option.value
                                                    ? 'gratuity_tipActive'
                                                    : ''
                                            }`}
                                            onClick={() => {
                                                // tipLabel(option.label);
                                                setSelectedGratuity(selectedGratuity);
                                                setCustomTip(0);
                                            }}>
                                            <div>
                                                {businessId?.currency}
                                                {option.value.toFixed(2)}
                                            </div>
                                            <GratuatyLabel>{option.label}</GratuatyLabel>
                                        </div>
                                    ) : (
                                        <div
                                            key={option.label}
                                            className={`gratuity--tip ${
                                                selectedGratuity === option.value
                                                    ? 'gratuity_tipActive'
                                                    : ''
                                            }`}
                                            onClick={() => {
                                                // gratuity(selectedGratuity == 0 ? 0 : option.value);
                                                tipLabel(option.label);
                                                setSelectedGratuity(option.value);
                                                setCustomTip(null);
                                            }}>
                                            <div>
                                                {businessId?.currency}
                                                {option.value.toFixed(2)}
                                            </div>
                                            <GratuatyLabel>{option.label}</GratuatyLabel>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    <InputField>
                        <Input
                            label="Custom Tip"
                            name="Custom Tip"
                            value={customTip}
                            //type={'number'}
                            type="tel"
                            inputMode="numeric"
                            // allowNumber={true}
                            step="any"
                            pattern="[0-9]*\.?[0-9]*"
                            onChange={(e: any) => {
                                setCustomTip(e.value !== '' ? parseFloat(e.value) : null);
                                // setOnCustomClick(true);
                            }}
                        />
                    </InputField>

                    <div style={{ padding: '1rem', height: '100 vh' }} className="subTotal-div">
                        <CustomDiv>
                            <Left></Left>{' '}
                            <Right>
                                <GratuatyContent>
                                    <Items>Service</Items>
                                    <Amount>Amount</Amount>
                                </GratuatyContent>

                                <BasketItem>
                                    <ItemsName>{service}</ItemsName>
                                    <ItemsAmount>
                                        {businessId?.currency}
                                        {subTotal}
                                    </ItemsAmount>
                                </BasketItem>
                            </Right>
                        </CustomDiv>
                        <TotalPriceContainer>
                            <Left></Left>
                            <Right>
                                <GratuatyContent>
                                    <Gratuaty>Tip</Gratuaty>
                                    <Gratuaty>
                                        {businessId?.currency}
                                        {selectedGratuity ? selectedGratuity.toFixed(2) : '0.00'}
                                    </Gratuaty>
                                </GratuatyContent>
                                {serviceProvider.pass_fees_to_customer && (
                                    <DiscountWrapper>
                                        <SubTotalContent>Service Charge</SubTotalContent>
                                        <SubTotalContent>
                                            {businessId?.currency}
                                            {stripeFee.toFixed(2)}
                                        </SubTotalContent>
                                    </DiscountWrapper>
                                )}
                                <SubTotalWrapper>
                                    <SubTotalContent>
                                        <span style={{ fontWeight: 'bold' }}>Total Charges</span>
                                    </SubTotalContent>
                                    <SubTotalContent>
                                        <span style={{ fontWeight: 'bold' }}>
                                            {businessId?.currency}
                                            {totalCharges.toFixed(2)}
                                        </span>
                                    </SubTotalContent>
                                </SubTotalWrapper>
                            </Right>
                        </TotalPriceContainer>
                    </div>
                </div>
            </div>
            {selectedGratuity !== undefined ? (
                <div
                    className="booking-widget--footer"
                    style={{ position: 'fixed', flexDirection: 'column' }}>
                    {onSuccess == true && (
                        <div className="validationField" style={{ marginBottom: '0.5rem' }}>
                            {' '}
                            <span className="error-text">
                                Something went wrong, Check your details and try again!{' '}
                            </span>
                        </div>
                    )}
                    <button className="btn" onClick={() => onSelect(selectedGratuity)}>
                        {buttonTitle}
                    </button>
                </div>
            ) : (
                <div
                    className="booking-widget--footer-disabled"
                    style={{ position: 'fixed', bottom: '28px' }}>
                    <button className="btn">{buttonTitle}</button>
                </div>
            )}
            <div className="agreement-txt-personal">
                {/* Tip your professional to show that you value the services being provided. */}
            </div>
        </div>
    );
};

const Items = styled.div`
    grid-column: span 6 / span 6 !important;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    color: rgba(157, 160, 164) !important;
`;
const Amount = styled.div`
    grid-column: span 3 / span 3 !important;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    text-align: right !important;
    color: rgba(157, 160, 164) !important;
`;
const Title = styled.div`
    font-weight: 600;
    font-size: 1.25rem /* 20px */;
    line-height: 1.75rem /* 28px */;
`;
const GratuatyLabel = styled.div`
    color: rgba(157, 160, 164);
`;

const BasketItem = styled.ul`
    display: flex !important;
    justify-content: space-between;
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
    gap: 0.75rem !important;
    margin-top: 0.5rem;
`;

const ItemsName = styled.div`
    font-size: 0.92rem;
    display: flex;
    align-items: center;
    grid-column: span 6 / span 6;
    width: 150px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;

const ItemsAmount = styled.div`
    font-size: 13.8px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: end;
    grid-column: span 3 / span 3;
    text-align: right;
`;
const CustomDiv = styled.div`
    display: grid !important;
    margin-top: 0.5rem !important;
    gap: 0.75rem !important;
`;
const TotalPriceContainer = styled.div`
    display: grid !important;
    margin-top: 0.5rem !important;
    gap: 0.75rem !important;
`;
const Left = styled.div`
    grid-column: span 3 / span 3 !important;
`;
const Right = styled.div`
    grid-column: span 7 / span 7 !important;
    border-top: 1px solid rgba(223, 226, 230);
`;
const SubTotalWrapper = styled.div`
    display: flex !important;
    justify-content: space-between !important;
    padding-top: 0.75rem !important;
    padding-bottom: 0.5rem !important;
`;
const SubTotalContent = styled.div`
    font-size: 0.9rem;
`;
const DiscountWrapper = styled.div`
    display: flex !important;
    justify-content: space-between !important;
    padding-bottom: 0.5rem !important;
`;
const GratuatyContent = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.5rem;
    margin-top: 1rem;
`;
const Gratuaty = styled.div`
    font-size: 0.9rem /* 14.4px */;
`;
const InputField = styled.div`
    margin-top: 1rem;
    width: 100%;
    margin-left: 0.5rem;
    ${media.xs`
    width: 100%;
  `}
    ${media.sm`
    width: 100%;
`}
    ${media.md`
  width: 100%;
`}
`;
export default SelectTip;
