import { useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import Card from './Card';
import CardForm from './CardForm';
import { api } from 'helpers/auth-axios';
import { useSnackbar } from '@components/common';
const initialState: any = {
    cardNumber: '',
    cardHolder: 'FULL NAME',
    cardMonth: '',
    cardYear: '',
    cardCVV: '',
    isCardFlipped: false,
};
const NewCreditCard = ({
    buttonTitle,
    stripeAccountId,
    onSave,
}: {
    buttonTitle: any;
    stripeAccountId: any;
    onSave(paymentMethodId: string): void;
}) => {
    const [state, setState] = useState(initialState);
    const [currentFocusedEl, setCurrentFocusedEl] = useState(null);
    const [openSnackbar] = useSnackbar();
    const updateStateValues = useCallback(
        (keyName: string, value: any) => {
            setState({
                ...state,
                [keyName]: value || initialState[keyName],
            });
        },
        [state]
    );

    const createPaymentMethod = useCallback(() => {
        api.post('/billing/payment-method', {
            stripe_account_id: stripeAccountId,
            number: state.cardNumber,
            exp_month: state.cardMonth,
            exp_year: state.cardYear,
            cvc: state.cardCVV,
            customer_email: null,
        })
            .then(res => {
                onSave((res.data as any).id);
            })
            .catch((e: any) => {
                if (e?.response) {
                    const data = e.response;
                    openSnackbar(e?.response?.data?.message);
                }
            });
    }, [state]);

    const formFieldsRefObj: any = {
        cardNumber: useRef(),
        cardHolder: useRef(),
        cardDate: useRef(),
        cardCVV: useRef(),
    };
    const cardElementsRef: any = {
        cardNumber: useRef(),
        cardHolder: useRef(),
        cardDate: useRef(),
    };
    const focusFormFieldByKey = useCallback(key => {
        formFieldsRefObj[key].current.focus();
    }, []);
    return (
        <Wrapper>
            <Row>
                <Col lg={6}>
                    <CardContent>
                        <CardBody>
                            <Card
                                cardNumber={state.cardNumber}
                                cardHolder={state.cardHolder}
                                cardMonth={state.cardMonth}
                                cardYear={state.cardYear}
                                cardCVV={state.cardCVV}
                                isCardFlipped={state.isCardFlipped}
                                currentFocusedEl={currentFocusedEl}
                                onCardElClick={(el: string) => console.log(el)}
                                cardNumberRef={cardElementsRef.cardNumber}
                                cardHolderRef={cardElementsRef.cardHolder}
                                cardDateRef={cardElementsRef.cardDate}
                            />
                            <CardForm
                                cardMonth={state.cardMonth}
                                cardYear={state.cardYear}
                                onUpdateState={updateStateValues}
                                cardNumberRef={cardElementsRef.cardNumber}
                                cardHolderRef={cardElementsRef.cardHolder}
                                cardDateRef={cardElementsRef.cardDate}
                            />
                            <Button onClick={() => createPaymentMethod()}>{buttonTitle}</Button>
                        </CardBody>
                    </CardContent>
                </Col>
            </Row>
        </Wrapper>
    );
};
const Wrapper = styled(Container)`
    padding: 0rem;
`;
const CardContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: border-box;
    border-radius: 0.42rem;
    box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
    border: 0;
`;
const CardBody = styled.div`
    padding: 1.75rem 2rem;
`;
const Button = styled.button`
    padding: 13px 26px;
    font-weight: 600;
    text-transform: capitalize;
    color: #fff;
    background: #27292e;
    border-radius: 8px;
    font-size: 13px;
    width: 100% !important;
    line-height: inherit;
    cursor: pointer;
`;
export default NewCreditCard;
