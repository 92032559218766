import { businessConstants } from 'actions/business';
import { IBusiness } from 'interfaces/business.interface';

const INITIAL_STATE: IBusiness = {};
export function business(state = INITIAL_STATE, action: any) {
    switch (action.type) {
        case businessConstants.GET_BUSINESS:
            return {
                business: action.business,
            };
        case businessConstants.UPDATE_SUCCESS:
            return {
                business: action.business,
            };
        default:
            return state;
    }
}
