import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
export interface InputProps {
    name?: string;
    type?: string;
    id?: string;
    value?: string | number;
    label?: string;
    className?: string;
    readOnly?: boolean;
    disabled?: boolean;
    min?: any;
    autoComplete?: string;
    error?: {
        message: string;
    };
    maxLength?: any;
    allowNumber?: boolean;
    slotLeft?: React.ReactNode;
    onBlur?(): void;
    onFocus?(): void;
    onChange?: any;
    required?: any;
    step?: any;
    pattern?: any;
    onInput?: any;
    allowPhoneNumberOnly?: boolean;
    inputMode?: any;
    showCharacterCount?: boolean; // if true, will show (current count / max length) next to label
}
const Input = ({
    name,
    type,
    id,
    value,
    label,
    className,
    readOnly,
    disabled,
    autoComplete,
    error,
    slotLeft,
    maxLength,
    allowNumber,
    onBlur,
    onFocus,
    onChange,
    min,
    step,
    required,
    pattern,
    onInput,
    allowPhoneNumberOnly,
    inputMode,
    showCharacterCount,
    ...props
}: InputProps) => {
    const [isActive, setActive] = useState(false);
    const [isFocused, setFocused] = useState(false);
    const [inputValue, setInputValue] = useState(value ?? '');
    const [characterCount, setCharacterCount] = useState(0); // the number of characters currently in the input field
    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (inputRef.current && inputRef.current.value.length > 0) setActive(true);
    }, []);
    useEffect(() => {
        setInputValue(value ?? '');
    }, [value]);
    useEffect(() => {
        if (inputValue) setActive(true);
        if (typeof inputValue === 'string') setCharacterCount(inputValue.length);
    }, [inputValue]);
    const focusInput = () => {
        setActive(true);
        setFocused(true);
        if (inputRef.current) inputRef.current.focus();
    };
    const handleFocus = () => {
        if (onFocus) onFocus();
    };
    const unfocusInput = () => {
        setFocused(false);
        if (inputRef.current && inputRef.current.value.length === 0) setActive(false);
        if (onBlur) onBlur();
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (allowNumber) {
            const re = /^[0-9 ]+/;
            let val = event.target.value.replace(' ', '');
            if (re.test(val) || val.length == 0) {
                setInputValue(event.target.value.replace(/[\s]/g, ''));
                if (onChange)
                    onChange({
                        value: event.target.value.replace(/[\s]/g, ''),
                        name: event.target.name,
                    });
            }
        } else if (allowPhoneNumberOnly) {
            const re = /^[0-9 -]+$/;
            let val = event.target.value.replace(' ', '');
            if (re.test(val) || val.length == 0) {
                setInputValue(event.target.value.replace(/[\s]/g, ''));
                if (onChange)
                    onChange({
                        value: event.target.value.replace(/[\s]/g, ''),
                        name: event.target.name,
                    });
            }
        } else {
            setInputValue(event.target.value);
            if (onChange) onChange({ value: event.target.value, name: event.target.name });
        }
    };
    return (
        <InputContainer onClick={() => focusInput()}>
            <Wrapper error={error}>
                <div>
                    <InputField
                        ref={inputRef}
                        placeholder={!isActive ? label : ''}
                        id={name}
                        name={name}
                        min={min}
                        type={
                            (type ? type : 'text') ||
                            (type ? type : 'date') ||
                            (type ? type : 'email')
                        }
                        onChange={event => handleChange(event)}
                        value={inputValue}
                        className={className}
                        disabled={disabled}
                        readOnly={readOnly}
                        autoComplete={autoComplete || 'off'}
                        onBlur={() => unfocusInput()}
                        onFocus={handleFocus}
                        maxLength={maxLength}
                        required={required}
                        step={step}
                        pattern={pattern}
                        onInput={onInput}
                        inputMode={inputMode}
                    />

                    <LabelWrapper value={inputValue} error={error}>
                        <label>
                            {label}
                            {showCharacterCount &&
                                maxLength !== undefined &&
                                ` (${characterCount}/${maxLength})`}
                        </label>
                    </LabelWrapper>
                </div>
            </Wrapper>
            {error && <Message>{error.message}</Message>}
        </InputContainer>
    );
};
const InputContainer = styled.div`
    width: 100%;
    position: relative;
    display: block;
`;
const Wrapper = styled.div<InputProps>`
    padding: 0.75rem 1.15rem;
    align-items: stretch;
    border-radius: 0.325rem;
    border-style: solid !important;
    border-width: 0.5px !important;
    background-color: #fff;
    transition: border-color 0.15s ease-in-out;
    border-color: ${({ error }) => (error ? `#eb5353` : 'rgba(223, 226, 230)')};
`;
const LabelWrapper = styled.div<InputProps>`
    position: absolute;
    max-width: calc(100% - 24px);
    top: -1px;
    label {
        color: ${({ error }) => (error ? `#eb5353` : '#8C8B88')};
        font-size: 10px;
        background: #fff;
        display: block;
        padding: 0 6px;
        margin-left: -6px;
        font-weight: 300;
        text-transform: capitalize;
        line-height: 1;
        opacity: ${({ value }) => (value ? `1` : '0')};
        transform: ${({ value }) => (value ? `translateY(-50%)` : 'translateY(50%)')};
        transition: ${({ value }) =>
            value
                ? `transition: color 0.15s ease-in-out, transform 0.15s ease-in-out, opacity 0.15s ease-in-out !important;`
                : 'all 0.15s ease-in-out'};
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &::before,
        &::after {
            content: ' ';
            position: absolute;
            width: 100%;
            height: 50%;
            z-index: -1;
            left: 0;
        }
        &::before {
            top: 50%;
            // background-color: #fff;
        }
        &::after {
            top: 0;
            background-color: transparent;
        }
    }
`;
const InputField = styled.input`
    display: block;
    background-color: transparent;
    width: 100%;
    border: none;
    min-width: 0;
    color: #263446;
    line-height: 24px;
    &::placeholder {
        font-weight: 300;
        color: #8C8B88;
    }
    &:focus {
        outline: 0;
    }
    &:focus + ${LabelWrapper} {
        label {
            transform: translateY(-50%) !important;
            opacity: 1 !important;
            transition: color 0.15s ease-in-out, transform 0.15s ease-in-out, opacity 0.15s ease-in-out !important;
        }
    }
    }
`;
const Message = styled.div`
    color: #fff;
    background-color: #eb5353;
    border: 1px solid #eb5353;
    border-radius: 0px 0px 0.325rem 0.325rem;
    padding: 2px 10px;
    margin-top: -3px;
    font-size: 0.75rem;
    font-weight: 300;
`;
export default Input;
