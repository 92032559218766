import { IGetTimeOff, ITimeOff } from 'interfaces/timeoff.interface';
import { Dispatch } from 'redux';
import { TimeOffServices } from 'services/timeoff.service';

export const timeoffConstants = {
    CREATE_REQUEST: 'TIMEOFF_CREATE_REQUEST',
    CREATE_SUCCESS: 'TIMEOFF_CREATE_SUCCESS',
    CREATE_FAILURE: 'TIMEOFF_CREATE_FAILURE',

    GET_REQUEST: 'TIMEOFF_GET_REQUEST',
    GET_SUCCESS: 'TIMEOFF_GET_SUCCESS',
    GET_FAILURE: 'TIMEOFF_GET_FAILURE',

    DELETE_SUCCESS: 'TIMEOFF_DELETE_SUCCESS',
};

const createTimeOff =
    (user_id: string, timeoffData: ITimeOff) =>
    (dispatch: Dispatch): Promise<ITimeOff> => {
        dispatch({
            type: timeoffConstants.CREATE_REQUEST,
        });

        return TimeOffServices.create(user_id, timeoffData).then(timeoff => {
            dispatch({
                type: timeoffConstants.CREATE_SUCCESS,
                timeoff,
            });

            return timeoff;
        });
    };

const getTimeOff =
    (user_id: string) =>
    (dispatch: Dispatch): Promise<IGetTimeOff[]> => {
        dispatch({
            type: timeoffConstants.GET_REQUEST,
        });

        return TimeOffServices.getTimeoff(user_id).then(timeoff => {
            dispatch({
                type: timeoffConstants.GET_SUCCESS,
                timeoff,
            });

            return timeoff;
        });
    };

const deleteTimeOff =
    (id: string) =>
    (dispatch: Dispatch): Promise<ITimeOff> => {
        return TimeOffServices.deleteTimeoff(id).then(timeoff => {
            dispatch({
                type: timeoffConstants.DELETE_SUCCESS,
                id,
            });

            return timeoff;
        });
    };
export const timeOffActions = {
    createTimeOff,
    getTimeOff,
    deleteTimeOff,
};
