import { otherConstants } from 'actions/team/other';
import { IGetOtherResponse } from 'interfaces/team/others.interface';

const INITIAL_STATE: IGetOtherResponse = {
    page: 0,
    pageSize: 20,
    other: [],
    total: 0,
};
export function other(state = INITIAL_STATE, action: any) {
    switch (action.type) {
        case otherConstants.GET_SUCCESS:
            return {
                ...action.other,
                other: state.other?.concat(action.other.other),
            };
        case otherConstants.CREATE_SUCCESS:
            return {
                ...state,
                total: state.total + 1,
                other: state.other?.concat(action.other),
            };
        case otherConstants.SEARCH_SUCCESS:
            return {
                ...action.other,
            };

        case otherConstants.DELETE_SUCCESS:
            return {
                ...state,
                other: state.other.filter(other => other._id !== action.id),
            };

        case otherConstants.UPDATE_SUCCESS:
            const prev = state.other.find(other => other._id === action.other._id);
            Object.assign(prev, action.other);
            return state;
        default:
            return state;
    }
}
