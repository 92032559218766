import React, { useEffect, useMemo, useState } from 'react';

import { Layout, Select } from '@components/common';
import styled from 'styled-components';
import _ from 'lodash';
import { Col, Container, media, Row } from 'styled-bootstrap-grid';
import moment from 'moment';
import { api } from 'helpers/auth-axios';
import { LoaderOverlay, useSnackbar } from '@components/common';
import SearchField from '@components/common/Search/SearchField';
import { ActivityTypeEnum, IActivity, ISearchActivities } from 'interfaces/activity.interface';
import { palette } from '../styled/common';
import Button from '@components/Button';
import { FaTimesCircle, FaMinusCircle } from 'react-icons/fa';
import { AiFillDollarCircle } from 'react-icons/ai';
import { IconContext } from 'react-icons/lib';

const ActivityFeed = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [openSnackbar] = useSnackbar();

    const [searchFieldValue, setSearchFieldValue] = useState<string>('');
    // all the returned list of activities
    const [activitySearchResults, setActivitySearchResults] = useState<any[]>([]);
    // the whole response from activity search
    const [activitySearchResponse, setActivitySearchResponse] = useState<any>();
    const [selectedActivityType, setSelectedActivityType] = useState<ActivityTypeEnum | string>();
    // TODO: do we want to implement date-range searching
    const [selectedStartDate, setSelectedStartDate] = useState<moment.Moment | undefined>();
    const [selectedEndDate, setSelectedEndDate] = useState<moment.Moment | undefined>();
    const [pageNumber, setPageNumber] = useState(0);
    const [hasMore, setHasMore] = useState(false);

    var _userData = JSON.parse(localStorage.getItem('user') || '{}');

    const pageSize = 20;
    const timezone = _userData?.user?.business_id?.timezone;

    const getActivityTypeOptions = () => {
        let typeOptions = Object.values(ActivityTypeEnum).map((val: string) => {
            return { label: toTitleCase(val), value: val };
        });

        typeOptions.unshift({ label: 'All', value: '' });

        return typeOptions;
    };

    const searchActivities = (searchRequest: ISearchActivities) => {
        setLoading(true);
        let params = [];
        if (searchRequest.containsText) {
            params.push(`containsText=${searchRequest.containsText}`);
        }
        if (searchRequest.activityType) {
            params.push(`activityType=${searchRequest.activityType}`);
        }
        if (searchRequest.startDate) {
            params.push(`startDate=${searchRequest.startDate}`);
        }
        if (searchRequest.endDate) {
            params.push(`endDate=${searchRequest.endDate}`);
        }
        if (searchRequest.page !== undefined) {
            params.push(`page=${searchRequest.page}`);
        }
        if (searchRequest.pageSize) {
            params.push(`pageSize=${searchRequest.pageSize}`);
        }
        api.get(`/activity?${params.join('&')}`)
            .then((res: any) => {
                // if we're getting another page, add it to the total list instead of wiping it out
                if (searchRequest.page > 0) {
                    setActivitySearchResults((prevActivitiesList: any) => [
                        ...prevActivitiesList,
                        ...res.data.activities,
                    ]);
                } else {
                    setActivitySearchResults(res.data.activities);
                }
                setActivitySearchResponse(res.data);
                setHasMore(res.data.activities.length > 0);
                setLoading(false);
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e.response?.data?.message);
                }
            });
    };

    const loadMoreActivities = () => {
        const nextPageNumber = pageNumber + 1;
        setPageNumber(nextPageNumber);
    };

    const fetchActivities = () => {
        let searchActivitiesRequestBody: ISearchActivities = {
            page: pageNumber,
            pageSize: pageSize,
        };
        if (searchFieldValue && searchFieldValue !== '') {
            searchActivitiesRequestBody['containsText'] = searchFieldValue;
        }
        if (selectedActivityType && selectedActivityType !== 'All') {
            searchActivitiesRequestBody['activityType'] = selectedActivityType;
        }
        if (selectedStartDate) {
            searchActivitiesRequestBody['startDate'] = selectedStartDate;
        }
        if (selectedEndDate) {
            searchActivitiesRequestBody['endDate'] = selectedEndDate;
        }
        searchActivities(searchActivitiesRequestBody);
    };

    const handleSearchFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchFieldValue(event.target.value);
        searchFieldDebounce(event.target.value);
    };
    const searchFieldDebounce = useMemo(() => {
        return _.debounce((searchFieldText: string) => {
            setHasMore(true);
            setPageNumber(0);
            let searchActivitiesRequestBody: ISearchActivities = {
                containsText: searchFieldText,
                page: 0,
                pageSize: pageSize,
            };
            if (selectedActivityType && selectedActivityType !== 'All') {
                searchActivitiesRequestBody['activityType'] = selectedActivityType;
            }
            searchActivities(searchActivitiesRequestBody);
        }, 300);
    }, []);

    useEffect(() => {
        fetchActivities();
    }, [pageNumber, selectedActivityType, selectedStartDate, selectedEndDate]);

    /*
     * Sub-Component-Building Functions
     */

    const listItem = (activity: IActivity) => {
        const title = activity.activity_title;
        const createdDate = moment(activity.created_at)
            .tz(timezone)
            .format('ddd, MMM D, YYYY h:mm a');
        const description = activity.activity_description;
        let iconColor = '#222';
        let titleColor = '#222';
        switch (activity.activity_type) {
            case ActivityTypeEnum.APPOINTMENT_BOOKED:
                if (activity.activity_extended_props.is_paid) {
                    iconColor = '#2cc050';
                }
                break;
            case ActivityTypeEnum.APPOINTMENT_MARKED_PAID:
            case ActivityTypeEnum.APPOINTMENT_PAID:
                iconColor = '#2cc050';
                break;
            case ActivityTypeEnum.APPOINTMENT_CANCELLED:
                iconColor = titleColor = 'rgb(235, 83, 83)';
                break;
            case ActivityTypeEnum.APPOINTMENT_DELETED:
                iconColor = '#FF9400';
                break;
            default:
                break;
        }
        return (
            <Container>
                <Row>
                    <ListItemCol lg={12}>
                        <ListItemHeader>
                            {getListItemIcon(activity, iconColor)}
                            <ListItemTitle style={{ color: titleColor }}>{title}</ListItemTitle>
                        </ListItemHeader>
                        <ListItemDate>Occurred: {createdDate}</ListItemDate>
                        <ListItemDescription>{description}</ListItemDescription>
                    </ListItemCol>
                </Row>
            </Container>
        );
    };

    const getListItemIcon = (activity: IActivity, iconColor: string) => {
        if (
            (activity.activity_type === ActivityTypeEnum.APPOINTMENT_BOOKED &&
                activity.activity_extended_props.is_paid) ||
            activity.activity_type === ActivityTypeEnum.APPOINTMENT_MARKED_PAID ||
            activity.activity_type === ActivityTypeEnum.APPOINTMENT_PAID
        ) {
            return (
                <IconContext.Provider value={{ size: '1.05em' }}>
                    <AiFillDollarCircle color={iconColor} />
                </IconContext.Provider>
            );
        } else if (activity.activity_type === ActivityTypeEnum.APPOINTMENT_CANCELLED) {
            return <FaMinusCircle color={iconColor} />;
        } else if (activity.activity_type === ActivityTypeEnum.APPOINTMENT_DELETED) {
            return <FaTimesCircle color={iconColor} />;
        }
        return <i className="far fa-circle" style={{ color: iconColor }}></i>;
    };

    /*
     * Utility Functions
     */

    const toTitleCase = (str: string) => {
        return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    };

    return (
        <Wrapper>
            {loading && <LoaderOverlay />}
            <CustomRow>
                <CustomCol lg={12}>
                    <HeadingWrapper></HeadingWrapper>
                    <QueryControlSection>
                        <SearchField
                            value={searchFieldValue}
                            handleChange={handleSearchFieldChange}
                            name={'Activities'}
                            customPadding={'0 24px 0 0'}
                        />
                        <Select
                            options={getActivityTypeOptions()}
                            value={getActivityTypeOptions()[0].label}
                            onChange={(val: string) =>
                                setSelectedActivityType(val as ActivityTypeEnum)
                            }
                        />
                    </QueryControlSection>
                    <ActivityList>
                        {activitySearchResults.length ? (
                            activitySearchResults.map((activity: any) => {
                                return <p>{listItem(activity)}</p>;
                            })
                        ) : (
                            <NotFound>No activities found matching the search criteria.</NotFound>
                        )}
                    </ActivityList>
                    {activitySearchResponse &&
                        (activitySearchResponse.total > (pageNumber + 1) * pageSize || loading) && (
                            <LoadMore>
                                <Button
                                    bgtype={'secondary'}
                                    ifClicked={loadMoreActivities}
                                    disabled={loading}
                                    label={`Load more activities (${
                                        activitySearchResponse.total - (pageNumber + 1) * pageSize
                                    })`}></Button>
                            </LoadMore>
                        )}
                </CustomCol>
            </CustomRow>
        </Wrapper>
    );
};

const Wrapper = styled(Container)`
    width: 100%;
    position: relative;
    height: 100%;
    ${media.xs`
        display: block !important;
        overflow: hidden;
    `}
    ${media.sm`
        display: block !important;
    `}
    ${media.md`
        display: flex !important;
        margin: 0;
        min-width: 100%;
    `}
    ${media.lg`
        display: flex !important;
    `};
`;
const CustomRow = styled(Row)`
    position: relative;
    margin: 0;
    background-color: white;
    width: 100%;
`;
const CustomCol = styled(Col)`
    padding: 0;
`;
const HeadingWrapper = styled.div`
    padding: 1.42rem 0rem 0.85rem 1.71rem;
    display: flex;
    align-items: center;
    justify-content: start;
`;
const QueryControlSection = styled(Container)`
    padding: 0.5rem 24px 0.5rem 24px;
    display: flex;
    justify-content: start;
    align-items: baseline;
`;
const ActivityList = styled.div`
    margin-top: 1rem;
    overflow: auto;
    ${media.xs`
        height: 30rem;
    `}
    ${media.sm`
        height: 36rem;
    `}
    ${media.md`
        height: 42rem;
    `}
    ${media.lg`
        height: 42rem;
    `}
    ${media.xl`
        height: 42rem;
    `}
    ${media.xxl`
        height: 42rem;
    `}
`;
const ListItemCol = styled(Col)`
    padding-left: 1.71rem;
    padding-right: 1.71rem;
`;
const ListItemHeader = styled.div`
    width: 100%;
    padding-top: 0.85rem;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-bottom: 3px;
`;
const ListItemTitle = styled.h1`
    font-weight: 700;
    margin-left: 0.7rem;
`;
const ListItemDate = styled.div`
    font-size: 0.85rem;
    font-weight: 500;
    padding-bottom: 3px;
    color: #99acc2;
`;
const ListItemDescription = styled.div`
    font-size: 0.98rem;
    font-weight: 400;
    color: rgb(70 64 64);
    padding-bottom: 0.85rem;
    border-bottom: 0.1rem solid ${palette.grey.lightest};
`;
const LoadMore = styled.div`
    margin-top: 0.5rem;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    ${media.xs`
	    margin-bottom: 0.5rem;
	`}
`;
const NotFound = styled.div`
    display: flex;
    justify-content: center;
`;

ActivityFeed.Layout = Layout;
export default ActivityFeed;
