import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from '@components/common';
import styled from 'styled-components';

const NotFound = () => {
    const navigate = useNavigate();
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const goBack = () => {
        if (_userData?.user?.permissions?.dashboard) {
            navigate('/');
        } else if (_userData?.user?.permissions?.calendar) {
            navigate('/calendar');
        } else if (_userData?.user?.permissions?.sales) {
            navigate('/sales');
        } else if (_userData?.user?.permissions?.team) {
            navigate('/team');
        } else if (_userData?.user?.permissions?.customers) {
            navigate('/customers');
        } else if (_userData?.user?.permissions?.business) {
            navigate('/settings');
        } else {
            navigate('/login');
        }
    };
    return (
        <Page>
            <Error>404</Error>
            <Error>Page Not Found</Error>
            <Button onClick={() => goBack()}>Go Back</Button>
        </Page>
    );
};

NotFound.Layout = Layout;

const Page = styled.div`
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;
const Error = styled.h1``;
const Button = styled.a`
    cursor: pointer;
`;
export default NotFound;
