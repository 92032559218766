import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
export interface SwitchProps {
    name?: string;
    value?: boolean;
    className?: string;
    disabled?: boolean;
    onChange?(value: boolean): void;
}
const Switch = ({ name, value, className, disabled, onChange, ...props }: SwitchProps) => {
    const [switchValue, setSwitchValue] = useState(value);
    useEffect(() => {
        setSwitchValue(value);
    }, [value]);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : false;
        setSwitchValue(value);
        if (onChange) onChange(value);
    };
    return (
        <SwitchContainer disabled={disabled}>
            <SwitchInput
                disabled={disabled}
                type="checkbox"
                name={name}
                onChange={event => handleChange(event)}
                checked={switchValue}
            />
            <SwitchCore></SwitchCore>
            {switchValue ? <On>On</On> : <Off>Off</Off>}
        </SwitchContainer>
    );
};
const SwitchContainer = styled.label<SwitchProps>`
    width: 58px;
    height: 24px;
    display: inline-block;
    position: relative;
    overflow: hidden;
    vertical-align: middle;
    font-size: 11px;
    cursor: pointer;
    margin: 2px;
    align-items: center;
    opacity: ${({ disabled }) => (disabled ? `0.5` : '1')};
    cursor: ${({ disabled }) => (disabled ? `no-drop` : 'pointer')};
`;
const SwitchCore = styled.div`
    background-color: #000;
    width: 58px;
    height: 24px;
    margin: 0;
    display: inline-block;
    position: relative;
    outline: 0;
    box-sizing: border-box;
    transition: border-color 0.3s, background-color 0.3s;
    user-select: none;
    border-radius: 999px;
    &::before {
        width: 19px;
        height: 19px;
        display: block;
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        z-index: 20;
        transform: translate(3px, 3px);
        transition: transform 0.3s;
        border-radius: 100%;
        background-color: #fff;
        content: '';
    }
`;
const SwitchInput = styled.input`
    position: absolute;
    left: 0;
    opacity: 0;
    outline: none;
    z-index: -1;
    &:checked + ${SwitchCore} {
        background-color: #49C496;
            &::before {
                transform: translate(37px, 3px);
            }
        }
    }
`;
const On = styled.span`
    text-transform: capitalize;
    font-weight: 700;
    color: white;
    position: absolute;
    top: 0;
    height: 24px;
    line-height: 25px;
    left: 0.65rem;
`;
const Off = styled.span`
    text-transform: capitalize;
    font-weight: 700;
    color: white;
    position: absolute;
    top: 0;
    height: 24px;
    line-height: 25px;
    right: 0.65rem;
`;
export default Switch;
