var _user = JSON.parse(localStorage.getItem('user') || '{}');
export const routes = [
    {
        path: '/',
        activeLink: '/',
        title: 'Dashboard',
        icon: 'far fa-chart-line',
    },
    {
        path: '/activity',
        activeLink: '/activity',
        title: 'Activity',
        icon: 'far fa-clipboard',
    },
    {
        path: '/calendar',
        activeLink: '/calendar',
        title: 'Calendar',
        icon: 'far fa-calendar-week',
    },
    // {
    //     path: '/sales',
    //     activeLink: '/sales',
    //     title: 'Sales',
    //     icon: 'far fa-receipt',
    // },
    {
        path: '/admin',
        activeLink: '/admin',
        title: 'Admin',
        icon: 'far fa-user',
    },
    {
        path: '/profile',
        activeLink: '/profile',
        title: 'Profile',
        icon: 'far fa-user-friends',
    },
    {
        path: '/team',
        activeLink: '/team',
        title: 'Team',
        icon: 'far fa-user-friends',
        patterns: [
            {
                path: '/team/service-provider-list',
                activeLink: '/team',
                title: 'Team',
                icon: 'far user-friends',
            },
            {
                path: '/team/other-list',
                activeLink: '/team',
                title: 'Team',
                icon: 'far user-friends',
            },
            {
                path: '/team/team-list',
                activeLink: '/team',
                title: 'Team',
                icon: 'far user-friends',
            },
            {
                path: '/team/other/create',
                activeLink: '/team',
                title: 'Team',
                icon: 'far user-friends',
            },
            {
                path: '/team/service-provider/create',
                activeLink: '/team',
                title: 'Team',
                icon: 'far user-friends',
            },
            {
                path: '/team/team-member/create',
                activeLink: '/team',
                title: 'Team',
                icon: 'far user-friends',
            },
        ],
    },
    {
        path: '/customers',
        activeLink: '/customers',
        title: 'Customers',
        icon: 'far fa-address-book',
        patterns: [
            {
                path: '/customers/create',
                activeLink: '/customers',
                title: 'Create Customers',
                icon: 'far fa-address-book',
            },
            // {
            // 	path: "/customers/",
            //  activeLink: '/customers',
            // 	title: "Detail",
            // 	icon: "far fa-user",
            // },
        ],
    },
    {
        path: '/settings',
        activeLink: '/settings',
        title: 'Business Settings',
        icon: 'far fa-cogs',
        patterns: [
            {
                path: '/settings/details',
                activeLink: '/settings',
                title: 'Business Settings',
                icon: 'far fa-cogs',
            },
            {
                path: '/settings/business-hours',
                activeLink: '/settings',
                title: 'Business Hours',
                icon: 'far fa-cogs',
            },
            {
                path: '/settings/location',
                activeLink: '/settings',
                title: 'Location',
                icon: 'far fa-cogs',
            },
            {
                path: '/settings/booking-settings',
                activeLink: '/settings',
                title: 'Booking Settings',
                icon: 'far fa-cogs',
            },
            {
                path: '/settings/services',
                activeLink: '/settings',
                title: 'Services',
                icon: 'far fa-cogs',
            },
            {
                path: '/settings/services/create',
                activeLink: '/settings',
                title: 'Services',
                icon: 'far fa-cogs',
            },
            {
                path: '/settings/billing',
                activeLink: '/settings',
                title: 'Subscription & Billing',
                icon: 'far fa-cogs',
            },
            {
                path: '/settings/online-booking',
                activeLink: '/settings',
                title: 'Online Booking',
                icon: 'far fa-cogs',
            },
        ],
    },
];
export const customerRoute = [
    {
        path: `/customers/${_user?.user?._id}`,
        activeLink: `/customers/${_user?.user?._id}`,
        title: 'Customers',
        icon: 'far fa-address-book',
    },
];
