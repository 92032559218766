import React from 'react';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';
export interface SearchProps {
    value?: string;
    handleChange?: any;
    name?: string;
    customPadding?: string;
}
const SearchField = ({ value, handleChange, name, customPadding }: SearchProps) => {
    return (
        <Container>
            <Search style={customPadding ? { padding: customPadding } : { padding: '0 24px' }}>
                <SearchWrapper md={12} lg={12}>
                    <SearchIcon>
                        <i className="fal fa-search"></i>
                    </SearchIcon>
                    <Input
                        type="text"
                        value={value ?? ''}
                        onChange={handleChange}
                        placeholder={`Search ${name}...`}
                    />
                </SearchWrapper>
            </Search>
        </Container>
    );
};

const Search = styled(Row)`
    margin-top: 1rem;
`;
const SearchWrapper = styled(Col)`
    position: relative;
    padding: 0;
`;
const SearchIcon = styled.span`
    position: absolute;
    left: 1.25rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.25rem;
    color: #b0b3bf;
`;
const Input = styled.input`
    display: block;
    width: 100%;
    padding: 0.75rem 1rem;
    font-weight: 500;
    line-height: 1.5;
    background-color: #f4f5f6;
    border: 1px solid #f4f5f6;
    appearance: none;
    border-radius: 0.475rem;
    outline: none;
    padding-left: 3.25rem;
    &::placeholder {
        font-weight: 400;
        color: #b0b3bf;
    }
    &:focus {
        background-color: #ebeff3;
        border-color: #ebeff3;
        color: #3a3b3f;
    }
`;
export default SearchField;
