import React, { useCallback, useMemo, useState } from 'react';
import { CSSTransition, TransitionGroup, SwitchTransition } from 'react-transition-group';

import chipImg from 'images/cards/chip.png';
import amexImg from 'images/cards/amex.png';
import visaImg from 'images/cards/visa.png';
import mastercardImg from 'images/cards/mastercard.png';

import cardImg1 from 'images/cards/card-1.jpeg';
import cardImg2 from 'images/cards/card-2.jpeg';
import cardImg3 from 'images/cards/card-3.jpeg';
import cardImg4 from 'images/cards/card-4.jpeg';
import cardImg5 from 'images/cards/card-5.jpeg';

import './styles.scss';

export type CardTypes =
    | 'visa'
    | 'amex'
    | 'mastercard'
    | 'discover'
    | 'unionpay'
    | 'troy'
    | 'diners';

const cardBackgroundImages = [cardImg1, cardImg2, cardImg3, cardImg4, cardImg5];

const cardTypeImages = {
    visa: visaImg,
    amex: amexImg,
    mastercard: mastercardImg,
    discover: '',
    unionpay: '',
    troy: '',
    diners: '',
};

const CARDS = {
    visa: '^4',
    amex: '^(34|37)',
    mastercard: '^5[1-5]',
    discover: '^6011',
    unionpay: '^62',
    troy: '^9792',
    diners: '^(30[0-5]|36)',
};

const cardType = (cardNumber: string) => {
    const number = cardNumber;
    let re;

    for (const [card, pattern] of Object.entries(CARDS)) {
        re = new RegExp(pattern);
        if (number.match(re) != null) {
            return card;
        }
    }

    return 'visa';
};

const outlineElementStyle = (element: HTMLElement) => {
    return element
        ? {
              width: `${element.offsetWidth}px`,
              height: `${element.offsetHeight}px`,
              transform: `translateX(${element.offsetLeft}px) translateY(${element.offsetTop}px)`,
          }
        : null;
};

interface CardProps {
    cardHolder: string;
    cardNumber: string;
    cardMonth: string;
    cardYear: string;
    cardCVV: string;
    currentFocusedEl: any;
    onCardElClick(el: string): any;
    isCardFlipped: boolean;
    cardNumberRef: any;
    cardHolderRef: any;
    cardDateRef: any;
}

const cardBackgroundName = () => {
    let random = Math.floor(Math.random() * cardBackgroundImages.length);
    return cardBackgroundImages[random];
};

const BACKGROUND_IMG = cardBackgroundName();
const cardPlaceholder = '#### #### #### ####';

const Card = ({
    cardHolder,
    cardNumber,
    cardMonth,
    cardYear,
    cardCVV,
    currentFocusedEl,
    onCardElClick,
    isCardFlipped,
    cardNumberRef,
    cardHolderRef,
    cardDateRef,
}: CardProps) => {
    const [style, setStyle] = useState();

    const useCardType = useMemo(() => {
        return cardTypeImages[cardType(cardNumber) as CardTypes];
    }, [cardNumber]);

    const isCardNumberMasked = useCallback(
        (index: number, n: string) => {
            return index > 4 && index < 14 && cardNumber.length > index && n.trim() !== '';
        },
        [cardNumber]
    );

    return (
        <div className={'card-item ' + (isCardFlipped ? '-active' : '')}>
            <div className="card-item__side -front">
                <div
                    className={`card-item__focus ${currentFocusedEl ? `-active` : ``}`}
                    style={style}
                />
                <div className="card-item__cover">
                    <img alt="" src={BACKGROUND_IMG} className="card-item__bg" />
                </div>

                <div className="card-item__wrapper">
                    <div className="card-item__top">
                        <img src={chipImg} alt="" className="card-item__chip" />
                        <div className="card-item__type">
                            <img alt="" src={useCardType} className="card-item__typeImg" />
                        </div>
                    </div>

                    <label
                        className="card-item__number"
                        ref={cardNumberRef}
                        onClick={() => onCardElClick('cardNumber')}>
                        {cardPlaceholder.split('').map((val, index) => {
                            if ([4, 9, 14].includes(index)) {
                                return <div className="card-item__numberItem" key={index}></div>;
                            }

                            return (
                                <SwitchTransition key={`card-number-${index}`}>
                                    <CSSTransition
                                        classNames="slide-fade-up"
                                        key={index < cardNumber.length ? cardNumber[index] : '#'}
                                        addEndListener={(node, done) =>
                                            node.addEventListener('transitionend', done, false)
                                        }>
                                        <div className="card-item__numberItem">
                                            {isCardNumberMasked(index, val)
                                                ? '*'
                                                : [
                                                      cardNumber.length > index
                                                          ? cardNumber.split('')[index]
                                                          : val,
                                                  ][0]}
                                        </div>
                                    </CSSTransition>
                                </SwitchTransition>
                            );
                        })}
                    </label>
                    <div className="card-item__content">
                        <label
                            className="card-item__info"
                            onClick={() => onCardElClick('cardHolder')}
                            ref={cardHolderRef}>
                            <div className="card-item__holder">Card Holder</div>
                            <div className="card-item__name">
                                <TransitionGroup component="div" className="slide-fade-up">
                                    {cardHolder === 'FULL NAME' ? (
                                        <CSSTransition classNames="slide-fade-up" timeout={250}>
                                            <div>FULL NAME</div>
                                        </CSSTransition>
                                    ) : (
                                        cardHolder.split('').map((val, index) => (
                                            <CSSTransition
                                                timeout={250}
                                                classNames="slide-fade-right"
                                                key={index}>
                                                <span className="card-item__nameItem">{val}</span>
                                            </CSSTransition>
                                        ))
                                    )}
                                </TransitionGroup>
                            </div>
                        </label>
                        <div
                            className="card-item__date"
                            onClick={() => onCardElClick('cardDate')}
                            ref={cardDateRef}>
                            <label className="card-item__dateTitle">Expires</label>
                            <div className="card-item__dateItems">
                                <label className="card-item__dateItem">
                                    <SwitchTransition in-out>
                                        <CSSTransition
                                            classNames="slide-fade-up"
                                            timeout={200}
                                            key={cardMonth}>
                                            <span>{!cardMonth ? 'MM' : cardMonth} </span>
                                        </CSSTransition>
                                    </SwitchTransition>
                                </label>
                                /
                                <label htmlFor="cardYear" className="card-item__dateItem">
                                    <SwitchTransition out-in>
                                        <CSSTransition
                                            classNames="slide-fade-up"
                                            timeout={250}
                                            key={cardYear}>
                                            <span>
                                                {!cardYear ? 'YY' : cardYear.toString().substr(-2)}
                                            </span>
                                        </CSSTransition>
                                    </SwitchTransition>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card-item__side -back">
                <div className="card-item__cover">
                    <img alt="" src={BACKGROUND_IMG} className="card-item__bg" />
                </div>
                <div className="card-item__band" />
                <div className="card-item__cvv">
                    <div className="card-item__cvvTitle">CVV</div>
                    <div className="card-item__cvvBand">
                        <TransitionGroup>
                            {cardCVV.split('').map((val, index) => (
                                <CSSTransition classNames="zoom-in-out" key={index} timeout={250}>
                                    <span>*</span>
                                </CSSTransition>
                            ))}
                        </TransitionGroup>
                    </div>
                    <div className="card-item__type">
                        <img alt="card-type" src={useCardType} className="card-item__typeImg" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card;
