import { useState } from 'react';
import Input from '../../../common/Input/Input';
import Select from '../../../common/Select/Select';

import '../../../common/Select/Select.scss';

interface CardFormProps {
    cardMonth: string;
    cardYear: string;
    onUpdateState(state: string, value: any): void;
    cardNumberRef: any;
    cardHolderRef: any;
    cardDateRef: any;
    isReserveAndPayEnabled: boolean;
    loading?: any;
}

const currentYear = new Date().getFullYear();
const monthsArr = Array.from({ length: 12 }, (x, i) => {
    const month: any = i + 1;
    return month <= 9 ? '0' + month : month;
});
const yearsArr = Array.from({ length: 9 }, (_x, i) => currentYear + i);

const CardForm = ({
    cardMonth,
    cardYear,
    onUpdateState,
    cardNumberRef,
    cardHolderRef,
    cardDateRef,
    isReserveAndPayEnabled,
    loading,
}: CardFormProps) => {
    const [cardNumber, setCardNumber] = useState('');
    const handleFormChange = ({ name, value }: { name: string; value: any }) => {
        onUpdateState(name, value);
    };

    const onCardNumberChange = (name: string, value: string) => {
        let cardNumber = value;
        value = value.replace(/\D/g, '');
        if (/^3[47]\d{0,13}$/.test(value)) {
            cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ');
        } else if (/^3(?:0[0-5]|[68]\d)\d{0,11}$/.test(value)) {
            // diner's club, 14 digits
            cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ');
        } else if (/^\d{0,16}$/.test(value)) {
            // regular cc number, 16 digits
            cardNumber = value
                .replace(/(\d{4})/, '$1 ')
                .replace(/(\d{4}) (\d{4})/, '$1 $2 ')
                .replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ');
        }

        setCardNumber(cardNumber.trimRight());
        onUpdateState(name, cardNumber);
    };

    return (
        <div className="card-form mt-10">
            <p
                style={{
                    paddingLeft: '4px',
                    paddingBottom: '10px',
                    fontSize: '14px',
                    color: '#5e5e5e',
                }}>
                A credit card is required to book your appointment.
                {isReserveAndPayEnabled && ' Save time by reserving and paying ahead of time.'}
            </p>
            <div className="userDetails-group">
                <Input
                    label="Card Number"
                    value={cardNumber}
                    maxLength={19}
                    readOnly={loading}
                    name="cardNumber"
                    type="tel"
                    inputMode="numeric"
                    allowNumber={true}
                    onChange={({ name, value }: { name: string; value: string }) => {
                        onCardNumberChange(name, value);
                    }}
                />
            </div>
            <div className="userDetails-group">
                <Input
                    label="Name"
                    name="cardHolder"
                    readOnly={loading}
                    onChange={handleFormChange}
                />
            </div>

            <div className="card-form_gridDetails">
                <div className="userDetails-group card-form_grid--exp">
                    <Select
                        label="MM"
                        maxLength={2}
                        onChange={(value: string) => {
                            onUpdateState('cardMonth', value);
                        }}
                        readOnly={loading}
                        allowNumber={true}
                        disabled={true}
                        customHeight={'146px'}
                        options={monthsArr.map(val => {
                            return {
                                label: val.toString(),
                            };
                        })}
                    />

                    <Select
                        label="YYYY"
                        maxLength={4}
                        readOnly={loading}
                        allowNumber={true}
                        disabled={true}
                        onChange={(value: string) => {
                            onUpdateState('cardYear', value);
                        }}
                        customHeight={'146px'}
                        options={yearsArr.map(val => {
                            return {
                                label: val.toString(),
                            };
                        })}
                    />
                </div>

                <div className="userDetails-group">
                    <Input
                        label="CVV"
                        name="cardCVV"
                        maxLength={4}
                        readOnly={loading}
                        allowPhoneNumberOnly={true}
                        type="tel"
                        inputMode="numeric"
                        onFocus={() => onUpdateState('isCardFlipped', true)}
                        onBlur={() => onUpdateState('isCardFlipped', false)}
                        onChange={handleFormChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default CardForm;
