import { useState, useEffect } from 'react';
import { AppointmentStatusEnum, IAppointment } from 'interfaces/appointment.interface';
import moment from 'moment';

import as from 'components/appointments/Appointment.module.scss';
import { Spinner } from '@components/common';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { api } from 'helpers/auth-axios';

const getAppointmentLabel = (status: AppointmentStatusEnum) => {
    switch (status) {
        case AppointmentStatusEnum.CONFIRMED:
        case AppointmentStatusEnum.COMPLETED:
            return as.appointment_statusSuccess;
        case AppointmentStatusEnum.LATE:
            return as.appointment_statusWarning;
        case AppointmentStatusEnum.CANCELLED:
            return as.appointment_statusDanger;
        default:
            return as.appointment_statusGray;
    }
};

const PastBookings = ({
    customerId,
    onInitialLoad,
}: {
    customerId: string;
    onInitialLoad(total: any): void;
}) => {
    const pageSize = 20;
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [bookings, setBookings] = useState<IAppointment[]>([]);
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    useEffect(() => {
        setPage(0);
        setBookings([]);
        api.get(
            `/appointments/customers/${customerId}?page=${0}&pageSize=${pageSize}&status=done`
        ).then((res: any) => {
            setBookings(res.data.bookings);
            onInitialLoad(res.data.total ?? 0);
            setTotal(res.data.total ?? 0);
        });
    }, [customerId]);

    const loadMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    useEffect(() => {
        if (page === 0) return;

        setLoading(true);
        api.get(
            `/appointments/customers/${customerId}?page=${page}&pageSize=${pageSize}&status=upcoming`
        ).then((res: any) => {
            setLoading(false);
            setBookings(prevBookings => [...prevBookings, ...res.data.bookings]);
            setTotal(res.data.total ?? 0);
            onInitialLoad(res.data.total ?? 0);
        });
    }, [page]);
    // let today = new Date();
    return (
        <>
            <Container>
                <Row>
                    {' '}
                    {bookings &&
                        bookings.map((booking, i: number) => (
                            <Wrapper lg={12} key={i}>
                                <AppointmentDate>
                                    <AppointmentMonth>
                                        {moment(booking.booked_from_iso).format('MMM')}
                                    </AppointmentMonth>
                                    <Date>{moment(booking.booked_from_iso).format('DD')}</Date>
                                    <AppointmentMonth>
                                        {moment(booking.booked_from_iso).format(
                                            _userData.user.business_id.time_select === '12'
                                                ? 'hh:mm a'
                                                : 'HH:mm'
                                        )}
                                    </AppointmentMonth>
                                </AppointmentDate>
                                <Hr
                                    className={`service-color-${booking.service?.color}--bg-2`}></Hr>
                                <AppointmentContent>
                                    <AppointmentLabel>
                                        <div
                                            className={`${
                                                as.appointment_statusLabel
                                            } ${getAppointmentLabel(booking.status)}`}>
                                            {booking.status}
                                        </div>
                                    </AppointmentLabel>
                                    {booking?.service?.name ? (
                                        <AppointmentName>{booking?.service?.name}</AppointmentName>
                                    ) : (
                                        <AppointmentName>service removed </AppointmentName>
                                    )}

                                    <AppointmentProvider>
                                        Service by{' '}
                                        <AppointmentProviderName>
                                            {booking?.serviceProvider?.name}
                                        </AppointmentProviderName>
                                    </AppointmentProvider>
                                    <AppointmentProvider>
                                        {' '}
                                        {booking?.payment == null ? 'unpaid' : 'paid'}
                                    </AppointmentProvider>
                                </AppointmentContent>
                            </Wrapper>
                        ))}
                    {(total > (page + 1) * pageSize || loading) && (
                        <AppointmentPages>
                            <Button disabled={loading} onClick={loadMore}>
                                {loading ? (
                                    <Spinner color="secondary" />
                                ) : (
                                    `Load more appointments (${total - (page + 1) * pageSize})`
                                )}
                            </Button>
                        </AppointmentPages>
                    )}
                </Row>
            </Container>
        </>
    );
};
const Wrapper = styled(Col)`
    display: flex;
    padding: 0rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom-width: 1px;
`;
const AppointmentDate = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 0.875rem;
    line-height: 1.25rem;
    width: 5rem;
`;
const AppointmentMonth = styled.div`
    color: rgba(103, 113, 126);
`;
const Date = styled.div`
    font-size: 1.5rem /* 24px */;
    line-height: 2rem /* 32px */;
    padding-top: 0.25rem /* 4px */;
    padding-bottom: 0.25rem /* 4px */;
`;

const Hr = styled.div`
    border-radius: 0.25rem /* 4px */;
    width: 0.28rem;
`;
const AppointmentContent = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 1rem;
`;
const AppointmentLabel = styled.div`
    display: flex;
`;
const AppointmentName = styled.div`
    font-weight: 500;
    font-size: 0.9rem;
`;
const AppointmentProvider = styled.div`
    font-size: 0.75rem /* 12px */;
    line-height: 1rem /* 16px */;
    color: rgba(181, 181, 195);
`;

const AppointmentProviderName = styled.span`
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

const IconBody = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1 1 0%;
`;
const CustomLink = styled(Link)`
    border-radius: 0.25rem /* 4px */;
    width: 2.5rem /* 40px */;
    height: 2.5rem /* 40px */;
    color: rgba(73, 196, 150);
    color: rgba(73, 196, 150);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(244, 245, 246);
    &:hover {
        background-color: rgba(237, 238, 241);
        transition-property: background-color, border-color, color, fill, stroke, opacity,
            box-shadow, transform, filter, backdrop-filter;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;
const AppointmentPages = styled.div`
    display: flex;
    justify-content: center;
`;
const Button = styled.button`
    padding-top: 0.5rem /* 8px */;
    padding-bottom: 0.5rem /* 8px */;
    padding-left: 2rem /* 32px */;
    padding-right: 2rem /* 32px */;
    font-weight: 500;
    font-size: 0.9rem /* 14.4px */;
`;
export default PastBookings;
