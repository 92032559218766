import { useDropdownContext } from '../Dropdown/DropdownContext';

const DropdownMenu = ({
    className,
    scrollable,
    children,
}: {
    className?: string;
    scrollable?: boolean;
    children?: React.ReactNode;
}) => {
    const context = useDropdownContext();

    return (
        context.value.isOpen && (
            <div className={`dropdown--menu ${className ?? ''}`} role="menu" tabIndex={-1}>
                {scrollable ? <div className="dropdown--scrollable">{children}</div> : children}
            </div>
        )
    );
};

export default DropdownMenu;
