import logo from '../../../images/logo-white-font.png';
import { Col } from 'styled-bootstrap-grid';
import styled from 'styled-components';

const BrandInfo = () => {
    return (
        <>
            <BrandContent lg={12}>
                <Logo src={logo} alt="Logo" />
            </BrandContent>
        </>
    );
};

const BrandContent = styled(Col)`
    padding: 0.8rem 0rem 0.8rem 0.8rem;
    display: flex;
    align-items: center;
    justify-content: start;
    cursor: pointer;
`;
const Logo = styled.img`
    width: 90%;
`;
const Header = styled.h1`
    font-weight: 700 !important;
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
    margin-left: 0.5rem;
`;
export default BrandInfo;
