import React from 'react';
import { Layout } from '@components/customers';
import { EditCustomer as CustomerEdit } from '@components/customers';

const EditCustomer = () => {
    return <CustomerEdit />;
};

EditCustomer.Layout = Layout;
export default EditCustomer;
