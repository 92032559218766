import React, { useCallback, useEffect, useState } from 'react';
import Compressor from 'compressorjs';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';

import Button from '@components/Button';
import { Input, Select, useSnackbar } from '@components/common';
import ProfileImageCropperModal from '@components/common/ProfileImageCropperModal';
import { api } from 'helpers/auth-axios';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from 'hooks/redux';
import useRouter from 'hooks/router';
import planImg from 'images/illustrations/plan.svg';
import { IService } from 'interfaces/service.interface';
import { IServiceProvider } from 'interfaces/team/serviceProvider.interface';
import SelectServices from '../../SelectServices/SelectServices';

const validationSchema = Yup.object().shape({
    name: Yup.string().required('This field is required'),
    email: Yup.string().required('This field is required'),
    phone: Yup.string().required('This field is required'),
    password: Yup.string().required('This field is required'),
});

const CreateServiceProvider = () => {
    const [openSnackbar] = useSnackbar();
    const router = useRouter();
    const [showCropperModal, setShowCropperModal] = useState<boolean>(false);
    const [selectedImage, setSelectedImage] = useState<any>(); // before cropping
    const [profileImage, setProfileImage] = useState<any>(null); // after cropping
    const [loading, setLoading] = useState<any>(false);
    const { handleSubmit, setValue, getValues, formState, trigger } = useForm<IServiceProvider>({
        resolver: yupResolver(validationSchema),
    });
    const { errors, isSubmitting } = formState;

    const [showSelect, setShowSelect] = useState(false);
    const [assignedServices, setAssignedServices] = useState<IService[]>([]);
    async function onChange({ name, value }: { name: string; value: string }) {
        if (name === 'phone') {
            let phone = value;
            if (phone.length <= 3) {
                phone = phone.replace(/[- .]/g, '');
                // phone = phone.slice(0, 3) + '-';
            } else if (phone.length <= 7) {
                phone = phone.replace(/[- .]/g, '');
                phone = phone.slice(0, 3) + '-' + phone.slice(3, 6);
            } else if (phone.length >= 7) {
                phone = phone.replace(/[- .]/g, '');
                phone = phone.slice(0, 3) + '-' + phone.slice(3, 6) + '-' + phone.slice(6);
            }
            setValue(name as never, phone as never);
            await trigger(name as never);
        } else if (name === 'email') {
            setValue(name as never, value?.toLowerCase() as never);
            await trigger(name as never);
        } else {
            setValue(name as never, value as never);
            await trigger(name as never);
        }
    }

    const onSubmit = (data: IServiceProvider) => {
        setLoading(true);
        api.post('/serviceProvider', {
            ...data,
            photo: profileImage,
            services: assignedServices.map(service => {
                return {
                    price: service.price,
                    service_id: service._id,
                    my_price: service.my_price,
                    my_duration: service.my_duration,
                    duration: service.duration,
                    name: service.name,
                    color: service.color,
                    available_for_boooking: service.available_for_boooking,
                    vip_price: service.price,
                };
            }),
        })
            .then((res: any) => {
                setLoading(false);

                if (res.error) {
                    openSnackbar('Failed to add ServiceProvider');
                } else {
                    openSnackbar('ServiceProvider added successfully');
                    router.navigate(`/team/service-provider-list/${res.data._id}`);
                    return res.data;
                }
            })
            .catch((e: any) => {
                setLoading(false);
                openSnackbar(e?.response?.data?.message);
            });
    };
    useEffect(() => {
        setTimeout(() => {
            setValue('name', '');
            setValue('email', '');
            setValue('phone', '');
            setValue('password', '');
        });
    }, [setValue]);
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const data = assignedServices.map(service => {
        return {
            price: service.price,
            service_id: service._id,
            my_price: service.my_price,
            my_duration: service.my_duration,
            duration: service.duration,
            name: service.name,
            color: service.color,
            available_for_boooking: service.available_for_boooking,
        };
    });

    const getNormalizedFile = (file: any) => {
        return new Promise((resolve, reject) => {
            new Compressor(file, {
                maxWidth: 750,
                maxHeight: 750,
                success(normalizedFile) {
                    resolve(normalizedFile);
                },
                error(error) {
                    reject(error);
                },
            });
        });
    };

    const readFile = useCallback((file: any) => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result);
                getNormalizedFile(file)
                    .then((normalizedFile: any) => reader.readAsDataURL(normalizedFile))
                    .catch((error: any) => reject(error));
            } catch (error) {
                reject(error);
            }
        });
    }, []);

    const handleCapture = async (e: any) => {
        try {
            e.preventDefault();
            if (e.target?.files?.length > 0) {
                const file = e.target.files[0];
                const imageDataUrl = await readFile(file);
                setSelectedImage(imageDataUrl);
                e.target.value = null;
                setShowCropperModal(true);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <MainContainer>
                <Row>
                    <CustomCol lg={12}>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <TitleContainer>
                                <TitleBody>
                                    <CustomLink to="/team/service-provider-list">
                                        <Icon className="fal fa-times"></Icon>
                                    </CustomLink>
                                    <Title>Add New Service Provider</Title>
                                </TitleBody>
                                <AddButton>
                                    <Button
                                        bgtype={'secondary'}
                                        type={'submit'}
                                        disabled={loading}
                                        label="Add"></Button>
                                </AddButton>
                            </TitleContainer>
                            <Card>
                                <FormContent>
                                    <Left>
                                        <InfoTitle>General Info</InfoTitle>
                                        <Circle>
                                            <CircleLabel
                                                htmlFor="faceImage"
                                                aria-label="upload picture">
                                                <Data src={profileImage}></Data>
                                            </CircleLabel>
                                            <FileInput
                                                accept="image/jpeg, image/png"
                                                id="faceImage"
                                                type="file"
                                                onChange={handleCapture}
                                            />
                                        </Circle>
                                        <InputField>
                                            <Input
                                                label="Name"
                                                name="name"
                                                value={getValues('name')}
                                                onChange={onChange}
                                                error={errors.name as any}
                                            />
                                        </InputField>
                                        <InputField>
                                            <Input
                                                label="Email"
                                                name="email"
                                                value={getValues('email')?.toLowerCase()}
                                                onChange={onChange}
                                                error={errors.email as any}
                                            />
                                        </InputField>
                                        <InputField>
                                            <Input
                                                allowPhoneNumberOnly={true}
                                                maxLength={12}
                                                label="Phone"
                                                name="phone"
                                                value={getValues('phone')}
                                                onChange={onChange}
                                                error={errors.phone as any}
                                            />
                                        </InputField>
                                        <InputField>
                                            <Input
                                                label="Password"
                                                type="password"
                                                name="password"
                                                value={getValues('password')}
                                                onChange={onChange}
                                                error={errors.password as any}
                                            />
                                        </InputField>
                                        <InputField>
                                            <Input
                                                label="Title"
                                                name="position"
                                                value={getValues('position')}
                                                onChange={onChange}
                                            />
                                        </InputField>
                                        <InputField>
                                            <Input
                                                label="Short Description"
                                                name="description"
                                                value={getValues('description')}
                                                onChange={onChange}
                                            />
                                        </InputField>
                                    </Left>
                                    <Right>
                                        <TabWrapper>
                                            <Tabs>
                                                <TabsData>Services</TabsData>
                                            </Tabs>
                                        </TabWrapper>
                                        <TabsContent>
                                            {assignedServices.length ? (
                                                <ServicesContainer>
                                                    <ServicesWrapper>
                                                        {assignedServices.map((service, i) => (
                                                            <Services
                                                                key={i}
                                                                className={`service-color-${service.color}--bg`}>
                                                                <Label>
                                                                    &nbsp;
                                                                    {
                                                                        _userData.user.business_id
                                                                            .currency
                                                                    }
                                                                    {service.price}
                                                                </Label>
                                                                <Name>{service.name}</Name>
                                                                <ServiceDuration>{`${
                                                                    service.duration >= 60
                                                                        ? Math.floor(
                                                                              service.duration / 60
                                                                          ) + 'h'
                                                                        : ''
                                                                } ${
                                                                    service.duration % 60
                                                                }min`}</ServiceDuration>
                                                            </Services>
                                                        ))}
                                                    </ServicesWrapper>
                                                    <EditButton>
                                                        <EditServiceButton
                                                            type="button"
                                                            onClick={() => setShowSelect(true)}>
                                                            Edit Services
                                                        </EditServiceButton>
                                                    </EditButton>
                                                </ServicesContainer>
                                            ) : (
                                                <AddService>
                                                    <Image src={planImg} alt="Plan" />
                                                    <Button
                                                        type={'button'}
                                                        bgtype={'secondary'}
                                                        disabled={isSubmitting}
                                                        ifClicked={() => setShowSelect(true)}
                                                        label="Assign services"></Button>
                                                </AddService>
                                            )}
                                        </TabsContent>
                                    </Right>
                                </FormContent>
                            </Card>
                        </Form>
                        {showSelect && (
                            <SelectServices
                                selectedServices={assignedServices}
                                modelStatus={'createservice'}
                                onClose={() => setShowSelect(false)}
                                onSelect={(services: IService[]) => setAssignedServices(services)}
                            />
                        )}
                    </CustomCol>
                </Row>
                <div style={{ position: 'absolute' }}>
                    <ProfileImageCropperModal
                        showModal={showCropperModal}
                        imgURL={selectedImage}
                        onModalClose={() => setShowCropperModal(false)}
                        onSaveHandler={(croppedImage: any) => setProfileImage(croppedImage)}
                    />
                </div>
            </MainContainer>
        </>
    );
};

const MainContainer = styled(Container)`
    width: 100%;
    padding: 1.71rem;
`;
const CustomCol = styled(Col)``;
const Form = styled.form``;

const TitleContainer = styled.div`
display: flex;
justify-content: space-between;
margin-bottom: 1.75rem
align-items: center;
`;

const TitleBody = styled.div`
    display: flex;
    align-items: center;
`;
const CustomLink = styled(Link)`
    margin-right: 1.5rem /* 24px */;
`;

const Icon = styled.i`
    font-size: 2.25rem /* 36px */;
    line-height: 2.5rem /* 40px */;
    ${media.xs`
    font-size: 1.5rem
    `}
`;

const Title = styled.div`
    font-size: 1.78rem;
    font-weight: 600;
    ${media.xs`
  font-size: 1.3rem
`}
`;
const AddButton = styled.div``;
const Card = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: border-box;
    border-radius: 0.42rem;
    box-shadow: 0rem 0rem 2.14rem 0rem rgb(82 63 105 / 5%);
    border: 0;
    margin-top: 1.5rem;
`;
const FormContent = styled.div`
    height: 100%;
    display: flex;
    ${media.xs`
display: block;
`}
    ${media.sm`
  display: block;
`}
  ${media.md`
  display: flex;
`}
${media.lg`
  display: flex;
`}
`;
const Left = styled.div`
    padding: 1.71rem;
    border-right: 1px solid #e5e7eb;
    flex-basis: 28.57rem;
`;
const Right = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const InfoTitle = styled.div`
    margin-bottom: 1rem /* 16px */;
    font-size: 1.125rem /* 18px */;
    line-height: 1.75rem /* 28px */;
    font-weight: 500;
`;
const Circle = styled.div`
    justify-content: center !important;
    display: flex !important;
    margin-bottom: 3.5rem /* 24px */;
`;
const CircleLabel = styled.label`
    display: inline-block;
    flex-shrink: 0;
    position: relative;
    border-radius: 0.42rem;
    width: 3.14rem;
    height: 3.14rem;
`;
const Data = styled.img`
    background: linear-gradient(-135deg, #eee, #dddfe1);
    color: #7e8299;
    width: 5.42rem;
    height: 5.42rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 0.42rem;
    font-size: 1.21rem;
    text-transform: capitalize !important;
    border-radius: 50%;
    cursor: pointer;
`;
const InputField = styled.div`
    margin-bottom: 1.71rem;
`;

const TabWrapper = styled.div`
    border-bottom: 0.07rem solid #e5e7eb;
    padding-left: 1.71rem;
    padding-right: 1.71rem;
`;

const Tabs = styled.ul`
    display: flex;
`;
const TabsData = styled.li`
    text-transform: capitalize;
    padding: 0 1.14rem;
    height: 3.42rem;
    display: flex;
    cursor: pointer;
    position: relative;
    font-size: 0.85rem;
    align-items: center;
    color: #9da0a4;
    font-weight: 500;
    color: #263446;
`;
const TabsContent = styled.div`
    padding: 1.71rem;
    flex: 1;
`;

const ServicesContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const ServicesWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem /* 16px */;
`;

const Services = styled.div`
    border-radius: 0.5rem;
    padding: 1.85rem 1.42rem;
    position: relative;
    cursor: pointer !important;
`;

const Label = styled.div`
    background-color: #00be70;
    padding: 0.14rem 0.42rem 0.14rem 0.14rem;
    display: inline-block;
    border-radius: 3.57rem;
    font-size: 0.85rem;
    font-weight: 500;
    color: #fff;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0.42rem;
    right: 0.42rem;
`;
const Name = styled.div`
    font-weight: 500;
    font-size: 1.2rem;
    width: 200px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;
const ServiceDuration = styled.div`
    font-size: 0.9rem /* 14.4px */;
`;

const EditButton = styled.div`
    margin-top: 2rem /* 32px */;
`;
const EditServiceButton = styled.button`
    padding: 0.92rem 1.85rem;
    font-weight: 600;
    text-transform: capitalize;
    color: #fff;
    background: #27292e;
    border-radius: 0.57rem;
    font-size: 0.92rem;
    color: rgba(38, 52, 70) !important;
    background-color: rgba(255, 255, 255) !important;
    border-width: 0.07rem !important;
    width: 100% !important;
    border: 1px solid rgba(223, 226, 230);
    cursor: pointer;
`;

const AddService = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;
const Image = styled.img`
    width: 70%;
    height: auto;
`;
const AssignService = styled.button`
    padding: 0.92rem 1.85rem;
    font-weight: 600;
    text-transform: capitalize;
    color: #fff;
    background: #27292e;
    border-radius: 0.57rem;
    font-size: 0.92rem;
    margin-top: 1.5rem;
    cursor: pointer;
    border: none;
`;
const FileInput = styled.input`
    display: none;
`;
export default CreateServiceProvider;
