import { useEffect, useState } from 'react';
import { serviceActions } from 'actions/service';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import s from './SelectServices.module.scss';
import { IService } from 'interfaces/service.interface';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import Button from '@components/Button';
import { api } from 'helpers/auth-axios';

const SelectServices = ({
    selectedServices,
    modelStatus,
    onClose,
    onSelect,
}: {
    selectedServices: any[];
    modelStatus: string;
    onClose(): void;
    onSelect(services: IService[]): void;
}) => {
    const dispatch = useAppDispatch();
    const [selected, setSelected] = useState<IService[]>([]);
    const [services, setService] = useState<any[]>([]);
    // const { services } = useAppSelector(state => state.service);
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');

    const toggleSelect = (service: IService) => {
        if (selected?.find(item => item._id === service._id)) {
            let newSelected = selected.filter(item => item._id !== service._id);
            setSelected(newSelected);
        } else {
            setSelected([...selected, service]);
        }
    };
    const getServices = () => {
        api.get('/services').then((res: any) => {
            if (res.data.length) {
                setService(res.data);
            }
        });
    };
    useEffect(() => {
        getServices();
    }, []);
    useEffect(() => {
        const selected: IService[] = [];
        if (services.length) {
            if (selectedServices.length) {
                selectedServices.forEach(service => {
                    selected.push(service);
                });
            }

            setSelected(selected);
        } else {
            dispatch(serviceActions.getServices()).then(res => {
                if (selectedServices.length) {
                    selectedServices.forEach(service => {
                        selected.push(service);
                    });
                } else {
                    res.forEach(service => {
                        // selected.push(service);
                    });
                }

                setSelected(selected);
            });
        }
    }, [dispatch]);

    const closeModal = (event: any) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    return (
        <>
            <Container>
                <Model tabIndex={-1} onClick={closeModal}>
                    <ModelBody lg={12}>
                        <ModelTitle>
                            <CloseButton onClick={onClose}>
                                {' '}
                                <Icon className="fal fa-times"></Icon>
                            </CloseButton>
                            <ModelHeading>Select Services</ModelHeading>
                        </ModelTitle>
                        <ModelContent>
                            <ServicesList>
                                {services.map((service, i) => (
                                    <Services sm={12} md={6} lg={4} key={i}>
                                        <div
                                            className={`${s.services_service}
                                            ${
                                                selected?.find(item => item._id === service._id)
                                                    ? s.services_selectedService
                                                    : ''
                                            }
                                            service-color-${service.color}--bd`}>
                                            {/* {modelStatus == 'editservice' && (
                                                <SetCustom
                                                    onClick={() => openEditServicesModel(service)}>
                                                    Edit
                                                </SetCustom>
                                            )} */}
                                            <div
                                                onClick={() => toggleSelect(service)}
                                                className={`${
                                                    modelStatus == 'editservice'
                                                        ? s.service_seleted
                                                        : s.service
                                                } service-color-${
                                                    service.color
                                                }--bg cursor-pointer`}>
                                                <Label>
                                                    &nbsp; {_userData.user.business_id.currency}
                                                    {service.price}
                                                </Label>

                                                <Name>{service.name}</Name>

                                                <ServiceDuration>
                                                    {`${
                                                        service.duration >= 60
                                                            ? Math.floor(service.duration / 60) +
                                                              'h'
                                                            : ''
                                                    } ${service.duration % 60}min`}
                                                </ServiceDuration>
                                            </div>
                                        </div>{' '}
                                    </Services>
                                ))}
                            </ServicesList>
                        </ModelContent>
                        <ModelButtons>
                            <div></div>
                            <CustomButtons>
                                <Button
                                    ifClicked={() => {
                                        onClose();
                                    }}
                                    bgtype={'discard'}
                                    type={'submit'}
                                    label={`Discard`}></Button>
                                &nbsp;
                                <Button
                                    ifClicked={() => {
                                        onSelect(selected);
                                        // getServices();
                                        onClose();
                                    }}
                                    bgtype={'secondary'}
                                    type={'submit'}
                                    label={`Select (${selected.length})`}></Button>
                            </CustomButtons>
                        </ModelButtons>
                    </ModelBody>
                </Model>
            </Container>
        </>
    );
};
const Model = styled(Row)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 1.8rem;
    overflow: auto;
    overscroll-behavior-y: contain;
    z-index: 102;
`;
const ModelBody = styled(Col)`
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 0.6rem;
    box-shadow: 0 1rem 1.8rem -1.4rem rgb(0 0 0 / 10%);
    padding: 1.7rem;
    width: 60rem;
    max-width: 63vw;
    ${media.xs`
  width: 100%;
  max-width: 100%;
`}
    ${media.sm`
  width: 100%;
`}
  ${media.md`
  width: 100%;
`}
`;
const CloseButton = styled.div`
    padding: 0;
    line-height: inherit;
    color: inherit;
    margin-right: 1.5rem !important;
    cursor: pointer;
    border: none;
`;
const ModelTitle = styled.div`
    display: flex;
    align-items: center;
`;
const Icon = styled.i`
    font-size: 2.25rem !important;
    line-height: 2.5rem !important;
`;

const ModelHeading = styled.h1`
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 600;
    ${media.xs`
  font-size: 1.6rem
`}
`;
const ModelContent = styled.div`
    flex: 1 1;
    display: flex;
    flex-direction: column;
`;
const ServicesList = styled(Row)`
    overflow: auto;
    padding-top: 2.5rem;
`;
const Services = styled(Col)`
    cursor: pointer;
    margin-bottom: 1rem;
`;
const ModelButtons = styled.div`
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const CustomButtons = styled.div`
    display: flex;
    justify-content: space-between;
    ${media.xs`
display: flex !important;
`}
`;
const Label = styled.div`
    background-color: #00be70;
    padding: 0.14rem 0.42rem 0.14rem 0.14rem;
    display: inline-block;
    border-radius: 3.57rem;
    font-size: 0.85rem;
    font-weight: 500;
    color: #fff;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0.42rem;
    right: 0.42rem;
`;

const LabelMyPrice = styled.div`
    background-color: #00be70;
    padding: 0.14rem 0.42rem 0.14rem 0.14rem;
    display: inline-block;
    border-radius: 3.57rem;
    font-size: 0.85rem;
    font-weight: 500;
    color: #fff;
    display: flex;
    align-items: center;
    position: absolute;
    top: 2rem;
    right: 0.42rem;
`;

const Name = styled.div`
    font-weight: 500;
    font-size: 1.2rem;
    width: 200px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;
const ServiceDuration = styled.div`
    margin-top: 0.2rem;
    font-size: 0.9rem;
`;

const SetCustom = styled.div`
    width: 3rem;
    text-align: center;
    background-color: #00be70;
    padding: 0.14rem 0.42rem 0.14rem 0.14rem;
    display: inline-block;
    border-radius: 3.57rem;
    font-size: 0.85rem;
    font-weight: 500;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0.8rem;
    left: 2rem;
    z-index: 2;
`;
export default SelectServices;
