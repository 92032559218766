import React, { useEffect, useState } from 'react';
import { Container, Col, Row, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import useRouter from 'hooks/router';
import { Layout } from '@components/common';

/*
 * This page is meant to be used as an intermediate step in Square's OAuth process.
 * As part of the process, the user is sent away from our web app to authenticate
 * on square's own servers. Once they've authenticated with Square, Square will send
 * the user back to whatever redirect URL we set in Square's Developer Dashboard
 * (in our Square App Settings).
 * Users need to be able to perform OAuth in 2 places: business settings and the
 * service providers list. To be able to redirect the user back to the appropriate
 * page, this page (SquareOAuthRedirectRouterPage) uses the "state" variable
 * (which we create when we build the "authorization link") to determine which
 * page to send the user to.
 */

const SquareOAuthRedirectRouterPage = () => {
    const router = useRouter();

    useEffect(() => {
        /*
         * Handle Square OAuth Redirect
         */
        if (
            router.query.response_type &&
            router.query.response_type === 'code' &&
            router.query.code &&
            router.query.state
        ) {
            const CSRF_TOKEN = router.query.state as string;
            // get the piece of the CSRF token we're using to route to the correct page
            // we use index 27, because the token part is 26 chars, followed by a dash
            const routeIndicator = CSRF_TOKEN.slice(27);
            if (routeIndicator === 'business') {
                // redirect user to business's online booking page
                router.navigate(`/settings/online-booking${router.location.search}`);
            } else if (routeIndicator.startsWith('serviceProvider')) {
                // get service provider ID
                const serviceProviderId = routeIndicator.slice(16);
                // redirect user to service providers list
                router.navigate(
                    `/team/service-provider-list/${serviceProviderId}${router.location.search}`
                );
            }
        }
    }, [router.query]);

    return (
        <Wrapper>
            {router.query.error ? (
                <>
                    <h3>An error occurred while trying to connect your Square account.</h3>
                    <p>Error Name: {router.query.error}</p>
                    <p>Error Description: {router.query.error_description}</p>
                </>
            ) : (
                <p>{'Performing Square OAuth Redirect'}</p>
            )}
        </Wrapper>
    );
};

const Wrapper = styled(Container)`
    paddind: 0;
    min-width: 100%;
    background-color: #fff;
`;

SquareOAuthRedirectRouterPage.Layout = Layout;

export default SquareOAuthRedirectRouterPage;
