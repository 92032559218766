import React, { useEffect } from 'react';
import { Container, media, Col, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { palette } from '../../../styled/common';

const InfoModel = ({
    cancellationPolicy,
    onClose,
}: {
    cancellationPolicy: number;
    onClose(): void;
}) => {
    return (
        <Container>
            <Wrapper>
                <ModelBody>
                    <ModelClose>
                        {/* <CloseButton onClick={onClose}>
                            {' '}
                            <i className="fa fas fa-times"></i>
                        </CloseButton> */}
                    </ModelClose>
                    <h3>
                        <b>Appointment Terms</b>
                    </h3>
                    <ModelContent>
                        <p>
                            <b>If you choose to pre-pay for your appointment:</b> if you miss your
                            appointment or cancel less than {cancellationPolicy}{' '}
                            {cancellationPolicy > 1 ? 'hours' : 'hour'} before your appointment
                            start time, you will need to schedule and pay for a brand new
                            appointment.
                        </p>
                        <p>
                            <b>If you choose not to pre-pay for your appointment:</b> if you miss
                            your appointment or cancel less than {cancellationPolicy}{' '}
                            {cancellationPolicy > 1 ? 'hours' : 'hour'} before your appointment
                            start time, your payment method will be charged and you will have to
                            schedule and pay for a new appointment.
                        </p>
                    </ModelContent>{' '}
                    <ModelContent>
                        By scheduling your appointment, you agree to the Appointment Terms above.
                    </ModelContent>{' '}
                    <AgreeButton onClick={onClose}>I Agree</AgreeButton>
                </ModelBody>
            </Wrapper>
        </Container>
    );
};
const Wrapper = styled(Row)`
    position: fixed;
    top: 0;
    left: 1.1rem;
    border-radius: 0.98rem;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: ${palette.overlay};
    padding: 1.8rem;
    overflow: auto;
    overscroll-behavior-y: contain;
    z-index: 102;
`;
const ModelBody = styled(Col)`
    display: flex;
    flex-direction: column;
    background: ${palette.white};
    border-radius: 0.6rem;
    box-shadow: 0 1rem 1.8rem -1.4rem ${palette.grey.shadow};
    padding: 1.7rem;
    max-width: 35vw;
    ${media.xs`
        width: 100%;
        max-width: 100%;
    `}
    ${media.sm`
        width: 100%;
        max-width: 60vw;
    `}
    ${media.md`
        width: 100%;
        max-width: 45vw;
    `}
    ${media.lg`
        max-width: 35vw;
    `}
`;
const ModelClose = styled.div`
    display: flex;
    justify-content: end;
    margin-bottom: 0.75rem;
`;
const CloseButton = styled.button`
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${palette.silver};
    background-color: ${palette.grey.lightest};
    border-radius: 0.5rem;
    font-size: 1.2rem;
    border: none;
    cursor: pointer;
`;
const AgreeButton = styled.button`
    padding-left: 25px;
    padding-right: 25px;
    height: 2.4rem;
    color: #fff;
    background-color: #000;
    border-radius: 4px;
    font-size: 1.2rem;
    border: none;
    cursor: pointer;
`;
const ModelContent = styled.div`
    flex: 1 1;
    display: flex;
    flex-direction: column;
    padding-bottom: 1.5rem !important;
    color: #000;
    font-size: 1rem;
`;
export default InfoModel;
