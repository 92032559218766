import { customerConstants } from 'actions/customer';
import { IGetCustomersResponse } from 'interfaces/customer.interface';

const INITIAL_STATE: IGetCustomersResponse = {
    page: 0,
    pageSize: 20,
    customers: [],
    total: 0,
};
export function customer(state = INITIAL_STATE, action: any) {
    switch (action.type) {
        case customerConstants.GET_SUCCESS:
            return {
                ...action.customers,
                customers: state.customers?.concat(action.customers.customers),
            };
        case customerConstants.CREATE_SUCCESS:
            return {
                ...state,
                total: state.total + 1,
                customers: state.customers?.concat(action.customer),
            };
        case customerConstants.SEARCH_SUCCESS:
            return {
                ...action.customers,
            };
        case customerConstants.DELETE_SUCCESS:
            return {
                ...state,
                customers: state.customers.filter(item => item._id !== action.customerId),
            };
        default:
            return state;
    }
}
