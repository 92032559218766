import { useDropdownContext } from '../Dropdown/DropdownContext';

const DropdownToggle = ({
    onClick,
    children,
}: {
    onClick?(): void;
    children?: React.ReactNode;
}) => {
    const context = useDropdownContext();

    const handleClick = () => {
        context.value.toggleMenu();
    };

    return (
        // <div className="dropdown--toggle cursor-pointer" onClick={handleClick}>
        <div className="dropdown--toggle cursor-pointer" onClick={onClick}>
            {children}
        </div>
    );
};

export default DropdownToggle;
