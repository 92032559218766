import { Dispatch } from 'redux';
import { IBreaks, IGetBreaks } from '../interfaces/breaks.interface';
import { BreaksServices } from '../services/break.service';

export const breakConstants = {
    CREATE_REQUEST: 'BREAKS_CREATE_REQUEST',
    CREATE_SUCCESS: 'BREAKS_CREATE_SUCCESS',
    CREATE_FAILURE: 'BREAKS_CREATE_FAILURE',

    GET_REQUEST: 'BREAKS_GET_REQUEST',
    GET_SUCCESS: 'BREAKS_GET_SUCCESS',
    GET_FAILURE: 'BREAKS_GET_FAILURE',

    DELETE_SUCCESS: 'BREAKS_DELETE_SUCCESS',
};

const createBreak =
    (user_id: string, breaksData: IBreaks) =>
    (dispatch: Dispatch): Promise<IBreaks> => {
        dispatch({
            type: breakConstants.CREATE_REQUEST,
        });

        return BreaksServices.create(user_id, breaksData).then(breaks => {
            dispatch({
                type: breakConstants.CREATE_SUCCESS,
                breaks,
            });

            return breaks;
        });
    };

const getBreaks =
    (user_id: string) =>
    (dispatch: Dispatch): Promise<IGetBreaks[]> => {
        dispatch({
            type: breakConstants.GET_REQUEST,
        });

        return BreaksServices.getBreaks(user_id).then(breaks => {
            dispatch({
                type: breakConstants.GET_SUCCESS,
                breaks,
            });
            return breaks;
        });
    };

const deleteBreaks =
    (id: string) =>
    (dispatch: Dispatch): Promise<IBreaks> => {
        return BreaksServices.deleteBreak(id).then(breaks => {
            dispatch({
                type: breakConstants.DELETE_SUCCESS,
                id,
            });

            return breaks;
        });
    };
export const breakActions = {
    createBreak,
    getBreaks,
    deleteBreaks,
};
