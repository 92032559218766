import { serviceProviderConstants } from 'actions/team/serviceProvider';
import { IGetServiceProviderResponse } from 'interfaces/team/serviceProvider.interface';

const INITIAL_STATE: IGetServiceProviderResponse = {
    page: 0,
    pageSize: 20,
    serviceProvider: [],
    total: 0,
};
export function serviceProvider(state = INITIAL_STATE, action: any) {
    switch (action.type) {
        case serviceProviderConstants.GET_SUCCESS:
            return {
                ...action.serviceProvider,
                serviceProvider: state.serviceProvider?.concat(
                    action.serviceProvider.serviceProvider
                ),
            };
        case serviceProviderConstants.CREATE_SUCCESS:
            return {
                ...state,
                total: state.total + 1,
                serviceProvider: state.serviceProvider?.concat(action.serviceProvider),
            };
        case serviceProviderConstants.SEARCH_SUCCESS:
            return {
                ...action.serviceProvider,
            };
        case serviceProviderConstants.DELETE_SUCCESS:
            return {
                ...state,
                serviceProvider: state.serviceProvider.filter(item => item._id !== action.id),
            };

        default:
            return state;
    }
}
