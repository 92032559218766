export enum BookingModeEnum {
    FIRST_AVAILABLE = 'F',
    NEXT_AVAILABLE = 'N',
}

export enum BookingWindowUnitsEnum {
    MINUTES = 'minutes',
    HOURS = 'hours',
    DAYS = 'days',
}

export enum NotificationTypeEnum {
    APPOINTMENT = 'appointment',
    CUSTOMER = 'customer',
    PAYMENT = 'payment',
}

export enum NotificationEventEnum {
    CREATE = 'create',
    UPDATE = 'update',
    DELETE = 'delete',
}

export enum RepeatBookingEnum {
    DAILY = 'daily',
    WEEKLY = 'weekly',
    EVERY_2_WEEKS = 'every_2_weeks',
    MONTHLY = 'monthly',
}

export enum EndRepeatBookingEnum {
    NEVER = 'never',
}

export enum BookingOptionsEnum {
    RESERVE_AND_PREPAY = 'Reserve and Pre-Pay',
    RESERVE_ONLY = 'Reserve Only',
    PREPAY_ONLY = 'Pre-Pay Only',
}

export enum RoleEnum {
    SERVICEPROVIDER = 'serviceProvider',
    OTHERS = 'others',
    TEAMMEMBER = 'teamMember',
    USER = 'user',
    CUSTOMER = 'customer',
}
