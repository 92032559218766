import React, { useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import { Link } from 'react-router-dom';
import { Layout } from '@components/common';
import { BusinessServices } from '@components/settings';
import EditService from '@components/settings/BusinessServices/EditService';
import { IService } from 'interfaces/service.interface';
export default function Services() {
    const [showEdit, setShowEdit] = useState(false);
    const [selectedService, setSelectedService] = useState<IService>();
    const onSelectService = (service: IService) => {
        setSelectedService(service);
        setShowEdit(true);
    };
    return (
        <Wrapper>
            {showEdit == false ? (
                <>
                    <Row>
                        <Column lg={12}>
                            <Link to="/settings">
                                <Icon className="fal fa-long-arrow-left"></Icon>
                            </Link>
                            <Header>Services</Header>
                        </Column>
                    </Row>
                    <BusinessServices onSelectService={onSelectService} />
                </>
            ) : (
                <EditService service={selectedService} onClose={() => setShowEdit(false)} />
            )}
        </Wrapper>
    );
}
const Wrapper = styled(Container)`
    padding-bottom: 1.5rem !important;
`;
const Column = styled(Col)`
    display: flex;
    align-items: center;
    margin-bottom: 2rem !important;
`;
const Header = styled.h1`
    font-size: 1.875rem;
    font-weight: 600;
`;
const Icon = styled.i`
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
    margin-right: 1.25rem !important;
`;
Services.Layout = Layout;
