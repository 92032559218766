import moment from 'moment-timezone';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { publicApi } from 'helpers/public-axios';

import LoaderOverlay from '@components/common/LoaderOverlay/LoaderOverlay';
import SelectService from '@components/widget/embed/SelectService';
import SelectProfessional from '@components/widget/embed/SelectProfessional';
import AppointmentCalendar from '@components/widget/embed/AppointmentCalendar';
import CustomerDetails from '@components/widget/embed/CustomerDetails';
import SelectTip from '@components/widget/embed/SelectTip';
import CompleteBooking from '@components/widget/embed/CompleteBooking';
import SuccessfulAppointment from '@components/widget/embed/SuccessfulAppointment';

import { IBusiness } from 'interfaces/business.interface';
import { ICreateReservation } from 'interfaces/appointment.interface';
import { IService } from 'interfaces/service.interface';
import { IServiceProvider } from 'interfaces/team/serviceProvider.interface';
import { ISquareCard } from 'interfaces/square.interface';
import { BookingOptionsEnum } from 'constants/enums';

const ServiceProviderBookingPage = () => {
    const { businessId, serviceProviderId } = useParams();

    const [business, setBusiness] = useState<IBusiness>();
    const [selectedProfessional, setSelectedProfessional] = useState<IServiceProvider>();

    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [onSuccess, setOnSuccess] = useState<boolean>(false);

    /*
     * Booking Steps:
     * 1. Select a Service
     * 2. Select a Service Provider
     * 3. Booking Calendar
     * 4. Customer Details
     * 5. Select a Tip
     * 6. Complete Booking
     * 7. Success Message
     */
    const [step, setStep] = useState<any>(1);

    const [selectedService, setSelectedService] = useState<any>();

    const [my_duration, setMy_Duration] = useState<any>();
    const [my_price, setMy_Price] = useState<any>();
    const [vip_price, setVip_Price] = useState<any>();

    const [calendarDate, setCalendarDate] = useState<any>();
    const [startTime, setStartTime] = useState<any>();
    const [is_vip, setIs_Vip] = useState<any>();

    const customerDetails = useRef<any>();
    const [customer_note, setCustomer_note] = useState<any>();

    const [tipLabel, setTipLabel] = useState<any>();
    const [gratuaty, setGratuaty] = useState<any>();
    const [total, setTotal] = useState<number>(0);

    const squareCardInfo = useRef<any>();
    const stripeCardInfo = useRef<any>();
    const isPrepaying = useRef<boolean>(false);

    const [showReserveButton, setShowReserveButton] = useState<boolean>(false);
    const [showPrepayButton, setShowPrepayButton] = useState<boolean>(false);

    // remove all pre-existing meta tags so we can insert the meta tags
    // specific to this page
    useEffect(() => {
        let originalDescriptionElements = document.querySelectorAll(
            'meta:not([name=theme-color]):not([charset])'
        );
        originalDescriptionElements.forEach(e => {
            // @ts-ignore: ignore html.dataset being unknown
            if (!e.dataset)
                throw new Error('Helmet: html.dataset is not available in this browser.');
            // @ts-ignore: ignore html.dataset being unknown
            else if (!e.dataset.reactHelmet) e.parentNode.removeChild(e);
        });
    }, []);

    const getBusinessById = useCallback((businessId: string) => {
        publicApi.get(`/businesses/public/${businessId}`).then((res: any) => {
            setBusiness(res.data);
        });
    }, []);

    const getServiceProviderById = useCallback((serviceProviderId: string) => {
        publicApi
            .get(`/serviceProvider/public/${businessId}/${serviceProviderId}`)
            .then((res: any) => {
                let professional = res.data;
                // currently, by default pass_fees_to_customer
                // is set to "True" in the backend, but that
                // leaves us with "undefined" in the frontend
                if (professional.pass_fees_to_customer === undefined) {
                    professional.pass_fees_to_customer = true;
                }
                setSelectedProfessional(professional);
                if (
                    !professional.booking_options ||
                    professional.booking_options === BookingOptionsEnum.RESERVE_AND_PREPAY
                ) {
                    // we check for falseness for booking_options because it's new
                    // (and may not yet be a property of the serviceprovider's document in db)
                    // and the default is to show both the reserve and pre-pay buttons
                    setShowReserveButton(true);
                    setShowPrepayButton(true);
                } else if (professional.booking_options === BookingOptionsEnum.RESERVE_ONLY) {
                    setShowReserveButton(true);
                } else if (professional.booking_options === BookingOptionsEnum.PREPAY_ONLY) {
                    setShowPrepayButton(true);
                }
            });
    }, []);

    useEffect(() => {
        // satisfy type-checking
        if (businessId !== undefined) {
            getBusinessById(businessId);
        }
    }, [businessId]);

    useEffect(() => {
        // satisfy type-checking
        if (serviceProviderId !== undefined) {
            getServiceProviderById(serviceProviderId);
        }
    }, [serviceProviderId]);

    /*
     * Reserve without payment information
     */
    const createReservation = () => {
        setLoading(true);
        setErrorMessage('');
        // to satisfy type-checking, look for undefined vars
        if (
            business === undefined ||
            selectedProfessional === undefined ||
            selectedService === undefined
        )
            return;
        const requestBody: ICreateReservation = {
            business_id: business._id!,
            customer_details: customerDetails.current,

            service_provider_id: selectedProfessional._id!,
            service_id: selectedService._id!,
            appointment_date: calendarDate,
            start_time: startTime,
            is_vip: is_vip,
            customer_note: customer_note,

            platform: 'widget',
        };
        publicApi
            .post('/appointments/reserve', requestBody)
            .then((res: any) => {
                setLoading(false);
                setStep(6);
                setErrorMessage('');
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    setErrorMessage(e?.response?.data?.message);
                }
            });
    };

    const checkIfCardHasExpired = () => {
        const currentDate = new Date();
        // if the card's expiration date has passed present an error and prevent submission
        if (stripeCardInfo.current.cardYear == currentDate.getFullYear()) {
            if (stripeCardInfo.current.cardMonth < currentDate.getMonth() + 1) {
                return true;
            }
        }
        return false;
    };

    const createReservationWithPaymentInfo = () => {
        if (getPaymentProcessor() === 'stripe') {
            const cardIsExpired = checkIfCardHasExpired();
            if (cardIsExpired) {
                setErrorMessage('The credit card expiration date has passed.');
                return;
            }
        }
        setLoading(true);
        setErrorMessage('');
        // to satisfy type-checking, look for undefined vars
        if (
            business === undefined ||
            selectedProfessional === undefined ||
            selectedService === undefined
        )
            return;
        const requestBody: ICreateReservation = {
            business_id: business._id!,
            customer_details: customerDetails.current,

            service_provider_id: selectedProfessional._id!,
            service_id: selectedService._id!,
            appointment_date: calendarDate,
            start_time: startTime,
            is_vip: is_vip,
            customer_note: customer_note,

            payment_processor: getPaymentProcessor(),
            square_card_info: squareCardInfo.current,
            stripe_card_info: stripeCardInfo.current,
            tip_amount: gratuaty,
            tip_label: tipLabel,
            is_prepaying: isPrepaying.current,

            platform: 'widget',
        };
        publicApi
            .post('/appointments/reserve', requestBody)
            .then((res: any) => {
                setLoading(false);
                setStep(6);
                setErrorMessage('');
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    setErrorMessage(e?.response?.data?.message);
                }
            });
    };

    /*
     * Helper/Utility Functions
     */
    const prepayIsDisabled = () => {
        // return true if business has disabled prepaying
        if (business?.booking_without_payment) {
            return true;
        }
        // return false if business or service provider have a payment processor enabled
        if (aPaymentProcessorIsEnabled()) {
            return false;
        }
        // default case: return true, to say "prepay is disabled"
        return true;
    };

    // Return true if business or provider have a payment processor enabled
    const aPaymentProcessorIsEnabled = () => {
        // First, check the service provider
        // Check if stripe is enabled
        if (selectedProfessional?.stripe_account_verified) {
            return true;
        }
        // Check if square is enabled
        if (selectedProfessional?.square_account_verified) {
            return true;
        }
        // Now, we check the business
        // Check if stripe is enabled
        if (business?.stripe_account_verified) {
            return true;
        }
        // Check if square is enabled
        if (business?.square_account_verified) {
            return true;
        }
        // Default case, return False since nothing else shows as enabled
        return false;
    };

    // return a string representing the payment processor in use
    const getPaymentProcessor = () => {
        // First, check the service provider
        // Check if stripe is enabled
        if (selectedProfessional?.stripe_account_verified) {
            return 'stripe';
        }
        // Check if square is enabled
        if (selectedProfessional?.square_account_verified) {
            return 'square';
        }
        // Now, we check the business
        // Check if stripe is enabled
        if (business?.stripe_account_verified) {
            return 'stripe';
        }
        // Check if square is enabled
        if (business?.square_account_verified) {
            return 'square';
        }

        return '';
    };

    return business && selectedProfessional ? (
        <>
            <Helmet>
                <title>
                    Book with {selectedProfessional.name} at {business.name}
                </title>
                <meta
                    name="description"
                    content={`Book your next service appointment with ${selectedProfessional.name} at ${business.name}.`}
                />
                <meta
                    property="og:site_name"
                    content={`Book with ${selectedProfessional.name} at ${business.name}`}
                />
                <meta
                    property="og:title"
                    content={`Book with ${selectedProfessional.name} at ${business.name}`}
                />
                <meta property="og:type" content="website" />
                <meta
                    property="og:url"
                    content={`https://app-stage.getexclusively.com/${businessId}/${serviceProviderId}`}
                />
                <meta
                    property="og:image"
                    content={`https://${process.env
                        .REACT_APP_AWS_S3_BUSINESS_BUCKET!}.s3.amazonaws.com/${business.photo}`}
                />
                <meta name="twitter:card" content="summary" />
                <meta
                    name="twitter:description"
                    content={`Book your next service appointment with ${selectedProfessional.name} at ${business.name}.`}
                />
                <meta
                    name="twitter:image"
                    content={`https://${process.env
                        .REACT_APP_AWS_S3_BUSINESS_BUCKET!}.s3.amazonaws.com/${business.photo}`}
                />
            </Helmet>
            <style>{`
                /*
                 * Override height here to make sure whole services list is
                 * scrollable, no matter the height of the window.
                 */
                .business-booking-widget .booking-wrapper {
                    height: 75vh !important;
                }
            `}</style>
            <div id="booking-overlay">
                <div
                    className="business-booking-widget business-booking-widget-wrapper"
                    style={{
                        height: '100%',
                    }}>
                    <ProfessionalInfoContainer>
                        <ProfileImage
                            src={`https://${process.env
                                .REACT_APP_AWS_S3_PROFILE_BUCKET!}.s3.amazonaws.com/${
                                selectedProfessional?.photo
                            }`}
                            alt=""
                        />
                        <div style={{ flexDirection: 'column' }}>
                            <ProfessionalName>{selectedProfessional.name}</ProfessionalName>
                            {selectedProfessional.position &&
                            selectedProfessional.position !== '' ? (
                                <>
                                    <ProviderTitle>{selectedProfessional.position}</ProviderTitle>
                                    <BusinessName style={{ marginTop: '5px' }}>
                                        {business.name}
                                    </BusinessName>
                                </>
                            ) : (
                                <BusinessName style={{ marginTop: '15px' }}>
                                    {business.name}
                                </BusinessName>
                            )}
                        </div>
                    </ProfessionalInfoContainer>
                    {step === 1 && (
                        <SelectService
                            businessId={business}
                            serviceProvider={selectedProfessional}
                            close={() => {}}
                            onSelect={(service: IService) => {
                                setSelectedService(service);

                                // get the staff service w/ service_id equal to given service's ID
                                const selectedStaffService = selectedProfessional.services.find(
                                    staffService => staffService.service_id == service._id
                                );
                                setMy_Duration(selectedStaffService.my_duration);
                                setMy_Price(selectedStaffService.my_price);
                                setVip_Price(selectedStaffService.vip_price);

                                setStep((val: number) => val + 1);
                            }}
                        />
                    )}
                    {step === 2 && (
                        <AppointmentCalendar
                            timezone={business && business?.timezone}
                            onPrevious={() => {
                                setStep((val: number) => val - 1);
                            }}
                            serviceProvider={selectedProfessional}
                            serviceId={selectedService._id}
                            serviceDuration={my_duration}
                            onSelect={(selectedDay: any, startTime: any, is_vip: any) => {
                                let selct: any = new Date(selectedDay);
                                let month: any = parseInt(selct.getMonth() + 1);
                                let day: any = selct.getDate();
                                if (month < 10) {
                                    month = '0' + month;
                                }
                                if (day < 10) {
                                    day = '0' + day;
                                }
                                let mydate = selct.getFullYear() + '-' + month + '-' + day;
                                setCalendarDate(mydate);
                                setStartTime(startTime);
                                setIs_Vip(is_vip);
                                setStep((val: number) => val + 1);
                            }}
                        />
                    )}
                    {step === 3 && (
                        <CustomerDetails
                            buttonTitle={
                                prepayIsDisabled()
                                    ? 'Reserve'
                                    : loading == false
                                    ? 'Next'
                                    : 'Please wait...'
                            }
                            onPrevious={() => {
                                setStep((val: number) => val - 1);
                            }}
                            customer_note={val => {
                                setCustomer_note(val);
                            }}
                            onNext={(customer_details: any) => {
                                customerDetails.current = customer_details;
                                if (prepayIsDisabled()) {
                                    createReservation();
                                } else {
                                    setStep((val: number) => val + 1);
                                }
                            }}
                            errorMessage={errorMessage}
                        />
                    )}
                    {step === 4 && (
                        <SelectTip
                            onPrevious={() => {
                                setStep((val: number) => val - 1);
                            }}
                            loading={loading}
                            subTotal={is_vip == true ? vip_price : my_price}
                            businessId={business}
                            service={selectedService?.name}
                            serviceProvider={selectedProfessional}
                            isVip={is_vip}
                            tipLabel={(val: number) => setTipLabel(val)}
                            buttonTitle={loading == false ? 'Next' : 'Please wait...'}
                            gratuity={(value: any, totalCharges: any) => {
                                setGratuaty(value);
                                setTotal(totalCharges);
                            }}
                            onSuccess={onSuccess}
                            onSelect={() => {
                                setStep((val: number) => val + 1);
                            }}
                        />
                    )}
                    {step === 5 && (
                        <CompleteBooking
                            business={business}
                            serviceProvider={selectedProfessional}
                            reserve={false}
                            errorMessage={errorMessage}
                            loading={loading}
                            service={total ? total : my_price}
                            isVip={is_vip}
                            buttonTitle={`${selectedService!.name} with ${
                                selectedProfessional?.name
                            } on ${moment(calendarDate).format('MMMM DD, YYYY')} at ${moment(
                                moment().format() + ' ' + startTime,
                                'YYYY-MM-DD HH:mm:ss'
                            ).format('hh:mm a')}`}
                            showReserveButton={showReserveButton}
                            showPrepayButton={showPrepayButton}
                            squareCardInfo={(card: ISquareCard, bookingFeeToken: string) => {
                                squareCardInfo.current = { card, bookingFeeToken };
                            }}
                            stripeCardInfo={(_cardInfo: any) => {
                                stripeCardInfo.current = _cardInfo;
                            }}
                            isPrepaying={(_isPrepaying: boolean) => {
                                isPrepaying.current = _isPrepaying;
                            }}
                            onPrevious={() => {
                                setStep((val: number) => val - 1);
                                setErrorMessage('');
                            }}
                            onNext={createReservationWithPaymentInfo}
                            customer={customerDetails.current}
                        />
                    )}
                    {step === 6 && (
                        <SuccessfulAppointment
                            business={business}
                            onBack={() => setStep(1)}
                            serviceName={selectedService?.name}
                            serviceProviderName={selectedProfessional?.name}
                            appointmentDate={calendarDate}
                            appointmentStartTime={startTime}
                        />
                    )}
                </div>
            </div>
            {business && business.booking_widget && (
                <style>{`
                    .btn {
                        background-color: ${business.booking_widget.button_bg_color};
                        color: ${business.booking_widget.button_text_color};
                    }
                `}</style>
            )}
        </>
    ) : (
        <LoaderOverlay />
    );
};

const ProfessionalInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    max-width: 650px;
    width: 100%;
    margin: 10px auto;
`;

const ProfileImage = styled.img`
    width: auto;
    height: 12vh;
    min-height: 7rem;
    border-radius: 50%;
    margin-left: 1rem;
`;

const ProfessionalName = styled.div`
    font-weight: 650;
    font-size: 3rem;
    line-height: 2rem;
    margin-left: 2rem;
`;

const BusinessName = styled.div`
    font-weight: 700;
    font-size: 1.2rem;
    margin-left: 2.5rem;
`;

const ProviderTitle = styled.div`
    margin-left: 2.5rem;
    font-size: 1rem;
    font-weight: bold;
    color: #999;
    margin-top: 11px;
`;

export default ServiceProviderBookingPage;
