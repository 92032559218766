import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Col, Row, media } from 'styled-bootstrap-grid';
import { Input, useSnackbar } from '@components/common';
import { Link, useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { userActions } from 'actions/user';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import useRouter from 'hooks/router';
import SideCover from '@components/common/SideCover/SideCover';
import { api } from 'helpers/auth-axios';
import ForgotPassword from './customer/ForgotPassword';

type FormValues = {
    email: string;
    password: string;
};

const CustomerLogin = () => {
    useEffect(() => {
        localStorage.removeItem('user');
        localStorage.removeItem('url');
    }, []);

    const router = useRouter();
    const navigate = useNavigate();
    const [openSnackbar] = useSnackbar();
    const dispatch = useAppDispatch();
    const { loggingIn } = useAppSelector(state => state.auth);
    const [authMethod, setAuthMethod] = useState('email');
    const [forgotPassword, setForgotPassword] = useState<any>(false);
    const [email, setEmail] = useState();
    const validationSchema = Yup.object().shape({
        email: Yup.string().required('This field is required'),
        password: Yup.string().required('This field is required'),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, setValue, formState, trigger } = useForm<FormValues>(formOptions);
    const { errors } = formState;

    const onSubmit = handleSubmit(({ email, password }: { email: string; password: string }) => {
        let customerLogin = {
            email,
            password,
            role: 'customer',
            businessId: window.location.pathname.slice(1, 25),
        };
        return dispatch(userActions.login(customerLogin)).then((res: any) => {
            if (res.user) {
                let _userData = res.user;
                if (res?.user_login === 'customer') {
                    navigate(`/customers/${_userData?._id}`);
                }
            } else {
                openSnackbar('Login Failed');
            }
        });
    });

    const onForgotPassword = () => {
        api.post('/auth/forgot_password', {
            email: email,
            role: 'customer',
            businessId: window.location.pathname.slice(1, 25),
        })
            .then((res: any) => {
                openSnackbar('Email sent to your email address Successfully');
                setForgotPassword(false);
            })
            .catch((e: any) => {
                if (e.response) {
                    openSnackbar('Incorrect email');
                }
            });
    };

    async function onChange({ name, value }: { name: string; value: string }) {
        setValue(name as never, value as never);
        await trigger(name as never);
    }

    useEffect(() => {
        if (router.query.success !== undefined) {
            if (router.query.provider) setAuthMethod(router.query.provider as string);
            if (router.query.accessToken) {
                dispatch(userActions.getCurrentUser(router.query.accessToken as string)).then(
                    (res: any) => {
                        if (res.email === true) {
                            openSnackbar('res.message');
                            navigate(`/customers/${res?._id}`);
                        }
                    }
                );
            }
        }
    }, [dispatch, router.query]);

    return (
        <>
            <Wrapper>
                <Row>
                    <SideCover />
                    <Col lg={5} style={{ padding: '0' }}>
                        <CardRight>
                            <Inner>
                                <Heading>
                                    {forgotPassword === false
                                        ? ' Customer Log In'
                                        : 'Reset Password'}
                                </Heading>

                                {forgotPassword === true && (
                                    <SignInText>
                                        Already have password?
                                        <Link to="">
                                            <SignUpText
                                                className="text-primary-light"
                                                onClick={() => setForgotPassword(false)}>
                                                Log In
                                            </SignUpText>
                                        </Link>
                                    </SignInText>
                                )}
                            </Inner>

                            {forgotPassword === false ? (
                                <form onSubmit={onSubmit}>
                                    <InputGroup>
                                        <Input
                                            label="E-mail Address"
                                            name="email"
                                            type="email"
                                            onChange={onChange}
                                            error={errors.email as any}
                                        />
                                    </InputGroup>

                                    <InputGroup>
                                        <Input
                                            type="password"
                                            name="password"
                                            label="Password"
                                            onChange={onChange}
                                            error={errors.password as any}
                                        />
                                    </InputGroup>

                                    <CustomDiv onClick={() => setForgotPassword(true)}>
                                        <Link to="">Forgot Password?</Link>
                                    </CustomDiv>

                                    <Button type="submit">Log In</Button>
                                </form>
                            ) : (
                                <ForgotPassword setForgotPassword={setForgotPassword} />
                            )}
                        </CardRight>
                    </Col>
                </Row>
            </Wrapper>
        </>
    );
};
const Wrapper = styled(Container)`
    paddind: 0;
    min-width: 100%;
    background-color: #fff;
`;
const CardLeft = styled.div`
    background-color: #4871fe;
    padding-top: 3.5rem !important;
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
`;
const CardRight = styled.div`
    padding-left: 4.75rem !important;
    padding-right: 4.75rem !important;
    background-color: #fff;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
const Inner = styled.div`
    margin-bottom: 3rem;
`;
const Heading = styled.h3`
    color: #000;
    font-size: 2.6rem;
    font-weight: 600 !important;
    ${media.xs`
    margin-top:0;
	`}
    ${media.sm`
    margin-top:0;
	`}
	${media.md`
    margin-top:0;
	`}
`;
const InputGroup = styled.div`
    margin-bottom: 1.71rem;
`;
const Button = styled.button`
    color: rgba(255, 255, 255) !important;
    text-transform: capitalize !important;
    font-weight: 800 !important;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
    background-color: #0154ff !important;
    border-radius: 0.5rem !important;
    width: 100% !important;
    display: inline-block !important;
    margin-top: 1rem !important;
    border: 0;
    cursor: pointer;
    font-size: 1.25rem;
`;
const Reset = styled.button`
    color: rgba(255, 255, 255) !important;
    text-transform: capitalize !important;
    font-weight: 800 !important;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
    // background-color: #0154ff !important;
    background-color: #7e899f !important;
    border-radius: 0.5rem !important;
    width: 100% !important;
    display: inline-block !important;
    margin-top: 1rem !important;
    border: 0;
    cursor: pointer;
    font-size: 1.25rem;
`;
const Span = styled.span``;
const SignUpText = styled.span`
    color: #0154ff !important;
    font-weight: 800;
    padding-left: 0.5rem;
    margin: 0;
`;
const SignInText = styled.span`
    display: flex;
    color: #000 !important;
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
`;
const CustomDiv = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 0rem 0rem;
    color: #0154ff;
    cursor: pointer;
`;
export default CustomerLogin;
