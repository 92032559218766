import { useDropdownContext } from '..//Dropdown/DropdownContext';

const DropdownItem = ({ onClick, children }: { onClick?(): void; children?: React.ReactNode }) => {
    const context = useDropdownContext();

    const handleClick = () => {
        context.value.toggleMenu();

        if (onClick) onClick();
    };
    return (
        // <div className="dropdown--item" onClick={handleClick}>
        <div className="dropdown--item" onClick={onClick}>
            {children}
        </div>
    );
};

export default DropdownItem;
