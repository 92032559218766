import { IGetBreaks } from '../interfaces/breaks.interface';
import { breakConstants } from '../actions/breaks';

const INITIAL_STATE: IGetBreaks = {
    breaks: [],
};
export function breaks(state = INITIAL_STATE, action: any) {
    switch (action.type) {
        case breakConstants.GET_SUCCESS:
            return {
                ...action.breaks,
                breaks: action.breaks,
            };
        case breakConstants.CREATE_SUCCESS:
            return {
                ...state,
                breaks: state.breaks?.concat(action.breaks),
            };

        case breakConstants.DELETE_SUCCESS:
            return {
                ...state,
                breaks: state.breaks.filter(item => item._id !== action.id),
            };
        default:
            return state;
    }
}
