import { useState } from 'react';
import styled from 'styled-components';
import Button from '@components/Button';
import { media } from 'styled-bootstrap-grid';
import { Input } from '@components/common';
import { Tab, TabPanel, Tabs } from '@components/common';
import TabContext from '@components/common/TabContext';
import { api } from 'helpers/auth-axios';
import { palette } from 'styled/common';
import { useSnackbar } from '@components/common';

export interface InputProps {
    error?: string;
}

const SendMessage = ({
    onClose,
    name,
    phone,
    senderEmail,
    reciverEmail,
}: {
    onClose(): void;
    name: any;
    phone: any;
    senderEmail: any;
    reciverEmail: any;
}) => {
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const [openSnackbar] = useSnackbar();
    const [message, setMessage] = useState<any>('');
    const [subject, setSubject] = useState<any>('');
    const [email, setEmail] = useState<any>('');
    const [tabValue, setTabValue] = useState(0);

    const [error, setError] = useState<any>(false);

    const [toggle, setToggle] = useState<any>(true);

    const closeModal = (event: any) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
            onClose();
        }
    };
    const sendMessage = async () => {
        if (IsValidMessage()) {
            let data: any = {
                message: message,
                sender_name: _userData.user.name,
                receiver_name: name,
                receiver_phone: phone,
            };
            await api
                .post(`/users/send_sms`, data)
                .then((res: any) => {
                    if (res.data === true) {
                        onClose();
                        openSnackbar('Message send successfully!');
                    }
                })
                .catch((e: any) => {
                    if (e?.response) {
                        openSnackbar(e?.response?.data?.message);
                    }
                });
        }
    };
    const sendEmail = async () => {
        if (IsValidEamil()) {
            let data: any = {
                email: reciverEmail,
                subject: subject,
                text: email,
            };
            await api
                .post(`/users/send_email`, data)
                .then((res: any) => {
                    if (res.data === true) {
                        onClose();
                        openSnackbar('Eamil send successfully!');
                    }
                })
                .catch((e: any) => {
                    if (e?.response) {
                        openSnackbar(e?.response?.data?.message);
                    }
                });
        }
    };

    const IsValidMessage = () => {
        let valid = true;
        if (message == undefined || message == null || message.trim() == '') {
            valid = false;
            setError(true);
        } else {
            setError(false);
        }
        return valid;
    };
    const IsValidEamil = () => {
        let valid = true;
        if (email == undefined || email == null || email.trim() == '') {
            valid = false;
            setError(true);
        } else {
            setError(false);
        }
        return valid;
    };
    const removeError = (val: any) => {
        if (val.trim() !== '') {
            setError(false);
        } else {
            setError(true);
        }
    };

    return (
        <>
            <Model tabIndex={-1} onClick={closeModal}>
                <ModelBody>
                    <ModelHead>
                        <Heading>Text Message</Heading>
                        <Cover onClick={onClose}>
                            <i className="fas fa-times"></i>
                        </Cover>
                    </ModelHead>
                    <CustomDiv>
                        <TabsCard>
                            <TabContext value={tabValue}>
                                <Tabs
                                    onChange={(event, newValue) => {
                                        setTabValue(newValue);
                                    }}>
                                    <Tab
                                        label={'Send 1 Way Text'}
                                        onClick={() => {
                                            setToggle(!toggle);
                                            setError(false);
                                        }}
                                    />
                                    <Tab
                                        label={'Send 1 Way Email'}
                                        onClick={() => {
                                            setToggle(!toggle);
                                            setError(false);
                                        }}
                                    />
                                </Tabs>
                                <TabPanel value={0} preload={true}>
                                    <TabContent>
                                        <InputGroup>
                                            <Textarea
                                                placeholder="Type your message here"
                                                rows={4}
                                                value={message}
                                                error={error}
                                                onChange={(e): any => {
                                                    setMessage(e.target.value);
                                                    removeError(e.target.value);
                                                }}></Textarea>
                                        </InputGroup>
                                    </TabContent>
                                </TabPanel>
                                <TabPanel value={1} preload={true}>
                                    <TabContent>
                                        <InputGroup>
                                            <Input
                                                label="Subject"
                                                name="subject"
                                                value={subject}
                                                onChange={(e: any) => {
                                                    setSubject(e.value);
                                                }}
                                            />
                                        </InputGroup>
                                        <InputGroup>
                                            <Textarea
                                                placeholder="Type your Email here"
                                                rows={4}
                                                value={email}
                                                error={error}
                                                onChange={(e): any => {
                                                    setEmail(e.target.value);
                                                    removeError(e.target.value);
                                                }}></Textarea>
                                        </InputGroup>
                                    </TabContent>
                                </TabPanel>
                            </TabContext>
                        </TabsCard>
                    </CustomDiv>
                    <Footer>
                        {toggle ? (
                            <Button
                                bgtype={'secondary'}
                                ifClicked={sendMessage}
                                width="12rem !important"
                                label="Send Text"></Button>
                        ) : (
                            <Button
                                bgtype={'secondary'}
                                ifClicked={sendEmail}
                                width="12rem !important"
                                label="Send Email"></Button>
                        )}
                    </Footer>
                </ModelBody>
            </Model>
        </>
    );
};
const Model = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 24px;
    overflow: auto;
    overscroll-behavior-y: contain;
    z-index: 102;
`;
const ModelBody = styled.div`
    display: flex;
    width: 37%;
    flex-direction: column;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 15px 25px -6px rgb(0 0 0 / 10%);
    padding: 24px;
    max-width: 82vw;
    ${media.xs`
    width: 100%;
	`}
    ${media.sm`
    width: 50%;
	`}
    ${media.md`
    width: 37%;
	`}
	${media.lg`
    width: 37%;
	`}
`;
const ModelHead = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0rem;
`;
const TabsCard = styled.div`
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: ${palette.light};
    background-clip: border-box;
    border-radius: 0.42rem;
    // box-shadow: 0rem 0rem 2.2rem 0rem ${palette.grey.shadow};
    border: 0;
`;
const TabContent = styled.div`
    padding: 1.3rem;
    flex: 1;
`;
const Cover = styled.div`
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3f4254;
    background-color: #e4e6ef;
    border-radius: 8px;
    font-size: 1.2rem;
`;
const ModelHeading = styled.div`
    align-items: center !important;
    display: flex !important;
`;
const Heading = styled.h1`
    font-weight: 600 !important;
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
    margin: 0px !important;
`;
const Space = styled.div`
    padding-top: 2.5rem !important;
`;
const CustomDiv = styled.div``;

const InputGroup = styled.div`
    margin-bottom: 1rem;
`;
const Textarea = styled.textarea<InputProps>`
    width: 100%;
    padding: 0.75rem 1.15rem;
    outline: none;
    resize: none;
    border: 1px solid ${({ error }) => (error ? `red` : '#dfe2e6')};
    border-radius: 0.325rem;
`;

const Footer = styled.div`
    display: flex;
    justify-content: center;
`;

export default SendMessage;
