import moment from 'moment';
import { IBusiness } from './business.interface';

export enum ActivityTypeEnum {
    APPOINTMENT_BOOKED = 'APPOINTMENT BOOKED',
    APPOINTMENT_RESCHEDULED = 'APPOINTMENT RESCHEDULED',
    APPOINTMENT_CANCELLED = 'APPOINTMENT CANCELLED',
    APPOINTMENT_DELETED = 'APPOINTMENT DELETED',
    APPOINTMENT_PAID = 'APPOINTMENT PAID',
    APPOINTMENT_MARKED_PAID = 'APPOINTMENT MARKED PAID',
    APPOINTMENT_MARKED_UNPAID = 'APPOINTMENT MARKED UNPAID',
    APPOINTMENT_STATUS_CHANGED = 'APPOINTMENT STATUS CHANGED',

    RECURRING_BOOKED = 'RECURRING BOOKED',
}

export interface IActivity {
    _id?: string;
    business_id: string | IBusiness;

    activity_type: ActivityTypeEnum;

    // A short title, representing the activity
    activity_title: string;

    // A more descriptive representation of the activity
    activity_description?: string;

    /*
     * Any additional associated data can be placed in here.
     * For example, if this is about an appointment and we want
     * to include whether or not the appointment has been paid
     * for, we can do
     * "activity.activity_extended_props.paid = true"
     */
    activity_extended_props: any;

    created_at?: Date | moment.Moment;
    updated_at?: Date | moment.Moment;
}

export interface ISearchActivities {
    containsText?: string;
    activityType?: ActivityTypeEnum | string;
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    page: number;
    pageSize: number;
}
