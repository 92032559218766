import { teamConstants } from 'actions/team/teamMember';
import { IGetTeamResponse } from 'interfaces/team/teamMember.interface';

const INITIAL_STATE: IGetTeamResponse = {
    page: 0,
    pageSize: 20,
    team: [],
    total: 0,
};
export function team(state = INITIAL_STATE, action: any) {
    switch (action.type) {
        case teamConstants.GET_SUCCESS:
            return {
                ...action.team,
                team: state.team?.concat(action.team.team),
            };
        case teamConstants.CREATE_SUCCESS:
            return {
                ...state,
                total: state.total + 1,
                team: state.team?.concat(action.team),
            };
        case teamConstants.SEARCH_SUCCESS:
            return {
                ...action.team,
            };

        case teamConstants.DELETE_SUCCESS:
            return {
                ...state,
                team: state.team.filter(team => team._id !== action.id),
            };
        default:
            return state;
    }
}
