import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { IOther, IGetOtherResponse } from 'interfaces/team/others.interface';
import useRouter from 'hooks/router';
import _ from 'lodash';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import SearchField from '@components/common/Search/SearchField';
import ContentList from '@components/common/ContentList/ContentList';
import { api } from 'helpers/auth-axios';
import { LoaderOverlay, useSnackbar } from '@components/common';
interface IProps {
    hasId: any;
}
const OthersList = forwardRef(({}, ref) => {
    useImperativeHandle(ref, () => ({
        getOthers() {
            getAllOther();
        },
    }));
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const router = useRouter();

    const [pageNumber, setPageNumber] = useState(0);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [query, setQuery] = useState<any>(null);
    const [value, setValue] = useState<any>('');
    const [initial, setInitial] = useState(false); // used to check whether data is initially on store
    const [other, setOther] = useState<IGetOtherResponse>();
    const observer = useRef<IntersectionObserver>();
    const [openSnackbar] = useSnackbar();
    const loaderRef = useCallback(
        (node: any) => {
            if (loading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(entries => {
                // if (entries[0].isIntersecting && hasMore) {
                //     setPageNumber(prevPageNumber => prevPageNumber + 1);
                // }
            });
            if (node) observer.current.observe(node);
        },
        [loading, hasMore]
    );

    const getAllOther = () => {
        setLoading(true);
        api.get(`/other?page=${pageNumber}&pageSize=${20}`)
            .then((other: any) => {
                setOther(other.data);
                if (
                    other.data.other.length &&
                    router.query[Object.keys(router.query)[0]] === '' &&
                    window.innerWidth > 767
                ) {
                    navigate(`/team/other-list/${other.data.other[0]._id}`);
                }

                setHasMore(other.data.other.length > 0);
                setLoading(false);
            })
            .catch((e: any) => {
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const searchOther = (query: string) => {
        let value = query;
        if (Number.isInteger(parseInt(value)) === true) {
            if (value.length <= 3) {
                value = value.replace(/[- .]/g, '');
                // value = value + '-';
            } else if (value.length <= 7) {
                value = value.replace(/[- .]/g, '');
                value = value.slice(0, 3) + '-' + value.slice(3, 6);
            } else if (value.length >= 7) {
                value = value.replace(/[- .]/g, '');
                value = value.slice(0, 3) + '-' + value.slice(3, 6) + '-' + value.slice(6);
            }
            setLoading(true);
            api.get(`/other?page=${0}&pageSize=${20}&query=${value}`)
                .then((other: any) => {
                    // setOther(other.data);
                    setLoading(false);
                    setHasMore(other.data.other.length > 0);
                })
                .catch((e: any) => {
                    if (e?.response) {
                        openSnackbar(e?.response?.data?.message);
                    }
                });
        } else {
            setLoading(true);
            api.get(`/other?page=${0}&pageSize=${20}&query=${value}`)
                .then((other: any) => {
                    // setOther(other.data);
                    setLoading(false);
                    setHasMore(other.data.other.length > 0);
                })
                .catch((e: any) => {
                    if (e?.response) {
                        openSnackbar(e?.response?.data?.message);
                    }
                });
        }
    };

    useEffect(() => {
        if (other !== undefined && other.total !== 0) {
            setPageNumber(other && other.page);
            setValue((other !== undefined && other.query) ?? null);
            setQuery((other !== undefined && other.query) ?? null);
            setHasMore(true);
            setInitial(true);
            return;
        }

        getAllOther();
    }, []);

    useEffect(() => {
        if ((initial && pageNumber === 0) || !hasMore || (pageNumber === 0 && hasMore)) {
            return;
        }

        if (initial) {
            setInitial(false);
            return;
        }

        setLoading(true);
    }, [pageNumber]);

    const searchOtherDebounce = useMemo(() => {
        return _.debounce((searchVal: string) => {
            setHasMore(true);
            setQuery(searchVal);
            setPageNumber(0);

            searchOther(searchVal);
        }, 300);
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        searchOtherDebounce(event.target.value);
    };
    return (
        <>
            <Wrapper>
                {loading && <LoaderOverlay />}
                <CustomRow>
                    <CustomCol md={12} lg={12}>
                        <HeadingWrapper>
                            <Button to="/team">
                                <i className="fal fa-long-arrow-left"></i>
                            </Button>
                            <Header>Others</Header>
                        </HeadingWrapper>
                        <OnSamllViewPort hasId={router.query['*']}>
                            <SearchField value={value} handleChange={handleChange} name={`Other`} />
                            <List>
                                {other !== undefined &&
                                    other.other.map((other: IOther, i: any) => {
                                        const queryId = router.query[Object.keys(router.query)[0]];
                                        return (
                                            <ContentList
                                                key={i}
                                                queryId={`${
                                                    (queryId as string) === other._id
                                                        ? 'isActivelist'
                                                        : ''
                                                }`}
                                                link={`/team/other-list/${other._id}`}
                                                icon={other.photo}
                                                name={other.name}
                                            />
                                        );
                                    })}
                                <div ref={loaderRef}></div>
                            </List>
                            <Col>
                                <AddIcon to="/team/other/create">
                                    <i className="fal fa-plus"></i>
                                </AddIcon>
                            </Col>
                        </OnSamllViewPort>
                    </CustomCol>
                </CustomRow>
            </Wrapper>
        </>
    );
});

const Wrapper = styled(Container)`
    flex: 0 0 25rem;
    margin-left: 0rem;

    ${media.xs`
    padding-top: 1rem;
    padding: auto;
    flex: 0 0 25rem;
    `}
    ${media.sm`
    margin-top: 1rem;
    flex: 0 0 25rem;
     background-color: white;
    `}
	${media.md`
    margin-top: 0rem;
    padding: 0;
    background-color: white;
     flex: 0 0 20rem;
    `} 
	@media screen and (min-width: 1076px) and (max-width: 1140px) {
        flex: 0 0 20rem;
    }
    @media screen and (min-width: 1032px) and (max-width: 1075px) {
        flex: 0 0 18rem;
    }
    @media screen and (min-width: 992px) and (max-width: 1031px) {
        flex: 0 0 16rem;
    }

    ${media.lg`
    margin-top: 0rem;
    padding: 0;
    background-color: white;
    `}
    ${media.xl`
    margin-top: 0rem;
    padding: 0;
    background-color: white;
`}
`;
const CustomRow = styled(Row)`
    position: relative;
    margin: 0;
    ${media.xs`
    background-color: white;
`}
`;
const CustomCol = styled(Col)`
    padding: 0;
`;
const Header = styled.h1`
    font-size: 1.78rem;
    font-weight: 600;
`;
const HeadingWrapper = styled.div`
    padding: 1.42rem 0rem 0.85rem 1.71rem;
    display: flex;
    align-items: center;
    justify-content: start;
`;
const AddIcon = styled(Link)`
    position: fixed;
    bottom: 1.71rem;
    // right: 2.14rem;
    width: 3.42rem;
    height: 3.42rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1rem 1.71rem 0 rgb(0 0 0 / 28%);
    background: #0154ff;
    border-radius: 100%;
    color: #fff;
    font-size: 1.5rem;
`;

const Button = styled(Link)`
    margin-right: 1.25rem /* 20px */;
    font-size: 1.875rem /* 30px */;
    line-height: 2.25rem /* 36px */;
`;
const List = styled.div`
    margin-top: 1rem;
    //height: 40rem;
    overflow: auto;
`;
const OnSamllViewPort = styled.span<IProps>`
    display: block;
    ${media.xs`
	display: ${({ hasId }: any) => (hasId ? 'none' : 'block')};
	`}
    ${media.sm`
	display: ${({ hasId }: any) => (hasId ? 'none' : 'block')};
	`}
	${media.md`
    display: block;
	`}
	${media.lg`
    display: block;
	`}
`;
export default OthersList;
