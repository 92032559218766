import Button from '@components/Button';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { palette } from 'styled/common';
export interface IDeleteModel {
    id: string;
    onClose(): void;
    onDelete(): void;
    onCLick(): void;
    loading: any;
    name: string;
    content: string;
}

const DeleteModel = ({ loading, name, onClose, content, onDelete, onCLick }: IDeleteModel) => {
    const closeModal = (event: any) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    return (
        <>
            <Container>
                <Model tabIndex={-1} onClick={closeModal}>
                    <ModelBody lg={12}>
                        <ModelClose>
                            <CloseButton onClick={onClose}>
                                {' '}
                                <i className="fas fa-times"></i>
                            </CloseButton>
                        </ModelClose>
                        <ModelTitle>
                            <DeletIcon>
                                <Icon className="fal fa-trash-alt"></Icon>
                            </DeletIcon>
                            <ModelHeading> Delete {name}?</ModelHeading>
                        </ModelTitle>
                        <ModelContent>{content}</ModelContent>
                        <ModelButtons>
                            <Button
                                ifClicked={onCLick}
                                disabled={loading}
                                bgtype={'primary'}
                                label="Delete"
                                width="13rem !important"></Button>
                            &nbsp;&nbsp;
                            <Button
                                ifClicked={onClose}
                                bgtype={'discard'}
                                label="Close"
                                width="13rem !important"></Button>
                        </ModelButtons>
                    </ModelBody>
                </Model>
            </Container>
        </>
    );
};

const Model = styled(Row)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: ${palette.overlay};
    padding: 1.8rem;
    overflow: auto;
    overscroll-behavior-y: contain;
    z-index: 102;
`;
const ModelBody = styled(Col)`
    display: flex;
    flex-direction: column;
    background: ${palette.white};
    border-radius: 0.6rem;
    box-shadow: 0 1rem 1.8rem -1.4rem ${palette.grey.shadow};
    padding: 1.7rem;
    max-width: 35vw;
    ${media.xs`
        width: 100%;
        max-width: 100%;
    `}
    ${media.sm`
        width: 100%;
        max-width: 60vw;
    `}
    ${media.md`
        width: 100%;
        max-width: 45vw;
    `}
    ${media.lg`
        max-width: 35vw;
    `}
`;
const ModelClose = styled.div`
    display: flex;
    justify-content: end;
    margin-bottom: 0.75rem;
`;
const CloseButton = styled.button`
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${palette.silver};
    background-color: ${palette.grey.lightest};
    border-radius: 0.5rem;
    font-size: 1.2rem;
    border: none;
    cursor: pointer;
`;
const ModelTitle = styled.div`
    display: flex;
    align-items: center;
`;
const DeletIcon = styled.div`
    margin-right: 1rem;
    width: 3rem;
    height: 3rem;
    border-radius: 9999px;
    background-color: ${palette.primary};
    color: ${palette.white};
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Icon = styled.i`
    font-size: 1.25rem;
    line-height: 1.75rem;
`;
const ModelHeading = styled.h1`
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 600;
    ${media.xs`
     font-size: 1.7rem;
    `}
`;
const ModelContent = styled.div`
    flex: 1 1;
    display: flex;
    flex-direction: column;
    padding-top: 1.25rem !important;
    padding-bottom: 2.5rem !important;
    color: rgba(157, 160, 164);
`;

const ModelButtons = styled.div`
    display: flex;
    justify-content: space-between;
`;

export default DeleteModel;
