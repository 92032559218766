import { userService } from 'services';
import { api } from '../helpers/auth-axios';

export interface ICalenderCurrentView {
    current_view: string;
}

let view: any = '';

const user_Data: any = JSON.parse(localStorage.getItem('user') || '{}');

// getUser(user_Data.accessToken);

// async function getUser(accessToken: any) {
//     let res: any = await api.post('/auth/success', { accessToken: accessToken });
//     view = res.data.user.current_view;
// }

const INITIAL_STATE: ICalenderCurrentView = {
    current_view: user_Data?.user?.current_view,
};
export function calenderCurrentView(state = INITIAL_STATE, action: any) {
    if (action.type == 'current_view') {
        return {
            current_view: action.current_view,
        };
    } else {
        return {
            current_view: state.current_view,
        };
    }
}
