import { Spinner, Switch, useSnackbar } from '@components/common';
import { customerActions } from 'actions/customer';
import { api } from 'helpers/auth-axios';
import { useAppDispatch } from 'hooks/redux';
import { ICustomer } from 'interfaces/customer.interface';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';

const CustomerNotifications = ({ customer }: { customer: ICustomer }) => {
    const dispatch = useAppDispatch();

    const [loading, setLoading] = useState(false);
    const [showActions, setShowActions] = useState(false);
    const [emailSwitch, setEmailSwitch] = useState(customer.notifications.email);
    const [smsSwitch, setSmsSwitch] = useState(customer.notifications.sms);
    const [openSnakebar] = useSnackbar();
    const onSubmit = () => {
        setLoading(true);
        api.put(`/customers/${customer._id}`, {
            notifications: { email: emailSwitch, sms: smsSwitch },
        }).then(res => {
            openSnakebar('Notification saved successfully!');
            setLoading(false);
            setShowActions(false);
        });
    };

    const onSwitch = (val: boolean) => {
        setShowActions(true);
    };

    const discard = () => {
        setEmailSwitch(customer.notifications.email);
        setSmsSwitch(customer.notifications.sms);
        setShowActions(false);
    };

    useEffect(() => {
        setEmailSwitch(customer.notifications.email);
        setSmsSwitch(customer.notifications.sms);
    }, [customer]);
    return (
        <>
            <Container>
                <Row>
                    <Col lg={12} style={{ padding: '0' }}>
                        <Options>
                            <div>
                                <Icon className="fas fa-mail-bulk"></Icon>
                                Email Notifications
                            </div>
                            <Switch
                                onChange={(val: boolean) => {
                                    onSwitch(val);
                                    setEmailSwitch(val);
                                }}
                                value={emailSwitch}
                            />
                        </Options>
                        <Hr />
                        <Options>
                            <div>
                                <Icon className="fas fa-sms"></Icon>SMS Notifications
                            </div>
                            <Switch
                                onChange={(val: boolean) => {
                                    onSwitch(val);
                                    setSmsSwitch(val);
                                }}
                                value={smsSwitch}
                            />
                        </Options>
                    </Col>
                </Row>
            </Container>

            {showActions && (
                <ButtonModel>
                    <DiscardButton onClick={discard}>Discard</DiscardButton>
                    <SaveButton onClick={onSubmit} disabled={loading}>
                        {loading ? <Spinner /> : 'Save Changes'}
                    </SaveButton>
                </ButtonModel>
            )}
        </>
    );
};

const Options = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`;

const Icon = styled.i`
    color: rgba(181, 181, 195);
    font-size: 1.25rem /* 20px */;
    line-height: 1.75rem /* 28px */;
    margin-right: 0.5rem /* 8px */;
`;

const Hr = styled.hr`
    margin-top: 0.75rem /* 12px */;
    margin-bottom: 0.75rem /* 12px */;
`;
const ButtonModel = styled.div`
    border-top-width: 1px;
    padding-top: 1.5rem /* 24px */;
    margin-top: 1.25rem /* 20px */;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0.75rem /* 12px */;
`;

const DiscardButton = styled.button`
    padding: 0.92rem 1.85rem;
    font-weight: 600;
    text-transform: capitalize;
    background-color: rgba(255, 255, 255);
    border-radius: 0.57rem;
    font-size: 0.92rem;
    color: rgba(38, 52, 70);
    border-width: 1px !important;
    border: 1px solid rgba(223, 226, 230);
    cursor: pointer;
`;
const SaveButton = styled.button`
    adding: 0.92rem 1.85rem;
    font-weight: 600;
    text-transform: capitalize;
    color: #fff;
    background: #27292e;
    border-radius: 0.57rem;
    font-size: 0.92rem;
    border: none;
    cursor: pointer;
`;
export default CustomerNotifications;
