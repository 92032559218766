import { Input, Select, Spinner } from '@components/common';
import styled from 'styled-components';
import { appointmentActions } from 'actions/appointment';
import { EndRepeatBookingEnum, RepeatBookingEnum } from 'constants/enums';
import { useAppDispatch } from 'hooks/redux';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Button from '@components/Button';
const repeatOptions = [
    { label: 'Daily', value: RepeatBookingEnum.DAILY },
    { label: 'Weekly', value: RepeatBookingEnum.WEEKLY },
    // { label: 'Every 2 weeks', value: RepeatBookingEnum.EVERY_2_WEEKS },
    { label: 'Monthly', value: RepeatBookingEnum.MONTHLY },
];
const addToDate = (date: any, type: RepeatBookingEnum) => {
    switch (type) {
        case RepeatBookingEnum.DAILY:
            return date.add(1, 'day');
        case RepeatBookingEnum.WEEKLY:
            return date.add(1, 'week');
        // case RepeatBookingEnum.EVERY_2_WEEKS:
        //     return date.add(2, 'weeks');
        case RepeatBookingEnum.MONTHLY:
            return date.add(1, 'month');
        default:
            break;
    }
};
// const endOptions = [{ label: 'Never (max 30 appointments)', value: EndRepeatBookingEnum.NEVER }];
// interface Props {
//     active: any;
// }
const days = [
    { day: 1, label: '1' },
    { day: 2, label: '2' },
    { day: 3, label: '3' },
    { day: 4, label: '4' },
    { day: 5, label: '5' },
    { day: 6, label: '6' },
];
const NewRecurringModal = ({
    start,
    end,
    onClose,
    onSave,
    recurring,
}: {
    start: any;
    end: any;
    onClose(): void;
    onSave(repeating: any): void;
    recurring: any;
}) => {
    useEffect(() => {
        if (recurring != undefined) {
            setRepeatType(recurring.repeat_type);
            setRepeat_every(recurring.repeat_every);
            setRepeat_till(moment(recurring.repeat_till).format('YYYY-MM-DD'));
            recurring.on.length &&
                recurring.on.map((id: any, index: any) => {
                    setWeek_on(preValue => {
                        preValue.map(val => {
                            if (val.day == id && val.active == false) {
                                val.active = true;
                                onDay.push(val.day);
                            } else if (val.day == id && val.active == true) {
                                val.active = false;
                                const dayOn = onDay.filter((item: any) => item !== val.day);
                                setOnDay(dayOn);
                            }
                        });
                        return [...preValue];
                    });
                });
        } else {
            activeCurrentDay();
        }
    }, []);

    const [loading, setLoading] = useState(false);
    const [repeatType, setRepeatType] = useState<RepeatBookingEnum>(RepeatBookingEnum.DAILY);
    const [endType, setEndType] = useState<EndRepeatBookingEnum>(EndRepeatBookingEnum.NEVER);
    const [repeat_every, setRepeat_every] = useState();
    const [onDay, setOnDay] = useState<number[]>([]);
    const [week_on, setWeek_on] = useState([
        { day: 1, label: 'M', active: false },
        { day: 2, label: 'T', active: false },
        { day: 3, label: 'W', active: false },
        { day: 4, label: 'T', active: false },
        { day: 5, label: 'F', active: false },
        { day: 6, label: 'S', active: false },
        { day: 0, label: 'S', active: false },
    ]);
    const [repeat_till, setRepeat_till] = useState<any>(
        recurring != undefined ? recurring.repeat_till : ''
    );

    const closeModal = (event: any) => {
        // event.preventDefault();
        if (event.target === event.currentTarget) {
            onClose();
        }
    };
    const save = () => {
        setLoading(true);
        const repeat_From = start.format('YYYY-MM-DD') + 'T' + start.format('HH:mm');
        const repeat_Till = repeat_till
            ? moment(repeat_till).format('YYYY-MM-DD') + 'T' + start.format('HH:mm')
            : moment().format('YYYY-MM-DD') + 'T' + start.format('HH:mm');
        const repeating = {
            repeat_from: moment(repeat_From).format(),
            repeat_till: moment(repeat_Till).format(),
            repeat_every: repeat_every ? repeat_every : '1',
            repeat_type: repeatType,
            end_type: endType,
            on: repeatType === 'weekly' ? onDay : [],
        };
        onSave(repeating);
    };
    const getCurrentDate = moment(start).weekday();
    const activeCurrentDay = () => {
        week_on.map(day => {
            if (day.day === getCurrentDate) {
                onDay.push(day.day);
                return (day.active = true);
            } else if (day.day !== getCurrentDate) {
                const dayOn = onDay.filter((item: any) => item !== day.day);
                setOnDay(dayOn);
                return (day.active = false);
            }
        });
    };
    const onDayClick = (id: any) => {
        setWeek_on(preValue => {
            preValue.map(val => {
                if (val.day == id && val.active == false) {
                    val.active = true;
                    onDay.push(val.day);
                } else if (val.day == id && val.active == true) {
                    val.active = false;
                    const dayOn = onDay.filter((item: any) => item !== val.day);
                    setOnDay(dayOn);
                }
            });
            return [...preValue];
        });
    };
    const reapeatTill = (e: any) => {
        setRepeat_till(e.value);
    };

    return (
        <Model tabIndex={-1} onClick={closeModal}>
            <ModelBody style={{ width: '480px' }}>
                <ModelHead>
                    <Cover onClick={onClose}>
                        <i className="fas fa-times"></i>
                    </Cover>
                </ModelHead>
                <ModelHeading>
                    <Heading>Recurring Appointment</Heading>
                </ModelHeading>
                <ModelContent>
                    <InputGroup>
                        <Select
                            label="Select repeat rule"
                            value={repeatType}
                            options={repeatOptions}
                            onChange={(val: RepeatBookingEnum) => {
                                setRepeatType(val);
                            }}
                        />
                    </InputGroup>
                    <InputGroup>
                        <Select
                            label={`Every(${repeatType})`}
                            value={repeat_every ? repeat_every : '1'}
                            options={days}
                            onChange={(val: any) => {
                                setRepeat_every(val);
                            }}
                        />
                        {repeatType && repeatType === 'weekly' ? (
                            <InputGroup>
                                <DaysName>
                                    {week_on.map((data: any, i: any) => {
                                        return (
                                            <Options
                                                active={data.active}
                                                onClick={() => {
                                                    onDayClick(data.day);
                                                }}>
                                                {data.label}
                                            </Options>
                                        );
                                    })}
                                </DaysName>
                            </InputGroup>
                        ) : (
                            ''
                        )}
                    </InputGroup>

                    <InputGroup>
                        <Input
                            type="date"
                            label="Repeat till"
                            name="repeat_till"
                            min={new Date().toISOString().split('T')[0]}
                            value={repeat_till ? repeat_till : moment().format('YYYY-MM-DD')}
                            onChange={(e: any) => reapeatTill(e)}
                        />
                    </InputGroup>
                </ModelContent>
                <Footer>
                    <Button
                        ifClicked={save}
                        bgtype={'secondary'}
                        disabled={loading}
                        label="Apply"></Button>
                    <Button ifClicked={onClose} bgtype={'discard'} label="Close"></Button>
                </Footer>
            </ModelBody>
        </Model>
    );
};
const Model = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 24px;
    overflow: auto;
    overscroll-behavior-y: contain;
    z-index: 102;
`;
const ModelBody = styled.div`
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 15px 25px -6px rgb(0 0 0 / 10%);
    padding: 24px;
    max-width: 82vw;
`;
const ModelHead = styled.div`
    display: flex;
    justify-content: end;
    margin-bottom: 0.75rem;
`;
const Cover = styled.div`
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3f4254;
    background-color: #e4e6ef;
    border-radius: 8px;
    font-size: 1.2rem;
    cursor: pointer;
`;
const ModelHeading = styled.div`
    align-items: center !important;
    display: flex !important;
`;
const Heading = styled.h1`
    font-weight: 600 !important;
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
    margin-bottom: 0px !important;
`;
const ModelContent = styled.div`
    padding: 2rem 0 1rem 0 !important;
    color: rgba(157, 160, 164) !important;
`;
const Text = styled.div`
    margin-bottom: 1.5rem !important;
    color: rgba(157, 160, 164) !important;
`;
const InputGroup = styled.div`
    margin-bottom: 1.71rem;
`;
const Footer = styled.div`
    display: flex;
    justify-content: space-between;
`;
const DaysName = styled.div`
    display: flex;
    flex-direction: row;
`;
const Options = styled.div<any>`
    margin-top: 0.5rem;
    height: 2rem;
    width: 2rem;
    border: 1px solid #e5e7eb;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: ${({ active }: any) => (active ? `rgb(57, 57, 185)` : 'white')};

    color: ${({ active }: any) => (active ? `white` : 'rgb(57, 57, 185)')};
`;
export default NewRecurringModal;
