import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { Aside, Header } from '@components/common';
import s from './Layout.module.scss';
import { routes } from 'constants/routes';
import useRouter from 'hooks/router';
interface Props {
    admin?: any;
}
let _user: any = null;
const Layout: FC<Props> = ({ children, admin, ...props }) => {
    const router = useRouter();
    _user = router.pathname;
    const activeRoute = () => {
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].patterns) {
                for (let j = 0; j < routes[i].patterns!.length; j++) {
                    if (router.pathname === routes[i].patterns![j].path) {
                        return routes[i].patterns![j];
                    }
                }
            }
            if (router.pathname === routes[i].path) {
                return routes[i];
            }
        }
    };

    return (
        <>
            <Wrapper>
                {_user == '/admin' ? '' : <Aside />}
                <PageContent admin={_user}>
                    <Header activeRoute={activeRoute()!} />
                    <Content>{children}</Content>
                </PageContent>
            </Wrapper>
        </>
    );
};
const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
    flex-direction: row !important;
    display: flex !important;
`;
const PageContent = styled.div<Props>`
    ${_user == '/admin' ? 'margin-left: 0rem' : 'margin-left: 18.6rem'};
    flex-direction: column !important;
    flex-grow: 1 !important;
    display: flex !important;
    position: relative;
    ${media.xs`
    margin-left: 0rem;
  `}
    ${media.sm`
    margin-left: 0rem;
  `}
    ${media.md`
	margin-left: 0rem;
  `}

    ${media.lg`
    margin-left :${({ admin }: any) => (admin == '/admin' ? '0rem' : '18.6rem')};
  `}
`;

const Content = styled.div`
    padding-left: 1.5rem;
    padding-top: 1.5rem;
    flex: 1;
    display: flex;
    flex-direction: column;
`;
export default Layout;
