import useRouter from 'hooks/router';

import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import { serviceActions } from 'actions/service';

import { ITeam } from 'interfaces/team/teamMember.interface';
import { teamActions } from 'actions/team/teamMember';
import { Tab, TabPanel, Tabs, useSnackbar } from '@components/common';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import TabContext from '@components/common/TabContext';
import Card from '@components/common/Cards/Card';
import TimeOff from '@components/common/TimeOff/TimeOff';
import Permissions from '@components/common/Permissions/Permissions';
import WorkingHours from '@components/common/WorkingHours/WorkingHours';
import Breaks from '@components/common/Breaks/Breaks';
import DeleteModel from '@components/common/DeleteModel/DeleteModel';
import { palette } from 'styled/common';
import Accordion from '@components/Accordion';
import { api } from 'helpers/auth-axios';
import { RoleEnum } from 'constants/enums';
const TeamCard = ({ callTeam }: any) => {
    const [team, setTeam] = useState<ITeam>();
    const [tabValue, setTabValue] = useState(0);
    const [openDeleteModel, setOpenDeleteModel] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleteId, setDeleteId] = useState<any>();
    const router = useRouter();
    const dispatch = useAppDispatch();
    const [businessId, setBusinessId] = useState<any>();
    const [openSnackbar] = useSnackbar();

    const _userData = JSON.parse(localStorage.getItem('user') || '{}');

    const teamMemberId: string = router.query?.id ?? _userData.user._id;

    // Is set to True if current user is the team member being viewed
    const viewingOwnProfile =
        _userData.user_login !== RoleEnum.USER && team?._id === _userData.user._id;

    const data = () => {
        api.get(`/team/${teamMemberId}`).then((res: any) => {
            if (res.data !== 0) {
                setTeam(res.data);
                setBusinessId(res.data.business_id);
            }
        });
    };
    useEffect(() => {
        data();
    }, [dispatch, teamMemberId]);

    const onChange = (day: any) => {};
    const deleteModel = (id: any) => {
        setOpenDeleteModel(true);
        setDeleteId(id);
    };

    const deleteTeamMember = (id: any) => {
        setLoading(true);

        api.delete(`/team/${id}`)
            .then((res: any) => {
                setLoading(false);
                router.navigate('/team/team-list');
                openSnackbar('Team Member deleted successfully!');
                setOpenDeleteModel(false);
                callTeam();
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };
    const tabsNameList = [
        {
            name: 'Profile',
            content: (
                <Card
                    link={`/team/team-member/${teamMemberId}/edit`}
                    model={() => deleteModel(teamMemberId)}
                    nameData={team?.name}
                    name={team?.name}
                    phone={team?.phone}
                    email={team?.email}
                    profileImage={team?.photo}
                    accountObject={team}
                    reloadAccountObject={() => data()}
                    accountRole={RoleEnum.TEAMMEMBER}
                />
            ),
        },
        {
            name: 'Working Hours',
            content: (
                <WorkingHours
                    id={teamMemberId}
                    active={true}
                    onChange={onChange}
                    business_id={businessId}
                    readOnly={viewingOwnProfile && !_userData.user.permissions.hours}
                />
            ),
        },
        {
            name: 'Breaks',
            content: (
                <Breaks
                    id={teamMemberId}
                    day={{ day: 1, from: '10:00', to: '19:00' }}
                    onChang={onChange}
                    readOnly={viewingOwnProfile && !_userData.user.permissions.breaks}
                />
            ),
        },
        {
            name: 'Time Off',
            content: (
                <TimeOff
                    id={teamMemberId}
                    readOnly={viewingOwnProfile && !_userData.user.permissions.time_off}
                />
            ),
        },
        {
            name: 'Permissions',
            content: (
                <Permissions
                    id={teamMemberId}
                    businessId={businessId}
                    userRole={RoleEnum.TEAMMEMBER}
                />
            ),
        },
    ];

    return (
        <>
            <MainContainer>
                <Row>
                    {team && (
                        <>
                            <WebView lg={12}>{tabsNameList[0].content}</WebView>
                            <WebView lg={12}>
                                <TabsCard>
                                    <TabContext value={tabValue}>
                                        <Tabs
                                            onChange={(event, newValue) => {
                                                setTabValue(newValue);
                                            }}>
                                            <Tab label={tabsNameList[1].name} />
                                            <Tab label={tabsNameList[2].name} />
                                            <Tab label={tabsNameList[3].name} />
                                            <Tab label={tabsNameList[4].name} />
                                        </Tabs>
                                        <TabPanel value={0} preload={true}>
                                            <TabContent>{tabsNameList[1].content}</TabContent>
                                        </TabPanel>
                                        <TabPanel value={1} preload={true}>
                                            <TabContent>{tabsNameList[2].content}</TabContent>
                                        </TabPanel>
                                        <TabPanel value={2} preload={true}>
                                            <TabContent>{tabsNameList[3].content}</TabContent>
                                        </TabPanel>
                                        <TabPanel value={3} preload={true}>
                                            <TabContent>{tabsNameList[4].content}</TabContent>
                                        </TabPanel>
                                    </TabContext>
                                </TabsCard>
                            </WebView>
                            <MobileView lg={12}>
                                <Accordion tabsNameList={tabsNameList} />
                            </MobileView>

                            {openDeleteModel && team && (
                                <DeleteModel
                                    id={team._id}
                                    onDelete={() => {
                                        setOpenDeleteModel(false);
                                    }}
                                    onClose={() => {
                                        setOpenDeleteModel(false);
                                    }}
                                    name="Team Member"
                                    content=" If you delete this team member you will lose all information connected
									to their profile including working hours, breake hours, time off and
									permissions. This action cannot be undone."
                                    loading={loading}
                                    onCLick={() => deleteTeamMember(deleteId)}
                                />
                            )}
                        </>
                    )}
                </Row>
            </MainContainer>
        </>
    );
};
const MainContainer = styled(Container)`
    height: 100vh;
    overflow-y: scroll;
    padding: 1.71rem;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    ${media.xs`
	padding: 1.1rem;
    overflow-x: hidden;
    overflow-y: auto;
	`}
    ${media.sm`
    padding: 0rem;
    padding-top: 1rem;
    overflow-x: hidden;
    overflow-y: auto;
	`}
	${media.md`
	  padding: 1.71rem;
	`}
	${media.lg`
	  padding: 1.71rem;
	`}
`;
const WebView = styled(Col)`
    display: block;
    @media screen and (min-width: 1px) and (max-width: 900px) {
        display: none;
    }
`;
const MobileView = styled(Col)`
    display: none;
    @media screen and (min-width: 1px) and (max-width: 900px) {
        display: block;
    }
`;
const TabsCard = styled.div`
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: ${palette.light};
    background-clip: border-box;
    border-radius: 0.42rem;
    box-shadow: 0rem 0rem 2.2rem 0rem ${palette.grey.shadow};
    border: 0;
`;

const TabContent = styled.div`
    padding: 1.3rem;
    flex: 1;
`;
export default TeamCard;
