import React from 'react';
import styled from 'styled-components';
import { Container, Col, Row, media } from 'styled-bootstrap-grid';
import bg from '../../../images/bg-login.jpg';
import logo from '../../../images/logo-white-font.png';
import illustration from '../../../images/appointment-bg-1.svg';

const SideCover = () => {
    return (
        <>
            <SideImage md={6} lg={7}>
                <CardLeft>
                    <FlexCenter>
                        <Cover>
                            <Logo src={logo} alt="Logo" />
                        </Cover>
                    </FlexCenter>
                    <ExclusivelyContent>
                        <Para>Build an exclusive experience for your customers.</Para>
                    </ExclusivelyContent>
                </CardLeft>
            </SideImage>
        </>
    );
};
const SideImage = styled(Col)`
    padding: 0;
    display: flex;
    ${media.xs`
    display:none;
	`}
    ${media.sm`
    display:none;
	`}
	${media.md`
    display:none;
	`}
	${media.lg`
    display: flex;
    flex-direction: column;
    justify-content: center;
	`}
`;

const Logo = styled.img`
    width: 100%;
`;

const CardLeft = styled.div`
    background-color: #000;
    background: url('${bg}') center center;
    background-size: cover;
    padding-top: 3.5rem !important;
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const FlexCenter = styled.div`
    display: flex;
    justify-content: center;
`;

const Cover = styled.div`
    width: 30rem;
    height: 2.42rem;
`;

const ExclusivelyContent = styled.div`
    padding-top: 3.5rem !important;
`;

const Text = styled.h1`
    color: rgba(255, 255, 255) !important;
    font-weight: 600 !important;
    font-size: 3rem !important;
    line-height: 1 !important;
    text-align: center !important;
`;

const Para = styled.p`
    color: rgba(255, 255, 255) !important;
    font-weight: 100 !important;
    font-size: 1.5rem !important;
    line-height: 1.75rem !important;
    text-align: center !important;
    padding: 1rem 7rem;
`;

export default SideCover;
