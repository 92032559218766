import React, { useEffect, useRef, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import { Col, Container, media, Row } from 'styled-bootstrap-grid';
import { Layout, OthersCard, ServiceProviderCard } from '@components/team';
import { RoleEnum } from 'constants/enums';
import TeamMemberCard from '@components/team/Cards/TeamMemberCard';

const Profile = () => {
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');

    return (
        <Wrapper>
            <Routes>
                {_userData.user_login == RoleEnum.SERVICEPROVIDER && (
                    <Route path="/" element={<ServiceProviderCard />} />
                )}
                {_userData.user_login == RoleEnum.TEAMMEMBER && (
                    <Route path="/" element={<TeamMemberCard />} />
                )}
                {_userData.user_login == RoleEnum.OTHERS && (
                    <Route path="/" element={<OthersCard />} />
                )}
            </Routes>
        </Wrapper>
    );
};

const Wrapper = styled(Container)`
    padding: 0px;
    height: 100%;

    ${media.xs`
	display: block !important;
    `}
    ${media.sm`
    display: block !important;
    `}
    ${media.md`
    display: flex !important;
    margin:0;
    min-width:100%;
    `}
    ${media.lg`
    display: flex !important;
    `}
`;

Profile.Layout = Layout;
export default Profile;
