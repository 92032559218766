import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { ICustomer } from 'interfaces/customer.interface';
import { IService } from 'interfaces/service.interface';
import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';

import BasketSidebar from './BasketSidebar';
import SelectServices from './SelectServices';
import PaymentMethods from './PaymentMethods';
import { useEffect } from 'react';
import { api } from 'helpers/auth-axios';

import finishImg from 'images/illustrations/finish-success.svg';
import { LoaderOverlay, useSnackbar } from '@components/common';
import NewCreditCard from '@components/settings/BusinesBilling/CreditCard';
import useRouter from 'hooks/router';
import moment from 'moment';

const Checkout = () => {
    const [finished, setFinished] = useState(false);
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [subtotal, setSubtotal] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [paymentMethod, setPaymentMethod] = useState({
        code: 'cash',
        label: 'Cash',
    });
    const [gratuity, setGratuity] = useState<any>({ label: 'No Tip', value: 0 });
    const [selectedCustomer, setSelectedCustomer] = useState<any>();
    const [selectedServices, setSelectedServices] = useState<any[]>([]);
    const [openCard, setOpenCard] = useState<boolean>(false);
    const [paymentIntentId, setPaymentIntentId] = useState<any>(null);
    const [paymentMethodId, setPaymentMethodId] = useState<any>(null);
    const [business, setBusiness] = useState<any>();
    const [openSnackbar] = useSnackbar();
    const [customTip, setCustomTip] = useState<any>();
    const router = useRouter();
    const onBack = () => {
        setStep(prevValue => prevValue - 1);
    };

    const onNext = () => {
        setStep(prevValue => prevValue + 1);
    };

    const handlePaymentGratuity = (
        method: { code: string; label: string },
        gratuity: { label: string; value: number }
    ) => {
        setGratuity(gratuity);
        setPaymentMethod(method);
    };
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const transaction_details = {
        customer: selectedCustomer?._id,
        total: _userData.user.business_id.currency + total.toFixed(2),
        total_value: total.toFixed(2),
        items: selectedServices.map(service => {
            return {
                price: _userData.user.business_id.currency + service.price,
                price_value: service.price,
                quantity: 1,
                service_name: service.name,
                service_id: service._id,
                tax_rate: service.tax_rate,
            };
        }),
        payments: [
            {
                amount: _userData.user.business_id.currency + total.toFixed(2),
                amount_value: total.toFixed(2),
                payment_method: paymentMethod,
                tip: gratuity.value ? gratuity.value : 0,
            },
        ],
        payment_method: paymentMethod,
        tip: {
            amount:
                _userData.user.business_id.currency +
                (gratuity.value ? parseFloat(gratuity.value).toFixed(2) : 0),
            amount_value: gratuity.value ? parseFloat(gratuity.value).toFixed(2) : 0,
            label: gratuity.label,
        },
    };
    const handleCharge = () => {
        setLoading(true);
        api.post('/transactions', transaction_details)
            .then(res => {
                if (res) {
                    setLoading(false);
                    setFinished(true);
                    openSnackbar('Transaction successfull!');
                    router.navigate(`/sales`);
                    setSelectedServices([]);
                    setSelectedCustomer('');
                    setSubtotal(0);
                    setGratuity({ label: 'No Tip', value: 0 });
                    setPaymentMethod({
                        code: 'cash',
                        label: 'Cash',
                    });
                    setFinished(false);
                    setStep(3);
                }
            })
            .catch((e: any) => {
                setLoading(false);
                openSnackbar('Failed!');
            });
    };

    useEffect(() => {
        setTotal(gratuity.value ? parseFloat(gratuity.value) + subtotal : 0 + subtotal);
        let serviceTotal = selectedServices.reduce((a, b) => a + parseInt(b.price), 0);
        setSubtotal(
            paymentMethod !== undefined &&
                (paymentMethod.code === 'paypal' || paymentMethod.code === 'american_express')
                ? serviceTotal + 1
                : serviceTotal
        );
    }, [subtotal, gratuity, customTip, paymentMethod]);
    const createPaymentIntent = useCallback(
        (customerData: any, total) => {
            if (!_userData || !selectedServices) return;
            let subTotal: any = total * 100;
            api.post('/billing/payment-intent', {
                business_id: business?._id,
                stripe_account_id: business && business.stripe_account_id,
                amount: parseFloat(subTotal),
                customer_details: {
                    firstname: customerData.firstname,
                    lastname: customerData.lastname,
                    email: customerData.email,
                    phone: customerData.phone,
                },
                description: {
                    service_name: selectedServices.map(service => service.name),
                    staff_name: customerData.firstname + ' ' + customerData.lastname,
                    date: moment().format('YYYY-MM-DD'),
                    service_tip: gratuity,
                },
            })
                .then(res => {
                    const data = res.data as any;
                    setPaymentIntentId(data.payment_intent_id);
                    setStep(prevVal => prevVal + 1); // move to next step
                })
                .catch((e: any) => {
                    if (e?.response) {
                        openSnackbar(e?.response?.data?.message);
                    }
                });
        },
        [selectedServices]
    );

    const getBusiness = () => {
        api.get(`/businesses/${_userData?.user?.business_id?._id}`).then((res: any) => {
            if (res) {
                setBusiness(res.data);
            }
        });
    };
    useEffect(() => {
        getBusiness();
    }, []);
    // When both the payment intent and the payment method are gathered
    // make the actual transaction
    useEffect(() => {
        if (paymentIntentId !== null && paymentMethodId !== null) {
            const customerData = {
                firstname: selectedCustomer?.firstname,
                lastname: selectedCustomer?.lastname,
                email: selectedCustomer?.email,
                phone: selectedCustomer?.phone,
            };
            setLoading(true);
            api.post('/appointments/payment/pay', {
                stripe_account_id: business && business.stripe_account_id,
                payment_intent_id: paymentIntentId,
                payment_method_id: paymentMethodId,
                appointment_details: {
                    sale: 'sale',
                    booking_without_payment: false,
                    stripe_account_verified: business && business.stripe_account_verified,
                },
                customer_details: customerData,
                transaction_details: transaction_details,
                business_id: business?._id,
            })
                .then(res => {
                    if (res) {
                        setLoading(false);
                        setSelectedServices([]);
                        setSelectedCustomer('');
                        setSubtotal(0);
                        setGratuity({ label: 'No Tip', value: 0 });
                        setPaymentMethod({
                            code: 'cash',
                            label: 'Cash',
                        });
                        setFinished(false);
                        setOpenCard(false);
                        setPaymentIntentId(null);
                        setPaymentMethodId(null);
                        setStep(3);
                        openSnackbar('Payed successfully!');
                        // router.navigate(`/sales`);
                    }
                })
                .catch((e: any) => {
                    setLoading(false);
                    if (e?.response) {
                        openSnackbar(e?.response?.data?.message);
                    }
                });
        }
    }, [paymentMethodId, paymentIntentId]);
    return (
        <MainContainer>
            {loading && <LoaderOverlay />}
            {!finished && (
                <Container>
                    {openCard ? (
                        business && business.stripe_account_verified ? (
                            <NewCreditCard
                                buttonTitle={`${
                                    loading == true ? 'Processing please wait' : 'Charge'
                                }`}
                                stripeAccountId={business && business.stripe_account_id}
                                onSave={(paymentMethodId: string) => {
                                    setPaymentMethodId(paymentMethodId);
                                }}
                            />
                        ) : (
                            <div>Please Connect your account with stripe first!</div>
                        )
                    ) : (
                        <Row>
                            {step == 1 && (
                                <>
                                    <Col md={7} lg={8}>
                                        <TitleBody>
                                            {/* <CustomLink to="/sales">
                                                <Icon className="fal fa-times"></Icon>
                                            </CustomLink> */}
                                            &nbsp; &nbsp;
                                            <Heading>Add New Sale</Heading>
                                        </TitleBody>

                                        <SelectServices
                                            selectedServices={selectedServices}
                                            onSelect={(services: IService[]) => {
                                                setSelectedServices(services);

                                                setGratuity({ label: 'No Tip', value: 0 });
                                            }}
                                            onClose={() => {}}
                                        />
                                    </Col>
                                    <BasketSidebar
                                        step={step}
                                        onNext={onNext}
                                        customer={selectedCustomer}
                                        items={selectedServices}
                                        subtotal={subtotal}
                                        gratuity={gratuity.value}
                                        onSelectCustomer={(customer: ICustomer) => {
                                            setSelectedCustomer(customer);
                                        }}
                                        onCharge={() => ''}
                                    />
                                </>
                            )}{' '}
                            {step == 2 && (
                                <>
                                    <PaymentMethods
                                        subtotal={subtotal}
                                        gratuity={gratuity}
                                        paymentMethod={paymentMethod}
                                        customTip={(val: any) => setCustomTip(val)}
                                        onBack={onBack}
                                        onChange={handlePaymentGratuity}
                                    />
                                    <BasketSidebar
                                        step={step}
                                        onNext={onNext}
                                        customer={selectedCustomer}
                                        items={selectedServices}
                                        subtotal={subtotal}
                                        gratuity={gratuity.value}
                                        paymentMethod={paymentMethod?.code}
                                        onSelectCustomer={(customer: ICustomer) => {
                                            setSelectedCustomer(customer);
                                        }}
                                        // onCharge={handleCharge}
                                        onCharge={() => {
                                            if (
                                                business &&
                                                business.stripe_account_verified == true &&
                                                (paymentMethod.code == 'paypal' ||
                                                    paymentMethod.code == 'american_express')
                                            ) {
                                                setOpenCard(true);
                                                createPaymentIntent(selectedCustomer, total);
                                            } else if (
                                                paymentMethod.code == 'cash' ||
                                                'cheque' ||
                                                'gift_card'
                                            ) {
                                                handleCharge();
                                            }
                                        }}
                                    />
                                </>
                            )}
                            {step == 3 && (
                                <CheckoutSuccess>
                                    <Image src={finishImg} alt="Finished" />
                                    <SuccessTitle>Checkout complete!</SuccessTitle>
                                    {/* <PriceBody>
                                        <Price>{_userData.user.business_id.currency}0.00</Price>{' '}
                                        change out of {_userData.user.business_id.currency}
                                        {total.toFixed(2)}
                                    </PriceBody> */}
                                    <Button onClick={() => setStep(1)}>Go Back</Button>
                                </CheckoutSuccess>
                            )}
                        </Row>
                    )}
                </Container>
                // ) : (
            )}
        </MainContainer>
    );
};

const MainContainer = styled.div`
    width: 100%;
    position: relative;
    height: 100%;
`;
const TitleBody = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 1.75rem;
`;
const Heading = styled.div`
    font-size: 25px;
    font-weight: 600;
`;
const TitleContent = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1.75rem;
`;

const CustomLink = styled(Link)`
    margin-right: 1.5rem;
`;
const Icon = styled.i`
    font-size: 2.25rem;
    line-height: 2.5rem;
`;
const CheckoutContent = styled.div`
    padding: 24px;
`;
const CheckoutTitle = styled.div`
    font-size: 25px;
    font-weight: 600;
`;
const CheckoutSuccess = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    margin: 0 auto;
`;
const Image = styled.img`
    max-width: 220px;
`;
const PriceBody = styled.div`
    margin-top: 1.25rem /* 20px */;
    margin-bottom: 1.5rem /* 24px */;
    font-size: 1.25rem /* 20px */;
    line-height: 1.75rem /* 28px */;
`;
const Price = styled.span`
    font-weight: 600;
`;
const SuccessTitle = styled.div`
    font-weight: 800;
    font-size: 27px;
`;

const Button = styled.button`
    padding: 13px 26px;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    background: #27292e;
    border-radius: 8px;
    font-size: 13px;
    text-align: center !important;
    border: none;
    cursor: pointer;
`;
export default Checkout;
