import {
    IAppointment,
    IGetAppointments,
    IGetCustomerAppointmentsResponse,
} from 'interfaces/appointment.interface';
import {
    ICustomer,
    IGetCustomers,
    IGetCustomersResponse,
    IGetCustomerWithBookings,
} from 'interfaces/customer.interface';
import { customer } from 'reducers/customer.reducer';

import { Dispatch } from 'redux';
import { customerService } from 'services';

export const customerConstants = {
    CREATE_REQUEST: 'CUSTOMER_CREATE_REQUEST',
    CREATE_SUCCESS: 'CUSTOMER_CREATE_SUCCESS',
    CREATE_FAILURE: 'CUSTOMER_CREATE_FAILURE',

    GET_REQUEST: 'CUSTOMER_GET_REQUEST',
    GET_SUCCESS: 'CUSTOMER_GET_SUCCESS',
    GET_FAILURE: 'CUSTOMER_GET_FAILURE',

    SEARCH_REQUEST: 'CUSTOMER_SEARCH_REQUEST',
    SEARCH_SUCCESS: 'CUSTOMER_SEARCH_SUCCESS',

    UPDATE_REQUEST: 'CUSTOMER_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'CUSTOMER_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'CUSTOMER_UPDATE_FAILURE',

    DELETE_SUCCESS: 'CUSTOMER_DELETE_SUCCESS',
};

const createCustomer =
    (customerData: ICustomer) =>
    (dispatch: Dispatch): Promise<ICustomer> => {
        dispatch({
            type: customerConstants.CREATE_REQUEST,
        });

        return customerService.create(customerData).then(customer => {
            dispatch({
                type: customerConstants.CREATE_SUCCESS,
                customer,
            });

            return customer;
        });
    };

const getBusinessCustomers =
    (getCustomersData: IGetCustomers, overwrite = false) =>
    (dispatch: Dispatch): Promise<IGetCustomersResponse> => {
        return customerService.getBusinessCustomers(getCustomersData).then(customers => {
            if (!overwrite) {
                dispatch({
                    type: customerConstants.GET_SUCCESS,
                    customers,
                });
            } else {
                dispatch({
                    type: customerConstants.SEARCH_SUCCESS,
                    customers,
                });
            }

            return customers;
        });
    };

const searchBusinessCustomers =
    (searchData: IGetCustomers) =>
    (dispatch: Dispatch): Promise<IGetCustomersResponse> => {
        return customerService.searchBusinessCustomers(searchData).then(customers => {
            dispatch({
                type: customerConstants.SEARCH_SUCCESS,
                customers,
            });
            return customers;
        });
    };

const getCustomerById =
    (customerId: string) =>
    (dispatch: Dispatch): Promise<ICustomer> => {
        return customerService.getCustomerById(customerId).then(customer => {
            // dispatch({
            //     type: customerConstants.GET_SUCCESS,
            //     customer,
            // });
            return customer;
        });
    };

const updateCustomerById =
    (id: string, customerDate: any) =>
    (dispatch: Dispatch): Promise<ICustomer> => {
        dispatch({
            type: customerConstants.UPDATE_REQUEST,
        });

        return customerService.updateCustomerById(id, customerDate).then(customer => {
            dispatch({
                type: customerConstants.UPDATE_SUCCESS,
                customer,
            });

            return customer;
        });
    };

const deleteCustomerById =
    (customerId: string) =>
    (dispatch: Dispatch): Promise<IGetCustomerWithBookings> => {
        return customerService.deleteCustomerById(customerId).then(customer => {
            dispatch({
                type: customerConstants.DELETE_SUCCESS,
                customerId,
            });

            return customer;
        });
    };

export const customerActions = {
    createCustomer,
    getBusinessCustomers,
    searchBusinessCustomers,
    getCustomerById,
    updateCustomerById,
    deleteCustomerById,
};
