import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';

import Login from '../pages/Login';
import Register from '../pages/Register';
import Dashboard from '../pages/Dashboard';
import ActivityFeed from 'pages/ActivityFeed';
import Settings from '../pages/Settings';
import Calendar from '../pages/Calendar';
// import TeamsCalendar from '../pages/TeamsCalendar';

import BusinessHours from '../pages/settings/BusinessHours';
import BusinessDetails from '../pages/settings/Details';
import BusinessLocation from '../pages/settings/Location';
import Booking from '../pages/settings/Booking';
import Services from '../pages/settings/services/Services';
import CreateService from '../pages/settings/services/CreateService';
import OnlineBooking from '../pages/settings/OnlineBooking';

import Customers from '../pages/Customers';
import CreateCustomer from '../pages/customer/CreateCustomer';
import EditCustomer from 'pages/customer/EditCustomer';

import ServiceProvider from '../pages/team/ServiceProvider';
import CreateServiceProvider from '../pages/team//create/CreateServiceProvider';
import Checkout from '../pages/sales/Checkout';
import Billing from 'pages/settings/Billing';

import CreateTeam from '../pages/team/create/CreateTeamMember';
import CreateOther from '../pages/team/create/CreateOther';
import Team from '../pages/team/Team';

import Teams from 'pages/Teams';
import Other from 'pages/team/Other';
import EditOthers from 'pages/team/edit/EditOthers';
import EditServiceProvider from 'pages/team/edit/EditServiceProvider';
import EditTeamMembers from 'pages/team/edit/EditTeamMembers';

import NotFound from 'pages/NotFound';
import ForgotPassword from 'pages/ForgotPassword/ForgotPassword';
import Admin from '../pages/Admin';
import CustomerLogin from 'pages/CustomerLogin';
import BusinessBookingPage from 'pages/book/BusinessBookingPage';
import ServiceProviderBookingPage from 'pages/book/ServiceProviderBookingPage';
import EditAppointmentDrawerForCustomer from '@components/appointments/EditAppointmentDrawer/EditAppointmentDrawerForCustomer';
import Notifications from 'pages/Notifications';

import SquareOAuthRedirectRouterPage from 'pages/SquareOAuthRedirectRouterPage';
import Profile from 'pages/Profile';

const AppRoutes = () => {
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    var _url = JSON.parse(localStorage.getItem('url') || '{}');
    return (
        <>
            <Routes>
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/:id/:id/appointment" element={<EditAppointmentDrawerForCustomer />} />
                <Route path="/book/:businessId" element={<BusinessBookingPage />} />
                <Route
                    path="/book/:businessId/:serviceProviderId"
                    element={<ServiceProviderBookingPage />}
                />
            </Routes>
            {_userData?.user == undefined ? (
                <Routes>
                    <Route
                        path="/square-oauth-redirect-router"
                        element={<PrivateRoute Component={SquareOAuthRedirectRouterPage} />}
                    />
                    <Route
                        path="/settings/online-booking"
                        element={<PrivateRoute Component={OnlineBooking} />}
                    />
                    <Route path="login" element={<Login />} />
                    <Route path="/:id/login" element={<CustomerLogin />} />
                    <Route path="register" element={<Register />} />

                    <Route path="/admin" element={<PrivateRoute Component={Admin} />} />

                    <Route path="/admin/login" element={<Login />} />
                    <Route path="/" element={<PrivateRoute Component={Dashboard} />} />
                    <Route path="/dashboard" element={<PrivateRoute Component={Dashboard} />} />

                    <Route path="/sales" element={<PrivateRoute Component={Checkout} />} />

                    <Route path="/customers/*" element={<PrivateRoute Component={Customers} />} />
                    <Route
                        path="/customers/:id/edit"
                        element={<PrivateRoute Component={EditCustomer} />}
                    />
                    <Route
                        path="/customers/create"
                        element={<PrivateRoute Component={CreateCustomer} />}
                    />

                    <Route path="/team" element={<PrivateRoute Component={Teams} />} />
                    <Route
                        path="/team/service-provider-list/*"
                        element={<PrivateRoute Component={ServiceProvider} />}
                    />
                    <Route
                        path="/team/service-provider/create"
                        element={<PrivateRoute Component={CreateServiceProvider} />}
                    />
                    <Route
                        path="/team/service-provider/:id/edit"
                        element={<PrivateRoute Component={EditServiceProvider} />}
                    />
                    <Route path="/team/team-list/*" element={<PrivateRoute Component={Team} />} />
                    <Route
                        path="/team/team-member/create"
                        element={<PrivateRoute Component={CreateTeam} />}
                    />
                    <Route
                        path="/team/team-member/:id/edit"
                        element={<PrivateRoute Component={EditTeamMembers} />}
                    />
                    <Route path="/team/other-list/*" element={<PrivateRoute Component={Other} />} />
                    <Route
                        path="/team/other/create"
                        element={<PrivateRoute Component={CreateOther} />}
                    />
                    <Route
                        path="/team/others/:id/edit"
                        element={<PrivateRoute Component={EditOthers} />}
                    />

                    <Route path="/settings" element={<PrivateRoute Component={Settings} />} />

                    <Route
                        path="/settings/business-hours"
                        element={<PrivateRoute Component={BusinessHours} />}
                    />
                    <Route
                        path="/settings/details"
                        element={<PrivateRoute Component={BusinessDetails} />}
                    />
                    <Route
                        path="/settings/location"
                        element={<PrivateRoute Component={BusinessLocation} />}
                    />
                    <Route
                        path="/settings/booking-settings"
                        element={<PrivateRoute Component={Booking} />}
                    />

                    <Route
                        path="/settings/services"
                        element={<PrivateRoute Component={Services} />}
                    />
                    <Route
                        path="/settings/services/create"
                        element={<PrivateRoute Component={CreateService} />}
                    />

                    <Route
                        path="/settings/billing"
                        element={<PrivateRoute Component={Billing} />}
                    />

                    <Route path="/calendar" element={<PrivateRoute Component={Calendar} />} />
                    <Route
                        path="/settings/notification"
                        element={<PrivateRoute Component={Notifications} />}
                    />
                </Routes>
            ) : (
                <Routes>
                    {_url === '/admin' && (
                        <Route path="/admin" element={<PrivateRoute Component={Admin} />} />
                    )}
                    {_userData?.user_login == 'customer' ? (
                        <>
                            <Route
                                path="/customers/*"
                                element={<PrivateRoute Component={Customers} />}
                            />
                            <Route
                                path="/customers/:id/edit"
                                element={<PrivateRoute Component={EditCustomer} />}
                            />
                        </>
                    ) : _userData.user?.permissions?.customers ? (
                        ''
                    ) : (
                        <Route path="/customers/*" element={<NotFound />} />
                    )}

                    <Route path="login" element={<Login />} />

                    <Route path="register" element={<Register />} />
                    {_userData?.user?.role == 'ADMIN' ? (
                        <>
                            <Route path="/admin" element={<PrivateRoute Component={Admin} />} />
                            <Route
                                path="/customers/*"
                                element={<PrivateRoute Component={Customers} />}
                            />
                            <Route
                                path="/customers/:id/edit"
                                element={<PrivateRoute Component={EditCustomer} />}
                            />
                            <Route
                                path="/customers/create"
                                element={<PrivateRoute Component={CreateCustomer} />}
                            />
                            <Route path="/" element={<PrivateRoute Component={Dashboard} />} />
                            <Route
                                path="/dashboard"
                                element={<PrivateRoute Component={Dashboard} />}
                            />
                            <Route path="/team" element={<PrivateRoute Component={Teams} />} />
                            <Route
                                path="/team/service-provider-list/*"
                                element={<PrivateRoute Component={ServiceProvider} />}
                            />
                            <Route
                                path="/team/service-provider/create"
                                element={<PrivateRoute Component={CreateServiceProvider} />}
                            />
                            <Route
                                path="/team/service-provider/:id/edit"
                                element={<PrivateRoute Component={EditServiceProvider} />}
                            />
                            <Route
                                path="/team/team-list/*"
                                element={<PrivateRoute Component={Team} />}
                            />
                            <Route
                                path="/team/team-member/create"
                                element={<PrivateRoute Component={CreateTeam} />}
                            />
                            <Route
                                path="/team/team-member/:id/edit"
                                element={<PrivateRoute Component={EditTeamMembers} />}
                            />
                            <Route
                                path="/team/other-list/*"
                                element={<PrivateRoute Component={Other} />}
                            />
                            <Route
                                path="/team/other/create"
                                element={<PrivateRoute Component={CreateOther} />}
                            />
                            <Route
                                path="/team/others/:id/edit"
                                element={<PrivateRoute Component={EditOthers} />}
                            />
                            <Route
                                path="/settings"
                                element={<PrivateRoute Component={Settings} />}
                            />

                            <Route
                                path="/settings/business-hours"
                                element={<PrivateRoute Component={BusinessHours} />}
                            />
                            <Route
                                path="/settings/details"
                                element={<PrivateRoute Component={BusinessDetails} />}
                            />
                            <Route
                                path="/settings/location"
                                element={<PrivateRoute Component={BusinessLocation} />}
                            />
                            <Route
                                path="/settings/booking-settings"
                                element={<PrivateRoute Component={Booking} />}
                            />

                            <Route
                                path="/settings/services"
                                element={<PrivateRoute Component={Services} />}
                            />
                            <Route
                                path="/settings/services/create"
                                element={<PrivateRoute Component={CreateService} />}
                            />

                            <Route
                                path="/settings/billing"
                                element={<PrivateRoute Component={Billing} />}
                            />
                            <Route
                                path="/settings/online-booking"
                                element={<PrivateRoute Component={OnlineBooking} />}
                            />
                            <Route
                                path="/calendar"
                                element={<PrivateRoute Component={Calendar} />}
                            />
                            <Route
                                path="/settings/notification"
                                element={<PrivateRoute Component={Notifications} />}
                            />
                        </>
                    ) : (
                        <Route path="/admin" element={<NotFound />} />
                    )}
                    <Route path="/:id/login" element={<CustomerLogin />} />
                    <Route path="/admin/login" element={<Login />} />
                    {_userData.user.permissions?.dashboard ? (
                        <>
                            <Route path="/" element={<PrivateRoute Component={Dashboard} />} />
                            <Route
                                path="/dashboard"
                                element={<PrivateRoute Component={Dashboard} />}
                            />
                        </>
                    ) : (
                        <Route path="/" element={<NotFound />} />
                    )}
                    <Route path="/activity" element={<PrivateRoute Component={ActivityFeed} />} />
                    <Route path="/profile" element={<PrivateRoute Component={Profile} />} />
                    {_userData?.user?.permissions?.sales ? (
                        <>
                            <Route path="/sales" element={<PrivateRoute Component={Checkout} />} />
                        </>
                    ) : (
                        <Route path="/sales" element={<NotFound />} />
                    )}

                    {_userData.user?.permissions?.customers ? (
                        <>
                            <Route
                                path="/customers/*"
                                element={<PrivateRoute Component={Customers} />}
                            />
                            <Route
                                path="/customers/:id/edit"
                                element={<PrivateRoute Component={EditCustomer} />}
                            />
                            <Route
                                path="/customers/create"
                                element={<PrivateRoute Component={CreateCustomer} />}
                            />
                        </>
                    ) : (
                        <>
                            <Route path="/customers/*" element={<NotFound />} />
                            <Route path="/customers/:id/edit" element={<NotFound />} />
                            <Route path="/customers/create" element={<NotFound />} />
                        </>
                    )}
                    {_userData?.user?.permissions?.team ? (
                        <>
                            <Route
                                path="/square-oauth-redirect-router"
                                element={<PrivateRoute Component={SquareOAuthRedirectRouterPage} />}
                            />
                            <Route path="/team" element={<PrivateRoute Component={Teams} />} />
                            <Route
                                path="/team/service-provider-list/*"
                                element={<PrivateRoute Component={ServiceProvider} />}
                            />
                            <Route
                                path="/team/service-provider/create"
                                element={<PrivateRoute Component={CreateServiceProvider} />}
                            />
                            <Route
                                path="/team/service-provider/:id/edit"
                                element={<PrivateRoute Component={EditServiceProvider} />}
                            />
                            <Route
                                path="/team/team-list/*"
                                element={<PrivateRoute Component={Team} />}
                            />
                            <Route
                                path="/team/team-member/create"
                                element={<PrivateRoute Component={CreateTeam} />}
                            />
                            <Route
                                path="/team/team-member/:id/edit"
                                element={<PrivateRoute Component={EditTeamMembers} />}
                            />
                            <Route
                                path="/team/other-list/*"
                                element={<PrivateRoute Component={Other} />}
                            />
                            <Route
                                path="/team/other/create"
                                element={<PrivateRoute Component={CreateOther} />}
                            />
                            <Route
                                path="/team/others/:id/edit"
                                element={<PrivateRoute Component={EditOthers} />}
                            />
                        </>
                    ) : (
                        <>
                            <Route path="/team" element={<NotFound />} />
                            <Route path="/team/service-provider-list/*" element={<NotFound />} />
                            <Route path="/team/service-provider/create" element={<NotFound />} />
                            <Route path="/team/service-provider/:id/edit" element={<NotFound />} />
                            <Route path="/team/team-list/*" element={<NotFound />} />
                            <Route path="/team/team-member/create" element={<NotFound />} />
                            <Route path="/team/team-member/:id/edit" element={<NotFound />} />
                            <Route path="/team/other-list/*" element={<NotFound />} />
                            <Route path="/team/other/create" element={<NotFound />} />
                            <Route path="/team/others/:id/edit" element={<NotFound />} />
                        </>
                    )}

                    {_userData?.user?.permissions?.business ? (
                        <>
                            <Route
                                path="/square-oauth-redirect-router"
                                element={<PrivateRoute Component={SquareOAuthRedirectRouterPage} />}
                            />
                            <Route
                                path="/settings"
                                element={<PrivateRoute Component={Settings} />}
                            />

                            <Route
                                path="/settings/business-hours"
                                element={<PrivateRoute Component={BusinessHours} />}
                            />
                            <Route
                                path="/settings/details"
                                element={<PrivateRoute Component={BusinessDetails} />}
                            />
                            <Route
                                path="/settings/location"
                                element={<PrivateRoute Component={BusinessLocation} />}
                            />
                            <Route
                                path="/settings/booking-settings"
                                element={<PrivateRoute Component={Booking} />}
                            />

                            <Route
                                path="/settings/services"
                                element={<PrivateRoute Component={Services} />}
                            />
                            <Route
                                path="/settings/services/create"
                                element={<PrivateRoute Component={CreateService} />}
                            />

                            <Route
                                path="/settings/billing"
                                element={<PrivateRoute Component={Billing} />}
                            />
                            <Route
                                path="/settings/online-booking"
                                element={<PrivateRoute Component={OnlineBooking} />}
                            />
                            <Route
                                path="/settings/notification"
                                element={<PrivateRoute Component={Notifications} />}
                            />
                        </>
                    ) : (
                        <>
                            <Route path="/settings" element={<NotFound />} />
                            <Route path="/settings/business-hours" element={<NotFound />} />
                            <Route path="/settings/details" element={<NotFound />} />
                            <Route path="/settings/location" element={<NotFound />} />
                            <Route path="/settings/booking-settings" element={<NotFound />} />
                            <Route path="/settings/services" element={<NotFound />} />
                            <Route path="/settings/services/create" element={<NotFound />} />
                            <Route path="/settings/billing" element={<NotFound />} />
                            <Route path="/settings/online-booking" element={<NotFound />} />
                            <Route path="/settings/notification" element={<NotFound />} />
                        </>
                    )}

                    {_userData?.user?.permissions?.calendar ? (
                        <>
                            <Route
                                path="/calendar"
                                element={<PrivateRoute Component={Calendar} />}
                            />
                        </>
                    ) : (
                        <>
                            <Route path="/calendar" element={<NotFound />} />
                        </>
                    )}
                </Routes>
            )}
        </>
    );
};

export default AppRoutes;
