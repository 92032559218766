import { createStore, applyMiddleware, Action } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware, { ThunkDispatch } from 'redux-thunk';
import rootReducer from '../reducers';

export const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunkMiddleware))
);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type TAppState = ReturnType<typeof rootReducer>;
export type ReduxDispatch = ThunkDispatch<TAppState, any, Action>;
