import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ICustomer, IGetCustomersResponse } from 'interfaces/customer.interface';
import useRouter from 'hooks/router';
import _ from 'lodash';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import SearchField from '@components/common/Search/SearchField';
import ContentList from '@components/common/ContentList/ContentList';
import { api } from 'helpers/auth-axios';
import { LoaderOverlay } from '@components/common';
import Button from '@components/Button';
interface IProps {
    hasId: any;
}
const CustomersList = forwardRef(({}, ref) => {
    useImperativeHandle(ref, () => ({
        getCustomers() {
            getAllCustomers();
        },
    }));
    const navigate = useNavigate();
    const router = useRouter();

    const [pageNumber, setPageNumber] = useState(0);
    const [customersData, setCustomersData] = useState<IGetCustomersResponse>();
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [query, setQuery] = useState<any>(null);
    const [value, setValue] = useState<any>('');
    const [initial, setInitial] = useState(false); // used to check whether data is initially on store
    const [customer, setCustomer] = useState<any[]>([]);
    const observer = useRef<IntersectionObserver>();
    const pageSize = 20;
    const [total, setTotal] = useState(0);

    const loaderRef = useCallback(
        (node: any) => {
            if (loading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(entries => {
                // if (entries[0].isIntersecting && hasMore) {
                //     setPageNumber(prevPageNumber => prevPageNumber + 1);
                // }
            });
            if (node) observer.current.observe(node);
        },
        [loading, hasMore]
    );

    const getAllCustomers = () => {
        setLoading(true);
        api.get(`/customers?page=${0}&pageSize=${pageSize}`).then((customers: any) => {
            setCustomer(customers.data.customers);
            setCustomersData(customers.data);
            setTotal(customers.data.total);
            if (
                customers.data.customers.length &&
                router.query[Object.keys(router.query)[0]] === '' &&
                window.innerWidth > 767
            ) {
                navigate(`/customers/${customers.data.customers[0]._id}`);
            }

            setHasMore(customers.data.customers.length > 0);
            setLoading(false);
        });
    };
    const loadCustomers = () => {
        setLoading(true);
        api.get(`/customers?page=${pageNumber}&pageSize=${pageSize}`).then((customers: any) => {
            setCustomer((prevCustomers: any) => [...prevCustomers, ...customers.data.customers]);
            setCustomersData(customers.data);
            setTotal(customers.data.total);
            setHasMore(customers.data.customers.length > 0);
            setLoading(false);
        });
    };
    useEffect(() => {
        if (pageNumber > 0) {
            loadCustomers();
        }
    }, [pageNumber]);
    const searchCustomers = (query: string) => {
        let value: any = query;
        if (Number.isInteger(parseInt(value)) === true) {
            if (value.length <= 3) {
                value = value.replace(/[- .]/g, '');
                // value = value + '-';
            } else if (value.length <= 7) {
                value = value.replace(/[- .]/g, '');
                value = value.slice(0, 3) + '-' + value.slice(3, 6);
            } else if (value.length >= 7) {
                value = value.replace(/[- .]/g, '');
                value = value.slice(0, 3) + '-' + value.slice(3, 6) + '-' + value.slice(6);
            }
            setLoading(true);
            api.get(`/customers?page=${pageNumber}&pageSize=${pageSize}&query=${value}`).then(
                (customers: any) => {
                    setCustomer(customers.data.customers);
                    setLoading(false);
                    setHasMore(customers.data.customers.length > 0);
                    setCustomersData(customers.data);
                }
            );
        } else {
            setLoading(true);
            api.get(`/customers?page=${pageNumber}&pageSize=${pageSize}&query=${value}`).then(
                (customers: any) => {
                    setCustomer(customers.data.customers);
                    setCustomersData(customers.data);
                    setLoading(false);
                    setHasMore(customers.data.customers.length > 0);
                }
            );
        }
    };
    const loadMore = () => {
        setPageNumber((prevPage: any) => prevPage + 1);
    };
    useEffect(() => {
        if (customersData !== undefined && customersData.total !== 0) {
            setPageNumber(customersData && customersData.page);
            setValue((customersData !== undefined && customersData.query) ?? null);
            setQuery((customersData !== undefined && customersData.query) ?? null);
            setHasMore(true);
            setInitial(true);
            return;
        }

        getAllCustomers();
    }, []);

    const searchCustomerDebounce = useMemo(() => {
        return _.debounce((searchVal: string) => {
            setHasMore(true);
            setQuery(searchVal);
            setPageNumber(0);

            searchCustomers(searchVal);
        }, 300);
    }, []);
    // const phone = data.phone.replace(/\D[^\.]/g, '');
    // data.phone = phone.slice(0, 3) + '-' + phone.slice(3, 6) + '-' + phone.slice(6);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        searchCustomerDebounce(event.target.value);
    };

    return (
        <>
            <Wrapper>
                {loading && <LoaderOverlay />}
                <CustomRow>
                    <CustomCol lg={12}>
                        <HeadingWrapper>
                            <Header>Customers</Header>
                        </HeadingWrapper>
                        <OnSamllViewPort hasId={router.query['*']}>
                            <SearchField
                                value={value}
                                handleChange={handleChange}
                                name={`Customer`}
                            />
                            <CustomerList>
                                {customer.length ? (
                                    customer.map((customer: ICustomer, i: any) => {
                                        const queryId = router.query[Object.keys(router.query)[0]];
                                        return (
                                            <ContentList
                                                key={i}
                                                queryId={`${
                                                    (queryId as string) === customer._id
                                                        ? 'isActivelist'
                                                        : ''
                                                }`}
                                                link={`/customers/${customer._id}`}
                                                icon={customer?.photo}
                                                name={customer.firstname + ' ' + customer.lastname}
                                                detailText={customer.phone}
                                            />
                                        );
                                    })
                                ) : (
                                    <NotFound>No customer found</NotFound>
                                )}
                                <div ref={loaderRef}></div>
                                {query
                                    ? ''
                                    : (total > (pageNumber + 1) * pageSize || loading) && (
                                          <LoadMore>
                                              <Button
                                                  bgtype={'secondary'}
                                                  ifClicked={loadMore}
                                                  disabled={loading}
                                                  //   width="12rem !important"
                                                  label={`Load more customers (${
                                                      total - (pageNumber + 1) * pageSize
                                                  })`}></Button>
                                          </LoadMore>
                                      )}
                            </CustomerList>
                            <Col>
                                <AddIcon to="/customers/create">
                                    <i className="fal fa-plus"></i>
                                </AddIcon>
                            </Col>
                        </OnSamllViewPort>
                    </CustomCol>
                </CustomRow>
            </Wrapper>
        </>
    );
});
const Wrapper = styled(Container)`
    flex: 0 0 25rem;
    margin-left: 0rem;

    ${media.xs`
    padding-top: 1rem;
    padding: auto;
    flex: 0 0 25rem;
    `}
    ${media.sm`
    margin-top: 1rem;
    flex: 0 0 25rem;
     background-color: white;
    `}
	${media.md`
    margin-top: 0rem;
    padding: 0;
    background-color: white;
     flex: 0 0 20rem;
    `}
	@media screen and (min-width: 1076px) and (max-width: 1140px) {
        flex: 0 0 20rem;
    }
    @media screen and (min-width: 1032px) and (max-width: 1075px) {
        flex: 0 0 18rem;
    }
    @media screen and (min-width: 992px) and (max-width: 1031px) {
        flex: 0 0 16rem;
    }

    ${media.lg`
    margin-top: 0rem;
    padding: 0;
    background-color: white;
    `}
    ${media.xl`
    margin-top: 0rem;
    padding: 0;
    background-color: white;
`}
`;
const CustomRow = styled(Row)`
    position: relative;
    margin: 0;
    ${media.xs`
    background-color: white;
`}
`;
const CustomCol = styled(Col)`
    padding: 0;
`;
const Header = styled.h1`
    font-size: 1.78rem;
    font-weight: 600;
`;
const HeadingWrapper = styled.div`
    padding: 1.42rem 0rem 0.85rem 1.71rem;
    display: flex;
    align-items: center;
    justify-content: start;
`;

const AddIcon = styled(Link)`
    position: fixed;
    bottom: 1.71rem;
    //right: 2.14rem;
    width: 3.42rem;
    height: 3.42rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1rem 1.71rem 0 rgb(0 0 0 / 28%);
    background: #0154ff;
    border-radius: 100%;
    color: #fff;
    font-size: 1.5rem;
`;

const CustomerList = styled.div`
    margin-top: 1rem;
    height: 40rem;
    overflow: auto;
`;
const OnSamllViewPort = styled.span<IProps>`
    display: block;
    ${media.xs`
	display: ${({ hasId }: any) => (hasId ? 'none' : 'block')};
	`}
    ${media.sm`
	display: ${({ hasId }: any) => (hasId ? 'none' : 'block')};
	`}
	${media.md`
    display: block;
	`}
	${media.lg`
    display: block;
	`}
`;
const LoadMore = styled.div`
    margin-top: 0.5rem;
    display: flex;
    justify-content: center;
    ${media.xs`
	margin-bottom: 0.5rem;
	`}
`;

const NotFound = styled.div`
    display: flex;
    justify-content: center;
`;
export default CustomersList;
