import useRouter from 'hooks/router';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { IServiceProvider } from 'interfaces/team/serviceProvider.interface';
import { serviceActions } from 'actions/service';
import TabContext from '@components/common/TabContext';
import { LoaderOverlay, Tab, TabPanel, Tabs, useSnackbar } from '@components/common';
import ServiceProviderServices from './ServiceProviderServices';
import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import Card from '@components/common/Cards/Card';
import { palette } from 'styled/common';
import TimeOff from '@components/common/TimeOff/TimeOff';
import Permissions from '@components/common/Permissions/Permissions';
import WorkingHours from '@components/common/WorkingHours/WorkingHours';
import Breaks from '@components/common/Breaks/Breaks';
import DeleteModel from '@components/common/DeleteModel/DeleteModel';
import Accordion from '@components/Accordion';
import { api } from 'helpers/auth-axios';
import CalendarBlock from '@components/common/CalendarBlock/CalendarBlock';
import VipWorkingHours from '@components/common/WorkingHours/VipWorkingHours';
import BookingWindow from '@components/common/BookingWindow/BookingWindow';
import ClientPaymentSettings from './ClientPaymentSettings';
import PaymentProcessorSettings from './PaymentProcessorSetup';
import { RoleEnum } from 'constants/enums';

const ServiceProviderCard = ({ callServiceProvider }: any) => {
    const [serviceProvider, setServiceProvider] = useState<IServiceProvider>();
    const [tabValue, setTabValue] = useState(0);
    const [openDeleteModel, setOpenDeleteModel] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleteId, setDeleteId] = useState<any>();
    const [businessId, setBusinessId] = useState<any>();
    const [revenue, setRevenue] = useState<any>();
    const [totalAppointments, setTotalAppointments] = useState<any>();
    const [tip, setTip] = useState<any>();

    const [paymentProcessorSettingsLoading, setPaymentProcessorSettingsLoading] =
        useState<boolean>(false);

    const router = useRouter();
    const dispatch = useAppDispatch();
    const [openSnackbar] = useSnackbar();
    const { services } = useAppSelector(state => state.service);
    const onChange = (day: any) => {};

    const _userData = JSON.parse(localStorage.getItem('user') || '{}');

    const serviceProviderId: string = router.query?.id ?? _userData.user._id;

    const data = () => {
        console.log('loading service provider stuff');
        api.get(`/serviceProvider/${serviceProviderId}`).then((res: any) => {
            if (res.data !== 0) {
                setServiceProvider(res.data);
                setBusinessId(res.data.business_id);
            }
        });
    };

    useEffect(() => {
        data();
    }, [dispatch, serviceProviderId]);

    useEffect(() => {
        if (services.length) return;
        dispatch(serviceActions.getServices());
    }, [dispatch, services.length]);

    const deleteModel = (id: any) => {
        setOpenDeleteModel(true);
        setDeleteId(id);
    };

    const deleteServiceProvider = (id: any) => {
        setLoading(true);
        // appointments/service_provider/621f6542c6b7b696c7955492
        api.delete(`/appointments/service_provider/${id}`)
            .then((res: any) => {
                if (res) {
                    router.navigate('/team/service-provider-list');
                    openSnackbar('Service Provider deleted successfully!');
                    setOpenDeleteModel(false);
                    callServiceProvider();
                } else {
                    openSnackbar('Failed to delete Service Provider!');
                    setOpenDeleteModel(false);
                }
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
        setLoading(false);
        setOpenDeleteModel(false);
    };

    const getAppointments = () => {
        setLoading(true);
        api.get(`/appointments/sevice_Provider/${serviceProviderId}`)
            .then((res: any) => {
                if (res.data) {
                    setLoading(false);
                    setTotalAppointments(res.data.appointments);
                    setRevenue(res.data.totalRevenue);
                    setTip(res.data.totalTip);
                }
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };
    useEffect(() => {
        getAppointments();
    }, [router.query]);

    // Is set to True if current user is the service provider being viewed
    const viewingOwnProfile =
        _userData.user_login !== RoleEnum.USER && serviceProvider?._id === _userData.user._id;

    const bookingSettingsTabNamesList = [
        {
            name: 'Booking Window',
            content: (
                <BookingSettingsSubsectionWrapper>
                    <BookingWindow
                        serviceProvider={serviceProvider}
                        reloadServiceProviderData={data}
                        readOnly={viewingOwnProfile && !_userData.user.permissions.booking_settings}
                    />
                </BookingSettingsSubsectionWrapper>
            ),
        },
        {
            name: 'Payment Processor Setup',
            content: (
                <BookingSettingsSubsectionWrapper>
                    {serviceProvider && (
                        <PaymentProcessorSettings
                            serviceProvider={serviceProvider}
                            reloadServiceProviderData={data}
                            setPaymentProcessorLoading={setPaymentProcessorSettingsLoading}
                            readOnly={
                                viewingOwnProfile && !_userData.user.permissions.payment_settings
                            }
                        />
                    )}
                </BookingSettingsSubsectionWrapper>
            ),
        },
        {
            name: 'Client Payment Settings',
            content: (
                <BookingSettingsSubsectionWrapper>
                    {serviceProvider?.stripe_account_verified ||
                    serviceProvider?.square_account_verified ? (
                        <ClientPaymentSettings
                            serviceProvider={serviceProvider}
                            reloadServiceProviderData={data}
                            readOnly={
                                viewingOwnProfile && !_userData.user.permissions.payment_settings
                            }
                        />
                    ) : (
                        <h3 style={{ opacity: 0.75 }}>No payment integration currently set up.</h3>
                    )}
                </BookingSettingsSubsectionWrapper>
            ),
        },
    ];

    const tabsNameList = [
        {
            name: 'Profile',
            content: (
                <Card
                    link={`/team/service-provider/${serviceProviderId}/edit`}
                    model={() => deleteModel(serviceProviderId)}
                    nameData={serviceProvider?.name}
                    name={serviceProvider?.name}
                    phone={serviceProvider?.phone}
                    email={serviceProvider?.email}
                    serviceProviderBookingLink={`${window.location.origin}/book/${businessId?._id}/${serviceProviderId}`}
                    button={true}
                    appointment={true}
                    revenue={true}
                    profileImage={serviceProvider?.photo}
                    totalRevenue={revenue}
                    totalAppointments={totalAppointments}
                    totalTip={tip}
                    checkStaff={true}
                    tip={true}
                    submit={serviceProvider}
                    stripe_account_verified={serviceProvider?.stripe_account_verified}
                    stripeId={serviceProvider?.stripe_account_id}
                    square_account_verified={serviceProvider?.square_account_verified}
                    showRevokeSquareTokenButton={!!serviceProvider?.square_access_token}
                    accountObject={serviceProvider}
                    reloadAccountObject={() => data()}
                    accountRole={RoleEnum.SERVICEPROVIDER}
                />
            ),
        },
        {
            name: 'Services',
            content: (
                <ServiceProviderServices
                    serviceProviderId={serviceProviderId}
                    readOnly={viewingOwnProfile && !_userData.user.permissions.services}
                />
            ),
        },
        {
            name: 'Hours',
            content: (
                <WorkingHours
                    id={serviceProviderId}
                    active={true}
                    onChange={onChange}
                    business_id={businessId}
                    readOnly={viewingOwnProfile && !_userData.user.permissions.hours}
                />
            ),
        },
        {
            name: 'VIP',
            content: (
                <VipWorkingHours
                    serviceProvider={serviceProvider}
                    reloadServiceProviderData={data}
                    readOnly={viewingOwnProfile && !_userData.user.permissions.vip}
                />
            ),
        },
        {
            name: 'Breaks',
            content: (
                <Breaks
                    id={serviceProviderId}
                    day={{ day: 1, from: '10:00', to: '19:00' }}
                    onChang={onChange}
                    readOnly={viewingOwnProfile && !_userData.user.permissions.breaks}
                />
            ),
        },
        {
            name: 'Time Off',
            content: (
                <TimeOff
                    id={serviceProviderId}
                    readOnly={viewingOwnProfile && !_userData.user.permissions.time_off}
                />
            ),
        },
        {
            name: 'Permissions',
            content: (
                <Permissions
                    id={serviceProviderId}
                    businessId={businessId}
                    userRole={RoleEnum.SERVICEPROVIDER}
                />
            ),
        },
        {
            name: 'Block',
            content: <CalendarBlock serviceProviderId={serviceProviderId} />,
        },
        {
            name: 'Booking Settings',
            content: <Accordion tabsNameList={bookingSettingsTabNamesList} />,
        },
    ];
    return (
        <>
            {(loading || paymentProcessorSettingsLoading) && <LoaderOverlay />}
            <MainContainer>
                <Row>
                    {serviceProvider && (
                        <>
                            <WebView lg={12}>{tabsNameList[0].content}</WebView>
                            <WebView lg={12}>
                                <TabsCard>
                                    <TabContext value={tabValue}>
                                        <Tabs
                                            onChange={(event, newValue) => {
                                                setTabValue(newValue);
                                            }}>
                                            <Tab label={tabsNameList[1].name} />
                                            <Tab label={tabsNameList[2].name} />
                                            <Tab label={tabsNameList[3].name} />
                                            <Tab label={tabsNameList[4].name} />
                                            <Tab label={tabsNameList[5].name} />
                                            <Tab label={tabsNameList[6].name} />
                                            <Tab label={tabsNameList[8].name} />
                                        </Tabs>
                                        <TabPanel value={0} preload={true}>
                                            <TabContent>{tabsNameList[1].content}</TabContent>
                                        </TabPanel>
                                        <TabPanel value={1}>
                                            <TabContent>{tabsNameList[2].content}</TabContent>
                                        </TabPanel>
                                        <TabPanel value={2} preload={true}>
                                            <TabContent>{tabsNameList[3].content}</TabContent>
                                        </TabPanel>
                                        <TabPanel value={3} preload={true}>
                                            <TabContent>{tabsNameList[4].content}</TabContent>
                                        </TabPanel>
                                        <TabPanel value={4} preload={true}>
                                            <TabContent>{tabsNameList[5].content}</TabContent>
                                        </TabPanel>
                                        <TabPanel value={5} preload={true}>
                                            <TabContent>{tabsNameList[6].content}</TabContent>
                                        </TabPanel>
                                        <TabPanel value={6} preload={true}>
                                            <TabContent>{tabsNameList[8].content}</TabContent>
                                        </TabPanel>
                                    </TabContext>
                                </TabsCard>
                            </WebView>
                            <MobileView lg={12}>
                                <Accordion tabsNameList={tabsNameList} />
                            </MobileView>

                            {openDeleteModel && serviceProvider && (
                                <DeleteModel
                                    id={serviceProvider._id}
                                    onDelete={() => {
                                        setOpenDeleteModel(false);
                                    }}
                                    onClose={() => {
                                        setOpenDeleteModel(false);
                                    }}
                                    name="Service Provider"
                                    content=" If you delete this service provider you will lose all information
                                    connected to their profile including past services, woking hours, breake
                                    time, off time and permissions. This action cannot be undone."
                                    loading={loading}
                                    onCLick={() => deleteServiceProvider(deleteId)}
                                />
                            )}
                        </>
                    )}
                </Row>
            </MainContainer>
        </>
    );
};

const MainContainer = styled(Container)`
    height: 100vh;
    overflow-y: scroll;
    padding: 1.71rem;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    ${media.xs`
	padding: 1.1rem;
    overflow-x: hidden;
    overflow-y: auto;
	`}
    ${media.sm`
    padding: 0rem;
    padding-top: 1rem;
    overflow-x: hidden;
    overflow-y: auto;
	`}
	${media.md`
	  padding: 1.71rem;
	`}
	${media.lg`
	  padding: 1.71rem;
	`}
`;

const WebView = styled(Col)`
    display: block;
    @media screen and (min-width: 1px) and (max-width: 900px) {
        display: none;
    }
`;
const MobileView = styled(Col)`
    display: none;
    @media screen and (min-width: 1px) and (max-width: 900px) {
        display: block;
    }
`;
const TabsCard = styled.div`
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: ${palette.light};
    background-clip: border-box;
    border-radius: 0.42rem;
    box-shadow: 0rem 0rem 2.2rem 0rem ${palette.grey.shadow};
    border: 0;
`;

const BookingSettingsSubsectionWrapper = styled.div`
    padding: 1rem 1.3rem;
`;

const TabContent = styled.div`
    padding: 1.3rem;
    flex: 1;
`;
export default ServiceProviderCard;
