import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Col, Row, media } from 'styled-bootstrap-grid';
import { Input, Select, useSnackbar } from '@components/common';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import useRouter from 'hooks/router';
import SideCover from '@components/common/SideCover/SideCover';
import { api } from 'helpers/auth-axios';

type FormValues = {
    email: string;
};

const ForgotPassword = () => {
    useEffect(() => {
        localStorage.removeItem('user');
    }, []);

    const router = useRouter();
    const [openSnackbar] = useSnackbar();
    const [roleList, setRole] = useState([
        { value: 'user', label: 'Business Owner' },
        { value: 'serviceProvider', label: 'Service Provider' },
        { value: 'others', label: 'Others' },
        { value: 'teamMember', label: 'Team Member' },
    ]);
    const [selectedRole, setSeletedRole] = useState('user');
    const validationSchema = Yup.object().shape({
        email: Yup.string().required('This field is required'),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, setValue, formState, trigger } = useForm<FormValues>(formOptions);
    const { errors } = formState;

    const onSubmit = handleSubmit(({ email }: { email: string }) => {
        api.post('/auth/forgot_password', { email: email, role: selectedRole })
            .then((res: any) => {
                openSnackbar('Email sent to your email address Successfully');
                router.navigate('/login');
            })
            .catch((e: any) => {
                if (e.response) {
                    openSnackbar('Incorrect email');
                }
            });
    });

    async function onChange({ name, value }: { name: string; value: string }) {
        setValue(name as never, value as never);
        await trigger(name as never);
    }

    return (
        <>
            <Wrapper>
                <Row>
                    <SideCover />
                    <Col lg={5} style={{ padding: '0' }}>
                        <CardRight>
                            <Inner>
                                <Heading>Reset Password</Heading>
                                <SignInText>
                                    Already have password?
                                    <Link to="/login">
                                        <SignUpText className="text-primary-light">
                                            Log In
                                        </SignUpText>
                                    </Link>
                                </SignInText>
                            </Inner>
                            <form onSubmit={onSubmit}>
                                <InputGroup>
                                    <Input
                                        label="E-mail Address"
                                        name="email"
                                        type="email"
                                        onChange={onChange}
                                        error={errors.email as any}
                                    />
                                </InputGroup>
                                <InputGroup>
                                    <Select
                                        name="selectedRole"
                                        disabled={true}
                                        value={
                                            selectedRole == 'user'
                                                ? 'Business Owner'
                                                : selectedRole == 'serviceProvider'
                                                ? 'Service Provider'
                                                : selectedRole == 'others'
                                                ? 'Others'
                                                : selectedRole == 'teamMember'
                                                ? 'Team Member'
                                                : 'Please select your role'
                                        }
                                        options={roleList}
                                        onChange={e => {
                                            setSeletedRole(e);
                                        }}
                                    />
                                </InputGroup>

                                <Button type="submit">Reset</Button>
                            </form>
                            <div>
                                <AgreeText>
                                    By clicking the Reset button you therefore a email send to your
                                    email address with your password and you can use this password
                                    for sign in
                                    {/* <Highlight>Privacy Policy</Highlight> and to the{' '}
                                    <Highlight>Terms & Conditions</Highlight> */}
                                </AgreeText>
                            </div>
                        </CardRight>
                    </Col>
                </Row>
            </Wrapper>
        </>
    );
};
const Wrapper = styled(Container)`
    paddind: 0;
    min-width: 100%;
    background-color: #fff;
`;
const CardLeft = styled.div`
    background-color: #4871fe;
    padding-top: 3.5rem !important;
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
`;
const CardRight = styled.div`
    padding-left: 4.75rem !important;
    padding-right: 4.75rem !important;
    background-color: #fff;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
const Inner = styled.div`
    margin-bottom: 3rem;
`;
const Heading = styled.h3`
    font-size: 2.6rem;
    font-weight: 600 !important;
    color: #000;
    ${media.xs`
    margin-top:0;
	`}
    ${media.sm`
    margin-top:0;
	`}
	${media.md`
    margin-top:0;
	`}
`;
const SignInText = styled.p`
    display: flex;
    color: #000 !important;
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
`;
const SignUpText = styled.p`
    color: #0154ff !important;
    font-weight: 800;
    padding-left: 0.5rem;
    margin: 0;
`;
const InputGroup = styled.div`
    margin-bottom: 1.71rem;
`;
const Button = styled.button`
    color: rgba(255, 255, 255) !important;
    text-transform: capitalize !important;
    font-weight: 800 !important;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
    background-color: #0154ff !important;
    border-radius: 0.5rem !important;
    width: 100% !important;
    display: inline-block !important;
    margin-top: 1rem !important;
    border: 0;
    cursor: pointer;
    font-size: 1.25rem;
`;
const Span = styled.span``;
const Divider = styled.div`
    border: 0.01rem solid #f3f4f6;
    width: 100%;
    height: 0.1rem;
    position: relative;
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
    & ${Span} {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        background-color: #fff;
        width: 7.14rem;
        font-weight: 500;
        text-align: center;
        color: #c0c5cd;
    }
`;
const SocialButton = styled.a`
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow,
        transform, filter, backdrop-filter, -webkit-backdrop-filter !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
    color: rgba(125, 128, 131) !important;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    background-color: rgba(244, 245, 246) !important;
    border-radius: 0.5rem !important;
    justify-content: center !important;
    width: 100% !important;
    display: flex !important;
    margin-bottom: 1rem !important;
    cursor: pointer;
`;
const Icon = styled.img`
    height: 1.42rem;
    margin-right: 0.75rem !important;
`;

const UserLink = styled.div`
    margin-top: 0.5rem;
    display: flex;
    justify-content: end;
    color: rgba(12, 83, 252);
    cursor: pointer;
`;
const CustomDiv = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 0rem 0rem;
    color: rgba(12, 83, 252);
    cursor: pointer;
`;
const AgreeText = styled.div`
    font-size: 0.85rem;
    max-width: 340px;
    margin-right: auto;
    margin-left: auto;
    color: rgba(157, 160, 164) !important;
    font-weight: 100 !important;
    text-align: center !important;
    margin-top: 1rem !important;
`;
const Highlight = styled.span`
    color: rgba(125, 128, 131) !important;
    font-weight: 500 !important;
`;
export default ForgotPassword;
