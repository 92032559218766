import { userConstants } from 'actions/user';

const user = JSON.parse(localStorage.getItem('user')!);
const initialState = user ? { loggedIn: true, user } : { loggingIn: false };
export function auth(state = initialState, action: any) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                loggingIn: true,
                user: action.user,
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                user: action.user,
            };
        case userConstants.LOGIN_FAILURE:
            return {};
        case userConstants.LOGOUT:
            return {};

        case userConstants.SAVE_USER:
            return {
                ...state,
                user: action.value,
            };
        default:
            return state;
    }
}
