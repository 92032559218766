import React, { useEffect, useState } from 'react';
import { Layout } from '@components/common';
import styled from 'styled-components';
import { Col, Container, media, Row } from 'styled-bootstrap-grid';
import moment from 'moment';
import { api } from 'helpers/auth-axios';
import { LoaderOverlay, useSnackbar } from '@components/common';
import { useNavigate, useLocation } from 'react-router-dom';
const Admin = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [openSnackbar] = useSnackbar();
    const [bussines, setBusniess] = useState<any>([]);
    const [active, setActive] = useState<any>();
    const navigate = useNavigate();
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const getUsers = () => {
        setLoading(true);
        api.get('/users/all')
            .then(res => {
                setLoading(false);
                setBusniess(res.data);
            })
            .catch(err => {
                if (err?.response) {
                    setLoading(false);
                    openSnackbar(err?.response?.data?.message);
                }
            });
    };
    const location = useLocation();
    const path = location.pathname;
    const ViewBusiness = (email: any) => {
        const data = {
            email: email,
            role: 'user',
            business: true,
        };
        _userData.user.role == 'ADMIN' &&
            api.post('auth/login', data).then((res: any) => {
                if (res.data) {
                    localStorage.removeItem('user');
                    localStorage.setItem('user', JSON.stringify(res.data));
                    localStorage.setItem('url', JSON.stringify(path));
                    navigate('/');
                }
            });
    };
    useEffect(() => {
        getUsers();
    }, []);

    const BlockUser = (id?: any, active?: any) => {
        api.put(`businesses/${id}`, { active: active })
            .then((res: any) => {
                getUsers();
                if (res.data.active == true) {
                    openSnackbar('User UnBlocked successfully!');
                } else {
                    openSnackbar('User Blocked successfully!');
                }
            })
            .catch((e: any) => {
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };
    return (
        <Wrapper id="container">
            {loading && <LoaderOverlay />}
            <Content>
                <AppointmentsDiv>
                    <Head>
                        <Heading>Business Name</Heading>
                        <Heading>Owner Full Name</Heading>
                        <Heading>Owner Email</Heading>
                        <Heading>Status</Heading>
                        <Heading>View Business</Heading>
                        <Heading>Action</Heading>
                    </Head>
                    <>
                        {bussines?.length >= 0 ? (
                            bussines.map((user: any, index: any) => {
                                return user.role === 'ADMIN' ? (
                                    ''
                                ) : (
                                    <Body key={index}>
                                        <List>{user?.business_id?.name}</List>
                                        <List>{user?.name}</List>
                                        <List>{user?.email}</List>
                                        <List>
                                            {user?.business_id?.active === true
                                                ? 'Active'
                                                : 'Blocked'}
                                        </List>
                                        <UnBlock onClick={() => ViewBusiness(user?.email)}>
                                            View
                                        </UnBlock>
                                        {/* <List
                                        onClick={() => ViewBusiness(user?.email)}
                                        style={{ cursor: 'pointer' }}>
                                        Go To Business
                                    </List> */}
                                        <List>
                                            {user?.business_id?.active == false ? (
                                                <UnBlock
                                                    onClick={() =>
                                                        BlockUser(user?.business_id?._id, true)
                                                    }>
                                                    Unblock
                                                </UnBlock>
                                            ) : (
                                                <Block
                                                    onClick={() =>
                                                        BlockUser(user?.business_id?._id, false)
                                                    }>
                                                    Block
                                                </Block>
                                            )}
                                        </List>
                                    </Body>
                                );
                            })
                        ) : (
                            <div> No User Found!</div>
                        )}
                    </>
                </AppointmentsDiv>
            </Content>
        </Wrapper>
    );
};
const Wrapper = styled(Container)`
    width: 100%;
    position: relative;
    height: 100%;
    ${media.xs`
  display: block !important;
  overflow: hidden;
  max-width:100%;
`} ${media.sm`
display: block !important;
max-width:100%;
`} ${media.md`
display: flex !important;
margin:0;
min-width:100%;
`} ${media.lg`
display: flex !important;
`};
`;
const Content = styled(Row)`
    width: 100%;
    flex-direction: column;
`;
const AppointmentsDiv = styled(Col)`
    position: absolute;
    background-color: white;
    width: 100%;
    padding: 2.5rem;
    border-radius: 8px;
    @media screen and (min-width: 0px) and (max-width: 700px) {
        overflow-x: scroll;
        border: 0.5rem solid white;
        padding: 0rem;
    }
    @media screen and (min-width: 700px) and (max-width: 991px) {
        padding: 0.5rem;
    }
`;

const Head = styled.div`
    display: grid !important;
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
    gap: 0.75rem !important;
    background: #f0f0f0;
    padding: 1rem;
    border-radius: 5px;
    @media screen and (min-width: 0px) and (max-width: 700px) {
        width: 60rem;
    }
`;

const Body = styled(Head)`
    background: white;
`;

const Heading = styled.div`
    width: 100%;
    height: 100%;
    overflow-wrap: break-word;
    font-weight: bold;
    font-size: 1rem;
`;

const List = styled(Heading)`
    font-weight: normal;
`;

const Block = styled.div`
    background: red;
    color: white;
    padding: 0.5rem;
    width: 5rem;
    font-size: 0.8rem;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
`;
const UnBlock = styled(Block)`
    background: green;
    border-radius: 5px;
`;

Admin.Layout = Layout;
export default Admin;
