import React, { FC, useState } from 'react';
import { UserNav } from '@components/common';
import AsideMobile from '../AsideMobile';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
interface RouteLink {
    path: string;
    title: string;
    icon: string;
}
const Header: FC<{ activeRoute: RouteLink }> = ({ activeRoute }) => {
    const [open, setOpen] = useState(false);
    const [openNotificationPopup, setOpenNotificationPopup] = useState(false);
    const [openAuthPopup, setOpenAuthPopup] = useState(false);
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');

    const openAsidNew = () => {
        if (open) {
            setOpen(false);
        } else if (open == false) {
            setOpen(true);
        }
    };

    const openNotification = () => {
        if (openNotificationPopup) {
            setOpenNotificationPopup(false);
        } else {
            setOpenAuthPopup(false);
            setOpenNotificationPopup(true);
        }
    };

    const openAuth = () => {
        if (openAuthPopup) {
            setOpenAuthPopup(false);
        } else {
            setOpenAuthPopup(true);
            setOpenNotificationPopup(false);
        }
    };
    return (
        <>
            <Container>
                <Flex>
                    {_userData?.user?.role == 'ADMIN' || _userData?.user_login === 'customer' ? (
                        ''
                    ) : (
                        <Bars onClick={openAsidNew}>
                            <BarIcon className="far fa-bars"></BarIcon>
                        </Bars>
                    )}
                    <Title>
                        <Icon className={`${activeRoute?.icon}`}></Icon>
                        {activeRoute?.title}
                    </Title>
                </Flex>
                <UserNav
                    openNotification={openNotification}
                    openNotificationPopup={openNotificationPopup}
                    setOpenAuthPopup={setOpenAuthPopup}
                    openAuth={openAuth}
                    openAuthPopup={openAuthPopup}
                    userData={_userData}
                />
            </Container>
            {open &&
                (_userData?.user?.role == 'ADMIN' || _userData?.user_login === 'customer' ? (
                    ''
                ) : (
                    <AsideMobile open={open} openAsidNew={openAsidNew} />
                ))}
        </>
    );
};
const Container = styled.div`
    height: 64px;
    width: 100%;
    padding: 1rem 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #edf2f7;
`;
const Flex = styled.div`
    display: flex;
    align-items: center;
`;
const Title = styled.h1`
    font-size: 1.75rem;
    line-height: 1.75rem;
    font-weight: 800;
    display: flex;
    align-items: center;
    text-color: black;
    margin-left: 1rem;
    @media screen and (min-width: 200px) and (max-width: 363px) {
        display: none;
    }
    @media screen and (min-width: 200px) and (max-width: 370px) {
        margin-left: 0rem;
    }
    @media screen and (min-width: 371px) and (max-width: 398px) {
        margin-left: 0.5rem;
    }
    @media screen and (min-width: 1px) and (max-width: 434px) {
        font-size: 1.1rem;
    }
`;

const Bars = styled.h1`
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 500;
    display: none;
    align-items: center;
    text-color: black;
    align-items: center;
    ${media.xs`
    display:flex;
  `}
    ${media.sm`
    display:flex;
  `}
    ${media.md`
	display:flex;
  `}
    ${media.lg`
    display:none;
  `}
`;

const BarIcon = styled.i`
    font-size: 1.5rem !important;
    line-height: 2rem !important;
    margin-right: 1rem !important;
`;
const Icon = styled.i`
    display: block;
    font-size: 1.5rem !important;
    line-height: 2rem !important;
    margin-right: 1rem !important;
    @media screen and (min-width: 1px) and (max-width: 473px) {
        display: none !important;
    }
    ${media.xs`
    display:block;
  `}
    ${media.sm`
    display:block;
  `}
        ${media.md`
	display:block;
  `}
        ${media.lg`
    display:block;
  `}
`;
export default Header;
