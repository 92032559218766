import Button from '@components/Button';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

interface WarningModalProps {
    message: string;
    continueButtonAlternateText?: string;
    cancelButtonAlternateText?: string;
    onContinue(): void;
    onClose(): void;
}

const WarningModal = ({
    message,
    continueButtonAlternateText,
    cancelButtonAlternateText,
    onContinue,
    onClose,
}: WarningModalProps) => {
    const closeModal = (event: any) => {
        if (event.target === event.currentTarget) {
            onClose();
        }
    };
    return (
        <ModalContainer onClick={closeModal}>
            <Modal>
                <ModalHead>
                    <CloseButton onClick={onClose}>
                        <i className="fas fa-times"></i>
                    </CloseButton>
                    <ModalTitleContainer>
                        <ModalTitle>Warning</ModalTitle>
                    </ModalTitleContainer>
                </ModalHead>
                <div>{message}</div>
                <ModalFooter>
                    <Button
                        bgtype={'discard'}
                        ifClicked={() => {
                            onContinue();
                        }}
                        label={continueButtonAlternateText ?? 'Continue'}></Button>
                    <Button
                        bgtype={'primary'}
                        ifClicked={() => {
                            onClose();
                        }}
                        label={cancelButtonAlternateText ?? 'Cancel'}></Button>
                </ModalFooter>
            </Modal>
        </ModalContainer>
    );
};

const ModalContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 14.5px;
    color: rgb(39, 41, 46);
    background-color: rgba(0, 0, 0, 0.5);
    padding: 24px;
    overflow: auto;
    overscroll-behavior-y: contain;
    z-index: 102;
`;
const Modal = styled.div`
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 15px 25px -6px rgb(0 0 0 / 10%);
    padding: 24px;
    ${media.xs`max-width: 82vw;`}
    ${media.sm`max-width: 82vw;`}
    ${media.md`max-width: 42vw;`}
    ${media.lg`max-width: 42vw;`}
`;
const ModalHead = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 0.75rem;
`;
const ModalTitleContainer = styled.div`
    display: flex;
    margin-left: auto;
    margin-right: auto;
`;
const ModalTitle = styled.h2`
    margin-top: 0 !important;
    margin-bottom: 0 !important;
`;
const CloseButton = styled.div`
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3f4254;
    background-color: #e4e6ef;
    border-radius: 8px;
    font-size: 1.2rem;
    cursor: pointer;
`;
const ModalFooter = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 0.75rem;
`;

export default WarningModal;
