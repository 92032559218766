import { useTabContext } from '../TabContext';

export interface TabPanelProps {
    value: any;
    preload?: boolean;
    children?: React.ReactNode;
}

const TabPanel = ({ value, preload, children }: TabPanelProps) => {
    const context = useTabContext();
    if (context === null) {
        throw new TypeError('No TabContext provided');
    }

    return (
        <div role="tabpanel" hidden={value !== context.value} className="tabPanel">
            {preload ? children : value === context.value && children}
        </div>
    );
};

export default TabPanel;
