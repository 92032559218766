import { ICustomer } from 'interfaces/customer.interface';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import SearchField from '@components/common/Search/SearchField';
import { api } from 'helpers/auth-axios';
import Button from '@components/Button';
import { LoaderOverlay } from '@components/common';
const SelectClient = ({
    onClose,
    onSelect,
    height,
}: {
    onClose(): void;
    onSelect(customer: ICustomer): void;
    height?: any;
}) => {
    const [pageNumber, setPageNumber] = useState(0);
    const [customers, setCustomers] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [query, setQuery] = useState<any>(null);
    const [value, setValue] = useState('');
    const [initial, setInitial] = useState(false); // used to check whether data is initially on store
    const observer = useRef<IntersectionObserver>();
    const [customerData, setCustomerData] = useState<any>();
    const pageSize = 20;
    const [total, setTotal] = useState(0);
    // const customersData = useAppSelector(state => state.customer);

    const loaderRef = useCallback(
        (node: any) => {
            if (loading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(entries => {
                // if (entries[0].isIntersecting && hasMore) {
                //     setPageNumber(prevPageNumber => prevPageNumber + 1);
                // }
            });
            if (node) observer.current.observe(node);
        },
        [loading, hasMore]
    );

    const getAllCustomers = () => {
        setLoading(true);
        api.get(`/customers?page=${pageNumber}&pageSize=${pageSize}`).then((res: any) => {
            setHasMore(res.data.customers.length > 0);
            setCustomers(res.data.customers);
            setCustomerData(res.data);
            setLoading(false);
            setTotal(res?.data?.total);
        });
    };
    const loadCustomers = () => {
        setLoading(true);
        api.get(`/customers?page=${pageNumber}&pageSize=${pageSize}`).then((customers: any) => {
            setCustomers((prevCustomers: any) => [...prevCustomers, ...customers.data.customers]);
            setCustomerData(customers.data);
            setTotal(customers.data.total);
            setHasMore(customers.data.customers.length > 0);
            setLoading(false);
        });
    };
    useEffect(() => {
        if (pageNumber > 0) {
            loadCustomers();
        }
    }, [pageNumber]);
    useEffect(() => {}, []);
    const searchCustomers = (query: string) => {
        let value = query;
        if (Number.isInteger(parseInt(value)) === true) {
            if (value.length <= 3) {
                value = value.replace(/[- .]/g, '');
                // value = value + '-';
            } else if (value.length <= 7) {
                value = value.replace(/[- .]/g, '');
                value = value.slice(0, 3) + '-' + value.slice(3, 6);
            } else if (value.length >= 7) {
                value = value.replace(/[- .]/g, '');
                value = value.slice(0, 3) + '-' + value.slice(3, 6) + '-' + value.slice(6);
            }
            setLoading(true);
            api.get(`/customers?page=${pageNumber}&pageSize=${pageSize}&query=${value}`).then(
                (customers: any) => {
                    setCustomers(customers.data.customers);
                    setLoading(false);
                    setHasMore(customers.data.customers.length > 0);
                    setCustomerData(customers.data);
                }
            );
        } else {
            setLoading(true);
            api.get(`/customers?page=${pageNumber}&pageSize=${pageSize}&query=${value}`).then(
                (customers: any) => {
                    setCustomers(customers.data.customers);
                    setCustomerData(customers.data);
                    setLoading(false);
                    setHasMore(customers.data.customers.length > 0);
                }
            );
        }
    };

    useEffect(() => {
        if (customerData !== undefined && customerData.total !== 0) {
            setPageNumber(customerData && customerData.page);
            setValue((customerData !== undefined && customerData.query) ?? null);
            setQuery((customerData !== undefined && customerData.query) ?? null);
            setHasMore(true);
            setInitial(true);
            return;
        }
        getAllCustomers();
    }, []);

    const searchCustomerDebounce = useMemo(() => {
        return _.debounce((searchVal: string) => {
            setHasMore(true);
            setQuery(searchVal);
            setPageNumber(0);

            searchCustomers(searchVal);
        }, 300);
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        searchCustomerDebounce(event.target.value);
    };

    const select = (customer: ICustomer) => {
        onSelect(customer);
        onClose();
    };
    const loadMore = () => {
        setPageNumber((prevPage: any) => prevPage + 1);
    };
    return (
        <>
            {loading && <LoaderOverlay />}
            <Container>
                <Row>
                    <ClientContent lg={12}>
                        <HeaderContainer>
                            <CloseButton onClick={onClose}>
                                <Icon className="fal fa-long-arrow-left"></Icon>
                            </CloseButton>
                            <Header>Select Client</Header>
                        </HeaderContainer>
                        <SearchField value={value} handleChange={handleChange} name={`Cusomer`} />
                        <CustomersBody height={height}>
                            <Wrapper>
                                <SearchResult>
                                    {customers.length ? (
                                        customers.map((customer: ICustomer, i: any) => {
                                            return (
                                                <li key={i} onClick={() => select(customer)}>
                                                    <SearchItems>
                                                        <SearchItemWrapper>
                                                            <Circle>
                                                                <NameCircle
                                                                    src={`https://profile-images-barberone-s3.s3.amazonaws.com/${customer.photo}`}></NameCircle>
                                                            </Circle>
                                                            <SearchItemInfoContainer>
                                                                <SearchItemName>
                                                                    {customer.firstname +
                                                                        ' ' +
                                                                        customer.lastname}
                                                                </SearchItemName>
                                                                <SearchItemDetails>
                                                                    {customer.phone}
                                                                </SearchItemDetails>
                                                            </SearchItemInfoContainer>
                                                        </SearchItemWrapper>
                                                    </SearchItems>
                                                </li>
                                            );
                                        })
                                    ) : (
                                        <NotFound>No customer found</NotFound>
                                    )}
                                    <div ref={loaderRef}></div>
                                </SearchResult>
                            </Wrapper>
                        </CustomersBody>
                        {query
                            ? ''
                            : (total > (pageNumber + 1) * pageSize || loading) && (
                                  <LoadMore>
                                      <Button
                                          bgtype={'secondary'}
                                          ifClicked={loadMore}
                                          disabled={loading}
                                          //   width="12rem !important"
                                          label={`Load more customers (${
                                              total - (pageNumber + 1) * pageSize
                                          })`}></Button>
                                  </LoadMore>
                              )}
                    </ClientContent>
                </Row>
            </Container>
        </>
    );
};
const ClientContent = styled(Col)`
    height: 100%;
    display: flex;
    flex-direction: column;
`;
const HeaderContainer = styled.div`
    display: flex;
    padding: 1rem 1.75rem;
    min-height: 70px;
    align-items: center;
`;
const CloseButton = styled.div`
    margin-right: 1.5rem;
    cursor: pointer;
`;

const Icon = styled.i`
    font-size: 2.25rem;
    line-height: 2.5rem;
`;
const Header = styled.div`
    font-size: 1.75rem;
    font-weight: 600;
`;

const CustomersBody = styled.div<any>`
    flex: 0 0 25rem;
    background-color: #fff;
    margin-left: 0rem;
    overflow-y: auto;
    min-height: ${({ height }) => (height ? `58vh` : '71vh')};
`;
const Wrapper = styled.div`
    // padding-bottom: 100px;
`;
const SearchResult = styled.ul`
    // margin-top: 2rem;
    flex: 1;
    min-height: auto;
    position: relative;
    overflow-y: auto;
    list-style: none;
    padding: 0;
`;
const SearchItems = styled.div`
    cursor: pointer;
    padding: 0 24px;
    position: relative;
    &:hover {
        background-color: #f4f5f6;
    }
`;
const SearchItemWrapper = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e5e7eb;
    padding: 12px 0;
`;
const SearchItemInfoContainer = styled.div`
    flex-direction: column;
`;
const SearchItemName = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 12px;
    flex: 1;
    font-size: 14px;
`;
const SearchItemDetails = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 12px;
    font-size: 10px;
`;
const Circle = styled.div`
    display: inline-block;
    flex-shrink: 0;
    position: relative;
    border-radius: 0.42rem;
`;

const NameCircle = styled.img`
    background: linear-gradient(-135deg, #eee, #dddfe1);
    color: #7e8299;
    width: 3.14rem;
    height: 3.14rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 0.42rem;
    font-size: 1.21rem;
    text-transform: capitalize !important;
    border-radius: 50%;
`;
const LoadMore = styled.div`
    margin-top: 0.5rem;
    display: flex;
    justify-content: center;
`;
const NotFound = styled.div`
    display: flex;
    justify-content: center;
`;
export default SelectClient;
