import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';

const DatePicker = ({
    date,
    dateFormat,
    dateStyle,
    containerStyle,
    readOnly,
    onChange,
}: {
    date: moment.Moment;
    dateFormat?: string; // The format to display date (https://momentjs.com/docs/#/parsing/string-format/)
    dateStyle?: any;
    containerStyle?: any;
    readOnly?: boolean;
    onChange?(newDate: moment.Moment): void;
}) => {
    const [containerDimensions, setContainerDimensions] = useState<any>();
    const dateFieldRef = useRef<HTMLInputElement>(null);
    const dateLabelRef = useRef<HTMLLabelElement>(null);

    /*
     * We want the container and input field to be the same size as the label.
     * So, get the label size, and set the container and input field to be the same.
     */
    useEffect(() => {
        if (!dateLabelRef.current) {
            return;
        }
        // set the proper dimensions for container
        const width = dateLabelRef.current.offsetWidth;
        const height = dateLabelRef.current.offsetHeight;
        setContainerDimensions({
            width,
            height,
        });
    }, [dateLabelRef.current?.offsetWidth, dateLabelRef.current?.offsetHeight]);

    const handleLabelPress = () => {
        // @ts-ignore - old version of typescript doesnt thing the HTMLInputElement.showPicker() exists
        dateFieldRef.current?.showPicker();
    };

    // is set to true when the viewing device is running iOS
    const iOS =
        ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
            navigator.platform
        ) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

    return (
        <Container
            style={
                containerDimensions
                    ? {
                          ...containerStyle,
                          width: containerDimensions.width,
                          height: containerDimensions.height,
                      }
                    : containerStyle
            }>
            {/* For iOS, we place the input field in front of the label so we receive the click directly, since showPicker() apparently doesnt work */}
            {iOS ? (
                <>
                    <Label ref={dateLabelRef} onClick={handleLabelPress} style={dateStyle}>
                        {date.format(dateFormat ?? 'ddd, MMM DD')}
                    </Label>
                    <input
                        type="date"
                        ref={dateFieldRef}
                        value={date.format('YYYY-MM-DD')}
                        readOnly={readOnly}
                        style={
                            containerDimensions && {
                                width: containerDimensions.width,
                                height: containerDimensions.height,
                            }
                        }
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const newDate = moment(event.target.value);
                            if (onChange) {
                                onChange(newDate);
                            }
                        }}
                    />
                </>
            ) : (
                <>
                    <input
                        type="date"
                        ref={dateFieldRef}
                        value={date.format('YYYY-MM-DD')}
                        readOnly={readOnly}
                        style={
                            containerDimensions && {
                                width: containerDimensions.width,
                                height: containerDimensions.height,
                            }
                        }
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const newDate = moment(event.target.value);
                            if (onChange) {
                                onChange(newDate);
                            }
                        }}
                    />
                    <Label ref={dateLabelRef} onClick={handleLabelPress} style={dateStyle}>
                        {date.format(dateFormat ?? 'ddd, MMM DD')}
                    </Label>
                </>
            )}
        </Container>
    );
};

const Container = styled.div`
    input {
        position: absolute;
        opacity: 0;
    }
`;
const Label = styled.label`
    position: absolute;
    cursor: pointer;
`;

export default DatePicker;
