import React, { useEffect, useState } from 'react';
import { Select, Spinner } from '@components/common';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { useAppDispatch } from 'hooks/redux';
import useRouter from 'hooks/router';
import { palette } from 'styled/common';
import { IBreaks } from 'interfaces/breaks.interface';
import { useSnackbar } from '../Snackbar';
import DeleteModel from '../DeleteModel/DeleteModel';
import { calculateHoursDifference, checkHoursValue } from '../utils';
import { api } from 'helpers/auth-axios';
import moment from 'moment';
interface IProps {
    id: string; // ID of the user we're looking up working hours for
    day: {
        day: number;
        from: string;
        to: string;
    };
    active: boolean;
    onChang: (day: any) => void;
    readOnly?: boolean;
}
const Breaks = (props: any) => {
    const { id, day, onChang, readOnly }: IProps = props;
    const dispatch = useAppDispatch();
    const [openSnackbar] = useSnackbar();
    const [deleteModel, setDeleteModel] = useState(false);
    const [loading, setLoading] = useState(false);
    const router = useRouter();
    const [hour_Diff, setHours_Diff] = useState();
    const [breaks, setBreaks] = useState<any>([]);
    const [start_Time, setStart_Time] = useState();

    const getWorkingHours = async () => {
        const difference: any = await calculateHoursDifference(dayHours?.from, dayHours?.to);
        setHours_Diff(difference);

        const startTime: any = await checkHoursValue(dayHours?.from, dayHours?.to);
        setStart_Time(startTime);
    };
    const days = [
        { day: 1, label: 'Monday' },
        { day: 2, label: 'Tuesday' },
        { day: 3, label: 'Wednesday' },
        { day: 4, label: 'Thursday' },
        { day: 5, label: 'Friday' },
        { day: 6, label: 'Saturday' },
        { day: 0, label: 'Sunday' },
    ];
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    let breakHours: { label: string; value: any }[] = [];

    for (let i: any = 0; i < 24; i++) {
        for (let j = 0; j < 4; j++) {
            breakHours.push({
                label: moment({
                    hour: i,
                    minute: j * 15,
                }).format(_userData.user.business_id.time_select === '12' ? 'hh:mm a' : 'HH:mm'),
                value: moment({ hour: i, minute: j * 15 }).format('HH:mm'),
            });
        }
    }

    const [dayHours, setDayHours] = useState(day);
    const [deleteId, setDeleteId] = useState<any>();
    const onSubmit = () => {
        setLoading(true);
        let data: any = {
            from: dayHours.from,
            to: dayHours.to,
            day: dayHours.day === 7 ? 0 : dayHours.day,
        };
        api.post(`/breaks/${id}`, data)
            .then((res: any) => {
                setLoading(false);
                breaks.push(res.data);
                openSnackbar('Break saved successfully!');
                return res.data;
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };
    useEffect(() => {
        onChang(dayHours);
    }, [onChang, dayHours]);
    //get breaks
    const getBreaks = () => {
        setLoading(true);
        api.get(`/breaks/all/${id}`)
            .then((res: any) => {
                setLoading(false);
                setBreaks(res.data);
                return res.data;
            })
            .catch((e: any) => {
                setLoading(false);
                openSnackbar('Failed to load breaks! Please refresh the page.');
            });
    };
    useEffect(() => {
        getBreaks();
    }, [id, setBreaks]);
    const openDeleteModel = (id: any) => {
        setDeleteModel(true);
        setDeleteId(id);
    };

    useEffect(() => {
        getWorkingHours();
    }, [dayHours]);

    const deleteBreak = (id: any) => {
        setLoading(true);
        api.delete(`/breaks/${id}`)
            .then((res: any) => {
                setLoading(false);
                openSnackbar('Break deleted successfully!');
                setDeleteModel(false);
                const deleteBreaks: any = breaks.filter((item: any) => {
                    return item._id !== id;
                });
                setBreaks(deleteBreaks);
            })
            .catch((e: any) => {
                setLoading(false);
                setDeleteModel(false);
                openSnackbar('Failed to delete break! Please try again later.');
            });
    };

    return (
        <>
            <Wrapper>
                <Row>
                    {!readOnly && (
                        <HourItems lg={12}>
                            <Header>Add break</Header>
                            <FormBody>
                                <FormContent>
                                    <InnerWrapper>
                                        <Row>
                                            <Col xs={12} sm={8} md={8} lg={8} xl={3}>
                                                <SelectGroup>
                                                    <Select
                                                        options={days}
                                                        name="day"
                                                        label="day"
                                                        value={
                                                            dayHours.day
                                                                ? days[dayHours.day - 1].label
                                                                : ''
                                                        }
                                                        onChange={(val: string) => {
                                                            let day = days.findIndex(
                                                                days => days.label == val
                                                            );
                                                            day++;
                                                            setDayHours((prevState: any) => ({
                                                                ...prevState,
                                                                day: day,
                                                            }));
                                                        }}
                                                    />
                                                </SelectGroup>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={9}>
                                                <InnerWrapper>
                                                    <Row>
                                                        <CustomCol
                                                            xs={4}
                                                            sm={4}
                                                            md={4}
                                                            lg={4}
                                                            xl={4}>
                                                            <SelectGroup>
                                                                <Select
                                                                    options={breakHours}
                                                                    label="from"
                                                                    name="from"
                                                                    value={moment(
                                                                        moment().format() +
                                                                            ' ' +
                                                                            dayHours.from,
                                                                        'YYYY-MM-DD HH:mm:ss'
                                                                    ).format(
                                                                        _userData.user.business_id
                                                                            .time_select === '12'
                                                                            ? 'hh:mm a'
                                                                            : 'HH:mm'
                                                                    )}
                                                                    onChange={(val: string) => {
                                                                        setDayHours(prevState => ({
                                                                            ...prevState,
                                                                            from: val,
                                                                        }));
                                                                    }}
                                                                />
                                                            </SelectGroup>
                                                        </CustomCol>
                                                        <CustomCol
                                                            xs={4}
                                                            sm={4}
                                                            md={4}
                                                            lg={4}
                                                            xl={4}>
                                                            <SelectGroup>
                                                                <Select
                                                                    options={breakHours}
                                                                    label="to"
                                                                    name="to"
                                                                    value={moment(
                                                                        moment().format() +
                                                                            ' ' +
                                                                            dayHours.to,
                                                                        'YYYY-MM-DD HH:mm:ss'
                                                                    ).format(
                                                                        _userData.user.business_id
                                                                            .time_select === '12'
                                                                            ? 'hh:mm a'
                                                                            : 'HH:mm'
                                                                    )}
                                                                    onChange={(val: string) => {
                                                                        setDayHours(prevState => ({
                                                                            ...prevState,
                                                                            to: val,
                                                                        }));
                                                                    }}
                                                                />
                                                            </SelectGroup>
                                                        </CustomCol>
                                                        <CustomCol
                                                            xs={2}
                                                            sm={2}
                                                            md={2}
                                                            lg={2}
                                                            xl={2}
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                            }}>
                                                            <ClockIcon>
                                                                <Icon className="fal fa-clock "></Icon>
                                                                {hour_Diff}h
                                                            </ClockIcon>
                                                        </CustomCol>
                                                        <FlexEnd xs={2} sm={2} md={2} lg={2} xl={2}>
                                                            <AddButton>
                                                                <AddIcon
                                                                    onClick={() => {
                                                                        if (!loading) {
                                                                            onSubmit();
                                                                        }
                                                                    }}>
                                                                    {loading ? (
                                                                        <Spinner />
                                                                    ) : (
                                                                        <i className="fal fa-plus"></i>
                                                                    )}
                                                                </AddIcon>
                                                            </AddButton>
                                                        </FlexEnd>
                                                    </Row>
                                                </InnerWrapper>
                                            </Col>
                                        </Row>
                                    </InnerWrapper>
                                </FormContent>
                            </FormBody>
                        </HourItems>
                    )}
                    <Col lg={12}>
                        <div>
                            {days.map((day: any, i: any) => (
                                <BreakOff key={i}>
                                    <div>{day.label}</div>
                                    {breaks &&
                                        breaks.map((breaks: IBreaks, i: any) =>
                                            day.day == breaks.day ? (
                                                <InnerWrapper
                                                    key={i}
                                                    style={{ marginBottom: '0.5rem' }}>
                                                    <Row>
                                                        <FlexEnd xs={4} sm={4} md={4} lg={4}>
                                                            <div>
                                                                From&nbsp;
                                                                <b>
                                                                    {breaks.from
                                                                        ? moment(
                                                                              moment().format() +
                                                                                  ' ' +
                                                                                  breaks.from,
                                                                              'YYYY-MM-DD HH:mm:ss'
                                                                          ).format(
                                                                              _userData.user
                                                                                  .business_id
                                                                                  .time_select ===
                                                                                  '12'
                                                                                  ? 'hh:mm a'
                                                                                  : 'HH:mm'
                                                                          )
                                                                        : 'No Break'}
                                                                </b>
                                                            </div>
                                                        </FlexEnd>
                                                        <FlexEnd xs={4} sm={4} md={4} lg={4}>
                                                            <div>
                                                                To&nbsp;{' '}
                                                                <b>
                                                                    {breaks.to
                                                                        ? moment(
                                                                              moment().format() +
                                                                                  ' ' +
                                                                                  breaks.to,
                                                                              'YYYY-MM-DD HH:mm:ss'
                                                                          ).format(
                                                                              _userData.user
                                                                                  .business_id
                                                                                  .time_select ===
                                                                                  '12'
                                                                                  ? 'hh:mm a'
                                                                                  : 'HH:mm'
                                                                          )
                                                                        : 'No Break'}
                                                                </b>
                                                            </div>
                                                        </FlexEnd>
                                                        {!readOnly && (
                                                            <FlexEnd xs={4} sm={4} md={4} lg={4}>
                                                                <DeletIcon
                                                                    onClick={() =>
                                                                        openDeleteModel(breaks._id)
                                                                    }>
                                                                    <i className="fal fa-trash-alt"></i>
                                                                </DeletIcon>
                                                            </FlexEnd>
                                                        )}
                                                    </Row>
                                                </InnerWrapper>
                                            ) : (
                                                ''
                                            )
                                        )}
                                </BreakOff>
                            ))}
                        </div>
                        {deleteModel && (
                            <DeleteModel
                                id={deleteId}
                                name="Break"
                                content="Are you sure you want to delete?"
                                loading={loading}
                                onCLick={() => deleteBreak(deleteId)}
                                onClose={() => {
                                    setDeleteModel(false);
                                }}
                                onDelete={() => {
                                    setDeleteModel(false);
                                }}
                            />
                        )}
                    </Col>
                </Row>
            </Wrapper>
        </>
    );
};
const Wrapper = styled(Container)`
    padding: 0;
    @media screen and (min-width: 1px) and (max-width: 900px) {
        padding: 1rem;
    }
`;
const InnerWrapper = styled(Container)`
    padding: 0;
`;

const HourItems = styled(Col)``;
const Header = styled.div`
    font-weight: 500 !important;
    font-size: 1.4rem !important;
    line-height: 1.25rem !important;
    margin-top: 0.75rem !important;
`;

const FormBody = styled.div`
    margin-top: 1rem;
    // display: flex !important;
`;
const FormContent = styled.div`
    display: flex !important;
    align-items: center !important;
`;
const SelectGroup = styled.div`
    margin-bottom: 0.5rem;
`;
const CustomCol = styled(Col)`
    ${media.xs`
	padding-right:0rem;
	`}
    ${media.sm`
    padding-right:auto;
	`}
	${media.md`
    padding-right:auto;
	`}
	${media.lg`
    padding-right:auto;
	`}
`;
const FlexEnd = styled(Col)`
    display: flex;
    align-items: center;
    justify-content: end;
`;

const ClockIcon = styled.span`
    color: rgba(223, 226, 230);
    font-weight: 300;
    font-size: 0.9rem;
`;
const Icon = styled.i`
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
    margin-right: 0.5rem !important;
`;
const AddButton = styled.div`
    display: flex;
    border: none;
    cursor: pointer;
`;
const AddIcon = styled.button`
    bottom: 1.71rem;
    right: 2.14rem;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1rem 1.71rem 0 rgb(0 0 0 / 28%);
    background: #0154ff;
    border-radius: 100%;
    color: #fff;
    font-size: 1.5rem;
    border: none;
    cursor: pointer;
`;
const List = styled.div`
    display: flex;
    margin-top: 0.4rem;
    // border-top: 1px solid #e5e7eb;
`;
const BreakOff = styled.div`
    margin-top: 0.4rem;
    padding-top: 1rem;
    border-top: 1px solid #e5e7eb;
`;
const DeletIcon = styled.button`
    padding: 0;
    width: 1.85rem;
    height: 1.85rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 0rem;
    background-color: ${palette.primary};
    color: ${palette.light};
    font-weight: 600;
    text-transform: capitalize;
    border-radius: 0.51rem;
    font-size: 0.87rem;
    cursor: pointer;
`;
export default Breaks;
