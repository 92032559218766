import { useEffect, useState } from 'react';
import UpcomingBookings from './UpcomingBookings';
import PastBookings from './PastBookings';
import { Container } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { Tab, TabPanel, Tabs } from '@components/common';
import TabContext from '@components/common/TabContext';
const CustomerAppointments = ({ customerId }: { customerId: string }) => {
    const [upcoming, setUpcoming] = useState(0);
    const [totalupcomming, setTotalUpcomming] = useState<any>();
    const [done, setDone] = useState(0);
    const [tabValue, setTabValue] = useState(0);

    useEffect(() => {
        setUpcoming(0);
        setDone(0);
        setTabValue(0);
    }, [customerId]);

    return (
        <MainContainer>
            <TabContext value={tabValue}>
                <Wrapper>
                    <Tabs
                        theme="bordered"
                        onChange={(event, newValue) => {
                            setTabValue(newValue);
                        }}>
                        <Tab label={`Upcoming (${upcoming})`} />
                        <Tab label={`Past (${done})`} />
                    </Tabs>
                </Wrapper>
                <TabPanel value={0} preload={true}>
                    <UpcomingBookings
                        customerId={customerId}
                        onInitialLoad={(total: number) => setUpcoming(total)}
                    />
                </TabPanel>
                <TabPanel value={1} preload={true}>
                    <PastBookings
                        customerId={customerId}
                        onInitialLoad={(total: number) => setDone(total)}
                    />
                </TabPanel>
            </TabContext>
        </MainContainer>
    );
};

const MainContainer = styled(Container)`
    dsiplay: flex;
    flex-direction: column;
    @media screen and (min-width: 1px) and (max-width: 900px) {
        padding: 1rem;
    }
`;
const Wrapper = styled.div`
    margin-bottom: 2rem /* 32px */;
`;
export default CustomerAppointments;
