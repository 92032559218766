import { timeoffConstants } from 'actions/timeoff';
import { IGetTimeOff } from 'interfaces/timeoff.interface';

const INITIAL_STATE: IGetTimeOff = {
    timeoff: [],
};
export function timeoff(state = INITIAL_STATE, action: any) {
    switch (action.type) {
        case timeoffConstants.GET_SUCCESS:
            return {
                // ...action.timeoff,
                timeoff: action.timeoff,
            };
        case timeoffConstants.CREATE_SUCCESS:
            return {
                ...state,
                timeoff: state.timeoff?.concat(action.timeoff),
            };
        case timeoffConstants.DELETE_SUCCESS:
            return {
                ...state,
                timeoff: state.timeoff.filter(item => item._id !== action.id),
            };
        default:
            return state;
    }
}
