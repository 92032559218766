import { useCallback, useMemo, useRef, useState } from 'react';
import CardForm from './CardForm';

const NewCreditCard = ({
    initialState,
    onChange,
    loading,
    cardDisable,
}: {
    initialState: any;
    onChange(keyName: string, value: any): void;
    loading: any;
    cardDisable?: any;
}) => {
    const [state, setState] = useState(initialState);
    const [currentFocusedEl, setCurrentFocusedEl] = useState(null);

    const updateStateValues = useCallback(
        (keyName: string, value: any) => {
            onChange(keyName, value);
            setState({
                ...state,
                [keyName]: value || initialState[keyName],
            });
        },
        [state]
    );

    const formFieldsRefObj: any = {
        cardNumber: useRef(),
        cardHolder: useRef(),
        cardDate: useRef(),
        cardCVV: useRef(),
    };

    const cardElementsRef: any = {
        cardNumber: useRef(),
        cardHolder: useRef(),
        cardDate: useRef(),
    };

    const focusFormFieldByKey = useCallback(key => {
        formFieldsRefObj[key].current.focus();
    }, []);

    return (
        <div>
            <div className="">
                <div className="appointmentcard">
                    <div className="appointmentcard-body">
                        <div className="card-title">Card Details</div>

                        <CardForm
                            loading={loading}
                            cardMonth={state.cardMonth}
                            cardYear={state.cardYear}
                            onUpdateState={updateStateValues}
                            cardNumberRef={cardElementsRef.cardNumber}
                            cardHolderRef={cardElementsRef.cardHolder}
                            cardDateRef={cardElementsRef.cardDate}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewCreditCard;
