import {
    Drawer,
    Input,
    Select,
    Spinner,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    WarningModal,
    useSnackbar,
} from '@components/common';
import { useEffect, useState } from 'react';
import SelectClient from '../NewAppointmentDrawer/SelectClient';
import SelectService from '../NewAppointmentDrawer/SelectService';

import { IService } from 'interfaces/service.interface';
import { ICustomer } from 'interfaces/customer.interface';
import moment from 'moment';
import { appointmentActions } from 'actions/appointment';
import { useAppDispatch } from 'hooks/redux';
import {
    AppointmentStatusEnum,
    AppointmentStatuses,
    IAppointment,
    IUpdateAppointmentStatus,
} from 'interfaces/appointment.interface';

import as from 'components/appointments/Appointment.module.scss';
import EditRecurringModal from '../Modal/EditRecurringModal';
import styled from 'styled-components';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import Button from '@components/Button';
import { IServiceProvider } from 'interfaces/team/serviceProvider.interface';
import SelectServiceProvider from '../NewAppointmentDrawer/SelectServiceProvider';
import ConflictModal from '../Modal/ConflictModal';
import DeleteRecurringModal from '../Modal/DeleteRecurringModal';
import { api } from 'helpers/auth-axios';
import DeleteModel from '@components/common/DeleteModel/DeleteModel';
import { SmallSwitch } from '@components/common/Switch';
import { TimePicker } from '@components/common/Select';
import CompletePayment from './CompletePayment/CompletePayment';
import MarkPaid from './MarkPaid/MarkPaid';
import CustomerModal from '../Modal/CustomerModal';
import { paymentOptions } from 'constants/paymentOptions';

const getAppointmentLabel = (status: AppointmentStatusEnum) => {
    switch (status) {
        case AppointmentStatusEnum.CONFIRMED:
        case AppointmentStatusEnum.COMPLETED:
            return as.appointment_statusSuccess;
        case AppointmentStatusEnum.LATE:
            return as.appointment_statusWarning;
        case AppointmentStatusEnum.CANCELLED:
            return as.appointment_statusDanger;
        default:
            return as.appointment_statusGray;
    }
};

// const appointmentTime: { label: string; value: any }[] = [];
// for (let hour = 0; hour < 24; hour++) {
//     for (let j = 0; j < 4; j++) {
//         appointmentTime.push({
//             label: moment({
//                 hour,
//                 minute: j * 15,
//             }).format(_userData?.user?.business_id.time_select === '12' ? 'hh:mm a' : 'HH:mm a'),
//             value: moment({ hour, minute: j * 15 }).format('HH:mm'),
//         });
//     }
// }
const DurationHours: { label: string; value: number }[] = [];
for (let i = 0; i < 24; i++) {
    DurationHours.push({
        label: `${i}h`,
        value: i,
    });
}

const DurationMinutes: { label: string; value: number }[] = [];
for (let i = 0; i < 4; i++) {
    DurationMinutes.push({
        label: `${i * 15}min`,
        value: i * 15,
    });
}
const formatDate = (date: Date) => {
    return moment(date).format('YYYY-MM-DD') + 'T' + moment(date).format('HH:mm:ss');
};

const EditAppointmentDrawer = ({
    id,
    onChange,
    onClose,
    getAppointments,
    setCalendarLoading,
}: {
    id: string;
    onChange(appointment: IAppointment): void;
    onClose(): void;
    getAppointments: any;
    setCalendarLoading(value: boolean): void;
}) => {
    const [openSnackbar] = useSnackbar();
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');

    const [appointment, setAppointment] = useState<IAppointment>();
    const [loading, setLoading] = useState(false);
    const [appointmentStatus, setAppointmentStatus] = useState(false);

    const [active, setActive] = useState(false);
    const [customerView, setCustomerView] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState<ICustomer>();
    const [selectedServiceProvider, setSelectedServiceProvider] = useState<IServiceProvider>();
    const [serviceView, setServiceView] = useState(false);
    const [serviceProviderView, setServiceProviderView] = useState(false);
    const [selectedService, setSelectedService] = useState<any>();
    const [showRecurringModal, setShowRecurringModal] = useState(false);
    const [showAppointmentModal, setShowAppointmentModal] = useState(false);
    const [conflictDates, setConflictDates] = useState<string[]>([]);
    const [showConflictModal, setShowConflictModal] = useState(false);
    const [appointmentNote, setAppointmentNote] = useState<string>();
    const [customerNote, setCustomerNote] = useState<string>();
    const [start, setStart] = useState<any>();
    const [end, setEnd] = useState<any>();
    const [status, setStatus] = useState<AppointmentStatusEnum>(AppointmentStatusEnum.CONFIRMED);
    const [overbooking, setOverbooking] = useState(false);
    const [providerId, setProviderId] = useState<any>();
    const [recurringId, setRecurringId] = useState<any>();
    const [startDate, setStartDate] = useState<any>();
    const [enddate, setEndDate] = useState<any>();
    const [platform, setPlatForm] = useState<any>();
    const [createdDate, setCreatedDate] = useState<any>();
    const [durationField, setDurationFieldOpen] = useState<boolean>(false);
    const [serviceDurationHours, setServiceDurationHours] = useState<any>(0);
    const [durationHours, setDurationHours] = useState<any>();
    const [serviceDurationMinutes, setServiceDurationMinutes] = useState<any>(0);
    const [durationMinutes, setDurationMinutes] = useState<any>();
    const [availableSlot, setAvailableSlot] = useState<any[]>([]);
    const [dropDownFilter, setDropdownFilter] = useState<any>();
    const [payment, setPayment] = useState<any>();
    const [paymentIntentId, setPaymentIntentId] = useState<any>('');
    const [paymentMethodId, setPaymentMethodId] = useState<any>('');
    const [transaction, setTransaction] = useState<any>(null);
    const [chargeModel, setChargeModel] = useState<boolean>(false);
    const [is_vip, setIs_vip] = useState<any>();
    const [vipAppointment, setVipAppointment] = useState<any>(true);
    const [startTime, setStartTime] = useState<any>();
    const [customerModel, setCustomerModel] = useState(false);
    const [customerEditModel, setCustomerEditModel] = useState(false);
    const [markPaidModel, setMarkPaidModel] = useState<boolean>(false);

    const [showServiceProviderWarningModal, setShowServiceProviderWarningModal] = useState(false);
    const [showMarkUnpaidWarningModal, setShowMarkUnpaidWarningModal] = useState(false);
    const [hideChargeButton, setHideChargeButton] = useState(false);

    /*
     * When this view is loading data while opening the drawer, we want then
     * calendar to show the LoadingOverlay
     */
    useEffect(() => {
        setCalendarLoading(loading);
    }, [loading]);

    const dispatch = useAppDispatch();
    const event = {
        id,
        start,
        end,
        customer: selectedCustomer,
        service: selectedService,
        serviceProvider: selectedServiceProvider,
    };

    const discard = () => {
        setActive(false);
        updateEvent(appointment);
    };
    const appointmentTime: { label: string; value: any }[] = [];
    //appointmentTime.push({ label: 'Select Time', value: '' });
    let time = moment(startTime).format('HH:mm');
    let date = moment(startDate ? startDate : start).format('YYYY-MM-DD');
    let todayDate = moment().format('YYYY-MM-DD');
    availableSlot.length &&
        availableSlot.forEach((slot: any) => {
            if (_userData?.user_login === 'customer' && date === todayDate) {
                // limit the time range customers can schedule for current day
                slot.time >= time &&
                    appointmentTime.push({
                        label: moment(
                            moment().format() + ' ' + slot.time,
                            'YYYY-MM-DD HH:mm:ss'
                        ).format(
                            _userData?.user?.business_id.time_select === '12' ? 'hh:mm a' : 'HH:mm'
                        ),
                        value: moment(
                            moment().format() + ' ' + slot.time,
                            'YYYY-MM-DD HH:mm:ss'
                        ).format('HH:mm'),
                    });
            } else {
                appointmentTime.push({
                    label: moment(
                        moment().format() + ' ' + slot.time,
                        'YYYY-MM-DD HH:mm:ss'
                    ).format(
                        _userData?.user?.business_id.time_select === '12' ? 'hh:mm a' : 'HH:mm'
                    ),
                    value: moment(
                        moment().format() + ' ' + slot.time,
                        'YYYY-MM-DD HH:mm:ss'
                    ).format('HH:mm'),
                });
            }
        });

    const setUpdateAppointment = (appointment: IAppointment) => {
        const appointmentDuration: any = appointment!.duration;
        setProviderId(appointment.serviceProvider._id);
        setAppointment(appointment);
        setAppointmentNote(appointment.appointment_note);
        setCustomerNote(appointment.customer_note);
        setSelectedCustomer(appointment.customer);
        // Service is tricky, the one attached to the populated appointment here isn't the one we want,
        // we want the staffService which contains this specific provider's settings
        //setSelectedService(appointment.service);
        setSelectedServiceProvider(appointment.serviceProvider);
        setStart(appointment.booked_from_iso);
        setStartTime(appointment.booked_from_iso);
        setEnd(appointment.booked_till_iso);
        setStartDate(appointment.booked_from_iso);
        setEndDate(appointment.booked_till_iso);
        setServiceDurationHours(
            appointmentDuration >= 60 ? Math.floor(appointmentDuration / 60) : ''
        );
        setDurationHours(appointmentDuration >= 60 ? Math.floor(appointmentDuration / 60) : '');
        setDurationMinutes(appointmentDuration % 60);
        setServiceDurationMinutes(appointmentDuration % 60);
        setDropdownFilter(moment(appointment.booked_from_iso).format('HH:mm'));
        setPaymentIntentId(appointment.payment_intent_id);
        setPaymentMethodId(appointment.payment_method_id);
        setPayment(appointment.payment);
        setIs_vip(appointment?.is_vip);
    };

    useEffect(() => {
        if (payment && payment !== '') {
            api.get(`/transactions/${payment}`)
                .then((res: any) => {
                    setTransaction(res.data);
                })
                .catch((e: any) => {
                    if (e?.esponse) {
                        openSnackbar(e?.response?.data?.message);
                    }
                });
        }
    }, [payment]);
    useEffect(() => {
        if (!appointment?.service || selectedService) {
            return;
        }

        if (!selectedServiceProvider) {
            return;
        }

        setLoading(true);
        api.get(`/serviceProvider/${selectedServiceProvider?._id}`)
            .then((res: any) => {
                const staffServicesList = res.data.services;
                const currentService = staffServicesList.find(
                    (staffService: any) => staffService.service_id === appointment.service._id
                );
                const data = {
                    price: currentService.price,
                    _id: currentService.service_id,
                    my_price: currentService.my_price,
                    my_duration: currentService.my_duration,
                    duration: currentService.duration,
                    name: currentService.name,
                    color: currentService.color,
                    available_for_boooking: currentService.available_for_boooking,
                };
                setSelectedService(data);
                setLoading(false);
            })
            .catch((e: any) => {
                console.log(e);
                openSnackbar(e?.response?.data?.message);
                setLoading(false);
            });
    }, [appointment?.service, selectedServiceProvider]);
    useEffect(() => {
        setLoading(true);
        dispatch(appointmentActions.getAppoinmentById(id)).then((res: any) => {
            setLoading(false);
            if (res.error) return;
            setRecurringId(res.recurring);
            const appointment = res as IAppointment;
            setUpdateAppointment(appointment);
            setPlatForm(appointment.platform);
            setCreatedDate(appointment.created_at);
            setStatus(appointment.status);
            setTimeout(() => setActive(true), 20);
        });
    }, []);
    useEffect(() => {
        if (overbooking) onSubmit();
    }, [overbooking]);
    const updateEvent = (data: any) => {
        onChange({
            ...event,
            ...data,
        });
    };
    const defaultDuration = serviceDurationHours * 60 + serviceDurationMinutes;
    const customDuration = durationHours * 60 + durationMinutes;
    const onSubmit = () => {
        const startTime =
            moment(startDate ? startDate : start).format('YYYY-MM-DD') +
            'T' +
            moment(start).format('HH:mm') +
            ':00';
        const endTime =
            moment(enddate ? enddate : end).format('YYYY-MM-DD') +
            'T' +
            moment(start)
                .add(durationField === false ? defaultDuration : customDuration, 'minutes')
                .format('HH:mm') +
            ':00';
        const updatedAppointment = {
            booked_from_iso: startTime ? moment(startTime).format() : moment(start).format(),
            booked_till_iso: endTime ? moment(endTime).format() : moment(end).format(),
            booked_from: startTime ? moment(startTime).format() : moment(start).format(),
            booked_till: endTime ? moment(endTime).format() : moment(end).format(),
            appointment_note: appointmentNote ? appointmentNote : '',
            serviceProvider: selectedServiceProvider ? selectedServiceProvider?._id : null,
            customer: selectedCustomer ? selectedCustomer?._id : null,
            service: selectedService!._id,
            total: _userData.user.business_id.currency + selectedService?.my_price,
            total_value: parseInt(selectedService?.my_price!),
            customer_note: customerNote ? customerNote : '',
            duration: durationField === false ? defaultDuration : customDuration,
        };
        setLoading(true);
        if (availableSlot.length) {
            dispatch(appointmentActions.rescheduleAppointment(id, updatedAppointment))
                .then((res: any) => {
                    setLoading(false);
                    if (res.error) {
                        return;
                    }

                    setHideChargeButton(false);
                    openSnackbar('Appointment updated successfully!');
                    setActive(false);
                })
                .catch(e => {
                    setLoading(false);
                    if (e?.response) {
                        openSnackbar(e.response.data.message);
                        const response = e?.response?.data?.response;
                        if (response?.dates) {
                            setConflictDates(response?.dates);
                            setShowConflictModal(true);
                        } else {
                            onClose();
                        }
                    }
                });
        } else {
            setLoading(false);
            openSnackbar('No slot available at this time!');
        }
    };
    const updateAppointmentStatus = (status: AppointmentStatusEnum) => {
        setLoading(true);
        const statusUpdate: IUpdateAppointmentStatus = {
            status: status,
        };
        dispatch(appointmentActions.updateAppointmentStatus(id, statusUpdate))
            .then((res: any) => {
                setLoading(false);
                setHideChargeButton(false);
                openSnackbar('Appointment status updated successfully!');
                setActive(false);
            })
            .catch(e => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e.response.data.message);
                }
            });
    };
    const singleAppointmentDelete = () => {
        setLoading(true);
        api.delete(`/appointments/${id}`)
            .then(() => {
                setLoading(false);
                openSnackbar('Appointment deleted successfully!');
                getAppointments();
            })
            .catch((res: any) => {
                setLoading(false);
                openSnackbar('Failed to delete Appointment!');
            });
    };

    const appointmentStatusOpen = () => {
        if (appointmentStatus) {
            setAppointmentStatus(false);
        } else {
            setAppointmentStatus(true);
        }
    };
    const doSetStatus = (status: AppointmentStatusEnum) => {
        setStatus(status);
        updateAppointmentStatus(status);
        appointmentStatusOpen();
    };

    const hiddenDateCalendar = (e: any) => {
        setStartDate(e.target.value);
        setEndDate(e.target.value);
    };

    const getAvailableSlot = (start_date = '') => {
        if (selectedServiceProvider !== undefined) {
            let date: any = moment(startDate ? startDate : start).format();
            let duration = durationField === false ? defaultDuration : customDuration;
            api.post(`appointments/available_slots/${selectedServiceProvider._id}`, {
                date: date,
                duration: duration,
                appointmentIdBeingRescheduled: id,
            }).then((res: any) => {
                setAvailableSlot(res.data);
            });
        }
    };

    useEffect(() => {
        getAvailableSlot();
    }, [startDate, defaultDuration, customDuration, start, is_vip]);

    useEffect(() => {
        dropDownValue();
    }, [startDate, defaultDuration, customDuration, start, selectedService]);
    //}, [startDate, defaultDuration, customDuration, start, selectedService, dropDownFilter]);
    const dropDownValue = () => {
        const value = moment(start).format('HH:mm');
        appointmentTime.forEach((val: any, i: any) => {
            if (val.value === value) {
                setDropdownFilter(val.value);
                return;
            } else {
                return appointmentTime[0].label;
            }
        });
    };

    const markUnpaid = () => {
        api.put(`/appointments/${appointment?._id}/mark-unpaid`)
            .then((res: any) => {
                openSnackbar('Appointment marked as "Unpaid"!');
                setActive(false);
            })
            .catch((e: any) => {
                if (e?.response) {
                    openSnackbar(e.response.data.message);
                }
            });
    };

    /*
     * Helper/Utility Functions
     */
    const onlinePaymentIsDisabled = () => {
        // return true if business has disabled prepaying
        if (_userData.user.business_id?.booking_without_payment) {
            return true;
        }
        // return false if business or service provider have a payment processor enabled
        if (aPaymentProcessorIsEnabled()) {
            return false;
        }
        // default case: return true, to say "prepay is disabled"
        return true;
    };

    // Return true if business or provider have a payment processor enabled
    const aPaymentProcessorIsEnabled = () => {
        // First, check the service provider
        // Check if stripe is enabled
        if (selectedServiceProvider?.stripe_account_verified) {
            return true;
        }
        // Check if square is enabled
        if (selectedServiceProvider?.square_account_verified) {
            return true;
        }
        // Now, we check the business
        // Check if stripe is enabled
        if (_userData.user.business_id?.stripe_account_verified) {
            return true;
        }
        // Check if square is enabled
        if (_userData.user.business_id?.square_account_verified) {
            return true;
        }
        // Default case, return False since nothing else shows as enabled
        return false;
    };

    const formatPhoneNumberForLink = (phoneNumber: string) => {
        // customer phone numbers are stored in format 012-345-6789
        // we want them in the format +10123456789
        return `+1${phoneNumber.replaceAll('-', '')}`;
    };

    /*
     * UI variables (that need to be declared last)
     */
    const showPaymentButtons =
        !onlinePaymentIsDisabled() ||
        appointment?.payment_intent_id ||
        appointment?.square_payment_id;

    return (
        <>
            <Container>
                <Row>
                    <Col sm={12} md={12} lg={12}>
                        <Drawer custom={true} active={active} onClose={onClose}>
                            {!customerView &&
                                !serviceView &&
                                !serviceProviderView &&
                                !chargeModel &&
                                !markPaidModel && (
                                    <AppointmentContent>
                                        <HeaderContainer>
                                            <CloseButton
                                                onClick={() => {
                                                    setActive(false);
                                                }}>
                                                <Icon className="fal fa-times"></Icon>
                                            </CloseButton>
                                            <Header>Appointment</Header>
                                            <ToggleButton>
                                                <Dropdown>
                                                    <DropdownToggle onClick={appointmentStatusOpen}>
                                                        <StatusButton
                                                            onClick={appointmentStatusOpen}
                                                            className={`${getAppointmentLabel(
                                                                status
                                                            )}`}>
                                                            {status ? status : appointment?.status}
                                                            <DownIcon className="fas fa-chevron-down"></DownIcon>
                                                        </StatusButton>
                                                    </DropdownToggle>
                                                    {appointmentStatus && (
                                                        <DropdownMenu>
                                                            {AppointmentStatuses.map(status => {
                                                                return (
                                                                    <DropdownItem
                                                                        key={status}
                                                                        onClick={() => {
                                                                            doSetStatus(
                                                                                status as AppointmentStatusEnum
                                                                            );
                                                                        }}>
                                                                        <Status
                                                                            className={`${
                                                                                as.appointment_statusLabel
                                                                            } ${getAppointmentLabel(
                                                                                status as AppointmentStatusEnum
                                                                            )}`}>
                                                                            {status}
                                                                        </Status>
                                                                    </DropdownItem>
                                                                );
                                                            })}
                                                        </DropdownMenu>
                                                    )}
                                                </Dropdown>
                                            </ToggleButton>
                                        </HeaderContainer>
                                        <DrawerBody>
                                            {selectedCustomer ? (
                                                <div>
                                                    <SelectCustomer>
                                                        <div
                                                            style={{ display: 'flex' }}
                                                            onClick={() => {
                                                                _userData?.user_login !==
                                                                    'customer' &&
                                                                    setCustomerView(true);
                                                            }}>
                                                            <Circle>
                                                                <NameCircle
                                                                    src={`https://profile-images-barberone-s3.s3.amazonaws.com/${selectedCustomer?.photo}`}></NameCircle>
                                                            </Circle>
                                                            <CustomerContent>
                                                                <Name>
                                                                    {selectedCustomer.firstname +
                                                                        ' ' +
                                                                        selectedCustomer.lastname}
                                                                </Name>
                                                                <Email>
                                                                    {selectedCustomer.email}
                                                                </Email>
                                                            </CustomerContent>
                                                        </div>
                                                        {/* <div>
                                                        <Icons className="fal fa-chevron-right"></Icons>
                                                    </div> */}
                                                        <IconsDiv>
                                                            <Icons
                                                                className="fal fa-edit"
                                                                onClick={() =>
                                                                    _userData?.user_login !==
                                                                        'customer' &&
                                                                    setCustomerEditModel(true)
                                                                }></Icons>
                                                            {selectedCustomer.phone && (
                                                                <a
                                                                    href={`sms:${formatPhoneNumberForLink(
                                                                        selectedCustomer.phone
                                                                    )}`}>
                                                                    <Icons className="fal fa-comment"></Icons>
                                                                </a>
                                                            )}

                                                            <Icons
                                                                className="fal fa-plus"
                                                                onClick={() =>
                                                                    _userData?.user_login !==
                                                                        'customer' &&
                                                                    setCustomerModel(true)
                                                                }></Icons>
                                                            <Icons
                                                                className="fal fa-user"
                                                                onClick={() => {
                                                                    _userData?.user_login !==
                                                                        'customer' &&
                                                                        setCustomerView(true);
                                                                }}></Icons>
                                                        </IconsDiv>
                                                    </SelectCustomer>
                                                </div>
                                            ) : (
                                                <div>
                                                    <AppointmentCustomer>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                            }}
                                                            onClick={() => {
                                                                _userData?.user_login !==
                                                                    'customer' &&
                                                                    setCustomerView(true);
                                                            }}>
                                                            <Circle>
                                                                <NameCircle></NameCircle>
                                                            </Circle>
                                                            <Label>Select a client</Label>
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                            }}>
                                                            <Icons
                                                                className="fal fa-plus"
                                                                onClick={() =>
                                                                    _userData?.user_login !==
                                                                        'customer' &&
                                                                    setCustomerModel(true)
                                                                }></Icons>
                                                            <Icons
                                                                className="fal fa-user"
                                                                onClick={() => {
                                                                    _userData?.user_login !==
                                                                        'customer' &&
                                                                        setCustomerView(true);
                                                                }}></Icons>
                                                        </div>
                                                    </AppointmentCustomer>
                                                </div>
                                            )}

                                            {selectedServiceProvider ? (
                                                <div>
                                                    {_userData.user_login == 'user' ? (
                                                        <ServiceProviderContent
                                                            onClick={() => {
                                                                if (
                                                                    paymentIntentId !== '' &&
                                                                    paymentMethodId !== ''
                                                                ) {
                                                                    setShowServiceProviderWarningModal(
                                                                        true
                                                                    );
                                                                } else {
                                                                    setServiceProviderView(true);
                                                                }
                                                            }}>
                                                            <div style={{ display: 'flex' }}>
                                                                <Circle>
                                                                    <NameCircle
                                                                        src={`https://profile-images-barberone-s3.s3.amazonaws.com/${selectedServiceProvider?.photo}`}></NameCircle>
                                                                </Circle>
                                                                <CustomerContent>
                                                                    <Name>
                                                                        {
                                                                            selectedServiceProvider.name
                                                                        }
                                                                    </Name>
                                                                    <Email>
                                                                        {
                                                                            selectedServiceProvider.email
                                                                        }
                                                                    </Email>
                                                                </CustomerContent>
                                                            </div>
                                                            <IconsDiv>
                                                                <Icons className="fal fa-user"></Icons>
                                                            </IconsDiv>
                                                        </ServiceProviderContent>
                                                    ) : (
                                                        <ServiceProviderContent>
                                                            <div style={{ display: 'flex' }}>
                                                                <Circle>
                                                                    <NameCircle
                                                                        src={`https://profile-images-barberone-s3.s3.amazonaws.com/${selectedServiceProvider?.photo}`}></NameCircle>
                                                                </Circle>
                                                                <CustomerContent>
                                                                    <Name>
                                                                        {
                                                                            selectedServiceProvider.name
                                                                        }
                                                                    </Name>
                                                                    <Email>
                                                                        {
                                                                            selectedServiceProvider.email
                                                                        }
                                                                    </Email>
                                                                </CustomerContent>
                                                            </div>
                                                            <IconsDiv>
                                                                <Icons className="fal fa-chevron-right"></Icons>
                                                            </IconsDiv>
                                                        </ServiceProviderContent>
                                                    )}
                                                </div>
                                            ) : (
                                                <div>
                                                    <ServiceProviderContent
                                                        onClick={() => {
                                                            if (
                                                                paymentIntentId !== '' &&
                                                                paymentMethodId !== ''
                                                            ) {
                                                                setShowServiceProviderWarningModal(
                                                                    true
                                                                );
                                                            } else {
                                                                setServiceProviderView(true);
                                                            }
                                                        }}>
                                                        <div style={{ display: 'flex' }}>
                                                            <Circle>
                                                                <NameCircle></NameCircle>
                                                            </Circle>
                                                            <Label>Select a serviceProvider</Label>
                                                        </div>
                                                        <IconsDiv>
                                                            <Icons className="fal fa-plus"></Icons>
                                                        </IconsDiv>
                                                    </ServiceProviderContent>
                                                </div>
                                            )}
                                            <FormContent>
                                                <FormDetails>
                                                    <DatesBody>
                                                        <FormDate>
                                                            <CalenderDate>
                                                                <i className="fal fa-calendar-day"></i>{' '}
                                                                {startDate
                                                                    ? moment(startDate).format(
                                                                          'ddd, MMM DD'
                                                                      )
                                                                    : moment(start).format(
                                                                          'ddd, MMM DD'
                                                                      )}
                                                            </CalenderDate>
                                                            <input
                                                                type="date"
                                                                min={
                                                                    new Date()
                                                                        .toISOString()
                                                                        .split('T')[0]
                                                                }
                                                                value={moment(startDate).format(
                                                                    'YYYY-MM-DD'
                                                                )}
                                                                onChange={(e: any) =>
                                                                    hiddenDateCalendar(e)
                                                                }
                                                            />
                                                        </FormDate>
                                                        <CustomButton>
                                                            {appointment?.recurring
                                                                ? appointment?.recurring && (
                                                                      <RepeateButton
                                                                          onClick={() =>
                                                                              setShowRecurringModal(
                                                                                  true
                                                                              )
                                                                          }>
                                                                          Recurring Options
                                                                          <RepeateIcon className="fas fa-repeat"></RepeateIcon>
                                                                      </RepeateButton>
                                                                  )
                                                                : _userData.user_login !==
                                                                      'customer' && (
                                                                      <Button
                                                                          ifClicked={() =>
                                                                              setShowAppointmentModal(
                                                                                  true
                                                                              )
                                                                          }
                                                                          bgtype={'primary'}
                                                                          label="Delete Appointment"></Button>
                                                                  )}
                                                        </CustomButton>
                                                    </DatesBody>
                                                    {selectedService ? (
                                                        <SelectServiceContent
                                                            onClick={() => {
                                                                _userData.user_login !==
                                                                    'customer' &&
                                                                    setServiceView(true);
                                                            }}>
                                                            <ServiceBorder
                                                                className={`service-color-${selectedService.color}--bd`}></ServiceBorder>
                                                            <ServiceDetails>
                                                                <NameBody>
                                                                    <ServiceName>
                                                                        {selectedService.name}
                                                                    </ServiceName>
                                                                    <ServiceDuration>
                                                                        {appointment!.duration}min
                                                                    </ServiceDuration>
                                                                </NameBody>
                                                                <PriceBody>
                                                                    <ServicePrice>
                                                                        {/* {
                                                                        _userData.user.business_id
                                                                            .currency
                                                                    } */}
                                                                        {appointment?.total}
                                                                    </ServicePrice>
                                                                    <div>
                                                                        <Icons className="fal fa-chevron-right"></Icons>
                                                                    </div>
                                                                </PriceBody>
                                                            </ServiceDetails>
                                                        </SelectServiceContent>
                                                    ) : (
                                                        <SelectServiceBody
                                                            onClick={() => {
                                                                setServiceView(true);
                                                            }}>
                                                            <ServiceHeader>
                                                                Select service
                                                            </ServiceHeader>
                                                            <div>
                                                                <Icons className="fal fa-chevron-right"></Icons>
                                                            </div>
                                                        </SelectServiceBody>
                                                    )}

                                                    {_userData.user_login !== 'customer' && (
                                                        <InputGroup>
                                                            <InputContent>
                                                                <Col lg={12}>
                                                                    <Select
                                                                        label="Start"
                                                                        value={
                                                                            availableSlot.length &&
                                                                            dropDownFilter
                                                                                ? moment(
                                                                                      moment().format() +
                                                                                          ' ' +
                                                                                          dropDownFilter,
                                                                                      'YYYY-MM-DD HH:mm:ss'
                                                                                  ).format(
                                                                                      _userData
                                                                                          ?.user
                                                                                          ?.business_id
                                                                                          .time_select ===
                                                                                          '12'
                                                                                          ? 'hh:mm a'
                                                                                          : 'HH:mm'
                                                                                  )
                                                                                : 'No slot available'
                                                                        }
                                                                        options={appointmentTime}
                                                                        onChange={(val: string) => {
                                                                            const newStart =
                                                                                moment(
                                                                                    start
                                                                                ).format(
                                                                                    'YYYY-MM-DD'
                                                                                ) +
                                                                                'T' +
                                                                                val +
                                                                                ':00';

                                                                            setStart(newStart);
                                                                        }}
                                                                    />
                                                                </Col>
                                                            </InputContent>
                                                            <Row>
                                                                <Col lg={6}>
                                                                    <InputGroup>
                                                                        <Select
                                                                            label="Hour(s)"
                                                                            readOnly={
                                                                                !durationField
                                                                            }
                                                                            options={DurationHours}
                                                                            value={
                                                                                durationField ===
                                                                                false
                                                                                    ? serviceDurationHours ===
                                                                                      ''
                                                                                        ? '0h'
                                                                                        : serviceDurationHours +
                                                                                          'h'
                                                                                    : durationHours ===
                                                                                      ''
                                                                                    ? '0h'
                                                                                    : durationHours +
                                                                                      'h'
                                                                            }
                                                                            onChange={(
                                                                                val: string
                                                                            ) => {
                                                                                setDurationHours(
                                                                                    parseInt(val)
                                                                                );
                                                                            }}
                                                                        />
                                                                    </InputGroup>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <InputGroup>
                                                                        <Select
                                                                            label="Minutes"
                                                                            readOnly={
                                                                                !durationField
                                                                            }
                                                                            options={
                                                                                DurationMinutes
                                                                            }
                                                                            value={
                                                                                durationField ===
                                                                                false
                                                                                    ? serviceDurationMinutes +
                                                                                      'min'
                                                                                    : durationMinutes +
                                                                                      'min'
                                                                            }
                                                                            onChange={(
                                                                                val: string
                                                                            ) => {
                                                                                setDurationMinutes(
                                                                                    parseInt(val)
                                                                                );
                                                                            }}
                                                                        />
                                                                    </InputGroup>
                                                                </Col>
                                                            </Row>
                                                            <ToggleDurationButton>
                                                                <div>
                                                                    {is_vip === true && (
                                                                        <>
                                                                            <CustomSpan>
                                                                                VIP
                                                                            </CustomSpan>
                                                                            <SmallSwitch
                                                                                value={true}
                                                                                disabled={true}
                                                                                onChange={() =>
                                                                                    setVipAppointment(
                                                                                        true
                                                                                    )
                                                                                }
                                                                            />{' '}
                                                                        </>
                                                                    )}
                                                                </div>
                                                                <div>
                                                                    <CustomSpan>
                                                                        Custom Duration
                                                                    </CustomSpan>
                                                                    <SmallSwitch
                                                                        value={durationField}
                                                                        onChange={() => {
                                                                            setDurationFieldOpen(
                                                                                !durationField
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                            </ToggleDurationButton>
                                                        </InputGroup>
                                                    )}
                                                    {_userData.user_login !== 'customer' && (
                                                        <InputGroup>
                                                            <Input
                                                                label="Appointment note (only visible to staff)"
                                                                value={appointmentNote}
                                                                onChange={({
                                                                    value,
                                                                }: {
                                                                    value: string;
                                                                }) => {
                                                                    setAppointmentNote(value);
                                                                }}
                                                            />
                                                        </InputGroup>
                                                    )}
                                                    <InputGroup>
                                                        <Input
                                                            label={`${
                                                                _userData.user_login !== 'customer'
                                                                    ? 'Message from client'
                                                                    : 'Message to serviceProvider'
                                                            }`}
                                                            value={customerNote}
                                                            readOnly={true}
                                                            onChange={({
                                                                value,
                                                            }: {
                                                                value: string;
                                                            }) => {
                                                                setCustomerNote(value);
                                                            }}
                                                        />
                                                    </InputGroup>

                                                    <FullWidthButtonContainer>
                                                        <CreatedDateText>
                                                            Created{' '}
                                                            {moment(createdDate).format(
                                                                'ddd, MMM D, YYYY h:mm a'
                                                            )}
                                                        </CreatedDateText>
                                                    </FullWidthButtonContainer>

                                                    <PlatformDiv>
                                                        {_userData.user_login !== 'customer' && (
                                                            <TotalPriceBody>
                                                                <PlatForm>Booked From</PlatForm>
                                                                <PlatFormText>
                                                                    {platform}
                                                                </PlatFormText>
                                                            </TotalPriceBody>
                                                        )}

                                                        <TotalPriceBody>
                                                            <PlatForm>Payment Status</PlatForm>
                                                            <PlatFormText>
                                                                {payment == null
                                                                    ? 'unpaid'
                                                                    : 'paid'}{' '}
                                                                {payment !== null &&
                                                                    transaction !== null && (
                                                                        <span>
                                                                            (
                                                                            {
                                                                                paymentOptions.find(
                                                                                    option =>
                                                                                        transaction
                                                                                            .payment_method
                                                                                            .code ===
                                                                                        option.code
                                                                                )?.label
                                                                            }
                                                                            )
                                                                        </span>
                                                                    )}
                                                            </PlatFormText>
                                                        </TotalPriceBody>
                                                        <TotalPriceBody>
                                                            <PriceHeader>Total</PriceHeader>
                                                            <TotalPrice>
                                                                {
                                                                    _userData.user.business_id
                                                                        .currency
                                                                }
                                                                {appointment?.total_value?.toFixed(
                                                                    2
                                                                )}
                                                            </TotalPrice>
                                                        </TotalPriceBody>
                                                    </PlatformDiv>

                                                    <FormFooter>
                                                        <FormButtons
                                                            style={{ marginBottom: '1.0rem' }}>
                                                            {payment == null &&
                                                                !hideChargeButton &&
                                                                _userData.user_login !==
                                                                    'customer' &&
                                                                showPaymentButtons && (
                                                                    <Button
                                                                        bgtype={'discard'}
                                                                        ifClicked={() => {
                                                                            setChargeModel(true);
                                                                        }}
                                                                        label="Charge Card"></Button>
                                                                )}
                                                            {payment !== null &&
                                                                _userData.user_login !==
                                                                    'customer' && (
                                                                    <Button
                                                                        bgtype={'discard'}
                                                                        ifClicked={() =>
                                                                            setShowMarkUnpaidWarningModal(
                                                                                true
                                                                            )
                                                                        }
                                                                        label="Mark Unpaid"></Button>
                                                                )}
                                                            {_userData.user_login !==
                                                                'customer' && (
                                                                <Button
                                                                    bgtype={'discard'}
                                                                    ifClicked={() => {
                                                                        setMarkPaidModel(true);
                                                                    }}
                                                                    label="Take Other Payment"></Button>
                                                            )}
                                                        </FormButtons>
                                                        <FullWidthButtonContainer>
                                                            <Button
                                                                width="100% !important"
                                                                bgtype={'secondary'}
                                                                disabled={loading}
                                                                ifClicked={onSubmit}
                                                                label="Save Changes"></Button>
                                                        </FullWidthButtonContainer>
                                                    </FormFooter>
                                                </FormDetails>
                                            </FormContent>
                                        </DrawerBody>
                                    </AppointmentContent>
                                )}
                            {chargeModel === true && (
                                <CompletePayment
                                    newAppointmentModel={false}
                                    onClose={() => setChargeModel(false)}
                                    onSubmit={() => {
                                        setActive(false);
                                    }}
                                    selectedService={selectedService}
                                    selectedProfessional={selectedServiceProvider}
                                    customerDetails={selectedCustomer}
                                    appointment={appointment}
                                />
                            )}
                            {markPaidModel &&
                                (appointment?.payment === null || transaction !== null) && (
                                    <MarkPaid
                                        onClose={() => setMarkPaidModel(false)}
                                        onSubmit={() => setActive(false)}
                                        transaction={transaction}
                                        appointment={appointment}
                                    />
                                )}
                            {customerView && (
                                <SelectClient
                                    onClose={() => {
                                        setCustomerView(false);
                                    }}
                                    onSelect={(customer: ICustomer) => {
                                        setSelectedCustomer(customer);
                                        updateEvent({ customer });
                                    }}
                                />
                            )}

                            {serviceProviderView && (
                                <SelectServiceProvider
                                    onClose={() => {
                                        setServiceProviderView(false);
                                    }}
                                    onSelect={(serviceProvider: IServiceProvider) => {
                                        // if the service provider has changed, hide Charge button,
                                        // forcing user to "Save Changes" before hitting Charge button
                                        if (serviceProvider._id != selectedServiceProvider?._id) {
                                            setHideChargeButton(true);
                                        }
                                        setSelectedServiceProvider(serviceProvider);
                                        setProviderId(serviceProvider._id);
                                    }}
                                />
                            )}
                            {serviceView && (
                                <SelectService
                                    id={providerId}
                                    onClose={() => {
                                        setServiceView(false);
                                    }}
                                    selectedService={selectedService}
                                    onSelect={(service: any) => {
                                        const data = {
                                            price: service.price,
                                            _id: service.service_id,
                                            my_price: service.my_price,
                                            my_duration: service.my_duration,
                                            duration: service.duration,
                                            name: service.name,
                                            color: service.color,
                                            available_for_boooking: service.available_for_boooking,
                                        };
                                        setSelectedService(data);
                                        setServiceDurationHours(
                                            service.my_duration >= 60
                                                ? Math.floor(service.my_duration / 60)
                                                : ''
                                        );
                                        setDurationHours(
                                            service.my_duration >= 60
                                                ? Math.floor(service.my_duration / 60)
                                                : ''
                                        );
                                        setDurationMinutes(service.my_duration % 60);
                                        setServiceDurationMinutes(service.my_duration % 60);
                                        updateEvent({ service });
                                    }}
                                />
                            )}

                            {showRecurringModal && appointment?.recurring && (
                                // <EditRecurringModal
                                //     recurring={recurringId}
                                //     onDelete={() => {
                                //         setShowRecurringModal(false);
                                //         setActive(false);
                                //     }}
                                //     onClose={() => {
                                //         setShowRecurringModal(false);
                                //     }}
                                // />
                                <DeleteRecurringModal
                                    deleteOne={appointment !== undefined && appointment._id}
                                    deleteAll={recurringId && recurringId.recurring_id}
                                    futureDelete={appointment !== undefined && appointment._id}
                                    onClose={() => setShowRecurringModal(false)}
                                    getAppointments={getAppointments}
                                    onDelete={() => {
                                        setShowRecurringModal(false);
                                        //onDelete();
                                    }}
                                />
                            )}
                            {showAppointmentModal && appointment && (
                                <DeleteModel
                                    id={id}
                                    onDelete={() => {
                                        setShowAppointmentModal(false);
                                    }}
                                    onClose={() => {
                                        setShowAppointmentModal(false);
                                    }}
                                    name="Appointment"
                                    content=" Are you sure you want to delete this appointment?"
                                    loading={loading}
                                    onCLick={() => singleAppointmentDelete()}
                                />
                            )}
                            {showConflictModal && (
                                <ConflictModal
                                    dates={conflictDates}
                                    onContinue={() => {
                                        setShowConflictModal(false);
                                        setOverbooking(true);
                                    }}
                                    onClose={() => setShowConflictModal(false)}
                                />
                            )}
                        </Drawer>
                    </Col>
                </Row>
                {(customerEditModel === true || customerModel) && (
                    <CustomerModal
                        onSave={(data: any) => {
                            setSelectedCustomer(data);
                            setCustomerModel(false);
                            setCustomerEditModel(false);
                        }}
                        onEdit={selectedCustomer}
                        editModel={customerEditModel}
                        title={customerEditModel === true ? 'Edit Customer' : 'Add New Customer'}
                        onClose={() => {
                            setCustomerModel(false);
                            setCustomerEditModel(false);
                        }}
                    />
                )}
            </Container>
            {showServiceProviderWarningModal && (
                <WarningModal
                    message={
                        'If you proceed with changing the service provider all customer payment information will be removed and the appointment will no longer have appointment protection.'
                    }
                    onContinue={() => {
                        setShowServiceProviderWarningModal(false);
                        setServiceProviderView(true);
                    }}
                    onClose={() => {
                        setShowServiceProviderWarningModal(false);
                    }}></WarningModal>
            )}
            {showMarkUnpaidWarningModal && (
                <WarningModal
                    message={'Are you sure you want to mark this appointment unpaid?'}
                    onContinue={() => {
                        setShowMarkUnpaidWarningModal(false);
                        markUnpaid();
                    }}
                    onClose={() => {
                        setShowMarkUnpaidWarningModal(false);
                    }}></WarningModal>
            )}
        </>
    );
};

const AppointmentContent = styled(Col)`
    height: 100%;
    display: flex;
    flex-direction: column;
`;
const HeaderContainer = styled.div`
    display: flex;
    padding: 1rem 1.75rem;
    min-height: 70px;
    align-items: center;
`;
const CloseButton = styled.div`
    margin-right: 1.5rem;
    cursor: pointer;
`;

const Icon = styled.i`
    font-size: 2.25rem;
    line-height: 2.5rem;
`;
const Header = styled.div`
    font-size: 1.25rem;
    font-weight: 800;
`;

const DrawerBody = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    padding-top: 0.5rem /* 8px */;
    padding: 0 1.75rem;
    overflow: auto;
`;
const AppointmentCustomer = styled.div`
    display: flex;
    align-items: center;
    box-shadow: none;
    background: #fff;
    padding: 1rem 16px;
    border-radius: 0.5rem;
    width: 100%;
    border: 1px solid rgba(223, 226, 230);
    cursor: pointer;
    justify-content: space-between;
`;
const ServiceProviderContent = styled.div`
    display: flex;
    align-items: center;
    box-shadow: none;
    background: #fff;
    padding: 1rem 16px;
    border-radius: 0.5rem;
    width: 100%;
    border: 1px solid rgba(223, 226, 230);
    cursor: pointer;
    margin-top: 0.5rem;
    justify-content: space-between;
`;
const FormContent = styled.div`
    flex: 1 1 0%;
`;
const FormDetails = styled.div`
    padding-top: 2rem;
    height: calc(100% - 84px);
    // overflow: scroll;
`;
const DatesBody = styled.div`
    justify-content: space-between !important;
    display: -webkit-box !important;
    margin-bottom: 0.5rem /* 24px */;
`;
const FormDate = styled.div`
    font-weight: 500;
    font-size: 1.35rem;
    display: block;
    justify-content: center;
    align-items: center;
    padding-top: 0.5rem;
    input {
        border: none;
        box-sizing: border-box;
        outline: 0;
        position: relative;
        top: -1.8rem;
        width: 8rem;
        opacity: 0;
        cursor: pointer;
    }
    input[type='date']::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }
`;
const CustomButton = styled.div``;
const RepeateButton = styled.button`
    padding: 0.92rem 1rem;
    font-weight: 600;
    text-transform: capitalize;
    background: #27292e;
    border-radius: 0.57rem;
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
    background-color: rgba(255, 255, 255);
    color: rgba(255, 255, 255);
    border-width: 1px;
    border-color: rgba(73, 196, 150);
    background-color: rgba(73, 196, 150);
    border: none;
    cursor: pointer;
`;
const RepeateIcon = styled.i`
    margin-left: 0.5rem /* 8px */;
`;

const SelectServiceContent = styled.div`
    border-radius: 0.5rem /* 8px */;
    padding: 12px 12px 12px 20px;
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    position: relative;
    border: 1px solid rgba(223, 226, 230);
    cursor: pointer;
`;
const ServiceHeader = styled.div`
    color: rgba(125, 128, 131);
`;
const InputGroup = styled.div`
    margin-bottom: 1rem;
`;
const InputContent = styled(Row)`
    margin-bottom: 1rem;
`;
const ServiceBorder = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-left-width: 4px;
    border-radius: 8px 0 0 8px;
`;
const ServiceDetails = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const NameBody = styled.div`
    display: flex;
    flex-direction: column;
`;
const ServiceName = styled.div`
    font-weight: 500;
    font-size: 1.1rem;
`;
const ServiceDuration = styled.div`
    font-size: 0.925rem;
    color: rgba(157, 160, 164);
`;
const PriceBody = styled.div`
    display: flex;
    align-items: center;
`;
const ServicePrice = styled.div`
    font-weight: 500;
    font-size: 1.1rem;
    margin-right: 18px;
`;
const SelectServiceBody = styled.div`
    border-radius: 0.5rem /* 8px */;
    padding-top: 1.5rem /* 24px */;
    padding-bottom: 1.5rem /* 24px */;
    padding-left: 1rem /* 16px */;
    padding-right: 1rem /* 16px */;
    margin-bottom: 2rem /* 32px */;
    align-items: center;
    display: flex;
    border: 1px solid rgba(223, 226, 230);
    cursor: pointer;
    justify-content: space-between;
`;
const Circle = styled.div`
    display: inline-block;
    flex-shrink: 0;
    position: relative;
    border-radius: 0.42rem;
`;
const Label = styled.div`
    margin-left: 1rem /* 16px */;
    margin-right: 1rem /* 16px */;
    flex: 1 1 0%;
`;
const Icons = styled.i`
    font-size: 1.125rem /* 18px */;
    line-height: 1.75rem /* 28px */;
`;
const NameCircle = styled.img`
    background: linear-gradient(-135deg, #eee, #dddfe1);
    color: #7e8299;
    width: 3.14rem;
    height: 3.14rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 0.42rem;
    font-size: 1.21rem;
    text-transform: capitalize !important;
    border-radius: 50%;
`;
const PlatformDiv = styled.div`
    display: flex;
    justify-content: space-between;
`;
const TotalPriceBody = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 0.5rem /* 16px */;
`;
const PlatForm = styled.div`
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
    text-transform: capitalize;
    color: #000;
    text-align: left;
    font-weight: 600;
`;
const PlatFormText = styled.div`
    font-size: 1rem /* 24px */;
    line-height: 2rem /* 32px */;
    font-weight: 400;
    text-align: left;
    text-transform: capitalize;
`;
const CreatedDateText = styled.div`
    font-size: 0.8rem;
`;

const Charge = styled.button`
    font-size: 1.3rem /* 24px */;
    line-height: 2rem /* 32px */;
    font-weight: 600;
    text-align: left;
    border: none;
    cursor: pointer;
    border-radius: 5px;
`;
const PriceHeader = styled.div`
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
    text-transform: capitalize;
    color: #000;
    text-align: right;
    font-weight: 600;
`;
const TotalPrice = styled.div`
    font-size: 1rem /* 24px */;
    line-height: 2rem /* 32px */;
    font-weight: 400;
    text-align: right;
`;
const FormFooter = styled.div`
    padding: 0.5rem 0rem;
    background: #fff;
`;

const FormButtons = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const FullWidthButtonContainer = styled.div`
    padding: 0.5rem 0rem;
`;

const CustomerContent = styled.div`
    margin-left: 1rem /* 16px */;
    margin-right: 1rem /* 16px */;
    flex: 1 1 0%;
    flex-direction: column;
    width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;
const Name = styled.div`
    font-weight: 500;
`;
const Email = styled.div`
    color: rgba(157, 160, 164);
    font-size: 0.9rem;
`;

const ToggleButton = styled.div`
    display: flex;
    justify-content: flex-end;
    flex: 1 1 0%;
`;
const ToggleDurationButton = styled.div`
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const CustomSpan = styled.span`
    font-size: 0.8rem;
`;
const StatusButton = styled.button`
    padding: 0.92rem 1.85rem;
    font-weight: 600;
    text-transform: capitalize;
    border-radius: 0.57rem;
    font-size: 0.92rem;
    padding: 0.71rem 1.57rem;
    border: none;
    cursor: pointer;
`;

const DownIcon = styled.i`
    margin-left: 0.5rem /* 8px */;
    font-size: 1.125rem;
    line-height: 1.75rem;
`;
const Status = styled.div`
    border-radius: 0.25rem;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 700;
`;
const CalenderDate = styled.div`
    cursor: pointer;
`;
const SelectCustomer = styled.div`
    display: flex;
    align-items: center;
    box-shadow: none;
    background: #fff;
    padding: 0.2rem 16px;
    border-radius: 0.5rem;
    width: 100%;
    border: 1px solid rgba(223, 226, 230);
    cursor: pointer;
    margin-top: 0.5rem;
    justify-content: space-between;
`;
const IconsDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: -0.7rem;
`;
export default EditAppointmentDrawer;
