import React, { useRef, useState } from 'react';
import styled from 'styled-components';

export interface CheckboxProps {
    name: string;
    checked?: boolean;
    label?: string | React.ReactNode;
    className?: string;
    readOnly?: boolean;
    error?: {
        message: string;
    };
    onChange?: any;
}

const Checkbox = ({
    name,
    checked,
    label,
    className,
    readOnly,
    error,
    onChange,
}: CheckboxProps) => {
    const [isChecked, setIsChecked] = useState<boolean>(checked ?? false);
    const inputRef = useRef<HTMLInputElement>(null);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(event.target.checked);
        if (onChange) onChange(event.target.checked);
    };
    return (
        <Wrapper>
            <InlineInputGroup>
                <InputField
                    ref={inputRef}
                    id={name}
                    name={name}
                    type="checkbox"
                    checked={isChecked}
                    onChange={event => handleChange(event)}
                    className={className}
                    readOnly={readOnly}
                />
                <label style={{ padding: '0 1rem' }}>{label}</label>
            </InlineInputGroup>
            {error && <Message>{error.message}</Message>}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    margin-bottom: 1rem;
    max-width: 400px;
`;
const InlineInputGroup = styled.div`
    display: flex;
    justify-content: start;
`;
const InputField = styled.input`
    width: 25px;
    display: block;
    background-color: transparent;
    border: none;
    min-width: 25px;
    color: #263446;
    line-height: 24px;
    &::placeholder {
        font-weight: 300;
        color: #8c8b88;
    }
    &:focus {
        outline: 0;
    }
`;
const Message = styled.div`
    color: #fff;
    background-color: #eb5353;
    border: 1px solid #eb5353;
    border-radius: 0px 0px 0.325rem 0.325rem;
    padding: 2px 10px;
    margin-top: -3px;
    font-size: 0.75rem;
    font-weight: 300;
`;

export default Checkbox;
