import useRouter from 'hooks/router';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'hooks/redux';
import CustomerAppointments from './CustomerAppointments';
import { LoaderOverlay, Tab, TabPanel, Tabs, useSnackbar } from '@components/common';
import CustomerPayments from './CustomerPayments';
import CustomerNotifications from './CustomerNotifications';
import CustomerNotes from './CustomerNotes';
import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import TabContext from '@components/common/TabContext';
import Card from '@components/common/Cards/Card';
import DeleteModel from '@components/common/DeleteModel/DeleteModel';
import { palette } from 'styled/common';
import Accordion from '@components/Accordion';
import { api } from 'helpers/auth-axios';
import { RoleEnum } from 'constants/enums';

const CustomerCard = ({ callCustomer }: any) => {
    const [customer, setCustomer] = useState<any>();
    const [tabValue, setTabValue] = useState(0);
    const [openDeleteModel, setOpenDeleteModel] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleteId, setDeleteId] = useState<any>();
    const [revenue, setTotalRevenue] = useState<any>();
    const [tip, setTip] = useState<any>();
    const [totalAppointment, setTotalAppointment] = useState<any>();
    const router = useRouter();
    const [openSnackbar] = useSnackbar();
    const dispatch = useAppDispatch();
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    useEffect(() => {
        setLoading(true);
        api.get(`/customers/${router.query.id as string}`)
            .then((customer: any) => {
                setCustomer(customer.data.customer);
                setLoading(false);
                setTotalRevenue(customer.data.totalRevenue);
                setTip(customer.data.totalTip);
                setTotalAppointment(customer.data.appointments);
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    }, [dispatch, router.query]);

    const deleteModel = (id: any) => {
        setOpenDeleteModel(true);
        setDeleteId(id);
    };
    useEffect(() => {
        api.get(`/payments?page=${0}&pageSize=${20}&customer_id=${router.query.id}`).then(
            (res: any) => {
                setTotalRevenue(
                    res.data.payments.reduce((a: any, b: any) => a + parseInt(b.amount_value), 0)
                );
                setTip(res.data.payments.reduce((a: any, b: any) => a + parseInt(b.tip), 0));
            }
        );
    }, [router.query]);
    const deleteCustomer = (id: any) => {
        setLoading(true);
        api.delete(`/customers/${id}`)
            .then(() => {
                router.navigate('/customers');
                openSnackbar('Customer deleted successfully');
                setOpenDeleteModel(false);
                setLoading(false);
                callCustomer();
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });

        setOpenDeleteModel(false);
    };
    const tabsNameList = [
        {
            name: 'Profile',
            content: (
                <Card
                    link={`/customers/${router.query.id}/edit`}
                    model={() => deleteModel(router.query.id)}
                    nameData={
                        (customer?.firstname ? customer.firstname.charAt(0) : '') +
                        (customer?.lastname ? customer.lastname.charAt(0) : '')
                    }
                    totalAppointments={totalAppointment}
                    name={customer?.firstname + ' ' + customer?.lastname}
                    phone={customer?.phone}
                    email={customer?.email}
                    button={true}
                    appointment={true}
                    revenue={_userData?.user_login === 'customer' ? false : true}
                    totalRevenue={revenue}
                    totalTip={tip}
                    tip={_userData?.user_login === 'customer' ? false : true}
                    profileImage={customer?.photo}
                    accountRole={RoleEnum.CUSTOMER}
                />
            ),
        },
        {
            name: 'Appointments',
            content: <CustomerAppointments customerId={customer?._id} />,
        },
        {
            name: 'Notes',
            content: <CustomerNotes customer={customer} />,
        },
        {
            name: 'Notifications',
            content: <CustomerNotifications customer={customer} />,
        },
    ];
    const tabsList = [
        {
            name: 'Profile',
            content: (
                <Card
                    link={`/customers/${router.query.id}/edit`}
                    model={() => deleteModel(router.query.id)}
                    nameData={
                        (customer?.firstname ? customer.firstname.charAt(0) : '') +
                        (customer?.lastname ? customer.lastname.charAt(0) : '')
                    }
                    totalAppointments={totalAppointment}
                    name={customer?.firstname + ' ' + customer?.lastname}
                    phone={customer?.phone}
                    email={customer?.email}
                    button={true}
                    appointment={true}
                    revenue={_userData?.user_login === 'customer' ? false : true}
                    totalRevenue={revenue}
                    totalTip={tip}
                    tip={_userData?.user_login === 'customer' ? false : true}
                    profileImage={customer?.photo}
                    accountRole={RoleEnum.CUSTOMER}
                />
            ),
        },
        {
            name: 'Appointments',
            content: <CustomerAppointments customerId={customer?._id} />,
        },
        {
            name: 'Notifications',
            content: <CustomerNotifications customer={customer} />,
        },
    ];
    return (
        <>
            {loading && <LoaderOverlay />}
            <MainContainer>
                <Row>
                    {customer && (
                        <>
                            <WebView lg={12}>{tabsNameList[0].content}</WebView>
                            <WebView lg={12}>
                                <TabsCard>
                                    {_userData?.user_login !== 'customer' ? (
                                        <TabContext value={tabValue}>
                                            <Tabs
                                                onChange={(event, newValue) => {
                                                    setTabValue(newValue);
                                                }}>
                                                <Tab label={tabsNameList[1].name} />
                                                <Tab label={tabsNameList[2].name} />
                                                <Tab label={tabsNameList[3].name} />
                                            </Tabs>
                                            <TabPanel value={0} preload={true}>
                                                <TabContent>{tabsNameList[1].content}</TabContent>
                                            </TabPanel>
                                            <TabPanel value={1}>
                                                <TabContent>{tabsNameList[2].content}</TabContent>
                                            </TabPanel>
                                            <TabPanel value={2} preload={true}>
                                                <TabContent>{tabsNameList[3].content}</TabContent>
                                            </TabPanel>
                                        </TabContext>
                                    ) : (
                                        <TabContext value={tabValue}>
                                            <Tabs
                                                onChange={(event, newValue) => {
                                                    setTabValue(newValue);
                                                }}>
                                                <Tab label={tabsList[1].name} />
                                                <Tab label={tabsList[2].name} />
                                            </Tabs>
                                            <TabPanel value={0} preload={true}>
                                                <TabContent>{tabsList[1].content}</TabContent>
                                            </TabPanel>
                                            <TabPanel value={1}>
                                                <TabContent>{tabsList[2].content}</TabContent>
                                            </TabPanel>
                                        </TabContext>
                                    )}
                                </TabsCard>
                            </WebView>
                            <MobileView lg={12}>
                                <Accordion
                                    tabsNameList={
                                        _userData?.user_login !== 'customer'
                                            ? tabsNameList
                                            : tabsList
                                    }
                                />
                            </MobileView>
                        </>
                    )}
                    {openDeleteModel && customer && (
                        <DeleteModel
                            id={customer._id}
                            onDelete={() => {
                                setOpenDeleteModel(false);
                            }}
                            onClose={() => {
                                setOpenDeleteModel(false);
                            }}
                            name="Customer"
                            content=" If you delete this client you will lose all information connected to
                                    their profile including past appointments, payment history, and more.
                                    This action cannot be undone."
                            loading={loading}
                            onCLick={() => deleteCustomer(deleteId)}
                        />
                    )}
                </Row>
            </MainContainer>
        </>
    );
};

const MainContainer = styled(Container)`
    height: 100vh;
    overflow-y: scroll;
    padding: 1.71rem;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    ${media.xs`
	padding: 1.1rem;
    overflow-x: hidden;
    overflow-y: auto;
	`}
    ${media.sm`
    padding: 0rem;
    padding-top: 1rem;
    overflow-x: hidden;
    overflow-y: auto;
	`}
	${media.md`
	  padding: 1.71rem;
	`}
	${media.lg`
	  padding: 1.71rem;
	`}
`;

const WebView = styled(Col)`
    display: block;
    @media screen and (min-width: 1px) and (max-width: 900px) {
        display: none;
    }
`;
const MobileView = styled(Col)`
    display: none;
    @media screen and (min-width: 1px) and (max-width: 900px) {
        display: block;
    }
`;

const TabsCard = styled.div`
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: ${palette.light};
    background-clip: border-box;
    border-radius: 0.42rem;
    box-shadow: 0rem 0rem 2.2rem 0rem ${palette.grey.shadow};
    border: 0;
`;

const TabContent = styled.div`
    padding: 1.3rem;
    flex: 1;
`;
export default CustomerCard;
