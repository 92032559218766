import styled from 'styled-components';
import { useState } from 'react';
import leftChevron from '../../../images/icons/chevron-left.svg';
import { Input } from '@components/common';

const CustomerDetails = ({
    onPrevious,
    onNext,
    buttonTitle,
    customer_note,
    errorMessage,
}: {
    onPrevious(): void;
    onNext(customer_details: any): void;
    buttonTitle: any;
    customer_note(val: any): void;
    errorMessage?: any;
}) => {
    const [firstName, setFirstName] = useState<any>();
    const [lastName, setLastName] = useState<any>();
    const [email, setEmail] = useState<any>();
    const [phoneNumber, setPhoneNumber] = useState<any>();

    let reg =
        /^([\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+\.)*[\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+@((((([a-z0-9]{1}[a-z0-9\-]{0,62}[a-z0-9]{1})|[a-z])\.)+[a-z]{2,6})|(\d{1,3}\.){3}\d{1,3}(\:\d{1,5})?)$/i;
    const onSubmit = () => {
        if (reg.test(email) === false) {
            return null;
        } else {
            onNext({
                firstname: firstName,
                lastname: lastName,
                email: email,
                phone: phoneNumber,
            });
        }
    };
    const phoneValidate = (value: any) => {
        let phone = value;
        if (phone.length <= 3) {
            phone = phone.replace(/[-.]/g, '');
        } else if (phone.length <= 7) {
            phone = phone.replace(/[-.]/g, '');
            phone = phone.slice(0, 3) + '-' + phone.slice(3, 6);
        } else if (phone.length >= 7) {
            phone = phone.replace(/[-.]/g, '');
            phone = phone.slice(0, 3) + '-' + phone.slice(3, 6) + '-' + phone.slice(6);
        }
        setPhoneNumber(phone);
    };
    return (
        <div className="booking-wrapper">
            <div className="boking-widget--header">
                <div className="booking-widget--backBtn" onClick={onPrevious}>
                    <img src={leftChevron} alt="" />
                </div>
                <h3>Your Details</h3>
            </div>
            <div className="booking-widget--body">
                <div className="booking-widget--body-detail" style={{ paddingTop: '8px' }}>
                    <p
                        style={{
                            paddingLeft: '4px',
                            paddingBottom: '10px',
                            fontSize: '14px',
                            color: '#5e5e5e',
                        }}>
                        By entering your information below, you agree to receive communication by
                        email and SMS regarding updates.
                    </p>
                    {errorMessage && (
                        <ErrorMessageContainer className="card_error">
                            {' '}
                            <span className="error-text">
                                {errorMessage === 'Appointment Time Not Available' ? (
                                    <>
                                        <b>{errorMessage}: </b>
                                        {
                                            'Due to high demand of our services, the appointment time you have selected is no longer available. Please select a  new time and try again.'
                                        }
                                    </>
                                ) : (
                                    <>{errorMessage}</>
                                )}
                            </span>
                        </ErrorMessageContainer>
                    )}
                    <div className="userDetails-grid userDetails-group">
                        <Input
                            label="First Name"
                            name="firstName"
                            allowNumber={false}
                            onChange={(e: any) => {
                                setFirstName(e.value);
                            }}
                        />
                        <Input
                            label="Last Name"
                            name="lastName"
                            allowNumber={false}
                            onChange={(e: any) => {
                                setLastName(e.value);
                            }}
                        />
                    </div>
                    <div className="userDetails-group">
                        <Input
                            required={true}
                            label="Email"
                            name="email"
                            type="email"
                            value={email}
                            onChange={(e: any) => {
                                setEmail(e.value?.toLowerCase());
                            }}
                        />
                        {email && reg.test(email) === false ? (
                            <div className="validationField">
                                {' '}
                                <span className="error-text">
                                    A valid email is required for updates.
                                </span>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="userDetails-group">
                        <Input
                            allowPhoneNumberOnly={true}
                            maxLength={12}
                            label="Phone"
                            name="phoneNumber"
                            value={phoneNumber}
                            type="tel"
                            inputMode="numeric"
                            onChange={(e: any) => {
                                phoneValidate(e.value);
                            }}
                        />
                    </div>

                    <div className="userDetails-group">
                        <Input
                            label="Appointments Notes (Ex: Haircut for my son Joey)"
                            onChange={(e: any) => customer_note(e.value)}
                        />
                    </div>
                </div>
            </div>
            {firstName && lastName && reg.test(email) === true && phoneNumber ? (
                <div className="personaldetail--footer" style={{ position: 'fixed' }}>
                    <button className="btn" onClick={() => onSubmit()}>
                        {buttonTitle}
                    </button>
                </div>
            ) : (
                <div className="personaldetail--footer-disabled" style={{ position: 'fixed' }}>
                    <button className="btn">{buttonTitle}</button>
                </div>
            )}
        </div>
    );
};

const ErrorMessageContainer = styled.div`
    margin-bottom: 0.5rem;
`;

export default CustomerDetails;
