import React, { useCallback, useRef, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import useRouter from 'hooks/router';

import _ from 'lodash';
import { api } from 'helpers/auth-axios';
import { LoaderOverlay, WarningModal, useSnackbar } from '@components/common';
import { ISquareCard } from 'interfaces/square.interface';
import { palette } from 'styled/common';

const SquareCardOnFileList = ({
    customerId,
    serviceProviderId,
    squareCardId,
    onSelect,
    onAddCardButtonClick,
}: {
    customerId: string;
    serviceProviderId: string;
    squareCardId?: string;
    onSelect(card: ISquareCard): void;
    onAddCardButtonClick(): void;
}) => {
    const navigate = useNavigate();
    const router = useRouter();
    const [loading, setLoading] = useState(false);
    const [cardList, setCardList] = useState<ISquareCard[]>([]);
    const [deleteCardMode, setDeleteCardMode] = useState<boolean>(false);
    const [showDeleteCardPrompt, setShowDeleteCardPrompt] = useState<boolean>(false);
    const [cardToDisable, setCardToDisable] = useState<string>('');
    const [openSnackbar] = useSnackbar();

    useEffect(() => {
        loadCards();
    }, []);

    const loadCards = () => {
        setLoading(true);
        api.get(
            `/payment-processor/square/get-cards?customerId=${customerId}&serviceProviderId=${serviceProviderId}`
        )
            .then((res: any) => {
                let cards: ISquareCard[] = [];
                res.data.forEach((card: any) => {
                    const squareCard: ISquareCard = {
                        id: card.id,
                        brand: card.cardBrand,
                        expMonth: parseInt(card.expMonth),
                        expYear: parseInt(card.expYear),
                        last4: card.last4,
                        billing: card.billingAddress.postalCode,
                    };
                    // if card is saved to the appointment, "select" it
                    if (card.id === squareCardId) {
                        onSelect(squareCard);
                    }
                    cards.push(squareCard);
                });
                setCardList(cards);
                setLoading(false);
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const disableCard = (cardId: string) => {
        setLoading(true);

        const requestBody = {
            customerId,
            serviceProviderId,
        };

        api.post(`/payment-processor/square/card/${cardId}/disable`, requestBody)
            .then((res: any) => {
                setLoading(false);
                loadCards();
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const getCardBrandIconClass = (brand: string | undefined) => {
        if (brand === 'VISA') {
            return 'fa-cc-visa';
        } else if (brand === 'MASTERCARD') {
            return 'fa-cc-mastercard';
        } else if (brand === 'DISCOVER') {
            return 'fa-cc-discover';
        } else if (brand === 'AMERICAN_EXPRESS') {
            return 'fa-cc-amex';
        } else {
            return 'fa-credit-card';
        }
    };

    return (
        <>
            <Wrapper>
                {loading && <LoaderOverlay />}
                <CustomRow>
                    <CustomCol md={12} lg={12}>
                        <HeadingWrapper>
                            <Header>Select a Card</Header>
                        </HeadingWrapper>
                        <OnSamllViewPort hasId={router.query['*']}>
                            <CardList>
                                {cardList?.length ? (
                                    cardList.map((card: ISquareCard, index: any) => {
                                        return (
                                            <Container>
                                                <Row>
                                                    <Col
                                                        style={{
                                                            padding: '0',
                                                        }}
                                                        lg={12}>
                                                        <CardsListItem>
                                                            <InfoContainer
                                                                onClick={() => {
                                                                    if (!deleteCardMode) {
                                                                        onSelect(card);
                                                                    }
                                                                }}>
                                                                <Name>
                                                                    <i
                                                                        className={`fab ${getCardBrandIconClass(
                                                                            card.brand
                                                                        )}`}></i>{' '}
                                                                    (***{card.last4})
                                                                </Name>
                                                            </InfoContainer>
                                                            {deleteCardMode && (
                                                                <DeleteButton
                                                                    onClick={() => {
                                                                        setCardToDisable(card.id);
                                                                        setShowDeleteCardPrompt(
                                                                            true
                                                                        );
                                                                    }}>
                                                                    <i className="fal fa-trash-alt"></i>
                                                                </DeleteButton>
                                                            )}
                                                        </CardsListItem>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        );
                                    })
                                ) : loading ? (
                                    <NotFound>Loading cards...</NotFound>
                                ) : (
                                    <NotFound>No cards found</NotFound>
                                )}
                            </CardList>
                            <Row>
                                {' '}
                                {cardList?.length ? (
                                    <>
                                        <Col md={7}>
                                            {!deleteCardMode ? (
                                                <AddCardButton onClick={onAddCardButtonClick}>
                                                    Add a Card
                                                </AddCardButton>
                                            ) : (
                                                <AddCardButton className="btn" disabled={true}>
                                                    Add a Card
                                                </AddCardButton>
                                            )}
                                        </Col>
                                        <Col md={5}>
                                            {deleteCardMode ? (
                                                <ExitDeleteCardModeButton
                                                    onClick={() => setDeleteCardMode(false)}>
                                                    Select a Card
                                                </ExitDeleteCardModeButton>
                                            ) : (
                                                <EnterDeleteCardModeButton
                                                    onClick={() => setDeleteCardMode(true)}>
                                                    Delete a Card
                                                </EnterDeleteCardModeButton>
                                            )}
                                        </Col>
                                    </>
                                ) : (
                                    <Col>
                                        <AddCardButton onClick={onAddCardButtonClick}>
                                            Add a Card
                                        </AddCardButton>
                                    </Col>
                                )}
                            </Row>
                        </OnSamllViewPort>
                    </CustomCol>
                </CustomRow>
                {showDeleteCardPrompt && (
                    <WarningModal
                        message={'Are you sure you want to disable this card?'}
                        onContinue={() => {
                            setShowDeleteCardPrompt(false);
                            disableCard(cardToDisable);
                            setCardToDisable('');
                        }}
                        onClose={() => {
                            setShowDeleteCardPrompt(false);
                            setCardToDisable('');
                        }}></WarningModal>
                )}
            </Wrapper>
        </>
    );
};
const Wrapper = styled(Container)`
    flex: 0 0 25rem;
    margin-left: 0rem;

    ${media.xs`
    padding-top: 1rem;
    padding: auto;
    flex: 0 0 25rem;
    `}
    ${media.sm`
    margin-top: 1rem;
    flex: 0 0 25rem;
     background-color: white;
    `}
	${media.md`
    margin-top: 0rem;
    padding: 0;
    background-color: white;
     flex: 0 0 20rem;
    `}
	@media screen and (min-width: 1076px) and (max-width: 1140px) {
        flex: 0 0 20rem;
    }
    @media screen and (min-width: 1032px) and (max-width: 1075px) {
        flex: 0 0 18rem;
    }
    @media screen and (min-width: 992px) and (max-width: 1031px) {
        flex: 0 0 16rem;
    }

    ${media.lg`
    margin-top: 0rem;
    padding: 0;
    background-color: white;
    `}
    ${media.xl`
    margin-top: 0rem;
    padding: 0;
    background-color: white;
`}
`;
const CustomRow = styled(Row)`
    position: relative;
    margin: 0;
    ${media.xs`
    background-color: white;
`}
`;
const CustomCol = styled(Col)`
    padding: 0;
`;
const Header = styled.h1`
    font-size: 1.78rem;
    font-weight: 600;
`;
const HeadingWrapper = styled.div`
    padding: 1.42rem 0rem 0.85rem 1.71rem;
    display: flex;
    align-items: center;
    justify-content: start;
`;
const AddCardButton = styled.button`
    width: 100%;
    padding: 0.75rem 1rem;
    font-weight: 400;
    display: inline-block;
    border: none;
    border-radius: 0.57rem;
    box-shadow: none;
    cursor: pointer;
    white-space: nowrap;
    transition: all 0.25s ease 0s;
    color: #fff;
    background-color: #0154ff;
`;
const EnterDeleteCardModeButton = styled.button`
    width: 100%;
    padding: 0.75rem 1rem;
    font-weight: 400;
    display: inline-block;
    border: none;
    border-radius: 0.57rem;
    box-shadow: none;
    cursor: pointer;
    white-space: nowrap;
    transition: all 0.25s ease 0s;
    color: #fff;
    background-color: ${palette.primary};
    &:hover {
        background-color: ${palette.primaryHover};
    }
`;
const ExitDeleteCardModeButton = styled.button`
    width: 100%;
    padding: 0.75rem 1rem;
    font-weight: 400;
    display: inline-block;
    border: none;
    border-radius: 0.57rem;
    box-shadow: none;
    cursor: pointer;
    white-space: nowrap;
    transition: all 0.25s ease 0s;
    color: #fff;
    background-color: #0154ff;
    &:hover {
        background-color: #02369e;
    }
`;
const AddIcon = styled(Link)`
    position: fixed;
    bottom: 1.71rem;
    // right: 2.14rem;
    width: 3.42rem;
    height: 3.42rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1rem 1.71rem 0 rgb(0 0 0 / 28%);
    background: #0154ff;
    border-radius: 100%;
    color: #fff;
    font-size: 1.5rem;
`;

const CardList = styled.div`
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: 8rem;
    overflow: auto;
`;

const CardsListItem = styled.div`
    padding: 0.5rem 1.71rem;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.1rem solid ${palette.grey.lightest};
    cursor: pointer;
    &:hover {
        background-color: ${palette.grey.lightest};
    }
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Name = styled.div`
    padding: 0 0.85rem;
    font-size: 1.8rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const DeleteButton = styled.button`
    padding: 0;
    min-width: 2.85rem;
    min-height: 2.85rem;
    border-width: 0rem;
    background-color: ${palette.primary};
    color: ${palette.light};
    font-weight: 600;
    border-radius: 0.51rem;
    font-size: 1.07rem;
    margin-right: 0.71rem;
    cursor: pointer;
    &:hover {
        background-color: ${palette.primaryHover};
    }
`;

interface IProps {
    hasId: any;
}
const OnSamllViewPort = styled.span<IProps>`
    display: block;
    ${media.xs`
	display: ${({ hasId }: any) => (hasId ? 'none' : 'block')};
	`}
    ${media.sm`
	display: ${({ hasId }: any) => (hasId ? 'none' : 'block')};
	`}
	${media.md`
    display: block;
	`}
	${media.lg`
    display: block;
	`}
`;

const NotFound = styled.div`
    display: flex;
    justify-content: center;
`;

export default SquareCardOnFileList;
