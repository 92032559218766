import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Col, Row, media } from 'styled-bootstrap-grid';
import { Input, Spinner, useSnackbar } from '@components/common';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks/redux';
import { userActions } from 'actions/user';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import useRouter from 'hooks/router';
import SideCover from '@components/common/SideCover/SideCover';
import { IUser } from 'interfaces/user.interface';
import { businessActions } from 'actions/business';

type FormValues = {
    name: string;
    business_name: string;
    email: string;
    password: string;
};

const Register = () => {
    const dispatch = useAppDispatch();
    const [openSnackbar] = useSnackbar();
    const router = useRouter();
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('This field is required'),
        business_name: Yup.string().required('This field is required'),
        email: Yup.string().required('This field is required'),
        password: Yup.string()
            .required('This field is required')
            .min(8, 'Password must be at least 8 characters'),
    });
    const [authMethod, setAuthMethod] = useState('email');
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, setValue, formState, trigger } = useForm<FormValues>(formOptions);
    const { errors, isSubmitting } = formState;
    const navigate = useNavigate();
    const onSubmit = handleSubmit((data: IUser) => {
        return dispatch(userActions.register(data))
            .then((res: any) => {
                if (res) {
                    dispatch(businessActions.getBusiness(res.user.business_id._id)).then(
                        (res: any) => {
                            let _userData = res;
                            openSnackbar('Account creation successful.');
                            navigate('/');
                        }
                    );
                } else {
                    openSnackbar('Account creation failed!');
                }
            })
            .catch((e: any) => {
                openSnackbar('Account creation failed!');
            });
    });

    useEffect(() => {
        if (router.query.success !== undefined) {
            if (router.query.provider) setAuthMethod(router.query.provider as string);
            if (router.query.accessToken) {
                dispatch(userActions.getCurrentUser(router.query.accessToken as string)).then(
                    (res: any) => {
                        navigate('/');
                    }
                );
            }
        }
    }, [dispatch, router.query]);
    async function onChange({ name, value }: { name: string; value: string }) {
        setValue(name as never, value as never);
        await trigger(name as never);
    }

    return (
        <>
            <Wrapper>
                <Row>
                    <SideCover />
                    <Col md={6} lg={5} style={{ padding: '0' }}>
                        <CardRight>
                            <Inner>
                                <Heading>Create Account</Heading>
                                <SignInText>
                                    Already have an account?
                                    <Link to="/login">
                                        <SignUpText className="text-primary-light">
                                            Log In
                                        </SignUpText>
                                    </Link>
                                </SignInText>
                            </Inner>
                            <form onSubmit={onSubmit}>
                                <InputGroup>
                                    <Input
                                        label="Name"
                                        name="name"
                                        onChange={onChange}
                                        error={errors.name as any}
                                    />
                                </InputGroup>
                                <InputGroup>
                                    <Input
                                        label="Business Name"
                                        name="business_name"
                                        onChange={onChange}
                                        error={errors.business_name as any}
                                    />
                                </InputGroup>
                                <InputGroup>
                                    <Input
                                        label="E-mail Address"
                                        name="email"
                                        type="email"
                                        onChange={onChange}
                                        error={errors.email as any}
                                        autoComplete="on"
                                    />
                                </InputGroup>
                                <InputGroup>
                                    <Input
                                        type="password"
                                        name="password"
                                        label="Password"
                                        onChange={onChange}
                                        error={errors.password as any}
                                    />
                                </InputGroup>
                                <Button type="submit">Create Account</Button>
                            </form>
                            <div>
                                <AgreeText>
                                    By clicking the Create Account you agree to the{' '}
                                    <a href="http://getexclusively.com/privacy" target="_blank">
                                        <Highlight>Privacy Policy</Highlight>
                                    </a>{' '}
                                    and{' '}
                                    <a href="http://getexclusively.com/terms" target="_blank">
                                        <Highlight>Terms and Conditions</Highlight>
                                    </a>
                                    .
                                </AgreeText>
                            </div>
                        </CardRight>
                    </Col>
                </Row>
            </Wrapper>
        </>
    );
};
const Wrapper = styled(Container)`
    paddind: 0;
    min-width: 100%;
    min-height: 100%;
    background-color: #fff;
`;
const CardLeft = styled.div`
    background-color: #4871fe;
    padding-top: 3.5rem !important;
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
`;
const CardRight = styled.div`
    padding-left: 4.75rem !important;
    padding-right: 4.75rem !important;
    background-color: #fff;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
const FlexCenter = styled.div`
    display: flex;
    justify-content: center;
`;
const Cover = styled.div`
    width: 11.42rem;
    height: 2.42rem;
`;
const BookedyaContent = styled.div`
    padding-top: 3.5rem !important;
`;
const Text = styled.h1`
    color: #000 !important;
    font-weight: 600 !important;
    font-size: 3rem !important;
    line-height: 1 !important;
    text-align: center !important;
`;
const Para = styled.p`
    color: rgba(255, 255, 255) !important;
    font-weight: 100 !important;
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
    text-align: center !important;
    padding: 1rem 7rem;
`;
const Inner = styled.div`
    margin-bottom: 3rem;
`;
const Heading = styled.h3`
    font-size: 2.6rem;
    font-weight: 600 !important;
    ${media.xs`
    margin-top:0;
	`}
    ${media.sm`
    margin-top:0;
	`}
	${media.md`
    margin-top:0;
	`}
`;
const SignInText = styled.span`
    display: flex;
    color: #000 !important;
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
`;
const SignUpText = styled.span`
    color: #0154ff !important;
    font-weight: 800;
    padding-left: 0.5rem;
    margin: 0;
`;
const InputGroup = styled.div`
    margin-bottom: 1.71rem;
`;
const Button = styled.button`
    color: rgba(255, 255, 255) !important;
    text-transform: capitalize !important;
    font-size: 1.25rem;
    font-weight: 800 !important;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
    background-color: #0154ff !important;
    border-radius: 0.5rem !important;
    width: 100% !important;
    display: inline-block !important;
    margin-top: 1rem !important;
    border: 0;
    cursor: pointer;
`;
const AgreeText = styled.div`
    font-size: 0.85rem;
    max-width: 340px;
    margin-right: auto;
    margin-left: auto;
    color: rgba(157, 160, 164) !important;
    font-weight: 100 !important;
    text-align: center !important;
    margin-top: 1rem !important;
`;
const Highlight = styled.span`
    color: rgba(125, 128, 131) !important;
    font-weight: 500 !important;
`;
export default Register;
