import { useEffect } from 'react';
import { useState } from 'react';
import s from './Checkout.module.scss';
import { paymentOptions } from 'constants/paymentOptions';
import { Col, Container, media, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { Input } from '@components/common';
const PaymentMethods = ({
    subtotal,
    gratuity,
    paymentMethod,
    onBack,
    onChange,
    customTip,
}: {
    subtotal: number;
    gratuity: { label: string; value: number };
    paymentMethod: { code: string; label: string };
    onBack(): void;
    onChange(
        method: { code: string; label: string },
        gratuity: { label: string; value: number }
    ): void;
    customTip(val: any): void;
}) => {
    const [selectedGratuity, setSelectedGratuity] = useState(gratuity);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(paymentMethod);
    const [customtip, setCustomTip] = useState<any>();
    const [onCustomClick, setOnCustomClick] = useState<boolean>(false);

    useEffect(() => {
        onChange(selectedPaymentMethod, selectedGratuity);
    }, [selectedGratuity, selectedPaymentMethod, onChange]);
    useEffect(() => {
        if (customTip !== undefined || null) {
            customtip !== null &&
                setSelectedGratuity({
                    label: 'custom tip',
                    value: customtip,
                });
        }
    }, [customtip]);
    const gratuityOptions = [
        {
            label: 'No Tip',
            value: 0,
        },
        {
            label: '20%',
            value: subtotal * 0.2,
        },
        {
            label: '25%',
            value: subtotal * 0.25,
        },
        {
            label: '30%',
            value: subtotal * 0.3,
        },
    ];
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    return (
        <>
            <CustomCol md={7} lg={8}>
                <TitileBody>
                    <TitleContent>
                        <Button onClick={onBack}>
                            <Icon className="fal fa-long-arrow-left"></Icon>
                        </Button>
                        <CheckoutTitle>Payment Method & Gratuity</CheckoutTitle>
                    </TitleContent>
                </TitileBody>
                <Cards>
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <Title>Gratuity</Title>
                            </Col>
                            {gratuityOptions.map((option: any) => {
                                return (
                                    <List xs={6} sm={4} md={4} lg={3} key={option.label}>
                                        <div
                                            className={`${s.gratuity_tip} ${
                                                selectedGratuity.value === option.value
                                                    ? s.gratuity_tipActive
                                                    : ''
                                            }`}
                                            onClick={() => {
                                                setSelectedGratuity({
                                                    label: option.label,
                                                    value: option.value,
                                                });
                                                setOnCustomClick(false);
                                                setCustomTip(null);
                                            }}>
                                            <div>
                                                {_userData.user.business_id.currency}
                                                {option.value.toFixed(2)}
                                            </div>
                                            <GratuatyLabel>{option.label}</GratuatyLabel>
                                        </div>
                                    </List>
                                );
                            })}
                            <InputField>
                                <Input
                                    label="Custom Tip"
                                    name="Custom Tip"
                                    value={customtip}
                                    type={'number'}
                                    // allowNumber={true}
                                    step="0.1"
                                    onChange={(e: any) => {
                                        setCustomTip(e.value);
                                        setOnCustomClick(true);
                                        customTip(e.value);
                                    }}
                                />
                            </InputField>
                        </Row>
                    </Container>
                </Cards>
                <Cards>
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <Title>Payment Method</Title>
                            </Col>
                            {paymentOptions.map(option => {
                                return (
                                    <List xs={6} sm={4} md={4} lg={4} key={option.label}>
                                        <div
                                            className={`${s.payment_method} ${
                                                selectedPaymentMethod.code === option.code
                                                    ? s.payment_methodActive
                                                    : ''
                                            }`}
                                            onClick={() =>
                                                setSelectedPaymentMethod({
                                                    code: option.code,
                                                    label: option.label,
                                                })
                                            }>
                                            <div>
                                                <i className={option.icon}></i>
                                            </div>
                                            <div>{option.label}</div>
                                        </div>
                                    </List>
                                );
                            })}
                        </Row>
                    </Container>
                </Cards>
            </CustomCol>
        </>
    );
};

const List = styled(Col)`
    padding: 0rem 0rem 0.5rem 0.5rem;
`;
const CustomCol = styled(Col)`
    padding: 24px;
`;
const TitileBody = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.75rem;
`;
const TitleContent = styled.div`
    display: flex;
    align-items: center;
`;

const Button = styled.div`
    margin-right: 1.5rem /* 24px */;
    border: none;
    cursor: pointer;
`;
const Icon = styled.i`
    font-size: 2.25rem /* 36px */;
    line-height: 2.5rem /* 40px */;
`;
const CheckoutTitle = styled.div`
    font-size: 1.78rem;
    font-weight: 600;
`;
const Cards = styled.div`
    margin-bottom: 3rem;
`;
const Title = styled.div`
    font-weight: 600;
    font-size: 1.25rem /* 20px */;
    line-height: 1.75rem /* 28px */;
    margin-bottom: 1.5rem /* 24px */;
`;
const GratuatyLabel = styled.div`
    color: rgba(157, 160, 164);
`;
const InputField = styled.div`
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    width: 25%;
    ${media.xs`
    width: 50%;
    // max-width: 70vw;
  `}
    ${media.sm`
    width: 50%;
`}
    ${media.md`
  width: 30%;
`}
    ${media.lg`
    width: 25%;
    `}
`;
export default PaymentMethods;
