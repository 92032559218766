import { useEffect, useState } from 'react';
import { Switch, Spinner, Input, useSnackbar } from '@components/common';
import { customerActions } from 'actions/customer';
import { useAppDispatch } from 'hooks/redux';
import { ICustomer } from 'interfaces/customer.interface';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { api } from 'helpers/auth-axios';
const CustomerNotes = ({ customer }: { customer: ICustomer }) => {
    const dispatch = useAppDispatch();

    const [loading, setLoading] = useState(false);
    const [showActions, setShowActions] = useState(false);
    const [note, setNote] = useState(customer.secret_note);
    const [openSnakebar] = useSnackbar();
    const onSubmit = () => {
        setLoading(true);
        api.put(`/customers/${customer._id}`, {
            secret_note: note,
        }).then(res => {
            openSnakebar('Notes saved successfully!');
            setLoading(false);
            setShowActions(false);
        });
    };

    const discard = () => {
        setNote(customer.secret_note);
        setShowActions(false);
    };
    useEffect(() => {
        setNote(customer.secret_note);
    }, [customer]);
    return (
        <>
            <Wrapper>
                <Row style={{ padding: '0' }}>
                    <CustomCol lg={12}>
                        <Input
                            label="Customer Profile Notes"
                            value={note}
                            onChange={({ value }: { value: string }) => {
                                setShowActions(true);
                                setNote(value);
                            }}
                        />
                    </CustomCol>
                </Row>
                {showActions && (
                    <ButtonsModel>
                        <ButtonsBody>
                            <DiscardButton onClick={discard}>Discard</DiscardButton>
                            <SaveButton onClick={onSubmit} disabled={loading}>
                                {loading ? <Spinner /> : 'Save Changes'}
                            </SaveButton>
                        </ButtonsBody>
                    </ButtonsModel>
                )}
            </Wrapper>
        </>
    );
};

const Wrapper = styled(Container)`
    padding: 0;
    @media screen and (min-width: 1px) and (max-width: 900px) {
        padding: 1rem;
    }
`;

const CustomCol = styled(Col)`
    position: relative;
    display: block;
`;
const ButtonsModel = styled.div`
    padding-top: 1.5rem !important;
    border-top-width: 1px !important;
    margin-top: 1.25rem !important;
`;
const ButtonsBody = styled.div`
    display: grid !important;
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    gap: 0.75rem !important;
`;
const SaveButton = styled.button`
    padding: 0.92rem 1.85rem;
    font-weight: 600;
    text-transform: capitalize;
    color: #fff;
    background: #27292e;
    border-radius: 0.57rem;
    font-size: 0.98rem;
    border: none;
    cursor: pointer;
`;
const DiscardButton = styled.button`
    padding: 0.92rem 1.85rem;
    font-weight: 600;
    text-transform: capitalize;
    background: #27292e;
    border-radius: 0.6rem;
    font-size: 0.98rem;
    color: #3f4254;
    border-color: #e4e6ef;
    background-color: rgba(255, 255, 255) !important;
    border: 1px solid rgba(223, 226, 230);
    cursor: pointer;
`;
export default CustomerNotes;
