import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import { palette } from '../../../../styled/common';
const Pricing = ({ onSubscribe }: { onSubscribe(): void }) => {
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    return (
        <>
            <Wrapper>
                <Row>
                    <Column sm={12} md={6} lg={6} xl={4}>
                        <Card>
                            <Heading>Independent</Heading>
                            <Para>
                                Perfect for an individual or a small team starting to get bigger.
                            </Para>
                            <Line />
                            <Flex>
                                <CurrencyUnit>
                                    {' '}
                                    {_userData.user.business_id.currency === '$'
                                        ? 'USD'
                                        : _userData.user.business_id.currency === '£'
                                        ? 'POUND'
                                        : 'EURO'}
                                </CurrencyUnit>
                                <Currency>{_userData.user.business_id.currency}0.00</Currency>
                            </Flex>
                            <Bill>billed yearly</Bill>
                            <Bill>
                                <b>{_userData.user.business_id.currency}0.00</b> billed monthly
                            </Bill>
                            <Button onClick={onSubscribe}>Subscribe</Button>
                            <Subheading>Core features, including:</Subheading>
                            <List>
                                <Icon className={`far fa-check`} />
                                Unlimited Appointments
                            </List>
                            <List>
                                <Icon className={`far fa-check`} />
                                Unlimited Customers
                            </List>
                            <List>
                                <Icon className={`far fa-check`} />
                                Unlimited Services
                            </List>
                            <List>
                                <Icon className={`far fa-check`} />
                                Unlimited Staff
                            </List>
                            <List>
                                <Icon className={`far fa-check`} />
                                Email Notifications
                            </List>
                            <List>
                                <Icon className={`far fa-check`} />
                                Booking Page
                            </List>
                            <List>
                                <Icon className={`far fa-check`} />
                                Booking Integrations
                            </List>
                        </Card>
                    </Column>
                    <Column sm={12} md={6} lg={6} xl={4}>
                        <Cover>
                            <Tag>POPULAR</Tag>
                        </Cover>
                        <ActiveCard>
                            <Heading>Single Location</Heading>
                            <Para>Perfect for growing teams wanting to get to the next level.</Para>
                            <Line />
                            <Flex>
                                <CurrencyUnit>
                                    {_userData.user.business_id.currency === '$'
                                        ? 'USD'
                                        : _userData.user.business_id.currency === '£'
                                        ? 'POUND'
                                        : 'EURO'}
                                </CurrencyUnit>
                                <Currency>{_userData.user.business_id.currency}49.00</Currency>
                            </Flex>
                            <Bill>billed yearly</Bill>
                            <Bill>
                                <b>{_userData.user.business_id.currency}49.00</b> billed monthly
                            </Bill>
                            <ActiveButton onClick={onSubscribe}>Subscribe</ActiveButton>
                            <Subheading>Personal features, plus:</Subheading>
                            <List>
                                <Icon className={`far fa-check`} />
                                Email + SMS Reminders
                            </List>
                            <List>
                                <Icon className={`far fa-check`} />
                                Email + SMS Direct Messaging
                            </List>
                            <List>
                                <Icon className={`far fa-check`} />
                                VIP Appointments
                            </List>
                            <List>
                                <Icon className={`far fa-check`} />
                                Waiting List Appointments
                            </List>
                            <List>
                                <Icon className={`far fa-check`} />
                                Automated Marketing
                            </List>
                            <List>
                                <Icon className={`far fa-check`} />
                                Automated Reviews
                            </List>
                        </ActiveCard>
                    </Column>
                    <Column sm={12} md={6} lg={6} xl={4}>
                        <Card>
                            <Heading>Multi-Location</Heading>
                            <Para>Perfect for businesses with mutliple locations.</Para>
                            <Line />
                            <Flex>
                                <CurrencyUnit>
                                    {' '}
                                    {_userData.user.business_id.currency === '$'
                                        ? 'USD'
                                        : _userData.user.business_id.currency === '£'
                                        ? 'POUND'
                                        : 'EURO'}
                                </CurrencyUnit>
                                <Currency>{_userData.user.business_id.currency}100.00</Currency>
                            </Flex>
                            <Bill>billed yearly</Bill>
                            <Bill>
                                <b>{_userData.user.business_id.currency}100.00</b> billed monthly
                            </Bill>
                            <Button onClick={onSubscribe}>Subscribe</Button>
                            <Subheading>Personal features, plus:</Subheading>
                            <List>
                                <Icon className={`far fa-check`} />
                                Unlimited Appointments
                            </List>
                            <List>
                                <Icon className={`far fa-check`} />
                                Unlimited Customers
                            </List>
                            <List>
                                <Icon className={`far fa-check`} />
                                Unlimited Services
                            </List>
                            <List>
                                <Icon className={`far fa-check`} />
                                Unlimited Staff
                            </List>
                            <List>
                                <Icon className={`far fa-check`} />
                                Email Notifications
                            </List>
                            <List>
                                <Icon className={`far fa-check`} />
                                Booking Page
                            </List>
                            <List>
                                <Icon className={`far fa-check`} />
                                Booking Integrations
                            </List>
                        </Card>
                    </Column>
                </Row>
            </Wrapper>
        </>
    );
};
const Wrapper = styled(Container)`
    padding: 0rem;
`;
const Column = styled(Col)`
    margin-bottom: 2rem;
`;
const Cover = styled.div`
    justify-content: center !important;
    align-items: center !important;
    display: flex !important;
    top: -1rem !important;
    left: 0rem !important;
    right: 0rem !important;
    position: absolute !important;
`;
const Tag = styled.div`
    background-color: ${palette.blue.inkBlue};
    color: ${palette.white} !important;
    line-height: 1 !important;
    font-weight: 500 !important;
    border-radius: 2rem !important;
    padding: 0.5rem 2rem;
`;
const Card = styled.div`
    background-color: ${palette.light};
    box-shadow: 0rem 0rem 2.14rem 0rem ${palette.grey.shadow};
    border-radius: 1rem;
    padding: 3rem 2rem 1.75rem 2rem !important;
`;
const ActiveCard = styled.div`
    background-color: ${palette.light};
    border: 0.2rem solid ${palette.blue.inkBlue};
    box-shadow: 0rem 0rem 2.14rem 0rem ${palette.grey.shadow};
    border-radius: 1rem;
    padding: 3rem 2rem 1.75rem 2rem !important;
    min-height: 52rem;
`;
const Heading = styled.h1`
    letter-spacing: -0.025em !important;
    line-height: 1.25 !important;
    font-weight: 700 !important;
    font-size: 2.25rem !important;
`;
const Para = styled.p`
    color: ${palette.silver} !important;
    font-weight: 500 !important;
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
    margin-top: 0.5rem !important;
`;
const Line = styled.p`
    background-color: ${palette.dark} !important;
    border-radius: 0.25rem !important;
    width: 2rem !important;
    height: 0.25rem !important;
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
`;
const CurrencyUnit = styled.div`
    font-size: 1.5rem !important;
    line-height: 2rem !important;
    margin-right: 0.5rem !important;
`;
const Currency = styled.div`
    font-weight: 600 !important;
    font-size: 3rem !important;
    line-height: 1 !important;
`;
const Flex = styled.div`
    display: flex;
    align-items: baseline !important;
`;
const Bill = styled.div`
    color: ${palette.silver} !important;
    margin-top: 0.5rem !important;
`;
const Button = styled.button`
    width: 100%;
    background-color: ${palette.white};
    padding: 0.92rem 1.85rem;
    font-weight: 600;
    text-transform: capitalize;
    font-size: 0.93rem;
    color: ${palette.blue.inkBlue} !important;
    border: 0.1rem solid ${palette.blue.inkBlue} !important;
    border-radius: 2rem !important;
    margin-top: 2.5rem !important;
    cursor: pointer;
`;
const ActiveButton = styled.button`
    width: 100%;
    background-color: ${palette.blue.inkBlue};
    padding: 0.92rem 1.85rem;
    font-weight: 600;
    text-transform: capitalize;
    font-size: 0.93rem;
    cursor: pointer;
    color: ${palette.white};
    border: 0.1rem solid ${palette.blue.inkBlue} !important;
    border-radius: 2rem !important;
    margin-top: 2.5rem !important;
`;
const Subheading = styled.div`
    margin-top: 3rem !important;
    font-weight: 600 !important;
`;
const List = styled.div`
    margin-top: 1rem !important;
    line-height: 1.25rem !important;
`;
const Icon = styled.i`
    margin-right: 0.5rem !important;
    font-size: 1.21rem;
    color: ${palette.freshGreen};
`;
export default Pricing;
