import {
    IGetServiceProvider,
    IGetServiceProviderResponse,
    IServiceProvider,
} from 'interfaces/team/serviceProvider.interface';
import { api } from '../../helpers/auth-axios';

export const serviceProviderService = {
    create,
    getBusinessServiceProvider,
    searchBusinessServiceProvider,
    getServiceProviderById,
    deleteServiceProviderById,
    updateServiceProviderById,
};

function create(serviceProviderData: IServiceProvider): Promise<IServiceProvider> {
    return api.post('/serviceProvider', serviceProviderData).then(res => {
        return res.data;
    });
}

function getBusinessServiceProvider(
    getServiceProviderData: IGetServiceProvider
): Promise<IGetServiceProviderResponse> {
    return api.get('/serviceProvider', { params: getServiceProviderData }).then(res => {
        return res.data;
    });
}

function searchBusinessServiceProvider(
    searchData: IGetServiceProvider
): Promise<IGetServiceProviderResponse> {
    return api.get('/serviceProvider', { params: searchData }).then(res => {
        return res.data;
    });
}

function getServiceProviderById(serviceProviderId: string): Promise<IServiceProvider> {
    return api.get(`/serviceProvider/${serviceProviderId}`).then(res => {
        return res.data;
    });
}

function deleteServiceProviderById(id: string): Promise<IServiceProvider> {
    return api.delete(`/serviceProvider/${id}`).then(res => {
        return res.data;
    });
}

function updateServiceProviderById(
    id: string,
    serviceProviderData: any
): Promise<IServiceProvider> {
    return api.put(`/serviceProvider/${id}`, serviceProviderData).then(res => {
        return res.data;
    });
}
