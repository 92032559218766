import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { palette } from '../styled/common';
import { Spinner } from '@components/common';

export interface InputProps {
    name?: string;
    bgtype?: any;
    id?: string;
    icon?: string;
    value?: string | number;
    label?: string;
    className?: string;
    ifClicked?: any;
    disabled?: boolean;
    autoComplete?: string;
    stretchMobile?: string;
    hasShadow?: string;
    hasBorder?: string;
    large?: string;
    small?: string;
    margin?: string;
    width?: string;
    type?: any;
    hasLabel?: string;
    error?: {
        message: string;
    };
    slotLeft?: React.ReactNode;
    onBlur?(): void;
    onFocus?(): void;
    onChange?: any;
    onClick?: any;
}

const Button = ({
    ifClicked,
    label,
    type,
    icon,
    bgtype,
    stretchMobile,
    hasShadow,
    hasBorder,
    large,
    small,
    margin,
    width,
    disabled,
}: InputProps) => {
    return (
        <Btn
            type={type}
            onClick={ifClicked}
            label={label}
            large={large}
            bgtype={bgtype}
            margin={margin}
            width={width}
            small={small}
            disabled={disabled}>
            {icon && <Icon hasLabel={label} className={`${icon}`} large={large} />}
            {disabled ? <Spinner /> : `${label}`}
        </Btn>
    );
};

const handleColorType = (bgtype: any) => {
    switch (bgtype) {
        case 'primary':
            return palette.light;
        case 'secondary':
            return palette.light;
        case 'discard':
            return palette.dark;
        case 'default':
            return palette.dark;
        case 'success':
            return palette.light;
        default:
            return palette.light;
    }
};

const handleBackgroundType = (bgtype: any) => {
    switch (bgtype) {
        case 'primary':
            return palette.primary;
        case 'secondary':
            return palette.secondary;
        case 'discard':
            return palette.grey.light;
        case 'default':
            return palette.light;
        case 'success':
            return palette.success;
        default:
            return palette.primary;
    }
};

const handleBorderColor = (type: any) => {
    switch (type) {
        case 'primary':
            return palette.primary;
        case 'secondary':
            return palette.dark;
        case 'discard':
            return palette.grey.light;
        case 'default':
            return palette.dark;
        case 'success':
            return palette.success;
        default:
            return palette.light;
    }
};

const handleHoverColorType = (bgtype: any) => {
    switch (bgtype) {
        case 'primary':
            return palette.primary;
        case 'secondary':
            return palette.dark;
        case 'discard':
            return palette.green;
        case 'default':
            return palette.light;
        default:
            return palette.light;
    }
};

const handleHoverBackgroundType = (bgtype: any) => {
    switch (bgtype) {
        case 'primary':
            return palette.primary;
        case 'secondary':
            return palette.light;
        case 'discard':
            return palette.dark;
        case 'default':
            return palette.dark;
        default:
            return palette.primary;
    }
};

const Btn = styled.button<InputProps>`
    text-transform: capitalize;
    font-weight: 400;
    display: inline-block;
    // border: ${({ bgtype }) => `1px solid ${handleBorderColor(bgtype)}`};
    border: none;
    border-radius: 0.57rem;
    padding: ${({ large, small }) =>
        large ? `1.4rem 1.8rem` : small ? '0.75rem 1rem' : '0.75rem 1rem'};
    color: ${({ bgtype }) => handleColorType(bgtype)};
    background: ${({ bgtype }) => `${handleBackgroundType(bgtype)}`};
    box-shadow: ${({ hasShadow }) => (hasShadow ? `1px 1px 2px ${palette.dark}` : 'none')};
    margin: ${({ margin }) => `${margin}`};
    // letter-spacing: 1px;
    // font-weight: 700;
    cursor: pointer;
    width: ${({ width }) => width};
    width: ${({ stretchMobile }) => (stretchMobile ? '100%' : 'min-content')};
    white-space: nowrap;
    ${media.lg`
		width: min-content
	`}
    transition: all ease 0.25s;
    &:hover {
        background: ${({ bgtype }) => `${handleBackgroundType(bgtype)}`};
    }
`;

const Icon = styled.span<InputProps>`
    margin-right: ${({ hasLabel }) => (hasLabel ? ` 1rem` : 'none')};
    font-size: ${({ large }) => (large ? `1.25rem` : 'inherit')};
`;

export default Button;
