import { useCallback, useMemo, useRef, useState } from 'react';
import Card from './Card';
import CardForm from './CardForm';

const NewCreditCard = ({
    initialState,
    isReserveAndPayEnabled,
    onChange,
    loading,
}: {
    initialState: any;
    isReserveAndPayEnabled: boolean;
    onChange(keyName: string, value: any): void;
    loading: any;
}) => {
    const [state, setState] = useState(initialState);
    const [currentFocusedEl, setCurrentFocusedEl] = useState(null);

    const updateStateValues = useCallback(
        (keyName: string, value: any) => {
            onChange(keyName, value);
            setState({
                ...state,
                [keyName]: value || initialState[keyName],
            });
        },
        [state]
    );

    const formFieldsRefObj: any = {
        cardNumber: useRef(),
        cardHolder: useRef(),
        cardDate: useRef(),
        cardCVV: useRef(),
    };

    const cardElementsRef: any = {
        cardNumber: useRef(),
        cardHolder: useRef(),
        cardDate: useRef(),
    };

    const focusFormFieldByKey = useCallback(key => {
        formFieldsRefObj[key].current.focus();
    }, []);

    return (
        <div className="grid grid-cols-6 gap-10">
            <div className="col-span-3 flex flex-col">
                <div className="card flex-1">
                    <div className="card-body">
                        <Card
                            cardNumber={state.cardNumber}
                            cardHolder={state.cardHolder}
                            cardMonth={state.cardMonth}
                            cardYear={state.cardYear}
                            cardCVV={state.cardCVV}
                            isCardFlipped={state.isCardFlipped}
                            currentFocusedEl={currentFocusedEl}
                            onCardElClick={(el: string) => console.log(el)}
                            cardNumberRef={cardElementsRef.cardNumber}
                            cardHolderRef={cardElementsRef.cardHolder}
                            cardDateRef={cardElementsRef.cardDate}
                        />
                        <CardForm
                            loading={loading}
                            cardMonth={state.cardMonth}
                            cardYear={state.cardYear}
                            onUpdateState={updateStateValues}
                            cardNumberRef={cardElementsRef.cardNumber}
                            cardHolderRef={cardElementsRef.cardHolder}
                            cardDateRef={cardElementsRef.cardDate}
                            isReserveAndPayEnabled={isReserveAndPayEnabled}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewCreditCard;
