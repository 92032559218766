import { IGetTeam, IGetTeamResponse, ITeam } from 'interfaces/team/teamMember.interface';
import { Dispatch } from 'redux';
import { teamService } from 'services/team/teamMember.service';

export const teamConstants = {
    CREATE_REQUEST: 'TEAM_CREATE_REQUEST',
    CREATE_SUCCESS: 'TEAM_CREATE_SUCCESS',
    CREATE_FAILURE: 'TEAM_CREATE_FAILURE',

    GET_REQUEST: 'TEAM_GET_REQUEST',
    GET_SUCCESS: 'TEAM_GET_SUCCESS',
    GET_FAILURE: 'TEAM_GET_FAILURE',

    SEARCH_REQUEST: 'TEAM_SEARCH_REQUEST',
    SEARCH_SUCCESS: 'TEAM_SEARCH_SUCCESS',

    UPDATE_REQUEST: 'TEAM_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'TEAM_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'TEAM_UPDATE_FAILURE',

    DELETE_SUCCESS: 'TEAM_SUCCESS',
};

const createTeam =
    (teamData: ITeam) =>
    (dispatch: Dispatch): Promise<ITeam> => {
        dispatch({
            type: teamConstants.CREATE_REQUEST,
        });

        return teamService.create(teamData).then(team => {
            dispatch({
                type: teamConstants.CREATE_SUCCESS,
                team,
            });

            return team;
        });
    };

const getBusinessTeam =
    (getTeamData: IGetTeam, overwrite = false) =>
    (dispatch: Dispatch): Promise<IGetTeamResponse> => {
        return teamService.getBusinessTeam(getTeamData).then(team => {
            if (!overwrite) {
                dispatch({
                    type: teamConstants.GET_SUCCESS,
                    team,
                });
            } else {
                dispatch({
                    type: teamConstants.SEARCH_SUCCESS,
                    team,
                });
            }

            return team;
        });
    };

const searchBusinessTeam =
    (searchData: IGetTeam) =>
    (dispatch: Dispatch): Promise<IGetTeamResponse> => {
        return teamService.searchBusinessTeam(searchData).then(team => {
            dispatch({
                type: teamConstants.SEARCH_SUCCESS,
                team,
            });
            return team;
        });
    };

const getTeamById =
    (teamId: string) =>
    (dispatch: Dispatch): Promise<ITeam> => {
        return teamService.getTeamById(teamId).then(team => {
            // dispatch({
            //     type: teamConstants.GET_SUCCESS,
            //     team,
            // });

            return team;
        });
    };
const updateTeamById =
    (id: string, teamData: any) =>
    (dispatch: Dispatch): Promise<ITeam> => {
        dispatch({
            type: teamConstants.UPDATE_REQUEST,
        });

        return teamService.updateTeamById(id, teamData).then(team => {
            dispatch({
                type: teamConstants.UPDATE_SUCCESS,
                team,
            });

            return team;
        });
    };
const deleteTeamById =
    (id: string) =>
    (dispatch: Dispatch): Promise<ITeam> => {
        return teamService.deleteTeamById(id).then(team => {
            dispatch({
                type: teamConstants.DELETE_SUCCESS,
                id,
            });

            return team;
        });
    };

export const teamActions = {
    createTeam,
    getBusinessTeam,
    searchBusinessTeam,
    getTeamById,
    updateTeamById,
    deleteTeamById,
};
