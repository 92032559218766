import { useEffect, useState } from 'react';
import Button from '@components/Button';
import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import { Input, LoaderOverlay, Switch, useSnackbar } from '@components/common';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { palette } from '../../../styled/common';
import { SmallSwitch } from '@components/common/Switch';
import { api } from 'helpers/auth-axios';

const BookingSettings = () => {
    const [loading, setLoading] = useState<boolean>(false);

    const [business, setBusiness] = useState<any>();
    const [emailNotifications, setEmailNotifications] = useState<boolean>(false);
    const [smsNotifications, setSmsNotifications] = useState<boolean>(false);

    const [openSnackbar] = useSnackbar();

    const validationSchema = Yup.object().shape({
        cancellation_policy: Yup.number()
            .typeError('Please enter a number')
            .integer('Please enter a whole number (no decimal points)')
            .moreThan(0, 'Please enter a number higher than ${more}')
            .required('This field is required'),
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
    };
    // @ts-ignore
    const { handleSubmit, setValue, getValues, formState, trigger } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    var _userData = JSON.parse(localStorage.getItem('user') || '{}');

    const getBusiness = () => {
        setLoading(true);
        api.get(`/businesses/${_userData?.user?.business_id?._id}`)
            .then((res: any) => {
                setLoading(false);
                setBusiness(res.data);
                setEmailNotifications(res.data.notifications.email);
                setSmsNotifications(res.data.notifications.sms);
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    useEffect(() => {
        getBusiness();
    }, []);

    async function onChange({ name, value }: { name: string; value: string }) {
        setValue(name as never, value as never);
        await trigger(name as never);
    }

    const onSubmit = (data: any) => {
        const requestBody = {
            ...data,
            notifications: {
                email: emailNotifications,
                sms: smsNotifications,
            },
        };
        api.put(`businesses/${business._id!}`, requestBody)
            .then((res: any) => {
                var _userData = JSON.parse(localStorage.getItem('user') || '{}');
                if (res !== undefined) {
                    _userData.user.business_id.booking_without_payment =
                        res?.data?.booking_without_payment;
                    _userData.user.business_id.cancellation_policy = res.data.cancellation_policy;
                    _userData.user.business_id.show_first_available = res.data.show_first_available;
                    localStorage.setItem('user', JSON.stringify(_userData));
                }
                openSnackbar('Updated successfully!');
            })
            .catch((e: any) => {
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    // Initialize form field values
    useEffect(() => {
        if (!business) {
            return;
        }
        setTimeout(() => {
            setValue('cancellation_policy', business.cancellation_policy);
            setValue('hide_business_prices', business.hide_business_prices);
            setValue('show_first_available', business.show_first_available);
            setValue('booking_without_payment', business.booking_without_payment);
        });
    }, [business, setValue]);
    return (
        <>
            {loading && <LoaderOverlay />}
            <Wrapper>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Wrapper>
                                    <Row>
                                        <CustomCol lg={6}>
                                            <CardHead>
                                                <Heading>Booking Rules</Heading>
                                                <Para>
                                                    Write your own rules regarding cancellation,
                                                    rescheduling, booking leeway and appointment
                                                    sizes.
                                                </Para>
                                            </CardHead>
                                            <InputGroup>
                                                <Input
                                                    type="tel"
                                                    label="Cancellation Policy (hours)"
                                                    name="cancellation_policy"
                                                    min="1"
                                                    step="1"
                                                    value={business?.cancellation_policy}
                                                    onChange={onChange}
                                                    error={errors?.cancellation_policy as any}
                                                />
                                            </InputGroup>
                                            <CardHeading>Custom Settings</CardHeading>
                                            <CardList>
                                                <div>Disable Prepay</div>
                                                <Switch
                                                    name="booking_without_payment"
                                                    value={business?.booking_without_payment}
                                                    onChange={(val: any) => {
                                                        onChange({
                                                            name: 'booking_without_payment',
                                                            value: val,
                                                        });
                                                    }}
                                                />
                                            </CardList>
                                            <Divider />
                                            <CardList>
                                                <div>Hide Business Prices</div>
                                                <Switch
                                                    name="hide_business_prices"
                                                    onChange={(val: any) => {
                                                        onChange({
                                                            name: 'hide_business_prices',
                                                            value: val,
                                                        });
                                                    }}
                                                    value={business?.hide_business_prices}
                                                />
                                            </CardList>
                                            <Divider />
                                            <CardList>
                                                <div>Show First Available</div>
                                                <Switch
                                                    name="show_first_available"
                                                    onChange={(val: any) => {
                                                        onChange({
                                                            name: 'show_first_available',
                                                            value: val,
                                                        });
                                                    }}
                                                    value={business?.show_first_available}
                                                />
                                            </CardList>
                                            <Divider />
                                            <CardList>
                                                <div>Email Notifications</div>
                                                <Switch
                                                    name="email_notifications"
                                                    onChange={(val: boolean) =>
                                                        setEmailNotifications(val)
                                                    }
                                                    value={emailNotifications}
                                                />
                                            </CardList>
                                            <Divider />
                                            <CardList>
                                                <div>SMS Notifications</div>
                                                <Switch
                                                    name="sms_notifications"
                                                    onChange={(val: boolean) =>
                                                        setSmsNotifications(val)
                                                    }
                                                    value={smsNotifications}
                                                />
                                            </CardList>
                                            <Divider />
                                            <Button
                                                disabled={isSubmitting}
                                                type="submit"
                                                bgtype="secondary"
                                                label="Save"></Button>
                                        </CustomCol>
                                    </Row>
                                </Wrapper>
                            </form>
                        </Card>
                    </Col>
                </Row>
            </Wrapper>
        </>
    );
};
const Wrapper = styled(Container)`
    padding: 0rem;
`;
const CustomCol = styled(Col)`
    border-right: 0.1rem solid ${palette.grey.lightest};
    ${media.xs`
    border-right: none;
  `}
    ${media.sm`
    border-right: none;
  `}
    ${media.md`
    border-right: none;
  `}
    ${media.lg`
    border-right: 0.1rem solid ${palette.grey.lightest};
  `}
`;
const Card = styled.div`
    background-color: ${palette.white};
    box-shadow: 0rem 0rem 2.14rem 0rem ${palette.grey.shadow};
    border-radius: 0.42rem;
    padding: 3rem 2rem 1.75rem 2rem !important;
`;
const CardHead = styled.div`
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    margin-bottom: 2.5rem !important;
`;
const Heading = styled.h1`
    font-weight: 600 !important;
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
    margin-bottom: 0.5rem !important;
`;
const Para = styled.p`
    color: rgba(157, 160, 164) !important;
    font-weight: 300 !important;
    font-size: 0.9rem !important;
`;
const InputGroup = styled.div`
    margin-bottom: 1.71rem;
`;
const ToggleButton = styled.div`
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;
const CustomSpan = styled.span`
    font-size: 1rem;
`;
const CardHeading = styled.h1`
    font-weight: 600 !important;
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
    margin-bottom: 1.5rem !important;
`;
const CardList = styled.div`
    align-items: center !important;
    cursor: pointer !important;
    display: flex !important;
    justify-content: space-between;
`;
const Divider = styled.hr`
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
    border: 0.1rem none none none solid ${palette.grey.lightest};
    border-top: none;
    border-right: none;
    border-left: none;
`;

export default BookingSettings;
