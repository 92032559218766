import { Layout } from '@components/common';
import NotificationOptions from '@components/settings/NotificationSetting';
const Notifications = () => {
    return (
        <>
            <NotificationOptions />
        </>
    );
};

Notifications.Layout = Layout;
export default Notifications;
