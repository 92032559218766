import s from '../../NewAppointmentDrawer/NewAppointmentDrawer.module.scss';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import { api } from 'helpers/auth-axios';
import { useSnackbar, Select } from '@components/common';
import { paymentOptions } from 'constants/paymentOptions';
import Button from '@components/Button';
interface IProps {
    appointment?: any;
    transaction?: any;
    onClose(): void;
    onSubmit(): any;
}
const MarkPaid = ({ appointment, transaction, onClose, onSubmit }: IProps) => {
    const [openSnackbar] = useSnackbar();
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any>(paymentOptions[0]);

    const markAppointmentAsPaid = () => {
        let requestBody = {
            payment_method: {
                code: selectedPaymentMethod.code,
                label: selectedPaymentMethod.label,
            },
        };
        api.put(`/appointments/${appointment._id}/mark-paid`, requestBody)
            .then((res: any) => {
                openSnackbar('Appointment marked as "Paid"!');
                onSubmit();
            })
            .catch((e: any) => {
                if (e?.response) {
                    openSnackbar(e.response.data.message);
                    onClose();
                }
            });
    };

    return (
        <>
            <Container>
                <Row>
                    <ClientContent lg={12}>
                        <HeaderContainer>
                            <CloseButton onClick={onClose}>
                                <Icon className="fal fa-long-arrow-left"></Icon>
                            </CloseButton>
                            <Header>Take an Offline Payment</Header>
                        </HeaderContainer>
                        <CustomersBody>
                            <Wrapper>
                                <p style={{ marginBottom: '1.25rem' }}>
                                    Don't want to charge your customer's credit card? Take payment
                                    for this appointment however you want and select the payment
                                    method below to mark this appointment as paid.
                                </p>
                                <div>
                                    <div style={{ marginBottom: '1.25rem' }}>
                                        <Select
                                            disabled={true}
                                            value={
                                                transaction?.payment_method.label ||
                                                paymentOptions[0].label
                                            }
                                            label="Payment Method"
                                            showLabel={true}
                                            options={paymentOptions.map((option: any) => {
                                                return {
                                                    label: option.label,
                                                    value: option.code,
                                                };
                                            })}
                                            onChange={(val: any) => {
                                                setSelectedPaymentMethod({
                                                    code: val,
                                                    label: paymentOptions.find(
                                                        (option: any) => option.code === val
                                                    )?.label,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>

                                <Button
                                    bgtype={'secondary'}
                                    disabled={selectedPaymentMethod === null}
                                    ifClicked={() => markAppointmentAsPaid()}
                                    width="100% !important"
                                    label={'Save & Mark As Paid'}></Button>
                            </Wrapper>
                        </CustomersBody>
                    </ClientContent>
                </Row>
            </Container>
        </>
    );
};
const ClientContent = styled(Col)`
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    ${media.xs`
    margin-left: 1.5rem;
  `}
    ${media.sm`
`}
    ${media.md`
`}
    ${media.lg`
    `}
`;
const HeaderContainer = styled.div`
    display: flex;
    padding: 2rem 0.3rem;
    min-height: 70px;
    align-items: center;
`;
const CloseButton = styled.div`
    margin-right: 1.5rem;
    cursor: pointer;
`;

const Icon = styled.i`
    font-size: 2.25rem;
    line-height: 2.5rem;
`;
const Header = styled.div`
    font-size: 1.75rem;
    font-weight: 600;
`;

const CustomersBody = styled.div`
    flex: 0 0 25rem;
    background-color: #fff;
    margin-left: 0rem;
    min-height: 100vh;
`;
const Wrapper = styled.div`
    padding-bottom: 100px;
`;
const SearchResult = styled.ul`
    margin-top: 2rem;
    flex: 1;
    min-height: auto;
    position: relative;
    overflow-y: auto;
    list-style: none;
    padding: 0;
`;
const CustomerDetails = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 10px;
    margin-bottom: 1.3rem;
`;
const DivGroup = styled.div`
    margin-bottom: 1.3rem;
`;
const Items = styled.div`
    grid-column: span 6 / span 6 !important;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    color: rgba(157, 160, 164) !important;
`;
const Amount = styled.div`
    grid-column: span 3 / span 3 !important;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    text-align: right !important;
    color: rgba(157, 160, 164) !important;
`;
const Title = styled.div`
    font-weight: 600;
    font-size: 1.25rem /* 20px */;
    line-height: 1.75rem /* 28px */;
`;
const Span = styled.div`
    font-size: 1rem /* 20px */;
    line-height: 1.75rem /* 28px */;
    color: #0154ff;
    cursor: pointer;
`;
const GratuatyLabel = styled.div`
    color: rgba(157, 160, 164);
`;

const BasketItem = styled.ul`
    display: flex !important;
    justify-content: space-between;
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
    gap: 0.75rem !important;
    margin-top: 0.5rem;
`;

const ItemsName = styled.div`
    font-size: 0.92rem;
    display: flex;
    align-items: center;
    grid-column: span 6 / span 6;
    width: 150px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;

const ItemsAmount = styled.div`
    font-size: 13.8px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: end;
    grid-column: span 3 / span 3;
    text-align: right;
`;
const CustomDiv = styled.div`
    display: grid !important;
    margin-top: 0.5rem !important;
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
    gap: 0.75rem !important;
`;
const TotalPriceContainer = styled.div`
    display: grid !important;
    margin-top: 0.5rem !important;
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
    gap: 0.75rem !important;
`;
const Left = styled.div`
    grid-column: span 3 / span 3 !important;
`;
const Right = styled.div`
    grid-column: span 7 / span 7 !important;
    border-top: 1px solid rgba(223, 226, 230);
`;
const SubTotalWrapper = styled.div`
    display: flex !important;
    justify-content: space-between !important;
    padding-top: 0.75rem !important;
    padding-bottom: 0.5rem !important;
`;
const SubTotalContent = styled.div`
    font-size: 0.9rem;
`;
const DiscountWrapper = styled.div`
    display: flex !important;
    justify-content: space-between !important;
    padding-bottom: 0.5rem !important;
`;
const GratuatyContent = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.5rem;
    margin-top: 1rem;
`;
const Gratuaty = styled.div`
    font-size: 0.9rem /* 14.4px */;
`;
const InputField = styled.div`
    margin-left: 1rem;
    margin-top: 1.5rem;
    width: 35%;
    ${media.xs`
    margin-top: 1.5rem;
    width: 42%;
  `}
    ${media.sm`
    margin-top: 1.5rem;
    width: 40%;
`}
    ${media.md`
    margin-top: 1rem;
  width: 35%;
`}
    ${media.lg`
    width: 35%;
    `}
`;
export default MarkPaid;
