import { IGetPermissions, IPermissions } from 'interfaces/permissions.interface';
import { Dispatch } from 'redux';
import { PermissionServices } from 'services/permissions.service';

export const permissionsConstants = {
    CREATE_REQUEST: 'PERMISSIONS_CREATE_REQUEST',
    CREATE_SUCCESS: 'PERMISSIONS_CREATE_SUCCESS',
    CREATE_FAILURE: 'PERMISSIONS_CREATE_FAILURE',

    GET_REQUEST: 'PERMISSIONS_GET_REQUEST',
    GET_SUCCESS: 'PERMISSIONS_GET_SUCCESS',
    GET_FAILURE: 'PERMISSIONS_GET_FAILURE',

    UPDATE_REQUEST: 'PERMISSIONS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'PERMISSIONS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'PERMISSIONS_UPDATE_FAILURE',
};

const createPermissions =
    (user_id: string, permissionsData: IPermissions) =>
    (dispatch: Dispatch): Promise<IPermissions> => {
        dispatch({
            type: permissionsConstants.CREATE_REQUEST,
        });

        return PermissionServices.create(user_id, permissionsData).then(permissions => {
            dispatch({
                type: permissionsConstants.CREATE_SUCCESS,
                permissions,
            });

            return permissions;
        });
    };

const getPermissions =
    (user_id: string) =>
    (dispatch: Dispatch): Promise<IGetPermissions[]> => {
        dispatch({
            type: permissionsConstants.GET_REQUEST,
        });

        return PermissionServices.getPermissions(user_id).then(permissions => {
            dispatch({
                type: permissionsConstants.GET_SUCCESS,
                permissions,
            });

            return permissions;
        });
    };

const updatePermissions =
    (id: string, permissionsData: IPermissions) =>
    (dispatch: Dispatch): Promise<IPermissions> => {
        dispatch({
            type: permissionsConstants.UPDATE_REQUEST,
        });

        return PermissionServices.updatePermissions(id, permissionsData).then(permissions => {
            dispatch({
                type: permissionsConstants.UPDATE_SUCCESS,
                permissions,
            });

            return permissions;
        });
    };
export const permissionsActions = {
    createPermissions,
    getPermissions,
    updatePermissions,
};
