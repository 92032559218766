import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Col, Row, media } from 'styled-bootstrap-grid';
import { Input, Select, Spinner, useSnackbar } from '@components/common';
import { Link, useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { userActions } from 'actions/user';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import logo from '../images/bookedya-logo-960w.png';
import illustration from '../images/appointment-bg-1.svg';
import useRouter from 'hooks/router';
import googleLogo from 'images/brands/google-icon.svg';
import facebookLogo from 'images/brands/facebook-icon.svg';
import appleLogo from 'images/brands/apple-icon.svg';
import SideCover from '@components/common/SideCover/SideCover';
import { businessActions } from 'actions/business';

type FormValues = {
    email: string;
    password: string;
};

const Login = () => {
    useEffect(() => {
        localStorage.removeItem('user');
        localStorage.removeItem('url');
    }, []);

    const router = useRouter();
    const navigate = useNavigate();
    const [openSnackbar] = useSnackbar();
    const dispatch = useAppDispatch();
    const { loggingIn } = useAppSelector(state => state.auth);
    const [authMethod, setAuthMethod] = useState('email');
    const [roleList, setRole] = useState([
        { value: 'user', label: 'Business Admin' },
        { value: 'serviceProvider', label: 'Service Provider' },
        { value: 'others', label: 'Others' },
        { value: 'teamMember', label: 'Team Member' },
    ]);

    const [selectedRole, setSeletedRole] = useState('');

    const validationSchema = Yup.object().shape({
        email: Yup.string().required('This field is required'),
        password: Yup.string().required('This field is required'),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, setValue, formState, trigger } = useForm<FormValues>(formOptions);
    const { errors } = formState;

    const onSubmit = handleSubmit(({ email, password }: { email: string; password: string }) => {
        let role = router.pathname === '/admin/login' ? 'user' : 'user';
        if (selectedRole != '' && selectedRole != undefined) {
            role = selectedRole;
        }
        let adminLogin = {
            email,
            password,
            role,
            // business: true,
        };
        let userLogin = {
            email,
            password,
            role,
        };
        return dispatch(
            userActions.login(router.pathname === '/admin/login' ? adminLogin : userLogin)
        ).then((res: any) => {
            if (res.user) {
                let _userData = res.user;
                if (
                    router.pathname == '/admin/login' &&
                    _userData.role == 'ADMIN' &&
                    res?.user_login === 'user'
                ) {
                    navigate('/admin');
                } else if (router.pathname == '/login' && res?.user?.business_id?._id) {
                    dispatch(businessActions.getBusiness(res?.user?.business_id?._id)).then(
                        (res: any) => {
                            if (res.active === true) {
                                openSnackbar('Login Successfully');
                                if (_userData != '' && _userData != undefined)
                                    if (_userData.permissions.dashboard) {
                                        navigate('/');
                                    } else if (_userData.permissions.calendar) {
                                        navigate('/calendar');
                                    } else if (_userData.permissions.sales) {
                                        navigate('/sales');
                                    } else if (_userData.permissions.team) {
                                        navigate('/team');
                                    } else if (_userData.permissions.business) {
                                        navigate('/settings');
                                    } else {
                                        navigate('/login');
                                        openSnackbar(
                                            "Your account doesn't have permissions required to access any other view."
                                        );
                                    }
                            } else {
                                localStorage.removeItem('user');
                                openSnackbar(
                                    'Login Failed. Please contact support for furthur information.'
                                );
                            }
                        }
                    );
                } else {
                    localStorage.removeItem('user');
                    openSnackbar('Login Failed');
                }
            } else {
                openSnackbar('Login Failed');
            }
        });
    });

    async function onChange({ name, value }: { name: string; value: string }) {
        setValue(name as never, value as never);
        await trigger(name as never);
    }

    useEffect(() => {
        if (router.query.success !== undefined) {
            if (router.query.provider) setAuthMethod(router.query.provider as string);
            if (router.query.accessToken) {
                dispatch(userActions.getCurrentUser(router.query.accessToken as string)).then(
                    (res: any) => {
                        if (res.email === true) {
                            openSnackbar('res.message');
                            navigate('/');
                        }
                    }
                );
            }
        }
    }, [dispatch, router.query]);

    return (
        <>
            <Wrapper>
                <Row>
                    <SideCover />
                    <Col lg={5} style={{ padding: '0' }}>
                        <CardRight>
                            <Inner>
                                <>
                                    <Heading>
                                        {router.pathname == '/admin/login'
                                            ? 'Admin Log In'
                                            : 'Log In'}{' '}
                                    </Heading>
                                    {router.pathname != '/admin/login' && (
                                        <SignInText>
                                            {/*Don't have an account?*/}
                                            {/*<Link to="/register">
                                                <SignUpText className="text-primary-light">
                                                    Create Account
                                                </SignUpText>
                                            </Link>*/}
                                        </SignInText>
                                    )}
                                </>
                            </Inner>

                            <form onSubmit={onSubmit}>
                                <InputGroup>
                                    <Input
                                        label="E-mail Address"
                                        name="email"
                                        type="email"
                                        onChange={onChange}
                                        error={errors.email as any}
                                    />
                                </InputGroup>

                                <InputGroup>
                                    <Input
                                        type="password"
                                        name="password"
                                        label="Password"
                                        onChange={onChange}
                                        error={errors.password as any}
                                    />
                                </InputGroup>

                                {router.pathname != '/admin/login' && (
                                    <>
                                        <CustomDiv>
                                            <Link to={'/forgot-password'}>Forgot Password?</Link>
                                        </CustomDiv>
                                        <InputGroup>
                                            <Select
                                                name="selectedRole"
                                                value={
                                                    selectedRole == 'user'
                                                        ? 'Business Admin'
                                                        : selectedRole == 'serviceProvider'
                                                        ? 'Service Provider'
                                                        : selectedRole == 'others'
                                                        ? 'Others'
                                                        : selectedRole == 'teamMember'
                                                        ? 'Team Member'
                                                        : 'Please select your role'
                                                }
                                                options={roleList}
                                                onChange={e => {
                                                    setSeletedRole(e);
                                                }}
                                            />
                                        </InputGroup>
                                    </>
                                )}
                                <Button type="submit">Log In</Button>
                            </form>

                            {router.pathname != '/admin/login' && (
                                <div>
                                    {/*
                                    <Divider>
                                        <Span>OR</Span>
                                    </Divider>
                                    <SocialButton href="http://localhost:5000/auth/google">
                                        <Icon src={googleLogo} alt="Google logo" />
                                        Continue with Google
                                    </SocialButton>
                                    <SocialButton href="http://localhost:5000/auth/facebook">
	const router = useRouter()
	const navigate = useNavigate()
	const [openSnackbar] = useSnackbar()
	const dispatch = useAppDispatch()
	const {loggingIn} = useAppSelector((state) => state.auth)
	const [authMethod, setAuthMethod] = useState("email")
	const validationSchema = Yup.object().shape({
		email: Yup.string().required("This field is required"),
		password: Yup.string().required("This field is required"),
	})
	const formOptions = {resolver: yupResolver(validationSchema)}
	const {handleSubmit, setValue, formState, trigger} = useForm(formOptions)
	const {errors} = formState
	const onSubmit = ({email, password}: {email: string; password: string}) => {
		return dispatch(userActions.login(email, password)).then((res: any) => {
			if (res.isAxiosError) {
				openSnackbar("Login Failed")
				console.log(res.message)
			} else {
				openSnackbar("Login Successfully")
				navigate("/")
			}
		})
	}
	async function onChange({name, value}: {name: string; value: string}) {
		setValue(name as never, value as never)
		await trigger(name as never)
	}
	useEffect(() => {
		if (router.query.success !== undefined) {
			if (router.query.provider) setAuthMethod(router.query.provider as string)
			if (router.query.accessToken) {
				dispatch(userActions.getCurrentUser(router.query.accessToken as string)).then((res: any) => {
					if (res.email === true) {
						openSnackbar("res.message")
						//   console.log(res);
						navigate("/")
					}
				})
			}
		}
	}, [dispatch, router.query])
	return (
		<>
			<Wrapper>
				<Row>
					<SideCover />
					<Col lg={5} style={{padding: "0"}}>
						<CardRight>
							<Inner>
								<Heading>Login</Heading>
								<SignInText>
									Don't have an account?
									<Link to="/register">
										<SignUpText className="text-primary-light">Sign Up</SignUpText>
									</Link>
								</SignInText>
							</Inner>
							<form onSubmit={handleSubmit(onSubmit)}>
								<InputGroup>
									<Input label="E-mail Address" name="email" type="email" onChange={onChange} error={errors.email as any} />
								</InputGroup>
								<InputGroup>
									<Input type="password" name="password" label="Password" onChange={onChange} error={errors.password as any} />
								</InputGroup>
								<Button disabled={loggingIn} type="submit">
									{loggingIn ? <Spinner /> : "Sign In"}
								</Button>
							</form>
							<div>
								<Divider>
									<Span>OR</Span>
								</Divider>
								<SocialButton href="http://localhost:5000/auth/google">
									<Icon src={googleLogo} alt="Google logo" />
									Continue with Google
								</SocialButton>
								{/* <SocialButton href="http://localhost:5000/auth/facebook">
                                        <Icon src={facebookLogo} alt="Facebook logo" />
                                        Continue with Facebook
                                    </SocialButton>
                                    <SocialButton href="http://localhost:5000/auth/apple">
                                        <Icon src={appleLogo} alt="Apple logo" />
                                        Continue with Apple
                                    </SocialButton> */}
                                </div>
                            )}
                        </CardRight>
                    </Col>
                </Row>
            </Wrapper>
        </>
    );
};
const Wrapper = styled(Container)`
    paddind: 0;
    min-width: 100%;
    background-color: #fff;
`;
const CardLeft = styled.div`
    background-color: #4871fe;
    padding-top: 3.5rem !important;
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
`;
const CardRight = styled.div`
    padding-left: 4.75rem !important;
    padding-right: 4.75rem !important;
    background-color: #fff;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
const Inner = styled.div`
    margin-bottom: 3rem;
`;
const Heading = styled.h3`
    color: #000;
    font-size: 2.6rem;
    font-weight: 600 !important;
    ${media.xs`
    margin-top:0;
	`}
    ${media.sm`
    margin-top:0;
	`}
	${media.md`
    margin-top:0;
	`}
`;
const SignInText = styled.span`
    display: flex;
    color: #000;
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
`;
const SignUpText = styled.span`
    color: #0154ff !important;
    font-weight: 800;
    padding-left: 0.5rem;
    margin: 0;
`;
const InputGroup = styled.div`
    margin-bottom: 1.71rem;
`;
const Button = styled.button`
    color: rgba(255, 255, 255) !important;
    text-transform: capitalize !important;
    font-weight: 800 !important;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
    background-color: #0154ff !important;
    border-radius: 0.5rem !important;
    width: 100% !important;
    display: inline-block !important;
    margin-top: 1rem !important;
    border: 0;
    cursor: pointer;
    font-size: 1.25rem;
`;
const Span = styled.span``;
const Divider = styled.div`
    border: 0.01rem solid #f3f4f6;
    width: 100%;
    height: 0.1rem;
    position: relative;
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
    & ${Span} {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        background-color: #fff;
        width: 7.14rem;
        font-weight: 500;
        text-align: center;
        color: #c0c5cd;
    }
`;
const SocialButton = styled.a`
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow,
        transform, filter, backdrop-filter, -webkit-backdrop-filter !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
    color: rgba(125, 128, 131) !important;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    background-color: rgba(244, 245, 246) !important;
    border-radius: 0.5rem !important;
    justify-content: center !important;
    width: 100% !important;
    display: flex !important;
    margin-bottom: 1rem !important;
    cursor: pointer;
`;
const Icon = styled.img`
    height: 1.42rem;
    margin-right: 0.75rem !important;
`;

const UserLink = styled.div`
    margin-top: 0.5rem;
    display: flex;
    justify-content: end;
    color: #000;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.25rem;
`;
const CustomDiv = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 0rem 0rem;
    color: #0154ff;
    cursor: pointer;
`;
export default Login;
