import React, { useRef } from 'react';
import { Layout, OthersCard, OthersList } from '@components/team';
import { Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import { Container, media } from 'styled-bootstrap-grid';
interface IProps {
    getOthers: () => void;
}
const Others = () => {
    const _useRef = useRef<IProps>(null);
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const callOther = () => {
        if (_useRef?.current) {
            _useRef?.current?.getOthers();
        }
    };
    return (
        <Wrapper>
            {_userData.user_login == 'user' ? <OthersList ref={_useRef} /> : ''}

            <Routes>
                <Route path=":id" element={<OthersCard callOther={callOther} />} />
            </Routes>

            <style>
                {`
                .customer-listing--main {
                    height: 100%;
                }
            `}
            </style>
        </Wrapper>
    );
};

const Wrapper = styled(Container)`
    padding: 0px;
    height: 100%;
    ${media.xs`
  display: block !important;
`}
    ${media.sm`
display: block !important;
`}
${media.md`
display: flex !important;
margin:0;
min-width:100%;
`}
${media.lg`
display: flex !important;
`}
`;
Others.Layout = Layout;
export default Others;
