import { Drawer, Input, Select, useSnackbar } from '@components/common';

import { useEffect, useState } from 'react';
import SelectClient from './SelectClient';
import SelectService from './SelectService';

import { IService } from 'interfaces/service.interface';
import { ICustomer } from 'interfaces/customer.interface';
import moment from 'moment';
import { appointmentActions } from 'actions/appointment';
import { useAppDispatch } from 'hooks/redux';
import { ICreateReservation, IAppointment } from 'interfaces/appointment.interface';
import NewRecurringModal from '../Modal/NewRecurringModal';
import { IRecurring } from 'interfaces/recurring.interface';
import ConflictModal from '../Modal/ConflictModal';
import styled from 'styled-components';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import Button from '@components/Button';
import { IServiceProvider } from 'interfaces/team/serviceProvider.interface';
import { api } from 'helpers/auth-axios';
import { SmallSwitch } from '@components/common/Switch';
import CompletePayment from '../EditAppointmentDrawer/CompletePayment/CompletePayment';
import logo from '../../../images/userDefault.png';
import CustomerModal from '../Modal/CustomerModal';
const DurationHours: { label: string; value: number }[] = [];
for (let i = 0; i < 24; i++) {
    DurationHours.push({
        label: `${i}h`,
        value: i,
    });
}

const DurationMinutes: { label: string; value: number }[] = [];
for (let i = 0; i < 4; i++) {
    DurationMinutes.push({
        label: `${i * 15}min`,
        value: i * 15,
    });
}

const formatDate = (date: Date) => {
    return moment(date).format('YYYY-MM-DD') + 'T' + moment(date).format('HH:mm:ss');
};

const NewAppointmentDrawer = ({
    id,
    startDate,
    endDate,
    onChange,
    onClose,
    onSave,
    serviceProviderId,
    setBlockModel,
    conflictDate,
    conflictModel,
}: {
    id: string;
    startDate: any;
    endDate: any;
    onChange(event: any): void;
    onClose(isAppointmentAdded: any, event: any): void;
    onSave(event: any, appointment: IAppointment): void;
    serviceProviderId: any;
    setBlockModel: any;
    conflictDate(val: any): void;
    conflictModel(val: any): void;
}) => {
    useEffect(() => {
        setStart(startDate);
        setEnd(endDate);
        updateEvent(event);
    }, [startDate, endDate]);

    /* inputs */
    const [startdate, setStartDate] = useState<any>();
    const [enddate, setEndDate] = useState<any>();

    /* UI elements */
    const [customerView, setCustomerView] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState<ICustomer>();

    const [selectedServiceProvider, setSelectedServiceProvider] = useState<IServiceProvider>();

    const [showRecurringModal, setShowRecurringModal] = useState(false);
    const [recurring, setRecurring] = useState<IRecurring>();

    const [serviceView, setServiceView] = useState(false);
    const [selectedService, setSelectedService] = useState<any>();
    const [serviceDurationHours, setServiceDurationHours] = useState<any>(0);
    const [serviceDurationMinutes, setServiceDurationMinutes] = useState<any>(0);

    const [start, setStart] = useState(formatDate(startDate));
    const [end, setEnd] = useState(formatDate(endDate));

    const [durationHours, setDurationHours] = useState<any>(0);
    const [durationMinutes, setDurationMinutes] = useState<any>(0);
    // toggle buttons
    const [vipAppointment, setVipAppointment] = useState<any>(false);
    const [durationField, setDurationFieldOpen] = useState<any>(false);
    // note fields
    const [appointmentNote, setAppointmentNote] = useState<string>();
    const [customerNote, setCustomerNote] = useState<string>();

    /* modals (and some of their relevant variables) */
    const [showConflictModal, setShowConflictModal] = useState(false);
    const [conflictDates, setConflictDates] = useState<string[]>([]);
    const [overbooking, setOverbooking] = useState(false);

    const [chargeModel, setChargeModel] = useState<boolean>(false);
    const [customerModel, setCustomerModel] = useState(false);
    const [customerEditModel, setCustomerEditModel] = useState(false);

    /* global variables (kind of) */
    const [isAppointmentAdded, setIsAppointmentAdded] = useState(false); // set to true after appointment is successfully created
    const [active, setActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [availableSlot, setAvailableSlot] = useState<any[]>([]);
    const [dropDownFilter, setDropdownFilter] = useState<any>();

    const dispatch = useAppDispatch();
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const [openSnackbar] = useSnackbar();
    const event = {
        id,
        start,
        end,
        customer: selectedCustomer,
        service: selectedService,
        serviceProvider: selectedServiceProvider,
    };

    const appointmentTime: { label: string; value: any }[] = [];

    let time = moment().format('HH:mm');
    let date = moment(startdate ? startdate : start).format('YYYY-MM-DD');
    let todayDate = moment().format('YYYY-MM-DD');
    appointmentTime.push({
        label: vipAppointment === true ? 'Select VIP Time' : 'Select Time',
        value: '',
    });

    availableSlot.length &&
        availableSlot.forEach((slot: any) => {
            if (_userData?.user_login === 'customer' && date === todayDate) {
                // limit the time range customers can schedule for current day
                slot.time >= time &&
                    appointmentTime.push({
                        label: moment(
                            moment().format() + ' ' + slot.time,
                            'YYYY-MM-DD HH:mm:ss'
                        ).format(
                            _userData?.user?.business_id.time_select === '12' ? 'hh:mm a' : 'HH:mm'
                        ),
                        value: moment(
                            moment().format() + ' ' + slot.time,
                            'YYYY-MM-DD HH:mm:ss'
                        ).format('HH:mm'),
                    });
            } else {
                appointmentTime.push({
                    label: moment(
                        moment().format() + ' ' + slot.time,
                        'YYYY-MM-DD HH:mm:ss'
                    ).format(
                        _userData?.user?.business_id.time_select === '12' ? 'hh:mm a' : 'HH:mm'
                    ),
                    value: moment(
                        moment().format() + ' ' + slot.time,
                        'YYYY-MM-DD HH:mm:ss'
                    ).format('HH:mm'),
                });
            }
        });

    const disabledBtn = !selectedService || !selectedCustomer || !selectedServiceProvider;

    useEffect(() => {
        setTimeout(() => setActive(true), 20);
    }, []);

    useEffect(() => {
        if (overbooking) onSubmit();
    }, [overbooking]);

    const updateEvent = (data: any) => {
        onChange({
            ...event,
            ...data,
        });
    };
    const defaultDuration = serviceDurationHours * 60 + serviceDurationMinutes;
    const customDuration = durationHours * 60 + durationMinutes;
    const startTime =
        moment(startdate ? startdate : start).format('YYYY-MM-DD') +
        'T' +
        moment(start).format('HH:mm') +
        ':00';
    const endTime =
        moment(enddate ? enddate : end).format('YYYY-MM-DD') +
        'T' +
        moment(start)
            .add(durationField === false ? defaultDuration : customDuration, 'minutes')
            .format('HH:mm') +
        ':00';
    const newAppointment = {
        booked_from_iso: startTime ? moment(startTime).format() : moment(start).format(),
        booked_till_iso: endTime ? moment(endTime).format() : moment(end).format(),
        booked_from: startTime ? moment(startTime).format() : moment(start).format(),
        booked_till: endTime ? moment(endTime).format() : moment(end).format(),
        appointment_note: appointmentNote ? appointmentNote : '',
        customer: selectedCustomer && selectedCustomer?._id,
        service: selectedService && selectedService!._id,
        serviceProvider: selectedServiceProvider && selectedServiceProvider?._id,
        total:
            selectedService && vipAppointment == true
                ? _userData.user.business_id.currency + selectedService?.vip_price
                : _userData.user.business_id.currency + selectedService?.my_price,
        total_value: parseInt(
            selectedService
                ? vipAppointment == true
                    ? selectedService!.vip_price
                    : selectedService!.my_price
                : 0
        ),
        customer_note: customerNote ? customerNote : '',
        recurring: recurring ? recurring : null,
        overbooking: overbooking,
        duration: durationField === false ? defaultDuration : customDuration,
        is_vip: vipAppointment,
        payment_method_id: '',
        payment_intent_id: '',
        paid_booking_fee: true,
    };

    const onSubmit = () => {
        if (!selectedCustomer || !selectedServiceProvider || !selectedService) {
            return;
        }

        setLoading(true);

        if (availableSlot.length) {
            let requestBody: ICreateReservation = {
                business_id: _userData.user.business_id?._id,
                customer_details: {
                    firstname: selectedCustomer.firstname!,
                    lastname: selectedCustomer.lastname!,
                    email: selectedCustomer.email!,
                    phone: selectedCustomer.phone!,
                },

                service_provider_id: selectedServiceProvider._id!,
                service_id: selectedService._id!,
                appointment_date: moment(startdate ? startdate : start).format('YYYY-MM-DD'),
                start_time: moment(start).format('HH:mm'),
                is_vip: vipAppointment,
                appointment_note: appointmentNote || '',
                customer_note: customerNote || '',

                platform: 'web',
            };
            // if a custom duration has been set
            if (durationField) {
                requestBody['custom_duration'] = customDuration;
            }
            if (recurring) {
                requestBody['recurring'] = recurring;
            }
            api.post('/appointments', requestBody)
                .then((res: any) => {
                    setLoading(false);
                    onSave(event, res as IAppointment);
                    setActive(false);
                    if (res.data.conflicts?.length) {
                        // change format of all conflict dates from "DD-MM-YYYY" to "dddd, MMM DD, YYYY"
                        const dates = res.data.conflicts.map((conflictingDate: string) =>
                            moment(conflictingDate, 'DD-MM-YYYY').format('dddd, MMM DD, YYYY')
                        );
                        conflictModel(true);
                        conflictDate(dates);
                    }
                    setIsAppointmentAdded(true);
                    openSnackbar('Appointment saved!');
                })
                .catch((error: any) => {
                    setLoading(false);
                    if (error?.response) {
                        openSnackbar(error.response.data.message);
                        if (error.response.data.dates) {
                            setConflictDates(error.response.data.dates);
                            setShowConflictModal(true);
                        }
                        setIsAppointmentAdded(false);
                    }
                });
        } else {
            setLoading(false);
            openSnackbar('No slot available at this time!');
        }
    };

    const getServiceProvider = () => {
        api.get(`/serviceProvider/${serviceProviderId}`).then((res: any) => {
            if (res.data !== 0) {
                setSelectedServiceProvider(res.data);
            }
        });
    };

    const getCustomer = () => {
        api.get(`/customers/${_userData?.user?._id}`).then((res: any) => {
            if (res.data !== 0) {
                setSelectedCustomer(res.data.customer);
            }
        });
    };

    const getAvailableSlot = () => {
        if (serviceProviderId !== undefined) {
            let date: any = moment(startdate ? startdate : start).format();
            let duration =
                durationField === false
                    ? defaultDuration == 0
                        ? '10'
                        : defaultDuration
                    : customDuration;
            api.post(`appointments/available_slots/${serviceProviderId}`, {
                date: date,
                duration: duration,
            }).then((res: any) => {
                setAvailableSlot(res.data);
            });
        }
    };

    useEffect(() => {
        getServiceProvider();
        if (_userData?.user_login === 'customer') {
            getCustomer();
        }
    }, []);

    useEffect(() => {
        getAvailableSlot();
    }, [
        startdate,
        defaultDuration,
        customDuration,
        start,
        selectedService,
        selectedCustomer,
        vipAppointment,
    ]);

    // used to set both "startDate" and "endDate" when date is selected
    const calendarDate = (e: any) => {
        setStartDate(e.target.value);
        setEndDate(e.target.value);
    };

    /*
     * Still figuring out what this is meant to do. It looks like it's some sort
     * of way to default to the first available time slot if the selected start
     * time isn't actually available?
     */
    useEffect(() => {
        dropDownValue();
    }, [startdate, defaultDuration, customDuration, start, selectedService]);
    //}, [startdate, defaultDuration, customDuration, start, selectedService, dropDownFilter]);
    const dropDownValue = () => {
        const value = moment(start).format('HH:mm');
        appointmentTime.forEach((val: any, i: any) => {
            if (val.value === value) {
                return setDropdownFilter(val.value);
            } else {
                return appointmentTime[0].label;
            }
        });
    };

    /*
     * Helper/Utility Functions
     */
    const onlinePaymentIsDisabled = () => {
        // return true if business has disabled prepaying
        if (_userData.user.business_id?.booking_without_payment) {
            return true;
        }
        // return false if business or service provider have a payment processor enabled
        if (aPaymentProcessorIsEnabled()) {
            return false;
        }
        // default case: return true, to say "prepay is disabled"
        return true;
    };

    // Return true if business or provider have a payment processor enabled
    const aPaymentProcessorIsEnabled = () => {
        // First, check the service provider
        // Check if stripe is enabled
        if (selectedServiceProvider?.stripe_account_verified) {
            return true;
        }
        // Check if square is enabled
        if (selectedServiceProvider?.square_account_verified) {
            return true;
        }
        // Now, we check the business
        // Check if stripe is enabled
        if (_userData.user.business_id?.stripe_account_verified) {
            return true;
        }
        // Check if square is enabled
        if (_userData.user.business_id?.square_account_verified) {
            return true;
        }
        // Default case, return False since nothing else shows as enabled
        return false;
    };

    const formatPhoneNumberForLink = (phoneNumber: string) => {
        // customer phone numbers are stored in format 012-345-6789
        // we want them in the format +10123456789
        return `+1${phoneNumber.replaceAll('-', '')}`;
    };

    /*
     * UI variables (that need to be declared last)
     */
    // "should we show payment buttons?"
    const showPaymentButtons = !onlinePaymentIsDisabled();

    return (
        <>
            <Container>
                <Row>
                    <Col sm={12} md={12} lg={12}>
                        <Drawer
                            custom={true}
                            left={false}
                            active={active}
                            onClose={() => {
                                onClose(isAppointmentAdded, event);
                            }}>
                            {!customerView && !serviceView && !chargeModel && (
                                <AppointmentContent>
                                    <HeaderContainer>
                                        <CloseButton
                                            onClick={() => {
                                                setActive(false);
                                            }}>
                                            <Icon className="fal fa-times"></Icon>
                                        </CloseButton>
                                        <Header>New Appointment</Header>
                                    </HeaderContainer>
                                    <DrawerBody>
                                        {selectedCustomer ? (
                                            <div>
                                                <SelectCustomer>
                                                    <div
                                                        style={{ display: 'flex' }}
                                                        onClick={() => {
                                                            _userData?.user_login !== 'customer' &&
                                                                setCustomerView(true);
                                                        }}>
                                                        <Circle>
                                                            <NameCircle
                                                                src={`https://profile-images-barberone-s3.s3.amazonaws.com/${selectedCustomer.photo}`}></NameCircle>
                                                        </Circle>
                                                        <CustomerContent>
                                                            <Name>
                                                                {selectedCustomer.firstname +
                                                                    ' ' +
                                                                    selectedCustomer.lastname}
                                                            </Name>
                                                            <Email>{selectedCustomer.phone}</Email>
                                                            <Email>{selectedCustomer.email}</Email>
                                                        </CustomerContent>
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                        }}>
                                                        <Icons
                                                            className="fal fa-edit"
                                                            onClick={() =>
                                                                _userData?.user_login !==
                                                                    'customer' &&
                                                                setCustomerEditModel(true)
                                                            }></Icons>
                                                        {selectedCustomer.phone && (
                                                            <a
                                                                href={`sms:${formatPhoneNumberForLink(
                                                                    selectedCustomer.phone
                                                                )}`}>
                                                                <Icons className="fal fa-comment"></Icons>
                                                            </a>
                                                        )}
                                                        <Icons
                                                            className="fal fa-plus"
                                                            onClick={() =>
                                                                _userData?.user_login !==
                                                                    'customer' &&
                                                                setCustomerModel(true)
                                                            }></Icons>
                                                        <Icons
                                                            className="fal fa-user"
                                                            onClick={() => {
                                                                _userData?.user_login !==
                                                                    'customer' &&
                                                                    setCustomerView(true);
                                                            }}></Icons>
                                                    </div>
                                                </SelectCustomer>
                                            </div>
                                        ) : (
                                            <div>
                                                <AppointmentCustomer>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}
                                                        onClick={() => {
                                                            _userData?.user_login !== 'customer' &&
                                                                setCustomerView(true);
                                                        }}>
                                                        <Circle>
                                                            <NameCircle
                                                                src={logo}
                                                                alt="logo"></NameCircle>
                                                        </Circle>
                                                        <Label>Select a client</Label>
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                        }}>
                                                        <Icons
                                                            className="fal fa-plus"
                                                            onClick={() =>
                                                                _userData?.user_login !==
                                                                    'customer' &&
                                                                setCustomerModel(true)
                                                            }></Icons>
                                                        <Icons
                                                            className="fal fa-user"
                                                            onClick={() => {
                                                                _userData?.user_login !==
                                                                    'customer' &&
                                                                    setCustomerView(true);
                                                            }}></Icons>
                                                    </div>
                                                </AppointmentCustomer>
                                            </div>
                                        )}
                                        {selectedServiceProvider && (
                                            <div>
                                                <ServiceProviderContent>
                                                    <Circle>
                                                        <NameCircle
                                                            src={`https://profile-images-barberone-s3.s3.amazonaws.com/${selectedServiceProvider.photo}`}></NameCircle>
                                                    </Circle>
                                                    <CustomerContent>
                                                        <Name>{selectedServiceProvider.name}</Name>
                                                        <Email>
                                                            {selectedServiceProvider.email}
                                                        </Email>
                                                    </CustomerContent>
                                                </ServiceProviderContent>
                                            </div>
                                        )}
                                        <FormContent>
                                            <FormDetails>
                                                <DatesBody>
                                                    <FormDate>
                                                        <CalenderDate>
                                                            <i className="fal fa-calendar-day"></i>{' '}
                                                            {startdate
                                                                ? moment(startdate).format(
                                                                      'ddd, MMM DD'
                                                                  )
                                                                : moment(startDate).format(
                                                                      'ddd, MMM DD'
                                                                  )}
                                                        </CalenderDate>
                                                        <input
                                                            type="date"
                                                            min={
                                                                new Date()
                                                                    .toISOString()
                                                                    .split('T')[0]
                                                            }
                                                            value={moment(startdate).format(
                                                                'YYYY-MM-DD'
                                                            )}
                                                            onChange={(e: any) => calendarDate(e)}
                                                        />
                                                    </FormDate>
                                                    {_userData.user_login !== 'customer' && (
                                                        <RepeateButton
                                                            className={`${
                                                                recurring
                                                                    ? 'border-success bg-success text-white'
                                                                    : ''
                                                            }`}
                                                            style={
                                                                recurring && {
                                                                    borderColor: '#28a745',
                                                                    backgroundColor: '#28a745',
                                                                    color: '#fff',
                                                                }
                                                            }
                                                            onClick={() =>
                                                                setShowRecurringModal(true)
                                                            }>
                                                            {recurring ? 'Recurring' : 'Repeat'}{' '}
                                                            <RepeateIcon className="fas fa-repeat"></RepeateIcon>
                                                        </RepeateButton>
                                                    )}
                                                </DatesBody>
                                                {selectedService ? (
                                                    <SelectServiceContent
                                                        onClick={() => {
                                                            setServiceView(true);
                                                        }}>
                                                        <ServiceBorder
                                                            className={`service-color-${selectedService.color}--bd`}></ServiceBorder>
                                                        <ServiceDetails>
                                                            <NameBody>
                                                                <ServiceName>
                                                                    {selectedService.name}
                                                                </ServiceName>
                                                                <ServiceDuration>
                                                                    {selectedService.my_duration}min
                                                                </ServiceDuration>
                                                            </NameBody>
                                                            <PriceBody>
                                                                <ServicePrice>
                                                                    {
                                                                        _userData.user.business_id
                                                                            .currency
                                                                    }
                                                                    {vipAppointment == true
                                                                        ? selectedService.vip_price
                                                                        : selectedService.my_price}
                                                                </ServicePrice>
                                                                <div>
                                                                    <Icons className="fal fa-chevron-right"></Icons>
                                                                </div>
                                                            </PriceBody>
                                                        </ServiceDetails>
                                                    </SelectServiceContent>
                                                ) : (
                                                    <SelectServiceBody
                                                        onClick={() => {
                                                            setServiceView(true);
                                                        }}>
                                                        <ServiceHeader>
                                                            Select service
                                                        </ServiceHeader>
                                                        <div>
                                                            <Icons className="fal fa-chevron-right"></Icons>
                                                        </div>
                                                    </SelectServiceBody>
                                                )}
                                                <InputGroup>
                                                    <InputContent>
                                                        <Col lg={12}>
                                                            <Select
                                                                label="Start"
                                                                value={
                                                                    availableSlot.length &&
                                                                    dropDownFilter
                                                                        ? moment(
                                                                              moment(
                                                                                  startDate
                                                                              ).format(
                                                                                  'YYYY-MM-DD'
                                                                              ) +
                                                                                  ' ' +
                                                                                  dropDownFilter,
                                                                              'YYYY-MM-DD HH:mm'
                                                                          ).format(
                                                                              _userData?.user
                                                                                  ?.business_id
                                                                                  .time_select ===
                                                                                  '12'
                                                                                  ? 'hh:mm a'
                                                                                  : 'HH:mm'
                                                                          )
                                                                        : 'No slot available'
                                                                }
                                                                options={appointmentTime}
                                                                onChange={(val: string) => {
                                                                    const newStart =
                                                                        moment(start).format(
                                                                            'YYYY-MM-DD'
                                                                        ) +
                                                                        'T' +
                                                                        val +
                                                                        ':00';

                                                                    setStart(newStart);
                                                                }}
                                                            />
                                                        </Col>
                                                    </InputContent>
                                                    {_userData.user_login !== 'customer' && (
                                                        <Row>
                                                            <Col lg={6}>
                                                                <InputGroup>
                                                                    <Select
                                                                        label="Hour(s)"
                                                                        readOnly={!durationField}
                                                                        options={DurationHours}
                                                                        value={
                                                                            durationField === false
                                                                                ? serviceDurationHours ===
                                                                                  ''
                                                                                    ? '0h'
                                                                                    : serviceDurationHours +
                                                                                      'h'
                                                                                : durationHours ===
                                                                                  ''
                                                                                ? '0h'
                                                                                : durationHours +
                                                                                  'h'
                                                                        }
                                                                        onChange={(val: string) => {
                                                                            setDurationHours(
                                                                                parseInt(val)
                                                                            );
                                                                        }}
                                                                    />
                                                                </InputGroup>
                                                            </Col>
                                                            <Col lg={6}>
                                                                <InputGroup>
                                                                    <Select
                                                                        label="Minutes"
                                                                        readOnly={!durationField}
                                                                        options={DurationMinutes}
                                                                        value={
                                                                            durationField === false
                                                                                ? serviceDurationMinutes +
                                                                                  'min'
                                                                                : durationMinutes +
                                                                                  'min'
                                                                        }
                                                                        onChange={(val: string) => {
                                                                            setDurationMinutes(
                                                                                parseInt(val)
                                                                            );
                                                                        }}
                                                                    />
                                                                </InputGroup>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                    <ToggleButton>
                                                        <div>
                                                            {selectedServiceProvider?.vip ==
                                                                true && (
                                                                <>
                                                                    {' '}
                                                                    <CustomSpan>
                                                                        VIP
                                                                    </CustomSpan>{' '}
                                                                    <SmallSwitch
                                                                        value={vipAppointment}
                                                                        onChange={(val: any) => {
                                                                            setVipAppointment(val);
                                                                        }}
                                                                    />
                                                                </>
                                                            )}
                                                        </div>
                                                        {_userData.user_login !== 'customer' && (
                                                            <div>
                                                                <CustomSpan>
                                                                    Custom Duration
                                                                </CustomSpan>{' '}
                                                                <SmallSwitch
                                                                    value={durationField}
                                                                    onChange={() => {
                                                                        setDurationFieldOpen(
                                                                            !durationField
                                                                        );
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                    </ToggleButton>
                                                </InputGroup>
                                                {_userData.user_login !== 'customer' && (
                                                    <InputGroup>
                                                        <Input
                                                            label="Appointment note (only visible to staff)"
                                                            value={appointmentNote}
                                                            onChange={({
                                                                value,
                                                            }: {
                                                                value: string;
                                                            }) => {
                                                                setAppointmentNote(value);
                                                            }}
                                                        />
                                                    </InputGroup>
                                                )}
                                                <InputGroup>
                                                    <Input
                                                        label={`${
                                                            _userData.user_login !== 'customer'
                                                                ? 'Message from client'
                                                                : 'Message to serviceProvider'
                                                        }`}
                                                        value={customerNote}
                                                        readOnly={true}
                                                        onChange={({
                                                            value,
                                                        }: {
                                                            value: string;
                                                        }) => {
                                                            setCustomerNote(value);
                                                        }}
                                                    />
                                                </InputGroup>
                                                <TotalPriceDiv
                                                    style={{
                                                        justifyContent: `${
                                                            _userData.user_login === 'customer' &&
                                                            'flex-end'
                                                        }`,
                                                    }}>
                                                    {_userData.user_login !== 'customer' && (
                                                        <TotalPriceBody>
                                                            <DeleteButton
                                                                onClick={setBlockModel}
                                                                disabled={loading}>
                                                                {' '}
                                                                Add Block to Schedule
                                                            </DeleteButton>
                                                        </TotalPriceBody>
                                                    )}
                                                    <TotalPriceBody>
                                                        <PriceHeader>Total</PriceHeader>
                                                        <TotalPrice>
                                                            {_userData.user.business_id.currency}
                                                            {vipAppointment == true
                                                                ? selectedService?.vip_price
                                                                : selectedService?.my_price}
                                                        </TotalPrice>
                                                    </TotalPriceBody>
                                                </TotalPriceDiv>

                                                <FormFooter>
                                                    <FormButtons>
                                                        {disabledBtn
                                                            ? ''
                                                            : showPaymentButtons && (
                                                                  <Button
                                                                      width="100% !important"
                                                                      bgtype={'discard'}
                                                                      ifClicked={() => {
                                                                          setChargeModel(true);
                                                                      }}
                                                                      label="Charge & Save"></Button>
                                                              )}

                                                        <Button
                                                            width="100% !important"
                                                            bgtype={'secondary'}
                                                            disabled={disabledBtn || loading}
                                                            ifClicked={onSubmit}
                                                            label="Save"></Button>
                                                    </FormButtons>
                                                </FormFooter>
                                            </FormDetails>
                                        </FormContent>
                                    </DrawerBody>
                                </AppointmentContent>
                            )}
                            {customerView && (
                                <SelectClient
                                    onClose={() => {
                                        setCustomerView(false);
                                    }}
                                    onSelect={(customer: ICustomer) => {
                                        setSelectedCustomer(customer);
                                        updateEvent({ customer });
                                    }}
                                />
                            )}

                            {serviceView && (
                                <SelectService
                                    id={serviceProviderId}
                                    onClose={() => {
                                        setServiceView(false);
                                    }}
                                    selectedService={selectedService}
                                    onSelect={(service: any) => {
                                        const newEnd = formatDate(
                                            moment(start).add(service.duration, 'm').toDate()
                                        );

                                        const data = {
                                            price: service.price,
                                            _id: service.service_id,
                                            my_price: service.my_price,
                                            my_duration: service.my_duration,
                                            duration: service.duration,
                                            name: service.name,
                                            color: service.color,
                                            available_for_boooking: service.available_for_boooking,
                                            vip_price: service?.vip_price,
                                        };
                                        setSelectedService(data);
                                        setEnd(newEnd);
                                        setServiceDurationHours(
                                            service.my_duration >= 60
                                                ? Math.floor(service.my_duration / 60)
                                                : ''
                                        );
                                        setDurationHours(
                                            service.my_duration >= 60
                                                ? Math.floor(service.my_duration / 60)
                                                : ''
                                        );
                                        setDurationMinutes(service.my_duration % 60);
                                        setServiceDurationMinutes(service.my_duration % 60);
                                        updateEvent({ service, end: newEnd });
                                        getAvailableSlot();
                                    }}
                                />
                            )}

                            {showRecurringModal && (
                                <NewRecurringModal
                                    start={moment(start)}
                                    end={moment(end)}
                                    onSave={(recurring: any) => {
                                        setRecurring(recurring);
                                        setShowRecurringModal(false);
                                    }}
                                    recurring={recurring}
                                    onClose={() => {
                                        setShowRecurringModal(false);
                                    }}
                                />
                            )}

                            {showConflictModal == true && (
                                <ConflictModal
                                    dates={conflictDates}
                                    onContinue={() => {
                                        setShowConflictModal(false);
                                        setOverbooking(true);
                                    }}
                                    onClose={() => setShowConflictModal(false)}
                                />
                            )}
                            {chargeModel === true && (
                                <CompletePayment
                                    newAppointmentModel={true}
                                    onClose={() => setChargeModel(false)}
                                    onSubmit={() => {
                                        setActive(false);
                                    }}
                                    selectedService={selectedService}
                                    selectedProfessional={selectedServiceProvider}
                                    customerDetails={selectedCustomer}
                                    appointment={newAppointment}
                                />
                            )}
                        </Drawer>
                    </Col>
                </Row>
                {(customerEditModel === true || customerModel) && (
                    <CustomerModal
                        onSave={(data: any) => {
                            setSelectedCustomer(data);
                            setCustomerModel(false);
                            setCustomerEditModel(false);
                        }}
                        onEdit={selectedCustomer}
                        editModel={customerEditModel}
                        title={customerEditModel === true ? 'Edit Customer' : 'Add New Customer'}
                        onClose={() => {
                            setCustomerModel(false);
                            setCustomerEditModel(false);
                        }}
                    />
                )}
            </Container>
        </>
    );
};

const AppointmentContent = styled(Col)`
    height: 100%;
    display: flex;
    flex-direction: column;
`;
const HeaderContainer = styled.div`
    display: flex;
    padding: 1rem 1.75rem;
    min-height: 70px;
    align-items: center;
`;
const CloseButton = styled.div`
    margin-right: 1.5rem;
    cursor: pointer;
`;

const Icon = styled.i`
    font-size: 2.25rem;
    line-height: 2.5rem;
`;
const Header = styled.div`
    font-size: 1.25rem;
    font-weight: 600;
`;

const DrawerBody = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    padding-top: 0.5rem /* 8px */;
    padding: 0 1.75rem;
    overflow-y: auto;
`;
const AppointmentCustomer = styled.div`
    display: flex;
    align-items: center;
    box-shadow: none;
    background: #fff;
    padding: 1rem 16px;
    border-radius: 0.5rem;
    width: 100%;
    border: 1px solid rgba(223, 226, 230);
    cursor: pointer;
    margin-top: 0.5rem;
    justify-content: space-between;
`;
const SelectCustomer = styled.div`
    display: flex;
    align-items: center;
    box-shadow: none;
    background: #fff;
    padding: 0.2rem 16px;
    border-radius: 0.5rem;
    width: 100%;
    border: 1px solid rgba(223, 226, 230);
    cursor: pointer;
    margin-top: 0.5rem;
    justify-content: space-between;
`;
const ServiceProviderContent = styled.div`
    display: flex;
    align-items: center;
    box-shadow: none;
    background: #fff;
    padding: 1rem 16px;
    border-radius: 0.5rem;
    width: 100%;
    border: 1px solid rgba(223, 226, 230);
    cursor: pointer;
    margin-top: 0.5rem;
`;
const FormContent = styled.div`
    flex: 1 1 0%;
`;
const FormDetails = styled.div`
    padding-top: 2rem;
    height: calc(100% - 84px);
`;
const DatesBody = styled.div`
    justify-content: space-between !important;
    display: -webkit-box !important;
    margin-bottom: 0rem /* 24px */;
`;
const FormDate = styled.div`
    font-weight: 500;
    font-size: 1.35rem;
    display: block;
    justify-content: center;
    align-items: center;
    padding-top: 0.5rem;
    input {
        border: none;
        box-sizing: border-box;
        outline: 0;
        position: relative;
        top: -1.8rem;
        width: 8rem;
        opacity: 0;
        cursor: pointer;
    }
    input[type='date']::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }
`;
const CalenderDate = styled.div`
    cursor: pointer;
`;
const RepeateButton = styled.button`
    padding: 0.92rem 1.85rem;
    font-weight: 600;
    text-transform: capitalize;
    background: #27292e;
    border-radius: 0.57rem;
    padding-top: 0.5rem /* 8px */;
    padding-bottom: 0.5rem /* 8px */;
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
    background-color: rgba(255, 255, 255);
    color: rgba(38, 52, 70);
    border: 1px solid rgba(223, 226, 230);
    cursor: pointer;
`;
const RepeateIcon = styled.i`
    margin-left: 0.5rem /* 8px */;
`;

const SelectServiceContent = styled.div`
    border: 1px solid rgba(223, 226, 230);
    border-radius: 0.5rem /* 8px */;
    padding: 12px 12px 12px 20px;
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    position: relative;
    cursor: pointer;
`;
const ServiceHeader = styled.div`
    color: rgba(125, 128, 131);
`;
const InputGroup = styled.div`
    margin-bottom: 1rem;
`;
const ToggleButton = styled.div`
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const CustomSpan = styled.span`
    font-size: 0.8rem;
`;

const InputContent = styled(Row)`
    margin-bottom: 1rem;
`;
const ServiceBorder = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-left-width: 4px;
    border-radius: 8px 0 0 8px;
`;
const ServiceDetails = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const NameBody = styled.div`
    display: flex;
    flex-direction: column;
`;
const ServiceName = styled.div`
    font-weight: 500;
    font-size: 1.1rem;
`;
const ServiceDuration = styled.div`
    font-size: 0.925rem;
    color: rgba(157, 160, 164);
`;
const PriceBody = styled.div`
    display: flex;
    align-items: center;
`;
const ServicePrice = styled.div`
    font-weight: 500;
    font-size: 1.1rem;
    margin-right: 18px;
`;
const SelectServiceBody = styled.div`
    border: 1px solid rgba(223, 226, 230);
    border-radius: 0.5rem /* 8px */;
    padding-top: 1.5rem /* 24px */;
    padding-bottom: 1.5rem /* 24px */;
    padding-left: 1rem /* 16px */;
    padding-right: 1rem /* 16px */;
    margin-bottom: 1rem /* 32px */;
    align-items: center;
    display: flex;
    cursor: pointer;
    justify-content: space-between;
`;
const Circle = styled.div`
    display: inline-block;
    flex-shrink: 0;
    position: relative;
    border-radius: 0.42rem;
`;
const Label = styled.div`
    margin-left: 1rem /* 16px */;
    margin-right: 1rem /* 16px */;
    flex: 1 1 0%;
`;
const Icons = styled.i`
    font-size: 1rem /* 18px */;
    line-height: 1.75rem /* 28px */;
`;
const NameCircle = styled.img`
    background: linear-gradient(-135deg, #eee, #dddfe1);
    color: #7e8299;
    width: 3.14rem;
    height: 3.14rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 0.42rem;
    font-size: 1.21rem;
    text-transform: capitalize !important;
    border-radius: 50%;
`;
const TotalPriceDiv = styled.div`
    display: flex;
    justify-content: space-between;
`;
const TotalPriceBody = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 0.5rem;
`;
const PriceHeader = styled.div`
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
    text-transform: capitalize;
    color: rgba(188, 192, 197);
    text-align: right;
`;
const TotalPrice = styled.div`
    font-size: 1.5rem /* 24px */;
    line-height: 2rem /* 32px */;
    font-weight: 600;
    text-align: right;
`;
const FormFooter = styled.div`
    padding: 0.5rem 0rem;
    background: #fff;
`;

const FormButtons = styled.div`
    display: flex;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0.5rem /* 24px */;
    justify-content: space-between;
`;

const CustomerContent = styled.div`
    margin-left: 1rem /* 16px */;
    margin-right: 1rem /* 16px */;
    flex: 1 1 0%;
    flex-direction: column;
`;
const Name = styled.div`
    font-weight: 500;
`;
const Email = styled.div`
    color: rgba(157, 160, 164);
    font-size: 0.9rem;
`;
const DeleteButton = styled.button`
    text-transform: capitalize;
    font-weight: 400;
    display: inline-block;
    border: none;
    border-radius: 0.57rem;
    padding: 0.75rem 1rem;
    color: #ffffff;
    // background: #eb5353;
    background-color: rgb(172, 172, 172);
    box-shadow: none;
    margin: undefined;
    cursor: pointer;
    width: fit-content;
    white-space: nowrap;
    -webkit-transition: all ease 0.25s;
    transition: all ease 0.25s;
    ${media.xs`
	padding: 0.93rem 0.5rem;
    font-size: 0.7rem;
	`}
    ${media.sm`
    padding: 0.93rem 0.8rem;
	`}
    ${media.md`
    padding: 0.93rem 0.8rem;
	`}
	${media.lg`
    padding: 0.93rem 0.8rem;
	`}
`;

export default NewAppointmentDrawer;
