import { Spinner, Switch, useSnackbar } from '@components/common';
import { RoleEnum } from 'constants/enums';
import { api } from 'helpers/auth-axios';
import useRouter from 'hooks/router';
import { IPermissions } from 'interfaces/permissions.interface';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { palette } from 'styled/common';
const Permissions = ({
    id,
    businessId,
    userRole,
}: {
    id: string; // ID of the user we're looking up working hours for
    businessId: string;
    userRole: RoleEnum;
}) => {
    const [loading, setLoading] = useState(false);
    const [showActions, setShowActions] = useState(false);
    const [permission, setPermissions] = useState<IPermissions>();
    const [openSnackbar] = useSnackbar();
    const router = useRouter();
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const onSubmit = () => {
        setLoading(true);
        let id: any = permission?._id;
        let data: any = {
            business_id: businessId,
            dashboard: permission?.dashboard,
            team_dashboard: permission?.team_dashboard,
            calendar: permission?.calendar,
            sales: permission?.sales,
            services: permission?.services,
            hours: permission?.hours,
            vip: permission?.vip,
            breaks: permission?.breaks,
            time_off: permission?.time_off,
            payment_settings: permission?.payment_settings,
            booking_settings: permission?.booking_settings,
            team: permission?.team,
            customers: permission?.customers,
            business: permission?.business,
            activity: permission?.activity,
        };

        id &&
            api.put(`/permissions/${id}`, data).then(res => {
                setLoading(false);
                setShowActions(false);
                openSnackbar('Update Successfully!');
                setPermissions(res.data);
            });
    };
    const getPermissionsData = () => {
        api.get(`/permissions/${id}`).then((permission: any) => {
            setPermissions(permission.data);
        });
    };
    useEffect(() => {
        getPermissionsData();
    }, [id]);

    const discard = () => {
        setShowActions(false);
    };

    return (
        <>
            <Container>
                <Row>
                    {permission && (
                        <CustomCol lg={12}>
                            <Options>
                                <div>
                                    <Icon className="far fa-tachometer-alt"></Icon>
                                    Dashboard
                                </div>
                                <Switch
                                    disabled={_userData.user_login != 'user' ? true : false}
                                    onChange={(val: boolean) => {
                                        setShowActions(true);
                                        // if Dashboard permission is turned off, turn off team_dashboard as well
                                        // if Dashboard permission is turned on, do nothing to team_dashboard
                                        if (val) {
                                            setPermissions((prevState: any) => ({
                                                ...prevState,
                                                dashboard: val,
                                            }));
                                        } else {
                                            setPermissions((prevState: any) => ({
                                                ...prevState,
                                                dashboard: val,
                                                team_dashboard: val,
                                            }));
                                        }
                                    }}
                                    value={permission?.dashboard}
                                />
                            </Options>
                            <Hr />
                            {userRole === RoleEnum.SERVICEPROVIDER && (
                                <>
                                    <Options>
                                        <div style={{ marginLeft: '15px' }}>
                                            <Icon className="far fa-project-diagram"></Icon>
                                            Team Dashboard
                                        </div>
                                        <Switch
                                            disabled={_userData.user_login != 'user' ? true : false}
                                            onChange={(val: boolean) => {
                                                setShowActions(true);
                                                setPermissions((prevState: any) => ({
                                                    ...prevState,
                                                    team_dashboard: val,
                                                }));
                                            }}
                                            value={permission?.team_dashboard}
                                        />
                                    </Options>
                                    <Hr />
                                </>
                            )}
                            <Options>
                                <div>
                                    <Icon className="far fa-list-alt"></Icon>
                                    Activity
                                </div>
                                <Switch
                                    disabled={_userData.user_login != 'user' ? true : false}
                                    onChange={(val: boolean) => {
                                        setShowActions(true);
                                        setPermissions((prevState: any) => ({
                                            ...prevState,
                                            activity: val,
                                        }));
                                    }}
                                    value={permission?.activity}
                                />
                            </Options>
                            <Hr />
                            <Options>
                                <div>
                                    <Icon className="far fa-calendar-week"></Icon>
                                    Calender
                                </div>
                                <Switch
                                    disabled={_userData.user_login != 'user' ? true : false}
                                    onChange={(val: boolean) => {
                                        setShowActions(true);
                                        setPermissions((prevState: any) => ({
                                            ...prevState,
                                            calendar: val,
                                        }));
                                    }}
                                    value={permission?.calendar}
                                />
                            </Options>
                            <Hr />
                            <Options>
                                <div>
                                    <Icon className="far fa-user"></Icon>
                                    Profile
                                </div>
                            </Options>
                            <Hr />
                            {userRole === RoleEnum.SERVICEPROVIDER && (
                                <>
                                    <Options>
                                        <div style={{ marginLeft: '15px' }}>
                                            <Icon className="far fa-cut"></Icon>
                                            Services
                                        </div>
                                        <Switch
                                            disabled={_userData.user_login != 'user' ? true : false}
                                            onChange={(val: boolean) => {
                                                setShowActions(true);
                                                setPermissions((prevState: any) => ({
                                                    ...prevState,
                                                    services: val,
                                                }));
                                            }}
                                            value={permission?.services}
                                        />
                                    </Options>
                                    <Hr />
                                </>
                            )}
                            {(userRole === RoleEnum.SERVICEPROVIDER ||
                                userRole === RoleEnum.TEAMMEMBER) && (
                                <>
                                    <Options>
                                        <div style={{ marginLeft: '15px' }}>
                                            <Icon className="far fa-clock"></Icon>
                                            Hours
                                        </div>
                                        <Switch
                                            disabled={_userData.user_login != 'user' ? true : false}
                                            onChange={(val: boolean) => {
                                                setShowActions(true);
                                                setPermissions((prevState: any) => ({
                                                    ...prevState,
                                                    hours: val,
                                                }));
                                            }}
                                            value={permission?.hours}
                                        />
                                    </Options>
                                    <Hr />
                                </>
                            )}
                            {userRole === RoleEnum.SERVICEPROVIDER && (
                                <>
                                    <Options>
                                        <div style={{ marginLeft: '15px' }}>
                                            <Icon className="far fa-crown"></Icon>
                                            VIP
                                        </div>
                                        <Switch
                                            disabled={_userData.user_login != 'user' ? true : false}
                                            onChange={(val: boolean) => {
                                                setShowActions(true);
                                                setPermissions((prevState: any) => ({
                                                    ...prevState,
                                                    vip: val,
                                                }));
                                            }}
                                            value={permission?.vip}
                                        />
                                    </Options>
                                    <Hr />
                                </>
                            )}
                            {(userRole === RoleEnum.SERVICEPROVIDER ||
                                userRole === RoleEnum.TEAMMEMBER) && (
                                <>
                                    <Options>
                                        <div style={{ marginLeft: '15px' }}>
                                            <Icon className="far fa-times-circle"></Icon>
                                            Breaks
                                        </div>
                                        <Switch
                                            disabled={_userData.user_login != 'user' ? true : false}
                                            onChange={(val: boolean) => {
                                                setShowActions(true);
                                                setPermissions((prevState: any) => ({
                                                    ...prevState,
                                                    breaks: val,
                                                }));
                                            }}
                                            value={permission?.breaks}
                                        />
                                    </Options>
                                    <Hr />
                                </>
                            )}
                            {(userRole === RoleEnum.SERVICEPROVIDER ||
                                userRole === RoleEnum.TEAMMEMBER) && (
                                <>
                                    <Options>
                                        <div style={{ marginLeft: '15px' }}>
                                            <Icon className="far fa-plane-departure"></Icon>
                                            Time Off
                                        </div>
                                        <Switch
                                            disabled={_userData.user_login != 'user' ? true : false}
                                            onChange={(val: boolean) => {
                                                setShowActions(true);
                                                setPermissions((prevState: any) => ({
                                                    ...prevState,
                                                    time_off: val,
                                                }));
                                            }}
                                            value={permission?.time_off}
                                        />
                                    </Options>
                                    <Hr />
                                </>
                            )}
                            {userRole === RoleEnum.SERVICEPROVIDER && (
                                <>
                                    <Options>
                                        <div style={{ marginLeft: '15px' }}>
                                            <Icon className="far fa-hand-holding-usd"></Icon>
                                            Payment Settings
                                        </div>
                                        <Switch
                                            disabled={_userData.user_login != 'user' ? true : false}
                                            onChange={(val: boolean) => {
                                                setShowActions(true);
                                                setPermissions((prevState: any) => ({
                                                    ...prevState,
                                                    payment_settings: val,
                                                }));
                                            }}
                                            value={permission?.payment_settings}
                                        />
                                    </Options>
                                    <Hr />
                                </>
                            )}
                            {userRole === RoleEnum.SERVICEPROVIDER && (
                                <>
                                    <Options>
                                        <div style={{ marginLeft: '15px' }}>
                                            <Icon className="far fa-calendar-alt"></Icon>
                                            Booking Settings
                                        </div>
                                        <Switch
                                            disabled={_userData.user_login != 'user' ? true : false}
                                            onChange={(val: boolean) => {
                                                setShowActions(true);
                                                setPermissions((prevState: any) => ({
                                                    ...prevState,
                                                    booking_settings: val,
                                                }));
                                            }}
                                            value={permission?.booking_settings}
                                        />
                                    </Options>
                                    <Hr />
                                </>
                            )}
                            <Options>
                                <div>
                                    <Icon className="far fa-user-friends"></Icon>
                                    Teams
                                </div>
                                <Switch
                                    disabled={_userData.user_login != 'user' ? true : false}
                                    onChange={(val: boolean) => {
                                        setShowActions(true);
                                        setPermissions((prevState: any) => ({
                                            ...prevState,
                                            team: val,
                                        }));
                                    }}
                                    value={permission?.team}
                                />
                            </Options>
                            <Hr />
                            <Options>
                                <div>
                                    <Icon className="far fa-users"></Icon>
                                    Customers
                                </div>
                                <Switch
                                    disabled={_userData.user_login != 'user' ? true : false}
                                    onChange={(val: boolean) => {
                                        setShowActions(true);
                                        setPermissions((prevState: any) => ({
                                            ...prevState,
                                            customers: val,
                                        }));
                                    }}
                                    value={permission?.customers}
                                />
                            </Options>
                            <Hr />
                            <Options>
                                <div>
                                    <Icon className="far fa-cogs"></Icon>
                                    Business Setting
                                </div>
                                <Switch
                                    disabled={_userData.user_login != 'user' ? true : false}
                                    onChange={(val: boolean) => {
                                        setShowActions(true);
                                        setPermissions((prevState: any) => ({
                                            ...prevState,
                                            business: val,
                                        }));
                                    }}
                                    value={permission?.business}
                                />
                            </Options>
                            {showActions && (
                                <ButtonModel>
                                    <DiscardButton onClick={discard}>Discard</DiscardButton>
                                    <SaveButton onClick={onSubmit} disabled={loading}>
                                        {loading ? <Spinner /> : 'Save Changes'}
                                    </SaveButton>
                                </ButtonModel>
                            )}
                        </CustomCol>
                    )}
                </Row>
            </Container>
        </>
    );
};

const CustomCol = styled(Col)`
    padding: 0rem;
    @media screen and (min-width: 1px) and (max-width: 900px) {
        padding: 1rem;
    }
`;

const Options = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`;

const Icon = styled.i`
    color: ${palette.silver};
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-right: 0.5rem;
`;

const Hr = styled.div`
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    border-bottom: 1px solid #e5e7eb;
`;
const ButtonModel = styled.div`
    padding-top: 1.5rem;
    margin-top: 1.25rem;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0.75rem;
`;

const DiscardButton = styled.button`
    padding: 0.92rem 1.85rem;
    font-weight: 600;
    text-transform: capitalize;
    background-color: ${palette.white};
    border-radius: 0.57rem;
    font-size: 0.92rem;
    color: rgba(38, 52, 70);
    border: 1px solid ${palette.grey.lightest};
    cursor: pointer;
`;
const SaveButton = styled.button`
    adding: 0.92rem 1.85rem;
    font-weight: 600;
    text-transform: capitalize;
    color: ${palette.white};
    background: ${palette.secondary};
    border-radius: 0.57rem;
    font-size: 0.92rem;
    border: none;
    cursor: pointer;
`;

export default Permissions;
