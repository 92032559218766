import { Layout } from '@components/common';
import { SettingsOptions } from '@components/settings';

const Settings = () => {
    return (
        <>
            <SettingsOptions />
        </>
    );
};

Settings.Layout = Layout;
export default Settings;
