import React from 'react';
import { Container, media, Col, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { palette } from '../../../styled/common';

const InfoModel = ({ onClose }: { onClose(): void }) => {
    return (
        <Container>
            <Wrapper>
                <ModelBody>
                    <ModelClose>
                        <CloseButton onClick={onClose}>
                            {' '}
                            <i className="fa fas fa-times"></i>
                        </CloseButton>
                    </ModelClose>
                    <ModelContent>
                        VIP appointments are special time slots that a professional has set aside
                        for providing services before regular hours, during lunch or even after
                        hours. These time slots are available at a premium price. Consider this
                        option for when you do not see an available time.
                    </ModelContent>{' '}
                </ModelBody>
            </Wrapper>
        </Container>
    );
};
const Wrapper = styled(Row)`
    position: fixed;
    top: 0;
    left: 1.1rem;
    border-radius: 0.98rem;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: ${palette.overlay};
    padding: 1.8rem;
    overflow: auto;
    overscroll-behavior-y: contain;
    z-index: 102;
`;
const ModelBody = styled(Col)`
    display: flex;
    flex-direction: column;
    background: ${palette.white};
    border-radius: 0.6rem;
    box-shadow: 0 1rem 1.8rem -1.4rem ${palette.grey.shadow};
    padding: 1.7rem;
    max-width: 35vw;
    ${media.xs`
        width: 100%;
        max-width: 100%;
    `}
    ${media.sm`
        width: 100%;
        max-width: 60vw;
    `}
    ${media.md`
        width: 100%;
        max-width: 45vw;
    `}
    ${media.lg`
        max-width: 35vw;
    `}
`;
const ModelClose = styled.div`
    display: flex;
    justify-content: end;
    margin-bottom: 0.75rem;
`;
const CloseButton = styled.button`
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${palette.silver};
    background-color: ${palette.grey.lightest};
    border-radius: 0.5rem;
    font-size: 1.2rem;
    border: none;
    cursor: pointer;
`;
const ModelContent = styled.div`
    flex: 1 1;
    display: flex;
    flex-direction: column;
    padding-top: 1.25rem !important;
    padding-bottom: 2.5rem !important;
    color: rgba(157, 160, 164);
`;
export default InfoModel;
