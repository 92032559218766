export interface TabProps {
    value?: any;
    label?: string;
    selected?: boolean;
    theme?: string;
    onClick?(): void;
    onChange?(event: React.SyntheticEvent, value: any): void;
}

const Tab = ({ value, label, selected, theme, onClick, onChange }: TabProps) => {
    const themeClass = theme ? `tab--${theme}` : 'tab--default';

    const handleClick = (event: React.MouseEvent) => {
        if (!selected && onChange) {
            onChange(event, value);
        }

        if (onClick) {
            onClick();
        }
    };

    return (
        <div
            role="tab"
            onClick={handleClick}
            tabIndex={selected ? 0 : -1}
            aria-selected={selected}
            className={`tab ${themeClass} ${selected ? 'tab--active' : ''}`}>
            {label}
        </div>
    );
};

export default Tab;
