import { useContext } from 'react';
import { SnackbarContext, defaultDuration } from './Snackbar';

export const useSnackbar = () => {
    const { openSnackbar, closeSnackbar } = useContext(SnackbarContext);

    function open(text = '', duration = defaultDuration) {
        openSnackbar(text, duration);
    }

    // Returns methods in hooks array way
    return [open, closeSnackbar];
};
