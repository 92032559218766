import Compressor from 'compressorjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';

import Button from '@components/Button';
import { Input, Select, Switch, useSnackbar } from '@components/common';
import ProfileImageCropperModal from '@components/common/ProfileImageCropperModal';
import { SmallSwitch } from '@components/common/Switch';
import { BookingOptionsEnum } from 'constants/enums';
import { api } from 'helpers/auth-axios';
import useRouter from 'hooks/router';
import { IServiceProvider } from 'interfaces/team/serviceProvider.interface';

interface ErrorMessage {
    message: string;
}

const EditServiceProvider = () => {
    const [openSnackbar] = useSnackbar();
    const [loading, setLoading] = useState(false);
    const router = useRouter();

    const [serviceProvider, setServiceProvider] = useState<IServiceProvider>();

    const [serviceProviderName, setServiceProviderName] = useState<string>('');
    const [serviceProviderEmail, setServiceProviderEmail] = useState<string>('');
    const [serviceProviderPhone, setServiceProviderPhone] = useState<string>('');
    const [serviceProviderUpdatedPassword, setServiceProviderUpdatedPassword] =
        useState<string>('');
    const [serviceProviderPosition, setServiceProviderPosition] = useState<string>('');

    const [nameFieldErrorMessage, setNameFieldErrorMessage] = useState<ErrorMessage | undefined>();
    const [emailFieldErrorMessage, setEmailFieldErrorMessage] = useState<
        ErrorMessage | undefined
    >();
    const [phoneFieldErrorMessage, setPhoneFieldErrorMessage] = useState<
        ErrorMessage | undefined
    >();

    // Profile image upload state-variables
    const [showCropperModal, setShowCropperModal] = useState<boolean>(false);
    const [selectedImage, setSelectedImage] = useState<any>(); // this is the image before cropping
    const [profileImage, setProfileImage] = useState<any>(); // this is after cropping
    const [uploadImage, setUploadImage] = useState<boolean>(false);

    const [profileInfoChanged, setProfileInfoChanged] = useState<boolean>(false);

    useEffect(() => {
        getServiceProviderById();
    }, [router.query.id]);

    useEffect(() => {
        if (!serviceProvider) {
            return;
        }
        setServiceProviderName(serviceProvider.name);
        setServiceProviderEmail(serviceProvider.email);
        setServiceProviderPhone(serviceProvider.phone);
        setServiceProviderPosition(serviceProvider.position);
        setProfileImage(serviceProvider.photo);
    }, [serviceProvider]);

    useEffect(() => {
        if (!serviceProvider) {
            return;
        }

        if (
            serviceProviderName !== serviceProvider?.name ||
            serviceProviderEmail !== serviceProvider?.email ||
            serviceProviderPhone !== serviceProvider?.phone ||
            serviceProviderUpdatedPassword ||
            serviceProviderPosition !== serviceProvider.position ||
            uploadImage
        ) {
            setProfileInfoChanged(true);
        } else {
            setProfileInfoChanged(false);
        }
    }, [
        serviceProviderName,
        serviceProviderEmail,
        serviceProviderPhone,
        serviceProviderUpdatedPassword,
        serviceProviderPosition,
        uploadImage,
    ]);

    const onSubmit = () => {
        setLoading(true);
        api.put(`/serviceProvider/${router.query.id as string}`, {
            name: serviceProviderName,
            email: serviceProviderEmail,
            phone: serviceProviderPhone,
            position: serviceProviderPosition,
            updatePassword: serviceProviderUpdatedPassword ?? null,
            newPhoto: uploadImage == true ? profileImage : null,
        })
            .then((res: any) => {
                setLoading(false);
                openSnackbar('Service Provider updated successfully!');
                router.navigate(`/team/service-provider-list/${res.data._id}`);
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const getServiceProviderById = () => {
        setLoading(true);
        api.get(`/serviceProvider/${router.query.id as string}`)
            .then((res: any) => {
                setLoading(false);
                setServiceProvider(res.data);
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    /*
     * Image-Upload Functions
     */

    const getNormalizedFile = (file: any) => {
        return new Promise((resolve, reject) => {
            new Compressor(file, {
                maxWidth: 750,
                maxHeight: 750,
                success(normalizedFile) {
                    resolve(normalizedFile);
                },
                error(error) {
                    reject(error);
                },
            });
        });
    };

    const readFile = useCallback((file: any) => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result);
                getNormalizedFile(file)
                    .then((normalizedFile: any) => reader.readAsDataURL(normalizedFile))
                    .catch((error: any) => reject(error));
            } catch (error) {
                reject(error);
            }
        });
    }, []);

    const handleCapture = async (e: any) => {
        try {
            e.preventDefault();
            if (e.target?.files?.length > 0) {
                const file = e.target.files[0];
                const imageDataUrl = await readFile(file);
                setSelectedImage(imageDataUrl);
                e.target.value = null;
                setShowCropperModal(true);
            }
        } catch (error) {
            console.error(error);
        }
    };

    /*
     * Utility Functions
     */

    const formatPhoneNumberString = (phoneNumber: string) => {
        let phone = phoneNumber;
        if (phone.length <= 3) {
            phone = phone.replace(/[- .]/g, '');
        } else if (phone.length <= 7) {
            phone = phone.replace(/[- .]/g, '');
            phone = phone.slice(0, 3) + '-' + phone.slice(3, 6);
        } else if (phone.length >= 7) {
            phone = phone.replace(/[- .]/g, '');
            phone = phone.slice(0, 3) + '-' + phone.slice(3, 6) + '-' + phone.slice(6);
        }
        return phone;
    };

    const isValidEmailAddress = (emailAddr: string) => {
        const reg =
            /^([\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+\.)*[\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+@((((([a-z0-9]{1}[a-z0-9\-]{0,62}[a-z0-9]{1})|[a-z])\.)+[a-z]{2,6})|(\d{1,3}\.){3}\d{1,3}(\:\d{1,5})?)$/i;
        const val = reg.test(emailAddr);
        return val;
    };

    return (
        <>
            <MainContainer>
                <Row>
                    <Col lg={12}>
                        {serviceProvider && (
                            <>
                                <TitleContainer>
                                    <TitleBody>
                                        <CustomLink
                                            to={`/team/service-provider-list/${router.query.id}`}>
                                            <Icon className="fal fa-times"></Icon>
                                        </CustomLink>
                                        <Title>Edit Service Provider</Title>
                                    </TitleBody>
                                    {profileInfoChanged && (
                                        <AddButton>
                                            <Button
                                                disabled={loading}
                                                bgtype={'secondary'}
                                                type={'submit'}
                                                label="Save"
                                                ifClicked={onSubmit}></Button>
                                        </AddButton>
                                    )}
                                </TitleContainer>
                                <Card>
                                    <FormContent>
                                        <Left>
                                            <InfoTitle>General Info</InfoTitle>
                                            <Circle>
                                                <CircleLabel
                                                    htmlFor="faceImage"
                                                    aria-label="upload picture">
                                                    <Data
                                                        src={
                                                            uploadImage == true
                                                                ? profileImage
                                                                : `https://profile-images-barberone-s3.s3.amazonaws.com/${profileImage}`
                                                        }></Data>
                                                </CircleLabel>
                                                <FileInput
                                                    accept="image/jpeg, image/png"
                                                    id="faceImage"
                                                    type="file"
                                                    onChange={handleCapture}
                                                />
                                            </Circle>
                                            <InputField>
                                                <Input
                                                    label="Name"
                                                    name="name"
                                                    value={serviceProviderName}
                                                    onChange={({ value }: { value: string }) => {
                                                        setNameFieldErrorMessage(undefined);
                                                        const name = value.trim();
                                                        setServiceProviderName(name);
                                                        if (name === '') {
                                                            setNameFieldErrorMessage({
                                                                message: 'A name is required.',
                                                            });
                                                        }
                                                    }}
                                                    error={nameFieldErrorMessage}
                                                />
                                            </InputField>
                                            <InputField>
                                                <Input
                                                    label="Email"
                                                    name="email"
                                                    value={serviceProviderEmail.toLowerCase()}
                                                    onChange={({ value }: { value: string }) => {
                                                        setEmailFieldErrorMessage(undefined);
                                                        const email = value.trim();
                                                        setServiceProviderEmail(email);
                                                        if (email === '') {
                                                            setEmailFieldErrorMessage({
                                                                message: 'Email is required.',
                                                            });
                                                        } else if (!isValidEmailAddress(email)) {
                                                            setEmailFieldErrorMessage({
                                                                message:
                                                                    'Must enter a valid email address.',
                                                            });
                                                        }
                                                    }}
                                                    error={emailFieldErrorMessage}
                                                />
                                            </InputField>
                                            <InputField>
                                                <Input
                                                    label="Phone"
                                                    name="phone"
                                                    value={serviceProviderPhone}
                                                    onChange={({ value }: { value: string }) => {
                                                        setPhoneFieldErrorMessage(undefined);
                                                        const phoneNumber =
                                                            formatPhoneNumberString(value);
                                                        setServiceProviderPhone(phoneNumber);
                                                        if (phoneNumber.trim() === '') {
                                                            setPhoneFieldErrorMessage({
                                                                message:
                                                                    'A phone number is required',
                                                            });
                                                        }
                                                    }}
                                                    allowPhoneNumberOnly={true}
                                                    maxLength={12}
                                                    error={phoneFieldErrorMessage}
                                                />
                                            </InputField>
                                            <InputField>
                                                <Input
                                                    label="Password"
                                                    type="password"
                                                    name="updatePassword"
                                                    value={serviceProviderUpdatedPassword}
                                                    onChange={({ value }: { value: string }) => {
                                                        setServiceProviderUpdatedPassword(value);
                                                    }}
                                                />
                                            </InputField>
                                            <InputField>
                                                <Input
                                                    label="Title"
                                                    name="position"
                                                    value={serviceProviderPosition}
                                                    onChange={({ value }: { value: string }) => {
                                                        setServiceProviderPosition(value);
                                                    }}
                                                />
                                            </InputField>
                                        </Left>
                                    </FormContent>
                                </Card>
                            </>
                        )}
                    </Col>
                </Row>
                <div style={{ position: 'absolute' }}>
                    <ProfileImageCropperModal
                        showModal={showCropperModal}
                        imgURL={selectedImage}
                        onModalClose={() => setShowCropperModal(false)}
                        onSaveHandler={(croppedImage: any) => {
                            setProfileImage(croppedImage);
                            setUploadImage(true);
                        }}
                    />
                </div>
            </MainContainer>
        </>
    );
};

const MainContainer = styled(Container)`
    width: 100%;
    padding: 1.71rem;
`;

const Form = styled.form``;

const TitleContainer = styled.div`
display: flex;
justify-content: space-between;
margin-bottom: 1.75rem
align-items: center;
`;

const TitleBody = styled.div`
    display: flex;
    align-items: center;
`;
const CustomLink = styled(Link)`
    margin-right: 1.5rem /* 24px */;
`;

const Icon = styled.i`
    font-size: 2.25rem /* 36px */;
    line-height: 2.5rem /* 40px */;
    ${media.xs`
    font-size: 1.5rem
    `}
`;

const Title = styled.div`
    font-size: 1.78rem;
    font-weight: 600;
    ${media.xs`
  font-size: 1.3rem
`}
`;
const AddButton = styled.div``;
const Card = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: border-box;
    border-radius: 0.42rem;
    box-shadow: 0rem 0rem 2.14rem 0rem rgb(82 63 105 / 5%);
    border: 0;
    margin-top: 1.5rem;
`;
const FormContent = styled.div`
    height: 100%;
    display: flex;
    ${media.xs`
        display: block !important;
    `}
    ${media.sm`
        display: block;
    `}
    ${media.md`
        display: flex;
    `}
    ${media.lg`
        display: flex;
    `}
`;
const Left = styled.div`
    padding: 5rem;
    flex-basis: 100%;
`;

const InfoTitle = styled.div`
    margin-bottom: 1rem /* 16px */;
    font-size: 1.125rem /* 18px */;
    line-height: 1.75rem /* 28px */;
    font-weight: 500;
`;
const Circle = styled.div`
    justify-content: center !important;
    display: flex !important;
    margin-bottom: 3.5rem /* 24px */;
`;
const CircleLabel = styled.label`
    display: inline-block;
    flex-shrink: 0;
    position: relative;
    border-radius: 0.42rem;
    width: 3.14rem;
    height: 3.14rem;
`;
const Data = styled.img`
    background: linear-gradient(-135deg, #eee, #dddfe1);
    color: #7e8299;
    width: 5.42rem;
    height: 5.42rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 0.42rem;
    font-size: 1.21rem;
    text-transform: capitalize !important;
    border-radius: 50%;
    cursor: pointer;
`;
const InputField = styled.div`
    margin-bottom: 1.71rem;
`;
const FileInput = styled.input`
    display: none;
`;

export default EditServiceProvider;
