import { Select, WarningModal, useSnackbar } from '@components/common';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { api } from 'helpers/auth-axios';
import { IServiceProvider } from 'interfaces/team/serviceProvider.interface';
import useRouter from 'hooks/router';
import { SiSquare } from 'react-icons/si';
import { customAlphabet } from 'nanoid';
import { IAccountLink } from 'interfaces/stripe.interface';
import Button from '@components/Button';

const PaymentProcessorSettings = ({
    serviceProvider,
    reloadServiceProviderData,
    setPaymentProcessorLoading,
    readOnly,
}: {
    serviceProvider: IServiceProvider;
    reloadServiceProviderData(): void;
    setPaymentProcessorLoading(value: boolean): void; // used to tell the parent when this component is loading data
    readOnly?: boolean;
}) => {
    const router = useRouter();
    const [openSnackbar] = useSnackbar();
    const [loading, setLoading] = useState(false);

    const [showDisconnectPrompt, setShowDisconnectPrompt] = useState<boolean>(false);
    const [showRevokeTokenPrompt, setShowRevokeTokenPrompt] = useState<boolean>(false);
    const [showUpdateSquareConnectionPrompt, setShowUpdateSquareConnectionPrompt] =
        useState<boolean>(false);
    const [showUpdateSquareLocationPrompt, setShowUpdateSquareLocationPrompt] =
        useState<boolean>(false);

    const [squarePermissionsList, setSquarePermissionsList] = useState<string[]>([]);
    const [squareLocationsList, setSquareLocationsList] = useState<any[]>([]);
    const [selectedSquareLocationId, setSelectedSquareLocationId] = useState<string>(
        serviceProvider.square_location_id ?? ''
    );

    const stripeIsConnected = serviceProvider.stripe_account_verified;
    const squareIsConnected = serviceProvider.square_account_verified;
    const providerHasSquareToken = !!serviceProvider.square_access_token;
    const showRevokeSquareTokenButton = providerHasSquareToken;
    const changedSelectedSquareLocation =
        providerHasSquareToken &&
        ((!serviceProvider.square_location_id && !!selectedSquareLocationId) ||
            selectedSquareLocationId !== serviceProvider.square_location_id);

    useEffect(() => {
        reloadServiceProviderData();
    }, []);

    useEffect(() => {
        // on service provider change, reset state variables
        setSquarePermissionsList([]);
        setSquareLocationsList([]);
        setSelectedSquareLocationId(serviceProvider.square_location_id ?? '');

        if (providerHasSquareToken) {
            loadSquarePermissionsList();
        }
    }, [serviceProvider]);

    // Once permission list loads, check to see if we have square MERCHANT_PROFILE_READ permission
    // if we do, fetch square locations
    useEffect(() => {
        if (providerHasSquareToken && squarePermissionsList?.includes('MERCHANT_PROFILE_READ')) {
            loadSquareLocationsList();
        }
    }, [squarePermissionsList]);

    const connectToStripe = () => {
        const stripeData = {
            id: serviceProvider._id,
        };
        setLoading(true);
        setPaymentProcessorLoading(true);
        api.post('/billing/connect-to-stripe', stripeData)
            .then((res: any) => {
                setLoading(false);
                setPaymentProcessorLoading(false);
                const data: IAccountLink = res.data;
                window.location.href = data.url;
            })
            .catch((e: any) => {
                setLoading(false);
                setPaymentProcessorLoading(false);
                openSnackbar('Failed to integrate with Stripe. Please try again');
                reloadServiceProviderData();
            });
    };

    /*
     * Square connection flow:
     * in frontend, generate authorization URL
     * navigate to authorization URL
     * user will be sent back to this page (/team/service-provider-list/:serviceProviderId)
     * once they are sent back here, refresh the view so that accurate integration
     * status is displayed.
     * finally, remove "squareOAuthCSRF" from session storage
     */
    const connectToSquare = () => {
        // build authorization link
        const STG_HOST = 'connect.squareupsandbox.com';
        const PRD_HOST = 'connect.squareup.com';
        const HOST = process.env.REACT_APP_ENVIRONMENT === 'production' ? PRD_HOST : STG_HOST;
        const APP_ID = process.env.REACT_APP_SQUARE_APPLICATION_ID;
        const PERMISSIONS =
            'CUSTOMERS_WRITE+CUSTOMERS_READ+PAYMENTS_WRITE+PAYMENTS_WRITE_SHARED_ONFILE+PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS+PAYMENTS_READ+ORDERS_READ+ORDERS_WRITE+MERCHANT_PROFILE_READ';
        // setup csrf token generator
        const nanoid = customAlphabet('0123456789abcdef', 26);
        let CSRF_TOKEN = nanoid();
        // because square only allows you to set a single, static redirect URL
        // the user will first be redirected back to another page, before they are
        // routed back to this page.
        // To get the user to be sent back to this page, we append "-serviceProvider-",
        // followed by the provider's ID, to the CSRF token
        CSRF_TOKEN += `-serviceProvider-${serviceProvider._id}`;
        // store copy of csrf token in session storage
        localStorage.setItem('squareOAuthCSRF', CSRF_TOKEN);
        const baseUrl = `https://${HOST}/oauth2/authorize`;
        const url = `${baseUrl}?client_id=${APP_ID}&scope=${PERMISSIONS}&session=false&state=${CSRF_TOKEN}`;
        window.location.href = url;
    };

    const disconnectStripe = (value: any) => {
        setLoading(true);
        setPaymentProcessorLoading(true);
        api.put(`/serviceProvider/service_provider/${serviceProvider._id}`, {
            stripe_account_verified: value,
        })
            .then((res: any) => {
                // if the stripe account hasn't been disconnected, let the user know
                const returnedStripeStatus: boolean = res.data.stripe_account_verified;
                if (value !== returnedStripeStatus) {
                    openSnackbar(
                        'Failed to disconnect Stripe account! Check your Exclusively account permissions.'
                    );
                } else {
                    openSnackbar('Stripe Account Successfully Disconnected!');
                }
                setLoading(false);
                setPaymentProcessorLoading(false);
                reloadServiceProviderData();
            })
            .catch((e: any) => {
                setLoading(false);
                setPaymentProcessorLoading(false);
                openSnackbar('Failed to remove account. Please try again.');
                reloadServiceProviderData();
            });
    };

    const disconnectSquare = () => {
        setLoading(true);
        setPaymentProcessorLoading(true);
        api.put(`/serviceProvider/service_provider/${serviceProvider._id}`, {
            square_account_verified: false,
        })
            .then((res: any) => {
                // if square account hasn't been disconnected, let the user know
                const returnedSquareStatus: boolean = res.data.square_account_verified;
                if (returnedSquareStatus !== false) {
                    openSnackbar(
                        'Failed to disconnect Square account! Check your Exclusively account permissions.'
                    );
                } else {
                    openSnackbar('Square account successfully disconnected!');
                }
                setLoading(false);
                setPaymentProcessorLoading(false);
                reloadServiceProviderData();
            })
            .catch((e: any) => {
                setLoading(false);
                setPaymentProcessorLoading(false);
                openSnackbar('Failed to remove square account. Please try again.');
                reloadServiceProviderData();
            });
    };

    const loadSquarePermissionsList = () => {
        setLoading(true);
        setPaymentProcessorLoading(true);
        api.get(`/serviceProvider/${serviceProvider._id}/square-permissions`)
            .then((res: any) => {
                setLoading(false);
                setPaymentProcessorLoading(false);
                setSquarePermissionsList(res.data);
            })
            .catch((e: any) => {
                setLoading(false);
                setPaymentProcessorLoading(false);
                openSnackbar('Failed to load Square permissions list.');
                reloadServiceProviderData();
            });
    };

    const loadSquareLocationsList = () => {
        setLoading(true);
        setPaymentProcessorLoading(true);
        api.get(`/serviceProvider/${serviceProvider._id}/square-locations`)
            .then((res: any) => {
                setLoading(false);
                setPaymentProcessorLoading(false);
                setSquareLocationsList(res.data);
            })
            .catch((e: any) => {
                setLoading(false);
                setPaymentProcessorLoading(false);
                openSnackbar('Failed to load Square locations list.');
                reloadServiceProviderData();
            });
    };

    const revokeSquareToken = () => {
        setLoading(true);
        setPaymentProcessorLoading(true);
        api.post(`/serviceProvider/${serviceProvider._id}/revoke-square-token`)
            .then((res: any) => {
                setLoading(false);
                setPaymentProcessorLoading(false);
                openSnackbar('Square Authentication Token Successfully Revoked!');
                reloadServiceProviderData();
            })
            .catch((e: any) => {
                setLoading(false);
                setPaymentProcessorLoading(false);
                openSnackbar('Failed to revoke square access token.');
                reloadServiceProviderData();
            });
    };

    const updateSquareLocationId = (squareLocationId: string) => {
        if (!squareLocationId) {
            openSnackbar('Failed to update Square Location. No location ID given.');
        }
        setLoading(true);
        setPaymentProcessorLoading(true);
        api.put(`/serviceProvider/${serviceProvider._id}`, {
            square_location_id: squareLocationId,
        })
            .then((res: any) => {
                // if square location hasn't been changed, let the user know
                const returnedSquareLocation: string = res.data.square_location_id;
                if (returnedSquareLocation !== squareLocationId) {
                    openSnackbar(
                        'Failed to change Square location! Check your Exclusively account permissions.'
                    );
                } else {
                    openSnackbar('Successfully changed Square Location!');
                }
                setLoading(false);
                setPaymentProcessorLoading(false);
                reloadServiceProviderData();
            })
            .catch((e: any) => {
                setLoading(false);
                setPaymentProcessorLoading(false);
                openSnackbar('Failed to change Square Location. Hit "refresh" and try again.');
                reloadServiceProviderData();
            });
    };

    const getPaymentProcessor = () => {
        if (serviceProvider.stripe_account_verified) {
            return 'stripe';
        } else if (serviceProvider.square_account_verified) {
            return 'square';
        }

        return '';
    };

    return (
        <Wrapper>
            <SectionTitle>Square</SectionTitle>
            <Section>
                {squareIsConnected ? (
                    <RemoveAccountButton
                        onClick={() => setShowDisconnectPrompt(true)}
                        disabled={loading || readOnly}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        Disconnect
                    </RemoveAccountButton>
                ) : (
                    <ConnectAccountButton
                        onClick={connectToSquare}
                        disabled={loading || readOnly}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        <SiSquare style={{ fontSize: '2em' }} />
                        <span style={{ paddingLeft: '3px' }}>Square</span>
                    </ConnectAccountButton>
                )}

                {showRevokeSquareTokenButton && (
                    <RemoveAccountButton
                        onClick={() => setShowRevokeTokenPrompt(true)}
                        disabled={loading || readOnly}
                        style={{ padding: '9px 26px' }}>
                        Revoke Token
                    </RemoveAccountButton>
                )}

                {squareIsConnected &&
                    squarePermissionsList.length > 0 &&
                    !squarePermissionsList.includes('MERCHANT_PROFILE_READ') && (
                        <UpdateAccountButton
                            onClick={() => setShowUpdateSquareConnectionPrompt(true)}
                            disabled={loading || readOnly}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            Get Locations
                        </UpdateAccountButton>
                    )}
            </Section>
            {squareLocationsList && squareLocationsList.length > 0 && (
                <>
                    <SubSectionTitle>Manage Square Location</SubSectionTitle>
                    <Section
                        style={{
                            paddingLeft: '10px',
                        }}>
                        {serviceProvider.square_location_id ? (
                            <Select
                                value={
                                    squareLocationsList.find(
                                        (location: any) =>
                                            location.id === serviceProvider.square_location_id
                                    )?.name
                                }
                                options={squareLocationsList.map((location: any) => {
                                    return {
                                        label: location.name,
                                        value: location.id,
                                    };
                                })}
                                onChange={(value: string) => setSelectedSquareLocationId(value)}
                                readOnly={readOnly}
                            />
                        ) : (
                            <Select
                                options={squareLocationsList.map((location: any) => {
                                    return {
                                        label: location.name,
                                        value: location.id,
                                    };
                                })}
                                onChange={(value: string) => setSelectedSquareLocationId(value)}
                                readOnly={readOnly}
                            />
                        )}
                        {changedSelectedSquareLocation && (
                            <Button
                                type="submit"
                                bgtype="secondary"
                                width="100px !important"
                                label="Set Location"
                                disabled={loading || readOnly}
                                margin="5px"
                                ifClicked={() => setShowUpdateSquareLocationPrompt(true)}></Button>
                        )}
                    </Section>
                </>
            )}
            {stripeIsConnected && (
                <>
                    <SectionTitle>Stripe</SectionTitle>
                    <Section>
                        {stripeIsConnected && (
                            <RemoveAccountButton
                                onClick={() => setShowDisconnectPrompt(true)}
                                disabled={loading || readOnly}>
                                Remove Stripe
                            </RemoveAccountButton>
                        )}
                        {/*!stripeIsConnected && (
                <ConnectAccountButton onClick={() => connectToStripe()}>
                    <i className="fab fa-stripe fa-3x"></i>
                </ConnectAccountButton>
            )*/}
                    </Section>
                </>
            )}

            {showDisconnectPrompt && (
                <WarningModal
                    message={`Are you sure you want to disconnect from ${getPaymentProcessor()}?`}
                    onContinue={() => {
                        setShowDisconnectPrompt(false);
                        if (getPaymentProcessor() === 'stripe') {
                            disconnectStripe(false);
                        } else if (getPaymentProcessor() === 'square') {
                            disconnectSquare();
                        }
                    }}
                    onClose={() => {
                        setShowDisconnectPrompt(false);
                    }}></WarningModal>
            )}
            {showRevokeTokenPrompt && (
                <WarningModal
                    message={`Are you sure you want to revoke your Square authentication token? If you do, you will not be able to charge any appointments that were scheduled while your Square account was connected.`}
                    onContinue={() => {
                        setShowRevokeTokenPrompt(false);
                        revokeSquareToken();
                    }}
                    onClose={() => {
                        setShowRevokeTokenPrompt(false);
                    }}></WarningModal>
            )}
            {showUpdateSquareConnectionPrompt && (
                <WarningModal
                    message={`Click "Continue" to update your Square connection's permissions and allow you to select different Locations from your Square account.`}
                    onContinue={() => {
                        setShowUpdateSquareConnectionPrompt(false);
                        connectToSquare();
                    }}
                    onClose={() => {
                        setShowUpdateSquareConnectionPrompt(false);
                    }}></WarningModal>
            )}
            {showUpdateSquareLocationPrompt && (
                <WarningModal
                    message={`Are you sure you want to change which Square location you use for future payments?`}
                    onContinue={() => {
                        setShowUpdateSquareLocationPrompt(false);
                        updateSquareLocationId(selectedSquareLocationId);
                    }}
                    onClose={() => {
                        setShowUpdateSquareLocationPrompt(false);
                    }}></WarningModal>
            )}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
const SectionTitle = styled.h1`
    font-weight: 600;
`;
const SubSectionTitle = styled.span`
    font-size: 0.9rem;
    font-weight: 400;
    padding 5px;
`;
const Section = styled.div`
    display: flex;
`;
const ConnectAccountButton = styled.button`
    cursor: pointer;
    padding: 0;
    text-align: center;
    margin: 5px;
    width: 150px;
    height: 50px;
    background: #000;
    color: #fff;
    border-radius: 8px;
    :disabled {
        background: #7d7d7d !important;
    }
`;
const RemoveAccountButton = styled.button`
    cursor: pointer;
    padding: 13px 26px;
    text-align: center;
    margin: 5px;
    width: 150px;
    height: 50px;
    background: #eb5353 !important;
    color: #fff;
    border-radius: 8px;
    :disabled {
        background: #7d7d7d !important;
    }
`;
const UpdateAccountButton = styled.button`
    cursor: pointer;
    padding: 13px 26px;
    text-align: center;
    margin: 5px;
    width: 150px;
    height: 50px;
    background: rgb(35, 134, 54) !important;
    color: #fff;
    border-radius: 8px;
    :disabled {
        background: #7d7d7d !important;
    }
`;

export default PaymentProcessorSettings;
