import { Navigate } from 'react-router-dom';

interface Props {
    Component: React.FunctionComponent;
    redirectTo?: string;
}

// const PrivateElement: React.FC<Props> = ({ element }) => {
//     console.log('nano');
//     const isAuthenticated = localStorage.getItem('user') ? true : false;

//     if (isAuthenticated) return element;

//     return <Navigate to="/login" />;
// };

// export const PrivateRoute: React.FC<Props> = ({ element, ...rest }) => {
//     console.log(element);
//     return <Route {...rest} element={<PrivateElement element={element} />} />;
// };

export const PrivateRoute: React.FC<Props> = ({ Component, redirectTo, ...props }) => {
    const isAuthenticated = localStorage.getItem('user') ? true : false;

    if (!isAuthenticated) return <Navigate to={redirectTo ?? '/login'} />;

    const component = <Component {...props} />;
    const Layout = component.type?.Layout ?? <> </>;
    return <Layout>{component}</Layout>;
};
