import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Layout } from '@components/common';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import { OnlineBooking } from '@components/settings';

export default function OnlineBookingPage() {
    return (
        <Wrapper>
            <Row>
                <Column lg={12}>
                    <Link to="/settings">
                        <Icon className="fal fa-long-arrow-left"></Icon>
                    </Link>
                    <Header>Online Booking</Header>
                </Column>
            </Row>
            <OnlineBooking />
        </Wrapper>
    );
}

const Wrapper = styled(Container)`
    padding-bottom: 1.5rem !important;
`;
const Column = styled(Col)`
    display: flex;
    align-items: center;
    margin-bottom: 2rem !important;
`;
const Header = styled.h1`
    font-size: 1.875rem;
    font-weight: 600;
`;
const Icon = styled.i`
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
    margin-right: 1.25rem !important;
`;

OnlineBookingPage.Layout = Layout;
