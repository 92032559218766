import axios from 'axios';

export const api = axios.create({
    baseURL: `https://app-stage.getexclusively.com/api`,
    headers: {
        'Content-Type': 'application/json',
    },
});

api.interceptors.request.use(
    request => {
        let user = JSON.parse(localStorage.getItem('user'));
        const isLoggedIn = user && user.accessToken;

        if (isLoggedIn) {
            request.headers.common.Authorization = `Bearer ${user.accessToken}`;
        }

        return request;
    },
    error => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    response => {
        return response;
    },
    async error => {
        const originalRequest = error.config;
        if (
            error.response.status === 401 &&
            !originalRequest._retry &&
            originalRequest.url !== '/auth/login'
        ) {
            originalRequest._retry = true;

            try {
                const res = await api.post('/auth/refresh', {
                    refreshToken: JSON.parse(localStorage.getItem('user')).refreshToken,
                });

                localStorage.setItem('user', JSON.stringify(res.data));
                window.location.reload();

                return;
            } catch (_error) {
                localStorage.removeItem('user');
                localStorage.removeItem('url');
                window.location.href = '/login';
                return Promise.reject(_error);
            }
        }

        return Promise.reject(error);
    }
);
