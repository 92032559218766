import { api } from 'helpers/auth-axios';
import useRouter from 'hooks/router';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { palette } from 'styled/common';
import { useSnackbar } from '..';
import DeleteModel from '../DeleteModel/DeleteModel';
const CalendarBlock = ({ serviceProviderId }: any) => {
    const [openSnackbar] = useSnackbar();
    const [deleteModel, setDeleteModel] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleteId, setDeleteId] = useState<any>();
    const [block, setBlock] = useState<any>();
    const router = useRouter();
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');

    useEffect(() => {
        data();
    }, [router.query.id]);

    const data = () => {
        api.get(`/serviceProvider/${serviceProviderId}`).then((res: any) => {
            if (res.data !== 0) {
                setBlock(res.data.blocked);
            }
        });
    };

    const deleteBlock = (id: any) => {
        setLoading(true);
        api.delete(`/appointments/${id}`)
            .then(() => {
                setLoading(false);
                openSnackbar('Block deleted successfully!');
                setDeleteModel(false);
                const deleteBlockAppointment: any = block.filter((item: any) => {
                    return item._id !== id;
                });
                setBlock(deleteBlockAppointment);
            })
            .catch((res: any) => {
                openSnackbar('Failed to delete Block!');
            });
    };
    const openDeleteModel = (id: any) => {
        setDeleteModel(true);
        setDeleteId(id);
    };
    return (
        <div>
            <CustomCol lg={12}>
                {block !== undefined &&
                    block.map((block: any, i: any) => (
                        <div key={i}>
                            <Container style={{ marginBottom: '0.5rem' }}>
                                <Row>
                                    <Flex xs={8} sm={5} md={5} lg={5}>
                                        <CustomDiv>
                                            From&nbsp;
                                            <b>
                                                {block.booked_from_iso
                                                    ? moment(block.booked_from_iso).format(
                                                          _userData.user.business_id.time_select ===
                                                              '12'
                                                              ? 'DD-MM-YYYY hh:mm a'
                                                              : 'DD-MM-YYYY HH:mm'
                                                      )
                                                    : 'No Block found'}{' '}
                                            </b>
                                        </CustomDiv>
                                    </Flex>
                                    <Flex xs={8} sm={5} md={5} lg={5}>
                                        <CustomDiv>
                                            To&nbsp;
                                            <b>
                                                {block.booked_till_iso
                                                    ? moment(block.booked_till_iso).format(
                                                          _userData.user.business_id.time_select ===
                                                              '12'
                                                              ? 'DD-MM-YYYY hh:mm a'
                                                              : 'DD-MM-YYYY HH:mm'
                                                      )
                                                    : 'No Block found'}
                                            </b>
                                        </CustomDiv>
                                    </Flex>
                                    <FlexEnd xs={4} sm={2} md={2} lg={2}>
                                        <DeletIcon onClick={() => openDeleteModel(block._id)}>
                                            <i className="fal fa-trash-alt"></i>
                                        </DeletIcon>
                                    </FlexEnd>
                                </Row>
                            </Container>
                        </div>
                    ))}
            </CustomCol>
            {deleteModel && (
                <DeleteModel
                    id={deleteId}
                    onDelete={() => {
                        setDeleteModel(false);
                    }}
                    name="Block"
                    content="Are you sure you want to delete this Block?"
                    loading={loading}
                    onCLick={() => deleteBlock(deleteId)}
                    onClose={() => {
                        setDeleteModel(false);
                    }}
                />
            )}
        </div>
    );
};

const CustomCol = styled(Col)`
    padding: 0;
    margin-top: 1rem;
`;
const Flex = styled(Col)`
    display: flex;
    justify-content: end;
    align-items: center;
    margin-bottom: 0rem;
    ${media.xs`
    justify-content: start;
    margin-bottom:1rem;
	`}
    ${media.sm`
    justify-content: end;
	`}
	${media.md`
    justify-content: end;
	`}
	${media.lg`
    justify-content: end;
	`}
    @media screen and (min-width: 200px) and (max-width: 1050px) {
        padding-right: 0rem;
    }
`;
const FlexEnd = styled(Col)`
    display: flex;
    justify-content: end;
    align-items: center;
`;
const CustomDiv = styled.div`
    margin-left: 1rem;
`;
const DeletIcon = styled.button`
    padding: 0;
    width: 1.85rem;
    height: 1.85rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 0rem;
    background-color: ${palette.primary};
    color: ${palette.light};
    font-weight: 600;
    text-transform: capitalize;
    border-radius: 0.51rem;
    font-size: 0.87rem;
    cursor: pointer;
`;
export default CalendarBlock;
