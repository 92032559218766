import { Input, Select, useSnackbar } from '@components/common';
import Button from '@components/Button';
import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { api } from 'helpers/auth-axios';
import { IBookingWindow, IServiceProvider } from 'interfaces/team/serviceProvider.interface';
import { BookingWindowUnitsEnum } from 'constants/enums';

import { isNaN } from 'lodash';

interface ErrorMessage {
    message: string;
}

const defaultBookingWindow = {
    upper_bound_value: 180,
    upper_bound_unit: BookingWindowUnitsEnum.DAYS,
    lower_bound_value: 0,
    lower_bound_unit: BookingWindowUnitsEnum.MINUTES,
};

const BookingWindow = ({
    serviceProvider,
    reloadServiceProviderData,
    readOnly,
}: {
    serviceProvider?: IServiceProvider;
    reloadServiceProviderData(): void;
    readOnly?: boolean;
}) => {
    const [openSnackbar] = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [bookingWindow, setBookingWindow] = useState<IBookingWindow>(
        serviceProvider?.booking_window ?? defaultBookingWindow
    );
    const [originalBookingWindow, setOriginalBookingWindow] = useState<IBookingWindow>(
        serviceProvider?.booking_window ?? defaultBookingWindow
    );
    const [upperBoundValueErrorMessage, setUpperBoundValueErrorMessage] = useState<
        ErrorMessage | undefined
    >();
    const [lowerBoundValueErrorMessage, setLowerBoundValueErrorMessage] = useState<
        ErrorMessage | undefined
    >();
    const [formError, setFormError] = useState<boolean>(false);

    useEffect(() => {
        reloadServiceProviderData();
    }, []);

    useEffect(() => {
        // clear any existing error messages
        setUpperBoundValueErrorMessage(undefined);
        setLowerBoundValueErrorMessage(undefined);
        setFormError(false);

        setBookingWindow(serviceProvider?.booking_window ?? defaultBookingWindow);
        setOriginalBookingWindow(serviceProvider?.booking_window ?? defaultBookingWindow);
    }, [serviceProvider]);

    const onSubmit = () => {
        if (!serviceProvider) {
            openSnackbar('Service Provider not found. Please refresh and try again.');
            return;
        }

        const requestBody = {
            booking_window: bookingWindow,
        };

        setLoading(true);
        api.put(`/serviceProvider/service_provider/${serviceProvider._id}`, requestBody)
            .then((res: any) => {
                // if the booking window hasn't changed, let the user know
                const returnedBookingWindow: IBookingWindow = res.data.booking_window;
                if (!bookingWindowsAreEqual(bookingWindow, returnedBookingWindow)) {
                    openSnackbar('Booking Window could not be updated. Check your permissions.');
                } else {
                    openSnackbar('Booking Window updated successfully!');
                }

                reloadServiceProviderData();
                setLoading(false);
            })
            .catch((err: any) => {
                setLoading(false);
                if (err?.response) {
                    openSnackbar(err?.response?.data?.message);
                }
            });
    };

    const boundaryValueIsInvalid = (
        value: string,
        setBoundaryErrorMessage: (msg: ErrorMessage | undefined) => void
    ): boolean => {
        // clear any existing error messages
        setBoundaryErrorMessage(undefined);

        // if field is empty, tell user to enter a number
        if (value.trim() === '') {
            setFormError(true);
            setBoundaryErrorMessage({
                message: 'Please enter a number',
            });
            return true;
        }

        // if value is contains letters, set an error message
        if (/[a-zA-Z]/.test(value)) {
            setFormError(true);
            setBoundaryErrorMessage({
                message: 'Value must be a number (no letters)',
            });
            return true;
        }

        // if user enters a negative sign, tell them to stop it
        if (value.trim().startsWith('-')) {
            setFormError(true);
            setBoundaryErrorMessage({
                message: 'Value CANNOT be negative',
            });
            return true;
        }

        // if value is not a number, set an error message
        if (!isNumeric(value)) {
            setFormError(true);
            setBoundaryErrorMessage({
                message: 'Value must be a number',
            });
            return true;
        }

        // if value is not an integer, set an error message
        if (!isIntegerString(value)) {
            setFormError(true);
            setBoundaryErrorMessage({
                message: 'Value must be a WHOLE number (no decimal point)',
            });
            return true;
        }

        return false;
    };

    // use this to only enable "save" button when there is something new to save
    const bookingWindowChanged =
        bookingWindow.upper_bound_value !== originalBookingWindow.upper_bound_value ||
        bookingWindow.upper_bound_unit !== originalBookingWindow.upper_bound_unit ||
        bookingWindow.lower_bound_value !== originalBookingWindow.lower_bound_value ||
        bookingWindow.lower_bound_unit !== originalBookingWindow.lower_bound_unit;

    /*
     * Utility Functions
     */
    const isNumeric = (str: string) => {
        if (typeof str != 'string') return false;
        return !isNaN(str) && !isNaN(parseFloat(str));
    };

    const isIntegerString = (str: string) => {
        if (typeof str != 'string') return false;
        return /^[0-9]+$/.test(str);
    };

    const bookingWindowsAreEqual = (bwA: IBookingWindow, bwB: IBookingWindow) => {
        return (
            bwA.lower_bound_unit === bwB.lower_bound_unit &&
            bwA.lower_bound_value === bwB.lower_bound_value &&
            bwA.upper_bound_unit === bwB.upper_bound_unit &&
            bwA.upper_bound_value === bwB.upper_bound_value
        );
    };

    return (
        <Form>
            <FormRow style={{ borderBottom: '1px solid #e5e7eb', paddingBottom: '1.25rem' }}>
                <MessageStart>Customers can book</MessageStart>
                <ValueInput>
                    <Input
                        value={bookingWindow.upper_bound_value}
                        onChange={({ value }: { value: string }) => {
                            if (boundaryValueIsInvalid(value, setUpperBoundValueErrorMessage)) {
                                return;
                            }

                            let newUpperBoundValue = parseInt(value);

                            setFormError(false);
                            setBookingWindow((prevBookingWindow: IBookingWindow) => {
                                return {
                                    ...prevBookingWindow,
                                    upper_bound_value: newUpperBoundValue,
                                };
                            });
                        }}
                        error={upperBoundValueErrorMessage}
                        readOnly={readOnly}
                    />
                </ValueInput>
                <UnitInput>
                    <Select
                        value={bookingWindow.upper_bound_unit}
                        options={Object.values(BookingWindowUnitsEnum).map((value: string) => {
                            return {
                                label: value,
                            };
                        })}
                        onChange={(val: string) => {
                            setBookingWindow((prevBookingWindow: IBookingWindow) => {
                                return {
                                    ...prevBookingWindow,
                                    upper_bound_unit: val as BookingWindowUnitsEnum,
                                };
                            });
                        }}
                        readOnly={readOnly}
                    />
                </UnitInput>
                <MessageEnd>into the future.</MessageEnd>
            </FormRow>

            <FormRow
                style={{
                    borderBottom: '1px solid #e5e7eb',
                    paddingTop: '1.25rem',
                    paddingBottom: '1.25rem',
                }}>
                <MessageStart>Require at least</MessageStart>
                <ValueInput>
                    <Input
                        value={bookingWindow.lower_bound_value}
                        onChange={({ value }: { value: string }) => {
                            if (boundaryValueIsInvalid(value, setLowerBoundValueErrorMessage)) {
                                return;
                            }

                            let newLowerBoundValue = parseInt(value);

                            setFormError(false);
                            setBookingWindow((prevBookingWindow: IBookingWindow) => {
                                return {
                                    ...prevBookingWindow,
                                    lower_bound_value: newLowerBoundValue,
                                };
                            });
                        }}
                        error={lowerBoundValueErrorMessage}
                        readOnly={readOnly}
                    />
                </ValueInput>
                <UnitInput>
                    <Select
                        value={bookingWindow.lower_bound_unit}
                        options={Object.values(BookingWindowUnitsEnum).map((value: string) => {
                            return {
                                label: value,
                            };
                        })}
                        onChange={(val: string) => {
                            setBookingWindow((prevBookingWindow: IBookingWindow) => {
                                return {
                                    ...prevBookingWindow,
                                    lower_bound_unit: val as BookingWindowUnitsEnum,
                                };
                            });
                        }}
                        readOnly={readOnly}
                    />
                </UnitInput>
                <MessageEnd>between when an appointment is booked and when it starts.</MessageEnd>
            </FormRow>

            <FormRow style={{ paddingTop: '1.25rem', paddingBottom: '1.25rem' }}>
                <MessageStart>
                    Please note that this will impact both regular and VIP appointments.
                </MessageStart>
            </FormRow>
            {bookingWindowChanged && (
                <FormFooter>
                    <Button
                        type="submit"
                        bgtype="secondary"
                        width="150px !important"
                        label="Save"
                        disabled={formError || loading || !bookingWindowChanged}
                        ifClicked={onSubmit}></Button>
                </FormFooter>
            )}
        </Form>
    );
};

const Form = styled.div`
    @media screen and (min-width: 1px) and (max-width: 900px) {
        padding: 1rem;
    }
`;
const FormRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    ${media.xs`
        display: block;
    `}
    ${media.sm`
        display: block;
    `}
    ${media.md`
        display: block;
    `}
    ${media.lg`
        display: flex;\
    `}
`;
const FormHeader = styled(FormRow)``;
const FormFooter = styled(FormRow)``;
const FormCol = styled.div`
    display: flex;
    align-items: center;
    // margin-right: 2rem;
    ${media.xs`
        margin-bottom: 1rem;
   `}
    ${media.sm`
        margin-bottom: 1rem;
    `}
    ${media.md`
        margin-bottom: 1rem;
    `}
    ${media.lg`
        margin-bottom: 0rem;
    `}
`;
const MessageStart = styled(FormCol)`
    font-weight: 500 !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    padding-right: 1rem !important;
`;
const MessageEnd = styled(FormCol)`
    font-weight: 500 !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
`;
const ValueInput = styled(FormCol)`
    padding: 5px;
    width: 9.28rem;
    ${media.xs`
        width: 15.28rem;
    `}
    ${media.sm`
        width: 15.28rem;
    `}
    ${media.md`
        width: 9.28rem;
    `}
    ${media.lg`
        width: 9.28rem;
    `}
    ${media.xl`
        width: 9.28rem;
    `}
`;
const UnitInput = styled(FormCol)`
    padding: 5px;
    width: 9.28rem;
    ${media.xs`
        width: 15.28rem;
    `}
    ${media.sm`
        width: 15.28rem;
    `}
    ${media.md`
        width: 9.28rem;
    `}
    ${media.lg`
        width: 9.28rem;
    `}
    ${media.xl`
        width: 9.28rem;
    `}
`;

export default BookingWindow;
