const LoaderOverlay = () => {
    return (
        <div className="overlay--loader">
            <div className="loader">
                <div
                    style={{
                        textAlign: 'center',
                        marginTop: '25%',
                    }}></div>
            </div>
        </div>
    );
};

export default LoaderOverlay;
