import { publicApi } from '../../../helpers/public-axios';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import moment from 'moment-timezone';
import leftChevron from '../../../images/icons/chevron-left.svg';
import { IServiceProvider } from '../../../interfaces/team/serviceProvider.interface';
import { palette } from 'styled/common';

const SelectProfessional = ({
    business,
    onPrevious,
    onSelect,
    serviceId,
    my_duration,
    my_price,
    vip_price,
}: {
    business: any;
    onPrevious(): void;
    serviceId: any;
    my_duration(myDuration: any): void;
    my_price(myPrice: any): void;
    onSelect(professional: IServiceProvider): void;
    vip_price(vip: any): void;
}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [staff, setStaff] = useState<IServiceProvider[]>([]);
    const [selectedStaff, setSelectedStaff] = useState<IServiceProvider>();

    useEffect(() => {
        setLoading(true);
        if (business.show_first_available) {
            publicApi
                .get(
                    `/appointments/first-available?businessId=${business._id}&serviceId=${serviceId}`
                )
                .then((res: any) => {
                    // if no providers are returned, don't do unnecessary stuff
                    if (res.data.length === 0) {
                        setStaff(res.data);
                        setLoading(false);
                        return;
                    }
                    const serviceProvidersSortedByIndex = checkServiceProviderIndexesAndSort(
                        res.data
                    );
                    // find index of first available provider
                    const firstAvailableProviderIndex = serviceProvidersSortedByIndex.findIndex(
                        (provider: any) => provider.first_available
                    );
                    // take first available provider out of the list
                    let firstAvailableProvider = serviceProvidersSortedByIndex.splice(
                        firstAvailableProviderIndex,
                        1
                    )[0];
                    // move the first available provider back to front of list
                    serviceProvidersSortedByIndex.splice(0, 0, firstAvailableProvider);
                    setStaff(serviceProvidersSortedByIndex);
                    setLoading(false);
                });
        } else {
            publicApi
                .get(
                    `/appointments/first-available?businessId=${business._id}&serviceId=${serviceId}`
                )
                .then((res: any) => {
                    // if no providers are returned, don't do unnecessary stuff
                    if (res.data.length === 0) {
                        setStaff(res.data);
                        setLoading(false);
                        return;
                    }
                    const serviceProvidersSortedByIndex = checkServiceProviderIndexesAndSort(
                        res.data
                    );
                    setStaff(serviceProvidersSortedByIndex);
                    setLoading(false);
                });
        }
    }, []);

    const checkServiceProviderIndexesAndSort = (list: IServiceProvider[]) => {
        // order services by their currently set index
        list.sort((provider1, provider2) => provider1.index - provider2.index);
        // go over array again, any value less than 0 should be set to next available
        // index and moved to the end of the list
        while (list[0]?.index < 0) {
            const nextAvailableIndex = list[list.length - 1].index + 1;
            // remove element from beginning of list
            const [elem] = list.splice(0, 1);
            // update element's index
            elem.index = nextAvailableIndex;
            // add element to end of list
            list.push(elem);
        }

        return list;
    };

    return (
        <div className="booking-wrapper">
            <div className="boking-widget--header">
                <div className="booking-widget--backBtn" onClick={onPrevious}>
                    <img src={leftChevron} alt="" />
                </div>
                <h3>Select a Professional</h3>
            </div>
            <div className="booking-widget--body">
                <div className="booking-widget--body-professional">
                    <div className="professionals-container">
                        {staff.map((singleStaff: any) =>
                            singleStaff.services.map((service: any) => {
                                return (
                                    <>
                                        {service.service_id == serviceId ? (
                                            <div
                                                key={singleStaff._id}
                                                className={`professional-card ${
                                                    singleStaff === selectedStaff
                                                        ? 'selectedProfessional'
                                                        : ''
                                                }`}
                                                onClick={() => {
                                                    setSelectedStaff(singleStaff);
                                                    my_duration(service.my_duration);
                                                    my_price(service.my_price);
                                                    vip_price(service.vip_price);
                                                }}>
                                                {business.show_first_available &&
                                                singleStaff.first_available ? (
                                                    <FirstAvailableBadge>
                                                        First Available
                                                    </FirstAvailableBadge>
                                                ) : (
                                                    <FirstAvailableBadgePlaceholder>
                                                        &nbsp;
                                                    </FirstAvailableBadgePlaceholder>
                                                )}
                                                <img
                                                    src={`https://profile-images-barberone-s3.s3.amazonaws.com/${singleStaff?.photo}`}
                                                    alt=""
                                                />
                                                <div className="professional-name">
                                                    {singleStaff.name}
                                                </div>

                                                {singleStaff.position &&
                                                singleStaff.position !== '' ? (
                                                    <StaffTitle>{singleStaff.position}</StaffTitle>
                                                ) : (
                                                    <div>&nbsp;</div>
                                                )}

                                                <div className="professional-subText">
                                                    <div className="service-details">
                                                        <span className="service-direction-title">
                                                            Duration
                                                        </span>
                                                        &nbsp; &nbsp;
                                                        <span>{service.my_duration}min</span>
                                                    </div>

                                                    <div className="service-details">
                                                        <span className="service-direction-title">
                                                            Price
                                                        </span>
                                                        &nbsp; &nbsp;
                                                        <span>
                                                            {business?.currency}
                                                            {service.my_price}
                                                        </span>
                                                    </div>
                                                    {singleStaff?.vip == true ? (
                                                        <div className="service-details">
                                                            <span className="service-direction-title">
                                                                VIP Price
                                                            </span>
                                                            &nbsp; &nbsp;
                                                            <span>
                                                                {business?.currency}
                                                                {service.vip_price}
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <div className="service-details">
                                                            &nbsp;
                                                        </div>
                                                    )}
                                                    {singleStaff.first_available_slot && (
                                                        <>
                                                            <span className="next-available-date-text">
                                                                <span>Available</span>
                                                                <span>
                                                                    {moment(
                                                                        singleStaff.first_available_slot
                                                                    ).format('ddd, M/D [at] h:mma')}
                                                                </span>
                                                            </span>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </>
                                );
                            })
                        )}
                        {loading === false && staff.length === 0 && (
                            <div className="selectDate">
                                <span className="text">
                                    No staff availability for the service you've selected.
                                </span>
                            </div>
                        )}
                        {loading && (
                            <div className="selectDate">
                                <span className="text">Loading... </span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {selectedStaff ? (
                <div
                    className="booking-widget--footer"
                    onClick={() => onSelect(selectedStaff)}
                    style={{ position: 'fixed', height: '88px' }}>
                    <button className="btn">Next</button>
                </div>
            ) : (
                <div
                    className="booking-widget--footer-disabled"
                    style={{ position: 'fixed', height: '88px' }}>
                    <button className="btn">Next</button>
                </div>
            )}
        </div>
    );
};

const CustomDiv = styled.div`
    padding: 0 16px 2px 16px;

    ${media.xs`
    height: 38rem;
    overflow-y: auto;
`}
    ${media.sm`
height: 38rem;
overflow-y: auto;
`}
${media.md`
height: 100%;
overflow-y: auto;
`}
${media.lg`
height: 100%;
overflow-y: auto;
`};
`;

const StaffTitle = styled.div`
    font-size: 0.7rem;
    color: #777;
`;

const FirstAvailableBadge = styled.span`
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    color: #212529;
    background-color: #ffc107;
    margin-bottom: 0.25rem;
`;

const FirstAvailableBadgePlaceholder = styled.span`
    display: inline-block;
    padding: 0.25em 0.4em;
    line-height: 1;
    white-space: nowrap;
    margin-bottom: 0.25rem;
`;

export default SelectProfessional;
