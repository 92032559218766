import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from '@components/common';
import s from './UserNav/UserNav.module.scss';
import styled from 'styled-components';
import { palette } from 'styled/common';
import { useNavigate } from 'react-router-dom';
import EditProfileModel from './EditProfileModel';
import { api } from 'helpers/auth-axios';
import { useSnackbar } from './Snackbar';

interface IProps {
    openAuth?: () => void;
    openAuthPopup?: boolean;
    dropPosition?: string;
    userData?: any;
    url?: any;
    onBack: (data: any) => void;
}
const DropDownList = ({ openAuth, openAuthPopup, userData, onBack, url }: IProps) => {
    const navigate = useNavigate();
    const [editProfileModal, setEditProfileModal] = useState(false);
    const [openSnackbar] = useSnackbar();
    var _user: any = JSON.parse(localStorage.getItem('user') || '{}');
    const doLogout = () => {
        _user?.user_login == 'customer'
            ? navigate(`/${_user?.user?.business_id?._id}/login`)
            : navigate(`/login`);
        localStorage.removeItem('user');
        localStorage.removeItem('url');
    };

    const onClose = () => {
        setEditProfileModal(false);
    };
    const ViewBusiness = (email?: any) => {
        const data = {
            email: 'admin@exclusively.com',
            business: true,
        };
        url == '/admin' &&
            api
                .post('auth/login', data)
                .then((res: any) => {
                    if (res.data) {
                        localStorage.removeItem('user');
                        localStorage.removeItem('url');
                        localStorage.setItem('user', JSON.stringify(res.data));
                        navigate('/admin');
                        // openAuth;
                        onBack(false);
                    }
                })
                .catch((e: any) => {
                    if (e?.response) {
                        openSnackbar(e.response.data.message);
                    }
                });
    };
    return (
        <>
            <Dropdown>
                <DropdownToggle onClick={openAuth}>
                    <Icon>
                        {openAuthPopup ? (
                            <i className="fal fa-chevron-up"></i>
                        ) : (
                            <i className="fal fa-chevron-down"></i>
                        )}
                    </Icon>
                </DropdownToggle>
                {openAuthPopup && (
                    <DropdownMenu className={s.setting_menu} scrollable={false}>
                        <Cover>
                            <Heading>
                                {userData?.user_login == 'customer'
                                    ? userData?.user?.firstname + ' ' + userData?.user?.lastname
                                    : userData?.user?.name}
                            </Heading>
                        </Cover>
                        <SettingListBody>
                            {url !== undefined && url == '/admin' && (
                                <Listing onClick={() => ViewBusiness()}>
                                    <ProfileIcon>
                                        <i className="far fa-sign-out"></i>
                                    </ProfileIcon>
                                    Switch to Admin
                                </Listing>
                            )}
                            {userData?.user_login == 'user' ? (
                                userData?.user.role == 'ADMIN' ? (
                                    ''
                                ) : (
                                    <Listing onClick={() => setEditProfileModal(true)}>
                                        <ProfileIcon>
                                            <i className="far fa-edit"></i>
                                        </ProfileIcon>
                                        Edit
                                    </Listing>
                                )
                            ) : (
                                ''
                            )}
                            <Listing onClick={doLogout}>
                                <ProfileIcon>
                                    <i className="far fa-sign-out"></i>
                                </ProfileIcon>
                                Logout
                            </Listing>
                        </SettingListBody>
                    </DropdownMenu>
                )}
            </Dropdown>
            {editProfileModal && <EditProfileModel onClose={onClose} />}
        </>
    );
};

const Icon = styled.div`
    color: rgba(38, 52, 70) !important;
    border-radius: 9999px !important;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    position: relative;
    &::before {
        position: absolute;
        height: 20px;
        width: 20px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transform-origin: 50% 50%;
        content: '';
        background-color: #e5e9f2;
        border-radius: 50%;
        opacity: 0;
        transition: all 0.3s;
    }
    &:hover::before {
        width: 44px;
        height: 44px;
        opacity: 1;
    }
    &:hover i {
    }

    i {
        z-index: 1;
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 1.3rem;
    }
`;
const Cover = styled.div`
    background-color: #1a202c;

    background-position: center center;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    background-size: cover;
    padding: 24px;
`;
const Heading = styled.div`
    color: rgba(255, 255, 255) !important;
    font-weight: 500 !important;
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
`;

const ProfileIcon = styled.div`
    background-color: #141923;
    width: 36px;
    height: 36px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9999px !important;
    margin-right: 0.5rem !important;
    font-size: 0.9rem;
`;

const SettingListBody = styled.div``;

const Listing = styled.div`
    display: flex;
    padding: 0.75rem 0.75rem !important;
    border: 0.1rem solid ${palette.grey.lightest};
    align-items: center;
    cursor: pointer;
    font-size: 1.15rem !important;
    color: ${palette.dark};
    &:hover {
        background: ${palette.grey.light};
    }
`;
export default DropDownList;
