import { serviceConstants } from 'actions/service';
import { IService } from 'interfaces/service.interface';

const INITIAL_STATE = {
    services: [] as IService[],
};

export function service(state = INITIAL_STATE, action: any) {
    switch (action.type) {
        case serviceConstants.GET_SUCCESS:
            return {
                services: state.services?.concat(action.services),
            };
        case serviceConstants.CREATE_SUCCESS:
            return {
                services: state.services?.concat(action.service),
            };
        case serviceConstants.UPDATE_SUCCESS:
            const prev = state.services.find(service => service._id === action.service._id);
            Object.assign(prev, action.service);
            return state;
        default:
            return state;
    }
}
