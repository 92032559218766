import { Spinner, useSnackbar } from '@components/common';
import styled from 'styled-components';
import { appointmentActions } from 'actions/appointment';
import { api } from 'helpers/auth-axios';
import { useAppDispatch } from 'hooks/redux';
import moment from 'moment';
import { useState } from 'react';
import Button from '@components/Button';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
const DeleteRecurringModal = ({
    deleteAll,
    deleteOne,
    futureDelete,
    onClose,
    onDelete,
    getAppointments,
}: {
    deleteAll: any;
    deleteOne: any;
    futureDelete: any;
    onClose(): void;
    onDelete(): void;
    getAppointments: any;
}) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();
    const [openSnackbar] = useSnackbar();
    const closeModal = (event: any) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    const allAppointmentDelete = () => {
        setLoading(true);
        api.delete(`/appointments/recurring/${deleteAll}`)
            .then(() => {
                setLoading(false);
                onDelete();
                openSnackbar('Recurrings deleted successfullly!');
                getAppointments();
            })
            .catch((res: any) => {
                openSnackbar('Failed to delete recurring!');
            });
    };
    const singleAppointmentDelete = () => {
        setLoading(true);
        api.delete(`/appointments/${deleteOne}`)
            .then(() => {
                setLoading(false);
                onDelete();
                openSnackbar('Recurring deleted successfully!');
                getAppointments();
            })
            .catch((res: any) => {
                openSnackbar('Failed to delete recurring!');
            });
    };
    const futureAppointmentsDelete = () => {
        setLoading(true);
        api.delete(`/appointments/future_recurring/${futureDelete}`)
            .then(() => {
                setLoading(false);
                onDelete();
                openSnackbar('Recurring deleted successfully!');
                getAppointments();
            })
            .catch((res: any) => {
                openSnackbar('Failed to delete recurring!');
            });
    };
    return (
        <Model tabIndex={-1} onClick={closeModal}>
            <ModelBody style={{ width: '440px' }}>
                <ModelHead>
                    <Cover onClick={onClose}>
                        <i className="fas fa-times"></i>
                    </Cover>
                </ModelHead>
                <ModelHeading>
                    <Heading>Delete recurring appointment?</Heading>
                </ModelHeading>
                <ModelContent>
                    This will remove appointments associated with this series from your calendar.
                </ModelContent>
                <Footer>
                    {/* <Button ifClicked={onClose} bgtype={'discard'} label="Close"></Button> */}
                    <DeleteButton onClick={() => singleAppointmentDelete()} disabled={loading}>
                        {' '}
                        Delete
                    </DeleteButton>
                    &nbsp;
                    <DeleteButton onClick={() => allAppointmentDelete()} disabled={loading}>
                        Delete All
                    </DeleteButton>{' '}
                    &nbsp;
                    <DeleteButton onClick={() => futureAppointmentsDelete()} disabled={loading}>
                        Delete Future
                    </DeleteButton>
                </Footer>
            </ModelBody>
        </Model>
    );
};
const Model = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 24px;
    overflow: auto;
    overscroll-behavior-y: contain;
    z-index: 102;
`;
const ModelBody = styled.div`
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 15px 25px -6px rgb(0 0 0 / 10%);
    padding: 24px;
    max-width: 82vw;
`;
const ModelHead = styled.div`
    display: flex;
    justify-content: end;
    margin-bottom: 0.75rem;
`;
const Cover = styled.div`
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3f4254;
    background-color: #e4e6ef;
    border-radius: 8px;
    font-size: 1.2rem;
    cursor: pointer;
`;
const ModelHeading = styled.div`
    align-items: center !important;
    display: flex !important;
`;
const Heading = styled.h1`
    font-weight: 600 !important;
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
    margin-bottom: 0px !important;
`;
const ModelContent = styled.div`
    padding: 2rem 0 1rem 0 !important;
    color: rgba(157, 160, 164) !important;
`;
const Footer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const DeleteButton = styled.button`
    text-transform: capitalize;
    font-weight: 600;
    display: inline-block;
    border: none;
    border-radius: 0.57rem;
    padding: 0.93rem 0.8rem;
    color: #ffffff;
    background: #eb5353;
    box-shadow: none;
    margin: undefined;
    cursor: pointer;
    width: fit-content;
    white-space: nowrap;
    -webkit-transition: all ease 0.25s;
    transition: all ease 0.25s;
    ${media.xs`
	padding: 0.93rem 0.5rem;
    font-size: 0.7rem;
	`}
    ${media.sm`
    padding: 0.93rem 0.8rem;
	`} 
    ${media.md`
    padding: 0.93rem 0.8rem;
	`}
	${media.lg`
    padding: 0.93rem 0.8rem;
	`}
`;
export default DeleteRecurringModal;
