import { Select, useSnackbar } from '@components/common';
import Button from '@components/Button';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { api } from 'helpers/auth-axios';
import { IServiceProvider } from 'interfaces/team/serviceProvider.interface';
import { BookingOptionsEnum } from 'constants/enums';
import useRouter from 'hooks/router';

const ClientPaymentSettings = ({
    serviceProvider,
    reloadServiceProviderData,
    readOnly,
}: {
    serviceProvider: IServiceProvider;
    reloadServiceProviderData(): void;
    readOnly?: boolean;
}) => {
    const router = useRouter();
    const [openSnackbar] = useSnackbar();
    const [loading, setLoading] = useState(false);

    const initialBookingOptions: BookingOptionsEnum =
        serviceProvider.booking_options ?? BookingOptionsEnum.RESERVE_AND_PREPAY;
    const [bookingOptions, setBookingOptions] = useState<BookingOptionsEnum>(initialBookingOptions);
    const [passFeesToCustomer, setPassFeesToCustomer] = useState<boolean>(
        serviceProvider.pass_fees_to_customer
    );

    useEffect(() => {
        reloadServiceProviderData();
    }, []);

    useEffect(() => {
        setBookingOptions(initialBookingOptions);
        setPassFeesToCustomer(serviceProvider.pass_fees_to_customer);
    }, [serviceProvider]);

    const onSubmit = () => {
        let successMessage = '';
        let requestBody: any = {};
        if (bookingOptions !== initialBookingOptions) {
            requestBody['booking_options'] = bookingOptions;
            successMessage = 'Client Reservation Options updated successfully!';
        }
        if (passFeesToCustomer !== serviceProvider.pass_fees_to_customer) {
            requestBody['pass_fees_to_customer'] = passFeesToCustomer;
            successMessage = 'Service Charges updated successfully!';
        }

        if (
            bookingOptions !== initialBookingOptions &&
            passFeesToCustomer !== serviceProvider.pass_fees_to_customer
        ) {
            successMessage = 'Client Reservation Options and Service Charges updated successfully!';
        }

        setLoading(true);
        api.put(`/serviceProvider/${serviceProvider._id}`, requestBody)
            .then((res: any) => {
                // get both the requested and the returned values
                const requestedBookingOptions = requestBody.booking_options;
                const requestedPassFees = requestBody.pass_fees_to_customer;
                const returnedBookingOptions = res.data.booking_options;
                const returnedPassFees = res.data.pass_fees_to_customer;

                // if updating a setting fails, change the "success message" to let the user know
                if (requestedPassFees && requestedPassFees !== returnedPassFees) {
                    successMessage =
                        'Failed to update your Service Charges setting. Please check your permissions.';
                }
                if (requestedBookingOptions && requestedBookingOptions !== returnedBookingOptions) {
                    successMessage =
                        'Failed to update your selected Client Reservation Option. Please check your permissions.';
                }

                reloadServiceProviderData();
                setLoading(false);
                openSnackbar(successMessage);
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    // use to disable the "Save" button until there is something new to be saved
    const settingsChanged: boolean =
        bookingOptions !== initialBookingOptions ||
        passFeesToCustomer !== serviceProvider.pass_fees_to_customer;

    return (
        <Wrapper>
            <InputField>
                <Select
                    value={bookingOptions}
                    label="Client Reservation Options"
                    showLabel={true}
                    options={Object.values(BookingOptionsEnum).map((value: string) => {
                        return {
                            label: value,
                        };
                    })}
                    onChange={(val: string) => {
                        setBookingOptions(val as BookingOptionsEnum);
                    }}
                    readOnly={readOnly}
                />
            </InputField>
            {/* <InputField>
                <Select
                    value={passFeesToCustomer ? 'Service Charge' : 'No Service Charge'}
                    label="Service Charges"
                    showLabel={true}
                    options={[{ label: 'No Service Charge' }, { label: 'Service Charge' }]}
                    onChange={(val: string) => {
                        setPassFeesToCustomer(val === 'Service Charge');
                    }}
                />
            </InputField> */}
            {settingsChanged && (
                <Button
                    type="submit"
                    bgtype="secondary"
                    width="150px !important"
                    label="Save"
                    disabled={loading || readOnly}
                    ifClicked={onSubmit}></Button>
            )}
        </Wrapper>
    );
};

const Wrapper = styled.div``;
const InputField = styled.div`
    margin-bottom: 1.71rem;
`;

export default ClientPaymentSettings;
